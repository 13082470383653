import React, { useState , useEffect} from "react";

import { NavLink } from "react-router-dom";
import "./style.scss";
import { useMenuInfluencerLogic } from "./MenuInfluencerLogic";
import { FollowingYou, PrivateMeting, BookendEvent } from "./component";
import typeActitiy from "../../../../constants/typeActitiy";
import { RESTRICTED_VIEWS,IS_DEV_ENVIRONMENT, PRIVATE_MARKETPLACE } from "../../../../environment/environment"
import Activities from "../../../Activities/Activities"
import useComponentVisible from '../../../../hooks/useComponentVisible'
import i18next from 'i18next';

const MenuInfluencer = ({mobileNav}) => {
  const { activities } = useMenuInfluencerLogic();
  const [showActivities, setShowActivities] = useState(false)
  const { ref, isComponentVisible , setIsComponentVisible} = useComponentVisible(true);
  const handleShowActivities = (val) => {
    setShowActivities(val)
    setIsComponentVisible(val)
  }
  useEffect(()=> {
    if (isComponentVisible===false){
      setShowActivities(false)
    }
  }, [isComponentVisible])
  return (
    <nav   className="Nav-menu">
      <ul style= {{display:mobileNav && 'flex', flexDirection:mobileNav &&'column' , padding: mobileNav&& '0px'}}>
       {!PRIVATE_MARKETPLACE && ( <li style={{textAlign:mobileNav && 'start', marginBottom: mobileNav && '12px'}}>
          <NavLink exact to={`/my_live_events`} activeClassName="activo">
            <i style={{ marginRight: mobileNav && '12px' }} className="far fa-calendar-alt"></i>
            <span>{i18next.t('InfluencerMenuMyEvents')}</span>
          </NavLink>
        </li>)}
        {!PRIVATE_MARKETPLACE && 
        
        
         <li style={{textAlign:mobileNav && 'start', marginBottom: mobileNav && '12px'}}>
          <NavLink exact to={`/stats`} activeClassName="activo">
            <i style={{ marginRight: mobileNav && '12px' }} className="fas fa-search"></i>
            {/* <img src={statsImg} alt="stats"/> */}
            <span className="tw-mt-12">{i18next.t('InfluencerMenuMyStats')}</span>
          </NavLink>
        </li>
        }
        {!PRIVATE_MARKETPLACE &&
          <li style={{textAlign:mobileNav && 'start',marginBottom: mobileNav && '12px'}}>
            <NavLink to={`/private_meeting`} activeClassName="activo">
              <i style={{ marginRight: mobileNav && '12px' }} className="fas fa-user"></i>
              <span>{i18next.t('InfluencerMenuPrivateRequests')}</span>
            </NavLink>
          </li>
        
        /* <li>
          <NavLink to={`/message_influencer`} activeClassName="activo">
            <i style={{ marginRight: mobileNav && '12px' }} className="fas fa-comment-dots"></i>
            <span>Messages</span>
          </NavLink>
        </li> */}
       {!PRIVATE_MARKETPLACE && ( <li style={{textAlign:mobileNav && 'start',marginBottom: mobileNav && '12px'}}>
          <div style={{ position: 'relative', display: mobileNav && 'flex' }} onClick={() => handleShowActivities(!showActivities) } className="Logued-div">
            <div style={{display: mobileNav && 'flex' , flexDirection:mobileNav&& 'row'}}>
              <i style={{ marginRight: mobileNav && '12px' }} className="fas fa-bell"></i>
              <span  className="activity">{i18next.t('InfluencerMenuActivity')}</span>
            </div>
          </div>
         
          {showActivities &&
            <div ref={ref} style={{ position: 'relative' }}>
              {isComponentVisible && <Activities activities={activities} />} 

            </div>

          }
        </li>)}
        
        
      </ul>
    </nav>
  );
};

export default MenuInfluencer;
