import React, { useState } from "react";
import "./style.scss";
import { withRouter } from "react-router-dom";
import i18next from 'i18next';

const Search_input = ({ history }) => {
  const [text, setText] = useState("");
  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: "0 16px" }}>
      <div className="Search-div">
        {/* <img src="https://place-hold.it/25x25" alt=""/>*/}
        <i style={{}} className="fas fa-search"></i>
        <input
          type="text"
          className="form-control"
          placeholder={i18next.t('GotmyHomeHeaderSeachBarPlaceholder')}
          value={text}
          onChange={event => setText(event.target.value)}
          onKeyPress={event => {
            if (event.which === 13 && text) {
              history.push(`/search/${text}`);
            }
          }}
        />
      </div>

    </div>

  );
};

export default withRouter(Search_input);
