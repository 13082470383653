import React from "react";
import { Redirect } from "react-router-dom";
import compose from "recompose/compose";
import logo from "assets/img/gotmy-n.png";
import "./style.scss";
import Delete from "assets/img/icons/ic-detele.svg";
import Sad from "assets/img/icons/sad-face-in-rounded-square.svg";
import { useModalVerifyPhoneLogic } from "./ModalVerifyPhoneLogic";
import SweetAlert from "sweetalert2-react";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import { LinkedIn } from "react-linkedin-login-oauth2";
// import { LINKEDIN_CLIENTID } from "../../../../../environment/environment";
import Input_with_label from "../../../input_with_label";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

const ModalVerifyPhone = ({user, history}) => {
  const {
    formValues,
    onChangeFormValues,
    sendCode,
    onSubmit,
    errorForm,
    setErrorForm,
    setShowAlertGoodCredentials,
    showAlertGoodCredentials,
    loading,
    phase,
    phoneValue,
    setPhoneValue
  } = useModalVerifyPhoneLogic(history);

  const isPhoneVerify = user.user_validated_mobile;

  return (
    <div
      className="modal Modales-login Modal-login"
      id="myModal_verify_phone" data-backdrop="static">

      <div className="modal-dialog">
        <div className="modal-content">
          <img data-dismiss="modal" src={Delete} className="Cruz" alt="" />
          <div className="modal-body">
            <h6>Verify Phone</h6>
            <img src={logo} className="Logo" alt="" />

            {phase === "PHONE" && !isPhoneVerify && (
              <>
                <p>Add your number and we'll send you a verification code</p>
                <br />

                <PhoneInput
                  placeholder="Enter phone number"
                  international
                  value={phoneValue}
                  onChange={setPhoneValue}
                  error={phoneValue ? (isValidPhoneNumber(phoneValue) ? undefined : 'Invalid phone number') : 'Phone number required'}/>
                <button className="Btn-rojo boton_go" onClick={sendCode}>
                  Send verification code
                </button>
              </>
            )}
            {phase === "CODE" && !isPhoneVerify && (
              <>
                <Input_with_label
                  label={"Code"}
                  placeholder={"Code"}
                  type={"string"}
                  id={"code"}
                  name={"code"}
                  onChange={onChangeFormValues}
                />
                <button className="Btn-rojo boton_go" onClick={onSubmit}>
                  Verify code
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <SweetAlert
        imageUrl={Sad}
        show={errorForm}
        title={errorForm}
        onConfirm={() => setErrorForm("")}
      />
      <SweetAlert
        icon="success"
        show={showAlertGoodCredentials}
        title="Number Verified!"
        text="Please login"
        onConfirm={() => setShowAlertGoodCredentials(false)}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    loadingUserInfo: state.user.loading,
    influencer: state.user.influencer,
    isInfluencer: state.user.isInfluencer
  };
};

const enhance = compose(withRouter, connect(mapStateToProps))
export default enhance(ModalVerifyPhone);
