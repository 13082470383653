import React, { Component } from 'react';
import { useLinkedinPageLogic } from "./LinkedinPageLogic";
import queryString from 'query-string'
import axios from "axios"
import { Api, SECOND_ACCESS_TOKEN_API } from "../../environment/environment"
import { parseLocale } from 'utils/Localization'

class LinkedinPage extends Component {
  state = {}
  componentDidMount = () => {
    const values = queryString.parse(this.props.location.search)
    const code = values.code
    const path = window.location.pathname

    if (code && (path === "/linkedin/" || path === "/linkedin")) {

      axios.post(`${Api}auth/login_linkedin`, { code }, {
        headers: {
          "Access-Token": SECOND_ACCESS_TOKEN_API,
          "Content-Type": "application/json",
          "Content-Language": parseLocale()

        }
      }).then(res => {
        const { data } = res;
        const env = {
          token: data.data.user_app_token
        };

        localStorage.setItem("env", JSON.stringify(env));
        if (
          !data.data.user_name &&
          !data.data.user_lastname
        ) {
          this.props.history.push("/viewer_profile");
        } else {
          this.props.history.push(`/profile`);
        }

        document.location.reload();
        // this.setState({redirect:true})


      })
        .catch(err => {
          // this.setState({redirect:true})

        })
        ;
    }
  }
  render() {
    return (
      <>
        linkedin
      </>
    );
  }
}

export default LinkedinPage;
