import React from 'react'
import Facebook from '../../../assets/img/icons/facebook.svg';
import Instagram from '../../../assets/img/icons/instagram.svg';
import Linkedin from '../../../assets/img/icons/Linkedin.svg';
import Twitter from '../../../assets/img/icons/twitter.svg';
import Youtube from '../../../assets/img/icons/youtube.svg';
import { connect } from "react-redux";
import { SocialDiv, SocialNetwork, SocialImg, TypeAndConnect, ActivatedButton, ConnectButton } from './styles'
import { linkedinLogin, twitterLogin, facebookLogin, instagramLogin } from '../../../utils/socialLogin'
import { useLocation } from "react-router";
import i18next from 'i18next';

const SocialConnectBox = ({ user }) => {
    const location = useLocation()
    const { user_linkedin_data, user_facebook_data, user_instagram_data, user_twitter_data } = user
    const socialToRender = [{ data: user_linkedin_data, type: 'LinkedIn' },
    { data: user_facebook_data, type: 'Facebook' },
    { data: user_instagram_data, type: 'Instagram' },
    { data: user_twitter_data, type: 'Twitter' }]
    const handleSocialConnect = (network) => {

        if (network.type === 'LinkedIn' && network.data === null) {
            linkedinLogin(location.pathname)
        }
        if (network.type === 'Twitter' && network.data === null) {
            twitterLogin(location.pathname)
        }
        if (network.type === 'Facebook' && network.data === null) {
            facebookLogin(location.pathname)
        }
        if (network.type === 'Instagram' && network.data === null) {
            instagramLogin(location.pathname)
        }
    }
    return (
        <SocialDiv>
            <h4>{i18next.t("SocialConnectBoxConnect")}</h4>
            <p>{i18next.t("SocialConnectBoxConnectDescription")}</p>

            {socialToRender &&
                <div>
                    {socialToRender && socialToRender.map((network) => {

                        return (
                            <SocialNetwork>
                                <SocialImg src={network.type === 'Twitter' ? Twitter : network.type === 'Facebook' ? Facebook :
                                    network.type === "LinkedIn" ? Linkedin : network.type === 'Instagram' ? Instagram : Youtube} alt={network.type} />
                                <TypeAndConnect>
                                    <p>{network.type}</p>
                                    {network.data ?
                                        <ActivatedButton disabled={true}> {i18next.t("SocialConnectBoxConnectActivated")}</ActivatedButton>
                                        :
                                        <ConnectButton onClick={() => handleSocialConnect(network)}> {i18next.t("SocialConnectBoxConnectConnect")} </ConnectButton>
                                    }
                                </TypeAndConnect>
                            </SocialNetwork>
                        )
                    })}
                </div>
            }
        </SocialDiv>
    )
}
const mapStateToProps = state => {
    return {
        user: state.user.user
    };
};

export default connect(mapStateToProps)(SocialConnectBox)

