


import React, {Component} from 'react';

import './style.scss';
import map from "../../../assets/img/icons/ic-map.svg";
import logo from '../../../assets/img/logo-white.png';
import i18next from 'i18next';
import { SHOW_DEFAULT_ICONS } from '../../../environment/environment';

class FechaCat extends Component {
    render() {
        return (
        <span className="Hor Hor2" style={{
            alignItems: 'center'
        }}> {SHOW_DEFAULT_ICONS && (<img src={map} alt=""/>)}{i18next.t("FechaCatComponentLiveFrom")} {this.props.eventLocation}</span>
        );
    }
}

export default FechaCat;

