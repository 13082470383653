import React from "react";
import SimpleLink from "../simpleLink";
import "./style.scss";
import logo from "../../../assets/img/logo-circulo-white.png";
import AppStore from "../../../assets/img/AppStoreBoton.png";
import PlayStore from "../../../assets/img/PlayStoreBoton.png";
import {
  googlePlayGotmyURL,
  appleStoreGotmyURL,
  BRAND_BLOG_URL,
  BRAND_FACEBOOK_URL,
  BRAND_INSTAGRAM_URL,
  BRAND_LINKEDIN_URL,
  BRAND_PINTEREST_URL,
  BRAND_TWITTER_URL,
  BRAND_WEBSITE_URL,
  BRAND_YOUTUBE_URL,
  B2B_PORT,
} from "../../../environment/environment";
import { Link } from "react-router-dom";
import i18next from "i18next";

{
  i18next.t("MobileFooterComponent");
}

export const MobileFooter = () => {
  return (
    <div className="Bg-blue">
      <div className="container">
        <div className="">
          <div className=" d-flex flex-column">
            <div className="mb-5 text-white">
              <img
                src={logo}
                style={{ maxHeight: "30px", marginBottom: "12px" }}
                alt="logo"
              />
              {!B2B_PORT && (
                <>
                  <h5>{i18next.t("MobileFooterComponentDownloadAppTitle")}</h5>
                  <p>{i18next.t("MobileFooterComponentDescription")}</p>
                  <div className="logos d-flex ">
                    <a
                      id="leftIcon"
                      style={{ display: "block" }}
                      className=" "
                      href={appleStoreGotmyURL}
                      target="_blank"
                    >
                      <img
                        className="storeLogo"
                        src={AppStore}
                        alt="App Store"
                      />
                    </a>
                    <a
                      style={{ display: "block" }}
                      className=""
                      href={googlePlayGotmyURL}
                      target="_blank"
                    >
                      <img
                        className="storeLogo"
                        src={PlayStore}
                        alt="Google Play"
                      />
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>

          <div
            className="termsOfService"
            style={{ display: "flex", justifyContent: "start" }}
          >
            <Link
              to="/terms/service"
              className="termOfService"
              style={{ marginRight: "15px" }}
            >
              {i18next.t("MobileFooterComponentTermsOfService")}
            </Link>
            <Link
              to="/terms/privacy"
              className="termOfService"
              style={{ marginRight: "15px" }}
            >
              {i18next.t("MobileFooterComponentPrivacyPolicy")}
            </Link>
          </div>
          <div
            className="termsOfService"
            style={{ display: "flex", justifyContent: "start" }}
          >
            {!B2B_PORT && (
              <>
                <a
                  style={{ marginRight: "15px" }}
                  target="_blank"
                  href="https://blog.gotmy.com"
                >
                  {i18next.t("MobileFooterComponentContact")}
                </a>
                <a
                  style={{ marginRight: "15px" }}
                  target="_blank"
                  href="https://blog.gotmy.com/business/"
                >
                  {i18next.t("MobileFooterComponentInvestors")}
                </a>
              </>
            )}
            {BRAND_BLOG_URL && (
              <a
                style={{ marginRight: "15px" }}
                target="_blank"
                href={BRAND_BLOG_URL}
              >
                {i18next.t("MobileFooterComponentBlog")}
              </a>
            )}
          </div>
        </div>
        <div
          className="mt-5 mb-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className="mb-3"
            style={{ display: "flex", justifyContent: "start" }}
          >
            {BRAND_TWITTER_URL && (
              <a
                target="_blank"
                href={BRAND_TWITTER_URL}
                className="ml-6 text-gray-400 hover:text-gray-500"
              >
                <svg
                  style={{ width: "30px", marginRight: "20px" }}
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
            )}
            {BRAND_INSTAGRAM_URL && (
              <a href={BRAND_INSTAGRAM_URL} target="_blank">
                <svg
                  style={{ width: "30px", marginRight: "20px" }}
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            )}
            {BRAND_FACEBOOK_URL && (
              <a target="_blank" href="https://www.facebook.com/gotmydotcom/">
                <svg
                  style={{ width: "30px", marginRight: "20px" }}
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            )}
            {BRAND_PINTEREST_URL && (
              <a href={BRAND_PINTEREST_URL} target="_blank">
                <svg
                  style={{ width: "30px", marginRight: "20px" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path
                    d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-7 20c-.825 0-1.62-.125-2.369-.357.326-.531.813-1.402.994-2.098l.499-1.901c.261.498 1.023.918 1.833.918 2.414 0 4.152-2.219 4.152-4.976 0-2.643-2.157-4.62-4.933-4.62-3.452 0-5.286 2.317-5.286 4.841 0 1.174.625 2.634 1.624 3.1.151.07.232.039.268-.107l.222-.907c.019-.081.01-.15-.056-.23-.331-.4-.595-1.138-.595-1.825 0-1.765 1.336-3.472 3.612-3.472 1.965 0 3.341 1.339 3.341 3.255 0 2.164-1.093 3.663-2.515 3.663-.786 0-1.374-.649-1.185-1.446.226-.951.663-1.977.663-2.664 0-.614-.33-1.127-1.012-1.127-.803 0-1.448.831-1.448 1.943 0 .709.239 1.188.239
                             1.188s-.793 3.353-.938 3.977c-.161.691-.098 1.662-.028 2.294-2.974-1.165-5.082-4.06-5.082-7.449 0-4.418 3.582-8 8-8s8 3.582 8 8-3.582 8-8 8z"
                  />
                </svg>
              </a>
            )}
            {BRAND_LINKEDIN_URL && (
              <a target="_blank" href={BRAND_LINKEDIN_URL}>
                <svg
                  style={{ width: "30px", marginRight: "20px" }}
                  fill="currentColor"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                </svg>
              </a>
            )}
            {BRAND_LINKEDIN_URL && (
              <a target="_blank" href={BRAND_LINKEDIN_URL}>
                <svg
                  style={{ width: "30px", marginRight: "20px" }}
                  fill="currentColor"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M10 9.333l5.333 2.662-5.333 2.672v-5.334zm14-4.333v14c0 2.761-2.238 5-5 5h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5zm-4 7c-.02-4.123-.323-5.7-2.923-5.877-2.403-.164-7.754-.163-10.153 0-2.598.177-2.904 1.747-2.924 5.877.02 4.123.323 5.7 2.923 5.877 2.399.163 7.75.164 10.153 0 2.598-.177 2.904-1.747 2.924-5.877z" />
                </svg>
              </a>
            )}
          </div>
          <span className="Font-peq">
            {new Date().getFullYear()}{" "}
            {i18next.t("MobileFooterComponentGotmyCopyright")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MobileFooter;
