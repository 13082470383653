import React from 'react'
import { Modal } from "react-bootstrap";
import logob from 'assets/img/gotmy-n.png';
import { Link } from "react-router-dom";
import i18next from "i18next"
const HowItWorks = ({ setShowHowItWorks, showHowItWorks}) => {
    return (
        <Modal show={showHowItWorks} onHide={() => setShowHowItWorks(false)}
      >

        <Modal.Body>
          <div className="text-center d-flex flex-column justify-content-center align-items-center">
                <h4> {i18next.t("HowItWorksTitle")} <br>
                </br> {i18next.t("HowItWorksSubtitle")} </h4>
               
                <div className="text-left mt-4" style={{maxWidth:'650px'}}> 
                <p> {i18next.t("HowItWorksStep1")}</p>
                <p> {i18next.t("HowItWorksStep2")}</p>
                <p> {i18next.t("HowItWorksStep3")}</p>
               
               <p> {i18next.t("HowItWorksStep4")}
                </p>
                <p> {i18next.t("HowItWorksStep5")}</p>

                
                </div>
                <div style={{width:'150px'}} className="mt-3">

                <button onClick={() => setShowHowItWorks(false)} className="Btn-rojo-b grow"> Got it </button> 

                </div>
          </div>
        </Modal.Body>
      </Modal>
    )
}
export default HowItWorks