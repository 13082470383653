import React from "react";
import "./style.scss";
import map from "assets/img/icons/ic-map.svg";
import PropTypes from "prop-types";

const Attendees = ({ user }) => {
  const { user_avatar, user_username, user_location } = user? user : [];
  const userLocation = user_location ? JSON.parse(user_location) : {}
  return (
    <div className="Attendees">
      <div className="d-flex">
        <img style={{objectFit: 'cover'}} className="Foto" src={user_avatar} alt="" />
        <div className="d-flex flex-column">
          <span className="Nombre">{user_username}</span>
          {Object.keys(userLocation).length> 0 &&
          <span className="Hor">
            {" "}
            <img src={map} alt="" />
            {userLocation.city}
          </span>
}
        </div>
      </div>
    </div>
  );
};

Attendees.propTypes = {
  user: PropTypes.object.isRequired
};

Attendees.defaultProps = {
  user: {}
};

export default Attendees;
