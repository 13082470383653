import React, {useState, useEffect} from 'react';
import './style.scss';
import {useSelector} from "react-redux"
import moment from "moment"
import PastEventCard from "components/comunes/Cards/PastEventCard"
import Stars from "components/comunes/ratings/stars"
const Rating = ({review})=> {
  const categories = useSelector((state)=>state.categories.categories)
  const [category, setCategory] = useState()
  const [formatedDate, setFormatedDate] = useState()
  useEffect(()=>{
    if (categories && review && review.event){

      let category = categories.find((category)=> category.cate_ide===review.event.event_category_ide)
      setCategory (category.cate_description)
      setFormatedDate(moment(parseInt( review.event.event_date)).format("dddd D/M/YYYY"))
    }
  },[categories, review])

        return (
          <>
            {review && review.user && 
            <div style={{borderBottom: '1px solid #D8DCE5'}} className="mt-3 user_rating">

             <div style={{justifyContent:"space-between"}} className="d-flex "> 
                  <div>
              <div className="d-flex">  
                <div className="d-flex flex-row">
                <div className = "d-flex align-items-center ml-3">
                <div className="Div-foto_rating">
                  <img className="Foto_rating" src={review.user.user_avatar} alt=""/>
                  {/* <img src={circulo} className="Icono_ra" alt=""/> */}
                </div>
                    <div className="d-flex flex-column ml-3"><span className="nombre_rating">{review.user.user_username}</span>
                  <span className="Hor rating_comment"> 
                  
                  {/* <img src={map} className="mr-1 country_rating" alt=""/> */}
                  
                  {review.user.user}</span></div>
                  
                </div>       
              </div>
            </div>
            <p className="mx-3 my-1">{review.review_message}</p>
            <p style={{color:"#7a7883"}} className="mx-3 my-1">{formatedDate}</p>
            <p className="mx-3 my-1">{review.event.event_title}</p>
            <p style={{  color: "#ff5a60"}} className="mx-3 mt-1 mb-3">{category}</p>



                  </div>
             <div> 
               <div className=" ml-3 mb-4">
                 <Stars score={review.review_score}/>
                 </div> 

             <PastEventCard hideDetails bg_img={review.event.event_cover_image} event={review.event}
                            user_img={false} name={false} titulo={review.event.event_title}
                            cat={review.event.event_category_ide}  uuid={review.event.event_uuid} categories={categories}
             />
             </div>
             </div>   



        </div>
}
        </>
        );
    
}
export default Rating;

