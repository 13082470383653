import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";

const InstructionRow = ({ iconSrc, optionTitle, description }) => {

  return (
    <Row
    className={'instructionRow'}
      style={{
       
      }}
    >
      <Col
        xs={{ span: 2 }}
        sm={{ span: 2 }}
        md={{ span: 2 }}
        lg={{ span: 3 }}
        xl={{ span: 2 }}
      >
        <img className="image_instructions align-self-center" src={iconSrc} />
      </Col>
      <Col
        xs={{ span: 6 }}
        md={{ span: 6 }}
        lg={{ span: 8 }}
        xl={{ span: 9 }}
        style={{
          minHeight: 65
        }}
      >
        <h4 className="font-size rojo">{optionTitle}</h4>
        <p className="enable_use">
          {description}
        </p>
      </Col>
    </Row>
  );
};


export default InstructionRow;
