import * as actionTypes from "./actionTypes";
import { LanguageDAO } from "daos";

const languageDAO = new LanguageDAO();

export const setLanguages = languages => {
  return {
    type: actionTypes.SET_LANGUAGES,
    languages
  };
};

export const getLanguages = () => {
  return async dispatch => {
    try {
      const response = await languageDAO.findAll();
      dispatch(setLanguages(response.data.data));
    } catch (err) {
      throw err;
    }
  };
};
