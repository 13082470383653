import React from "react";
import Clock from "assets/img/icons/clock.svg";
import Open from "assets/img/icons/openModeBlack.svg";
import { Grid } from "@material-ui/core";
import Switch from "react-input-switch";
import i18next from "i18next";
import { NEW_PUBLISH_PAGE } from "../../../../environment/environment";
import useScreenSize from "../../../../hooks/useScreenSize";
const PublicEventOptions = ({
  publicEvent,
  setPublicEvent,
  openTicket,
  setOpenTicket,
  hideOpenTicket,
  newSkin,
}) => {
  const { screenSize } = useScreenSize();

  if (newSkin) {
    return (
      <div className={"PublicEventOptionsContainer"}>
        <p className="mb-1 small_grey">
          {i18next.t("LiveEventInformationPublicEvent")} (
          <span>{i18next.t("LiveEventInformationShowInMarketPlace")}</span>)
        </p>
        <div className=" SwitchContainer row">
          <div className={"col-12 d-flex justify-content-center"}>
            <Switch
              styles={{
                trackChecked: { backgroundColor: "#ff5a60" },
                buttonChecked: { backgroundColor: "white" },
              }}
              on={true}
              off={false}
              value={publicEvent}
              onChange={setPublicEvent}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Grid
      xs={12}
      container
      justify={"center"}
      alignItems={"center"}
      alignContent={"center"}
      direction={"column"}
    >
      <Grid
        xs={12}
        container
        item
        justify={"center"}
        alignItems={"center"}
        alignContent={"center"}
        direction={"row"}
      >
        <img
          src={Open}
          alt=""
          style={{
            cursor: "pointer",
            height: "18px",
            width: "18px",
            marginRight: "10px",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "75%",
            margin: "10px 15px",
            borderBottom: "1px solid #D8DCE5",
          }}
        >
          <span style={{ color: "black", fontSize: "16px" }}>
            {i18next.t("LiveEventInformationPublicEvent")}
          </span>
          <span style={{ color: "grey", fontSize: "12px" }}>
            {i18next.t("LiveEventInformationShowInMarketPlace")}
          </span>
        </div>
        <Switch
          styles={{
            trackChecked: { backgroundColor: "#ff5a60" },
            buttonChecked: { backgroundColor: "white" },
          }}
          on={true}
          off={false}
          value={publicEvent}
          onChange={setPublicEvent}
        />
      </Grid>
      <Grid
        xs={12}
        container
        item
        justify={"center"}
        alignItems={"center"}
        alignContent={"center"}
        direction={"row"}
      >
        <img
          src={Clock}
          alt=""
          style={{
            cursor: "pointer",
            height: "18px",
            width: "18px",
            marginRight: "10px",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "75%",
            margin: "10px 15px",
            borderBottom: "1px solid #D8DCE5",
          }}
        >
          <span style={{ color: "black", fontSize: "16px" }}>
            {i18next.t("LiveEventInformationOpenTicketing")}
          </span>
          <span style={{ color: "grey", fontSize: "12px" }}>
            {i18next.t("LiveEventInformationOpenTicketingExplanation")}
          </span>
        </div>
        <Switch
          styles={{
            trackChecked: { backgroundColor: "#ff5a60" },
            buttonChecked: { backgroundColor: "white" },
          }}
          on={true}
          off={false}
          value={openTicket}
          onChange={setOpenTicket}
        />
      </Grid>
    </Grid>
  );
};
export default PublicEventOptions;
