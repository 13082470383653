import React, { useState, useEffect } from 'react';
// import React, {Component,useState} from 'react';
import {useSelector} from "react-redux"
import './style.scss';
import Search from '../../comunes/search_input_peq';
import TableList from '../../comunes/table_stats';
import eventState from "../../../constants/eventState";
import SearchEvent from "./SearchEvent"
import i18next from "i18next"
function LiveEventStats(props) {
    const stats = useSelector(state=> state.user.myStats.data)
    const [eventsToShow, setEventsToShow] = useState("all")
    const [searchText,setSearchText] = useState("")

    const handleShowChange = (type)=>{
        setEventsToShow(type)
    }
    return (
       <div>
           <div className="container " id="live_event_stats">
               <div className="d-flex header_stats"><h2>{i18next.t("LiveEventStatsAll") }</h2>
               
               
                 </div>
                <div className="d-flex" style={{justifyContent:"space-between"}}>
                    <select onChange={e=>handleShowChange(e.target.value)} className="select_events_stats" id="select_stats_event">
                        <option value="all">{i18next.t("LiveEventStatsShowAll") }</option>
                        <option value="private">{i18next.t("LiveEventStatsShowPrivate") }</option>
                        <option value="public">{i18next.t("LiveEventStatsShowPubllic") }</option>
                    </select>
                    {/* <select className="select_events_stats">
                        <option value="grapefruit">Order by date</option>
                        <option value="lime">Order by </option>
                    </select> */}
                    <div >


               <SearchEvent searchText={searchText} setSearchText={setSearchText}/> 
                    </div>
                </div>
            <TableList eventsToShow={eventsToShow} searchText={searchText} />
            </div>
       </div>
    );
}

export default LiveEventStats;