import React, {Component} from 'react';
import Card from '../card';
import './style.scss';
import Estadisticas from '../../../comunes/graphic_line_detail';
import EstadisticasBig from '../../../comunes/graphic_line_detail_big';
import Back from "../../../comunes/back";
class LiveStatsDetail extends Component {
    constructor(){
        super();

      }
    
render(){
    return (
        <div class="StatsDetail">
        <header className="App-header">
     
        </header>
    <section className="container">
        <div className="row">
        {/* <div className="col-1">
            </div> */}
            <div className="col-4 ">
            <Back text={"Back to Live Events Stats"} url={"/stats/live_stats"}/>
            <Card/>
            </div>
            <div className="col-1"></div>
            <div className="col-6 ">
            <Estadisticas/>
            </div>
        </div>
        <div className="big_stadistics"><EstadisticasBig/></div>
        
      </section>
    </div>
     );
}
}
export default LiveStatsDetail;