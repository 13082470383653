import React, { useContext, useEffect } from "react";
import { SignContext } from "../../Sign";
import Modal_login from "../../components/comunes/login_menu/modals-login/modal_login/index";
import Modal_recover from "../../components/comunes/login_menu/modals-login/modal_recover";
import { Redirect } from "react-router-dom";
import i18next from "i18next";

function HomePrivateMarketplace({ user, loading }) {
  const [sign] = useContext(SignContext);

  useEffect(() => {
    window.$("#myModal_recover").modal({ backdrop: false, keyboard: false });
    window.$("#myModal_login").modal({ backdrop: false, keyboard: false });
    window.$("#myModal_login").modal("show");
  }, []);

  return (
    <div>
      {sign ? (
        <div>
          <Redirect to="/my_live_events" />
        </div>
      ) : null}
 <Modal_recover
        onCloseClick={() => {
          window.$("#myModal_login").modal("show");
          window.$("#myModal_recover").modal("hide");
        }}
      />
      <Modal_login
        onRecoverClick={() => {
          window
            .$("#myModal_recover")
            .modal({ backdrop: "static", keyboard: false });
        }}
      />
     
    </div>
  );
}

export default HomePrivateMarketplace;
