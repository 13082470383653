import React from 'react';
import { useState, useEffect } from "react";
import { Button, Modal } from 'react-bootstrap'
import './style.scss';
import 'react-credit-cards/lib/styles.scss'
import { connect } from "react-redux";
import Navbar from "../comunes/navbar";
import Loader from "../comunes/loader";
import useViewerPaymentMethodsLogic from "./ViewerPaymentMethodsLogic";
import Cards from 'react-credit-cards';
import i18next from 'i18next';
import logo from '../../assets/img/logo.png';
import defaultCardIcon from '../../assets/img/creditCardsIcons/default.png';
import masterCardIcon from '../../assets/img/creditCardsIcons/mastercard.png';
import amexCardIcon from '../../assets/img/creditCardsIcons/americanexpress.png';
import dinersCardIcon from '../../assets/img/creditCardsIcons/diners.png';
import unionPayCardIcon from '../../assets/img/creditCardsIcons/unionpay.png';
import discoverCardIcon from '../../assets/img/creditCardsIcons/discover.png';
import visaCardIcon from '../../assets/img/creditCardsIcons/visa.png';

const MyViewerPaymentMethods = ({ user }) => {

    const { paymentMethods, cvc, expiry, focus, name, number, handleInputChange,
        savePaymentMethod, deletePaymentMethod,
        showModal, setShowModal, handleCloseModal, handleShowModal, error } = useViewerPaymentMethodsLogic({ user });
    const [errorModal, setErrorModal] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [methodToDelete, setMethodToDelete] = useState(null);
    const handleCloseModalDelete = () => setShowModalDelete(false);
    const handleShowModalDelete = () => setShowModalDelete(true);

    useEffect(()=>{

        if (error && error.status ===true){
            setShowModal(false)
            setErrorModal(true)
        }
    },[error])

    const chooseCardIcon = (brand) => {
        if (brand === "visa") {
            return visaCardIcon;
        } else if (brand === "mastercard") {
            return masterCardIcon;
        } else if (brand === "amex") {
            return amexCardIcon;
        } else if (brand === "discover") {
            return discoverCardIcon;
        } else if (brand === "dinersclub") {
            return dinersCardIcon;
        } else if (brand === "unionpay") {
            return unionPayCardIcon;
        } else {
            return defaultCardIcon;
        }
    }

    return (
        <div>
            <header>
                <Navbar />
            </header>
            <div className="container container_my_balance" id="mybalance">

                <div className="text-center">
                    <h2>{i18next.t("MyViewerPaymentMethodsComponentHowDoYouWantToPay")}</h2>
                </div>

                <div>
                    <h5 className="text-center grey-description">
                        {i18next.t("MyViewerPaymentMethodsComponentWeNeedAPaymentMethodToProcessYourPayments")}
                    </h5>
                </div>

                {(paymentMethods && paymentMethods.map(method => {
                    var image = chooseCardIcon(method.card.brand);
                    return (
                        <div>
                            <img className="brand-image" src={image} />
                            <button className="Btn-rojo float-right" onClick={() => { setMethodToDelete(method.id); handleShowModalDelete(); }}>
                                X
                            </button>
                            <div className="border-bottom-grey spacing padding-bottom-5">
                                <div>{method.card.brand}</div>
                                <div>
                                    <div>**** **** **** {method.card.last4}</div>
                                </div>
                            </div>
                        </div>
                    )
                })
                )}

                {(!paymentMethods) ? (
                    <div>
                        <Loader />
                    </div>
                ) : null
                }

                <p></p>

                <div className="text-center">
                    <button className=" Btn-rojo" onClick={handleShowModal}>
                        {i18next.t("MyViewerPaymentMethodsComponentAddCreditCard")}
                    </button>
                </div>
                


                <Modal className="modalxd" show={errorModal} onHide={()=>setErrorModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{i18next.t("MyViewerPaymentMethodsComponentRemoveCreditCard")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                         {i18next.t("MyViewerPaymentMethodsComponentTheresBeenAnError")}
                        </div>

                        <div className="text-center">

                            {error.message}
                        </div>

                        <div className="text-center mt-4">
                                <button className="Btn-rojo" onClick={savePaymentMethod}>
                                    Back to my payment methods
                                </button>
                            </div>

                    </Modal.Body>
                </Modal>


                <Modal className="modalxd" show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{i18next.t("MyViewerPaymentMethodsComponentAddCreditCard")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="payment-form">
                            <Cards
                                cvc={cvc}
                                expiry={expiry}
                                focused={focus}
                                name={name}
                                number={number}
                                placeholders={
                                    {name: i18next.t("MyViewerPaymentMethodsComponentCardName")}
                                }
                                locale={{valid: i18next.t("MyViewerPaymentMethodsComponentExpierDate")}}
                            />
                            <p></p>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder={i18next.t("MyViewerPaymentMethodsComponentCardName")}
                                    className="form-control modal_payment_methods"
                                    onChange={handleInputChange} />
                            </div>

                            <div className="form-group">
                                <input
                                    type="tel"
                                    name="number"
                                    className="form-control modal_payment_methods"
                                    placeholder={i18next.t("MyViewerPaymentMethodsComponentCardNumber")}
                                    pattern="[\d| ]{16,22}"
                                    onChange={handleInputChange} />
                            </div>

                            <div className="form-group">
                                <input
                                    type="tel"
                                    name="expiry"
                                    className="form-control modal_payment_methods"
                                    placeholder={i18next.t("MyViewerPaymentMethodsComponentExpierDate")}
                                    pattern="\d\d/\d\d"
                                    onChange={handleInputChange} />
                            </div>

                            <div className="form-group">
                                <input
                                    type="tel"
                                    name="cvc"
                                    placeholder={i18next.t("MyViewerPaymentMethodsComponentCVC")}
                                    className="form-control modal_payment_methods"
                                    pattern="\d{3,4}"
                                    onChange={handleInputChange} />
                            </div>

                            <div className="text-center">
                                <button className="Btn-rojo" onClick={savePaymentMethod}>
                                    {i18next.t("MyViewerPaymentMethodsComponentSaveChanges")}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal className="modalxd" show={showModalDelete} onHide={handleCloseModalDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>{i18next.t("MyViewerPaymentMethodsComponentRemoveCreditCard")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <h1>{i18next.t("MyViewerPaymentMethodsComponentDoYouWantToRemoveThisCard")}</h1>
                            <h2 className="grey-description">{i18next.t("MyViewerPaymentMethodsComponentAreYouSureYouWantToRemoveThisCard")}</h2>
                            <h2 className="grey-description">{i18next.t("MyViewerPaymentMethodsComponentYouWouldHaveToAddItAgainForFuturePayments")}</h2>
                        </div>

                        <div className="text-center">
                            <button className="Btn-rojo" onClick={() => {deletePaymentMethod(methodToDelete); handleCloseModalDelete(); }}>
                                {i18next.t("MyViewerPaymentMethodsComponentYesIWantToRemoveIt")}
                            </button>
                        </div>

                    </Modal.Body>
                </Modal>

            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(MyViewerPaymentMethods);