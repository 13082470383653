import React, {Component} from 'react';
import './style.scss';
import Menu from "../../comunes/navbar_profile";
import {NavLink} from "react-router-dom";
class NavBar_Profile extends Component {
    constructor(){
        super()
        this.state = {
            showMe2 : false
        }
    }
   
    render() {
        return (
            <div></div>
        );
    }
}

export default NavBar_Profile;