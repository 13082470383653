import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from 'styled-components'
import moment from 'moment'
const BookendEvent = ({ data }) => {
  try {
    const {
      activity_from_user: { user_avatar, user_username },
      activity_event: { event_title, event_cover_image, event_uuid },
      activity_timestamp
    } = data;
    var now = moment()
    
    const timeAgo = now.to(parseInt(activity_timestamp))
  
    
    return (
      <div className="" style={{ padding: "5px 10px", maxWidth: '480px' }}>
        <div className="" style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <div >
            <div style={{ display: 'flex', maxWidth: '300px' }}>
              <img alt="avatar"
                className="user_emergency"
                src={user_avatar}
                style={{ width: 45, height: 45, borderRadius: "50%", marginRight: '12px', objectFit: 'cover' }} />
              <div style={{borderBottom:'2px solid #F3F3F5'}}>
                <Span>
                  {user_username} booked your event {event_title}
                </Span>
                <span style={{fontSize:'14px', marginTop:'4px'}}>
                  {timeAgo}
          </span>
              </div>

            </div>

          </div>


          <div style={{ position: 'relative' }}>
            <img
              alt="event"
              className="img_activity"
              src={event_cover_image}
              style={{ width: 55, height: 55, borderRadius: 5 }}
            />
          </div>
          {/* <Link to={`/event/${event_uuid}`}>
           
           
          </Link> */}

        </div>

      </div>
    );
  }
  catch {
    return null;
  }
};

BookendEvent.proTypes = {
  data: PropTypes.object.isRequired
};

const Span = styled.span`
color : black !important;
font-weight:500 !important;
margin-top:0px !important;
:hover{
  color:black !important;
}
`
export { BookendEvent };
