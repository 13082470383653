import React from "react";

const PayoutInformation = () => (
  <div className="item_help">
    <h2 className="g_color">Questions Related to Fees:</h2>
    <br />
    <h3 className="g_color">
      I’m a Viewer. What ́s the gotmy total ticket price I need to pay tojoin to
      an Influencer ́s event?:
    </h3> <br/>

    <p>Total ticket price paid by a Viewer ( “Gross Ticket Price”) includes:</p>
    <p className="tabulation_x1">
      - Ticket price as defined by Influencer ( “Net Ticket Price”)
    </p>
    <p className="tabulation_x1">
      - A fee ( “Ticketing Fee”) of $0.50 per ticket, plus a variable fee of:
    </p>
    <p className="tabulation_x2">
      4.9% of the amount of the ticket, if paid using a USA credit card ora USA
      Paypal account.
    </p>
    <p className="tabulation_x2">
      5.9% of the amount of the ticket, otherwise.
    </p>

    <h3 className="g_color">What are the gotmy Ticketing fees?gotmy</h3> <br/>
    <p>
      <span className="g_strong">gotmy</span> Ticketing fee is $0.50 per ticket,
      plus a variable fee of:
    </p>
    <p className="tabulation_x1">
      - 4.9% of the amount of the ticket, if paid using a USA credit card or a
      USAPaypal account
    </p>
    <p className="tabulation_x1">
      - 5.9% of the amount of the ticket, otherwise.
    </p>
    <p>
      Ticketing Fees do not include any fees associated with converting USD
      toViewer’s native currency.
    </p>

    <h3 className="g_color">
      I am an Influencer. What is the gotmy Platform fee?
    </h3> <br/>
    <p>
      <span className="g_strong">gotmy</span> Platform fee is 10% of the Net
      Ticket Price.
    </p>

    <h3 className="g_color">Questions Related to Payout:</h3>
    <h3 className="g_color">Payout Information</h3>

    <h3 className="g_color">
      I am an Influencer. What do I need to proceed with my Payout?
    </h3> <br/>
    <p>
      In order to proceed with any pay-out to an Influencer, Influencer must
      alreadybe or become a Verified Influencer. See{" "}
      <span className="g_strong">
        How do I become a VerifiedInfluencer? at gotmy Operational Support
        FAQMeanwhile one Influencer has not “Verified” his/her Influencer
        profile, hecannot receive any Payout.
      </span>
    </p>

    <h3 className="g_color">
      What ́is my gotmy Influencer “available balance”?
    </h3><br/>
    <p>
      Your <span className="g_strong">gotmy</span> Influencer “available
      balance” is the 90% of all the Net Ticket Priceof every ticket sold, not
      refunded and with related payment not rejected.Once a public or private
      event has successfully terminated, gotmy shall applya 10% fee to the
      Influencer for every ticket sold and not refunded (“PlatformFee”) and
      transfer a 90% of the Net Ticket Price of every ticket sold, notrefunded
      and with related payment not rejected to the Influencer’s
      “availablebalance”
    </p>

    <h3 className="g_color">
      I’m already a Verified Influencer. When do I receive my Payout?
    </h3>
    <h3 className="g_color">Automatic Payment</h3> <br/>
    <p>
      On the last working day of every month, by default, an automatic
      payment(“Payout”) will be delivered to all Verified Influencers, including
      all ticket soldand not refunded for public and private events of each
      Influencer during lastmonth (i.e. on Nov 29, 2019 a Payout will be
      released covering all tickets soldfor events terminated from Oct 1 to Oct
      31, 2019) that have not been paid-outin advanced and only if amount is $50
      or above. This automatic payment can take up to 3labor days to be executed
      in somecases (residents outside USA).
    </p>

    <h3 className="g_color">Immediate Transfer</h3> <br/>
    <p>
      At any time, the Verified Influencer can request, an “Immediate Transfer”
      of partor all his/her current available balance.These “Immediate
      Transfers” can take up to 5 labor days to be executed insome cases
      (residents outside USA).
    </p>

    <h3 className="g_color">What are the gotmy payout fees?</h3>
    <h3>
      <span className="g_strong">gotmy</span> fees for automatic Payouts are:
    </h3> <br/>

    <p>
      For residents in USA and Canada with domestic accounts:
    </p>
    <p className="tabulation_x1">- Using Stripe Direct Deposit: $3.</p>
    <p className="tabulation_x1">- Using Paypal: free.</p>

    <p>
      For residents outside USA and Canada with international accounts inUSD:
    </p>
    <p className="tabulation_x1">- Using Stripe Direct Deposit: $5 plus 1% of the transferredamount.</p>
    <p className="tabulation_x1">- Using Paypal: 2% of the transferred amount with $20 maximum.</p>

    <p>
      For residents outside USA and Canada with international accounts
      inInfluencer ́s native currency:
    </p>
    <p className="tabulation_x1">- Using Stripe Direct Deposit: $5 plus 1% of the transferredamount.</p>
    <p className="tabulation_x1">- Using Paypal: 2% of the transferred amount with $20 maximum.</p>
    <p className="tabulation_x1">
      Plus, the international account fees associated with converting USD
      toInfluencer’s native currency.
    </p>
    <p>
      Automatic payments can take up to 3labor days to be executed in some
      cases(residents outside USA).
    </p>

    <p>
      <span className="g_strong">gotmy</span> fees for “Inmediate Transfer” add an additional 3% fee shall apply
      as follows:
    </p>
    <p>For residents in USA and Canada with domestic accounts:</p>
    <p className="tabulation_x1">- Using Stripe Direct Deposit: $3 plus 3% of the transferredamount.</p>
    <p className="tabulation_x1">- Using Paypal: 3% of the transferred amount.</p>

    <p>
      For residents outside USA and Canada with international accounts inUSD:
    </p>
    <p className="tabulation_x1">- Using Stripe Direct Deposit: $5 plus 4% of the transferred amount.</p>
    <p className="tabulation_x1">- Using Paypal: 5% of the transferred amount with $50 maximum.</p>

    <p>
      For residents outside USA and Canada with international accounts
      inInfluencer ́s native currency:
    </p>
    <p className="tabulation_x1">- Using Stripe Direct Deposit: $5 plus 4% of the transferredamount.</p>
    <p className="tabulation_x1">- Using Paypal: 5% of the transferred amount with $50 maximum.</p>
    <p>
      Plus, the international account fees associated with converting USD
      toInfluencer’s native currency.
    </p>
    <p>
      Immediate Transfers” can take up to 5 labor days to be executed in
      somecases (residents outside USA).
    </p>
  </div>
);

export { PayoutInformation };
