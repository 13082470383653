import { useState, useEffect } from "react";
import useWindowSize from "./useWindowSize";
import { isMobile } from "react-device-detect";
import { B2B_PORT } from "../environment/environment";
const useMobileNav = () => {
  const windowSize = useWindowSize();
  const { width } = windowSize;
  const [mobileNav, setMobileNav] = useState(isMobile);

  useEffect(() => {
    if (width < (!B2B_PORT ? 989 : 768)) {
      setMobileNav(true);
    } else {
      setMobileNav(false);
    }
  }, [width]);
  return { mobileNav };
};

export default useMobileNav;
