import React, { useState, useEffect } from 'react';
import './style.scss';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NavBarInfluencer from "../comunes/navbar_influencer";
import Loader from "../comunes/loader";
import useInfluencerBalanceLogic from "./InfluencerBalanceLogic";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux"

import statsMoneyIcon from "../../assets/img/icons/stasMoney.png";
import calendarIcon from "../../assets/img/icons/CalendarGrey.png";
import * as actions from "../../store/actions";
import {parsePriceByCurrencyCode} from "utils/currencyUtils"
const MyBalance = ({ user }) => {
    const dispatch = useDispatch()

    const { resume, transactions } = useInfluencerBalanceLogic({ user });
    const stats = useSelector(state => state.user.myStats.data)
    const [currentCurrency, setCurrentCurrency] = useState()
    const [currencySelectFlag, setCurrencySelectFlag] = useState(false)
    useEffect(()=>{
        try{
            if (stats && stats.currencies && !currencySelectFlag){
                setCurrentCurrency (stats.currencies[0].currency_code)
            }
        }catch(error){
            console.error(error);
        }

    },[stats])
    const handleCurrencySelect = (currency) => {
        const env = JSON.parse(localStorage.getItem("env") || "{}");
        dispatch(actions.getInfluencer(env.token, currency))
        console.log ({currency})
        setCurrentCurrency (currency)
        setCurrencySelectFlag(true)
    }

    return (
        <div>
            <header>
                <NavBarInfluencer />
            </header>
            <div className="container container_my_balance" id="mybalance">
                <div className="d-flex header_stats"><h2>My Balance</h2></div>

                {(resume && stats) ? (
                    <div className="container Shadow card">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <span>Total Current Balance </span>
                                <div className="float-right font-x-large">
                                    <div>
                                        
                                    {parsePriceByCurrencyCode (resume.total_available.toFixed(2), currentCurrency, stats.currencies ) }
                                    
                                    <select onChange={(e) => handleCurrencySelect(e.target.value)} className=" select-css ml-2 " default="usd" id="inlineFormCustomSelect" >
                                        {stats && 
                                            stats.currencies.map((currency) => { if (currency.currency_code !== "free") return (<option value={currency.currency_code}>{currency.currency_name}</option>) }
                                            )
                                        }
                                </select>
                                    
                                    
                                    </div>
                                    <div className="color-green float-right"><img src={statsMoneyIcon} /> {resume.percentage.toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-md-6 text-center border-right-gray">
                                <span>Available Balance</span>
                                {/* <div> {resume.total_available.toFixed(2)} {currentCurrency}

                                  
                                </div> */}
                                <div> {parsePriceByCurrencyCode (resume.total_available.toFixed(2), currentCurrency, stats.currencies ) } </div>
                                </div>

                                <div className="col-6 col-md-6 text-center">
                                    <span>Unavailable balance</span>
                                    {/* <div>$ {resume.total_not_available.toFixed(2)} {currentCurrency}</div> */}
                                    <div>{parsePriceByCurrencyCode (resume.total_not_available.toFixed(2), currentCurrency, stats.currencies )}</div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 text-center">
                                    <span>Processing Balance</span>
                                    {/* <div>$ {resume.total_processing.toFixed(2)} {currentCurrency}</div> */}
                                    <div>{parsePriceByCurrencyCode (resume.total_processing.toFixed(2), currentCurrency, stats.currencies )}</div>

                                </div>
                            </div>
                        </div>
                ) : (<Loader />)
                }

                        {transactions ? (
                            <div className="margin-top-15">
                                <span>Balance overview</span>
                                <span className="float-right"><Link to="/my_transactions">View transactions</Link></span>
                            </div>) : null
                        }
                        {/* I don't get this (gabri) */}
                        {false && (transactions && transactions[0]) ? (
                            <div>
                                <img src={calendarIcon} className="padding-right-10" />
                                <span className="inline-grid">
                                    <div className="font-weight-700">Ticket bought for event {transactions[0].event.event_title} on {moment(parseInt(transactions[0].transaction_date)).format("L")}</div>
                                    <div>$ {transactions[0].amount} USD</div>
                                </span>
                            </div>
                        ) : null
                        }

                    </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
                user: state.user
    };
};

export default connect(mapStateToProps)(MyBalance);