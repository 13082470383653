import React from "react";
import Avatar from '../CardUtils/Avatar'
import cinco_estrellas from "assets/img/5-estrellas.png";
import map from "assets/img/icons/ic-map.svg";
import per from "assets/img/icons/ic-user.svg";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import Loader from "components/comunes/loader";
import FavoriteIcon from "components/comunes/FavoriteIcon";
import { connect } from "react-redux";
import { EventCardContainer } from './styles'
import useInfluencerHour from "../../../hooks/useInfluencerHour"
import useUserOrLocalTimeHour from "../../../hooks/useUserOrLocalTimeHour"
import {  parsePriceByCurrency } from "../../../utils/currencyUtils";
import styles from '../../../assets/css_general/general.scss';

import i18next from 'i18next';
import {B2B_PORT, hideCategories} from "../../../environment/environment";

const EventCard = ({ history, event, loading, uniqueEvent, purchasedEvents, showFavorite, showCountdown, extraStyles }) => {
    const {event_title,currency, event_category, event_uuid, event_date,
        event_cover_image, event_num_attendees,
         event_num_attendees_streaming, event_num_tickets_free_streaming, event_price_ticket_streaming,
         event_num_tickets_free, event_streaming_start_date,
         is_favorite, event_price_ticket } = event.event;
    console.log({event: event.event})
    const { user_username, user_name,user_avatar } = event.user;
    const { influencerTime } = useInfluencerHour(event_date, event.user)
    const { time } = useUserOrLocalTimeHour(event_date, event.event)
    const linkTo =
        Array.isArray(purchasedEvents) &&
            purchasedEvents.find(item => item.event.event_uuid === event_uuid)
            ? `/event/${event_uuid}`
            : `/event/${event_uuid}`;
    var now = moment()
    const eventStart = moment(parseInt(event_date))
    const startIn = now.to(eventStart)
    return (
        <EventCardContainer className={`${extraStyles}   mb-5 grow`}>
            <div style={{ borderRadius: '7px' }} className="Shadow-lg">
                {!loading ? (
                    <>
                        <div
                            className="Header"
                            style={{
                                background: `linear-gradient(rgba(17, 16, 22, 0.0),
                                rgba(17, 16, 22, 0.0)), url(${event_cover_image})`,
                                backgroundSize: "cover"
                            }}
                        >

                            {showFavorite
                                && <div style={{ zIndex: 999 }} className="cursor-pointer">
                                    <FavoriteIcon isFavoriteInitial={is_favorite} uuid={event_uuid} />
                                </div>
                            }
                            {showCountdown && (
                                <div className="Start">
                                    {i18next.t('EventCardComponentStarts')} {startIn}
                                </div>
                            )}

                            <Link to={`/event/${event_uuid}`} >
                                <div style={{  alignItems: B2B_PORT ? 'center' : 'start' }} className="Negro">
                                    <Avatar user_avatar={user_avatar} />
                                    <div style={{ display: 'flex', alignItems: B2B_PORT ? 'center' : 'start' }}>
                                        <span className="Nombre">{B2B_PORT? user_name : user_username}</span>
                                    </div>
                                    <div className="Div-asis">

                                        {(event_num_tickets_free > 0 || event_num_tickets_free_streaming>0)&& <span className="Tickets"> {i18next.t('EventCardComponentTicketsLeft')}</span>}
                                        {(event_num_tickets_free === 0  || event_num_tickets_free_streaming===0 )&&
                                         <span className="Tickets">{i18next.t('EventCardComponentSoldOut')}</span>}
                                       {(event_price_ticket !== null) &&  <span style={{color:styles.secondColor}}>{parsePriceByCurrency(event_price_ticket, currency)}</span>}
                                       {(event_price_ticket_streaming !== null) &&  <span style={{color:styles.secondColor}}>{parsePriceByCurrency(event_price_ticket_streaming , currency)}</span>}
                                    </div>
                                </div>
                            </Link>

                        </div>
                        <Link to={linkTo}>
                            <div className="Footer" style={{
                                    alignItems: B2B_PORT ? 'center' : ""
                                }}>
                                <div className="Fecha" style={{
                                    justifyContent: B2B_PORT ? 'center' : ""
                                }}>
                                    <span className="Dia">
                                        {time.day}
                                    </span>
                                    <span style={{ color: styles.secondColor, textAlign: "center" }} className="Mes">
                                        {time.month}
                                    </span>
                                </div>
                                <div className="Texto">
                                    <span className="Tit">{event_title}</span>
                                    {!B2B_PORT && !hideCategories && (<span style={{ color: styles.secondColor }} className="Cat">
                                        {event_category && event_category.cate_description}
                                    </span>)}
                                    <span className="Hor">

                                        {i18next.t('EventCardComponentLiveAt')} {time.time}             </span>
                                </div>
                            </div>
                        </Link>
                    </>
                ) : (
                        <Loader />
                    )}
            </div>
        </EventCardContainer>
    );
};

EventCard.propTypes = {
    event: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
    return {
        purchasedEvents: state.user.user.purchasedEvents
    };
};

export default connect(mapStateToProps)(withRouter(EventCard));
