import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import i18next from "i18next";
import DiaMes from "components/comunes_events/dia_mes";
import PuntUsu from "components/comunes_events/imagen_usu_puntuacion";
import NombreCat from "components/comunes_events/nombre_cat_fecha";
import moment from "moment";
import { parsePriceByCurrency } from "utils/currencyUtils";
import ShareEvent from "../../../../pages/event/ShareEvent";
import genericUsername from "assets/img/generic.png";
import ViewerCount from "../../../../pages/event/ViewerCount";
import AttendeesEvent from "../../../../pages/event/AttendeesEvent";

const monthNames = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const mockEventData = {
  attendees_users: [
    { user_avatar: genericUsername, user_username: "Charles" },
    { user_avatar: genericUsername, user_username: "Cristina" },
    { user_avatar: genericUsername, user_username: "Paul" },
    { user_avatar: genericUsername, user_username: "Leo" },
    { user_avatar: genericUsername, user_username: "Faus" }
  ],
};

const EventPreview = ({
  event_num_attendees,
  event_description,
  event_price,
  event_title,
  showPreview,
  setShowPreview,
  loadedImg,
  event_category,
  event_date,
  upcoming,
  event_location,
  user_username,
  influencer_avatar,
  imagePlaceHolder
}) => {
  console.log({ event_date });
  const [date, setDate] = useState({
    day: moment().format("DD"),
    month: moment().format("MM"),
  });

  const calculateDate = (event_date) => {
    let event_date_from_timestamp = moment(event_date);

    try {
      setDate({
        month: event_date_from_timestamp
          .format("MMM")
          .toUpperCase()
          .replace(".", ""),
        day: event_date_from_timestamp.format("D"),
        hour: event_date_from_timestamp.format("HH:mm"),
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    calculateDate(event_date);
  }, [event_date]);

  console.log("render", date);
  return (
    <Modal show={showPreview} onHide={() => setShowPreview(false)}>
      {true && (
        <Modal.Header closeButton>
          <div className="text-center  w-100">
            <span className="">
              {i18next.t("InfluencerPublishPreviewYourEvent")}
            </span>
          </div>
        </Modal.Header>
      )}
      <Modal.Body>
        <div className="w-100 d-md-flex">
          <div className=" col-12 col-md-8 ">
            <div className="p-2  rounded-md row">
              <div className="col-1">
                <DiaMes
                  prev
                  day={date.day}
                  month={date.month}
                  // day={time.day} month={time.month}
                />
              </div>

              <div className="col-11">
                <PuntUsu
                  disabledLink={true}
                  score={5}
                  userName={user_username}
                  userAvatar={influencer_avatar}
                  hideSpecialIcon={true}
                />
              </div>

              <div className="col-12 titleDescriptionEventPreview">
                <NombreCat
                  event_description={
                    event_description ||
                    i18next.t("EventPreviewComponentDescriptionPlaceholder")
                  }
                  upcoming={upcoming}
                  title={
                    event_title ||
                    i18next.t("EventPreviewComponentTitlePlaceholder")
                  }
                  category={event_category}
                  hour={date && date.hour}
                  timeTotal={date && date.hour ? date.hour : ""}
                  timeZone={date && ""}
                  year={moment(event_date).format("YYYY")}
                  location={"los angeles"}
                  day={date && date.day}
                />
              </div>
            </div>

            <img
              style={{
                maxHeight: "425px",
                objectFit: "cover",
                marginTop: "0px !important",
              }}
              className={`w-100 br-10 `}
              src={loadedImg || imagePlaceHolder}
            />
          </div>

          <div
            style={{ flexDirection: "column", alignItems: "start" }}
            className="col-md-4 d-flex col-12 mt-2 mt-md-0 "
          >
            <ShareEvent
              url={"testUrl"}
              title={event_title}
              isCreator
              size={26}
            />
            <AttendeesEvent eventData={mockEventData} />

            <button
              style={{ maxWidth: 250, marginTop: 0 }}
              disabled={true}
              className="Btn-rojo-b mt-2 grow px-4 alignMobile"
            >
              {parseFloat(event_price) > 0 &&
                i18next.t("EventPageComponentBuyTicketFor") +
                  ` ${event_price} USD`}
              {parseFloat(event_price) === 0 &&
                i18next.t("EventPageComponentBuyTicketForFree")}
              {event_num_attendees === 0 &&
                i18next.t("EventPageComponentSorryEventSoldOut")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EventPreview;
