import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../stats/canvasjs.react/canvasjs.react';
import './App.css';
import {useSelector } from "react-redux"

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
const StackedColumnChart = (props)=> {
		var bookingsInitial = {theme: "light2",animationEnabled: true,exportEnabled: false,
			toolTip: {
					shared: true,
					content: "{name}: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {y}",
					backgroundColor: "#2F2D3A",
					fontColor: "white",
					borderThickness: 0,
					cornerRadius: 5 
			},
		dataPointMaxWidth: 600,
			toolTip:{enabled: true },
			data: [{axisYType: "secondary", legendMarkerType: "circle",type: "stackedColumn",name: "unsold tickets",showInLegend: true,color: '#E2E7ED',
				yValueFormatString: "#",
				// dataPoints: [
				// 	{ label: "Jan", y: 10 },
				// 	{ label: "Feb", y: 18 },
				// ]
			},
			{
				axisYType: "secondary", legendMarkerType: "circle",type: "stackedColumn",name: "sold tickets",color: '#FF5A60',
				showInLegend: true,yValueFormatString: "#",
				// dataPoints: [{ label: "Jan", y: 13 },{ label: "Feb", y: 13 },]
			},
    ]
		}
		const stats = useSelector(state=> state.user.myStats.data)
		const [bookingsData, setBookingsData ] = useState(bookingsInitial)
		// const [dateOption, setDateOption] = useState("day") // day, week or month
		useEffect(()=>{
			let unsold = []
			let sold=[]
			if (stats && stats.booking){
			let reversedBookings = stats.booking.bookings.reverse()
					reversedBookings.forEach((booking)=>
					{
						unsold.push({y:booking.unsold_tickets, label:formatDate(booking.date)})
						sold.push({y:booking.sold_tickets, label:formatDate(booking.date)})
					})

					setBookingsData({...bookingsData, data:[
			{
				legendMarkerType: "circle",type: "stackedColumn",name: "sold tickets",color: '#FF5A60',
				showInLegend: true,yValueFormatString: "#,###",
				dataPoints: sold
			},
{legendMarkerType: "circle",type: "stackedColumn",name: "unsold tickets",showInLegend: true,color: '#E2E7ED',
				yValueFormatString: "#,###",
				dataPoints: unsold
			},
						
					]})
			}

		},[stats])
	// constructor() {
	// 	super();
	// 	this.toggleDataSeries = this.toggleDataSeries.bind(this);
	// }
	// toggleDataSeries(e){
	// render() {
		//https://canvasjs.com/docs/charts/chart-options/data/color/
		return (
		<div className="profile_stats" id="line-stats">
			<h1 className="stats_title">Bookings</h1>
			{/* <select id="stats_live" className="profile_stats_select">
				<option value="seven_days">Last 12 Months</option>
				<option value="month">Last 6 Months</option>
				<option value="six_month">Last Month</option>
				<option value="year">Last 7 Days</option>
			</select> */}
			<CanvasJSChart options = {bookingsData} 
				// onRef={ref => this.chart = ref}
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	
}

export default StackedColumnChart;


function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear().toString().slice(2);

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [ day, month, year].join('-');
}