import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import "./style.scss";
import user2 from "assets/img/icons/ic-user.svg";
import user3 from "assets/img/icons/ic-edit.svg";
import user4 from "assets/img/icons/ic-billing.svg";
import user5 from "assets/img/icons/ic-settings.svg";
import user6 from "assets/img/icons/ic-help.svg";
import user7 from "assets/img/icons/ic-paymentmethod.svg";
import { isMobile } from "react-device-detect";

import { connect } from "react-redux";
import { hideNotReadyContent, INFLUENCER_ONLY_MODE, HIDE_MOBILE_LOGIN , IS_DEV_ENVIRONMENT} from '../../../environment/environment'
import * as actions from "../../../store/actions";
import i18next from 'i18next';

const visible = true;//!hideNotReadyContent;

const MenuLogin = ({ history, user, loadingUserInfo, influencer, isInfluencer, onLogoutUser, mobileNav , referral}) => {

  console.log(user.influencer_request);
  const logout = e => {
    e.preventDefault();
    onLogoutUser()
    const ref = window.location.href;
    if (ref.charAt(ref.length - 1) === "/") {
      window.location.reload();
    } else {
      history.push("/");

    }
  };
  const handleLogOut = () => {
    localStorage.clear();
    const ref = window.location.href;
    if (ref.charAt(ref.length - 1) == "/") {
      window.location.reload();
    } else {
      history.push("/");
    }

  }

  const showProcessingInfluenerRequestButton = user => {
    return user.influencer_request && !user.influencer_request.influencer_request_authorized && user.user_role == 0 ? true : false;
  }

  const showBecomeInfluencerButton = user => {
    return user.user_role == 0 && user.influencer_request == null ? true : false;
  }

  const showGoToInfluencerModeButton = user => {
    return user.user_role == 1;
  }

  const renderGoToInfluencerModeButton = () => {


    if (true) {
      return (
        <div className="d-flex justify-content-center align-items-center mt-3">
          {showBecomeInfluencerButton(user) ? <button
            className="Btn-rojo-join boton_go"
            data-toggle="modal"
            data-target="#myModal_verify_influencer">
              {i18next.t("LoginMenuComponentBecomeInfluencer")}
            </button> : null
          }

          {
            showProcessingInfluenerRequestButton(user) ? <button
              className="Btn-rojo-join boton_go">
              {i18next.t("LoginMenuComponentProcessingInfluencerRequest")}
            </button> : null
          }

          {showGoToInfluencerModeButton(user) ?
            <Link to={user.user_role === 1 ? `/my_live_events` : "/new_influencer_profile"}>
              <button

                className="Btn-rojo boton_go"
              >
                {i18next.t("LoginMenuComponentGoToInfluencerMode")}
            </button>
            </Link> : null
          }

        </div>
      );
    } else {
      return null;
    }
  }

  const env = JSON.parse(localStorage.getItem("env") || "{}");
  return (Object.keys(user).length > 0 || env.token) ? (
    <div className="dropdown">
      <a className="dropdown-toggle" data-toggle="dropdown">
        <div style={{ display: "flex", alignItems: "center" }} className="Logued-div">
          <div>
            <span className="Name">{(user.user_name !== '') ? user.user_name : user.user_username}</span>

            <span className="Mode">{i18next.t("LoginMenuComponentViewerMode")}</span>
          </div>
          <img src={user.user_avatar} alt="" />
        </div>
      </a>
      <div className="dropdown-menu profile">
        <Link to={`/profile`}>
          <div className="dropdown-item mt-3">
            <img src={user2} alt=""></img> {i18next.t("LoginMenuComponentMyViewerProfile")}
          </div>
        </Link>
        <Link to={`/viewer_profile`}>
          {" "}
          <div className="dropdown-item">
            <img src={user3} alt=""></img>{i18next.t("LoginMenuComponentEditProfile")}
          </div>
        </Link>
        <Link to={`/billing_viewer`}>
          <div className="dropdown-item">
            <img src={user4} alt=""></img>{i18next.t("LoginMenuComponentBilling")}
          </div>
        </Link> 
        {true && <Link to={`/my_viewer_payment_methods`}>
          <div className="dropdown-item">
            <img src={user7} alt=""></img>{i18next.t("LoginMenuComponentPaymentMethods")}
          </div>
        </Link> 
}
        {(false && visible)  ? <a className="dropdown-item" href="#">
          <img src={user5} alt=""></img>{i18next.t("LoginMenuComponentSettings")}
        </a> : null}

        <a className="dropdown-item" href="https://support.gotmy.com/hc/en-us" target="_blank" >
          <img src={user6} alt=""></img>{i18next.t("LoginMenuComponentHelp")}
                </a>
        {renderGoToInfluencerModeButton()}
        <div className="div_logout">
          <a className="LogOut" onClick={logout}>
          {i18next.t("LoginMenuComponentLogout")}
          </a>
        </div>
      </div>
    </div>
  ) : (
      (!loadingUserInfo && !INFLUENCER_ONLY_MODE && !(isMobile && HIDE_MOBILE_LOGIN) && (referral!==true)) ? (
        <button style={{}}
          className="Btn-rojo-join"
          data-toggle="modal"
          data-target="#myModal_login"
        >
          {!mobileNav ? i18next.t("LoginMenuComponentMobileLogin") : i18next.t("LoginMenuComponentMobileSignup")}
        </button>
      ) : (!loadingUserInfo && INFLUENCER_ONLY_MODE) ? (
        <button style={{}}
          className="Btn-rojo-join"
          data-toggle="modal"
          data-target="#myModal_login"
        >
          {i18next.t("LoginMenuComponentLoginAsInfluencer")}
        </button>
      )
        :
        
        referral && 
        <button style={{}}
          className="Btn-rojo-join"
          data-toggle="modal"
          data-target="#myModal_sign"
        >
          {referral && "Become a gotmyer!"}
        </button>
  )
  
};

const mapDispatchToProps = dispatch => {
  return {
    onLogoutUser: () => dispatch(actions.logoutUser()),
  };
};
const mapStateToProps = state => {
  return {
    user: state.user.user,
    loadingUserInfo: state.user.loading,
    influencer: state.user.influencer,
    isInfluencer: state.user.isInfluencer
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuLogin));
