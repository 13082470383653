import React from "react";
import "./style.scss";
import Camera from "../../comunes/camera";
import styled from 'styled-components'
function PitchEvent({ onChange, previousBlob }) {
  return (
    <div>
      <PitchContainer>
        <div className="mb-4">
        <div className="markdown">

          <h2 className="mb-2 ">Pitch video promotion</h2>
          <p>
            A pitch is a form of words used when trying to persuade someone to
            buy or accept something. It can be in the form of a video, speech,
            slide deck, or even a written essay...
          </p>
          </div>
        </div>
        <Camera onChange={onChange} previousBlob={previousBlob} />
      </PitchContainer>
 
    </div>
  );
}

export default PitchEvent;

const PitchContainer = styled.div`
margin-bottom:20px;
padding-top:12rem;
min-width:600px;
max-width:800px;
margin:auto;
text-align:left;
.markdown {
  max-width:700px;
}
p{ 
 color: #7a7883;
}
h2{
  font-size:26px;
}
`