import { useState, useEffect } from "react";
import { UserDAO } from "../../../../../daos";
import { useDispatch} from 'react-redux'
import * as actions from "../../../../../store/actions";
import * as yup from "yup";
import moment from "moment";
import i18next from 'i18next';

const dateLimit = moment(new Date()).subtract(16, "y").format("YYYY-MM-DD");

const initialFormValues = {
  user_name: "",
  user_lastname: "",
  user_birthdate: new Date(dateLimit),
  user_username: ""
};

let schema = null;


const userDAO = new UserDAO();

/**
 * Logic of ModalBasicInfo=
 * @returns {Object}
 */
export const useModalBasicInfoLogic = (user) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errorForm, setErrorForm] = useState("");
  const [token, setToken] = useState('')
  const [showAlertGoodCredentials, setShowAlertGoodCredentials] = useState(
    false
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    schema = yup.object().shape({
      user_name: yup
        .string()
        .trim()
        .required(i18next.t("ModalLoginLogicComponentNameIsRequired")),
      user_lastname: yup
        .string()
        .trim()
        .required(i18next.t("ModalLoginLogicComponentSurnameRequired")),
      user_birthdate: yup
        .date()
        .required(i18next.t("ModalLoginLogicComponentBirthDateRequired")),
      user_username: yup
        .string()
        .trim()
        .required(i18next.t("ModalLoginLogicComponentUsernameRequired"))
    });
  }, [])


  useEffect(() => {
    if (Object.keys(user).length > 0) {
      if (!user.user_name || !user.user_lastname || !user.user_birthdate) {
        
        const autoUpgrade= JSON.parse( localStorage.getItem("autoUpgrade"))

        if (autoUpgrade && autoUpgrade.status===true){

          console.log("AUTOUPGRADE!!!")
          // localStorage.setItem("autoUpgrade", JSON.stringify({status:false}))
          setUserName(user);

        }
        else{

          window.$("#myModal_basic_info").modal("show");
          setUserName(user);
          getUserToken()
        }

      }
    }   

  }, [user]);

  // /*  saves token in local state and removes
  //    local storage so that the user is not logged in if user exits modal */
  const getUserToken = ()=>{
    var env_ls = JSON.parse(localStorage.getItem('env'))
      if (env_ls) {
      setToken(env_ls.token)
      }
      // // localStorage.clear();
      // localStorage.removeItem('env')



      // log out the user on redux (will be logged in when user submits form)
      // dispatch(actions.logoutUser())
  }

  function setUserName(user) {
    const updatedValues = JSON.parse(JSON.stringify(formValues));
    updatedValues["user_username"] = user.user_username;
    setFormValues(updatedValues);
  }

  /**
   * Handle form changes.
   * @param {Object} event, Event trigerred for the input form
   */
  const onChangeFormValues = event => {
    const updatedValues = JSON.parse(JSON.stringify(formValues));
    const { name, value } = event.target;
    updatedValues[name] = value;
    setFormValues(updatedValues);
  };

  const onChangeDatePickerValues = date => {
    const updatedValues = JSON.parse(JSON.stringify(formValues));
    updatedValues["user_birthdate"] = date;
    setFormValues(updatedValues);
  }


  const onSubmit = async event => {
    
    // Remove localStorage autoUpgrade so that in subsequent logins influencers are asked to fill in their bio
    localStorage.removeItem("autoUpgrade")

    if (loading) return;
    event.preventDefault();
    try {
      await schema.validate(formValues);
    } catch (err) {
      setErrorForm(err.errors[0]);
      return;
    }

    setLoading(true);

    try {
      
      const data = formValues;
      var dataCopy = Object.assign({}, data);
      dataCopy.user_name = dataCopy.user_name.trim();
      dataCopy.user_lastname = dataCopy.user_lastname.trim();
      dataCopy.user_username = dataCopy.user_username.trim();
      dataCopy.user_birthdate = new Date(dataCopy.user_birthdate).getTime();
      var env_ls = JSON.parse(localStorage.getItem('env'))

      // await userDAO.update(token, dataCopy);
      await userDAO.update(env_ls.token, dataCopy);
      await dispatch(actions.getUser(env_ls.token))
      window.$("#myModal_sign").modal("hide");
      window.$("#myModal_sign").modal("hide");
      window.$("#myModal_login").modal("hide");
      window.$("#myModal_basic_info").modal("hide");
      localStorage.setItem("env", JSON.stringify({token}));

      setFormValues(initialFormValues);
    } catch (err) {
      if (err === undefined) {
        setErrorForm(i18next.t("ModalLoginLogicComponentSomethingWentWrong"));
        return
      }
      if (err.response.status === 400) {
        setErrorForm(
          err.response.data.error.map(item => item.message).join(", ")
        );
      } else {
        setErrorForm(i18next.t("ModalLoginLogicComponentSomethingWentWrong"));
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    formValues,
    onChangeFormValues,
    onChangeDatePickerValues,
    onSubmit,
    errorForm,
    setErrorForm,
    showAlertGoodCredentials,
    setShowAlertGoodCredentials,
    loading
  };
};
