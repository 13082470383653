import { useEffect, useState, useCallback } from "react";

const useInfluencerBalanceLogic = ({user}) => {

  const [resume, setResume] = useState(null);
  const [transactions, setTransactions] = useState(null);

  useEffect(() => {
    if (user.myStats && user.myStats.data && user.myStats.data.transactions && user.myStats.data.transactions.transactions_resume) {
        setResume(user.myStats.data.transactions.transactions_resume)
    }

    if (user.myStats && user.myStats.data && user.myStats.data.transactions && user.myStats.data.transactions.transactions) {
        setTransactions(user.myStats.data.transactions.transactions)
    }
  }, [user]);

  return { resume, transactions};

};

export default useInfluencerBalanceLogic