import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import useScreenSize from "../../../../hooks/useScreenSize";
import i18next from "i18next";
import { Modal } from "react-bootstrap";

const SetDefaultCoverImages = ({
  handlePhoto,
  data,
  setModalVisibility,
  modalVisibility,
}) => {
  const { screenSize } = useScreenSize();
  const [currentPhoto, setCurrentPhoto] = useState(0);

  const renderCarousel = () => {
    if (!data) {
      return;
    }

    return (
      <Carousel
        showThumbs={true}
        autoPlay={false}
        interval={1e11}
        onClickItem={(index) => {
          setCurrentPhoto(index);
        }}
        onChange={(index) => {
          setCurrentPhoto(index);
        }}
      >
        {data.map((imageSRC, index) => (
          <div>
            <img onClick={() => handlePhoto(index)} src={imageSRC} alt="" />
          </div>
        ))}
      </Carousel>
    );
  };
  const renderImagesContainer = () => {
    if (!data) {
      return;
    }

    return data.map((imageSRC, index) => (
      <img onClick={() => handlePhoto(index)} src={imageSRC} alt="" />
    ));
  };

  return (
    <div className={"DefaultImageContainer d-flex justify-content-center"}>
      <h2>{i18next.t("LiveEventInformationUseOurThumbnail")}</h2>
      <button
        type="button"
        className="mt-3 gotmyButton grow"
        onClick={() => setModalVisibility(true)}
      >
        {i18next.t("LiveEventInformationUseOurThumbnailSelect")}
      </button>

      <Modal show={modalVisibility} onHide={() => setModalVisibility(false)}>
        <Modal.Header closeButton>
          {i18next.t("LiveEventInformationUseOurThumbnailSelect")}
        </Modal.Header>
        <Modal.Body className={"ImageContainerModalBody"}>
          <div className={"ImageContainer"}>{renderCarousel()}</div>
          <button
            type="button"
            className="mt-3 gotmyButton grow"
            onClick={() => {
              console.log("Selecting new current photo", currentPhoto)
              handlePhoto(currentPhoto);
              setModalVisibility(false);
            }}
          >
            {i18next.t(
              "LiveEventInformationUseOurThumbnailSelectImageThumbnail"
            )}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default SetDefaultCoverImages;
