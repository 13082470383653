import React from 'react'
import { Modal } from "react-bootstrap";
import logob from '../../assets/img/gotmy-n.png';
import { Link } from "react-router-dom";
import i18next from 'i18next';
import { B2B_PORT } from '../../environment/environment';

const ModalSuccess = ({ email, isUserLoggedIn, event_thanks_video, setModalPurchaseSuccess, modalPurchaseSuccess }) => {
  return (
    <Modal show={modalPurchaseSuccess} onHide={() => setModalPurchaseSuccess(false)}
    >

      <Modal.Body>
        <div className="text-center d-flex flex-column justify-content-center align-items-center">
          <img src={logob} className=" Logo" alt="logo" />
          {event_thanks_video && (
            <video
              style={{ height: '380px', backgroundColor: 'black', objectFit: 'contain' }}
              width="320"
              height="340"
              className="Video mt-4 br-10 mb-4"
              controls={false}
              autoPlay
            >
              <source src={event_thanks_video} type="video/mp4"></source>
            </video>
          )}
          {isUserLoggedIn || B2B_PORT ?
            <>
              <p className="Title2 mb-1">{i18next.t("ModalSuccessEventBoughtComponentYouAreAmazing")}</p>
              <p className="Title2 mb-3">
              {i18next.t("ModalSuccessEventBoughtComponentThankYouForBooking")}
            </p>
            </>
            :
            <>
              <p className="Title2 mb-1">{i18next.t("ModalSuccessEventBoughtComponentYouAreAmazing")}</p>
              <p className="Title2 mb-3">
              {i18next.t("ModalSuccessEventBoughtComponentDownloadAppMessage")}
              </p>
            </>
          }

          {B2B_PORT ? null : isUserLoggedIn ?
            <Link  style={{color:'#ff5a60'}} to="/live_events">{i18next.t("ModalSuccessEventBoughtComponentGoToMyLiveEvents")}</Link>
             :
            <Link className="Go" to="/">{i18next.t("ModalSuccessEventBoughtComponentDiscoverMoreEvents")}</Link>
          }
         
          <p style={{fontSize:'14px'}} className="mt-2">
          {email && email.length>0 &&  <span style={{fontSize:'14px'}} className="mt-2">{i18next.t("ModalSuccessEventBoughtComponentWeSentConfirmationEmailTo")} {email}.  </span>}
            {" "}{i18next.t("ModalSuccessEventBoughtComponentQuestionsRegardingYourEvent")} <a style={{color:'#ff5a60'}} href ={B2B_PORT ?"mailto: support@accesoevento.com" : "mailto: support@gotmy.com"} >{B2B_PORT ? "support@accesoevento.com": "support@gotmy.com" }</a></p>

        </div>
      </Modal.Body>
    </Modal>
  )
}
export default ModalSuccess