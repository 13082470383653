import React, { useState, useEffect } from "react";
import user from "assets/img/icons/ic-user.svg";
import user2 from "assets/img/icons/ic-raisehand.svg";
import user3 from "assets/img/icons/ic-price.svg";
import user5 from "assets/img/icons/ic-descuento.svg";
import useMobileNav from "../../hooks/useMobileNav";
import { parsePriceByCurrencyJustSymbol } from "../../utils/currencyUtils";
import i18next from "i18next";
import { PRIVATE_MARKETPLACE } from "../../environment/host-config/active";
import { SUPPORT_EMAIL } from "../../environment/environment";

const PrivateInfo = ({
  currency,
  upcoming,
  ticket,
  ticket_streaming,
  event_num_attendees,
  event_num_attendees_streaming,
  event_price_ticket,
  event_price_ticket_streaming,
  event_promotional_code_discount,
}) => {
  const [grossRev, setGrossRev] = useState();
  const [fee, setFee] = useState();
  const { mobileNav } = useMobileNav();

  useEffect(() => {
    //TODO when a ticket type is disabled this can not be calculated.

    if (ticket) {
      setGrossRev(event_num_attendees * event_price_ticket);

      setFee(
        (
          ticket.gotmy_min_ticket_fee +
          ticket.gotmy_ticket_fee * 0.01 * event_price_ticket
        ).toFixed(2)
      );
    } else if (ticket_streaming) {
      setGrossRev(event_num_attendees_streaming * event_price_ticket_streaming);

      setFee(
        (
          ticket_streaming.gotmy_min_ticket_fee +
          ticket_streaming.gotmy_ticket_fee *
            0.01 *
            event_price_ticket_streaming
        ).toFixed(2)
      );
    }
  }, [event_price_ticket, ticket, ticket_streaming]);
  return (
    <div className="mt-5 privateInfoContainer">
      <div
        style={{ flexDirection: mobileNav && "column" }}
        className=" mt-4 d-flex div_private_information"
      >
        {!PRIVATE_MARKETPLACE && (<div className="mt-4">
          <p className="information">
            {i18next.t("PrivateInfoComponentPrivateInformation")}
          </p>
          <div className="d-flex information_detalles">
            <img src={user} alt="" />
            <div>
              <p className="m-0">
                {i18next.t("PrivateInfoComponentPricePerTicket")}
              </p>
              <p className="price_information">
                {event_price_ticket
                  ? parsePriceByCurrencyJustSymbol(event_price_ticket, currency)
                  : parsePriceByCurrencyJustSymbol(
                      event_price_ticket_streaming,
                      currency
                    )}
              </p>
            </div>
          </div>
          <div className="d-flex information_detalles">
            <img src={user2} alt="" />
            <div>
              <p className="m-0">
                {i18next.t("PrivateInfoComponentNumberOfTickets")}
              </p>
              <p className="price_information">
                {event_num_attendees !== null
                  ? event_num_attendees
                  : event_num_attendees_streaming}
              </p>
            </div>
          </div>
          <div className="d-flex information_detalles">
            <img src={user3} alt="" />
            <div>
              <p className="m-0">
                {i18next.t("PrivateInfoComponentGrossRevenue")}
              </p>
              <p className="price_information">$ {grossRev}</p>
            </div>
          </div>
          <div className="d-flex information_detalles">
            <img src={user} alt="" />
            <div>
              <p className="m-0">
                {i18next.t("PrivateInfoComponentTicketingFees")}
              </p>
              <p className="price_information">$ {fee}</p>
            </div>
          </div>
          <div className="d-flex information_detalles">
            <img src={user5} alt="" />
            <div>
              <p className="m-0">
                {i18next.t("PrivateInfoComponentDiscountCode")}
              </p>
              <p className="price_information">
                {" "}
                {event_promotional_code_discount}%
              </p>
            </div>
          </div>
        </div>)}
        <div className="politic_div mt-4">
          <h4>{i18next.t("PrivateInfoComponentReschedulingPolicy")}</h4>
          <p className="parrafo_politicas">
            {i18next.t("PrivateInfoComponentReschedulingPolicyDescription")}
          </p>
          <h4>{i18next.t("PrivateInfoComponentCancellationPolicy")}</h4>
          <p className="parrafo_politicas">
            {i18next.t("PrivateInfoComponentCancellationPolicyDescription")}
          </p>
          <h4>{i18next.t("PrivateInfoComponentInfluencerDelay")}</h4>
          <p className="parrafo_politicas">
            {i18next.t("PrivateInfoComponentInfluencerDelayDescription")}
          </p>
          {upcoming && (
            <span
              className="request-streaming cancelEvent"
              style={{ padding: "10px 20px" }}
              id="cancel_live_event"
            >
              <a
                style={{ color: "white", padding: "10px" }}
                href={"mailto:"+ SUPPORT_EMAIL +"?subject=Event cancellation"}
              >
                {i18next.t("PrivateInfoComponentCancelLiveEvent")}
              </a>
            </span>
          )}
          {/* <button
              className="request-streaming send_request"
              id="edit_live_event"
            >
              Edit Live Event
            </button> */}
        </div>
      </div>
    </div>
  );
};
export default PrivateInfo;
