export default {
  CHAT: "update",
  STREAMING_FINISHED: "streamingFinished",
  MESSAGE_REMOVED: "update",
  RISE_HAND_UP: "update",
  RISE_HAND_DOWN: "update",
  VIDEO_STREAMING_REVOKED: "update",
  VIDEO_STREAMING_GRANTED: "update",
  AUDIO_STREAMING_GRANTED: "update",
  BROADCAST_AUDIO_STREAMING_GRANTED: "update",
  BROADCAST_AUDIO_STREAMING_REVOKED: "update",
  CHAT_PERMISION_REVOKED_FOR: "update",
  MODERATION_PERMISSION_GRANTED: "update",
  MODERATION_PERMISSION_REVOKED: "update",
  KICKED_OUT: "update",
  ENABLE_RISE_HAND_UP: "update",
  DISABLE_RISE_HAND_UP: "update",
  UPDATE_SESSION_DATA: "update"
};

/**
 * Para el parsear las señales de WEBRTC  para los mensajes del streaming usaremos el tipo: ‘chat’. Se enviara un campo username con el username del usuario, Avatar con la URL de su Avatar, message con el mensaje completo, un uuid del mensaje (generado aleatoriamente) y influencerMode (bool) que indique si el usuario es el influencer o no.
Para parsear la señal de WEBRTC para el evento de que el influencer va a cerrar el streaming usaremos el tipo: ‘streamingFinished’
Para parsear la señal de WEBRTC para el evento de activar pedir turno de proyectar el vídeo del viewer Durante el vídeo usaremos el tipo: ‘enableRiseHandUp’.
Para parsear la señal de WEBRTC para el evento de desactivar pedir turno de proyectar el vídeo del viewer Durante el vídeo usaremos el tipo: ‘disableRiseHandUp’.
Para parsear la señal de WEBRTC para el evento de pedir turno de proyectar el vídeo del viewer Durante el vídeo usaremos el tipo: ‘riseHandUp’.
Para parsear la señal de WEBRTC para el evento de cancelar pedir turno de proyectar el vídeo del viewer durante el vídeo usaremos el tipo: ‘riseHandDown’.
Para parsear la señal de WEBRTC para el evento de permitir el turno de proyectar el vídeo del viewer Durante el vídeo (audio incluido) usaremos el tipo: ‘videoStreamingGranted’, enviando el username del viewer que tiene el permiso concedido. Con ello, si había previamente algún otro viewer emitiendo cancelará su video al ser otro username el que contiene el mensaje.
Para parsear la señal de WEBRTC para el evento de permitir el turno de usar el micro del viewer Durante el vídeo usaremos el tipo: ‘audioStreamingGranted’, enviando el username del viewer que tiene el permiso concedido. Con ello, si había previamente algún otro viewer emitiendo cancelará su audio al ser otro username el que contiene el mensaje.
Para parsear la señal de WEBRTC para el evento de permitir usar el micro a todos los viewer Durante el vídeo usaremos el tipo: ‘broadcastAudioStreamingGranted’. Con ello, se habilitará el micro de todos los viewers.
Para parsear la señal de WEBRTC para el evento de bloquear usar el micro a todos los viewer Durante el vídeo usaremos el tipo: ‘broadcastAudioStreamingRevoked’. Con ello, se deshabilitará el micro de todos los viewers.
Para parsear la señal de WEBRTC para el evento de bloquear el chat a un viewer Durante el vídeo por X segundos usaremos el tipo: ‘chatPermissionRevokedFor’. Se enviará un campo penalty: X indicando el número de segundos durante los que el user no puede escribir en el chat y el username del user. Hará falta una llamada a la api para guardar en la BBDD las penalizaciones.
Para parsear la señal de WEBRTC para el evento moderar un mensaje (eliminarlo de todos los chats) usaremos el tipo: ‘messageRemovedFromChat’. Se hará una petición a la api con el identificador del mensaje para eliminarlo y junto con el token del usuario.
Para parsear la señal de WEBRTC para  seleccionar un moderador usaremos el tipo: ‘moderationPermissionGranted’. Se hará una petición a la api con el identificador del usuario para hacerlo moderador y junto con el token del influencer.
Para parsear la señal de WEBRTC para quitar un moderador usaremos el tipo: ‘moderationPermissionRevoked’. Se hará una petición a la api con el identificador del usuario para hacerlo moderador y junto con el token del influencer.
Para parsear la señal de WEBRTC para expulsar a un usuario usaremos: ‘kickedOut’. Previamente se hará una petición a la api con el identificador del usuario y la penalización que se le va a aplicar junto con el token del moderador/influencer. Si se reescribe una penalización se eliminará la que ya estaba aplicada anteriormente. TODOS LOS VIEWER DEBEN CONSULTAR SI TIENEN ALGUNA PENALIZACIÓN HACIENDO UNA CONSULTA A LA API ANTES DE CONECTAR EL STREAM.
 */
