import React from "react";
import "./style.scss";
import LiveEventInformation from "./live_event_information/LiveEventInformation";
import PitchEvent from "./pitch_event";
import ThankYou from "./thank_you_video";
import { connect } from "react-redux";
import { usePublishLiveEventLogic } from "./PublishLiveEventLogic";
import Navbar from "./navbar_live_publish";
import SweetAlert from "sweetalert2-react";
import Sad from "assets/img/icons/sad-face-in-rounded-square.svg";
import { withRouter } from "react-router-dom";
import { FRESHCHAT_ID } from "../../environment/environment";
import Menu from "../comunes/navbar_publish";

const LiveEventsPagePublish = ({
  categories,
  languages,
  user,
  history,
  match,
  location,
}) => {
  const { id, ide } = match.params;
  const {
    massiveBroadcast,
    setMassiveBroadcast,
    errorForm,
    setErrorForm,
    openTicket,
    setOpenTicket,
    publicEvent,
    setPublicEvent,
    eventValues,
    onChangeEventValues,
    setEventValues,
    handleImageChange,
    loading,
    loadingImage,
    loadedImg,
    handleImageLoad,
    onSubmit,
    initiaLoading,
    onSaveInDraft,
    handlePitchVideoChange,
    handleThanksVideoChange,
    refPresentationVideo,
    refThanksVideo,
    ticketTypes,
    loadTicketTypes,
    onChangeUserEmail,
    onChangeConfirmUserEmail,
    userEmail,
    confirmUserEmail,
    initDefaultImage,
    defaultImage
  } = usePublishLiveEventLogic({ categories, languages, user, history, id });
  const { pathname } = location;

  return (
    <div className="Live-Events-Page">
      <div
        style={{ display: pathname === "/publish_event" ? "block" : "none" }}
      >
        <Navbar submit={onSubmit} onSave={onSaveInDraft} loading={loading} />
        <LiveEventInformation
          massiveBroadcast={massiveBroadcast}
          setMassiveBroadcast={setMassiveBroadcast}
          openTicket={openTicket}
          setOpenTicket={setOpenTicket}
          publicEvent={publicEvent}
          setPublicEvent={setPublicEvent}
          loadTicketTypes={loadTicketTypes}
          handleImageLoad={handleImageLoad}
          eventValues={eventValues}
          categories={categories}
          errorForm={errorForm}
          setErrorForm={setErrorForm}
          onChangeEventValues={onChangeEventValues}
          setEventValues={setEventValues}
          handleImageChange={handleImageChange}
          loading={loading}
          loadedImg={loadedImg}
          loadingImage={loadingImage}
          onSubmit={onSubmit}
          initiaLoading={initiaLoading}
          onSaveInDraft={onSaveInDraft}
          languages={languages}
          ticketTypes={ticketTypes}
          user={user}
          onChangeUserEmail={onChangeUserEmail}
          submit={onSubmit}
          saveInDraft={onSaveInDraft}
          onChangeConfirmUserEmail={onChangeConfirmUserEmail}
          userEmail={userEmail}
          confirmUserEmail={confirmUserEmail}
          initDefaultImage={initDefaultImage}
          defaultImage={defaultImage}
        />
      </div>
      <div
        style={{
          display: pathname === "/publish_event/pitch_video" ? "block" : "none",
        }}
      >
        <Navbar submit={onSubmit} onSave={onSaveInDraft} loading={loading} />
        <PitchEvent
          onChange={handlePitchVideoChange}
          previousBlob={refPresentationVideo}
        />
      </div>
      <div
        style={{
          display: pathname === "/publish_event/thank_you" ? "block" : "none",
        }}
      >
        <Navbar submit={onSubmit} onSave={onSaveInDraft} loading={loading} />
        <ThankYou
          onChange={handleThanksVideoChange}
          previousBlob={refThanksVideo}
        />
      </div>

      <SweetAlert
        imageUrl={Sad}
        show={errorForm}
        title={errorForm}
        onConfirm={() => setErrorForm("")}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    categories: state.categories.categories,
    languages: state.languages.languages,
  };
};

export default withRouter(connect(mapStateToProps)(LiveEventsPagePublish));
