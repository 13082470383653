import React from "react";
import circulo from "assets/img/logo-circulo.png";
import Avatar from '../../../comunes/CardUtils/Avatar'
import cinco_estrellas from "assets/img/5-estrellas.png";
import map from "assets/img/icons/ic-map.svg";
import per from "assets/img/icons/ic-user.svg";
import { Link } from "react-router-dom";
import "./style.scss"; 
import PropTypes from "prop-types";
import moment from "moment";
import Loader from "components/comunes/loader";
import FavoriteIcon from "../../FavoriteIcon/FavoriteIcon";
import { connect } from "react-redux";
import i18next from 'i18next';
import { SHOW_DEFAULT_ICONS } from "../../../../environment/environment";

const CardUpcoming = ({ event, loading, uniqueEvent, purchasedEvents }) => {
  const {
    event_title,
    event_category,
    event_uuid,
    event_date,
    event_start_hour,
    event_cover_image,
    event_num_attendees,
    event_num_tickets_free,
    is_favorite
  } = event.event;
  const { user_username, user_avatar } = event.user;

  const linkTo =
    Array.isArray(purchasedEvents) &&
    purchasedEvents.find(item => item.event.event_uuid === event_uuid)
      ? `/event/${event_uuid}`
      : `/event/${event_uuid}`;

  return (
    <div className="CardUpcoming col-12 col-lg-4 col-md-6 col-sm-12 mb-5 grow">
      <div style={{borderRadius:'7px'}} className="Shadow-lg">
        {!loading ? (
          <>
            <div
              className="Header"
              style={{
                background: `linear-gradient(
                rgba(17, 16, 22, 0.0),
                rgba(17, 16, 22, 0.0)), url(${event_cover_image})`,
                backgroundSize: "cover"
              }}
            >
              <div className="cursor-pointer">
              <FavoriteIcon isFavoriteInitial={is_favorite} uuid={event_uuid} />

              </div>
              <Link to={`influencer/${user_username}`}>
              <div className="Negro">
                
                <Avatar user_avatar={user_avatar}/>
                <div>
                  <span className="Nombre">{user_username}</span>
                  <div className="Estrellas">
                    {/* <img src={cinco_estrellas} alt="puntuacion" /> */}
                  </div>
                </div>
                <div className="Div-asis">
                  <span className="Asistentes">
                    {SHOW_DEFAULT_ICONS && (<img src={per} alt="" />)}
                    {event_num_attendees}
                  </span>
                  <span className="Tickets">
                    {i18next.t('CardUpcomingComponentOnly')} {event_num_tickets_free} {i18next.t('CardUpcomingComponentTicketsFree')}
                  </span>
                </div>
              </div>
              </Link>
            </div>
            <Link to={linkTo}>
              <div className="Footer">
                <div className="Fecha">
                  <span className="Dia">
                    {moment(parseInt(event_date)).format("DD")}
                  </span>
                  <span className="Mes">
                    {moment(parseInt(event_date))
                      .format("MMMM")
                      .split("")
                      .slice(0, 3)
                      .join("")}
                  </span>
                </div>
                <div className="Texto">
                  <span className="Tit">{event_title}</span>
                  <span className="Cat">
                    {event_category && event_category.cate_description}
                  </span>
                  <span className="Hor">
                    {" "}
                    {SHOW_DEFAULT_ICONS && (<img src={map} alt="" />)}
                    {i18next.t('CardUpcomingComponentLiveFrom')} TODO {i18next.t('CardUpcomingComponentLiveAt')} TODO
                    Live From New York, at 18:30 pm
                  </span>
                </div>
              </div>
            </Link>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

CardUpcoming.propTypes = {
  event: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    purchasedEvents: state.user.user.purchasedEvents
  };
};

export default connect(mapStateToProps)(CardUpcoming);
