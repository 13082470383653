import React from 'react'

const NewInfluencerEmail = ({onChangeConfirmUserEmail, onChangeUserEmail, userEmail, confirmUserEmail}) => {
    return (
        <div className="email_publish mt-5 mt-md-0">
            <h2 className="mt-0 mb-3">
                Email
        </h2>
            <form className="">
                <div className=" mt-4">
                    <p className="mb-1 small_grey">
                        Your email
            </p>
                    <input className="input_title_video" type="email" name="user_email"
                        onChange={onChangeUserEmail} value={userEmail} placeholder="Your email" />
                </div>
                <div className=" mt-4">
                    <p className="mb-1 small_grey">
                        Confirm your email
            </p>
                    <input className="input_title_video" type="email" name="confirm_user_email"
                        onChange={onChangeConfirmUserEmail} value={confirmUserEmail} placeholder="Confirm your email" />
                </div>
            </form>
        </div>
    )
}

export default NewInfluencerEmail