import React from 'react'
import { Modal } from "react-bootstrap";
import logob from '../../assets/img/gotmy-n.png';
import i18next from 'i18next';
import streamingIcon from '../../assets/img/rtmp.png';
import interactiveIcon from '../../assets/img/webrtc.png';

const ModalEventInfo = ({ modalEventInfo, setModalEventInfo, disabledMassive=true , massiveBroadcast}) => {
  return (
    <Modal show={modalEventInfo} onHide={() => setModalEventInfo(false)}
    >

      <Modal.Body>
        <div className="text-center d-flex flex-column justify-content-center align-items-center">
          <img src={logob} className=" Logo" alt="logo" />
          <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <h4 > Ofrecemos dos modalidades de evento: Interactivo y Masivo </h4>


            <div style={{display:"flex", flexDirection:"column" , justifyContent:"space-around", width:"100%", marginTop:14, marginBottom:14}}>

                <div style={{padding: 10, marginBottom:12, border: "solid", borderRadius:6, borderColor:"#D8DCE5",  borderWidth:0.5}}>
                   <div style={{display:"flex",flexShrink:0,   justifyContent:"space-between", alignItems:"start"}}>

                    <div style={{ display:"flex", alignSelf:"center", justifyContent: "center", alignItems:"center", 
                    height:80, width:"80px !important", flexShrink:0,flexGrow:0, flexBasis:"80px", borderRadius:9999, backgroundColor:"#D8DCE5"}}>
                        <img src={interactiveIcon} style={{height:50, width:50}}/>
                    </div>
                    
                    <div style={{textAlign:'center', justifySelf:"center"}}>
                    <h5 style={{textAlign:'center', marginBottom:12, fontWeight:800}}>{i18next.t("ModalEventInfoInteractive")}</h5> 

                    <p style={{fontSize:14, maxWidth:400}}>{i18next.t("ModalEventInfoInteractiveExplain")}</p>

                    </div>
                   </div>
                </div>
                <div style={{padding: 10, marginBottom:12, border: "solid", borderRadius:6, borderColor:"#D8DCE5",  borderWidth:0.5}}>
                   <div style={{display:"flex",flexShrink:0,   justifyContent:"space-between", alignItems:"start"}}>

                    <div style={{ display:"flex", alignSelf:"center", justifyContent: "center", alignItems:"center", 
                    height:80, width:"80px !important", flexShrink:0,flexGrow:0, flexBasis:"80px", borderRadius:9999, backgroundColor:"#D8DCE5"}}>
                        <img src={streamingIcon} style={{height:50, width:50}}/>
                    </div>
                    
                    <div style={{textAlign:'center'}}>
                    <h5 style={{textAlign:'center', marginBottom:12, fontWeight:800}}>{i18next.t("ModalEventInfoMassive")}</h5> 

                    <p style={{fontSize:14, marginBottom:4}}>{i18next.t("ModalEventInfoMassiveExp1")}</p>
                    <p style={{fontSize:14, marginBottom:4}}> {i18next.t("ModalEventInfoMassiveExp2")}</p>
                    <p style={{fontSize:14, marginBottom:4, color:"red"}}>{i18next.t("ModalEventInfoMassiveExp3")}</p>

                    </div>
                   </div>
                </div>
            </div>

            {disabledMassive  &&
                <p > {i18next.t("ModalEventInfoContactUs")}</p>
            }


            </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default ModalEventInfo