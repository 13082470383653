import { useState } from "react";
import { UserDAO } from "../../../../../daos";
import { LINKEDIN_CLIENTID, LOGIN_BACK_URL } from "../../../../../environment/environment";

// import $ from "jquery";
import * as yup from "yup";

const initialFormValues = {
  phone: "",
  code: ""
};

const schema = yup.object().shape({
  phone: yup.string().required("Phone number is required"),
  code: yup.string().required("Verification code is required")
});

const userDAO = new UserDAO();

/**
 * Logic of ModalRegister=
 * @returns {Object}
 */
export const useModalVerifyInfluencerLogic = history => {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errorForm, setErrorForm] = useState("");
  const [phase, setPhase] = useState("PHONE");
  const [showAlertGoodCredentials, setShowAlertGoodCredentials] = useState(
    false
  );
  const [loading, setLoading] = useState(false);

  /**
   * Handle form changes.
   * @param {Object} event, Event trigerred for the input form
   */
  const onChangeFormValues = event => {
    const updatedValues = JSON.parse(JSON.stringify(formValues));
    const { name, value } = event.target;
    updatedValues[name] = value;
    setFormValues(updatedValues);
  };

  const sendCode = async () => {
    const myToken = JSON.parse(localStorage.getItem("env"));

    const phone = formValues.phone;

    try {
      await userDAO.influencerVerificationCode({ phone }, myToken.token);
      setPhase("CODE");
    } catch (err) {
      
      if (err && err.response && err.response.status === 400) {
        setErrorForm(
          err.response.data.error.map(item => item.message).join(", ")
        );
      } else {
        setErrorForm("Sorry, something went wrong.");
      }
    }
  };
  /**
   * Evaluate the response of the register request
   * @param {Object} event, Event triggered for the form
   */
  const twitterVerification = async () => {
    try {
      localStorage.setItem("twitterAction", "verify");
      const res = await userDAO.requestTwitterToken();
      const token = res.data.oauth_token;
      window.location.href =
        "https://api.twitter.com/oauth/authenticate?oauth_token=" + token;
    } catch (err) {
      if (err && err.response && err.response.status === 400) {
        setErrorForm(
          err.response.data.error.map(item => item.message).join(", ")
        );
      } else {
        setErrorForm("Sorry, something went wrong.");
      }
    }
  };
  const linkedinVerification = async () => {
    localStorage.setItem("LinkedinAction", "verify");
    const backURL = LOGIN_BACK_URL;
    
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENTID}&redirect_uri=${backURL}&scope=r_emailaddress,r_liteprofile&state=Verify`;
  };

  const goToBecomeInfluencerForm = async event =>{
    window.$("#myModal_verify_influencer").modal("hide");
    history.push("/become_influencer");
  }   

  const onSubmit = async event => {
    const myToken = JSON.parse(localStorage.getItem("env"));

    if (loading) return;
    event.preventDefault();
    try {
      await schema.validate(formValues);
    } catch (err) {
      setErrorForm(err.errors[0]);
      return;
    }
    setLoading(true);
    try {
      await userDAO.influencerVerificationCode(formValues, myToken.token);
      setPhase("SELECT_SOCIAL_NETWORK");
    } catch (err) {
      if (err.response.status === 400) {
        setErrorForm(
          err.response.data.error.map(item => item.message).join(", ")
        );
      } else {
        setErrorForm("Sorry, something went wrong.");
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    formValues,
    onChangeFormValues,
    onSubmit,
    errorForm,
    setErrorForm,
    showAlertGoodCredentials,
    setShowAlertGoodCredentials,
    twitterVerification,
    linkedinVerification,
    loading,
    sendCode,
    goToBecomeInfluencerForm,
    phase
  };
};
