import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "utils";

const initialState = { moderator: [], optionsModerators: [] };

const setModerator = (state, action) => {
  return updateObject(state, {
    moderator: action.moderator
  });
};

const setOptionsModerators = (state, action) => {
  return updateObject(state, {
    optionsModerators: action.optionsModerators
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MODERATOR:
      return setModerator(state, action);
    case actionTypes.SET_OPTION_MODERATORS:
      return setOptionsModerators(state, action);
    default:
      return state;
  }
};

export default reducer;
