import React, {Component} from 'react'
import './style.scss'
import {Link} from 'react-router-dom'
import Menu from '../comunes/navbar_profile_influencer copy';
import Steps from '../../assets/img/steps_influencer.png'

class NewProfileInfluencer extends Component{
    render(){
        return(
            <div>
                <Menu/>
                <div className="d-flex align-items-center flex-column text-center mt-5">
                    <h1>Become an Influencer</h1>
                    <h2 className="face-to-face">Talk face to face with anyone online,<br/> either in a private video meeting or live group of event</h2>
                    <Link to="/influencer_profile/active">
                        <button className="btn-rojo">Complete my influencer profile</button>
                    </Link>
                    <img className="mt-5 img_new_influencer" src={Steps}/>
                </div>
            </div>
        )
    }
}
export default NewProfileInfluencer