import * as actionTypes from "./actionTypes";
import { UserDAO, EventDAO } from "daos";

const userDAO = new UserDAO();
const eventDAO = new EventDAO();

export const setUser = user => {
  return {
    type: actionTypes.SET_USER,
    user
  };
};
export const logoutUser = ()=> (dispatch)=>{
  localStorage.removeItem('env')

  dispatch({type: actionTypes.SET_UNAUTHENTICATED})
}

export const confirmUser =  (token) => {
  return async dispatch => {
    try{
      dispatch({type:actionTypes.LOAD_USER})
      const res = await userDAO.confirmEmail(token);
      dispatch({type:actionTypes.SET_USER, user: res.data.data})
      const env = {
        token: res.data.data.user_app_token
      };
      localStorage.setItem('env', JSON.stringify(env))
    }
    catch (err){
      
      dispatch({type:actionTypes.SET_ERROR, error: err})
    }
  }
}
export const resetUserPassword = (data) => {
  return async dispatch => {
    try{
      dispatch({type:actionTypes.LOAD_USER})
      const res = await userDAO.forgotPsswordNewPassword(data);
      
      dispatch({type:actionTypes.SET_USER, user: res.data.data})
      const env = {
        token: res.data.data.user_app_token
      };
      localStorage.setItem('env', JSON.stringify(env))
    }
    catch (err){
      
      dispatch({type:actionTypes.SET_ERROR, error: err})
    }
  }
}

export const getUser = token => {
  return async dispatch => {
    try {
      const {
        data: { data }
      } = await userDAO.findByToken(token);

      const purchasedEvents = await eventDAO.purchasedEvents(token);
      data.purchasedEvents =
        (purchasedEvents.data && purchasedEvents.data.data) || []; 
      const myPaymentMethods = await userDAO.getUserPaymentMethods(token);
      dispatch(setUser(data));
      dispatch(setUserPaymentMethods(myPaymentMethods.data));
    } catch (err) {
      dispatch(setUser({}));
    }
  };
};



export const deletePaymentMethod = (token, paymentMethod) => {
  return async dispatch => {
    try {
      const {
        data: { data }
      } = await userDAO.deletePaymentMethod(token, paymentMethod);
      const myPaymentMethods = await userDAO.getUserPaymentMethods(token);
      dispatch(setUserPaymentMethods(myPaymentMethods.data));
    } catch (err) {
      
    }
  };

}

export const addStripePaymentMethod = (token, number, month, year, cvc, stripeKey) => {
  return async dispatch => {
    try {
      const {
        data: { id }
      } = await userDAO.createCardTokenStripe(number, month, year, cvc, stripeKey);
      await userDAO.savePaymentMethod(token, id);
      const myPaymentMethods = await userDAO.getUserPaymentMethods(token);
      dispatch(setUserPaymentMethods(myPaymentMethods.data));
    } catch (err) {
      console.log ({err})
      throw err
    }
  };
}

export const setInfluencer = (influencer, events) => {
  return {
    type: actionTypes.SET_INFLUENCER,
    influencer,
    events
  };
};

export const setInfluencerStats = stats => {
  return {
    type: actionTypes.SET_INFLUENCER_STATS,
    stats
  };
};

export const setUserPaymentMethods = methods => {
  return {
    type: actionTypes.FETCH_PAYMENT_METHODS,
    methods
  };
};

export const getInfluencer = (token, currency="usd") => {
  return async dispatch => {
    try {
      const response = await userDAO.profileInfluencer(token);
      let myStats = await userDAO.getMyInfluencerStats(token, currency);
      const myCurrencies = await userDAO.getMyInfluencerCurrencies(token);
      if (myStats.data && myStats.data.data && myCurrencies.data && myCurrencies.data.data){
        myStats.data.data.currencies = myCurrencies.data.data
      }
      dispatch(setInfluencer(response.data.influencer, response.data.events));
      dispatch(setInfluencerStats(myStats.data));
    } catch (err) {
      dispatch(setInfluencer({}));
      dispatch(setInfluencerStats({}));
    }
  };
};


// Clear errors
export const clearErrors = () => (dispatch) => {dispatch({type:actionTypes.CLEAR_ERRORS})}


export const setError = (error, typeError = null) => {
  return async dispatch => {
    dispatch({type:actionTypes.SET_ERROR, error: error, typeError: typeError})
  };
};

