import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import useLocalStorage from "../../hooks/useLocalStorage"
import i18next from 'i18next';
import ReferralImg from "assets/img/womanLanding.jpg";
import { Container } from "./ReferralStyles"
import { useModalRegisterLogic } from "components/comunes/login_menu/modals-login/modal_register/ModalRegisterLogic";
import Footer from "components/comunes/footer";
import ModalCTA from "./ModalCTA"
import useSizeFactors from "./useSizeFactor"
import { parseLocale } from 'utils/Localization'
import LandingCopy from "./LandingCopy"
const gotmyerEng = require("../../assets/referral/gotmyer_eng.mp4");
const gotmyerEsp = require("../../assets/referral/gotmyer_esp.mp4");
const recaptchaRef = React.createRef();

const Referral = () => {
    const [modalCTA, setModalCTA] = useState(false)
    const { twitterRegister, linkedinRegister, facebookRegister, instagramRegister } = useModalRegisterLogic(recaptchaRef.current);
    const params = useParams()
    const { sizeFactor, inAppBrowse, screenSize , ultraWide} = useSizeFactors()
    const [referral, setReferral, clearReferral] = useLocalStorage("referral")
    const [talent, setTalent, cleatTalent] = useLocalStorage("LandingTalent")
    useEffect(() => {
        var millis = new Date().getTime()
        if (params && params.id) {
            setReferral({ id: params.id, timeStamp: millis })
        }

        setTalent(true);

    }, [params])
    return (
        <div style={{ marginBottom: screenSize !== "sm" ? -130 : -50 }}>
            <section className="homeVideoHeader">
                {((screenSize !== "sm") && (inAppBrowse === false)) && <img src={ReferralImg} autoPlay  style={{ minHeight: (screenSize === "md" || screenSize === "sm") ? 800 : 800, maxHeight:"100vh" }} id="the-video" muted loop className="fullscreen-bg__video"/>
                }
                {/* {((screenSize !== "sm") && (inAppBrowse === false)) && <video autoPlay  style={{ minHeight: (screenSize === "md" || screenSize === "sm") ? 800 : 800 }} id="the-video" muted loop className="fullscreen-bg__video">
                    <source src={VideoBackground} type="video/mp4" />
                </video>
                } */}
                <Container>
                    {modalCTA &&
                        <ModalCTA modalCTA={modalCTA} setModalCTA={setModalCTA} facebookRegister={facebookRegister}
                            instagramRegister={instagramRegister}
                            linkedinRegister={linkedinRegister}
                            twitterRegister={twitterRegister}
                            screenSize={screenSize}
                            inAppBrowse={inAppBrowse} />
                    }
                    <div className="d-flex flex-column flex-lg-row" style={{ justifyContent: "space-between", marginTop: 0, marginLeft: (screenSize !== "sm") && 20 }}>
                        <div style={{flex:1, marginRight: (screenSize==="xl" || screenSize==="lg"|| screenSize==="md")?24:0}}>
                            <div style={{ display: "flex", justifyContent: (screenSize === "lg" || screenSize === "xl") ? "center" : "center", marginTop: 26, marginLeft: 20 }}>
                                <div>
                                    <p className="" style={{ marginBottom: 2, fontWeight: 500, text: "white", fontSize: 30 * sizeFactor }}>{i18next.t("ReferralCTAfirstLine")}</p>
                                    <button style={{ fontSize: 18, width: (screenSize === "sm" || screenSize === "md" || inAppBrowse) ? '300px' : 350, backgroundColor: "#ff5a60", marginTop: 8, marginBottom: '8px', padding: "12px", border: "none", color: 'white', borderRadius: '24px' }} className="Btn-face grow " onClick={() => setModalCTA(true)}>
                                        {i18next.t("ReferralCTA")}
                                    </button>
                                </div>
                            </div>
                            <video style={{borderRadius:8, minHeight:350, maxHeight:600, height:"auto", width:"auto", objectFit:"contain", maxWidth:"100%"}} id="referralVid" muted playsInline autoPlay loop className="Shadow-md mt-3 mb-5  ">
                                <source src={parseLocale() ==="en"? gotmyerEng: gotmyerEsp} type="video/mp4" />
                            </video>

                        </div>

                        <div style={{ flex:1 , marginTop: screenSize === "xl" || screenSize === "lg" ? 55 : -30 }}>
                            <LandingCopy screenSize={screenSize} sizeFactor={sizeFactor}/>

                            <div style={{ marginBottom: screenSize === "sm" && 40, marginTop: inAppBrowse? 10:   screenSize !== "sm" ? -50: 10, width: "100%", display: "flex", justifyContent: ultraWide? "flex-start" :  "center" }}>

                                <div style={{ position: "relative", }}>

                                    <div style={{
                                        marginTop: screenSize !== "sm" && 26, marginBottom: 6,
                                        fontWeight: 600, padding: "2px 50px", borderRadius: 50, border: 0, color: "#fff",
                                        fontSize: 24 * sizeFactor, maxWidth: 500
                                    }} className="">

                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: (screenSize === "lg" || screenSize === "xl") && 44 }}>
                                        <button style={{ fontSize: 18, width: (screenSize === "sm" || screenSize === "md" || inAppBrowse) ? '300px' : 350, backgroundColor: "#ff5a60", marginBottom: '8px', padding: "12px", border: "none", color: 'white', borderRadius: '24px' }} className="Btn-face grow " onClick={() => setModalCTA(true)}>
                                            {i18next.t("ReferralCTA")}
                                        </button>
                                    </div>
                                    <span className="" style={{ fontWeight: 500, text: "white", fontSize: 30 * sizeFactor }}>{i18next.t("ReferralCTAfirstLine")}</span>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>

                </Container>
            </section>
            <footer style={{ marginTop: 0 }}>
                <Footer />
            </footer>
        </div>
    )
}


export default Referral
