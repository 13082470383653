import axios from "axios";
import { Api, ACCESS_TOKEN_API } from "./../environment/environment";
import URI from "urijs";
import { version } from "../../package.json";
import { parseLocale } from '../utils/Localization'

var DetectRTC = require('detectrtc');

let headers = {
  "Access-Token": ACCESS_TOKEN_API,
  "Content-Language": parseLocale()
};

const refreshHeaders = (accessToken) => {
  DetectRTC.load();
  headers = {};

  headers["Access-Token"] = accessToken;
  headers["Platform"] = "Web/" + DetectRTC.browser.name + "/" + DetectRTC.browser.fullVersion + "/OS:" + DetectRTC.osName + DetectRTC.osVersion;
  headers["Platform-Display"] = DetectRTC.displayResolution;
  headers["Video-Permission"] = DetectRTC.isWebsiteHasWebcamPermissions;
  headers["Microphone-Permission"] = DetectRTC.isWebsiteHasMicrophonePermissions;
  headers["Microphone"] = DetectRTC.hasMicrophone;
  headers["Camera"] = DetectRTC.hasWebcam;
  headers["isWebSocketsBlocked"] = DetectRTC.isWebSocketsBlocked;
  headers["isWebSocketsSupported"] = DetectRTC.isWebSocketsSupported;
  headers["Content-Language"] = parseLocale();
  headers["Build"] = version;

}

export default class InfluencerDAO {
  trendingInfluencers(categories) {
    const url = new URI(`${Api}influencer/trending`);
    if (categories) url.addSearch("categories", categories);
    return axios.get(url, { headers });
  }

  getInfluencerProfile(username, token = null) {
    if (token) {
      refreshHeaders(token);
    }
    const url = new URI(`${Api}influencer/username/` + username);

    var response = axios.get(url, { headers });
    return response;
  }

  createCheckoutSuscription(username, token = null) {
    if (token) {
      refreshHeaders(token);
    }
    const url = new URI(`${Api}influencer/subscribe/` + username);

    return axios.post(
      url,
      {
        platform: "web"
      },
      { headers }
    );
  }
}
