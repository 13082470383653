import React from 'react'
import Avatar from "../CardUtils/Avatar";
import { EventHappeningContainer } from './styles'
import { Link } from "react-router-dom";
import { diffWithCurrentDate } from "utils";
import Loader from "components/comunes/loader";
import cinco_estrellas from "assets/img/5-estrellas.png";
import map from "assets/img/icons/ic-map.svg";
import i18next from 'i18next';

const EventHappeningnCard = ({ event, loading }) => {
  const {
    event_title,
    event_category,
    event_uuid,
    event_cover_image,
    event_location
  } = event.event;
  const {
    user_city,
    user_avatar,
    user_username
  } = event.user;
  const startIn = diffWithCurrentDate(event.event.event_start_hour);
  return (
    <EventHappeningContainer className="col-12 col-md-4 grow">
      <div style={{ borderRadius: '7px' }} className="Shadow-lg" >
        {!loading ? (
          <>
            <Link to={`/event/${event_uuid}`}>
              <div
                className="Header"
                style={{
                  backgroundSize: 'cover', backgroundImage: `linear-gradient(rgba(18, 16, 22, 0.35), rgba(17, 16, 22, 0.35)), url(${event_cover_image})`
                }}>
                {startIn > -1 && (
                  <span className="Tiempo">
                    {startIn > 0
                      ? i18next.t('EventHappeningCardComponentStartsIn') + " " + startIn + startIn === 1 ? i18next.t('EventHappeningCardComponentHour') : i18next.t('EventHappeningCardComponentHours')
                      : i18next.t('EventHappeningCardComponentStartsInAFewMinutes')
                    }
                  </span>
                )}
                <span className="Titulo">{event_title}</span>
                <span className="Categoria">
                  {
                    event_category.cate_description
                  }
                </span>
              </div>
            </Link>
            <div className="Footer">
              <Link to={`influencer/${user_username}`}>
                <Avatar user_avatar={user_avatar} />
              </Link>
              <div>
                <span className="Nombre">
                  {user_username}
                </span>
                <span className="Pais">
                  {" "}
                  <img src={map} alt="" />
                  {event_location &&
                    JSON.parse(event_location)
                      .city.split("")
                      .splice(0, 15)
                      .join("")}
                </span>
              </div>
              <div className="Estrellas">
                {/* <img src={cinco_estrellas} alt="puntuacion" /> */}
              </div>
            </div>
          </>
        ) : (
            <Loader />
          )}
      </div>
    </EventHappeningContainer>
  )
}
export default EventHappeningnCard