import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import Request from "assets/img/icons/ic-requests.svg";
import i18next from 'i18next';

const AttendeesModerator = ({ data = [], isEnabled }) => {
  return (
    <div className="Attendees_moderator" style={{ marginTop: data.length > 0 ? "5%" : 0 }}>

      <div className="div_moderator_views">
        {data.map(
          user =>
            user && (
              <img
                key={user.user_ide}
                src={user.user_avatar}
                alt=""
                style={{ width: "30px", height: "30px", borderRadius: "50%" }}
              />
            )
        )}
        {isEnabled ? (<span className="waiting_viewers">
          {data.length} {i18next.t("AttendeesModeratorComponentViewersWaiting")}
        </span>
        ) : (
        <span className="waiting_viewers" style={{textAlign: 'center'}}>
        {i18next.t("AttendeesModeratorComponentRaiseHandModeDisabled")}
      </span>
      )}
      </div>
    </div>
  );
};

AttendeesModerator.propTypes = {
  data: PropTypes.array.isRequired
};

export default AttendeesModerator;
