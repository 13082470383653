import React from 'react'
import facebookIcon from "assets/img/icons/facebook.svg";
import linkedinIcon from "assets/img/icons/Linkedin.svg";
import instragramIcon from "assets/img/icons/instagram.svg";
import twitterIcon from "assets/img/icons/twitter.svg";
import TiwchtIcon from "assets/img/icons/Tiwcht.svg";
import youtubeIcon from "assets/img/icons/youtube.svg";

const SocialButtons = ({ linkedinLink, twitterLink, facebookLink, instagramLink }) => {
  return (
    <div className="social-icons">
      { linkedinLink &&
        <a style={{ marginRight: 10 }} target="_blank" href={`https://www.linkedin.com/in/${linkedinLink}`} >
          <img style={{ width: 42, height: 42 }} src={linkedinIcon} />
        </a>
      }
      {twitterLink && (
        <a style={{ marginRight: 10, marginLeft: 10 }} target="_blank" href={`https://www.twitter.com/${twitterLink}`} >
          <img style={{ width: 42, height: 42 }} src={twitterIcon} />
        </a>
      )}

      {facebookLink && (
        <a style={{ marginRight: 10, marginLeft: 10 }} target="_blank" /*href={`https://www.twitter.com/${twitterLink}`}*/ >
          <img style={{ width: 42, height: 42 }} src={facebookIcon} />
        </a>
      )}

      {instagramLink && (
        <a style={{ marginLeft: 10 }} target="_blank" href={`https://www.instagram.com/${instagramLink}`} >
          <img style={{ width: 42, height: 42 }} src={instragramIcon} />
        </a>
      )}
    </div>
  )
}
export default SocialButtons