import React, { useEffect, useState } from "react";
import "./style.scss";
import logo from "assets/img/logo-white.png";
import Menu from "./menu";
import LoginMenu from "../login_menu";
import useMobileNav from "../../../hooks/useMobileNav";
import { IS_SIGN_UP_ENABLED, B2B_PORT, BRAND_BLOG_URL, HOME_PAGE } from "../../../environment/environment";
import useWindowSize from "../../../hooks/useWindowSize";
import MobileNav from "./mobile/MobileNav";
import { Link } from "react-router-dom";
import i18next from "i18next";

export default ({ referral }) => {
  const { mobileNav } = useMobileNav();

  if (!mobileNav || B2B_PORT) {
    if (IS_SIGN_UP_ENABLED ) {
      return (
        <div className="w-100">
          <div className="Nav Bg-blue">
            <div className="d-flex">
              <a target={!B2B_PORT ? "_blank" : "_self" } href={!B2B_PORT ? BRAND_BLOG_URL : HOME_PAGE}>
                <img className="mr-3 " src={logo} className="Logo" alt="logo" />
              </a>
              {!B2B_PORT && (
                <a
                  style={{
                    color: "#FF5A60",
                    alignSelf: "flex-end",
                    marginTop: "14px",
                  }}
                  className="align-bottom"
                  target="_blank"
                  href="https://blog.gotmy.com/"
                  className=""
                >
                  <h5>{i18next.t("NavBarComponentBlog")}</h5>
                </a>
              )}
            </div>
            {!B2B_PORT && (
              <>
                <Menu />
                <LoginMenu referral={referral} />
              </>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="Nav Nav-non-signing Bg-blue">
            {/* <img src={logo} className="Logo fixed" alt="logo" /> */}
            <div
              style={{ position: "absolute", left: "20px" }}
              className="d-flex"
            >
              <a target="_blank" href="https://blog.gotmy.com/">
                <img
                  className="mr-3 "
                  src={logo}
                  className="Logo "
                  alt="logo"
                />
              </a>
              <a
                style={{
                  color: "#FF5A60",
                  alignSelf: "flex-end",
                  marginTop: "14px",
                }}
                className="align-bottom"
                target="_blank"
                href="https://blog.gotmy.com/"
                className=""
              >
                <h5>{i18next.t("NavBarComponentBlog")}</h5>
              </a>
            </div>
            <Menu />
          </div>
        </div>
      );
    }
  } else {
    return <MobileNav referral={referral} />;
  }
};
