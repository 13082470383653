import React, { useContext, useEffect } from "react";
import "./style.scss";
import "../../assets/css_general/general.scss"
import { connect } from "react-redux";
import { SignContext } from "../../Sign";
import { Redirect } from "react-router-dom";
import { isAndroid } from "react-device-detect";
import logo from "assets/img/logo.png";
import {useSpring, animated} from 'react-spring'
import { googlePlayGotmyURL, appleStoreGotmyURL, gotmyAppScheme } from '../../environment/environment'

const wp = window.innerWidth;
const hp = window.innerHeight;




function redirectDownloadApp() {

  if (isAndroid) {
    window.open(
      googlePlayGotmyURL,
      '_blank'
    );
    //window.location.href = googlePlayGotmyURL;
  } else {
    window.open(
      appleStoreGotmyURL,
      '_blank'
    );
    //window.location.href = appleStoreGotmyURL;
  }

}

function redirectToApp() {

  window.location.replace("gotmy://");
  setTimeout(redirectDownloadApp, 1000);
  
  /*
    window.open(
      googlePlayGotmyURL,
      '_blank'
    );
    */
    //window.location.href = googlePlayGotmyURL;

}


function Home({ user, loading }) {

  const [sign] = useContext(SignContext);
  const animation = useSpring({opacity: 1, from: {opacity: 0}, marginBottom: 0, from: {marginBottom: -1000}})
  useEffect(() => {
    redirectToApp()
  }, [])

  return (
    <div
      //className="Bg-black"
      style={{
        display: 'flex',
        width: wp,
        height: hp,
        alignSelf: 'center',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '70%',
          marginLeft: wp * 0.15,
          marginRight: wp * 0.15,
        }}
      >
        <img
          style={{
            width: '100%'
          }}
          src={logo} alt="" />
      </div>
      <animated.div
       style={animation}
      >
        <div
         style={{
          alignContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
        }}
        >

        
        <button
          className="Bg-red"
          style={{
            width: '100%',
            color: 'white',
            paddingRight: wp * 0.15,
            paddingLeft: wp * 0.15,
            paddingTop: hp * 0.020,
            paddingBottom: hp * 0.020,
            marginTop: hp * 0.15,
            borderWidth: 0,
            borderRadius: wp,
            fontSize: wp * 0.05,
            outline: 'none'
          }}
          onClick={redirectToApp}>
          Get the App
        </button>
        </div>
      </animated.div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    loading: state.user.loading
  };
};
export default connect(mapStateToProps)(Home);
