import React from 'react'
import userIcon from "assets/img/icons/ic-user.svg";

const ViewerCount = ({eventData}) => {
    return (
        <div className="viewers-past my-3 " style={{
            alignItems: "center"
        }}>
        <img src={userIcon} alt='' />
        <p style={{marginBottom: 0}}>{eventData && eventData.attendees_users.length} Viewers</p>
    </div>
    )
}
export default ViewerCount