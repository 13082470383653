import React, {Component} from 'react';
import PuntUsu from '../comunes_events/imagen_usu_puntuacion';
import NombreCat from '../comunes_events/nombre_cat_past';
import './style.scss';
import './modal.scss';
import Tags from "../comunes/tag";
// import {Link} from "react-router-dom";
import Rating from "../comunes_events/rating"
import NavBar_general from "../comunes/navbar_influencer";
import Back from "../comunes/back";
import NavBar_upcoming from "../live_events_page_influencer/navbar_live_events";
import user from '../../assets/img/icons/ic-user.svg';
import user2 from '../../assets/img/icons/ic-raisehand.svg';
import user3 from '../../assets/img/icons/ic-price.svg';
import user5 from '../../assets/img/icons/ic-descuento.svg';
import LiveChat from "../../assets/img/chatxd.png";
import RatingPoints from "../comunes_events/rating_points";
import RelatedVideos from "../comunes_events/Related_videos";
import LastImg from "../../assets/img/another-view2.png";
class PastDetailsEventInfluencer extends Component {
    render() {
        return (
            <div class="upcoming-details">
                <header className="App-header">
                    <NavBar_general/>
                    <NavBar_upcoming/>
                </header>
            <section className="Live-Event-Detail container">

                <div className="row">
                    <Back text={"Back to my past event"} url={"/my_live_events/past_live_events"}/>

                    <div className="col-7 past mt-5">
                        <PuntUsu/>
                        <br/>
                        <NombreCat/>
                    
                        <div>
                            <img className="w-100 br-10 img-past" src={LastImg} alt=""/>
                            <div className="viewers-past"><img src={user} alt/><p>845 Viewers</p></div>
                        </div>
                        <p className="D-title">
                            Live Event Description
                        </p>
                        <p className="Desc">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci at aut cupiditate
                            dignissimos dolor facilis, incidunt ipsam, labore laborum, libero nihil odit optio pariatur
                            perferendis placeat quam similique velit voluptatum.
                        </p>

                        <p className="D-title">
                            Language
                        </p>
                        <div className="d-flex align-items-center p-1 Cg ">
                            <img src="https://via.placeholder.com/20x20" alt=""/>&nbsp; English
                        </div>
                        <h2 className="mt-5 mb-2 rating_and_reviews">Rating and Reviews</h2>
                        <RatingPoints/>
                        <Rating/>
                        <Rating/>
                        <p className="D-title">
                            Related Tags
                        </p>

                        <Tags/>
                        <Tags/>
                        <Tags/>
                        <Tags/>
                        <Tags/>
                        <Tags/>
                        <Tags/>
                        <Tags/>
                        <Tags/>
                        
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <p className="information">Private Information</p>
                        <div className="d-flex information_detalles"><img src={user} alt/><div><p className="m-0">Price per ticket</p><p className="price_information">$ 20.00</p></div></div>
                        <div className="d-flex information_detalles"><img src={user2} alt/><div><p className="m-0">Number of tickets</p><p className="price_information">60</p></div></div>
                        <div className="d-flex information_detalles"><img src={user3} alt/><div><p className="m-0">Gross Revernus (sold out)</p><p className="price_information">$ 120.00</p></div></div>
                        <div className="d-flex information_detalles"><img src={user} alt/><div><p className="m-0">Ticketing Fees</p><p className="price_information">$ 2.00</p></div></div>
                        <div className="d-flex information_detalles"><img src={user5} alt/><div><p className="m-0">Discount Code</p><p className="price_information"> 20%</p></div></div>  
                    </div>

                    <div className="col-3 past_chat_event">
                        

                        <p className="D-title p-0 mb-3 mt-4">
                            Share Live Event
                        </p>
                        <div className="d-flex justify-content-around">
                            <i className="fab fa-facebook face"></i>
                            <i className="fab fa-linkedin linkedin"></i>
                            <i className="fab fa-twitter twitter "></i>
                            <i className="fab fa-whatsapp whats"></i>
                            <i className="fas fa-envelope google"></i>
                            <i className="fas fa-link google"></i>
                        </div>
                        <p className="D-title p-0 mb-3 mt-5 ">
                            Live chat playback
                        </p>
                        <div className="Attendes-div relate-div">
                        <img src = {LiveChat}></img>  
                        <p className="D-title p-0 mb-3 mt-5 ">
                            Related Videos
                        </p> 
                        <RelatedVideos/>
                        <RelatedVideos/>
                        <RelatedVideos/>
                        <RelatedVideos/>
                        <RelatedVideos/>
                        <RelatedVideos/>           
                        </div>
                    </div>
                    </div>
            </section>
            </div>

        );
    }
}
export default PastDetailsEventInfluencer;