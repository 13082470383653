import React, { useState, useEffect, useRef } from "react";
import Navbar from "../comunes/navbar";
// import Video from "./videos_seccion";
import Video from "../comunes/videos_seccion";
import { SlideCards } from "../comunes/upcomingLiveEvents/SlideCards";
import "../../assets/css_general/video-react.css";
import "./style.scss";
import { UserDAO, InfluencerDAO } from "daos";
import { Link, withRouter } from "react-router-dom";
import map from "../../assets/img/icons/ic-map.svg";
import close from "assets/img/icons/close-maincolor.svg";
import exampleVideo from "../../assets/videos/Earth_Zoom_In.mov";
import RatingPoints from "../comunes_events/rating_points";
import Loader from "components/comunes/loader";
import SocialButtons from "../comunes/social/SocialButtons/SocialButtons";
import { useProfileInfluencerLogic } from "./ProfileInfluencerLogic";
import { Row, Col, Container } from "react-bootstrap";
import UpcomingLiveEvents from "components/comunes/upcomingLiveEvents";
import { eventState } from "./../../constants";
import { connect } from "react-redux";
import Linkify from "react-linkify";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import ModalDownloadApp from "./ModalDownloadApp";
import useMobileNav from "../../hooks/useMobileNav";
import { useSelector } from "react-redux";
import { WithAlert } from "../../hocs/withAlert";
import i18next from "i18next";
import {
  Player,
  BigPlayButton,
  ControlBar,
  PlayToggle,
  VolumeMenuButton,
  FullscreenToggle,
} from "video-react";

import {
  hideNotReadyContent,
  RESTRICTED_VIEWS,
  newB2BInfluencerLanding,
} from "../../environment/environment";

const influencerDAO = new InfluencerDAO();
const userDAO = new UserDAO();
const ProfileInfluencer = ({ match, history, userLogged, showAlert }) => {
  const { mobileNav } = useMobileNav();
  const privateMeetingEventSlotsRef = useRef(null);

  const viewer = useSelector((state) => state.user.user);
  const [data, setData] = useState({});
  const [showPrivateRequest, setShowPrivateRequest] = useState(false);
  const [modalDownload, setModalDownload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [follow, setFollow] = useState();
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  function renderHeaderTagsAndtitle(data) {
    var pageTitle = "gotmy | " + data.user.user_username;
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta
          name="og:description"
          content={data.influencer.influencer_aboutme}
        />
        <meta property="og:title" content={pageTitle} />
        <meta
          property="og:url"
          content={"https://gotmy.com/" + data.user.user_username}
        />
        <meta property="og:image" content={data.influencer.influencer_avatar} />
        <link
          rel="canonical"
          href={"https://gotmy.com/" + data.user.user_username}
        />
      </Helmet>
    );
  }

  useEffect(() => {
    if (userLogged && userLogged.user_username && data && data.influencer) {
      if (
        data.followers.find(
          (follower) => follower.user_username == userLogged.user_username
        )
      ) {
        console.log(" FOLLOW TRUE");
        setFollow(true);
      } else {
        console.log(" FOLLOW FALZ");

        setFollow(false);
      }
    }
  }, [userLogged, data]);

  useEffect(() => {
    if (showPrivateRequest) {
      privateMeetingEventSlotsRef.current.scrollIntoView();
    }
  }, [showPrivateRequest]);

  const initData = async () => {
    const { id } = match.params;
    const env = JSON.parse(localStorage.getItem("env") || "{}");

    try {
      let response = await influencerDAO.getInfluencerProfile(
        id,
        env.token ? env.token : null
      );

      setData(response.data);

      console.log("DATOS DEL INFLUENCER", response.data);
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const windowResizer = async () => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  };

  const requestPrivateEventNonLoggedIn = () => {
    if (newB2BInfluencerLanding) {
      setShowPrivateRequest(!showPrivateRequest);

      return null;
    }
    // alert("Coming soon!");
    setModalDownload(true);
  };

  useEffect(() => {
    initData();
    windowResizer();
  }, []);

  if (loading) return <Loader />;

  if (data && data.influencer) {
    const {
      categories,
      languages,
      user,
      followers,
      influencer: {
        influencer_aboutme,
        influencer_avatar,
        influencer_ranking,
        influencer_subscription_data,
        influencer_presentation_video,
      },
      events,
      user: {
        user_name,
        user_lastname,
        user_username,
        user_location,
        user_linkedin_data,
        user_twitter_data,
        user_instagram_data,
        user_facebook_data,
      },
    } = data;
    const followUnfollow = async () => {
      const { id } = match.params;
      const env = JSON.parse(localStorage.getItem("env") || "{}");

      try {
        if (
          !followers.find(
            (follower) => follower.user_username == loggedInUsername
          )
        ) {
          await userDAO.followUser(id, env.token);
          let response = await influencerDAO.getInfluencerProfile(
            id,
            env.token ? env.token : null
          );

          setData(response.data);
          setFollow(true);
        } else {
          await userDAO.unFollowUser(id, env.token);

          let response = await influencerDAO.getInfluencerProfile(
            id,
            env.token ? env.token : null
          );
          setFollow(false);
          setData(response.data);
        }
      } catch (err) {
        throw err;
      } finally {
        setLoading(false);
      }
    };

    const handleSubscribe = () => {
      history.push(`/subscribe/${user_username}`);
    };

    const handlePrivateRequest = () => {
      if (newB2BInfluencerLanding) {
        setShowPrivateRequest(!showPrivateRequest);

        return null;
      }

      if (viewer) {
        if (viewer.user_location !== "") {
          history.push(`/private_request/${user_username}`);
        } else {
          if (
            !alert(
              i18next.t(
                "ProfileInfluencerComponentPrivateRequestRequireLocation"
              )
            )
          ) {
            localStorage.setItem(
              "pendingPrivateRequest",
              `/private_request/${user_username}`
            );
            history.push("/viewer_profile");
          }
        }
      }
    };

    var reviews = 0,
      totalAttendees = 0;

    console.log("INFLUENCER EVENTS", events);

    for (var i = 0; i < events.length; i++) {
      reviews = reviews + events[i].reviews.length;
      totalAttendees =
        totalAttendees +
        (parseInt(events[i].event_num_attendees) -
          parseInt(events[i].event_num_tickets_free));
    }

    const loggedInUsername = userLogged ? userLogged.user_username : "";

    const env = JSON.parse(localStorage.getItem("env") || "{}");

    const upcomingEvents = data.events
      .filter((event) => event.event_state === eventState.upcoming)
      .map((event) => {
        const item = { event, user };
        return item;
      });

    const privateMeetingEventSlots = data.events
      .filter(
        (event) =>
          event.event_state === eventState.upcoming &&
          event.event_is_private_request
      )
      .map((event) => {
        const item = { event, user };
        return item;
      });

    const pastEventLimit = 10;

    const pastEvents = data.events
      .slice(0, pastEventLimit)
      .filter((event) => event.event_state === eventState.past)
      .map((event) => {
        const item = { event, user };
        return item;
      });

    console.log(
      "DATOS DEL INFLUENCER",
      privateMeetingEventSlots,
      upcomingEvents,
      pastEvents
    );

    var gmtPrefix = null;

    try {
      var userLocation = JSON.parse(user_location);

      gmtPrefix =
        userLocation.data.gmtOffset / 3600 > 0
          ? "GMT+"
          : userLocation.data.gmtOffset / 3600 === 0
          ? "GMT"
          : "GMT";

      gmtPrefix = gmtPrefix + (userLocation.data.gmtOffset / 3600).toString();
    } catch (error) {}

    if (newB2BInfluencerLanding) {
      return (
        <div>
          <header>
            <Navbar />
            {renderHeaderTagsAndtitle(data)}
          </header>
          {modalDownload && (
            <ModalDownloadApp
              modalDownload={modalDownload}
              setModalDownload={setModalDownload}
              message={i18next.t(
                "ProfileInfluencerComponentDownloadOurAppToRequestPrivate"
              )}
            />
          )}
          <div
            className={
              "container profile_div " +
              (windowSize.width < 768 ? "profile_div_mobile" : "")
            }
          >
            <Row>
              <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                <Row className={windowSize.width < 992 ? "text-center" : ""}>
                  <Col xs={{ span: 12 }} lg={{ span: 2 }}>
                    <img
                      className="img_profile_influencer"
                      src={influencer_avatar}
                    />
                  </Col>
                  <Col xs={{ span: 12 }} lg={{ span: 10 }}>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col xs={{ span: 12 }} lg={{ span: 3 }}>
                        <h2 className="mb-1 name_profile">
                          {user_name
                            ? user_name + " " + user_lastname
                            : i18next.t("ProfileInfluencerComponentNoContent")}
                        </h2>
                      </Col>
                      <Col xs={{ span: 12 }} lg={{ span: 3, offset: 1 }}>
                        <div
                          className="social-icons"
                          style={{
                            justifyContent:
                              windowSize.width < 992 ? "center" : "",
                          }}
                        >
                          <SocialButtons
                            linkedinLink={
                              user_linkedin_data
                                ? JSON.parse(user_linkedin_data).vanityName
                                : undefined
                            }
                            twitterLink={
                              user_twitter_data
                                ? JSON.parse(user_twitter_data).screen_name
                                : undefined
                            }
                            //user_instagram_data, user_facebook_data
                            facebookLink={
                              user_facebook_data
                                ? user_facebook_data
                                : undefined
                            }
                            instagramLink={
                              user_instagram_data
                                ? JSON.parse(user_instagram_data).username
                                : undefined
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={{ span: 12 }} lg={{ span: 4, offset: 1 }}>
                        <div className="">
                          {env && env.token && !hideNotReadyContent
                            ? privateMeetingEventSlots.length > 0 && (
                                <div className="d-flex justify-content-start mt-3 mb-3 privateRequestButtonContainer">
                                  <button
                                    onClick={handlePrivateRequest}
                                    className="Btn-rojo grow boton_go Btn-green"
                                  >
                                    {i18next.t(
                                      "ProfileInfluencerComponentRequestPrivateMeeting"
                                    )}
                                  </button>
                                </div>
                              )
                            : privateMeetingEventSlots.length > 0 && (
                                <div className="d-flex justify-content-start mt-3 mb-3 privateRequestButtonContainer">
                                  <button
                                    className="Btn-rojo btn-private boton_go grow Btn-green"
                                    onClick={requestPrivateEventNonLoggedIn}
                                  >
                                    {i18next.t(
                                      "ProfileInfluencerComponentRequestPrivateMeeting"
                                    )}
                                  </button>
                                </div>
                              )}
                          {influencer_subscription_data && (
                            <div className="d-flex justify-content-start mt-3 mb-3 privateRequestButtonContainer">
                              <button
                                onClick={handleSubscribe}
                                className="Btn-white grow boton_go"
                              >
                                {i18next.t(
                                  "ProfileInfluencerComponentsSubscribeInfluencer"
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                        <h5>
                          {i18next.t("ProfileInfluencerComponentAboutMe")}
                        </h5>
                        <Linkify properties={{ target: "_blank" }}>
                          <p className={"influencerAboutMeDescription"}>
                            {influencer_aboutme
                              ? influencer_aboutme
                              : i18next.t(
                                  "ProfileInfluencerComponentNoContent"
                                )}
                          </p>
                        </Linkify>

                        {influencer_presentation_video && (
                          <>
                            <h5
                              style={{
                                marginBottom: 15,
                              }}
                            >
                              {i18next.t(
                                "ProfileInfluencerComponentMoreAboutMe"
                              )}
                            </h5>
                            <div className="InfluencerPresentationVideo">
                              <Player
                                autoPlay={false}
                                muted={true}
                                src={influencer_presentation_video}
                                //src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                //src={/*influencer_presentation_video */ exampleVideo}
                              >
                                <BigPlayButton position="center" />
                                <ControlBar
                                  autoHide={true}
                                  disableDefaultControls={true}
                                >
                                  <PlayToggle />
                                  <VolumeMenuButton />
                                  <FullscreenToggle />
                                </ControlBar>
                              </Player>
                            </div>
                          </>
                        )}
                      </Col>
                      <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                        <div className="mt-3 aboutme_div_influencer">
                          <Row>
                            <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                              <h5>
                                {i18next.t(
                                  "ProfileInfluencerComponentLocation"
                                )}
                              </h5>
                              <p className="grey">
                                {false && <img src={map} />}
                                {user_location ?
                                  JSON.parse(user_location).city : i18next.t("ProfileInfluencerComponentNoContent")}
                              </p>
                            </Col>

                            {gmtPrefix && (
                              <Col
                                xs={{ span: 12 }}
                                lg={{ span: 5, offset: 1 }}
                              >
                                <h5>
                                  {i18next.t(
                                    "ProfileInfluencerComponentTimeZone"
                                  )}
                                </h5>
                                <p className="grey">{gmtPrefix}</p>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div ref={privateMeetingEventSlotsRef}>
              {showPrivateRequest &&
                privateMeetingEventSlots.length > 0 &&
                !mobileNav && (
                  <div className="ml-2 mt-1">
                    <UpcomingLiveEvents
                      dataInjected={privateMeetingEventSlots}
                      customTitle={i18next.t(
                        "ProfileInfluencerComponentPrivateRequestSlot"
                      )}
                      closeSection={() => {
                        setShowPrivateRequest(false);
                      }}
                    />
                  </div>
                )}

              {showPrivateRequest &&
                privateMeetingEventSlots.length > 0 &&
                mobileNav && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <h2 style={{ fontSize: "22px", marginBottom: "16px" }}>
                        {i18next.t(
                          "ProfileInfluencerComponentPrivateRequestSlot"
                        )}
                      </h2>
                      <div
                        className="Show-all"
                        onClick={() => {
                          setShowPrivateRequest(false);
                        }}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={close}
                          alt="close"
                          style={{ width: 30, height: 30 }}
                        />
                      </div>
                    </div>
                    <SlideCards
                      loading={loading}
                      noMargin
                      events={privateMeetingEventSlots}
                    />
                  </>
                )}
            </div>

            {!showPrivateRequest && upcomingEvents.length > 0 && !mobileNav && (
              <div className="ml-2 mt-1">
                <UpcomingLiveEvents dataInjected={upcomingEvents} />
              </div>
            )}

            {!showPrivateRequest &&
            events &&
            events.length > 0 &&
            !RESTRICTED_VIEWS ? (
              <Video
                events={events ? events : []}
                influencer_avatar={influencer_avatar}
                user={user}
              />
            ) : (
              !showPrivateRequest &&
              upcomingEvents.length > 0 &&
              mobileNav && (
                <>
                  <h2 style={{ fontSize: "22px", marginBottom: "16px" }}>
                    {i18next.t("ProfileInfluencerComponentUpcomingLiveEvents")}
                  </h2>
                  <SlideCards
                    loading={loading}
                    noMargin
                    events={upcomingEvents}
                  />
                </>
              )
            )}
            {!showPrivateRequest && pastEvents.length > 0 && (
              <Video pastVideos={pastEvents} noMargin />
            )}
          </div>
        </div>
      );
    }

    return (
      <div>
        <header>
          <Navbar />
          {renderHeaderTagsAndtitle(data)}
        </header>
        {modalDownload && (
          <ModalDownloadApp
            modalDownload={modalDownload}
            setModalDownload={setModalDownload}
            message={i18next.t(
              "ProfileInfluencerComponentDownloadOurAppToRequestPrivate"
            )}
          />
        )}
        <div
          className={
            "container profile_div " +
            (windowSize.width < 768 ? "profile_div_mobile" : "")
          }
        >
          <div className="row">
            <div
              className={
                "d-flex row col-sm-12 col-lg-8 " +
                (windowSize.width < 992 ? "text-center" : "")
              }
            >
              <div className="col-sm-12 col-lg-4">
                <img
                  className="img_profile_influencer"
                  src={influencer_avatar}
                />
              </div>
              <div className="about_user col-sm-12 col-lg-7">
                <h2 className="mb-1 name_profile">
                  {user_name} {user_lastname}
                </h2>
                <p className="username_profile mb-1">@{user_username}</p>

                <div
                  className={
                    windowSize.width < 992 ? "text-center" : "d-flex mt-2"
                  }
                >
                  <p>
                    <span className="followers">
                      {followers ? followers.length : "N/A"}{" "}
                    </span>
                    <span className="grey">
                      {i18next.t("ProfileInfluencerComponentFollowers")}
                    </span>
                  </p>
                </div>
                {env && env.token && follow !== undefined ? (
                  <div
                    className="d-flex justify-content-start mt-1"
                    style={{ marginBottom: "5%" }}
                  >
                    <button
                      style={{
                        border: follow === false && "1px solid red",
                        backgroundColor: follow === false && "white",
                        color: follow === false && "red",
                      }}
                      className=" Btn-rojo grow boton_go"
                      onClick={followUnfollow}
                    >
                      {follow
                        ? "✓ " +
                          i18next.t("ProfileInfluencerComponentFollowing")
                        : i18next.t("ProfileInfluencerComponentFollow")}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className=" col-sm-12 col-lg-4">
              <RatingPoints score={influencer_ranking} reviews={reviews} />
              <div className="">
                <h5>{i18next.t("ProfileInfluencerComponentSkills")}</h5>
                {categories.map(({ cate_ide, cate_description }) => (
                  <p className="username_profile mb-1" key={cate_ide}>
                    {cate_description}
                  </p>
                ))}
                {env && env.token && !hideNotReadyContent ? (
                  <div className="d-flex justify-content-start mt-3 mb-3">
                    <button
                      onClick={handlePrivateRequest}
                      className="Btn-rojo grow boton_go"
                    >
                      {i18next.t(
                        "ProfileInfluencerComponentRequestPrivateMeeting"
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-start mt-3 mb-3  ">
                    <button
                      className="Btn-rojo btn-private boton_go grow"
                      onClick={requestPrivateEventNonLoggedIn}
                    >
                      {i18next.t(
                        "ProfileInfluencerComponentRequestPrivateMeeting"
                      )}
                    </button>
                  </div>
                )}
                {influencer_subscription_data && (
                  <div className="d-flex justify-content-start mt-3 mb-3">
                    <button
                      onClick={handleSubscribe}
                      className="Btn-white grow boton_go"
                    >
                      {i18next.t(
                        "ProfileInfluencerComponentsSubscribeInfluencer"
                      )}
                    </button>
                  </div>
                )}
              </div>
              <h5>{i18next.t("ProfileInfluencerComponentLanguages")}</h5>
              <div
                className="d-flex align-items-center Cg "
                style={{ flexWrap: "wrap" }}
              >
                {languages.map(({ lang_ide, lang_description, lang_image }) => (
                  <span
                    style={{ marginRight: "4.5%", marginBottom: "1.5%" }}
                    key={lang_ide}
                  >
                    <img
                      style={{ height: "25px" }}
                      className="lenguage"
                      src={lang_image}
                      alt=""
                    />
                    &nbsp; {lang_description}
                  </span>
                ))}
              </div>
              <h5 className="mt-3">
                {i18next.t("ProfileInfluencerComponentFollowMeOn")}
              </h5>
              <div
                className="social-icons"
                style={{
                  justifyContent: windowSize.width < 992 ? "center" : "",
                }}
              >
                <SocialButtons
                  linkedinLink={
                    user_linkedin_data
                      ? JSON.parse(user_linkedin_data).vanityName
                      : undefined
                  }
                  twitterLink={
                    user_twitter_data
                      ? JSON.parse(user_twitter_data).screen_name
                      : undefined
                  }
                  //user_instagram_data, user_facebook_data
                  facebookLink={
                    user_facebook_data ? user_facebook_data : undefined
                  }
                  instagramLink={
                    user_instagram_data
                      ? JSON.parse(user_instagram_data).username
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
          <div className="mt-3 aboutme_div_influencer">
            <h5>{i18next.t("ProfileInfluencerComponentAboutMe")}</h5>
            <Linkify properties={{ target: "_blank" }}>
              <p className={windowSize.width < 992 ? "" : "aboutme_influencer"}>
                {influencer_aboutme}
              </p>
            </Linkify>

            <h5>{i18next.t("ProfileInfluencerComponentLocation")}</h5>
            <p className="grey">
              <img src={map} />
              {user_location && JSON.parse(user_location).city}
            </p>
          </div>
          {upcomingEvents.length > 0 && !mobileNav && (
            <div className="ml-2 mt-1">
              <UpcomingLiveEvents dataInjected={upcomingEvents} />
            </div>
          )}

          {events && events.length > 0 && !RESTRICTED_VIEWS ? (
            <Video
              events={events ? events : []}
              influencer_avatar={influencer_avatar}
              user={user}
            />
          ) : (
            upcomingEvents.length > 0 &&
            mobileNav && (
              <>
                <h2 style={{ fontSize: "22px", marginBottom: "16px" }}>
                  {i18next.t("ProfileInfluencerComponentUpcomingLiveEvents")}
                </h2>
                <SlideCards
                  loading={loading}
                  noMargin
                  events={upcomingEvents}
                />
              </>
            )
          )}
          {pastEvents.length > 0 && <Video pastVideos={pastEvents} noMargin />}
        </div>
      </div>
    );
  } else {
    if (!isMobile) {
      window.location.href = "/404";
    } else {
      window.location.href = "/";
    }
  }
};

const mapStateToProps = (state) => {
  return {
    userLogged: state.user.user,
  };
};

export default withRouter(
  WithAlert(connect(mapStateToProps)(ProfileInfluencer))
);
