import { useEffect, useState, useCallback } from "react";

const useBillingLogic = ({user}) => {
  const [purchasedEvents, setPurchasedEvents] = useState([]);
  
  useEffect(() => {
    if (user.user.purchasedEvents && user.user.purchasedEvents.length > 0) {
      setPurchasedEvents(user.user.purchasedEvents);
    }
  }, [user]);

  const filterPurchasedEvents = (searchTerm) => {
    const filteredEvents = user.user.purchasedEvents.filter(function (event) {
      return event.event.event_title.includes(searchTerm);
    });
    setPurchasedEvents(filteredEvents);
  }

  return { purchasedEvents, filterPurchasedEvents };
};

export default useBillingLogic