import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import i18next from 'i18next';

const Filtro_select = ({options, getFatherCategory, getChildrenCategory, disabled}) => {
  const handleSelect = (e) => {
    if(getFatherCategory) {
      getFatherCategory(e.value);
      getChildrenCategory('');
    } else if(getChildrenCategory) {
      getChildrenCategory(e.value);
    }
  }
  return (

      <Select
        isDisabled={disabled}
        defaultValue={options[0]} 
        options={options}
        onChange={handleSelect}
        placeholder={i18next.t('FiltroComponentSearchPlaceholder') + "..."}
        noOptionsMessage={() => i18next.t('FiltroComponentSearchNoOptionsFound')}
      />
  );
};

Filtro_select.propTypes = {
  options: PropTypes.array.isRequired
};

Filtro_select.defaultProps = {
  options: []
};

export default Filtro_select;
