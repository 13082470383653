import React, { useState, useEffect } from 'react';
import '../../../comunes/upcomingLiveEvents/card_upcoming/style.scss';
import './style.scss';


const SkillCategories = ({categories, userCategories, onChangeCategory, onSave, loader}) => {
    const [listCategories, setListCategories] = useState([]);


    const getCategories = () => {
        const categoriesValues = categories.filter(cat =>  {
                return userCategories.indexOf(cat.cate_ide) > -1
        })

        return categoriesValues
    }

    useEffect(() => {
        getCategories();
        setListCategories(getCategories)
    },[userCategories])

    const subCategoriesComponent = (id) => {
        const subCategoriesValues = categories.filter(cat => {
            return cat.cate_parent_id === id;
        })
        return (
            <React.Fragment>
                {subCategoriesValues && 
                    <div className="skill-sub-category-content d-flex">
                        {subCategoriesValues.map(sc => (
                            <React.Fragment>
                                <label key={sc.cate_ide} onClick={() => onChangeCategory(sc.cate_ide)}>
                                    <input value={sc.cate_description}
                                           name="categories-user"
                                           type="checkbox"
                                           checked={userCategories.indexOf(sc.cate_ide) > -1}
                                    />
                                    {sc.cate_description}
                                </label>
                            </React.Fragment>
                        ))}
                    </div>
                }
            </React.Fragment>
        )
    }
    return ( 
        <div className="skill-categories mb-5">
            <div className="row">
                <div className="col-md-12">
                    {Array.isArray(listCategories) &&
                        <React.Fragment>
                            {listCategories.map(c =>(
                                <div className="d-flex mb-4" key={c.cate_ide}>
                                    <div className="skill-categories-content w-100">
                                        <div className="d-flex align-items-start">
                                        <img src={c.cate_image} alt={c.cate_description} />
                                        <div className="d-flex w-100 skill-categories-elements col-xs-12  col-sm-12 col-md-8 col-lg-8">
                                            <h2>
                                                {c.cate_description}
                                            </h2>
                                            {/* <p>Choose a maximum of three subcategories</p>
                                            {subCategoriesComponent(c.cate_ide)} */}
                                        </div>

                                        </div>
                                        
                                        <div className="">
                                            <div className="d-flex justify-content-end">
                                            <button className="Btn-rojo cancel" 
                                                    onClick={() => onChangeCategory(c.cate_ide)}>Delete</button>
                                            {/* <button className="Btn-rojo save"  
                                                    type="submit" 
                                                    form="form_update"
                                                    onClick={onSave}>
                                                {loader ? 'Loading....' : 'Save'}
                                            </button> */}
                                            </div>
                                            
                                        </div>
                                    </div> 
                                </div>
                            ))}
                            
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    );
}
export default SkillCategories;