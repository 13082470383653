import React from "react";
import "./style.scss";
import NavBar_liveEvents from "./navbar_live_events";
import UpcomingLiveEventsPage from "./upcoming_live_events";
import Past_live_events from "./past_live_events";
import FavoriteLiveEvents from "./favorite_live_events";
import { Switch, Route } from "react-router-dom";
import HappeningInfluencer from "./happening/HappeningInfluencer"
function LiveEventsPage() {
  return (
    <div className="Live-Events-Page">
      <NavBar_liveEvents />
      <Switch>
        <Route
          path="/my_live_events/drafts_live_events"
          component={FavoriteLiveEvents}
        />
        <Route
          path="/my_live_events/past_live_events"
          component={Past_live_events}
        />
        <Route
          path="/my_live_events/happening"
          component={HappeningInfluencer}
        />
        <Route
          path="/my_live_events"
          exact
          component={UpcomingLiveEventsPage}
        />
      </Switch>
    </div>
  );
}

export default LiveEventsPage;
