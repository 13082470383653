import React, {useEffect, useState} from "react";
import NavBarGeneral from "../comunes/navbar_influencer";
import "./style.scss";
import SearchInputMessage from "../comunes/search_input_messages";
import FilteredList from "../comunes/chat";
import Time from "../../assets/img/icons/ic-time-copy.svg";
import Check from "../../assets/img/icons/ic-check.svg";
import { usePrivateMessageInfluencerLogic } from "./PrivateMessageInfluencerLogic";
import { RequestDetail } from "./component";
import { WithAlert } from "../../hocs/withAlert/WithAlert";
import Loader from "components/comunes/loader";
import modesUser from "../../constants/modesUser";
import moment from "moment";
import RequestMessages from "components/comunes_events/RequestMessages/RequestMessages"
import HowItWorks from 'components/comunes_events/modalEvent/HowItWorks'
import useMobilenav from "../../hooks/useMobileNav"
import MobileDetails from '../Message/private-message/MobileDetails'
import i18next from "i18next";

const imagesPath = {
  minus: Check,
  plus: Time
};

const MessageMeetingInfluencer = ({ showAlert }) => {
  const [timeAgo, setTimeAgo] = useState('')
  const [showHowItWorks, setShowHowItWorks] = useState(false)
  const {mobileNav} = useMobilenav()
  const [showMobileDetails, setShowMobileDetails] = useState(false)

  const {
    state,
    onChangeSearchInput,
    onPressRequest,
    onRejectRequest,
    onAcceptRequest,
    getFilteredRequests
  } = usePrivateMessageInfluencerLogic({ showAlert });
  const { searchInput, requests: requestsRaw, requestActive, loadingRequests, rejecting, acepting } = state;
  const requests = [
    ...(requestsRaw.pending_payment || []),
    ...(requestsRaw.pending || []),
    ...(requestsRaw.accepted || []),
    ...(requestsRaw.rejected || []),
    ...(requestsRaw.canceled || [])
  ];

  useEffect(()=> {
    var now = moment()
    if (requestActive && requestActive.request){
  const lastInteraction= moment(parseInt(requestActive.request.request_updated_date ||
    requestActive.request.request_created_date))
  const timeAgo = now.to(lastInteraction)
    setTimeAgo(timeAgo)
    }
  }, [requestActive])
  return !loadingRequests ? (
    <div className="messages_details_private_influencer">
      <header className="App-header">
        <NavBarGeneral />
      </header>
      <MobileDetails
        
        showMobileDetails={showMobileDetails}
        setShowMobileDetails={setShowMobileDetails}
        onAcceptRequest={onAcceptRequest}
         onRejectRequest={onRejectRequest}
          requestActive={requestActive}
          imagesPath={imagesPath}
          onReject={onRejectRequest}
          onAccept={onAcceptRequest}
          rejecting={rejecting}
          accepting={false}
          isViewer={false}
        />
      {showHowItWorks && <HowItWorks setShowHowItWorks={setShowHowItWorks} showHowItWorks={showHowItWorks}/> }

      <section className="container-fluid some2">
        <div className="row" >
          <div style={{borderRight:'1px solid #D8DCE5', paddingTop:'20px', paddingRight:'0px'}} className="px-4 px-md-0 col-md-3 ml-md-5">
            <div className="d-flex">
              <h2 style={{fontSize:'25px', fontWeight:'500' }} className="mt-3 mb-3">{i18next.t("PrivateMessageInfluencerRequests")}</h2>
            </div>
            <p style={{cursor:'pointer'}} onClick={()=> setShowHowItWorks(true)} className="mb-3 works">{i18next.t("PrivateMessageInfluencerHowItWorks")}</p >
            {/* <SearchInputMessage
              value={searchInput}
              onChange={onChangeSearchInput}
            /> */}
            <FilteredList
            accepting={acepting}
            setShowMobileDetails={setShowMobileDetails}
              data={getFilteredRequests(requests)}
              onPressItem={onPressRequest}
              modeUser={modesUser.influencer}
              isViewer={true}
            />          
          </div>
          {!mobileNav &&<div className="col-5">
            <div style={{ paddingTop: '20px' }} className=" date">
              <div className="bar"></div>
              {timeAgo && timeAgo.length>0 &&  <p style={{color:'color : #808080'}} className="text-center ml-3">{i18next.t("PrivateMessageInfluencerLastUpdate")} {timeAgo}</p>}
              <div className="bar"></div>
            </div>

            <div style={{ paddingTop: '60px' }} className="">

              <RequestMessages requestActive={requestActive} isViewer={false} />

              
            </div>
          </div>
}
{ !mobileNav && 

          <RequestDetail
          
            data={requestActive}
            imagesPath={imagesPath}
            onReject={onRejectRequest}
            onAccept={onAcceptRequest}
            rejecting={rejecting}
            acepting={acepting}
          />
}

         
        </div>
      </section>
    </div>
  ) : (
    <div className="messages_details_private_influencer">
      <header className="App-header">
        <NavBarGeneral />
      </header>
      <section className="container-fluid some2">
        <Loader />
      </section>
    </div>
  );
};

export default WithAlert(MessageMeetingInfluencer);
