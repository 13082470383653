import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect,
  withRouter,
  useLocation,
  useParams
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router";
import Store from "./Store";
import Sign from "./Sign";
import "./assets/css_general/general.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.css";
import ScrollToTop from "./ScrollToTop";
// Test blocking branch
// import ScrollToTop from "react-router-scroll-top";
import Footer from "./components/comunes/footer";
import Home from "./components/discover";
import HomeReferral from "./pages/b2bLanding/B2BLanding";
import { RedirectRallyPage } from "./pages/redirect/Rally";
import HomePrivateMarketplace from "./components/homePrivateMarketPlace";
import ErrorNotFound from "./components/ErrorNotFound";
import SubscriptionSuccess from "./components/SubscriptionSuccess";
import MobileHome from "./components/mobile_home";
import Search from "./components/search_page/search";
import SearchByCategory from "./components/search_page/search_by_category";
import AllInfluencers from "./components/search_page/all_influencers";
import AllVideos from "./components/search_page/all_videos";
import AllUpcomingLiveEvents from "./components/search_page/all_upcoming_live_events";
import LiveEventsPage from "./components/live_events_page";
import Modal_sign from "./components/comunes/login_menu/modals-login/modal_register/index";
import Modal_login from "./components/comunes/login_menu/modals-login/modal_login/index";
import Modal_recover from "./components/comunes/login_menu/modals-login/modal_recover";
import Modal_basic_info from "./components/comunes/login_menu/modals-login/modal_fill_basic_info/index";
import DacastStreaming from "./components/dacast-streaming";
import Modal_verify_influencer from "./components/comunes/login_menu/modals-login/modal_verify_influencer";
import ModalVerifyPhone from "./components/comunes/login_menu/modals-login/modal_verify_phone";
import streaming_moderator from "./components/view-moderator";
import banned_moderator from "./components/view-banned";
import LiveEventInfluencer from "./components/live_events_page_influencer";
import LoginTicket from "./pages/loginTicket/LoginTicket";
import past_streaming_influencer from "./components/past_streaming_influencer";
import messagecopy from "./components/Message copy/inbox";
import PrivateMeeting from "./components/Message/private-message";
import RequestPrivateMeeting from "./components/Request_private";
import PublishEvent from "./components/publish-live-events";
import PitchEvent from "./components/publish-live-events/pitch_event";
import UpcomingLiveEventInfluencer from "./components/comunes_events/live_event_influencer";
//import UpcomingDetailsInfluencer from "./components/preview-streaming_influencer";
import UpcomingDetailsInfluencer from "./components/interactive_streaming";
import ViewStreamingInfluencer from "./components/view-streaming_influencer";
import ChooseModerator from "./components/view-choose-moderator";
import PrivateMeetingInfluencer from "./components/private-message_influencer";
import PublishEventPrivate from "./components/publish-private-live-events";
import MessageInfluencer from "./components/Message_influencer";
import Loader from "./components/comunes/loader";
import Stats from "./components/Stats";
import CardsBalance from "./components/my_balance";
import ViewerProfile from "./components/viewer_profile";
import BecomeInfluencerParent from "./components/become_influencer";
import Billing from "./components/Billing";
import Profile from "./components/profile";
import Friends from "./components/profile/friends";
import Followers from "./components/profile/following";
import NewInfluencerProfile from "./components/new_profile_influencer";
import ProfileInfluencer from "./components/profile_influencer";
import PublicProfileInfluencer from "./components/public_profile_influencer";
import BillingViewer from "./components/Billing_viewer";
import MyBalance from "./components/my_influencer_balance";
import MyInfluencerPayoutMethods from "./components/my_influencer_payout_methods";
import MyTransactions from "./components/my_influencer_transactions";
import MyViewerPaymentMethods from "./components/my_viewer_payment_methods";
import { connect, useSelector } from "react-redux";
import * as actions from "./store/actions";
import "react-google-places-autocomplete/dist/assets/index.css";
import LinkedinPage from "./components/linkedin/LinkedinPage";
import ApplePage from "./components/apple/ApplePage";
import FacebookPage from "./components/facebook";
import InstagramPage from "./components/instagram";
import instagramSignUp from "./components/instagram_sign_up";
import Help from "./components/help";
import VerifyTwitterLogin from "./components/comunes/login_menu/VerifyLogin/VerifyTwitterLogin";
import VerifyInfluencerTwitter from "./components/comunes/login_menu/VerifyInfluencer/VerifyInfluencerTwitter";
import VerifyLinkedinLogin from "./components/comunes/login_menu/VerifyLogin/VerifyLinkedinLogin";
import VerifyFacebookLogin from "./components/comunes/login_menu/VerifyLogin/VerifyFacebookLogin";
import VerifyInfluencerLinkedin from "./components/comunes/login_menu/VerifyInfluencer/VerifyInfluencerLinkedin";
import ConfirmEmail from "./pages/confirmEmail/ConfirmEmail";
import RecoverPassword from "./pages/recoverPassword/RecoverPassword";
import Referral from "./pages/referral/Referral";
import ReferralWellness from "./pages/referral/ReferralWellness";
import EventPage from "./pages/event/EventPage";
import SubscribeInfluencer from "./components/SubscribeInfluencer";
import RequestPrivate from "./pages/requestPrivate/RequestPrivate";
import RequestEdit from "./pages/requestEdit/RequestEdit";
import PublishPrivateEvent from "./pages/publishPrivateEvent/PublishPrivateEvent";
import { isMobile } from "react-device-detect";
import {
  IS_SIGN_UP_ENABLED,
  IS_INFLUENCER_STREAMING_ENABLED,
  IS_DACAST_STREAMING_ENABLED,
  IS_VIEWER_STREAMING_ENABLED,
  DESKTOP_ONLY_MODE,
  INFLUENCER_ONLY_MODE,
  HOME_PAGE_PAYPAL_REDIRECT,
  VIEWER_ROLE,
  IS_DEV_ENVIRONMENT,
  CANONICAL_HOME_PAGE,
  B2B_PORT,
  BRAND_NAME,
  PRIVATE_MARKETPLACE,
  LOGIN_MAIN_PAGE,
  REFERRAL_HOME_PAGE,
  DISABLE_FOOTER_HOME
} from "./environment/environment";
import { Helmet } from "react-helmet";
import TermsOfService from "./pages/terms/TermsOfService";
import PrivacyPolicy from "./pages/terms/PrivacyPolicy";
import TermsOfServiceNonGotmy from "./pages/terms/NonGotmy/TermsOfService";
import PrivacyPolicyNonGotmy from "./pages/terms/NonGotmy/PrivacyPolicy";
import StripeBack from "./pages/stripeCallback/StripeBack";
import PaypalBack from "./pages/paypalBack/PaypalBack";
import Modal from "react-modal";
import ModalDownloadApp from "./components/public_profile_influencer/ModalDownloadApp";
import CookieConsent from "react-cookie-consent";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import en from "./translations/en";
import es from "./translations/es";
import { parseLocale } from "./utils/Localization";
import moment from "moment";
import styles from "./assets/css_general/general.scss";

const history = createBrowserHistory();

i18next.init(
  {
    lng: parseLocale(), //['es', 'es'],
    fallbackLng: true,
    interpolation: {
      formatSeparator: ",",
      format: function(value, formatting, lng) {
        if (value instanceof Date) {
          if (formatting) {
            return moment(value).format("L");
          } else {
            return value;
          }
        }
        return value.toString();
      }
    },
    debug: false,
    resources: {
      en: {
        translation: en
      },
      es: {
        translation: es
      }
    }
  },
  function(err, t) {
    // initialized and ready to go!
    //nothing to do
  }
);

function renderSigningElements(shouldRender) {
  if (B2B_PORT) {
    return null;
  } else {
    if (shouldRender) {
      return (
        <div>
          <Modal_sign />
          <Modal_login />
          <Modal_basic_info />
          <Modal_recover />
          <Modal_verify_influencer />
          <ModalVerifyPhone />
          <VerifyTwitterLogin />
          <VerifyInfluencerTwitter />
          <VerifyLinkedinLogin />
          <VerifyFacebookLogin />
          <VerifyInfluencerLinkedin />
        </div>
      );
    } else {
      return null;
    }
  }
}

function renderHeaderTagsAndtitle() {
  //const { t, i18n } = useTranslation();

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{i18next.t("GotmyLandingHeaderoOGTitle")}</title>
      <meta
        name="og:description"
        content={i18next.t("GotmyLandingHeaderDescription")}
      />
      <meta
        property="og:title"
        content={i18next.t("GotmyLandingHeaderoOGTitle")}
      />
      <meta
        property="og:image"
        //content="https://www.gotmy.com/static/media/logo-white.83874cd2.png"
      />
      <meta property="og:url" content={CANONICAL_HOME_PAGE} />
      <link rel="canonical" href={CANONICAL_HOME_PAGE} />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
      />
    </Helmet>
  );
}

const App = ({
  onGetCategories,
  onGetCurrencies,
  onGetLanguages,
  onGetUser,
  onGetInfluencer,
  user,
  languages,
  error,
  clearErrors,
  isInfluencer
}) => {
  const [renderFooter, setRenderFooter] = useState(false);
  const [cookiesPosition, setCookiesPosition] = useState("fixed");
  const [cookiesDisabled, setCookiesDisabled] = useState(false);
  const [influencerRedirect, setInfluencerRedirect] = useState(false);
  const [influencerRedirectLoading, setInfluencerRedirectLoading] = useState(
    true
  );
  const [localError, setLocalError] = useState(null);
  const [modalDownload, setModalDownload] = useState(false);
  const { t, i18n } = useTranslation();
  const isAdmin = useSelector(state => state.user.user.user_is_admin);

  const init = async () => {
    await onGetCategories();
    await onGetCurrencies();
    if (
      !window.location.pathname.includes("api/payment/stripe_callback") &&
      !window.location.pathname.includes("paypal_callback") &&
      !window.location.pathname.includes("/subscribe") &&
      !window.location.pathname.includes("instagramSignUp") &&
      !window.location.pathname.includes("streamingDacastMobile") &&
      !window.location.pathname.includes("/talent") &&
      !window.location.pathname.includes("/b2b") &&
      !window.location.pathname.includes("/wellness")
    ) {
      if (!DISABLE_FOOTER_HOME) {
        if (window.location.pathname.includes("broadcast")) {
          if (!isMobile) {
            setRenderFooter(true);
          }
        } else {
          setRenderFooter(true);
        }
      }
    }

    if (!window.location.pathname.includes("streamingDacastMobile")) {
      setCookiesDisabled(true);
    }

    if (
      (isMobile && !window.location.pathname.includes("/wellness")) ||
      !window.location.pathname.includes("/streaming")
    ) {
      setCookiesPosition("relative");
    }

    const env = JSON.parse(localStorage.getItem("env") || "{}");
    if (env.token && Object.keys(user).length === 0) {
      await onGetUser(env.token);
    }
    if (languages.length === 0) {
      await onGetLanguages();
    }
    if (env.token) {
      onGetInfluencer(env.token);
    }

    setLocalError(error);
  };
  useEffect(() => {
    if (user.user_role === VIEWER_ROLE && INFLUENCER_ONLY_MODE) {
      //setModalDownload(true)
      //logoutUser()
      // PUSH TO  HOMEPAGE
      // PREGUNTAR GABRI
    }
  }, [user]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isInfluencer) {
      if (INFLUENCER_ONLY_MODE) {
        setInfluencerRedirect(true);
      }
      setInfluencerRedirectLoading(false);
    } else {
      setInfluencerRedirect(false);
      setInfluencerRedirectLoading(false);
    }
  }, [isInfluencer]);

  useEffect(() => {
    setLocalError(error);
  }, [error]);

  if (!PRIVATE_MARKETPLACE && window.location.pathname.includes("/rally")) {
    return <div></div>;
  }

  if (PRIVATE_MARKETPLACE && window.location.pathname.includes("/rally")) {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <BrowserRouter>
          <ScrollToTop />
          <Store>
            <Sign>
              <Switch>
                <Route path="/" component={RedirectRallyPage} />
              </Switch>
            </Sign>
          </Store>
        </BrowserRouter>
      </Router>
    );
  }

  if (PRIVATE_MARKETPLACE) {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <BrowserRouter>
          <ScrollToTop />
          <Store>
            <div id={"mainAppContainer"} className="App position-relative">
              {cookiesDisabled && (
                <CookieConsent
                  location="top"
                  buttonText="close"
                  cookieName="gotmyCookies"
                  style={{ background: styles.mainBackground }}
                  buttonStyle={{
                    //backgroundColor: styles.mainColor,
                    color: "white",
                    borderRadius: 25,
                    paddingBottom: "0.75vh",
                    paddingTop: "0.75vh",
                    paddingLeft: "1.5vw",
                    paddingRight: "1.5vw"
                  }}
                  expires={150}
                >
                  <span className={"gotmy-title-cookies-banner"}>
                    {BRAND_NAME}
                  </span>{" "}
                  {i18next.t("CookiesWarningText")}
                </CookieConsent>
              )}
              <Sign>
                {renderHeaderTagsAndtitle()}
                {renderSigningElements(IS_SIGN_UP_ENABLED)}

                <Switch>
                  {LOGIN_MAIN_PAGE ? (
                    <Route path="/" exact component={HomeReferral} />
                  ) : (
                    <Route path="/" exact component={EventPage} />
                  )}

                  {LOGIN_MAIN_PAGE && isAdmin && (
                    <Route path="/discover" exact component={Home} />
                  )}

                  <Route
                    path="/private"
                    exact
                    component={HomePrivateMarketplace}
                  />

                  <Route path="/viewer_profile" component={ViewerProfile} />
                  <Route
                    path="/become_influencer"
                    component={BecomeInfluencerParent}
                  />

                  <Route path="/profile" component={Profile} />

                  <Route
                    path="/influencer_profile/:active/:category?"
                    component={ViewerProfile}
                  />
                  <Route
                    path="/new_influencer_profile"
                    component={NewInfluencerProfile}
                  />
                  <Route
                    path="/profile_influencer"
                    component={ProfileInfluencer}
                  />

                  <Route
                    path="/my_live_events"
                    component={LiveEventInfluencer}
                  />

                  <Route
                    exact
                    path="/reset-password/:token"
                    component={RecoverPassword}
                  />

                  {(isInfluencer || user.user_role === 1) && (
                    <Route
                      exact
                      path="/publish_events"
                      component={PublishEvent}
                    />
                  )}

                  <Route
                    path="/mobileStreaming/:uuid"
                    exact
                    component={() => {
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      const { uuid } = useParams();
                      window.location.href = `https://gotmy.com/mobileStreaming/${uuid}`;
                    }}
                  />

                  <Route path="/404" exact component={ErrorNotFound} />
                  <Route exact path="/tof.html">
                    <Redirect to="/terms/service" />
                  </Route>
                  <Route
                    path="/terms/service"
                    exact
                    component={TermsOfServiceNonGotmy}
                  />
                  <Route exact path="/privacy.html">
                    <Redirect to="/terms/privacy" />
                  </Route>
                  <Route
                    path="/terms/privacy"
                    exact
                    component={PrivacyPolicyNonGotmy}
                  />

                  <Route path="/event/:id" exact component={EventPage} />

                  <Route
                    //path="/preview_influencer/:id"
                    path="/streaming/:id"
                    component={UpcomingDetailsInfluencer}
                  />

                  <Route path="/broadcast/:id" component={DacastStreaming} />

                  <Route path="/ticket/:ticket" component={LoginTicket} />

                  <Route path="/help" component={Help} />

                  <Route
                    path="/influencer/:id"
                    component={PublicProfileInfluencer}
                  />
                  <Route path="/stripeAccounCallback" component={StripeBack} />
                  <Route
                    path="/payment/paypal_callback"
                    component={PaypalBack}
                  />
                  <Route
                    path="/payment/paypal_callback_error"
                    component={PaypalBack}
                  />

                  <Route path="/:id" component={PublicProfileInfluencer} />
                </Switch>
              </Sign>
              <Modal // USAR ESTE MODAL PARA ERRORES
                isOpen={localError !== null}
                onRequestClose={() => {
                  clearErrors();
                }}
                //style={{ position: 'absolute', top: window.innerHeight * 0.3, height: window.innerHeight * 0.3, left: window.innerWidth * 0.25, width: window.innerWidth * 0.5 }}
                style={{
                  overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.75)"
                  },
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                    paddingTop: "2.5%",
                    paddingBottom: "2.5%",
                    alignContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column"
                  }
                }}
              >
                <h2>Ups there was a problem</h2>

                {/* <div><p>{localError}</p></div> */}
                <div>
                  <p>There's been an error</p>
                </div>

                <button
                  style={{
                    color: "white",
                    backgroundColor: "#ff5a60",
                    width: "auto",
                    padding: 50,
                    paddingBottom: "2.5%",
                    paddingTop: "2.5%",
                    borderWidth: 0,
                    borderRadius: 50
                  }}
                  onClick={() => {
                    clearErrors();
                    //this.setState({ show: false })
                  }}
                >
                  Close
                </button>
              </Modal>
              {renderFooter && (
                <footer className={"dropShadowFooter"}>
                  <Footer />
                </footer>
              )}
            </div>
          </Store>
          {/* </ScrollToTop> */}
        </BrowserRouter>
      </Router>
    );
  }

  if (PRIVATE_MARKETPLACE) {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <BrowserRouter>
          <ScrollToTop />
          <Store>
            <div id={"mainAppContainer"} className="App position-relative">
              {cookiesDisabled && (
                <CookieConsent
                  location="bottom"
                  buttonText="close"
                  cookieName="gotmyCookies"
                  style={{ background: styles.mainBackground }}
                  buttonStyle={{
                    //backgroundColor:  styles.mainBackground,
                    color: "white",
                    borderRadius: 25,
                    paddingBottom: "0.75vh",
                    paddingTop: "0.75vh",
                    paddingLeft: "1.5vw",
                    paddingRight: "1.5vw"
                  }}
                  expires={150}
                >
                  <span className={"gotmy-title-cookies-banner"}>
                    {BRAND_NAME}
                  </span>{" "}
                  {i18next.t("CookiesWarningText")}
                </CookieConsent>
              )}
              <Sign>
                {renderHeaderTagsAndtitle()}

                <Switch>
                  <Route
                    exact
                    path="/confirm-email/:token"
                    component={ConfirmEmail}
                  />
                  {influencerRedirect && !influencerRedirectLoading ? (
                    <Route exact path="/">
                      <Redirect to="/my_live_events" />
                    </Route>
                  ) : (
                    !influencerRedirect &&
                    !influencerRedirectLoading && (
                      <Route
                        path="/"
                        exact
                        component={HomePrivateMarketplace}
                      />
                    )
                  )}
                  <Route
                    path="/mobileStreaming/:uuid"
                    exact
                    component={() => {
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      const { uuid } = useParams();
                      window.location.href = `https://gotmy.com/mobileStreaming/${uuid}`;
                    }}
                  />
                  <Route path="/404" exact component={ErrorNotFound} />
                  <Route path="/linkedin" exact component={LinkedinPage} />
                  <Route path="/facebook" exact component={FacebookPage} />
                  <Route path="/instagram" exact component={InstagramPage} />
                  <Route
                    path="/instagramSignUp"
                    exact
                    component={instagramSignUp}
                  />
                  <Route path="/apple/callback" exact component={ApplePage} />
                  <Route exact path="/tof.html">
                    <Redirect to="/terms/service" />
                  </Route>
                  <Route
                    path="/terms/service"
                    exact
                    component={TermsOfService}
                  />
                  <Route exact path="/privacy.html">
                    <Redirect to="/terms/privacy" />
                  </Route>
                  <Route
                    path="/terms/privacy"
                    exact
                    component={PrivacyPolicy}
                  />
                  <Route path="/b2b" exact component={HomeReferral} />
                  <Route
                    exact
                    path="/reset-password/:token"
                    component={RecoverPassword}
                  />
                  {influencerRedirect && !influencerRedirectLoading ? (
                    <Route exact path="/search">
                      <Redirect to="/my_live_events" />
                    </Route>
                  ) : (
                    !influencerRedirect && (
                      <Route
                        path="/search"
                        exact
                        component={influencerRedirectLoading ? Loader : Search}
                      />
                    )
                  )}
                  <Route path="/search/:query" exact component={Search} />
                  <Route
                    path="/search/category/:id"
                    exact
                    component={SearchByCategory}
                  />
                  <Route
                    path="/all_influencers"
                    exact
                    component={AllInfluencers}
                  />
                  <Route
                    path="/all_upcoming_live_events"
                    exact
                    component={AllUpcomingLiveEvents}
                  />

                  <Route path="/all_videos" exact component={AllVideos} />
                  <Route path="/live_events" component={LiveEventsPage} />
                  <Route
                    path="/successSuscription"
                    exact
                    component={SubscriptionSuccess}
                  />
                  <Route path="/event/:id" exact component={EventPage} />
                  <Route
                    path="/subscribe/:id/:userToken?"
                    component={SubscribeInfluencer}
                  />
                  <Route path="/talent/:id" exact component={Referral} />
                  <Route path="/talent/" exact component={Referral} />
                  <Route
                    path="/wellness/:id"
                    exact
                    component={ReferralWellness}
                  />
                  <Route path="/wellness/" exact component={ReferralWellness} />
                  <Route
                    path="/moderator-view"
                    component={streaming_moderator}
                  />
                  <Route
                    path="/banned_moderator"
                    component={banned_moderator}
                  />
                  {IS_INFLUENCER_STREAMING_ENABLED ? (
                    influencerRedirect ? (
                      <Route exact path="/">
                        <Redirect to="/my_live_events" />
                      </Route>
                    ) : (
                      <Route
                        //path="/preview_influencer/:id"
                        path="/streaming/:id"
                        component={UpcomingDetailsInfluencer}
                      />
                    )
                  ) : null}
                  {IS_DACAST_STREAMING_ENABLED ? (
                    influencerRedirect ? (
                      <Route exact path="/">
                        <Redirect to="/my_live_events" />
                      </Route>
                    ) : (
                      <Route
                        path="/broadcast/:id"
                        component={DacastStreaming}
                      />
                    )
                  ) : null}

                  {IS_INFLUENCER_STREAMING_ENABLED ? (
                    <Route
                      //path="/preview_influencer/:id"
                      path="/streaming/:id"
                      component={UpcomingDetailsInfluencer}
                    />
                  ) : null}
                  {
                    <Route
                      path="/my_live_events"
                      component={LiveEventInfluencer}
                    />
                  }
                  {
                    <Route
                      exact
                      path="/confirm-email/:token"
                      component={ConfirmEmail}
                    />
                  }
                  <Route path="/ticket/:ticket" component={LoginTicket} />
                  <Route path="/message/:id" exact component={PrivateMeeting} />
                  <Route
                    path="/message/private_request"
                    exact
                    component={PrivateMeeting}
                  />
                  <Route
                    path="/private_request/:influencerId"
                    component={RequestPrivate}
                  />

                  {isInfluencer && (
                    <Route
                      path="/private_meeting"
                      component={PrivateMeetingInfluencer}
                    />
                  )}
                  <Route
                    path="/private_publish/:ide"
                    component={PublishPrivateEvent}
                  />
                  {(isInfluencer || user.user_role === 1) && (
                    <Route
                      exact
                      path="/publish_events"
                      component={PublishEvent}
                    />
                  )}
                  <Route
                    path="/upcoming_influencer/:id"
                    exact
                    component={UpcomingLiveEventInfluencer}
                  />
                  <Route path="/change_moderator" component={ChooseModerator} />
                  <Route
                    path="/request_edit/:id"
                    component={RequestEdit}
                    exact
                  />

                  <Route
                    path="/message_influencer"
                    exact
                    component={MessageInfluencer}
                  />
                  <Route path="/stats" component={Stats} />

                  {false && (
                    <Route path="/my_balance" component={CardsBalance} />
                  )}
                  <Route path="/viewer_profile" component={ViewerProfile} />
                  <Route
                    path="/become_influencer"
                    component={BecomeInfluencerParent}
                  />
                  {IS_DEV_ENVIRONMENT && (
                    <Route path="/billing" component={Billing} />
                  )}
                  <Route path="/profile" component={Profile} />
                  <Route path="/friends" component={Friends} />
                  {false && <Route path="/followers" component={Followers} />}
                  <Route
                    path="/influencer_profile/:active/:category?"
                    component={ViewerProfile}
                  />
                  <Route
                    path="/new_influencer_profile"
                    component={NewInfluencerProfile}
                  />
                  <Route
                    path="/profile_influencer"
                    component={ProfileInfluencer}
                  />

                  <Route path="/billing_viewer" component={BillingViewer} />
                  <Route path="/my_balance" component={MyBalance} />
                  <Route
                    path="/my_influencer_payout_methods"
                    component={MyInfluencerPayoutMethods}
                  />
                  <Route path="/my_transactions" component={MyTransactions} />
                  <Route
                    path="/my_viewer_payment_methods"
                    component={MyViewerPaymentMethods}
                  />
                  <Route path="/help" component={Help} />
                  <Route
                    path="/successSuscription"
                    exact
                    component={SubscriptionSuccess}
                  />
                  <Route
                    path="/influencer/:id"
                    component={PublicProfileInfluencer}
                  />
                  <Route path="/stripeAccounCallback" component={StripeBack} />
                  <Route
                    path="/payment/paypal_callback"
                    component={PaypalBack}
                  />
                  <Route
                    path="/payment/paypal_callback_error"
                    component={PaypalBack}
                  />

                  <Route path="/:id" component={PublicProfileInfluencer} />
                </Switch>
              </Sign>
              <Modal // USAR ESTE MODAL PARA ERRORES
                isOpen={localError !== null}
                onRequestClose={() => {
                  clearErrors();
                }}
                //style={{ position: 'absolute', top: window.innerHeight * 0.3, height: window.innerHeight * 0.3, left: window.innerWidth * 0.25, width: window.innerWidth * 0.5 }}
                style={{
                  overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.75)"
                  },
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                    paddingTop: "2.5%",
                    paddingBottom: "2.5%",
                    alignContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column"
                  }
                }}
              >
                <h2>Ups there was a problem</h2>

                {/* <div><p>{localError}</p></div> */}
                <div>
                  <p>There's been an error</p>
                </div>

                <button
                  style={{
                    color: "white",
                    backgroundColor: "#ff5a60",
                    width: "auto",
                    padding: 50,
                    paddingBottom: "2.5%",
                    paddingTop: "2.5%",
                    borderWidth: 0,
                    borderRadius: 50
                  }}
                  onClick={() => {
                    clearErrors();
                    //this.setState({ show: false })
                  }}
                >
                  Close
                </button>
              </Modal>
              {renderFooter && (
                <footer className={"dropShadowFooter"}>
                  <Footer />
                </footer>
              )}
            </div>
          </Store>
          {/* </ScrollToTop> */}
        </BrowserRouter>
      </Router>
    );
  }

  //Maybe Temportal fix till the website will be mobile friendly
  if (isMobile) {
    return (
      <Router history={history}>
        <BrowserRouter>
          <ScrollToTop />
          <Store>
            <div id={"mainAppContainer"} className="App position-relative">
              {cookiesDisabled && (
                <CookieConsent
                  location="bottom"
                  buttonText="close"
                  cookieName="gotmyCookies"
                  style={{
                    background: styles.mainBackground,
                    position: cookiesPosition
                  }}
                  buttonStyle={{
                    //backgroundColor: styles.mainColor,
                    color: "white",
                    borderRadius: 25,
                    paddingBottom: "0.75vh",
                    paddingTop: "0.75vh",
                    paddingLeft: "1.5vw",
                    paddingRight: "1.5vw"
                  }}
                  expires={150}
                >
                  <span className={"gotmy-title-cookies-banner"}>
                    {BRAND_NAME}
                  </span>{" "}
                  {i18next.t("CookiesWarningText")}
                </CookieConsent>
              )}
              <Sign>
                {modalDownload && (
                  <ModalDownloadApp
                    modalDownload={modalDownload}
                    setModalDownload={setModalDownload}
                    message={"Use the App to enjoy our live events!"}
                  />
                )}
                {renderSigningElements(IS_SIGN_UP_ENABLED)}
                {renderHeaderTagsAndtitle()}
                <Switch>
                  <Route exact path="/tof.html">
                    <Redirect to="/terms/service" />
                  </Route>
                  <Route path="/profile" component={Profile} />
                  <Route path="/viewer_profile" component={ViewerProfile} />
                  <Route path="/live_events" component={LiveEventsPage} />
                  <Route path="/facebook" exact component={FacebookPage} />
                  <Route path="/billing_viewer" component={BillingViewer} />
                  <Route path="/my_balance" component={MyBalance} />
                  <Route
                    path="/my_influencer_payout_methods"
                    component={MyInfluencerPayoutMethods}
                  />
                  <Route path="/my_transactions" component={MyTransactions} />
                  <Route
                    path="/my_viewer_payment_methods"
                    component={MyViewerPaymentMethods}
                  />
                  <Route path="/help" component={Help} />
                  <Route
                    path="/my_live_events"
                    component={LiveEventInfluencer}
                  />
                  <Route path="/apple/callback" exact component={ApplePage} />
                  <Route path="/ticket/:ticket" component={LoginTicket} />
                  <Route path="/talent/:id" exact component={Referral} />
                  <Route path="/talent/" exact component={Referral} />
                  <Route
                    path="/wellness/:id"
                    exact
                    component={ReferralWellness}
                  />
                  <Route path="/wellness/" exact component={ReferralWellness} />
                  <Route path="/linkedin" exact component={LinkedinPage} />
                  <Route path="/instagram" exact component={InstagramPage} />
                  <Route
                    path="/instagramSignUp"
                    exact
                    component={instagramSignUp}
                  />
                  <Route
                    path="/all_upcoming_live_events"
                    exact
                    component={AllUpcomingLiveEvents}
                  />
                  <Route path="/all_videos" exact component={AllVideos} />
                  {(isInfluencer || user.user_role === 1) && (
                    <Route
                      exact
                      path="/publish_events"
                      component={PublishEvent}
                    />
                  )}
                  {isInfluencer && (
                    <Route
                      path="/private_meeting"
                      component={PrivateMeetingInfluencer}
                    />
                  )}
                  <Route
                    path="/mobileStreaming/:uuid"
                    exact
                    component={() => {
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      const { uuid } = useParams();
                      window.location.href = `https://gotmy.com/mobileStreaming/${uuid}`;
                    }}
                  />
                  {isInfluencer && (
                    <Route
                      path="/influencer_profile/:active/:category?"
                      component={ViewerProfile}
                    />
                  )}
                  <Route
                    path="/message/private_request"
                    exact
                    component={PrivateMeeting}
                  />
                  <Route path="/message/:id" exact component={PrivateMeeting} />
                  <Route
                    path="/private_request/:influencerId"
                    component={RequestPrivate}
                  />
                  {isInfluencer && (
                    <Route
                      path="/profile_influencer"
                      component={ProfileInfluencer}
                    />
                  )}
                  <Route
                    path="/terms/service"
                    exact
                    component={TermsOfService}
                  />
                  <Route exact path="/privacy.html">
                    <Redirect to="/terms/privacy" />
                  </Route>
                  <Route path="/stripeAccounCallback" component={StripeBack} />
                  <Route
                    path="/payment/paypal_callback"
                    component={PaypalBack}
                  />
                  <Route
                    path="/payment/paypal_callback_error"
                    component={PaypalBack}
                  />
                  <Route
                    path="/terms/privacy"
                    exact
                    component={PrivacyPolicy}
                  />
                  <Route path="/b2b" exact component={HomeReferral} />
                  {IS_DACAST_STREAMING_ENABLED ? (
                    influencerRedirect ? (
                      <Route exact path="/">
                        <Redirect to="/my_live_events" />
                      </Route>
                    ) : (
                      <Route
                        path="/broadcast/:id"
                        component={DacastStreaming}
                      />
                    )
                  ) : null}
                  {influencerRedirect && !influencerRedirectLoading ? (
                    <Route exact path="/search">
                      <Redirect to="/my_live_events" />
                    </Route>
                  ) : (
                    !influencerRedirect && (
                      <Route
                        path="/search"
                        exact
                        component={influencerRedirectLoading ? Loader : Search}
                      />
                    )
                  )}
                  {IS_INFLUENCER_STREAMING_ENABLED ? (
                    influencerRedirect ? (
                      <Route exact path="/">
                        <Redirect to="/my_live_events" />
                      </Route>
                    ) : (
                      <Route
                        //path="/preview_influencer/:id"
                        path="/streaming/:id"
                        component={UpcomingDetailsInfluencer}
                      />
                    )
                  ) : null}
                  {IS_INFLUENCER_STREAMING_ENABLED ? (
                    <Route
                      //path="/preview_influencer/:id"
                      path="/streaming/:id"
                      component={UpcomingDetailsInfluencer}
                    />
                  ) : null}
                  <Route path="/event/:id" exact component={EventPage} />
                  <Route
                    path="/subscribe/:id/:userToken?"
                    component={SubscribeInfluencer}
                  />
                  <Route
                    path="/influencer/:id"
                    component={PublicProfileInfluencer}
                  />
                  <Route path="/:id" component={PublicProfileInfluencer} />
                  <Route
                    path="/private_publish/:ide"
                    component={PublishPrivateEvent}
                  />
                  <Route
                    path="/"
                    exact
                    component={REFERRAL_HOME_PAGE ? HomeReferral : Home}
                  />
                  <Route path="*" component={MobileHome} />
                </Switch>
              </Sign>
              {renderFooter && (
                <footer className={"dropShadowFooter"}>
                  <Footer />
                </footer>
              )}
            </div>
          </Store>
        </BrowserRouter>
      </Router>
    );
  } else {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <BrowserRouter>
          <ScrollToTop />
          <Store>
            <div id={"mainAppContainer"} className="App position-relative">
              {cookiesDisabled && (
                <CookieConsent
                  location="bottom"
                  buttonText="close"
                  cookieName="gotmyCookies"
                  style={{ background: styles.mainBackground }}
                  buttonStyle={{
                    //backgroundColor: styles.mainColor,
                    color: "white",
                    borderRadius: 25,
                    paddingBottom: "0.75vh",
                    paddingTop: "0.75vh",
                    paddingLeft: "1.5vw",
                    paddingRight: "1.5vw"
                  }}
                  expires={150}
                >
                  <span className={"gotmy-title-cookies-banner"}>
                    {BRAND_NAME}
                  </span>{" "}
                  {i18next.t("CookiesWarningText")}
                </CookieConsent>
              )}
              <Sign>
                {renderHeaderTagsAndtitle()}
                {renderSigningElements(IS_SIGN_UP_ENABLED)}
                {modalDownload && (
                  <ModalDownloadApp
                    modalDownload={modalDownload}
                    setModalDownload={setModalDownload}
                    message={"Use the App to enjoy our live events!"}
                  />
                )}

                <Switch>
                  <Route
                    exact
                    path="/confirm-email/:token"
                    component={ConfirmEmail}
                  />
                  {influencerRedirect && !influencerRedirectLoading ? (
                    <Route exact path="/">
                      <Redirect to="/my_live_events" />
                    </Route>
                  ) : (
                    !influencerRedirect &&
                    !influencerRedirectLoading && (
                      <Route path="/" exact component={Home} />
                    )
                  )}
                  <Route path="/404" exact component={ErrorNotFound} />
                  <Route path="/linkedin" exact component={LinkedinPage} />
                  <Route path="/facebook" exact component={FacebookPage} />
                  <Route path="/instagram" exact component={InstagramPage} />
                  <Route
                    path="/instagramSignUp"
                    exact
                    component={instagramSignUp}
                  />
                  <Route path="/apple/callback" exact component={ApplePage} />
                  <Route exact path="/tof.html">
                    <Redirect to="/terms/service" />
                  </Route>
                  <Route
                    path="/terms/service"
                    exact
                    component={TermsOfService}
                  />
                  <Route exact path="/privacy.html">
                    <Redirect to="/terms/privacy" />
                  </Route>
                  <Route
                    path="/terms/privacy"
                    exact
                    component={PrivacyPolicy}
                  />
                  <Route path="/b2b" exact component={HomeReferral} />
                  <Route
                    exact
                    path="/reset-password/:token"
                    component={RecoverPassword}
                  />
                  {influencerRedirect && !influencerRedirectLoading ? (
                    <Route exact path="/search">
                      <Redirect to="/my_live_events" />
                    </Route>
                  ) : (
                    !influencerRedirect && (
                      <Route
                        path="/search"
                        exact
                        component={influencerRedirectLoading ? Loader : Search}
                      />
                    )
                  )}
                  <Route path="/search/:query" exact component={Search} />
                  <Route
                    path="/search/category/:id"
                    exact
                    component={SearchByCategory}
                  />
                  <Route
                    path="/all_influencers"
                    exact
                    component={AllInfluencers}
                  />
                  <Route
                    path="/all_upcoming_live_events"
                    exact
                    component={AllUpcomingLiveEvents}
                  />

                  <Route path="/all_videos" exact component={AllVideos} />
                  <Route path="/live_events" component={LiveEventsPage} />
                  <Route
                    path="/successSuscription"
                    exact
                    component={SubscriptionSuccess}
                  />
                  <Route path="/event/:id" exact component={EventPage} />
                  <Route
                    path="/subscribe/:id/:userToken?"
                    component={SubscribeInfluencer}
                  />
                  <Route path="/talent/:id" exact component={Referral} />
                  <Route path="/talent/" exact component={Referral} />
                  <Route
                    path="/wellness/:id"
                    exact
                    component={ReferralWellness}
                  />
                  <Route path="/wellness/" exact component={ReferralWellness} />
                  <Route
                    path="/moderator-view"
                    component={streaming_moderator}
                  />
                  <Route
                    path="/banned_moderator"
                    component={banned_moderator}
                  />
                  <Route
                    path="/mobileStreaming/:uuid"
                    exact
                    component={() => {
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      const { uuid } = useParams();
                      window.location.href = `https://gotmy.com/mobileStreaming/${uuid}`;
                    }}
                  />
                  {IS_INFLUENCER_STREAMING_ENABLED ? (
                    influencerRedirect ? (
                      <Route exact path="/">
                        <Redirect to="/my_live_events" />
                      </Route>
                    ) : (
                      <Route
                        //path="/preview_influencer/:id"
                        path="/streaming/:id"
                        component={UpcomingDetailsInfluencer}
                      />
                    )
                  ) : null}
                  {IS_DACAST_STREAMING_ENABLED ? (
                    influencerRedirect ? (
                      <Route exact path="/">
                        <Redirect to="/my_live_events" />
                      </Route>
                    ) : (
                      <Route
                        path="/broadcast/:id"
                        component={DacastStreaming}
                      />
                    )
                  ) : null}

                  {IS_INFLUENCER_STREAMING_ENABLED ? (
                    <Route
                      //path="/preview_influencer/:id"
                      path="/streaming/:id"
                      component={UpcomingDetailsInfluencer}
                    />
                  ) : null}
                  {
                    <Route
                      path="/my_live_events"
                      component={LiveEventInfluencer}
                    />
                  }
                  {
                    <Route
                      exact
                      path="/confirm-email/:token"
                      component={ConfirmEmail}
                    />
                  }
                  <Route path="/ticket/:ticket" component={LoginTicket} />
                  <Route path="/message/:id" exact component={PrivateMeeting} />
                  <Route
                    path="/message/private_request"
                    exact
                    component={PrivateMeeting}
                  />
                  <Route
                    path="/private_request/:influencerId"
                    component={RequestPrivate}
                  />

                  {isInfluencer && (
                    <Route
                      path="/private_meeting"
                      component={PrivateMeetingInfluencer}
                    />
                  )}
                  <Route
                    path="/private_publish/:ide"
                    component={PublishPrivateEvent}
                  />
                  {(isInfluencer || user.user_role === 1) && (
                    <Route
                      exact
                      path="/publish_events"
                      component={PublishEvent}
                    />
                  )}
                  <Route
                    path="/upcoming_influencer/:id"
                    exact
                    component={UpcomingLiveEventInfluencer}
                  />
                  <Route path="/change_moderator" component={ChooseModerator} />
                  <Route
                    path="/request_edit/:id"
                    component={RequestEdit}
                    exact
                  />

                  <Route
                    path="/message_influencer"
                    exact
                    component={MessageInfluencer}
                  />
                  <Route path="/stats" component={Stats} />

                  {false && (
                    <Route path="/my_balance" component={CardsBalance} />
                  )}
                  <Route path="/viewer_profile" component={ViewerProfile} />
                  <Route
                    path="/become_influencer"
                    component={BecomeInfluencerParent}
                  />
                  {IS_DEV_ENVIRONMENT && (
                    <Route path="/billing" component={Billing} />
                  )}
                  <Route path="/profile" component={Profile} />
                  <Route path="/friends" component={Friends} />
                  {false && <Route path="/followers" component={Followers} />}
                  <Route
                    path="/influencer_profile/:active/:category?"
                    component={ViewerProfile}
                  />
                  <Route
                    path="/new_influencer_profile"
                    component={NewInfluencerProfile}
                  />
                  <Route
                    path="/profile_influencer"
                    component={ProfileInfluencer}
                  />

                  <Route path="/billing_viewer" component={BillingViewer} />
                  <Route path="/my_balance" component={MyBalance} />
                  <Route
                    path="/my_influencer_payout_methods"
                    component={MyInfluencerPayoutMethods}
                  />
                  <Route path="/my_transactions" component={MyTransactions} />
                  <Route
                    path="/my_viewer_payment_methods"
                    component={MyViewerPaymentMethods}
                  />
                  <Route path="/help" component={Help} />
                  <Route
                    path="/successSuscription"
                    exact
                    component={SubscriptionSuccess}
                  />
                  <Route
                    path="/influencer/:id"
                    component={PublicProfileInfluencer}
                  />
                  <Route path="/stripeAccounCallback" component={StripeBack} />
                  <Route
                    path="/payment/paypal_callback"
                    component={PaypalBack}
                  />
                  <Route
                    path="/payment/paypal_callback_error"
                    component={PaypalBack}
                  />

                  <Route path="/:id" component={PublicProfileInfluencer} />
                </Switch>
              </Sign>
              <Modal // USAR ESTE MODAL PARA ERRORES
                isOpen={localError !== null}
                onRequestClose={() => {
                  clearErrors();
                }}
                //style={{ position: 'absolute', top: window.innerHeight * 0.3, height: window.innerHeight * 0.3, left: window.innerWidth * 0.25, width: window.innerWidth * 0.5 }}
                style={{
                  overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.75)"
                  },
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                    paddingTop: "2.5%",
                    paddingBottom: "2.5%",
                    alignContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column"
                  }
                }}
              >
                <h2>Ups there was a problem</h2>

                {/* <div><p>{localError}</p></div> */}
                <div>
                  <p>There's been an error</p>
                </div>

                <button
                  style={{
                    color: "white",
                    backgroundColor: "#ff5a60",
                    width: "auto",
                    padding: 50,
                    paddingBottom: "2.5%",
                    paddingTop: "2.5%",
                    borderWidth: 0,
                    borderRadius: 50
                  }}
                  onClick={() => {
                    clearErrors();
                    //this.setState({ show: false })
                  }}
                >
                  Close
                </button>
              </Modal>
              {renderFooter && (
                <footer className={"dropShadowFooter"}>
                  <Footer />
                </footer>
              )}
            </div>
          </Store>
          {/* </ScrollToTop> */}
        </BrowserRouter>
      </Router>
    );
  }
};
const mapStateToProps = state => {
  return {
    user: state.user.user,
    isInfluencer: state.user.isInfluencer,
    loading: state.user.loading,
    error: state.user.error,
    languages: state.languages.languages
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetCategories: () => dispatch(actions.getCategories()),
    onGetCurrencies: () => dispatch(actions.getCurrencies()),
    onGetLanguages: () => dispatch(actions.getLanguages()),
    onGetUser: token => dispatch(actions.getUser(token)),
    logoutUser: () => dispatch(actions.logoutUser()),
    onGetInfluencer: token => dispatch(actions.getInfluencer(token)),
    clearErrors: () => dispatch(actions.clearErrors())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
