import { useState, useEffect } from "react";
import { UserDAO, EventDAO, InfluencerDAO } from "daos";

const influencerDAO = new InfluencerDAO();

export const useProfileInfluencerLogic = (username) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const initData = async () => {
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    
    
  
    try {
      let response = await influencerDAO.getInfluencerProfile(username, env.token ? env.token : null);

      setData(response.data);
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return { data, loading };
};
