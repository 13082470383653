import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "utils";

const initialState = { currencies: [] };

const setCurrencies = (state, action) => {
  return updateObject(state, {
    currencies: action.currencies
  });
};

const reducer = (state = initialState, action) => {
    
  switch (action.type) {
    case actionTypes.SET_CURRENCIES:
      return setCurrencies(state, action);
    default:
      return state;
  }
};

export default reducer;
