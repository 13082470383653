import React, {useState, useContext} from 'react';

export const SignContext = React.createContext(false)
const Sign = ({children}) => {
    const [sign, setSign] = useState(false)
    return (
        <SignContext.Provider value={[sign, setSign]}>
            {children}
        </SignContext.Provider>
 
    );
}

export default Sign;