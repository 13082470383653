import { useState, useEffect } from "react";
import { UserDAO, EventDAO } from "daos";

const userDAO = new UserDAO();

export const useProfileInfluencerLogic = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const initData = async () => {
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    if (!env.token) return;

    try {
      let response = await userDAO.profileInfluencer(env.token);

      setData(response.data);
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return { data, loading };
};
