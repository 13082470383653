import React, {Component} from 'react';
import './style.scss';
import logo from '../../../assets/img/logo-white.png';
import Menu from "../../comunes/navbar_influencer/menu_influencer";
import Login_menu from "../../comunes/login_menu_influencer";
import {NavLink} from "react-router-dom";
import { Link } from "react-router-dom";
import i18next from 'i18next';
 
class NavBar_liveEvents extends Component {
    render() {
        return (
            <div className="Nav_search">

                <div className="Nav Bg-blue flex-column pb-0">

                    <div className="d-flex justify-content-between w-100">
                    <div className="d-flex">
                    <a target="_blank" href="https://blog.gotmy.com/">
                    <img className="mr-3 " src={logo} className="Logo" alt="logo" />
                    </a>
                    <a style={{ color: "#FF5A60", alignSelf: 'flex-end',marginTop:'14px' }} className="align-bottom" target="_blank" href="https://blog.gotmy.com/" className="">
                        <h5>Blog</h5>

                    </a>
                </div>
                  <Menu/>
                   <Login_menu/>
                    </div>
                    {/*MENU LIVE EVENTS*/}
                    <div className="Menu-live-Events">
                        <NavLink exact to={`/stats`} activeClassName="active_live_events"  className="col-4 ">
                        {i18next.t("NavbarStatsOverview")}
                        </NavLink>
                            <NavLink exact to={`/stats/live_stats`} activeClassName="active_live_events" className="col-4 ">
                            {i18next.t("NavbarStatsLive")}
                        </NavLink>
    
                        <NavLink  exact to={`/stats/rating_review`} activeClassName="active_live_events" className="col-4">
                        {i18next.t("NavbarStatsRatings")}
                        </NavLink>
                        
                    </div>

                </div>

            </div>
        );
    }
}

export default NavBar_liveEvents;