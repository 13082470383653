import * as actionTypes from "../actions/actionTypes";
var initialError = null;

try{
  initialError = JSON.parse(localStorage.getItem("error"))
}catch{

}

const initialState = {
  user: {},
  loading: false,
  error: initialError,
  influencer: {},
  isInfluencer: false,
  events: [],
  purchasedEvents: [],
  myStats: {},
  userCardMethods: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
        purchasedEvents: action.purchasedEvents,
        loading: false
      }
    case actionTypes.SET_UNAUTHENTICATED:
      
      return {
        ...initialState
      }
    case actionTypes.SET_INFLUENCER:
      return {
        ...state,
        influencer: action.influencer,
        events: action.events,
        isInfluencer: Object.keys(action.influencer).length > 0
      }
    case actionTypes.FETCH_PAYMENT_METHODS:
        return {
          ...state,
          userCardMethods: action.methods.data
        }
    case actionTypes.SET_INFLUENCER_STATS:
        return {
          ...state,
          myStats: action.stats,
      }
    case actionTypes.LOAD_USER:
      
      return { ...state, loading: true }
    case actionTypes.CLEAR_ERRORS:
      localStorage.setItem("error", null);
      return {
        ...state,
        loading: false,
        error: null
      }
    case actionTypes.SET_ERROR:
      

      var error = null;

      if (action && action.error && action.error.response && action.error.response.data && action.error.response.data.error && action.error.response.data.error.length > 0) {
        error = action.error.response.data.error[0];
      } else if (action && action.error && action.typeError && action.typeError != "text") {
        for (var i = 0; i < action.error.length; i++) {
          var item = action.error[i];

          error = error + "\n\n" + item.message;
        }
      } else {
        error = action.error;
      }

      localStorage.setItem("error", JSON.stringify(error));

      return {
        ...state,
        loading: false,
        error: error
      }
    default:
      return state;
  }
};

export default reducer;
