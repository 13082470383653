import React, { Component } from 'react';
import started from '../../../assets/img/get-started.png';
import access from '../../../assets/img/get-access.png';
import att from '../../../assets/img/get-their-attention.png';
import styled from 'styled-components'
import './style.scss';
import i18next from 'i18next';

class Pasos extends Component {
    render() {
        return (
            <section className="Home-pasos">

                <div className="container">
                    <div className="row">
                        <div className="col-12 my-5 ">
                            <h1>{i18next.t("StepComponentYouAreIn")}</h1>
                            <h2>{i18next.t("StepComponentTalkFaceToFaceWith")}</h2>

                        </div>
                        <div className="col-12 col-md-4 sec">
                            <img src={started} alt="" className="mw-100" />
                            <div className="caja">
                                <span className="numero">01</span>
                                <h4>{i18next.t("StepComponentStepOneTitle")}</h4>
                                <p>{i18next.t("StepComponentStepOneDescription")}
                                </p>
                            </div>

                            <hr />


                        </div>
                        <div className="col-12 col-md-4 sec">
                            <img src={access} alt="" className="mw-100" />
                            <div className="caja">
                                <span className="numero">02</span>
                                <h4>{i18next.t("StepComponentStepTwoTitle")}</h4>
                                <p>{i18next.t("StepComponentStepTwoDescription")}
                                </p>
                            </div>
                            <hr />
                        </div>
                        <div className="col-12 col-md-4 sec">
                            <img src={att} alt="" className="mw-100" />
                            <div className="caja">
                                <span className="numero">03</span>

                                <h4>{i18next.t("StepComponentStepThreeTitle")}</h4>
                                <p>{i18next.t("StepComponentStepThreeDescription")}
                                </p>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default Pasos;