import React from "react";
import SweetAlert from "sweetalert2-react";

export const WithAlert = WrappedComponent => {
  return class extends React.Component {
    state = {
      show: false,
      title: ""
    };

    showAlert = (title) => this.setState({ show: true, title });

    render() {
      const { show, title } = this.state;
      return (
        <>
          <WrappedComponent {...this.props} showAlert={this.showAlert} />

          <SweetAlert
            show={show}
            confirmButtonColor= '#3085d6'
            title={title}
            onConfirm={() => this.setState({ show: false, title: "" })}
          />
        </>
      );
    }
  };
};
