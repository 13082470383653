import React from "react";
import "./style.scss";

const InputWithLabel = ({
  id,
  label,
  name,
  placeholder,
  type,
  value,
  onChange
}) => {
  return (
    <div className="Input-with-label">
      <label htmlFor={id}>{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputWithLabel;
