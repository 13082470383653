import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Card = ({ id, img, cat }) => {
  return (
    <div className="col-4 col-md-2 Card-discover big-gutter grow">

      <Link to={`/search/category/${id}`} className={"text-white"}>
          <span>{cat}</span>
          <img src={img} alt="category" />
        </Link>
    </div>
  );
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  cat: PropTypes.string.isRequired
};

export default Card;
