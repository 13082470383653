import React, { Component } from "react";
import "./style.scss";
import logo from "../../../assets/img/logo-white.png";
import user from "../../../assets/img/user.png";
import Search_input_peq from "../../comunes/search_input_peq";
import Menu from "../../comunes/navbar_influencer/menu_influencer";
import Login_menu from "../../comunes/login_menu_influencer";
import { NavLink } from "react-router-dom";
import NavBarInfluencer from "../../comunes/navbar_influencer";
import styled from "styled-components";
import useMobileNav from "../../../hooks/useMobileNav";
import { useSelector} from "react-redux";
import i18next from "i18next";
import {
  PRIVATE_MARKETPLACE,
  B2B_PORT,
  LOGIN_MAIN_PAGE,
} from "../../../environment/environment";

const NavBar_liveEvents = () => {
  const { mobileNav } = useMobileNav();
  const isAdmin = useSelector (state=> state.user.user.user_is_admin)

  console.log("DATOS DEL SELECTOR DE USUARIO", isAdmin)

  return (
    <Container className="Nav_search dropShadow">
      <div className=" Bg-blue flex-column pb-0">
        <NavBarInfluencer />

        

        {/*MENU LIVE EVENTS*/}
        <div className="eventsMenu Menu-live-Events">
        {B2B_PORT && LOGIN_MAIN_PAGE && isAdmin && (
          <NavLink
            exact
            to={`/discover`}
            activeClassName="active_live_events"
            className="col-4 "
          >
            All events
          </NavLink>
        )}
          <NavLink
            exact
            to={`/my_live_events`}
            activeClassName="active_live_events"
            className="col-4 "
          >
            {!mobileNav
              ? i18next.t("NavBarLiveEventsComponentUpcomingLiveEvents")
              : i18next.t("NavBarLiveEventsComponentUpcomingLiveEventsMobile")}
          </NavLink>
          <NavLink
            exact
            to={`/my_live_events/happening`}
            activeClassName="active_live_events"
            className="col-4 "
          >
            {i18next.t("InfluencerHappeningEvents")}
          </NavLink>
          {!PRIVATE_MARKETPLACE && (
            <NavLink
              exact
              to={`/my_live_events/past_live_events`}
              activeClassName="active_live_events"
              className="col-4 "
            >
              {!mobileNav
                ? i18next.t("NavBarLiveEventsComponentPastEvents")
                : i18next.t("NavBarLiveEventsComponentPastEventsMobile")}
            </NavLink>
          )}
          {/* <NavLink  exact to={`/my_live_events/drafts_live_events`} activeClassName="active_live_events" className="col-4">
                            Drafts Live Events
                        </NavLink> */}
        </div>
      </div>
    </Container>
  );
};

export default NavBar_liveEvents;

const Container = styled.div`
  .eventsMenu {
    color: white;
    width: 100%;

    /*background:#312F3D;*/
    /* padding-top:110px; */
    display: flex;

    @media (max-width: 768px) {
      padding-top: 20px !important;
    }
  }
`;
