import React from "react";
import { Redirect } from "react-router-dom";
import compose from "recompose/compose";
import logo from "assets/img/gotmy-n.png";
import "./style.scss";
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import Delete from "assets/img/icons/ic-detele.svg";
import Sad from "assets/img/icons/sad-face-in-rounded-square.svg";
import { useModalVerifyInfluencerLogic } from "./ModalVerifyInfluencerLogic";
import SweetAlert from "sweetalert2-react";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import { LinkedIn } from "react-linkedin-login-oauth2";
// import { LINKEDIN_CLIENTID } from "../../../../../environment/environment";
import Input_with_label from "../../../input_with_label";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

import fotoConEstrellas from "assets/img/foto-con-estrellas.png";
import movilConVideo from "assets/img/movil-con-video.png";
import grafica from "assets/img/grafica.png";


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const ModalVerifyInfluencer = ({user, history}) => {
  const {
    goToBecomeInfluencerForm
  } = useModalVerifyInfluencerLogic(history);
      
  return (
    <div
      className="modal Modales-login Modal-login"
      id="myModal_verify_influencer" data-backdrop="static"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <img data-dismiss="modal" src={Delete} className="Cruz" alt="" />
          <div className="modal-body">
            <h6>Become Influencer</h6>
            <img src={logo} className="Logo" alt="" />
              <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  pagination
                  observer="true"
                  observeparents="true"
                  loop={true}
                  autoplay={{delay: 2000, disableOnInteraction: false}}
                  >
                  <SwiperSlide> 
                    <img src={fotoConEstrellas} className="small"/>
                    <h1>Become An Influencer</h1>
                    <p></p>
                    <p>Create your profile and earn money by hosting your own live events</p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={movilConVideo} className="small"/>
                    <h1>Share your knowledge</h1>
                    <p></p>
                    <p>Hosting your live event is super simple with our integrated scheduling system, payment processing, and live video chat technology</p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={grafica} className="small"/>
                    <h1>Take control</h1>
                    <p></p>
                    <p>Manage and track your sales in real time, and manage your community through private messages and chat moderation</p>
                  </SwiperSlide>
                </Swiper>  
              <button className="Btn-rojo boton_go" onClick={goToBecomeInfluencerForm}>
                Create Influencer Profile
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    loadingUserInfo: state.user.loading,
    influencer: state.user.influencer,
    isInfluencer: state.user.isInfluencer
  };
};

const enhance = compose(withRouter, connect(mapStateToProps))
export default enhance(ModalVerifyInfluencer);
