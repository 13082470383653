import {useState, useEffect } from 'react'
import {InfluencerDAO} from 'daos'
const influencerDAO = new InfluencerDAO()
const useInfluencerData = (influencerUserName)=>{
    const [influencerData, setInfluencer] = useState([])
    const [initialLoading, setLoading] = useState(true);

    const getInfluencer= async ()=> {
        const data = await influencerDAO.getInfluencerProfile(influencerUserName)
        
        setInfluencer (data.data)
        setLoading (false)
    }
    useEffect(()=>{
        if (influencerUserName){
            getInfluencer()

        }
    },[influencerUserName])
    return {influencerData, initialLoading}
}
export default useInfluencerData