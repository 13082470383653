import React, { useState, useEffect } from "react";
import map from "assets/img/icons/ic-map.svg";
import userr from "assets/img/icons/ic-user.svg";
import "../upcomingLiveEvents/card_upcoming/style.scss";
// import "./style.scss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import useUserOrLocalTimeHour from "../../../hooks/useUserOrLocalTimeHour"
import { parsePriceByCurrency, parsePriceByCurrencyJustSymbol } from "../../../utils/currencyUtils";
import i18next from "i18next";
import { useSelector } from 'react-redux'
import eventState from "../../../constants/eventState";
import { SHOW_DEFAULT_ICONS } from "../../../environment/environment";

const EventCardInfluencer = ({ event, categories, influencer, cardsPerRow, type }) => {
  const [sold, setSold] = useState('')
  const user = useSelector(state => state.user.user)
  const {
    currency,
    event_title,
    event_uuid,
    event_state,
    event_date,
    event_cover_image,
    event_num_attendees,
    event_num_tickets_free,
    event_category_ide,
    event_streaming_start_date,
    event_num_attendees_streaming,
    event_num_tickets_free_streaming,
    event_price_ticket_streaming
  } = event;
  var now = moment()
  const { time } = useUserOrLocalTimeHour(event_date, event)

  const eventStart = moment(parseInt(event_date))
  const startIn = now.to(eventStart)
  const category = categories.find(
    category => category.cate_ide === event_category_ide
  );
  const [eventUrl, setEventUrl] = useState('')

  useEffect(() => {
    const url = `/event/${event_uuid}`
    setEventUrl(url)
    let numSold
    if (isNaN(event_num_attendees)) {
      numSold = event_num_attendees_streaming - event_num_tickets_free_streaming
    }
    else {

      numSold = event_num_attendees - event_num_tickets_free
    }
    setSold(numSold)
  }, [type, event])
  console.log(sold, event_price_ticket_streaming)

  // if (!category && type!=='draft') return null;

  return (
    <div className={`CardUpcoming LiveEventsCard col-12 ${cardsPerRow === 2 && "col-lg-6"} ${cardsPerRow === 3 && "col-lg-4"} mb-5 grow`}>
      <div style={{ borderRadius: '7px' }} className="Shadow-lg">
        <Link to={eventUrl}>
          <div
            className="Header"
            style={{ background: `url('${event_cover_image}')` }}
          >
            {!startIn.includes('ago') && event_state === eventState.upcoming && (
              <div className="Start">
                {i18next.t("EventCardInfluencerComponentStartsIn", { startingText: startIn })}
              </div>
            )}
          </div>

          <div className="Footer">
            <div className="Fecha">
              <span className="Dia">
                {time.day}
              </span>
              <span className="Mes">
                {time.month}
              </span>
            </div>
            <div className="Texto">
              <span className="Tit">{event_title}</span>
              {category && <span className="Cat">
                {category && category.cate_description}
              </span>
              }
              <span className="Hor">
                {" "}
                {SHOW_DEFAULT_ICONS && (<img src={map} alt="" />)}
                {i18next.t("EventCardInfluencerComponentLiveAt", { date: time.time })}
              </span>
              <br />
            </div>
          </div>
          <div style={{ padding: '6px 12px' }} className="">



            <div style={{ borderTop: '2px solid #d3d3d3' }}>
              <div className="price_ticket d-flex px-4 pt-2">
                <div>
                  <p>  {event.event_price_ticket !== null ? parsePriceByCurrencyJustSymbol(event.event_price_ticket, currency) :
                    parsePriceByCurrencyJustSymbol(event.event_price_ticket_streaming, currency)
                  }</p>
                  <p>
                    {i18next.t("EventCardInfluencerComponentMaxXPeople", { maxNumber: event && event.event_num_attendees ? event.event_num_attendees : event.event_num_attendees_streaming })}
                  </p>{" "}
                </div>
                <div className="ml-5">
                  <p>{sold}</p>
                  <p>
                    {i18next.t("EventCardInfluencerComponentTicketsSold")}
                  </p>
                </div>{" "}
                {/* <img src={cinco_estrellas} className="stars"></img> */}
              </div>
              <div className="px-4 mb-3">
                <span className="precio_total">

                  {event.event_price_ticket === null ?
                    parsePriceByCurrencyJustSymbol(event.event_price_ticket_streaming * sold, currency)
                    :
                    parsePriceByCurrencyJustSymbol(event.event_price_ticket * sold, currency)}
                </span>
                <p className="float-right viewers-influencer">
                  {SHOW_DEFAULT_ICONS && (<img src={userr} />)}
                  {sold} {i18next.t("EventCardInfluencerComponentViewers")}
              </p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

EventCardInfluencer.propTypes = {
  event: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  influencer: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  };
};

export default connect(mapStateToProps)(EventCardInfluencer);
