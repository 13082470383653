import React, { useState, useEffect, useRef } from "react";
import favoriteIconMarked from "assets/img/icons/ic-bookmark-red.svg";
import favoriteIconUnmarked from "assets/img/icons/ic-bookmark-white.svg";
import { UserDAO } from "daos";
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import i18next from 'i18next';
import { PRIVATE_MARKETPLACE } from "../../../environment/environment";
const userDAO = new UserDAO();

const FavoriteIcon = ({ isFavoriteInitial, uuid }) => {
  const [isFavorite, setIsFavorite] = useState(isFavoriteInitial);
  const [isTooltipEnabled, setIsTooltipEnabled] = useState(true); // when clicking save to favorites, tooltip disables for 1 second
  const [changingFavoriteStatus, setChangingFavoriteStatus] = useState(false);

  const blockTooltip = ()=> {
    setIsTooltipEnabled (false)
    setTimeout(() => {
      setIsTooltipEnabled(true)
    }, 500);
  }
  const onChangeFavorite = async value => {
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    if (changingFavoriteStatus || !env.token) return;
    setChangingFavoriteStatus(true);
    setIsFavorite(value);

    const request = value
      ? userDAO.setFavoriteEvent
      : userDAO.removeFavoriteEvent;
    // TODO: handle error request
    request(env.token, uuid);
    setChangingFavoriteStatus(false);
    blockTooltip()
  };

  useEffect(() => {
    setIsFavorite(isFavoriteInitial);
  }, [isFavoriteInitial]);

  if(PRIVATE_MARKETPLACE){
    return null;
  }

  return  (
    <OverlayTrigger
        key={"top"}
        placement={"top"}
        overlay={
          <Tooltip className={`${"hideTooltip"}`} id={`tooltip-${"top"}`}>
            { `${isFavorite? i18next.t("FavoriteIconComponentRemoveFromFavorites") : i18next.t("FavoriteIconComponentAddToFavorites")}` }
          </Tooltip>
        }
      >
    <div className="saveCircle" onClick={() => onChangeFavorite(isFavorite? false: true)}>
      
      <img
        className="Guardar"
        src={isFavorite? favoriteIconMarked:favoriteIconUnmarked}
        alt="favorite"
      />
    </div>
    </OverlayTrigger>
  )
};

export default FavoriteIcon;
