import React from "react";
import { Launcher } from "react-chat-window";
import "./style.scss";
import { useChatDemoLogic } from "./ChatDemoLogic";
import PropTypes from "prop-types";

const ChatDemo = ({ data = [] }) => {
  useChatDemoLogic();
  
  return (
    <div className="chat_demo">
      <Launcher
        agentProfile={{
          teamName: "react-chat-window",
          imageUrl:
            "https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png"
        }}
        
        showEmoji
        messageList={data}
      />
    </div>
  );
};

ChatDemo.propTypes = {
  data: PropTypes.array.isRequired
};

export default ChatDemo;
