import React, { Component } from "react";
import DiaMes from "../comunes_events/dia_mes";
import ImagenUsuPuntuacionChat from "../comunes_events/imagen_usu_puntuacion_streaming_chat";
import PuntUsu from "../comunes_events/imagen_usu_puntuacion_streaming";
import PuntUsu2 from "../comunes_events/imagen_usu_puntuacion";
import Moderator from "../comunes_events/imagen_usu_puntuacion_streaming_mode";
import NombreCat from "../comunes_events/nombre_cat_fecha";
import "./style.scss";
import NavBar_streaming from "../comunes_events/cat-fecha-city";
import per from "../../assets/img/icons/ic-streaming-mic-off.svg";
import video from "../../assets/img/icons/ic-camera-off.svg";
import logo from "../../assets/img/logo-circulo-gris.png";
import user from "../../assets/img/icons/ic-user.svg";
import { Link } from "react-router-dom";
import Algo from "../comunes/star-rating";
import FilteredChat from "../comunes/chat-moderator";
import Close from "../../assets/img/icons/ic-detele.svg";
import ImgStreaming from "../../assets/img/another-view.png";
class StreamingModerator extends Component {
  refreshPage() {
    window.setTimeout(() => {
      window.location.reload();
    }, 100);
  }
  render() {
    return (
      <div class="streaming">
        <header className="app-streaming">
          <NavBar_streaming />
        </header>
        <div className="modal" id="myModal">
          <div className="modal-dialog">
            <div className="modal-content modal_rating">
              <img data-dismiss="modal" src={Close} className="Cruz" alt="" />
              <div className="modal-body modal_rating_body">
                <div className="d-flex flex-column align-items-center">
                  <div className="Div-g w-100">
                    <PuntUsu />
                    <div className="d-flex align-items-center flex-column">
                      <h2 className="mb-1">
                        Thanks for attending my live event!
                      </h2>
                      <p className="mb-0">Have you enjoyed it? i hope so!</p>
                      <p>Would you please rate my live event?</p>
                      <div class="aling-item-center">
                        <Algo />
                      </div>
                      <div class="form-group w-70 ml-3">
                        <textarea
                          placeholder="If love you know you thoughts, and how i can improve my next live events."
                          class="form-control message"
                          rows="5"
                          id="comment"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="Div-m d-flex m-auto justify-content-center w-100">
                    <Link
                      onClick={this.refreshPage}
                      className="thing"
                      to={`/live_events`}
                    >
                      <div>
                        <button
                          className="Btn-rojo mt-4 feedback"
                          onClick={this.handleClick}
                        >
                          Send feedback
                        </button>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*MODAL 2*/}
        <div className="container-fluid streaming">
          <div className="row moderator_row">
            <div className="col-md-9 d-flex flex-column align-items-end">
              <img
                className="w-100 br-10 img-streaming"
                src={ImgStreaming}
                alt=""
              />
              <div className="svg-streaming">
                <div className="mic-off">
                  <img src={per} alt="" />
                </div>{" "}
                <div className="vid-off">
                  <img src={video} alt="" />
                </div>
                <div className="logo-off">
                  <img src={logo} alt="" />
                </div>
              </div>
              <div className="viewer viewer_streaming_moderator">
                <img src={user} />
                <p>845 Viewers</p>
              </div>
              <div className="puntusu2">
                <DiaMes />
                <PuntUsu2 />
                <br />
                <NombreCat />
              </div>
            </div>

            <div className="col-md-3 chat ">
              <Moderator />
              <h2 className="mb-0">Live Chat</h2>
              <FilteredChat />
              {/* <img src = {LiveChat}></img> */}
              <ImagenUsuPuntuacionChat />
              <div class="form-group w-70 ml-3">
                <textarea
                  class="form-control message_chat"
                  rows="5"
                  id="comment"
                ></textarea>
                <div>
                  <button className="Btn-rojo mt-4 send">Send</button>
                  <p className="caracteres_chat">0/200</p>
                </div>
              </div>
              <div className="Div-m d-flex m-auto justify-content-center w-100">
                <Link to={"banned_moderator"}>
                  <button className="Btn-rojo mt-4 feedback">
                    View Banned
                  </button>
                </Link>
              </div>
            </div>
            <div className="container card-streaming"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default StreamingModerator;
