import * as actionTypes from "./actionTypes";

export const setModerator = moderator => {
  return {
    type: actionTypes.SET_MODERATOR,
    moderator
  };
};

export const setOptionsModerators = optionsModerators => {
  return {
    type: actionTypes.SET_OPTION_MODERATORS,
    optionsModerators
  };
};
