import React, { useEffect, useState } from "react";
import NavBarGeneral from "../../comunes/navbar";
import "./style.scss";
import SearchInputMessage from "../../comunes/search_input_messages";
import FilteredList from "../../comunes/chat";
import { WithAlert } from "../../../hocs/withAlert/WithAlert";
import useMobilenav from "../../../hooks/useMobileNav"
import { usePrivateMessageViewerLogic } from "./PrivateMessageViewerLogic";
import { RequestDetail } from "./components";
import Loader from "components/comunes/loader";
import modesUser from "../../../constants/modesUser";
import Time from "assets/img/icons/ic-time-copy.svg";
import Check from "assets/img/icons/ic-check.svg";
import moment from "moment";
import RequestMessages from "components/comunes_events/RequestMessages/RequestMessages"
import HowItWorks from './HowItWorks'
import MobileDetails from './MobileDetails'
import i18next from "i18next";

const imagesPath = {
  minus: Check,
  plus: Time
};
const MessageMeeting = ({ showAlert }) => {
  const { mobileNav } = useMobilenav()
  const [showHowItWorks, setShowHowItWorks] = useState(false)
  const [showMobileDetails, setShowMobileDetails] = useState(false)

  const [timeAgo, setTimeAgo] = useState('')
  const {
    state, onRequestActive, onAcceptRequest, onRejectRequest, getFilteredRequests, onChangeSearchInput
  } = usePrivateMessageViewerLogic({
    showAlert
  });
  const { requestActive, loadingRequests, requests: requestsRaw, accepting, rejecting, searchInput
  } = state;
  

  const requests = [...(requestsRaw.pending_payment || []), ...(requestsRaw.pending || []), ...(requestsRaw.accepted || []),
  ...(requestsRaw.rejected || []),
  ...(requestsRaw.canceled || [])
  ];
  useEffect(() => {
    var now = moment()
    if (requestActive && requestActive.request) {
      const lastInteraction = moment(parseInt(requestActive.request.request_updated_date ||
        requestActive.request.request_created_date))
      const timeAgo = now.to(lastInteraction)
      setTimeAgo(timeAgo)

      
    }
  }, [requestActive])

  return !loadingRequests ? (
    <div classNameName="messages_details_private_influencer">
      <header className="App-header">
        <NavBarGeneral />
      </header>
        <MobileDetails
        showMobileDetails={showMobileDetails}
        setShowMobileDetails={setShowMobileDetails}
        onAcceptRequest={onAcceptRequest}
         onRejectRequest={onRejectRequest}
          requestActive={requestActive}
          imagesPath={imagesPath}
          onReject={onRejectRequest}
          onAccept={onAcceptRequest}
          rejecting={rejecting}
          accepting={accepting}
          isViewer
        />
      {showHowItWorks && <HowItWorks setShowHowItWorks={setShowHowItWorks} showHowItWorks={showHowItWorks} />}
      <section className="container-fluid some2">
        <div className="row" >

          <div style={{ borderRight: '1px solid #D8DCE5', paddingTop: '20px', paddingRight: '0px' }} className="col-12 px-md-4 px-2 px-md-0 col-md-3 ml-2 ml-md-5">
            <div className="d-flex">
              <h2 style={{ fontSize: '25px', fontWeight: '500' }} className="mt-3 mb-3">{i18next.t("PrivateMessageViewerPrivateRequest")}</h2>
            </div>
            <p style={{ cursor: 'pointer' }} onClick={() => setShowHowItWorks(true)} className="mb-3 works">{i18next.t("PrivateMessageViewerHowItWorks")}</p >
            {/* <SearchInputMessage
              value={searchInput}
              onChange={onChangeSearchInput}
            /> */}
            <FilteredList
              setShowMobileDetails={setShowMobileDetails}
              data={getFilteredRequests(requests)}
              onPressItem={onRequestActive}
              modeUser={modesUser.viewer}
              isViewer={false}
            />
          </div>
          {!mobileNav && <div className="col-md-5">
            <div style={{ paddingTop: '20px' }} className=" date">
              <div className="bar"></div>
              {timeAgo && timeAgo.length > 0 && <p style={{ color: 'color : #808080' }} className="text-center ml-3">{i18next.t("PrivateMessageViewerLastUpdate")} {timeAgo}</p>}
              <div className="bar"></div>
            </div>
            <div style={{ paddingTop: '60px' }} className="">

              <RequestMessages requestActive={requestActive} isViewer />

            </div>
          </div>
          }
          {!mobileNav &&
            <RequestDetail
              data={requestActive}
              imagesPath={imagesPath}
              onReject={onRejectRequest}
              onAccept={onAcceptRequest}
              rejecting={rejecting}
              acepting={accepting}
            />
          }
        </div>
      </section>
    </div>
  ) : (
      <div className="messages_details_private_influencer">
        <header className="App-header">
          <NavBarGeneral />
        </header>
        <section className="container-fluid some2">
          <Loader />
        </section>
      </div>
    );
};

export default WithAlert(MessageMeeting);
