import React, {Component} from 'react';
import circulo from '../../../../assets/img/logo-circulo.png';
import cinco_estrellas from '../../../../assets/img/5-estrellas.png';
import usr from '../../../../assets/img/user.png';
import map from '../../../../assets/img/icons/ic-map.svg';
import per from '../../../../assets/img/icons/ic-user.svg';
import user from '../../../../assets/img/icons/ic-user.svg';
import '../../../comunes/upcomingLiveEvents/card_upcoming/style.scss';
import './style.scss';
import {Link} from "react-router-dom";

class BestEventInfluencer extends Component {
    render() {
        return (
            <div className="CardUpcoming LiveEventsCard col-12 ">
                <div className="Shadow">
                    <Link to={``} >

                    <div className="Header">
                        <div className="Start">
                            Start in 20 min
                        </div>

                    </div>

                    <div className="Footer">

                        <div className="Fecha">
                            <span className="Dia">18</span>
                            <span className="Mes"> Abr</span>
                        </div>
                        <div className="Texto">
                            <span className="Tit">Hot Pursuit - "Hotting Up"</span>
                            <span className="Cat">Games</span>
                            <span className="Hor"> <img src={map} alt=""/>Live From New York, at 18:30 pm</span>
                            <br/>
                            
                        </div>
                    </div>
                    <div><div className="price_ticket d-flex px-4">
                                <div><p>$ 20.00</p>
                                <p>Max. 80 pax</p> </div>
                                <div className="ml-5"><p>20</p>
                                <p>Ticket sold</p></div> <img src={cinco_estrellas} className="stars"></img>               
                            </div><div className="px-4 mb-3"><span className="precio_total">$ 400.00</span><p className="float-right viewers-influencer"><img src={user}/>845 Viewers</p></div></div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default BestEventInfluencer;