import React, { Component } from "react";
import "./styles.scss";
import NavBar_streaming from "../comunes_events/cat-fecha-city-pre";
import per from "../../assets/img/icons/ic-streaming-mic-off.svg";
import video from "../../assets/img/icons/ic-camera-off.svg";
import logo from "../../assets/img/logo-circulo-gris.png";
import user from "../../assets/img/icons/ic-user.svg";
import { Link } from "react-router-dom";
import FilteredList from "../comunes/chat-streaming";
import Request from "../../assets/img/icons/ic-requests.svg";
import Open from "../../assets/img/icons/ic-raisehand.svg";
import ImgStreaming from "../../assets/img/view-streaming.png";
import UserModerator from "../comunes_events/attendees_influencer";
import ExitModerator from "../../assets/img/icons/ic-detele.svg";
import AttendeesModerator from "../comunes_events/attendees_moderator";
class StreamingInfluencer extends Component {
  constructor() {
    super();
    this.state = {
      showMe: false
    };
    this.state = {
      showMe3: true
    };
    this.state = {
      showMe2: false
    };
  }
  operation() {
    this.setState({
      showMe: !this.state.showMe,
      showMe2: !this.state.showMe2
    });
  }
  operationModerator() {
    this.setState({
      showMe3: !this.state.showMe3,
      showMe: !this.state.showMe
    });
  }
  render() {
    const style = this.state.hideToolTip ? { display: "none" } : {};
    return (
      <div class="streaming">
        <header className="app-streaming">
          <NavBar_streaming />
        </header>
        <div className="container-fluid streaming2">
          <div className="row">
            <div className="col-md-9 d-flex flex-column align-items-end">
              <img
                className="w-100 br-10 img-streaming"
                src={ImgStreaming}
                alt=""
              />
              <div className="svg-streaming" id="svg_streaming_view">
                <div className="mic-off">
                  <img src={per} alt="" />
                </div>{" "}
                <div className="vid-off">
                  <img src={video} alt="" />
                </div>
                <div className="logo-off">
                  <img src={logo} alt="" />
                </div>
                <div className="logo-off">
                  <img src={Open} alt="" />
                </div>
              </div>
              <div className="viewers">
                <img src={user} />
                <p>845 Viewers</p>
              </div>
              <div className="puntusu2"></div>
            </div>

            <div className="col-md-3 chat ">
              {this.state.showMe ? (
                <div>
                  <p className="Choose_raise">
                    Choose Raise Hand{" "}
                    <img
                      className="exit_moderator"
                      onClick={() => this.operationModerator()}
                      src={ExitModerator}
                    />
                  </p>
                  <UserModerator />
                  <UserModerator />
                  <UserModerator />
                  <UserModerator />
                  <UserModerator />
                  <UserModerator />
                </div>
              ) : null}

              {this.state.showMe3 ? (
                <div>
                  <AttendeesModerator />
                  <h2 className="mb-0 h2_chat_with_moderator">Live Chat</h2>
                  <FilteredList />{" "}
                </div>
              ) : null}
              {this.state.showMe2 ? (
                <div style={{display: 'none'}}>
                  <div
                    onClick={() => this.operation()}
                    className="d-flex request_moderator"
                  >
                    <img src={Request} className="request_img"></img>
                    <div className="ml-4">
                      <p className="mb-0 mt-3">Chat Moderation</p>
                      <p className="rojo">Choose viewer for moderator</p>
                    </div>
                  </div>
                  <h2 className="mb-0">Live Chat</h2>
                  <FilteredList />{" "}
                </div>
              ) : null}
            </div>
            <div class="form-group w-100 ml-3 col-8">
              <textarea
                class="form-control message_chat"
                rows="5"
                id="comment_influencer"
              ></textarea>
              <div>
                <button className="Btn-rojo mt-4 send_influencer">Send</button>
                <p className="caracteres_chat_influencer">0/200</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StreamingInfluencer;
