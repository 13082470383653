import React, {useEffect, useState} from 'react'
import {useParams, useLocation, useHistory} from "react-router-dom"
import { UserDAO } from "daos";
import {useDispatch } from "react-redux"
import * as actions from "../../store/actions";
import Loader from "../../components/comunes/loader";
const userDAO = new UserDAO();

const LoginTicket = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const query = useQuery()
    const history = useHistory()
    useEffect(()=>{
        if (params && query)
        {
            let ticket = params.ticket
            let eventId= query.get("event")
             if (ticket && eventId){
                ticketLogin(ticket, eventId)
             }
            console.log({ticket:params.ticket, event: query.get("event")})
        }


    },[params, query ])
    const ticketLogin = async (ticket, eventId)=>{
        try {
            const user = await userDAO.loginWithTicket({ticket})
            const userToken = user.data.data.user_app_token
            const env = {
              token: userToken
            }
            localStorage.setItem("env", JSON.stringify(env));
            
            if (env.token) {
              await dispatch(actions.getUser(env.token))
              await dispatch(actions.getInfluencer(env.token))
            }
            if (userToken){
                dispatch(actions.getUser(userToken))
            }
            history.push(`/event/${eventId}`) 
        }
        catch (e){
            localStorage.setItem("ticketSuccess", JSON.stringify(  {success:false, message:getTicketMessage(e.response.data.error[0].error_code)}));
            history.push(`/event/${eventId}`) 
            console.error(e)
        }

    }
    const getTicketMessage = (code)=>
    {
        console.log ({code})
        let message
        switch (code){
            case 0:
                message="There has been an internal error. Can you try again?"
                return message

            case 400: 
                message= "We couldn't find this event. Questions? Contact us at support@gotmy.com "
                return message

            case 1700:
                message= "This ticket is not valid. Questions? Contact us at support@gotmy.com"
                return message

            case 1701:
                message="This ticket has expired!"
                return message

            default: 
                message= "This ticket has probably expired or is invalid. Questions? Contact us at support@gotmy.com"
                return message
        }
    }
    // A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
    return (
        <div>
            <Loader/>
        </div>
    )
}
export default  (LoginTicket)