import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Api } from "../../../../environment/environment";
import { withRouter } from "react-router-dom";
import SweetAlert from "sweetalert2-react";
import {useDispatch } from 'react-redux'
import * as actions from '../../../../store/actions/user';

const VerifyInfluencerTwitter = ({ history }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch()

  const verify = useCallback(async () => {
    const url = new URL(window.location.href.replace("#", ""));
    const oauth_token = url.searchParams.get("oauth_token");
    const oauth_verifier = url.searchParams.get("oauth_verifier");
    const actionTwitter = localStorage.getItem("twitterAction");
    const redirectUrl = localStorage.getItem("redirectUrl");
    
    if (
      !oauth_token ||
      !oauth_verifier ||
      (actionTwitter !== "verify" || !actionTwitter)
    )
      return;

    const myToken = JSON.parse(localStorage.getItem("env"));
    try {
      await axios.post(
        `${Api}user/verify_twitter`,
        { oauth_token, oauth_verifier: oauth_verifier.split("/")[0] },
        {
          headers: {
            "Access-Token": myToken.token,
            "Content-Type": "application/json"
          }
        }
      );
      setErrorMessage("Your Twitter profile was verified!")
      dispatch(actions.getUser(myToken.token))

    } catch (err) {
      setErrorMessage("We were unable to verify your twitter profile");
    } finally {
      history.push(redirectUrl? redirectUrl: '/');
      localStorage.removeItem("twitterAction");
      localStorage.removeItem("redirectUrl");
    }
  }, [history]);

  useEffect(() => {
    verify();
  }, [verify]);

  return (
    errorMessage.length>0 && (
      <SweetAlert
        show={errorMessage}
        title={errorMessage}
        onConfirm={() => {
          setErrorMessage("");
          // history.push("/");
        }}
      />
    )
  );
};

export default withRouter(VerifyInfluencerTwitter);
