import React, {Component} from 'react';
import './style.scss';
import map from "../../../assets/img/icons/ic-map.svg";
import usr from "../../../assets/img/user.png";

class User_ban extends Component {
    render() {
        return (
            <div className="user_bans">
              <div className="d-flex">  
                <div className="d-flex flex-row">
                    <div className = "d-flex flex-column align-items-center ml-4 card_banned">
                    <img className="Foto_ban" src={usr} alt=""/>
                    <span className="Nombre">Minnie Lawrence</span>
                  <span className="Hor"> <img src={map} alt=""/>Country</span>
                </div>
                <div className = "d-flex flex-column align-items-center ml-4 card_banned">
                    <img className="Foto_ban" src={usr} alt=""/>
                    <span className="Nombre">Minnie Lawrence</span>
                  <span className="Hor"> <img src={map} alt=""/>Country</span>
                </div>
                <div className = "d-flex flex-column align-items-center ml-4 card_banned">
                    <img className="Foto_ban" src={usr} alt=""/>
                    <span className="Nombre">Minnie Lawrence</span>
                  <span className="Hor"> <img src={map} alt=""/>Country</span>
                </div>
              </div>
            </div>
        </div>
        );
    }
}
export default User_ban;

