import React, { useState } from "react";
import "./style.scss";
import { connect, useSelector } from "react-redux";
import NavBarInfluencer from "../comunes/navbar_influencer";
import SweetAlert from "sweetalert2-react";
import usePayoutLogic from "./InfluencerPayoutLogic";
import { Modal } from "react-bootstrap";

import logo from "../../assets/img/logo.png";
import paypalLogo from "../../assets/img/icons/Paypal.svg";
import Sad from "../../assets/img/icons/sad-face-in-rounded-square.svg";
import i18next from "i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  LOGIN_BACK_URL,
  STRIPE_CLIENT_ID,
} from "../../environment/environment";
const MyInfluencerPayoutMethods = ({ user }) => {
  const {
    stripeEmail,
    setStripeEmail,
    paypalEmail,
    setPaypalEmail,
    updatePaypalEmail,
    tmpPaypalEmail,
    seTtmpPaypalEmail,
    setErrorForm,
    errorForm,
    showModalPaypal,
    handleCloseModalPaypal,
    handleShowModalPaypal,
  } = usePayoutLogic({ user });
  let stripeUrl = `https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${LOGIN_BACK_URL}stripeAccounCallback`;
  const popUpPaypal = React.createRef();
  const [openExressModal, setOpenExpressModal] = useState(false);
  const handleAddStripe = () => {
    window.location.href = stripeUrl;
  };
  const handleOpenExpressModal = () => {
    console.log("handleonepexpress");
    if (openExressModal === false) {
      setOpenExpressModal(true);
    }
  };
  const handleStripeCashout = () => {
    console.log("stripe cashout");
    setOpenExpressModal(false);
  };
  const hideExpressModal = () => {
    setOpenExpressModal(false);
  };
  const stripeId = useSelector(
    (state) => state.user.influencer.influencer_stripe_customer_id
  );
  return (
    <div>
      <header>
        <NavBarInfluencer />
      </header>
      <div className="container container_my_balance" id="mybalance">
        <div className="d-flex header_stats">
          <img src={logo} className="padding-right-10 Logo" />
        </div>
        <div className="text-center">
          <h2>{i18next.t("InfluencerPayoutSetupPayoutMethod")}</h2>
        </div>

        {paypalEmail || stripeId ? (
          <div>
            <div className="text-center">
              <h4 className="grey">
                {i18next.t("InfluencerPayoutBindedMethods")}
              </h4>
            </div>

            <div>
              <h3 className="mt-4 mb-3">
                {i18next.t("InfluencerPayoutYourPayoutMethods")}
              </h3>

              <div
                className="d-flex align-items-center justify-content-center"
                style={{ flexDirection: "column" }}
              >
                {paypalEmail && (
                  <div>
                    <div>
                      <img src={paypalLogo}></img>
                      <input
                        type="text"
                        className="inputNameGroup"
                        value={paypalEmail}
                        disabled={true}
                      ></input>
                    </div>
                  </div>
                )}
                {stripeId && (
                  <div
                    className="ml-5 "
                    onClick={() => handleOpenExpressModal(true)}
                  >
                    <div>
                      <Modal
                        className="modalxd"
                        show={false}
                        onHide={hideExpressModal}
                      >
                        <Modal.Body>
                          <h3 className="text-center">Cash out with stripe</h3>
                          {/* <p className="text-center grey">{i18next.t("InfluencerPayoutPaypalExplanation")}</p> */}

                          <div className="text-center mt-2">
                            <button
                              className="Btn-rojo boton_go mt-3"
                              onClick={() => handleStripeCashout()}
                            >
                              {i18next.t("InfluencerPayoutContinue")}
                            </button>
                          </div>
                        </Modal.Body>
                      </Modal>

                      <div className="text-center">
                        <button className="Btn-rojo">Express</button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <h3 className="mt-4" style={{ textAlign: "center" }}>
                {" "}
                {i18next.t("InfluencerPayoutChangeAccount")}
              </h3>
              <div className="d-flex align-items-center justify-content-center mt-1">
                <div
                  className="col-4 d-flex align-items-center justify-content-center"
                  style={{ flexDirection: "column" }}
                >
                  <button
                    className="Btn-paypal"
                    onClick={() => {
                      handleShowModalPaypal();
                    }}
                  >
                    Paypal
                  </button>
                  <Modal
                    className="modalxd"
                    show={showModalPaypal}
                    onHide={handleCloseModalPaypal}
                  >
                    <Modal.Body
                      className="d-flex align-items-center justify-content-center"
                      style={{ flexDirection: "column" }}
                    >
                      <h3 className="text-center">
                        {i18next.t("InfluencerPayoutLinkPaypal")}
                      </h3>
                      <p className="text-center grey">
                        {i18next.t("InfluencerPayoutPaypalExplanation")}
                      </p>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ flexDirection: "column" }}
                      >
                        Paypal email
                        <input
                          type="text"
                          maxLength="255"
                          className="inputNameGroup"
                          value={tmpPaypalEmail}
                          onChange={(e) =>
                            seTtmpPaypalEmail(e.target.value.toLowerCase())
                          }
                        ></input>
                      </div>
                      <div className="text-center mt-3">
                        <button
                          className="Btn-rojo boton_go"
                          onClick={() => updatePaypalEmail(popUpPaypal)}
                        >
                          {i18next.t("InfluencerPayoutContinue")}
                        </button>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <p className="mt-1">
                    {i18next.t("InfluencerPayoutPaypalExtraExpla")}
                  </p>
                </div>
                <div
                  className="col-4 d-flex align-items-center justify-content-center"
                  style={{ flexDirection: "column" }}
                >
                  <button
                    className="Btn-rojo"
                    onClick={() => handleAddStripe()}
                  >
                    Express
                  </button>

                  <p className="mt-1">
                    {i18next.t("InfluencerPayoutExpressExplanation")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h4>{i18next.t("InfluencerPayoutNeedPaymentMethod")}</h4>
            <h3 className="mt-4">
              {" "}
              {i18next.t("InfluencerPayoutLinkAccount")}
            </h3>
            <div className="d-flex align-items-center justify-content-center mt-1">
              <div
                className="col-4 d-flex align-items-center justify-content-center"
                style={{ flexDirection: "column" }}
              >
                <button
                  className="Btn-paypal"
                  onClick={() => {
                    handleShowModalPaypal();
                  }}
                >
                  Paypal
                </button>
                <Modal
                  className="modalxd"
                  show={showModalPaypal}
                  onHide={handleCloseModalPaypal}
                >
                  <Modal.Body>
                    <h3 className="text-center">
                      {i18next.t("InfluencerPayoutLinkPaypal")}
                    </h3>
                    <p className="text-center grey">
                      {i18next.t("InfluencerPayoutPaypalExplanation")}
                    </p>
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ flexDirection: "column" }}
                    >
                      Paypal email
                      <input
                        type="text"
                        maxLength="255"
                        className="inputNameGroup"
                        value={tmpPaypalEmail}
                        onChange={(e) =>
                          seTtmpPaypalEmail(e.target.value.toLowerCase())
                        }
                      ></input>
                    </div>
                    <div className="text-center mt-3">
                      <button
                        className="Btn-rojo boton_go"
                        onClick={() => updatePaypalEmail(popUpPaypal)}
                      >
                        {i18next.t("InfluencerPayoutContinue")}
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
                <p className="mt-1">
                  {i18next.t("InfluencerPayoutPaypalExtraExpla")}
                </p>
              </div>
              <div
                className="col-4 d-flex align-items-center justify-content-center"
                style={{ flexDirection: "column" }}
              >
                <button className="Btn-rojo" onClick={() => handleAddStripe()}>
                  Express
                </button>

                <p className="mt-1">
                  {i18next.t("InfluencerPayoutExpressExplanation")}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <SweetAlert
        imageUrl={Sad}
        show={errorForm}
        title={errorForm}
        onConfirm={() => setErrorForm("")}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(MyInfluencerPayoutMethods);
