import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./style.scss";
import { useMenuViewerLogic } from "./MenuViewerLogic";
import { FollowingYou, PrivateMeeting, BookendEvent, NewEvent } from "./component";
import typeActitiy from "../../../../constants/typeActitiy";
import { connect } from "react-redux";
import { hideNotReadyContent, IS_DEV_ENVIRONMENT } from "../../../../environment/environment"
import Activities from "../../../Activities/Activities"
import useComponentVisible from '../../../../hooks/useComponentVisible'
import i18next from 'i18next';

const Menu = ({ user, loadingUserInfo, mobileNav }) => {
  const { activities } = useMenuViewerLogic();
  
  const { token } = JSON.parse(localStorage.getItem("env") || "{}");
  const [showActivities, setShowActivities] = useState(false)
  const { ref, isComponentVisible , setIsComponentVisible} = useComponentVisible(true);
  const handleShowActivities = (val) => {
    setShowActivities(val)
    setIsComponentVisible(val)
  }
  useEffect(()=> {
    if (isComponentVisible===false){
      setShowActivities(false)
    }
  }, [isComponentVisible])
  return (
    <nav expand="lg" className="Nav-menu">
    {/* <nav expand="lg" className=""> */}
      <ul style={{
        display: 'flex', flexDirection: mobileNav && "column",
        textAlign: mobileNav && 'start', padding: 'mobileNav' && '0px'
      }}>
        <li style={{ textAlign: mobileNav && 'start', marginBottom: mobileNav && '12px' }}>
          <NavLink exact to={`/`} activeClassName="activo">
            <i style={{ marginRight: mobileNav && '12px' }} className="fas fa-fire"></i>
            <span>{i18next.t("MenuViewerComponentDiscover")}</span>
          </NavLink>
        </li>
        <li style={{ textAlign: mobileNav && 'start', marginBottom: mobileNav && '12px' }}>
          <NavLink exact to={`/search`} activeClassName="activo">
            <i style={{ marginRight: mobileNav && '12px' }} className="fas fa-search"></i>

            <span>{i18next.t("MenuViewerComponentSearch")}</span>
          </NavLink>
        </li >
        {(token || (Object.keys(user).length > 0 || loadingUserInfo))  &&
          <li style={{ textAlign: mobileNav && 'start', marginBottom: mobileNav && '12px' }}>

            <NavLink to={`/live_events`} activeClassName="activo">
              <i style={{ marginRight: mobileNav && '12px' }} className="far fa-calendar-alt"></i>
              <span>{i18next.t("MenuViewerComponentLiveEvent")}</span>
            </NavLink>
          </li>}
        {(token || (Object.keys(user).length > 0 || loadingUserInfo))   &&
          <li style={{ textAlign: mobileNav && 'start', marginBottom: mobileNav && '12px' }}>
            <NavLink to={`/message/private_request`} activeClassName="activo">
              <i style={{ marginRight: mobileNav && '12px' }} className="fas fa-comment-dots"></i>
              <span>{i18next.t("MenuViewerComponentRequests")}</span>
            </NavLink>
          </li>
        }

        {(token || (Object.keys(user).length > 0 || loadingUserInfo))  && false && 
        
        <li style={{textAlign:mobileNav && 'start',marginBottom: mobileNav && '12px'}}>
          <div style={{ position: 'relative', display: mobileNav && 'flex' }} onClick={() => handleShowActivities(!showActivities) } className="Logued-div">
            <div style={{display: mobileNav && 'flex' , flexDirection:mobileNav&& 'row'}}>
              <i style={{ marginRight: mobileNav && '12px' }} className="fas fa-bell"></i>
              <span>{i18next.t("MenuViewerComponentActivity")}</span>
            </div>
          </div>

          {showActivities && activities && 
            <div ref={ref} style={{ position: 'relative' }}>
              {isComponentVisible && <Activities activities={activities} />} 

            </div>

          }
     
        </li>
        }
      </ul>
    </nav>
  );
};


// export default Menu;
const mapStateToProps = state => {
  return {
    user: state.user.user,
    loadingUserInfo: state.user.loading,
    influencer: state.user.influencer,
    isInfluencer: state.user.isInfluencer
  };
};
export default connect(mapStateToProps)(Menu)
