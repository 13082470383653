import React from 'react'
import i18next from 'i18next';
import infoIcon from "assets/img/information-button.png";
import { NEW_PUBLISH_PAGE } from '../../../../environment/environment';

 const SelectStreamingType = ({ setModalEventInfo,onChangeStreamingType,
    influencerInfo, streamingTypes}) => {
    return (
        <div style={{ minWidth: 280, maxWidth: 280, display: 'flex', flexDirection: 'column', }}>
        <div style={{ display: "flex", marginBottom: "0.25rem" }}>
          <span style={{ color: 'grey', fontSize: '14px', marginRight: 8 }}> {i18next.t("LiveEventInformationStreamType")} </span>
          <img onClick={() => setModalEventInfo(true)} style={{ marginTop: 4, height: 16, width: 16, marginLeft: 6, cursor: "pointer" }} src={infoIcon} />
        </div>
    
        <div style={{ display: "flex" }}>
          <select disabled={influencerInfo && influencerInfo.influencer_massive_streaming === false} className="select_category" name="streamingType" onChange={onChangeStreamingType}>
            {streamingTypes.map(stream => {
              return (
                <option id={stream.event_type} value={stream.str_description} key={stream.event_type}>
                  {stream.str_description}
                </option>
              )
            }
            )}
          </select>
        </div>
      </div>
    )
}
export default SelectStreamingType