import React, {useCallback, useEffect, useRef, useState} from "react";
import {EventDAO} from "daos";
import typeEventsStreaming from "../../constants/typeEventsStreaming";
import {toast} from "react-toastify";
import * as LogTypes from "../../constants/LogTypes";
import {isMobile} from "react-device-detect";
import Video from "twilio-video";
import {IS_DEV_ENVIRONMENT} from "../../environment/environment";
import io from "socket.io-client";
import {useLocation} from "react-router-dom";
import i18next from "i18next";
import Config from "../../environment/configSelector";

const AsyncLock = require("async-lock");
const lock = new AsyncLock();

const ENDPOINT = IS_DEV_ENVIRONMENT
  ? Config.DEV_SOCKETIO_ENDPOINT
  : Config.SOCKETIO_ENDPOINT;
// const ENDPOINT = "https://socket.dev.gotmy.com";

const trimNewlines = require("trim-newlines");
const MAX_RECONNECTCOUNTDOWN = 30;

var actualRemoteSpeakingUser = null;

var DetectRTC = require("detectrtc");
//DetectRTC.load();

let viewersGlobal = [];
const eventDAO = new EventDAO();

let eventOkTimer = null;
const SOCKETIO_CONFIRMATION_SIGNAL_TIMEOUT = 5000;

let publishingVideoLock = false;
let publishedVideo = false;

let isSharingScreenEnabled = false;

let publishingAudioLock = false;
let publishedAudio = false;

//2 seconds till auto refresh sessionState
let shouldInitNewPublisher = false;
let temporalNewOutputAudioDevice = null;
let temporalNewOutputAudioDeviceId = null;
let temporalNewAudioDevice = null;
let temporalNewAudioDeviceId = null;
let temporalNewVideoDevice = null;
let temporalNewVideoDeviceId = null;

//reconnect timeout
let connectUpdateState = false;
let reconnectCountdownCompleteTimer = null;
let reconnectCountdownCompleteState = false;

export const usePreviewLogic = ({
  user,
  uuid,
  onSetOptionsModerators,
}) => {
  const [resourcesLoaded, setResourcesLoaded] = useState(false);
  const [minimisedChat, setMinimisedChat] = useState(false);
  const [session, setSession] = useState(null);
  const [OVObject, setOVObject] = useState(null);
  const [chats, setChats] = useState([]);
  const [eventattendees, setEventAttendees] = useState([]);
  const [viewersConnected, setViewersConnected] = useState([]);
  const [message, setMessage] = useState("");
  const [microphone, setMicrophone] = useState(true);
  const [microphoneViewer, setMicrophoneViewer] = useState(false);
  const [audioShareScreen, setAudioShareScreen] = useState(true);
  const [webcam, setWebCam] = useState(true);
  const [localTracks, setLocalTracks] = useState(null);
  const [webcamViewer, setWebCamViewer] = useState(true);
  const [autoUpdate, setAutoUpdate] = useState(false);
  const [updatingSession, setUpdatingSession] = useState(false);
  const [myPublisher, setMyPublisher] = useState(null);
  const [mySpeaking_viewer, setMyspeaking_viewer] = useState(null);
  const [speaking_viewer, setSpeaking_viewer] = useState(null);
  const [startingStreaming, setStartingStreaming] = useState(false);
  const [streamingOnline, setStreamingOnline] = useState(true);
  const [streamingOn, setstreamingOn] = useState(false);
  const [eventData, setEventData] = useState({});
  const [enableRiseHand, setEnableRiseHand] = useState(false);
  const [enabledChat, setEnabledChat] = useState(true);
  const [enabledOpenMic, setEnabledOpenMic] = useState(false);
  const [showChooseRaiseHand, setShowChooseRaiseHand] = useState(false);
  const [viewersWaiting, setViewersWaiting] = useState([]);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [publications, setPublications] = useState([]);
  const [remotePublications, setRemotePublications] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [localSessionParticipants, setLocalSessionParticipants] = useState(
    new Map()
  );
  const [audioTracks, setAudioTracks] = useState([]);
  const [videoAudioTracks, setVideoAudioTracks] = useState([]);
  const [mainStreaming, setMainStreaming] = useState(null);
  const [userNameRaiseHand, setUserNameRaiseHnad] = useState(null);
  const [isRemoteApp, setIsRemoteApp] = useState(false);
  const [viewers, setViewers] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [shareScreenSource, setShareScreenSource] = useState(null);
  const [isSharingScreen, setIsSharingScreen] = useState(false);
  const [screenShareTracks, setScreenShareTracks] = useState(null);
  const [shareScreenSession, setShareScreenSession] = useState(null);
  const [activeUsers, setActiveUsers] = useState(0);
  const [sessionError, setSessionError] = useState(null);
  const [sessionConnected, setSessionConnected] = useState(false);
  const [sessionLoading, setSessionLoading] = useState(false);
  const [sessionReset, setSessionReset] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [hasTurn, setHasTurn] = useState(false);
  const [viewerPublisher, setViewerPublisher] = useState(null);
  const [risedHand, setRiseHand] = useState(false);
  const [streamingFinished, setStreamingFinished] = useState(false);
  const [feedBackText, setFeedBackText] = useState("");
  const [feedBackScore, setFeedBackScore] = useState(null);
  const [renderRedirect, setRenderRedirect] = useState(false);
  const [showDisconnectDialog, setShowDisconnectDialog] = useState(false);
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [disabledStartStreaming, setDisabledStartStreaming] = useState(false);
  const [currentVideoDevice, _setCurrentVideoDevice] = useState(null);
  const [currentAudioDevice, _setCurrentAudioDevice] = useState(null);
  const [currentOutputAudioDevice, setCurrentOutputAudioDevice] = useState(
    null
  );
  const [availableDevices, _setAvailableDevices] = useState(null);
  const [isChangingSettings, setIsChangingSettings] = useState(false);
  const [socket, setSocket] = useState(null);
  const [selectStreamingDevices, setSelectStreamingDevices] = useState({
    selectedDefault: false,
    showSelectionModal: true,
    availableDevicesInitialized: false,
    disableCloseButton: true,
    isChangingSettings: false,
    shouldInitNewPublisher: false,
    temporalNewOutputAudioDevice: null,
    temporalNewOutputAudioDeviceId: null,
    temporalNewAudioDevice: null,
    temporalNewAudioDeviceId: null,
    temporalNewVideoDevice: null,
    temporalNewVideoDeviceId: null,
    disableChangeCamera: false,
    disableChangeAudioOutputDevices: true,
  });
  const [showConnectionRetrying, setShowConnectionRetrying] = useState(false);
  const [isReconnecting, setIsreconnecting] = useState({
    status: false,
    previousStatus: true,
  });
  const [reconnectCountDownLength, setReconnectCountDownLength] = useState(10);
  const [retryingAttempt, setRetryingAttempt] = useState(0);
  const [networkStatus, setNetworkStatus] = useState({
    status: true,
    previousStatus: true,
  });
  const [triggerAutoConnect, setTriggerAutoConnect] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [influencerConnected, setInfluencerConnected] = useState(false);
  const [currentDominantSpeaker, setCurrentDominantSpeaker] = useState({
    enabled: false,
    imageSrc: null,
    participant: null,
  });
  const location = useLocation();

  const hasVideoTracks = useRef(true);
  const hasAudioTracks = useRef(true);


  const currentAudioDeviceRef = useRef(currentAudioDevice);

  const setCurrentAudioDevice = (data) => {
    currentAudioDeviceRef.current = data;
    _setCurrentAudioDevice(data);
  };
  const currentVideoDeviceRef = useRef(currentVideoDevice);

  const setCurrentVideoDevice = (data) => {
    currentVideoDeviceRef.current = data;
    _setCurrentVideoDevice(data);
  };
  const availableDevicesRef = useRef(availableDevices);
  const setAvailableDevices = (data) => {
    availableDevicesRef.current = data;
    _setAvailableDevices(data);
  };

  const selectVideoDevice = (videoDevice) => {
    if (OVObject) {
      OVObject.getUserMedia({
        audioSource: currentAudioDevice
          ? currentAudioDevice.deviceId
          : undefined,
        videoSource: videoDevice.deviceId,
        resolution: "1280x720",
        frameRate: 30,
      })
        .then((mediaStream) => {
          if (isInfluencer) {
            myPublisher
              .replaceTrack(mediaStream.getVideoTracks()[0])
              .then(() => {
                setCurrentVideoDevice(videoDevice);
              })
              .catch((error) => console.error("Error replacing track"));
          } else {
            viewerPublisher
              .replaceTrack(mediaStream.getVideoTracks()[0])
              .then(() => {
                setCurrentVideoDevice(videoDevice);
              })
              .catch((error) => console.error("Error replacing track"));
          }
        })
        .catch((error) => {
          console.error(
            "There was a problem obtaining the new mediaStream for video device"
          );
        });
    }
  };

  const setNewOutputAudioDevice = (newOutputAudioDevice) => {
    try {
      if (newOutputAudioDevice && newOutputAudioDevice.deviceId) {
        let videoList = document.getElementsByTagName("video");
        let audioList = document.getElementsByTagName("audio");

        for (let videoelement of videoList) {
          videoelement.setSinkId(newOutputAudioDevice.deviceId);
        }

        for (let audioElement of audioList) {
          audioElement.setSinkId(newOutputAudioDevice.deviceId);
        }

        setCurrentOutputAudioDevice(newOutputAudioDevice);
        setSelectStreamingDevices({
          ...selectStreamingDevices,
          isChangingSettings: false,
        });
      }
    } catch (error) {
      console.error("There was an error changing current audio output:", error);
    }
  };

  const toggleStreamingOptionsModal = (shouldBeOpen) => {
    console.log(
      "Se llama a toggleStreamingOptionsModal",
      shouldBeOpen,
      selectStreamingDevices,
      selectStreamingDevices.showSelectionModal
    );

    if (shouldBeOpen === undefined) {
      setSelectStreamingDevices({
        ...selectStreamingDevices,
        showSelectionModal: !selectStreamingDevices.showSelectionModal,
      });
    } else {
      if (shouldBeOpen) {
        if (selectStreamingDevices.disableChangeCamera) {
          setSessionError(
            i18next.t(
              "PreviewStreamingInfluencerComponentBrowserNotSupportSwitchMediaDeviceDuringStreaming"
            )
          );
        } else {
          setSelectStreamingDevices({
            ...selectStreamingDevices,
            showSelectionModal: shouldBeOpen,
          });
        }
      } else {
        setSelectStreamingDevices({
          ...selectStreamingDevices,
          showSelectionModal: shouldBeOpen,
        });
      }
    }
  };


  const initNewPublisherFromStreamingOptionsChanged = (
    audioDevice,
    audioDeviceId,
    videoDevice,
    videoDeviceId
  ) => {
    try {
      let newProperties = {
        audioSource: audioDeviceId,
        videoSource: videoDeviceId,
        publishAudio: microphone, // Whether you want to start publishing with your audio unmuted or not
        publishVideo: webcam, // Whether you want to start publishing with your video enabled or not
        resolution: "1280x720", // The resolution of your video
        frameRate: 30, // The frame rate of your video
        mirror: false, // Whether to mirror your local video or not
      };

      initNewPublisher(newProperties, () => {
        shouldInitNewPublisher = false;
        temporalNewOutputAudioDevice = null;
        temporalNewOutputAudioDeviceId = null;
        temporalNewAudioDevice = null;
        temporalNewAudioDeviceId = null;
        temporalNewVideoDevice = null;
        temporalNewVideoDeviceId = null;

        setSelectStreamingDevices({
          ...selectStreamingDevices,
          shouldInitNewPublisher: false,
          temporalNewOutputAudioDevice: null,
          temporalNewOutputAudioDeviceId: null,
          temporalNewAudioDevice: null,
          temporalNewAudioDeviceId: null,
          temporalNewVideoDevice: null,
          temporalNewVideoDeviceId: null,
        });
        //toggleisChangingStreamingOptions(false);
        toggleStreamingOptionsModal(false);
        setCurrentAudioDevice(audioDevice);
        setCurrentVideoDevice(videoDevice);

        try {
          if (temporalNewOutputAudioDevice) {
            setNewOutputAudioDevice(temporalNewOutputAudioDevice);
          }
        } catch (error) {
          console.error(
            "There was an error changing new output audio device in initNewPublisherFromStreamingOptionsChanged",
            error
          );
        }
      });
    } catch (error) {
      console.error("initNewPublisherFromStreamingOptionsChanged error", error);
    }
  };

  const parseSelectedDevice = (
    newVideoDevice = null,
    newAudioDevice = null,
    newOutputAudioDevice = null
  ) => {
    let videoDevice = currentVideoDevice;
    let videoDeviceId = undefined;
    let audioDevice = currentAudioDevice;
    let audioDeviceId = undefined;
    let outputAudioDevice = currentOutputAudioDevice;
    let outputAudioDeviceId = undefined;
    let localShouldPublish = false;

    try {
      if (currentVideoDevice && currentVideoDevice.deviceId) {
        videoDeviceId = currentVideoDevice.deviceId;
      }

      if (currentAudioDevice && currentAudioDevice.deviceId) {
        audioDeviceId = currentAudioDevice.deviceId;
      }

      if (currentOutputAudioDevice && currentOutputAudioDevice.deviceId) {
        outputAudioDeviceId = currentOutputAudioDevice.deviceId;
      }

      //Check if currentVideoDevice was correctly set and then check if newVideoDevice is not null and different than the previous videoDevice
      if (!currentVideoDevice) {
        if (newVideoDevice && newVideoDevice.deviceId) {
          videoDevice = newVideoDevice;
          videoDeviceId = newVideoDevice.deviceId;
          //Set if we should unpublish and publish the new stream with the new devices
          localShouldPublish = true;
        } else {
          //In case there was an error and no previous videoDevice was set we should assign undefined to the videosource and Openvidu will take the default video source device
          videoDevice = null;
          videoDeviceId = undefined;
        }
      } else {
        if (newVideoDevice && newVideoDevice.deviceId) {
          if (
            currentVideoDevice &&
            currentVideoDevice.deviceId !== newVideoDevice.deviceId
          ) {
            videoDevice = newVideoDevice;
            videoDeviceId = newVideoDevice.deviceId;
            //Set if we should unpublish and publish the new stream with the new devices
            localShouldPublish = true;
          }
        }
      }

      //Check if currentAudioDevice was correctly set and then check if newAudioDevice is not null and different than the previous audioDevice
      if (!currentAudioDevice) {
        if (newAudioDevice && newAudioDevice.deviceId) {
          audioDevice = newAudioDevice;
          audioDeviceId = newAudioDevice.deviceId;
          //Set if we should unpublish and publish the new stream with the new devices
          localShouldPublish = true;
        } else {
          //In case there was an error and no previous audioDevice was set we should assign undefined to the audioDevice and Openvidu will take the default audio source device
          audioDevice = null;
          audioDeviceId = undefined;
        }
      } else {
        if (newAudioDevice && newAudioDevice.deviceId) {
          if (
            currentAudioDevice &&
            currentAudioDevice.deviceId !== newAudioDevice.deviceId
          ) {
            audioDevice = newAudioDevice;
            audioDeviceId = newAudioDevice.deviceId;
            //Set if we should unpublish and publish the new stream with the new devices
            localShouldPublish = true;
          }
        }
      }

      //Check if currentOutputAudioDevice was correctly set and then check if newAudioDevice is not null and different than the previous audioDevice
      if (!currentOutputAudioDevice) {
        if (newOutputAudioDevice && newOutputAudioDevice.deviceId) {
          outputAudioDevice = newOutputAudioDevice;
          outputAudioDeviceId = newOutputAudioDevice.deviceId;
        } else {
          //In case there was an error and no previous audioDevice was set we should assign undefined to the audioDevice and Openvidu will take the default audio source device
          outputAudioDevice = null;
          outputAudioDeviceId = undefined;
        }
      } else {
        if (newOutputAudioDevice && newOutputAudioDevice.deviceId) {
          if (
            currentOutputAudioDevice &&
            currentOutputAudioDevice.deviceId !== newOutputAudioDevice.deviceId
          ) {
            outputAudioDevice = newOutputAudioDevice;
            outputAudioDeviceId = newOutputAudioDevice.deviceId;
          }
        }
      }

      return {
        videoDevice,
        videoDeviceId,
        audioDevice,
        audioDeviceId,
        outputAudioDevice,
        outputAudioDeviceId,
        localShouldPublish,
      };
    } catch (error) {
      console.error("ParseSelectedDevice", error);

      return null;
    }
  };

  const shouldPublishAudioTrack = (
    newAudioDevice = null,
    forceToPublish = false
  ) => {
    let result = false;

    if (
      (forceToPublish && (isInfluencer || hasTurn || enabledOpenMic)) ||
      (newAudioDevice &&
        currentAudioDevice.deviceId !== newAudioDevice.deviceId &&
        (isInfluencer || hasTurn || enabledOpenMic))
    ) {
      result = true;
    }

    console.log(
      "shouldPublishAudioTrack",
      result,
      newAudioDevice,
      forceToPublish,
      forceToPublish && (isInfluencer || hasTurn || enabledOpenMic)
    );
    return result;
  };

  const shouldPublishVideoTrack = (
    newVideoDevice = null,
    forceVideoPublish = false
  ) => {
    let result = false;

    if (
      (forceVideoPublish && (isInfluencer || hasTurn)) ||
      (newVideoDevice &&
        currentVideoDevice.deviceId !== newVideoDevice.deviceId &&
        (isInfluencer || hasTurn))
    ) {
      result = true;
    }

    return result;
  };

  const switchLocalTracks = (
    newAudioDevice,
    newVideoDevice,
    forceVideoPublish = false,
    forceAudioPublish = false
  ) => {
    console.log("switchLocalTracks", newAudioDevice, newVideoDevice);
    if (shouldPublishVideoTrack(newVideoDevice, forceVideoPublish)) {
      publishVideoTrack(
        newVideoDevice,
        (newTrack) => {
          try {
            //removePreviousLocalVideoTrack(currentVideoDevice);
          } catch (error) {}
          switchCameraCallback(newTrack, newVideoDevice);
        },
        (error) => {
          //toggleStreamingOptionsModal(false);
        }
      );
    }

    if (shouldPublishAudioTrack(newAudioDevice, forceAudioPublish)) {
      publishAudioTrack(
        newAudioDevice,
        (newTrack) => {
          console.log("Audio track published", newTrack);
          switchMicrophoneCallback(newTrack, newAudioDevice);
        },
        (error) => {
          //toggleStreamingOptionsModal(false);
        }
      );
    }
  };

  const switchCameraCallback = (localVideoTrack, videoDevice) => {
    try {
      let localParticipant = session.localParticipant;
      const tracks = Array.from(localParticipant.videoTracks.values());

      if (tracks.length > 0) {
        localParticipant.unpublishTrack(tracks[0].track);

        tracks.forEach((track) => {
          removeAndDetachTrack(track);
        });

        let priority = "high";

        if (!isInfluencer) {
          priority = "low";
          //setMyspeaking_viewer(null);
          //setSpeaking_viewer(null);
          setRefreshTokenDelay(4000, true);
        }

        localParticipant
          .publishTrack(localVideoTrack, {
            priority: priority,
          })
          .then((publication) => {
            if (hasTurn) {
              setMyspeaking_viewer(speaking_viewer);
              remoteTrackPublished(
                { track: publication.track, participant: localParticipant },
                localParticipant,
                true
              );
            }
            setRefreshTokenDelay(1500);

            setCurrentVideoDevice(videoDevice);

            //toggleStreamingOptionsModal(false);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } catch (error) {
      //toggleStreamingOptionsModal(false);
    }
  };

  const switchMicrophoneCallback = (localAudioTrack, audioDevice) => {
    try {
      let localParticipant = session.localParticipant;
      const tracks = Array.from(localParticipant.audioTracks.values());

      console.log("switchMicrophoneCallback", localAudioTrack, audioDevice);

      for (let i = 0; i < tracks.length; i++) {
        try {
          localParticipant.unpublishTrack(tracks[i].track);
          removeAndDetachTrack(tracks[i]);
        } catch (error) {
          console.error("switchMicrophoneCallback unpublishing tracks", error);
        }
      }

      localParticipant
        .publishTrack(localAudioTrack)
        .then((publication) => {
          console.log("Successfully published to the session");
          setCurrentAudioDevice(audioDevice);

          //toggleStreamingOptionsModal(false);
        })
        .catch((error) => {
          console.error("switchMicrophoneCallback", error);
        });
    } catch (error) {
      console.error("switchMicrophoneCallback", error);
      //toggleStreamingOptionsModal(false);
    }
  };

  const publishVideoTrack =(
    videoDevice,
    callback = null,
    errorCallback = null
  ) => {
    Video.createLocalVideoTrack({
      name: videoDevice.deviceId + Date.now(),
      deviceId: { exact: videoDevice.deviceId },
      //width: 1920,
      //height: 1080,
      //frameRate: 30
    })
      .then(function(localVideoTrack) {
        if (callback) {
          callback(localVideoTrack);
        }
        setRefreshTokenDelay(3000);
      })
      .catch((error) => {
        if (errorCallback) {
          errorCallback(error);
        }
        console.error(error);
      });
  };

  const publishAudioTrack = (
    audioDevice,
    callback = null,
    errorCallback = null
  ) => {
    Video.createLocalAudioTrack({
      name: audioDevice.deviceId + Date.now(),
      deviceId: { exact: audioDevice.deviceId },
    })
      .then(function(localTrack) {
        if (callback) {
          callback(localTrack);
        }
        setRefreshTokenDelay(2000);
      })
      .catch((error) => {
        if (errorCallback) {
          errorCallback(error);
        }
        console.error(error);
      });
  };

  /*
   * selectVideoAndAudioDevice set the current audio and video device for the current stream.
   *
   */
  const selectVideoAndAudioDevice = async (
    newVideoDevice = null,
    newAudioDevice = null,
    newOutputAudioDevice = null,
    shouldPublish = true,
    forceVideoPublish = false,
    forceAudioPublish = false
  ) => {
    try {
      console.log(
        "selectVideoAndAudioDevice",
        newVideoDevice,
        newAudioDevice,
        newOutputAudioDevice,
        shouldPublish
      );
      if (session) {
        if (newOutputAudioDevice && newOutputAudioDevice.deviceId) {
          setSelectStreamingDevices({
            ...selectStreamingDevices,
            isChangingSettings: true,
          });
          setNewOutputAudioDevice(newOutputAudioDevice);
        }

        if (session) {
          try {
            let selectedDevices = parseSelectedDevice(
              newVideoDevice,
              newAudioDevice,
              newOutputAudioDevice
            );

            let videoDevice = selectedDevices.videoDevice;
            let videoDeviceId = selectedDevices.videoDeviceId;
            let audioDevice = selectedDevices.audioDevice;
            let audioDeviceId = selectedDevices.audioDeviceId;
            let outputAudioDevice = selectedDevices.outputAudioDevice;
            let outputAudioDeviceId = selectedDevices.outputAudioDeviceId;
            let localShouldPublish = selectedDevices.localShouldPublish;

            if (localShouldPublish) {
              temporalNewOutputAudioDevice = outputAudioDevice;
              temporalNewOutputAudioDeviceId = outputAudioDeviceId;
              temporalNewAudioDevice = audioDevice;
              temporalNewAudioDeviceId = audioDeviceId;
              temporalNewVideoDevice = videoDevice;
              temporalNewVideoDeviceId = videoDeviceId;

              console.log(
                "selectVideoAndAudioDevice publishing",
                newVideoDevice,
                newAudioDevice,
                newOutputAudioDevice,
                shouldPublish,
                setCurrentVideoDevice
              );

              setSelectStreamingDevices({
                ...selectStreamingDevices,
                isChangingSettings: true,
                shouldInitNewPublisher: true,
                temporalNewOutputAudioDevice: outputAudioDevice,
                temporalNewOutputAudioDeviceId: outputAudioDeviceId,
                temporalNewAudioDevice: audioDevice,
                temporalNewAudioDeviceId: audioDeviceId,
                temporalNewVideoDevice: videoDevice,
                temporalNewVideoDeviceId: videoDeviceId,
              });

              switchLocalTracks(
                audioDevice,
                videoDevice,
                forceVideoPublish,
                forceAudioPublish
              );
            } else {
              if (newOutputAudioDevice) {
                setNewOutputAudioDevice(newOutputAudioDevice);
                setSelectStreamingDevices({
                  ...selectStreamingDevices,
                  isChangingSettings: false,
                  //showSelectionModal: false,
                });
              }
            }

            switchLocalTracks(
              audioDevice,
              videoDevice,
              forceVideoPublish,
              forceAudioPublish
            );
          } catch (error) {
            //initNewPublisherFromError();
            console.error(
              "error publishing new publisher initNewPublisherFromError error:",
              error
            );
          }
        }
      } else {
        console.log(
          "selectVideoAndAudioDevice no publish",
          newVideoDevice,
          newAudioDevice,
          newOutputAudioDevice,
          shouldPublish,
          setCurrentVideoDevice
        );
        setNewOutputAudioDevice(newOutputAudioDevice);
        setCurrentAudioDevice(newAudioDevice);
        setCurrentVideoDevice(newVideoDevice);

        if (newOutputAudioDevice && newOutputAudioDevice.deviceId) {
          setNewOutputAudioDevice(newOutputAudioDevice);
        }

        setSelectStreamingDevices({
          ...selectStreamingDevices,
          isChangingSettings: false,
          //showSelectionModal: false,
          disableCloseButton: false,
          selectedDefault: true,
          availableDevicesInitialized: true,
        });
      }
    } catch (error) {}
  };

  const enumerateDevicesAsync = async (stream, setDefault) => {
    try {
      console.log(
        "launchCameraPermission result",
        stream,
        stream.getVideoTracks(),
        stream.getAudioTracks(),
        stream.getTracks()
      );
    } catch (error) {}

    navigator.mediaDevices.enumerateDevices =
      navigator.mediaDevices.enumerateDevices ||
      navigator.mediaDevices.getUserMedia ||
      navigator.mediaDevices.mozGetUserMedia ||
      navigator.mediaDevices.msGetUserMedia ||
      navigator.mediaDevices.webkitGetUserMedia;

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      setSelectStreamingDevices({
        ...selectStreamingDevices,
        availableDevicesInitialized: true,
      });

      let videoInputDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      let audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      let audioOutputDevices = devices.filter(
        (device) => device.kind === "audiooutput"
      );
      let localAudioInputAvailableDevices = [];
      let localAudioOutputAvailableDevices = [];
      let localAvailableDevices = [];

      console.log(
        "launchCameraPermission result",
        stream,
        videoInputDevices,
        audioInputDevices
      );

      audioInputDevices.forEach(function(device) {
        let audioInputDevice = device;

        if (
          true ||
          (device.deviceId !== "default" &&
            device.deviceId !== "communications")
        ) {
          localAudioInputAvailableDevices.push(audioInputDevice);
        }
      });

      if (setDefault && localAudioInputAvailableDevices.length > 0) {
        setCurrentAudioDevice(localAudioInputAvailableDevices[0]);
      }

      audioOutputDevices.forEach(function(device) {
        let audioOutputDevice = device;

        if (
          true ||
          (device.deviceId !== "default" &&
            device.deviceId !== "communications")
        ) {
          localAudioOutputAvailableDevices.push(audioOutputDevice);
        }
      });

      if (setDefault && localAudioOutputAvailableDevices.length > 0) {
        setCurrentOutputAudioDevice(localAudioOutputAvailableDevices[0]);
      }

      if (videoInputDevices.length > 0) {
        let hasVirtualDevice = videoInputDevices.find((videoDevice) =>
          videoDevice.label.toLocaleLowerCase().includes("virtual")
        );
        let device = videoInputDevices[0];

        console.log("setDefault videocamera", hasVirtualDevice);

        if (setDefault) {
          if (hasVirtualDevice) {
            setCurrentVideoDevice(hasVirtualDevice);
          } else {
            setCurrentVideoDevice(device);
          }
        }
      }

      localAvailableDevices = localAudioInputAvailableDevices.concat(
        localAudioOutputAvailableDevices
      );

      setAvailableDevices(localAvailableDevices.concat(videoInputDevices));
      stream.stop();

      return devices;
    } catch (error) {
      console.error("getMediaDevices enumerateDevices async", error);
    }
  };

  const getMediaDevices = (setDefault = false, requestVideo = false) => {
    if (session) {
      enumerateDevices(null, setDefault);
    } else {
      launchCameraPermission(requestVideo)
          .then((stream) => {
            enumerateDevices(stream, setDefault);
          })
          .catch((error) => {
            console.error("LaunchCameraPermission error:", error);

            if(!requestVideo){
              hasVideoTracks.current = false;
              hasAudioTracks.current = false;
            }else{
              hasVideoTracks.current = false;
            }

            sendServerLog(
                "LaunchCameraPermission. Function <getMediaDevices()>. Line 1236.",
                error
            );
            if (
                error.name == "NotFoundError" ||
                error.name == "DevicesNotFoundError"
            ) {
              //required track is missing
              setSessionError(
                  i18next.t(
                      "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia"
                  )
              );
            } else if (
                error.name == "NotReadableError" ||
                error.name == "TrackStartError"
            ) {
              //webcam or mic are already in use
              /*setSessionError(
              i18next.t(
                "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia"
              )
            );*/
              console.log(
                  "NotReadableError",
                  error.name,
                  error,
                  error.description,
                  error.number,
                  error.message
              );
            } else if (
                error.name == "OverconstrainedError" ||
                error.name == "ConstraintNotSatisfiedError"
            ) {
              //constraints can not be satisfied by avb. devices
              setSessionError(
                  i18next.t(
                      "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia"
                  )
              );
            } else if (
                error.name == "NotAllowedError" ||
                error.name == "PermissionDeniedError"
            ) {
              //permission denied in browser
              setSessionError(
                  i18next.t(
                      "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia"
                  )
              );
            } else if (error.name == "TypeError" || error.name == "TypeError") {
              //empty constraints object
              setSessionError(
                  i18next.t(
                      "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia"
                  )
              );
            } /*else if (error.name == "TypeError" || error.name == "TypeError") {
          //empty constraints object
          setSessionError(
            i18next.t(
              "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia"
            )
          );
        }*/ else {
              //other errors
              console.log("Otro tipo de error nombre:", error.name);
            }
          });
    }
  };

  //Before launching the event we should request the camera and microphone access in order to start the session with the influencer.
  const launchCameraPermission = (requestVideo) => {
    return navigator.mediaDevices.getUserMedia({
      audio: true,
      video: requestVideo,
    });
  };
  const enumerateDevices = (stream, setDefault) => {
    try {
      console.log(
          "launchCameraPermission result",
          stream,
          stream.getVideoTracks(),
          stream.getAudioTracks(),
          stream.getTracks()
      );
    } catch (error) {}

    navigator.mediaDevices.enumerateDevices =
        navigator.mediaDevices.enumerateDevices ||
        navigator.mediaDevices.getUserMedia ||
        navigator.mediaDevices.mozGetUserMedia ||
        navigator.mediaDevices.msGetUserMedia ||
        navigator.mediaDevices.webkitGetUserMedia;

    navigator.mediaDevices
        .enumerateDevices()
        .then(function(devices) {
          console.log("enumerateDevices", devices);

          var videoInputDevices = devices.filter(
              (device) => device.kind === "videoinput"
          );

          try {
            var hasDeviceIdAndLabel = videoInputDevices.filter(
                (device) => device.label && device.deviceId
            );

            if (hasDeviceIdAndLabel.length <= 0) {
              getMediaDevices(true, true);

              return null;
            }

            console.log("enumerateDevices videoDevices: ", hasDeviceIdAndLabel);
          } catch (error) {
            console.error("error checking videoDevices enumerateDevices", error);
          }

          setSelectStreamingDevices({
            ...selectStreamingDevices,
            availableDevicesInitialized: true,
          });

          var audioInputDevices = devices.filter(
              (device) => device.kind === "audioinput"
          );
          var audioOutputDevices = devices.filter(
              (device) => device.kind === "audiooutput"
          );
          var localAudioInputAvailableDevices = [];
          var localAudioOutputAvailableDevices = [];
          var localAvailableDevices = [];

          console.log(
              "launchCameraPermission result",
              stream,
              videoInputDevices,
              audioInputDevices
          );

          audioInputDevices.forEach(function(device) {
            var audioInputDevice = device;

            if (
                true ||
                (device.deviceId !== "default" &&
                    device.deviceId !== "communications")
            ) {
              localAudioInputAvailableDevices.push(audioInputDevice);
            }
          });

          if (setDefault && localAudioInputAvailableDevices.length > 0) {
            setCurrentAudioDevice(localAudioInputAvailableDevices[0]);
          }

          audioOutputDevices.forEach(function(device) {
            var audioOutputDevice = device;

            if (
                true ||
                (device.deviceId !== "default" &&
                    device.deviceId !== "communications")
            ) {
              localAudioOutputAvailableDevices.push(audioOutputDevice);
            }
          });

          if (setDefault && localAudioOutputAvailableDevices.length > 0) {
            setCurrentOutputAudioDevice(localAudioOutputAvailableDevices[0]);
          }

          if (videoInputDevices.length > 0) {
            var hasVirtualDevice = videoInputDevices.find((videoDevice) =>
                videoDevice.label.toLocaleLowerCase().includes("virtual")
            );
            var device = videoInputDevices[0];

            console.log("setDefault videocamera", hasVirtualDevice);

            if (setDefault) {
              if (hasVirtualDevice) {
                setCurrentVideoDevice(hasVirtualDevice);
              } else {
                setCurrentVideoDevice(device);
              }
            }
          }

          localAvailableDevices = localAudioInputAvailableDevices.concat(
              localAudioOutputAvailableDevices
          );

          setAvailableDevices(localAvailableDevices.concat(videoInputDevices));
          stream.stop();
        })
        .catch((error) => {
          console.error("getMediaDevices enumerateDevices", error);
        });
  };

  const sendServerLog = (message, exception = null) => {
    const env = JSON.parse(localStorage.getItem("env"));

    if (!env || !env.token) {
      return null;
    }

    let canParseError = false;
    let stringifyResult = null;
    try {
      stringifyResult = JSON.stringify(exception);

      if (stringifyResult) {
        canParseError = true;
      }
    } catch {}

    let logData;

    if (exception && canParseError) {
      logData = {
        message: message,
        data: {
          userID: user.user_ide,
          userName: user.user_username,
          //stateData: this.state,
          streamingReducerData: {
            eventData,
            //session,
            isRemoteApp,
            chats,
            activeUsers,
            viewersConnected,
            message,
            microphone,
            webcam,
            startingStreaming,
            enableRiseHand,
            enabledOpenMic,
            showChooseRaiseHand,
            viewersWaiting,
            sendingMessage,
            mainStreaming,
            myPublisher,
            userNameRaiseHand,
            mySpeaking_viewer,
            resourcesLoaded,
            subscribers,
            shareScreenSource,
            isSharingScreen,
            enabledChat,
            sessionError,
            sessionConnected,
            sessionLoading,
            sessionReset,
            isCreator,
            hasTurn,
            viewerPublisher,
            risedHand,
            microphoneViewer,
            webcamViewer,
            streamingFinished,
            renderRedirect,
            showDisconnectDialog,
          },
          subscriberList: subscribers,
          //eventReducerData: this.props.dataReducer,
          error: stringifyResult,
          errorException: exception,
        },
      };
      //let response = await eventDAO.sendServerLog(logData, uuid, LogTypes.STREAMING_LOG, env.token);
      eventDAO.sendServerLog(logData, uuid, LogTypes.STREAMING_LOG, env.token);
    } else {
      logData = {
        message: message,
        data: {
          userID: user.user_ide,
          userName: user.user_username,
          streamingReducerData: {
            eventData,
            //session,
            isRemoteApp,
            chats,
            activeUsers,
            viewersConnected,
            message,
            microphone,
            webcam,
            startingStreaming,
            enableRiseHand,
            enabledOpenMic,
            showChooseRaiseHand,
            viewersWaiting,
            sendingMessage,
            mainStreaming,
            myPublisher,
            userNameRaiseHand,
            mySpeaking_viewer,
            resourcesLoaded,
            subscribers,
            shareScreenSource,
            isSharingScreen,
            enabledChat,
            sessionError,
            sessionConnected,
            sessionLoading,
            sessionReset,
            isCreator,
            hasTurn,
            viewerPublisher,
            risedHand,
            microphoneViewer,
            webcamViewer,
            streamingFinished,
            renderRedirect,
            showDisconnectDialog,
          },
          subscriberList: subscribers,
        },
      };

      //let response = await eventDAO.sendServerLog(logData, uuid, LogTypes.STREAMING_LOG, env.token);

      eventDAO.sendServerLog(logData, uuid, LogTypes.STREAMING_LOG, env.token);
    }
  };


  const getMediaDevicesAsync = async (setDefault = false) => {
    if (session) {
      try {
        const devices = await enumerateDevicesAsync(null, setDefault);

        return devices;
      } catch (error) {
        console.error("getMediaDevicesAsync error:", error);
        return null;
      }
    } else {
      try {
        const stream = await launchCameraPermission();

        const devices = await enumerateDevicesAsync(stream, setDefault);

        return devices;
      } catch (error) {
        console.error("LaunchCameraPermission error:", error);
        sendServerLog(
          "LaunchCameraPermission. Function <getMediaDevicesAsync()>. Line 1149.",
          error
        );
        if (
          error.name === "NotFoundError" ||
          error.name === "DevicesNotFoundError"
        ) {
          //required track is missing
          setSessionError(
            i18next.t(
              "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia"
            )
          );
        } else if (
          error.name === "NotReadableError" ||
          error.name === "TrackStartError"
        ) {
          //webcam or mic are already in use
          /*setSessionError(
          i18next.t(
            "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia"
          )
        );*/
          console.log(
            "NotReadableError",
            error.name,
            error,
            error.description,
            error.number,
            error.message
          );
        } else if (
          error.name === "OverconstrainedError" ||
          error.name === "ConstraintNotSatisfiedError"
        ) {
          //constraints can not be satisfied by avb. devices
          setSessionError(
            i18next.t(
              "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia"
            )
          );
        } else if (
          error.name === "NotAllowedError" ||
          error.name === "PermissionDeniedError"
        ) {
          //permission denied in browser
          setSessionError(
            i18next.t(
              "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia"
            )
          );
        } else if (error.name === "TypeError" || error.name === "TypeError") {
          //empty constraints object
          setSessionError(
            i18next.t(
              "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia"
            )
          );
        } /*else if (error.name==="TypeError" || error.name==="TypeError") {
        //empty constraints object
        setSessionError(
          i18next.t(
            "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia"
          )
        );
      }*/ else {
          //other errors
          console.log("Otro tipo de error nombre:", error.name);
        }

        return null;
      }
    }
  };

  useEffect(() => {
    const env = JSON.parse(localStorage.getItem("env"));
    if (!env) {
      disableStartStreaming("You should be logged in to attend this event.");
      setRenderRedirect(true);
    }

    if (eventData && eventData.influencer && user && user.user_ide) {
      setIsInfluencer(
        eventData.influencer.influencer_user_ide === user.user_ide
      );
      if (eventData.influencer.influencer_user_ide === user.user_ide) {
        setInfluencerConnected(true);
      }

      if (shouldDisableStreaming(eventData)) {
        if (/*isMobile*/ false) {
          disableStartStreaming(
            "Please download the app in order to attend this event on your device."
          );
        } else {
          disableStartStreaming("You need a ticket to attend this event.");
        }
        setRenderRedirect(true);
      }
    }
  }, [eventData, user]);

  useEffect(() => {
    if (!location.pathname.includes("streaming")) {
      onLeaveLiveEvent(false);
    }
  }, [location]);

  useEffect(() => {
    console.log(
      "selectVideoAndAudioDevice useeffect",
      currentAudioDevice,
      currentOutputAudioDevice,
      currentVideoDevice
    );
  }, [currentAudioDevice, currentOutputAudioDevice, currentVideoDevice]);

  const onSendFeedback = async () => {
    if (feedBackScore === null) {
      setSessionError(
        i18next.t("PreviewStreamingInfluencerComponentRateEvent")
      );
    } else {
      const data = {
        event_uuid: uuid,
        message: feedBackText,
        review_score: feedBackScore,
      };

      const env = JSON.parse(localStorage.getItem("env"));
      try {
        const response = await eventDAO.reviewEvent(data, env.token);

        if (response.data.success) {
          setRenderRedirect(true);
        } else {
          try {
            setSessionError(response.data.error[0], message);
          } catch (error) {
            sendServerLog(
              "Error getUsernameFromSubscriber. Function <onSendFeedback()>. Line 235.",
              error
            );
            setSessionError(
              i18next.t(
                "PreviewStreamingInfluencerComponentThereWasAnErrorSendingReview"
              )
            );
          }
        }
      } catch (err) {
        sendServerLog(
          "Error sending review onSendFeedback. Function <onSendFeedback()>. Line 242.",
          err
        );
      } finally {
      }
    }
  };

  const checkStreamingRequirements = () => {
    DetectRTC.load();

    if (DetectRTC.isWebRTCSupported === false) {
      setSessionError(
        i18next.t(
          "PreviewStreamingInfluencerComponentVideoStreamingNotSupportedBrowser"
        )
      );

      return false;
    } else if (
      DetectRTC.hasWebcam === false ||
      DetectRTC.hasMicrophone === false
    ) {
      setSessionError(
        i18next.t("PreviewStreamingInfluencerComponentMediaDevicesNotDetected")
      );
      /*hasVideoTracks.current =  DetectRTC.hasWebcam;
      hasAudioTracks.current = DetectRTC.hasMicrophone;*/
      return false;
    } else {
      /*hasVideoTracks.current = true;
      hasAudioTracks.current = true;*/
      return true;
    }
  };

  /**
   * @constructor
   * - Initializes the OpenVidu session on the server.
   * - Initialize the video / audio publication.
   * @param {boolean} skipProtection - Default false. If true will skip checkup and reset the state of the streaming.
   */
  const startStreaming = async (
    skipProtection = false,
    launchTrigger = true
  ) => {
    setSessionReset(false);
    setSessionLoading(true);

    let result = false;

    if (checkStreamingRequirements() || eventData.influencer.influencer_user_ide !== user.user_ide ) {
      if (startingStreaming && !skipProtection) return;
      setStartingStreaming(true);
      try {
        triggerConnect(
          eventData.influencer.influencer_user_ide === user.user_ide
        );

        result = true;
      } catch (err) {
        sendServerLog(
          "Error initializing startStreaming. Function <startStreaming()>. Line 303.",
          err
        );
        setSessionError(err);
        setSessionLoading(false);
        setStartingStreaming(false);
      }
    }

    return result;
  };

  // Get Data event
  const initEventData =() => {
    eventDAO
      .findById(uuid)
      .then((response) => {
        const {
          data: { data },
        } = response;
        onSetOptionsModerators(data.attendees_users);
        setEventData(data);

        setIsCreator(data.influencer.influencer_user_ide === user.user_ide);
        setResourcesLoaded(true);
      })
      .catch((error) => {});
  };

  const shouldDisableStreaming =
    (data) => {
      if (
        data &&
        (data.event.is_purchased ||
          (data.influencer &&
            user &&
            data.influencer.influencer_user_ide === user.user_ide))
      ) {
        return false;
      } else {
        return true;
      }
    };

  const disableStartStreaming = (message) => {
    setSessionError(message);
    setDisabledStartStreaming(true);
  };

  const fetchStreamingState = async () => {
    const env = JSON.parse(localStorage.getItem("env"));
    return await eventDAO.getCurrentStateStreaming(uuid, env.token);
  };

  const setStreamingStateInfluencer = (response) => {
    const { attendees_users } = eventData;
    const {
      data: {
        data: {
          current_state: {
            messages,
            raised_hands,
            raised_hand_enabled,
            speaking_viewer,
            viewers,
            open_mic,
            chat_enabled,
          },
        },
      },
    } = response;
    const updatedChats = [];

    setAttendees(viewers);

    messages.forEach((message) => {
      const {
        user: { user_avatar, user_username },
        message_ide,
      } = message;

      updatedChats.push({
        avatar: user_avatar,
        username: user_username,
        message: message.message,
        uuid: message_ide,
      });
    });

    const updatedViewersWaiting = mapRaisedHandViewers(raised_hands, viewers);

    setEventAttendees(attendees_users);

    viewersGlobal = viewers;

    let remoteStreamingUserLocal = actualRemoteSpeakingUser;

    let remoteStreamingUserUsername = null;
    let newSpeakingUserUsername = null;

    try {
      remoteStreamingUserUsername = remoteStreamingUserLocal.username;
    } catch (error) {
      //console.error("There is no current speaking_viewer", error);
    }

    try {
      newSpeakingUserUsername = speaking_viewer.username;
    } catch (error) {
      //console.error("There is no new speaking_viewer", error);
    }

    if (remoteStreamingUserUsername !== newSpeakingUserUsername) {
      setSpeaking_viewer(speaking_viewer);
      setRefreshTokenDelay(5000, false);
      actualRemoteSpeakingUser = speaking_viewer;
    }

    setChats(updatedChats);
    setViewersWaiting(updatedViewersWaiting);
    setEnableRiseHand(raised_hand_enabled);

    setEnabledChat(chat_enabled);

    setEnabledOpenMic(open_mic);

    setUpdatingSession(false);
  };

  const setStreamingStateViewer = (response) => {
    const { attendees_users } = eventData;
    const {
      data: {
        data: {
          current_state: {
            messages,
            raised_hands,
            raised_hand_enabled,
            speaking_viewer,
            viewers,
            open_mic,
            chat_enabled,
          },
        },
      },
    } = response;
    const updatedChats = [];

    messages.forEach((message) => {
      const {
        user: { user_avatar, user_username },
        message_ide,
      } = message;

      updatedChats.push({
        avatar: user_avatar,
        username: user_username,
        message: message.message,
        uuid: message_ide,
      });
    });

    const updatedViewersWaiting = mapRaisedHandViewers(raised_hands, viewers);

    const hasHandUp = updatedViewersWaiting.find(
      (viewer) => viewer.username === user.user_username
    );

    setEnableRiseHand(raised_hand_enabled && !hasHandUp);

    setEventAttendees(attendees_users);

    viewersGlobal = viewers;

    let remoteStreamingUserLocal = actualRemoteSpeakingUser;

    let remoteStreamingUserUsername = null;
    let newSpeakingUserUsername = null;

    try {
      remoteStreamingUserUsername = remoteStreamingUserLocal.username;
    } catch (error) {
      console.error("There is no current speaking_viewer", error);
    }

    try {
      newSpeakingUserUsername = speaking_viewer.username;
    } catch (error) {
      console.error("There is no new speaking_viewer", error);
    }

    if (remoteStreamingUserUsername !== newSpeakingUserUsername) {
      setSpeaking_viewer(speaking_viewer);
      setRefreshTokenDelay(5000, false);
      actualRemoteSpeakingUser = speaking_viewer;
    }

    setChats(updatedChats);
    setViewersWaiting(updatedViewersWaiting);

    setEnabledChat(chat_enabled);

    setEnabledOpenMic(open_mic);

    setUpdatingSession(false);
  };

  const setStreamingState = (response) => {
    if (eventData.influencer.influencer_user_ide === user.user_ide) {
      setStreamingStateInfluencer(response);
    } else {
      setStreamingStateViewer(response);
    }
  };

  const mapRaisedHandViewers = (raised_hands, attendees_users) => {
    let resolution = [];

    raised_hands.forEach(function(key, index) {
      resolution.push(findViewerFromAttendees(attendees_users, key.username));
    });

    return resolution;
  };

  const mapConnectedViewersFromSubscribers = (subscribers, attendees_users) => {
    let resolution = [];

    subscribers.forEach(function(key, index) {
      resolution.push(findViewer(attendees_users, key.identity));
    });

    return resolution;
  };


  const updateActiveUsers = (subscribersList) => {
    let activeUsersCount = 0;

    for (let i = 0; i < subscribersList.length; i++) {
      let subscriber = subscribersList[i];

      let subscriberUsername = subscriber.identity; //getUsernameFromSubscriber(subscriber);
      let shareScreenStructure = subscriberUsername.split("-");

      if (
        subscriberUsername &&
        (shareScreenStructure.length > 1 &&
          shareScreenStructure[shareScreenStructure.length - 1] ===
            "ScreenShare")
      ) {
        //nothing to do
      } else {
        //influencerMode
        if (true) {
          activeUsersCount = activeUsersCount + 1;
        }
        //viewerMode
        else {
        }
      }
    }

    setActiveUsers(activeUsersCount);
  };

  const findViewer = (attendees_users, username) => {
    let userDetail = attendees_users.find(
      (item) => item.user_username === username
    );

    if (userDetail !== undefined && userDetail !== null) {
      if (
        userDetail.user_avatar === undefined ||
        userDetail.user_avatar === null ||
        userDetail.user_avatar === ""
      ) {
        userDetail.user_avatar =
          "https://www.seekpng.com/png/detail/413-4139803_unknown-profile-profile-picture-unknown.png";
      }

      if (
        userDetail.user_username === undefined ||
        userDetail.user_username === null ||
        userDetail.user_username === ""
      ) {
        userDetail.username = "User";
      }
      userDetail = {
        username: userDetail.user_username,
        avatar: userDetail.user_avatar,
        user_username: userDetail.username,
        user_name: userDetail.username,
        user_avatar: userDetail.user_avatar,
      };
    }

    //userDetail = { user_username: userDetail.username, user_name: userDetail.username, user_avatar: userDetail.user_avatar };

    return userDetail;
  };

  const findViewerFromAttendees = (attendees_users, username) => {
    let userDetail = attendees_users.find((item) => item.username === username);

    if (userDetail !== undefined && userDetail !== null) {
      if (
        userDetail.user_avatar === undefined ||
        userDetail.user_avatar === null ||
        userDetail.user_avatar === ""
      ) {
        userDetail.user_avatar =
          "https://www.seekpng.com/png/detail/413-4139803_unknown-profile-profile-picture-unknown.png";
      }

      if (
        userDetail.username === undefined ||
        userDetail.username === null ||
        userDetail.username === ""
      ) {
        userDetail.username = "User";
      }
      userDetail = {
        user_username: userDetail.username,
        user_name: userDetail.username,
        user_avatar: userDetail.user_avatar,
      };
    }

    //userDetail = { user_username: userDetail.username, user_name: userDetail.username, user_avatar: userDetail.user_avatar };

    return userDetail;
  };

  const updateStreamingState = async () => {
    connectUpdateState = true;
    setUpdatingSession(true);
    const response = await fetchStreamingState();

    setStreamingState(response);
  };

  const sendUpdateMessage = async () => {
    updateStreamingState();
  };

  const closeSessionWithServer =async () => {
    try {
      const env = JSON.parse(localStorage.getItem("env"));
      let response = await eventDAO.stopStreaming(uuid, env.token);

      if (IS_DEV_ENVIRONMENT) {
        if (response.data.success) {
          setStreamingOnline(false);
          setViewersWaiting([]);
          setRenderRedirect(true);
        } else {
          setSessionError(response.data.error[0].message);
        }
      }
    } catch (error) {
      sendServerLog(
        "Error finishing the event. Function <closeSessionWithServer()>.",
        error
      );
      setSessionError(
        i18next.t("PreviewStreamingInfluencerComponentProblemFinishingEvent")
      );
    }

    if (!IS_DEV_ENVIRONMENT) {
      setStreamingOnline(false);
      setViewersWaiting([]);
      setRenderRedirect(true);
    }
  };

  /**
   * - Disconnect local streaming.
   * - It does not affect other viewers
   */
  const onLeaveEventStandard = () => {
    if (session) {
      setStreamingFinished(true);
      try {
        onLeaveEvent();
        session.disconnect();
      } catch (error) {
        sendServerLog(
          "Error exiting the event. Function <onLeaveEventStandard()>.",
          error
        );
        setSessionError(
          i18next.t(
            "PreviewStreamingInfluencerComponentProblemFinishingEventLeaveStandard"
          )
        );
      }
    } else {
      setStreamingFinished(true);
      try {
        onLeaveEvent();
      } catch (error) {
        sendServerLog(
          "Error exiting the event without session. Function <onLeaveEventStandard()> Line 689.",
          error
        );
        setSessionError(
          i18next.t(
            "PreviewStreamingInfluencerComponentProblemFinishingEventLeaveStandard"
          )
        );
      }
    }
  };

  /**
   * - Close streaming on the server.
   * - Send the signal to the other viewers that the event ends
   */
  const onLeaveLiveEvent =
    (sendClosingSessionWithServer = true) => {
      try {
        socket.disconnect();
      } catch (error) {
        console.error(error);
      }

      if (sendClosingSessionWithServer) {
        if (session) {
          try {
            closeSessionWithServer();
          } catch (err) {
            sendServerLog(
              "Error sending streaming finished signal. Function <onLeaveLiveEvent()>. Line 718",
              err
            );
          }
        } else {
          closeSessionWithServer();
        }
      } else {
        try {
          session.disconnect();
        } catch (error) {}
        setRenderRedirect(true);
      }
    };

  /**
   * - Close streaming on the server.
   * - Navigate to user profile
   */
  const disconnectEvent = () => {
    try {
      socket.disconnect();
    } catch (error) {
      console.error(error);
    }

    if (session) {
      try {
        session.disconnect();

        setRenderRedirect(true);
      } catch (err) {
        sendServerLog(
          "Error disconnecting streaming. Function <disconnectEvent()>. Line 1001",
          err
        );
      }
    } else {
      setRenderRedirect(true);
    }
  };

  // Send message on the chat
  const onSendMessage = async () => {
    let adaptedMessage = trimNewlines(message.trim());

    setSendingMessage(true);
    const env = JSON.parse(localStorage.getItem("env"));
    await eventDAO.sendEventMessage(uuid, adaptedMessage, env.token);
    setMessage("");
    setSendingMessage(false);
    sendUpdateMessage();
  };

  // Delete message on the chat
  const onDeleteMessage = (uuid) => {
    session
      .signal({
        data: JSON.stringify({ uuid }),
        type: typeEventsStreaming.MESSAGE_REMOVED,
      })
      .then(() => {
        const updatedChats = [...chats];
        const indexMessage = updatedChats.findIndex(
          (message) => message.uuid === uuid
        );
        updatedChats.splice(indexMessage, 1);
        setChats(updatedChats);
      });
  };

  // Switch microphone
  const onSetMicrophone = () => {
    try {
      session.localParticipant.audioTracks.forEach((track) => {
        track.track.enable(!microphone);
      });
      setMicrophone(!microphone);
    } catch (error) {
      console.error("Error onSetMicrophone:", error);
    }
  };

  // Switch webcam
  const onSetWebcam = () => {
    try {
      session.localParticipant.videoTracks.forEach((track) => {
        track.track.enable(!webcam);
      });
      setWebCam(!webcam);
    } catch (error) {
      console.error("Error onSetWebcam:", error);
    }
  };

  // Switch microphone viewer
  const onSetMicrophoneViewer = (mode) => {
    try {
      if (viewerPublisher) {
        viewerPublisher.publishAudio(mode);
      }
      setMicrophoneViewer(mode);
    } catch (error) {
      console.error("Error onSetMicrophoneViewer:", error);
    }
  };

  // Switch webcam viewer
  const onSetWebcamViewer = (mode) => {
    try {
      if (viewerPublisher) {
        viewerPublisher.publishVideo(mode);
      }
      setWebCamViewer(mode);
    } catch (error) {
      console.error("Error onSetWebcamViewer:", error);
    }
  };

  /**
   * Block the chat for a certain time to a certain user.
   * @param {String} username, username to block
   */
  // TODO: waiting for backend
  const onBlockedChat = (username) => {
    session
      .signal({
        type: typeEventsStreaming.CHAT_PERMISION_REVOKED_FOR,
        data: JSON.stringify({ username, penaltyStartTimestamp: 0 }),
      })
      .then(() => {});
  };

  // Switch rise hand
  const onSetEnableRiseHand = async () => {
    //if (enableRiseHand && userNameRaiseHand) return;
    if (session) {
      setEnableRiseHand(!enableRiseHand);
      setShowChooseRaiseHand(showChooseRaiseHand);

      if (!enableRiseHand) {
        const env = JSON.parse(localStorage.getItem("env"));
        await eventDAO.toggleRiseHand(uuid, true, env.token);
        //await updateStreamingState();
      } else {
        const env = JSON.parse(localStorage.getItem("env"));
        await eventDAO.toggleRiseHand(uuid, false, env.token);
        //await updateStreamingState();
      }
      sendUpdateMessage();
    }
  };

  const onSetRiseHand = async () => {
    //if (enableRiseHand && userNameRaiseHand) return;
    if (session) {
      console.log("onSetRiseHand", hasVideoTracks.current, hasAudioTracks.current)
      if(hasVideoTracks.current && hasAudioTracks.current){
        setRiseHand(!risedHand);

        if (!risedHand) {
          const env = JSON.parse(localStorage.getItem("env"));
          await eventDAO.raiseHand(uuid, true, env.token);
        } else {
          const env = JSON.parse(localStorage.getItem("env"));
          await eventDAO.raiseHand(uuid, false, env.token);
        }
        sendUpdateMessage();
      }else{
        setSessionError(
            i18next.t("PreviewStreamingInfluencerComponentProblemConnectingAccesingToMediaDevices")
        );
      }

    }
  };

  // Switch enable / disable chat
  const onSetEnableChat = async () => {
    //if (enableRiseHand && userNameRaiseHand) return;
    if (session) {
      setEnabledChat(!enabledChat);

      if (!enabledChat) {
        const env = JSON.parse(localStorage.getItem("env"));
        await eventDAO.toggleEnableChat(uuid, true, env.token);
        //await updateStreamingState();
      } else {
        const env = JSON.parse(localStorage.getItem("env"));
        await eventDAO.toggleEnableChat(uuid, false, env.token);
        //await updateStreamingState();
      }
      sendUpdateMessage();
    }
  };

  // Switch open mic mode
  const onSetEnableOpenMic = async () => {
    if (session) {
      setEnabledOpenMic(!enabledOpenMic);

      if (!enabledOpenMic) {
        const env = JSON.parse(localStorage.getItem("env"));
        await eventDAO.toggleOpenMic(uuid, true, env.token);
        //await updateStreamingState();
      } else {
        const env = JSON.parse(localStorage.getItem("env"));
        await eventDAO.toggleOpenMic(uuid, false, env.token);
        //await updateStreamingState();
      }
      sendUpdateMessage();
    }
  };

  // Stop remoteCall
  const onStopRemoteCall = async () => {
    //if (enableRiseHand && userNameRaiseHand) return;
    if (session && mySpeaking_viewer) {
      //
      const env = JSON.parse(localStorage.getItem("env"));
      await eventDAO.allowMic(
        { event_uuid: uuid, username: mySpeaking_viewer.username },
        env.token
      );

      setUserNameRaiseHnad(null);
      //updateStreamingState();
      sendUpdateMessage();
    }
  };

  useEffect(() => {
    if (
      document.getElementById("messageContainer") !== null &&
      document.getElementById("messageContainer") !== undefined
    ) {
      document
        .getElementById("messageContainer")
        .scrollTo(0, document.querySelector("#messageContainer").scrollHeight);
    }

    if (
      document.getElementById("messageContainerViewer") !== null &&
      document.getElementById("messageContainerViewer") !== undefined
    ) {
      document
        .getElementById("messageContainerViewer")
        .scrollTo(
          0,
          document.querySelector("#messageContainerViewer").scrollHeight
        );
    }

    if (isMobile) {
      if (
        document.getElementById("mobileChat") !== null &&
        document.getElementById("mobileChat") !== undefined
      ) {
        document
          .getElementById("mobileChat")
          .scrollTo(0, document.querySelector("#mobileChat").scrollHeight);
      }
    }
  }, [chats]);

  const parseUserDataForSocketIOSignal = () => {
    let responseData = null;

    if (isInfluencer) {
      responseData = {
        influencerData: {
          username: user.user_username,
          userId: user.user_ide,
        },
        uuid: uuid,
      };
    } else {
      responseData = {
        viewerData: {
          username: user.user_username,
          userId: user.user_ide,
        },
        uuid: uuid,
      };
    }

    return responseData;
  };

  const resentEventSignal = (localSocket) => {
    localSocket.emit("event", parseUserDataForSocketIOSignal());

    clearTimeout(eventOkTimer);
    eventOkTimer = setTimeout(() => {
      resentEventSignal(localSocket);
    }, SOCKETIO_CONFIRMATION_SIGNAL_TIMEOUT);
  };

  const connectSocketIO = () => {
    try {
      //const localSocket = IS_DEV_ENVIRONMENT ? io.connect("https://dev.gotmy.com:4001", { secure: false, reconnection: true, rejectUnauthorized: false }) : io.connect(ENDPOINT);//io.connect('http://3.83.52.62:4001');//io.connect(ENDPOINT, { secure: false, reconnection: true, rejectUnauthorized: false });
      const localSocket = io.connect(ENDPOINT); //io.connect('http://3.83.52.62:4001');//io.connect(ENDPOINT, { secure: false, reconnection: true, rejectUnauthorized: false });
      localSocket.on("connect", (data) => {
        localSocket.emit("connectOk", parseUserDataForSocketIOSignal());
        localSocket.emit("event", parseUserDataForSocketIOSignal());

        console.log("localSocket connectOk", ENDPOINT);

        eventOkTimer = setTimeout(() => {
          resentEventSignal(localSocket);
        }, SOCKETIO_CONFIRMATION_SIGNAL_TIMEOUT);

        setSocket(localSocket);
      });

      localSocket.on("eventOk", (data) => {
        clearTimeout(eventOkTimer);
      });

      localSocket.on("updateEvent", (data) => {
        console.log("localSocket updateEvent");
        updateStreamingState();

        localSocket.emit("updateEventOk", parseUserDataForSocketIOSignal());
      });

      localSocket.on("newMessage", (data) => {
        console.log("localSocket newMessage");
        updateStreamingState();
        localSocket.emit("newMessageOk", parseUserDataForSocketIOSignal());
        console.log("EVENTO: newMessage", data);
      });

      localSocket.on("finishEvent", (data) => {
        setStreamingFinished(true);
        try {
          onLeaveEvent();
          session.disconnect();
        } catch (error) {
          sendServerLog(
            "Error disconnecting from event finished signal (viewer). Function <session.on(STREAMING_FINISHED)>.",
            error
          );
        }

        localSocket.emit("finishEventOk", parseUserDataForSocketIOSignal());
      });

      localSocket.on("connect_error", (data) => {});

      localSocket.on("connect_timeout", (data) => {});

      localSocket.on("reconnect", (data) => {});

      localSocket.on("reconnect_attempt", (data) => {});

      localSocket.on("reconnecting", (data) => {});

      localSocket.on("reconnect_error", (data) => {});

      localSocket.on("reconnect_failed", (data) => {});
    } catch (error) {
      console.error("USEEFFECT SOCKETIO", error);
    }
  };

  const publishLocalTrack = (track, username) => {
    try {
      let localParticipant = session.localParticipant;

      //let publishedResult = localParticipant.publishTrack(track);

      let trackPriority = "high";
      if (!isInfluencer) {
        trackPriority = "low";
      }

      localParticipant
        .publishTrack(track, {
          priority: trackPriority,
        })
        .then((publication) => {
          if (publication) {
            if (publication.kind === "video") {
              /*
              try {
                let priority = 'high';
                if (!isInfluencer) {
                  priority = 'low';
                }

                session.localParticipant.tracks.forEach(track => {

                  track.setPriority(priority)
                });
              } catch (error) {
                console.error(error);
              }*/

              publishingVideoLock = false;
              publishedVideo = true;

              setHasTurn(true);
              setRiseHand(false);

              setMicrophoneViewer(true);

              setMyspeaking_viewer(speaking_viewer);
              remoteTrackPublished(
                { track: publication.track, participant: localParticipant },
                localParticipant,
                true
              );

              setMainStreaming(localParticipant);
              setRemoteViewCSS(username);
            } else if (publication.kind === "audio") {
              publishingAudioLock = false;
              publishedAudio = true;
            }
          } else {
            console.error("Error publishing track");

            publishingVideoLock = false;
            publishedVideo = false;

            publishingAudioLock = false;
            publishedAudio = false;

            setHasTurn(false);

            setMyspeaking_viewer(null);

            setMainStreaming(null);
          }
        })
        .catch((error) => {
          console.error("Error publishing track", error);
          publishingVideoLock = false;
          publishedVideo = false;

          publishingAudioLock = false;
          publishedAudio = false;

          setHasTurn(false);

          setMyspeaking_viewer(null);

          setMainStreaming(null);
        });
    } catch (error) {
      //toggleStreamingOptionsModal(false);
    }
  };

  const removeLocalScreenShareTrack = () => {
    try {
      let localParticipant = session.localParticipant;
      const tracks = Array.from(localParticipant.videoTracks.values());

      let trackToRemove = null;

      for (let i = 0; i < tracks.length; i++) {
        let itemTrack = tracks[i];

        let itemTrackName = "";

        try {
          itemTrackName = itemTrack.trackName;
        } catch (error) {
          itemTrackName = "";
        }

        if (itemTrackName.includes("-SCREENSHARE-")) {
          trackToRemove = itemTrack;
        }
      }

      if (trackToRemove) {
        localParticipant.unpublishTrack(trackToRemove.track);

        try {
          trackToRemove.track.stop();
        } catch (error) {
          console.error("error stopping share screen track", error);
        }

        setScreenShareTracks(null);

        removeAndDetachTrack(trackToRemove, localParticipant);
      }

      /*
            if (tracks.length > 0) {



              let result = localParticipant.unpublishTrack(tracks[0].track)




              tracks.forEach(track => {
                removeAndDetachTrack(track, localParticipant);
              })

              publishedVideo = false;
              publishingVideoLock = false;


            }*/
    } catch (error) {
      console.error(error);
    }
  };

  const removeLocalVideoTrack =() => {
    try {
      let localParticipant = session.localParticipant;
      const tracks = Array.from(localParticipant.videoTracks.values());

      if (tracks.length > 0) {
       localParticipant.unpublishTrack(tracks[0].track);

        tracks.forEach((track) => {
          removeAndDetachTrack(track, localParticipant);
        });

        publishedVideo = false;
        publishingVideoLock = false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeLocalAudioTrack =() => {
    try {
      const tracks = getLocalAudioTracks();
      let localParticipant = session.localParticipant;

      //if (tracks.length > 0) {

      console.log("removeLocalAudioTrack", tracks);

      for (let i = 0; i < tracks.length; i++) {
        console.log(
          "removeLocalAudioTrack for track before unpublishing",
          tracks[i]
        );
        try {
          tracks[i].track.stop();
        } catch (error) {
          console.error(
            "removeLocalAudioTrack stopping track before unpublishing",
            tracks[i]
          );
        }
        localParticipant.unpublishTrack(tracks[i].track);
      }

      tracks.forEach((track) => {
        console.log(
          "removeLocalAudioTrack foreach track before unpublishing",
          track
        );
        console.log(
          "removeLocalAudioTrack for track before unpublishing",
          track
        );
        try {
          track.track.stop();
        } catch (error) {
          console.error(
            "removeLocalAudioTrack stopping track before unpublishing",
            track
          );
        }
        localParticipant.unpublishTrack(track.track);

        console.log("removeLocalAudioTrack despues de unpublish track ", track);

        removeAndDetachTrack(track, localParticipant);
      });

      publishedAudio = false;
      publishingAudioLock = false;
      //}
    } catch (error) {
      console.error("removeLocalAudioTrack", error);
    }
  };

  const getLocalAudioTracks = () => {
    try {
      let localParticipant = session.localParticipant;
      console.log(
        "getLocalAudioTracks",
        localParticipant.audioTracks,
        Array.from(localParticipant.audioTracks.values())
      );
      return Array.from(localParticipant.audioTracks.values());
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const enableOpenmode = () => {
    if (enabledOpenMic && session) {
      if (
        session.localParticipant.audioTracks.size === 0 &&
        !publishingAudioLock &&
        !publishedAudio
      ) {
        publishingAudioLock = true;
        publishAudioTrack(currentAudioDevice, (localAudioTrack) => {
          publishLocalTrack(localAudioTrack, null);
        });
      }
    } else if (!enabledOpenMic && !hasTurn && session) {
      if (publishedAudio) {
        removeLocalAudioTrack();
      } else {
        try {
          removeLocalAudioTrack();
        } catch (error) {
          console.error("removeLocalAudioTrack enableOpenmode", error);
        }
      }
    }
  };

  const enableRemoteSpeakerViewerMode = (username) => {
    if (!session) {
      return null;
    }
    let subscriber = null;

    try {
      if (username === user.user_username) {
        subscriber = session.localParticipant;

        if (
          session.localParticipant.videoTracks.size === 0 &&
          !publishingVideoLock &&
          !publishedVideo
        ) {
          publishingVideoLock = true;

          publishVideoTrack(currentVideoDevice, (localVideoTrack) => {
            //enableRemoteViewTrack({ track: localVideoTrack });

            //setIsPublishingVideo(true);

            publishLocalTrack(localVideoTrack, username);
          });
        }

        if (
          session.localParticipant.audioTracks.size === 0 &&
          !publishingAudioLock &&
          !publishedAudio
        ) {
          publishingAudioLock = true;
          publishAudioTrack(currentAudioDevice, (localAudioTrack) => {
            //enableRemoteViewTrack({ track: localAudioTrack });

            //setIsPublishingAudio(true);

            publishLocalTrack(localAudioTrack, username);
          });
        }
      } else {
        //enableRemoteSpeakerViewerModeLock = true;
        setHasTurn(false);

        if (publishedVideo) {
          removeLocalVideoTrack();
        }

        if (!enabledOpenMic) {
          if (publishedAudio) {
            removeLocalAudioTrack();
          }
        }

        if (
          !username ||
          (username && username !== user.user_username && mySpeaking_viewer)
        ) {
          //setRemotePublications([]);
          setMyspeaking_viewer(null);
        } else {
          if (session) {
            let participant = Array.from(session.participants.values()).find(
              (participantItem) => participantItem.identity === username
            );

            if (participant) {
              setMyspeaking_viewer(speaking_viewer);

              setMainStreaming(participant);
              setRemoteViewCSS(username);

              setRefreshTokenDelay();
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }

    return null;
  };

  /**
   * - Notify the other users that a user's turn has been granted.
   * - Shows the video of the user who has been granted the turn.
   * @param {String} username, username permission granted
   */
  const givePermissionsViewerStreaming = async (username) => {
    const env = JSON.parse(localStorage.getItem("env"));
    await eventDAO.allowMic({ event_uuid: uuid, username }, env.token);
    sendUpdateMessage();
  };

  /**
   * Notify other users that the turn has been canceled for a certain user.
   * @param {String} username, User who had the turn.
   */
  const onRiseHandCanceled = (username) => {
    setMainStreaming(null);
    setUserNameRaiseHnad(null);

    sendUpdateMessage();
  };

  const setRemoteViewCSS = (username) => {
    let platform = "";

    if (
      viewersGlobal !== undefined &&
      viewersGlobal !== null &&
      viewersGlobal.length > 0
    ) {
      if (username !== undefined && username !== null) {
        for (let i = 0; i < viewersGlobal.length; i++) {
          if (viewersGlobal[i].username === username) {
            platform = viewersGlobal[i].platform;
          }
        }

        if (platform === "app") {
          setIsRemoteApp(true);
        } else {
          setIsRemoteApp(false);
        }
      }
    }
  };

  //Handle remote cameras
  useEffect(
    () => {
      if (mySpeaking_viewer !== speaking_viewer) {
        if (speaking_viewer) {
          try {
            lock
              .acquire("enableRemoteSpeakerViewerMode", function() {
                // return value or promise

                enableRemoteSpeakerViewerMode(speaking_viewer.username);
              })
              .then(function() {
                // lock released
              });
          } catch (error) {}
        } else {
          try {
            lock
              .acquire("enableRemoteSpeakerViewerMode", function() {
                // return value or promise

                enableRemoteSpeakerViewerMode(null);
              })
              .then(function() {
                // lock released
              });
          } catch (error) {}
        }
      }
    },
    [
      refreshToken,
      speaking_viewer,
      mySpeaking_viewer,
      remotePublications,
      subscribers,
      publications,
      enabledOpenMic,
      enableRemoteSpeakerViewerMode,
    ] //[speaking_viewer, mySpeaking_viewer, remotePublications, subscribers, publications]
  );

  //Handle remote cameras
  useEffect(
    () => {
      if (
        eventData.influencer &&
        eventData.influencer.influencer_user_ide !== user.user_ide
      ) {
        enableOpenmode();
      }
    },
    [
      refreshToken,
      remotePublications,
      subscribers,
      publications,
      enabledOpenMic,
      enableOpenmode,
      eventData.influencer,
      user.user_ide,
    ] //[speaking_viewer, mySpeaking_viewer, remotePublications, subscribers, publications]
  );

  /**
   * - Trigger user streaming connection.
   * @param {boolean} influencerMode - Defines user rol connection logic. If true will execute INFLUENCER connection logic. If false VIEWER connection logic.
   */
  const triggerConnect = (influencerMode) => {

    if (influencerMode) {
      connectStreaming();
    } else {
      connectStreamingViewer();
    }
  };

  const resetAllState = () => {
    setOVObject(null);
    setViewersConnected([]);
    setAudioShareScreen(true);
    setAutoUpdate(false);
    setUpdatingSession(false);
    setMyspeaking_viewer(null);
    setSpeaking_viewer(null);
    setstreamingOn(false);
    setSubscribers([]);
    setLocalSessionParticipants(new Map());
    setMainStreaming(null);
    setUserNameRaiseHnad(null);
    setIsRemoteApp(false);
    setShareScreenSource(null);
    setIsSharingScreen(false);
    setShareScreenSession(null);
    setActiveUsers(0);
    setSessionError(null);
    setViewerPublisher(null);
    setRiseHand(false);
    setStreamingFinished(false);
    isSharingScreenEnabled = false;
  };

  const resetStreamingState = () => {
    setSubscribers([]);
    setLocalSessionParticipants(new Map());
    setPublications([]);
    setRemotePublications([]);
    publishingVideoLock = false;
    publishedVideo = false;
    publishingAudioLock = false;
    publishedAudio = false;
    try {
      socket.disconnect();
    } catch (error) {
      console.error(error);
    }
    setHasTurn(false);
    setSocket(null);
    setAudioTracks([]);
    setVideoAudioTracks([]);
    setActiveUsers(0);
    updateConnectedViewers([]);
    setStartingStreaming(false);
    setstreamingOn(false);
    setStreamingOnline(false);
    setTriggerAutoConnect("MANUAL_CONNECT");
    setMyPublisher(null);
    setMyspeaking_viewer(null);
    setSpeaking_viewer(null);
    setEnabledOpenMic(false);
    setMainStreaming(null);
    setIsRemoteApp(false);
    setShareScreenSource(null);
    setIsSharingScreen(false);
    isSharingScreenEnabled = false;
    setShareScreenSession(null);
    resetAllState();
  };

  const onLeaveEvent = () => {
    try {
      socket.disconnect();
    } catch (error) {
      console.error(error);
    }

    try {
      setStreamingOnline(false);

      if (isInfluencer) {
        setRenderRedirect(true);
      }
    } catch (error) {
      sendServerLog(
        "Error onLeaveEvent. Function <onLeaveEvent()>. Line 1341.",
        error
      );
    }
  };

  const stopShareScreen = async () => {
    removeLocalScreenShareTrack();

    setIsSharingScreen(false);
    isSharingScreenEnabled = false;
  };

  const isScreenShareSupported = () => {
    let result = false;

    if (!DetectRTC) {
      setSessionError(
        i18next.t("PreviewStreamingInfluencerComponentShareScreenNotSupported")
      );
    }

    if (DetectRTC && DetectRTC.browser) {
      if (DetectRTC.browser.isChrome && DetectRTC.browser.version > 71) {
        result = true;
      } else if (DetectRTC.browser.isChrome) {
        setSessionError(
          i18next.t("PreviewStreamingInfluencerComponentChromeOutdated")
        );
      }

      if (DetectRTC.browser.isFirefox && DetectRTC.browser.version > 65) {
        result = true;
      } else if (DetectRTC.browser.isFirefox) {
        setSessionError(
          i18next.t("PreviewStreamingInfluencerComponentFirefoxOutdated")
        );
      }

      if (DetectRTC.browser.isOpera && DetectRTC.browser.version > 71) {
        result = true;
      } else if (DetectRTC.browser.isOpera) {
        setSessionError(
          i18next.t("PreviewStreamingInfluencerComponentOperaOutdated")
        );
      }

      if (DetectRTC.browser.isSafari && DetectRTC.browser.version >= 13) {
        result = true;
      } else if (DetectRTC.browser.isSafari) {
        setSessionError(
          i18next.t("PreviewStreamingInfluencerComponentSafariOutdated")
        );
      }

      if (
        !DetectRTC.browser.isChrome &&
        !DetectRTC.browser.isFirefox &&
        !DetectRTC.browser.isOpera &&
        !DetectRTC.browser.isSafari
      ) {
        setSessionError(
          i18next.t(
            "PreviewStreamingInfluencerComponentShareScreenNotSupportedUseOther"
          )
        );
      }

      //Other checking
      /*
      if(DetectRTC.browser.isFirefox && DetectRTC.browser.version > 65){
        result = true;
      }else if(DetectRTC.browser.isFirefox){
        setSessionError("Your Firefox is outdated (" +  DetectRTC.browser.version +").\nUpdate Firefox (minimum version 65) to start sharing your screen.");
      }
      */
    }

    return result;
  };

  const shareScreen = async () => {
    if (isScreenShareSupported() && session) {
      navigator.mediaDevices
        .getDisplayMedia({
          video: {
            cursor: "never",
            width: { ideal: 1920, max: 1920 },
            height: { ideal: 1080, max: 1080 },
            frameRate: 30,
          },
          audio: true,
        })
        .then((stream) => {
          let screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0], {
            name:
              session.localParticipant.identity + "-SCREENSHARE-" + Date.now(),
          });
          session.localParticipant
            .publishTrack(screenTrack)
            .then(() => {
              setIsSharingScreen(true);

              setScreenShareTracks(screenTrack);
              isSharingScreenEnabled = true;
            })
            .catch((error) => {
              stopShareScreen();
            });

          screenTrack.mediaStreamTrack.onended = () => {
            stopShareScreen();
          };
        })
        .catch((error) => {
          console.error("Sharescreen error:", error);
        });
    }
  };

  const disableReconnectLock = (location) => {
    setShowConnectionRetrying(false);
  };

  const initNewPublisher = (publisherProperties, callback = null) => {
    if (!OVObject) {
      throw "OVObject undefined";
    }

    const publisher = OVObject.initPublisher(
      "",
      publisherProperties,
      (error) => {
        // Function to be executed when the method finishes
        if (error) {
          session.disconnect();
          sendServerLog(
            "Error while initializing new publisher initNewPublisher. Function <initNewPublisher()>.",
            error
          );
          setSessionError(
            i18next.t(
              "PreviewStreamingInfluencerComponentGotmyHasNoAccessToMediaEnsure"
            )
          );
          console.error("Error while initializing publisher: ", error);
        } else {
          if (eventData.influencer.influencer_user_ide === user.user_ide) {
            setMyPublisher(publisher);
          } else {
            setViewerPublisher(publisher);
          }

          publisher.on("streamDestroyed", (event) => {
            event.preventDefault();

            triggerNewPublisher();
          });

          session.publish(publisher);

          if (callback) {
            callback();
          }
        }
      }
    );
  };

  const triggerNewPublisher = () => {
    //if(selectStreamingDevices.shouldInitNewPublisher){
    if (shouldInitNewPublisher) {
      setTimeout(() => {
        initNewPublisherFromStreamingOptionsChanged(
          temporalNewAudioDevice,
          temporalNewAudioDeviceId,
          temporalNewVideoDevice,
          temporalNewVideoDeviceId
        );
      }, 8000);
    }
  };

  const subscribeParticipantTracks = (participant) => {
    let updatedSubscribers = subscribers;

    updatedSubscribers.push(participant);

    // Update the state with the new subscribers
    setSubscribers(updatedSubscribers);

    updateConnectedViewers(updatedSubscribers);

    /*
    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        let updatedSubscribers = subscribers;

        updatedSubscribers.push(participant);

        // Update the state with the new subscribers
        setSubscribers(updatedSubscribers);

        updateConnectedViewers(updatedSubscribers);
      }
    });*/

    return participant;
  };

  const unSubscribeParticipantTracks = (participant) => {
    let updatedSubscribers = [];

    subscribers.forEach((localParticipant) => {
      if (participant.identity !== localParticipant.identity) {
        updatedSubscribers.push(localParticipant);
      }
    });

    // Update the state with the new subscribers
    setSubscribers(updatedSubscribers);

    updateConnectedViewers(updatedSubscribers);

    return participant;
  };

  const updateAudioTracks = (track, participant) => {
    let updatedAudioTracks = audioTracks;

    if (track.kind === "audio") {
      updatedAudioTracks.push({ track: track, participant: participant });

      setAudioTracks(updatedAudioTracks);
    }
  };

  const addInfluencerTrack = (track, participant) => {
    if (participant.identity === eventData.influencer.user.user_username) {
      setMyPublisher(participant);
    }
  };

  const updateVideoAudioDataTracks = (track, participant) => {
    addInfluencerTrack(track, participant);

    let updatedVideoAudioTracks = videoAudioTracks;
    updatedVideoAudioTracks.push({ track: track, participant: participant });

    setVideoAudioTracks(updatedVideoAudioTracks);
  };

  const deleteAudioTracks = (track, participant) => {
    try {
      let updatedAudioTracks = audioTracks;

      if (track && track.kind === "audio") {
        updatedAudioTracks.push({ track: track, participant: participant });

        setAudioTracks(updatedAudioTracks);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteVideoAudioDataTracks = (track, participant) => {
    try {
      let updatedVideoAudioTracks = videoAudioTracks;
      updatedVideoAudioTracks.push({ track: track, participant: participant });

      setVideoAudioTracks(updatedVideoAudioTracks);
    } catch (error) {
      console.error(error);
    }
  };

  const updateParticipantTracks = (track, participant) => {
    updateAudioTracks(track, participant);
    updateVideoAudioDataTracks(track, participant);
  };

  const deleteParticipantTracks = (track, participant) => {
    deleteAudioTracks(track, participant);
    deleteVideoAudioDataTracks(track, participant);
    unSubscribeParticipantTracks(participant);
  };

  const participantConnected = (participant) => {
    composeNewConnectionToastMessage(participant);
    subscribeParticipantTracks(participant);
    //initializeParticipantListeners(participant);

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        const track = publication.track;

        updateParticipantTracks(track, participant);
      }
    });

    participant.on("trackSubscribed", (track) => {
      updateParticipantTracks(track, participant);
      //document.getElementById('remote-media-div').appendChild(track.attach());
    });

    //document.body.appendChild(div);
  };

  const participantDisconnected = (participant) => {
    deleteParticipantTracks(null, participant);
    //document.getElementById(participant.sid).remove();
  };

  const trackUnsubscribed = (track) => {
    //track.detach().forEach(element => element.remove());
  };

  const initializeRoomListeners = (room) => {
    room.on("participantConnected", participantConnected);

    room.on("participantDisconnected", participantDisconnected);
    room.once("disconnected", (error) =>
      room.participants.forEach(participantDisconnected)
    );
  };

  const trackPublished = (publication, participant, isSubscribed = false) => {
    if (publication.isSubscribed || isSubscribed) {
      console.log("  PUBLICATION: '", participant, publication);
      let oldPublications = publications;
      let shareScreenVideo = null;
      let hasScreenShareTracks = false;

      try {
        if (
          publication.track.kind === "video" &&
          participant.identity === eventData.influencer.user.user_username
        ) {
          if (publication.track.name.includes("-SCREENSHARE-")) {
            shareScreenVideo = publication;
            hasScreenShareTracks = true;
          } else {
            oldPublications.push(publication);
          }
        } else if (publication.track.kind === "audio") {
          oldPublications.push(publication);
        }
      } catch (error) {
        oldPublications.push(publication);
        console.error(error);
      }
      console.log("New PUBLICATIONS", oldPublications);

      if (hasScreenShareTracks) {
        setScreenShareTracks(shareScreenVideo);
      }

      setPublications(oldPublications);
      //setMyPublisher(participant);
    }
  };

  const setRefreshTokenDelay = (
    specialDelay = 400,
    updateSessionData = false
  ) => {
    setTimeout(() => {
      if (updateSessionData) {
        updateStreamingState();
      }
      setRefreshToken(Date.now());
    }, specialDelay);
  };

  const remoteTrackPublished = (
    publication,
    participant,
    isSubscribed = false
  ) => {
    if (publication.isSubscribed || isSubscribed) {
      console.log("  remoteTrackPublished: '", participant, publication);
      if (publication.track.kind === "video") {
        let oldPublications = remotePublications; //[];//remotePublications;

        oldPublications.push(publication);
        /*
        try {
          if (publication.track.kind === "video") {
            let previousTrack = oldPublications.find(publicationItem => {
              let trackId = publicationItem.track.sid || publicationItem.track.name;
              let newTrackId = publication.track.sid || publication.track.name;

              console.log("FUNCTION FIND remoteTrackPublished ", publicationItem, publication, newTrackId, trackId, session)
              return newTrackId === trackId;

            })

            console.log("remoteTrackPublished 2", previousTrack, oldPublications, publication)

            if (!previousTrack) {
              oldPublications.push(publication);
            }

          }

        } catch (error) {
          oldPublications.push(publication);
          console.error(error);
        }*/

        console.log("New PUBLICATIONS remoteTrackPublished", oldPublications);
        setRemotePublications(oldPublications);
        setRefreshTokenDelay();
        //setMyPublisher(participant);
      }
    }
  };

  /**
   * Detach a Track from the DOM.
   * @param track - the Track to be detached
   * @param HTMLElement - the HTMLElement that is playing the Track
   */
  const detachTrack = (track, HTMLElement) => {
    // Detach the Participant's Track from the thumbnail.
    console.log("detachTrack MAIN", track, HTMLElement);
    try {
      try {
        if (HTMLElement.detach) {
          HTMLElement.detach(track);
        }
      } catch (error) {
        console.error(error);
      }

      console.log("detachTrack MAIN done", track, HTMLElement);
    } catch (error) {
      console.error(error);
    }

    try {
      clearHTMLElement(HTMLElement);
    } catch (error) {
      console.error("clearHTMLElement error", error);
    }

    try {
      HTMLElement.innerHTML = "";
    } catch (error) {
      console.error("Error removing video from DOM:", error);
    }
  };

  const clearHTMLElement = (HTMLElement) => {
    while (HTMLElement.firstChild) {
      HTMLElement.removeChild(HTMLElement.lastChild);
    }

    HTMLElement.innerHTML = null;
  };

  const removeAndDetachTrack = (
    track,
    participant = null,
    removeByName = false
  ) => {
    console.log("removeAndDetachTrack MAIN", track);

    let trackSidOrName = "";

    try {
      if (track.trackName.includes("microphone")) {
        try {
          trackSidOrName = "Track-" + track.trackSid;
        } catch (error) {}
      } else {
        try {
          trackSidOrName = "Track-" + track.trackName;
        } catch (error) {}
      }
    } catch (error) {
      console.log("Error removeAndDetachTrack: ", error);
      console.error(error);
    }

    console.log("removeAndDetachTrack track", trackSidOrName, track);

    /*
    let previousHTMLElement = document.getElementById("Track-" + track.trackSid);
    let previousHTMLElementByName = document.getElementById("Track-" + track.trackName);
    */

    let previousHTMLElement = document.getElementById(trackSidOrName);
    let previousHTMLElementByName = document.getElementById(trackSidOrName);

    console.log(
      "removeAndDetachTrack elements",
      previousHTMLElement,
      previousHTMLElementByName
    );

    if (previousHTMLElement && !removeByName) {
      try {
        detachTrack(track.track, previousHTMLElement);
      } catch (error) {
        console.error("removeAndDetachTrack ERROR:", error);
      }
    }

    if (previousHTMLElementByName && !removeByName) {
      try {
        detachTrack(track.track, previousHTMLElementByName);
      } catch (error) {
        console.error(
          "removeAndDetachTrack previousHTMLElementByName ERROR:",
          error
        );
      }
    }

    let currentTracks = publications;

    console.log(
      "removeAndDetachTrack MAIN",
      track,
      previousHTMLElement,
      currentTracks
    );
    let elemeToDelete = null;

    for (let i = 0; i < currentTracks.length; i++) {
      let item = currentTracks[i];

      console.log("Esto tiene el item removeAndDetachTrack", item);
    }

    if (participant) {
      //elemeToDelete = currentTracks.find(publication => publication.participant && publication.participant.identity === participant.identity);
    } else {
      //elemeToDelete = currentTracks.find(publication => publication.track.sid === track.trackSid);
      elemeToDelete = currentTracks.find(
        (publication) => publication.track.name === track.trackName
      );
    }

    const index = currentTracks.indexOf(elemeToDelete);

    console.log(
      "removeAndDetachTrack MAIN 2",
      index,
      track,
      elemeToDelete,
      currentTracks
    );

    if (index > -1) {
      currentTracks.splice(index, 1);
    }

    console.log(
      "removeAndDetachTrack MAIN 3",
      index,
      track,
      elemeToDelete,
      currentTracks
    );

    setPublications(currentTracks);

    try {
      //let videoHtmlElement = document.getElementById("Track-" + track.trackName);
      let videoHtmlElement = document.getElementById(trackSidOrName);

      if (videoHtmlElement) {
        videoHtmlElement.remove();
      }
    } catch (error) {
      console.error("Error removeAndDetachTrach elemeToDelete.remove()", error);
    }

    let remoteCurrentTracks = remotePublications;

    let remoteElemeToDelete = null;

    if (participant) {
      remoteElemeToDelete = remoteCurrentTracks.find(
        (publication) =>
          publication.participant &&
          publication.participant.identity === participant.identity
      );
    } else {
      remoteElemeToDelete = remoteCurrentTracks.find(
        (publication) => publication.track.sid === track.trackSid
      );
    }

    const indexRemoteElement = remoteCurrentTracks.indexOf(remoteElemeToDelete);

    if (indexRemoteElement > -1) {
      remoteCurrentTracks.splice(indexRemoteElement, 1);
    }

    try {
      //let videoHtmlElement = document.getElementById("Track-" + track.trackName);
      let videoHtmlElement = document.getElementById(
        "Track-" + track.trackName
      );
      if (videoHtmlElement) {
        videoHtmlElement.remove();
      }
    } catch (error) {
      console.error("Error removeAndDetachTrach elemeToDelete.remove()", error);
    }

    setRemotePublications(remoteCurrentTracks);
  };

  const findDominantParticipantProfilePhoto = (participantIdentity) => {
    try {
      let participantData = eventData.attendees_users.find(
        (participantItem) =>
          participantItem.user_username === participantIdentity
      );

      return participantData.user_avatar;
    } catch (error) {
      return "https://api.gotmy.com/public/static/img/user/generic.png";
    }
  };

  const handleSpeakerChange = (participant) => {
    console.log("Dominant speaker changed", participant);

    if (participant) {
      setCurrentDominantSpeaker({
        enabled: true,
        imageSrc: findDominantParticipantProfilePhoto(participant.identity),
        participant: participant,
      });
    } else {
      setCurrentDominantSpeaker({
        enabled: false,
        imageSrc: null,
        participant: null,
      });
    }
  };

  const connectWithTwilio = (token, isInfluencerLocal = true) => {
    const renderDimensions = {
      high: { height: 1080, width: 1920 },
      standard: { height: 1080, width: 1920 },
      low: { height: 1080, width: 1920 },
    };

    const mobileRenderDimensions = {
      high: { height: 1920, width: 1080 },
      standard: { height: 1920, width: 1080 },
      low: { height: 1920, width: 1080 },
    };

    let influencerConnectionProperties;
    let viewerConnectionProperties

    try{
      influencerConnectionProperties = {
        dominantSpeaker: true,
        video: {
          name: currentVideoDevice.deviceId + Date.now(),
          deviceId: currentVideoDevice.deviceId,
          //width: 1920,
          //height: 1080,
          //frameRate: { min: 20, ideal: 45, max: 60 }
        },
        audio: {
          name: currentAudioDevice.deviceId + Date.now(),
          deviceId: currentAudioDevice.deviceId,
        },
        preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
        bandwidthProfile: {
          video: {
            mode: "presentation",
            maxTracks: 3,
            dominantSpeakerPriority: "standard",
            renderDimensions: isMobile
                ? mobileRenderDimensions
                : renderDimensions,
          },
        },
      };

      viewerConnectionProperties = {
        dominantSpeaker: true,
        video: false,
        bandwidthProfile: {
          video: {
            mode: "presentation",
            maxTracks: 3,
            dominantSpeakerPriority: "standard",
            renderDimensions: isMobile
                ? mobileRenderDimensions
                : renderDimensions,
          },
        },
        audio: false,
      };
    }catch(error){

    }



    //disable if viewer starts with no audio
    publishedAudio = false;

    const options = isInfluencerLocal
      ? influencerConnectionProperties
      : viewerConnectionProperties;

    console.log("connectWithTwilio", options);

    Video.createLocalTracks(options)
      .then((localTracks) => {
        // For Safari
        // This ensures that localTracks will have an array of LocalTracks at this point

        sendServerLog("User local tracks created.");

        console.log("createLocalTracks estas son las localTracks del usuario", localTracks);

        setLocalTracks(localTracks);

        let priority = "high";

        if (!isInfluencer) {
          priority = "low";
        }

        Video.connect(token, {
          name: uuid,
          audio: false,
          dominantSpeaker: true,
          video: false,
          /*tracks: localTracks, */ automaticSubscription: true,
          preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
          bandwidthProfile: {
            video: {
              mode: "presentation",
              maxTracks: 3,
              dominantSpeakerPriority: "standard",
              renderDimensions: isMobile
                ? mobileRenderDimensions
                : renderDimensions,
            },
          },
        })
          .then((room) => {
            try {
              connectSocketIO();

              updateStreamingState();


              localTracks.forEach((track) => {
                room.localParticipant.publishTrack(track);
              });

              setSessionLoading(false);

              if (isInfluencer) {
                room.localParticipant.tracks.forEach((track) => {
                  trackPublished(
                    { track: track.track },
                    room.localParticipant,
                    true
                  );
                });
                setRefreshTokenDelay(3000);
              }

              // Handle theTrackPublications that will be published by the Participant later.
              room.localParticipant.on("trackPublished", (publication) => {
                if (isInfluencer) {
                  trackPublished(
                    { track: publication.track },
                    room.localParticipant,
                    true
                  );
                  toggleStreamingOptionsModal(false);
                } else {
                  remoteTrackPublished(
                    { track: publication, participant: room.localParticipant },
                    room.localParticipant
                  );
                }
                setRefreshTokenDelay(3000);
              });

              // Handle theTrackPublications that will be published by the Participant later.
              room.localParticipant.on("trackAdded", (publication) => {
                console.log(
                  "new track trackAdded LOCAL PARTICIPANT",
                  publication
                );
                setRefreshTokenDelay(3000);
                /*if (isInfluencer) {
                console.log("NEW TRACK PUBLISHED LOCAL", publication, publication.isSubscribed);
                trackPublished({ track: publication.track }, room.localParticipant, true);
                toggleStreamingOptionsModal(false);
              } else {
                remoteTrackPublished({ track: publication, participant: room.localParticipant }, room.localParticipant);
              }*/
              });

              // Handle theTrackPublications that will be published by the Participant later.
              room.localParticipant.on("trackSubscribed", (publication) => {
                console.log(
                  "new track SUBSCRIBED LOCAL PARTICIPANT",
                  publication
                );
                if (isInfluencer) {
                  console.log(
                    "NEW TRACK SUBSCRIBED LOCAL",
                    publication,
                    publication.isSubscribed
                  );
                  trackPublished(
                    { track: publication.track },
                    room.localParticipant,
                    true
                  );
                  toggleStreamingOptionsModal(false);
                } else {
                  remoteTrackPublished(
                    { track: publication, participant: room.localParticipant },
                    room.localParticipant
                  );
                }
                setRefreshTokenDelay(3000);
              });

              // Handle theTrackPublications that will be published by the Participant later.
              room.localParticipant.on("trackRemoved", (track, participant) => {
                console.log(
                  "trackRemoved LOCAL PARTICIPANT",
                  track,
                  participant
                );
              });

              setSession(room);

              let previousParticipants = subscribers;

              // Attach the Tracks of the Room's Participants.
              room.participants.forEach((participant) => {
                console.log(
                  "  Attach the Tracks of the Room's Participants Already in Room: '" +
                    participant.identity +
                    "'",
                  participant
                );

                if (
                  participant.identity ===
                  eventData.influencer.user.user_username
                ) {
                  setInfluencerConnected(true);
                } else {
                  localSessionParticipants.set(
                    participant.identity,
                    participant
                  );
                }

                previousParticipants.push(participant);

                participant.tracks.forEach((publication) => {
                  if (
                    publication.kind === "audio" ||
                    participant.identity ===
                      eventData.influencer.user.user_username
                  ) {
                    trackPublished(publication, participant);
                  } else {
                    remoteTrackPublished(
                      { track: publication.track, participant: participant },
                      participant
                    );
                  }
                });
                setRefreshTokenDelay(3000);

                // Handle theTrackPublications that will be published by the Participant later.
                participant.on("trackPublished", (publication) => {
                  console.log(
                    "NEW TRACK PUBLISHED",
                    publication,
                    publication.isSubscribed
                  );

                  if (
                    publication.kind === "audio" ||
                    participant.identity ===
                      eventData.influencer.user.user_username
                  ) {
                    trackPublished({ track: publication }, participant);
                  } else {
                    remoteTrackPublished(
                      { track: publication, participant: participant },
                      participant
                    );
                  }
                  setRefreshTokenDelay(3000);
                });

                // Handle theTrackPublications that will be published by the Participant later.
                participant.on("trackSubscribed", (publication) => {
                  console.log(
                    "NEW TRACK SUBSCRIBED",
                    publication,
                    publication.isSubscribed
                  );
                  if (
                    publication.kind === "audio" ||
                    participant.identity ===
                      eventData.influencer.user.user_username
                  ) {
                    trackPublished({ track: publication }, participant, true);
                  } else {
                    remoteTrackPublished(
                      { track: publication, participant: participant },
                      participant,
                      true
                    );
                  }
                  setRefreshTokenDelay(3000);
                });

                // Handle theTrackPublications that will be published by the Participant later.
                participant.on("trackRemoved", (track, participant) => {
                  console.log(
                    "NEW TRACK trackRemoved",
                    track,
                    remotePublications
                  );
                });
              });

              if (!isInfluencer) {
                localSessionParticipants.set(
                  room.localParticipant.identity,
                  room.localParticipant
                );
              }

              let mapToArray = Array.from(
                localSessionParticipants,
                ([name, value]) => value
              );
              updateConnectedViewers(mapToArray);
              setSubscribers(previousParticipants);

              room.on("dominantSpeakerChanged", (participant) => {
                handleSpeakerChange(participant);
              });

              // When a Participant joins the Room, log the event.
              room.on("participantConnected", (participant) => {
                if (isInfluencer) {
                  composeNewConnectionToastMessage(participant);
                }

                if (
                  participant.identity ===
                  eventData.influencer.user.user_username
                ) {
                  setInfluencerConnected(true);
                }

                let previousParticipants = [];

                //change

                for (let i = 0; i < subscribers.length; i++) {
                  let currentSubscriber = subscribers[i];

                  if (currentSubscriber.identity !== participant.identity) {
                    previousParticipants.push(currentSubscriber);
                  }
                }

                previousParticipants.push(participant);

                console.log(
                  " participantConnected updateConnectedViewers",
                  session,
                  subscribers,
                  previousParticipants
                );

                localSessionParticipants.set(participant.identity, participant);

                let mapToArray = Array.from(
                  localSessionParticipants,
                  ([name, value]) => value
                );
                updateConnectedViewers(mapToArray);

                setSubscribers(previousParticipants);

                console.log(
                  " participantConnected Joining: '" +
                    participant.identity +
                    "'",
                  participant
                );

                participant.tracks.forEach((publication) => {
                  if (
                    publication.kind === "audio" ||
                    participant.identity ===
                      eventData.influencer.user.user_username
                  ) {
                    trackPublished(publication, participant);
                  } else {
                    remoteTrackPublished(
                      { track: publication, participant: participant },
                      participant
                    );
                  }
                });
                setRefreshTokenDelay(3000, true);

                // Handle theTrackPublications that will be published by the Participant later.
                participant.on("trackPublished", (publication) => {
                  console.log(
                    "NEW TRACK PUBLISHED",
                    publication,
                    publication.isSubscribed
                  );
                  if (
                    publication.kind === "audio" ||
                    participant.identity ===
                      eventData.influencer.user.user_username
                  ) {
                    trackPublished({ track: publication }, participant);
                  } else {
                    //remoteTrackPublished({ track: publication, participant: participant }, participant);
                  }
                  setRefreshTokenDelay(3000, true);
                });

                // Handle theTrackPublications that will be published by the Participant later.
                participant.on("trackSubscribed", (publication) => {
                  console.log(
                    "NEW TRACK SUBSCRIBED",
                    publication,
                    publication.isSubscribed
                  );
                  if (
                    publication.kind === "audio" ||
                    participant.identity ===
                      eventData.influencer.user.user_username
                  ) {
                    trackPublished({ track: publication }, participant, true);
                  } else {
                    remoteTrackPublished(
                      { track: publication, participant: participant },
                      participant,
                      true
                    );
                  }
                  setRefreshTokenDelay(3000, true);
                });

                updateStreamingState();
              });

              // When a Participant adds a Track, attach it to the DOM.
              room.on("trackAdded", (track, participant) => {
                console.log("trackAdded", participant, track);
                setRefreshTokenDelay(3000, true);
              });

              // When a Participant adds a Track, attach it to the DOM.
              room.on("trackPublished", (track, participant) => {
                console.log("trackPublished 1", participant, track);
                setRefreshTokenDelay(3000, true);
              });

              room.on("trackUnpublished", (publication, participant) => {
                console.log(
                  "trackUnpublished 1",
                  participant,
                  publication,
                  remotePublications
                );

                try {
                  if (publication.trackName.includes("-SCREENSHARE-")) {
                    setScreenShareTracks(null);
                  }
                } catch (error) {
                  console.error("ERROR reseting screen share mode off", error);
                }

                try {
                  removeAndDetachTrack(publication, participant);
                } catch (error) {
                  console.error(
                    "Error detaching and removing track in trackUnpublished",
                    error
                  );
                }

                setRefreshTokenDelay(3000, true);
              });

              // When a Participant removes a Track, detach it from the DOM.
              room.on("trackRemoved", (track, participant) => {
                console.log(
                  "trackRemoved",
                  participant.identity,
                  " removed track: ",
                  track.kind,
                  remotePublications
                );

                removeAndDetachTrack(track);
                setRefreshTokenDelay(3000, true);
              });

              // When a Participant leaves the Room, detach its Tracks.
              room.on("participantDisconnected", (participant) => {
                console.log(
                  "participantDisconnected",
                  participant,
                  participant._RemoteVideoTrackPublication,
                  participant._RemoteAudioTrackPublication,
                  participant._RemoteDataTrackPublication
                );

                console.log(
                  "antes de participantDisconnected",
                  participant,
                  publications,
                  remotePublications
                );

                if (
                  participant.identity ===
                  eventData.influencer.user.user_username
                ) {
                  setInfluencerConnected(false);
                }

                let previousParticipants = [];

                for (let i = 0; i < subscribers.length; i++) {
                  let currentSubscriber = subscribers[i];

                  if (currentSubscriber.identity !== participant.identity) {
                    previousParticipants.push(currentSubscriber);
                  }
                }

                localSessionParticipants.delete(participant.identity);

                let mapToArray = Array.from(
                  localSessionParticipants,
                  ([name, value]) => value
                );
                updateConnectedViewers(mapToArray);

                setSubscribers(previousParticipants);

                console.log(
                  " participantDisconnected updateConnectedViewers",
                  session,
                  subscribers,
                  previousParticipants
                );

                let previousRemoteViewer = actualRemoteSpeakingUser;

                try {
                  previousRemoteViewer = actualRemoteSpeakingUser.username;
                } catch (error) {
                  console.error(
                    "There is no actualRemoteSpeakingUser in disconnectedParticipant",
                    error
                  );
                }

                if (
                  participant.identity &&
                  previousRemoteViewer &&
                  previousRemoteViewer === participant.identity
                ) {
                  actualRemoteSpeakingUser = null;
                  setSpeaking_viewer(null);
                }

                if (isInfluencer) {
                  composeNewConnectionToastMessage(participant, false);
                }

                participant.tracks.forEach((track) => {
                  removeAndDetachTrack(track, participant);
                });
                setRefreshTokenDelay(3000, true);

                /*
              let elemeToDelete = previousParticipants.find(subscriber => subscriber.identity === participant.identity);

              const index = previousParticipants.indexOf(elemeToDelete);

              if (index > -1) {
                previousParticipants.splice(index, 1);
              }

              updateConnectedViewers(previousParticipants);

              participant.tracks.forEach(track => {
                console.log("PISTA A REMOVER participantDisconnected", track, track.track, participant)
                removeAndDetachTrack(track);
              })

              console.log("Despues de participantDisconnected", participant, publications, remotePublications)
    */
              });

              // Once the LocalParticipant leaves the room, detach the Tracks
              // of all Participants, including that of the LocalParticipant.
              room.on("disconnected", (room, error) => {
                console.log("disconnected", room, error);
                room.localParticipant.tracks.forEach((track) => {
                  console.log("stop all tracks", track);

                  let videoList = document.getElementsByTagName("video");

                  console.log("VIDEO ELEMENTS", videoList);

                  for (let videoElement of videoList) {
                    try {
                      videoElement.remove();
                      videoElement.innerHTML = null;
                    } catch (error) {
                      console.error(error);
                    }
                  }
                  try {
                    track.track.stop();
                  } catch (error) {
                    console.error(error);
                  }
                });
                room.participants.forEach((participant) => {
                  participant.tracks.forEach((track) => {
                    removeAndDetachTrack(track, participant);
                  });
                  console.log("disconnected", participant);
                });

                if (error && error.code !== 53118) {
                  console.log("Disconnected due to error, it should reconnect");
                  reconnectSession("Error networkDisconnect");
                } else {
                  console.log("Session finished");
                }
              });

              //initializeRoomListeners(room);

              disableReconnectLock("publish connectStreaming");

              setStartingStreaming(false);
              setStreamingOnline(true);
              setSessionConnected(true);

              if (isInfluencer) {
                setMyPublisher(room.localParticipant);
                //eventDAO.influencerReady(uuid, "", env.token);
              } else {
                setViewerPublisher(room.localParticipant);
              }

              if (isReconnecting.status) {
                updateStreamingState();
                setIsreconnecting({
                  status: false,
                  previousStatus: true,
                });
              }

              sendServerLog("User connected to twilio room.");
            } catch (error) {
              sendServerLog(
                "There was an error connecting to the twilio room.",
                error
              );
            }
          })
          .catch((error) => {
            console.error("Error connecting with Twilio room:", error);
            sendServerLog(
              "Error connecting to the session connectStreaming. Function <connectStreaming()>.",
              error
            );
            setSessionError(
              i18next.t(
                "PreviewStreamingInfluencerComponentProblemConnectingSession"
              )
            );
            setSessionConnected(false);
          });
      })
      .catch((error) => {

        console.log("createLocalTracks error", error)

        setSessionError(
            i18next.t(
                "PreviewStreamingInfluencerComponentProblemConnectingAccesingToMediaDevices"
            )
        );

        Video.connect(token, {
          name: uuid,
          audio: false,
          dominantSpeaker: true,
          video: false,
          /*tracks: localTracks, */ automaticSubscription: true,
          preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
          bandwidthProfile: {
            video: {
              mode: "presentation",
              maxTracks: 3,
              dominantSpeakerPriority: "standard",
              renderDimensions: isMobile
                  ? mobileRenderDimensions
                  : renderDimensions,
            },
          },
        })
            .then((room) => {
              try {
                connectSocketIO();

                updateStreamingState();

                setSessionLoading(false);

                if (isInfluencer) {
                  room.localParticipant.tracks.forEach((track) => {
                    trackPublished(
                        { track: track.track },
                        room.localParticipant,
                        true
                    );
                  });
                  setRefreshTokenDelay(3000);
                }

                // Handle theTrackPublications that will be published by the Participant later.
                room.localParticipant.on("trackPublished", (publication) => {
                  if (isInfluencer) {
                    trackPublished(
                        { track: publication.track },
                        room.localParticipant,
                        true
                    );
                    toggleStreamingOptionsModal(false);
                  } else {
                    remoteTrackPublished(
                        { track: publication, participant: room.localParticipant },
                        room.localParticipant
                    );
                  }
                  setRefreshTokenDelay(3000);
                });

                // Handle theTrackPublications that will be published by the Participant later.
                room.localParticipant.on("trackAdded", (publication) => {
                  console.log(
                      "new track trackAdded LOCAL PARTICIPANT",
                      publication
                  );
                  setRefreshTokenDelay(3000);
                  /*if (isInfluencer) {
                  console.log("NEW TRACK PUBLISHED LOCAL", publication, publication.isSubscribed);
                  trackPublished({ track: publication.track }, room.localParticipant, true);
                  toggleStreamingOptionsModal(false);
                } else {
                  remoteTrackPublished({ track: publication, participant: room.localParticipant }, room.localParticipant);
                }*/
                });

                // Handle theTrackPublications that will be published by the Participant later.
                room.localParticipant.on("trackSubscribed", (publication) => {
                  console.log(
                      "new track SUBSCRIBED LOCAL PARTICIPANT",
                      publication
                  );
                  if (isInfluencer) {
                    console.log(
                        "NEW TRACK SUBSCRIBED LOCAL",
                        publication,
                        publication.isSubscribed
                    );
                    trackPublished(
                        { track: publication.track },
                        room.localParticipant,
                        true
                    );
                    toggleStreamingOptionsModal(false);
                  } else {
                    remoteTrackPublished(
                        { track: publication, participant: room.localParticipant },
                        room.localParticipant
                    );
                  }
                  setRefreshTokenDelay(3000);
                });

                // Handle theTrackPublications that will be published by the Participant later.
                room.localParticipant.on("trackRemoved", (track, participant) => {
                  console.log(
                      "trackRemoved LOCAL PARTICIPANT",
                      track,
                      participant
                  );
                });

                setSession(room);

                let previousParticipants = subscribers;

                // Attach the Tracks of the Room's Participants.
                room.participants.forEach((participant) => {
                  console.log(
                      "  Attach the Tracks of the Room's Participants Already in Room: '" +
                      participant.identity +
                      "'",
                      participant
                  );

                  if (
                      participant.identity ===
                      eventData.influencer.user.user_username
                  ) {
                    setInfluencerConnected(true);
                  } else {
                    localSessionParticipants.set(
                        participant.identity,
                        participant
                    );
                  }

                  previousParticipants.push(participant);

                  participant.tracks.forEach((publication) => {
                    if (
                        publication.kind === "audio" ||
                        participant.identity ===
                        eventData.influencer.user.user_username
                    ) {
                      trackPublished(publication, participant);
                    } else {
                      remoteTrackPublished(
                          { track: publication.track, participant: participant },
                          participant
                      );
                    }
                  });
                  setRefreshTokenDelay(3000);

                  // Handle theTrackPublications that will be published by the Participant later.
                  participant.on("trackPublished", (publication) => {
                    console.log(
                        "NEW TRACK PUBLISHED",
                        publication,
                        publication.isSubscribed
                    );

                    if (
                        publication.kind === "audio" ||
                        participant.identity ===
                        eventData.influencer.user.user_username
                    ) {
                      trackPublished({ track: publication }, participant);
                    } else {
                      remoteTrackPublished(
                          { track: publication, participant: participant },
                          participant
                      );
                    }
                    setRefreshTokenDelay(3000);
                  });

                  // Handle theTrackPublications that will be published by the Participant later.
                  participant.on("trackSubscribed", (publication) => {
                    console.log(
                        "NEW TRACK SUBSCRIBED",
                        publication,
                        publication.isSubscribed
                    );
                    if (
                        publication.kind === "audio" ||
                        participant.identity ===
                        eventData.influencer.user.user_username
                    ) {
                      trackPublished({ track: publication }, participant, true);
                    } else {
                      remoteTrackPublished(
                          { track: publication, participant: participant },
                          participant,
                          true
                      );
                    }
                    setRefreshTokenDelay(3000);
                  });

                  // Handle theTrackPublications that will be published by the Participant later.
                  participant.on("trackRemoved", (track, participant) => {
                    console.log(
                        "NEW TRACK trackRemoved",
                        track,
                        remotePublications
                    );
                  });
                });

                if (!isInfluencer) {
                  localSessionParticipants.set(
                      room.localParticipant.identity,
                      room.localParticipant
                  );
                }

                let mapToArray = Array.from(
                    localSessionParticipants,
                    ([name, value]) => value
                );
                updateConnectedViewers(mapToArray);
                setSubscribers(previousParticipants);

                room.on("dominantSpeakerChanged", (participant) => {
                  handleSpeakerChange(participant);
                });

                // When a Participant joins the Room, log the event.
                room.on("participantConnected", (participant) => {
                  if (isInfluencer) {
                    composeNewConnectionToastMessage(participant);
                  }

                  if (
                      participant.identity ===
                      eventData.influencer.user.user_username
                  ) {
                    setInfluencerConnected(true);
                  }

                  let previousParticipants = [];

                  //change

                  for (let i = 0; i < subscribers.length; i++) {
                    let currentSubscriber = subscribers[i];

                    if (currentSubscriber.identity !== participant.identity) {
                      previousParticipants.push(currentSubscriber);
                    }
                  }

                  previousParticipants.push(participant);

                  console.log(
                      " participantConnected updateConnectedViewers",
                      session,
                      subscribers,
                      previousParticipants
                  );

                  localSessionParticipants.set(participant.identity, participant);

                  let mapToArray = Array.from(
                      localSessionParticipants,
                      ([name, value]) => value
                  );
                  updateConnectedViewers(mapToArray);

                  setSubscribers(previousParticipants);

                  console.log(
                      " participantConnected Joining: '" +
                      participant.identity +
                      "'",
                      participant
                  );

                  participant.tracks.forEach((publication) => {
                    if (
                        publication.kind === "audio" ||
                        participant.identity ===
                        eventData.influencer.user.user_username
                    ) {
                      trackPublished(publication, participant);
                    } else {
                      remoteTrackPublished(
                          { track: publication, participant: participant },
                          participant
                      );
                    }
                  });
                  setRefreshTokenDelay(3000, true);

                  // Handle theTrackPublications that will be published by the Participant later.
                  participant.on("trackPublished", (publication) => {
                    console.log(
                        "NEW TRACK PUBLISHED",
                        publication,
                        publication.isSubscribed
                    );
                    if (
                        publication.kind === "audio" ||
                        participant.identity ===
                        eventData.influencer.user.user_username
                    ) {
                      trackPublished({ track: publication }, participant);
                    } else {
                      //remoteTrackPublished({ track: publication, participant: participant }, participant);
                    }
                    setRefreshTokenDelay(3000, true);
                  });

                  // Handle theTrackPublications that will be published by the Participant later.
                  participant.on("trackSubscribed", (publication) => {
                    console.log(
                        "NEW TRACK SUBSCRIBED",
                        publication,
                        publication.isSubscribed
                    );
                    if (
                        publication.kind === "audio" ||
                        participant.identity ===
                        eventData.influencer.user.user_username
                    ) {
                      trackPublished({ track: publication }, participant, true);
                    } else {
                      remoteTrackPublished(
                          { track: publication, participant: participant },
                          participant,
                          true
                      );
                    }
                    setRefreshTokenDelay(3000, true);
                  });

                  updateStreamingState();
                });

                // When a Participant adds a Track, attach it to the DOM.
                room.on("trackAdded", (track, participant) => {
                  console.log("trackAdded", participant, track);
                  setRefreshTokenDelay(3000, true);
                });

                // When a Participant adds a Track, attach it to the DOM.
                room.on("trackPublished", (track, participant) => {
                  console.log("trackPublished 1", participant, track);
                  setRefreshTokenDelay(3000, true);
                });

                room.on("trackUnpublished", (publication, participant) => {
                  console.log(
                      "trackUnpublished 1",
                      participant,
                      publication,
                      remotePublications
                  );

                  try {
                    if (publication.trackName.includes("-SCREENSHARE-")) {
                      setScreenShareTracks(null);
                    }
                  } catch (error) {
                    console.error("ERROR reseting screen share mode off", error);
                  }

                  try {
                    removeAndDetachTrack(publication, participant);
                  } catch (error) {
                    console.error(
                        "Error detaching and removing track in trackUnpublished",
                        error
                    );
                  }

                  setRefreshTokenDelay(3000, true);
                });

                // When a Participant removes a Track, detach it from the DOM.
                room.on("trackRemoved", (track, participant) => {
                  console.log(
                      "trackRemoved",
                      participant.identity,
                      " removed track: ",
                      track.kind,
                      remotePublications
                  );

                  removeAndDetachTrack(track);
                  setRefreshTokenDelay(3000, true);
                });

                // When a Participant leaves the Room, detach its Tracks.
                room.on("participantDisconnected", (participant) => {
                  console.log(
                      "participantDisconnected",
                      participant,
                      participant._RemoteVideoTrackPublication,
                      participant._RemoteAudioTrackPublication,
                      participant._RemoteDataTrackPublication
                  );

                  console.log(
                      "antes de participantDisconnected",
                      participant,
                      publications,
                      remotePublications
                  );

                  if (
                      participant.identity ===
                      eventData.influencer.user.user_username
                  ) {
                    setInfluencerConnected(false);
                  }

                  let previousParticipants = [];

                  for (let i = 0; i < subscribers.length; i++) {
                    let currentSubscriber = subscribers[i];

                    if (currentSubscriber.identity !== participant.identity) {
                      previousParticipants.push(currentSubscriber);
                    }
                  }

                  localSessionParticipants.delete(participant.identity);

                  let mapToArray = Array.from(
                      localSessionParticipants,
                      ([name, value]) => value
                  );
                  updateConnectedViewers(mapToArray);

                  setSubscribers(previousParticipants);

                  console.log(
                      " participantDisconnected updateConnectedViewers",
                      session,
                      subscribers,
                      previousParticipants
                  );

                  let previousRemoteViewer = actualRemoteSpeakingUser;

                  try {
                    previousRemoteViewer = actualRemoteSpeakingUser.username;
                  } catch (error) {
                    console.error(
                        "There is no actualRemoteSpeakingUser in disconnectedParticipant",
                        error
                    );
                  }

                  if (
                      participant.identity &&
                      previousRemoteViewer &&
                      previousRemoteViewer === participant.identity
                  ) {
                    actualRemoteSpeakingUser = null;
                    setSpeaking_viewer(null);
                  }

                  if (isInfluencer) {
                    composeNewConnectionToastMessage(participant, false);
                  }

                  participant.tracks.forEach((track) => {
                    removeAndDetachTrack(track, participant);
                  });
                  setRefreshTokenDelay(3000, true);

                  /*
                let elemeToDelete = previousParticipants.find(subscriber => subscriber.identity === participant.identity);

                const index = previousParticipants.indexOf(elemeToDelete);

                if (index > -1) {
                  previousParticipants.splice(index, 1);
                }

                updateConnectedViewers(previousParticipants);

                participant.tracks.forEach(track => {
                  console.log("PISTA A REMOVER participantDisconnected", track, track.track, participant)
                  removeAndDetachTrack(track);
                })

                console.log("Despues de participantDisconnected", participant, publications, remotePublications)
      */
                });

                // Once the LocalParticipant leaves the room, detach the Tracks
                // of all Participants, including that of the LocalParticipant.
                room.on("disconnected", (room, error) => {
                  console.log("disconnected", room, error);
                  room.localParticipant.tracks.forEach((track) => {
                    console.log("stop all tracks", track);

                    let videoList = document.getElementsByTagName("video");

                    console.log("VIDEO ELEMENTS", videoList);

                    for (let videoElement of videoList) {
                      try {
                        videoElement.remove();
                        videoElement.innerHTML = null;
                      } catch (error) {
                        console.error(error);
                      }
                    }
                    try {
                      track.track.stop();
                    } catch (error) {
                      console.error(error);
                    }
                  });
                  room.participants.forEach((participant) => {
                    participant.tracks.forEach((track) => {
                      removeAndDetachTrack(track, participant);
                    });
                    console.log("disconnected", participant);
                  });

                  if (error && error.code !== 53118) {
                    console.log("Disconnected due to error, it should reconnect");
                    reconnectSession("Error networkDisconnect");
                  } else {
                    console.log("Session finished");
                  }
                });

                //initializeRoomListeners(room);

                disableReconnectLock("publish connectStreaming");

                setStartingStreaming(false);
                setStreamingOnline(true);
                setSessionConnected(true);

                if (isInfluencer) {
                  setMyPublisher(room.localParticipant);
                  //eventDAO.influencerReady(uuid, "", env.token);
                } else {
                  setViewerPublisher(room.localParticipant);
                }

                if (isReconnecting.status) {
                  updateStreamingState();
                  setIsreconnecting({
                    status: false,
                    previousStatus: true,
                  });
                }

                sendServerLog("User connected to twilio room.");
              } catch (error) {
                sendServerLog(
                    "There was an error connecting to the twilio room.",
                    error
                );
              }
            })
            .catch((error) => {
              console.error("Error connecting with Twilio room:", error);
              sendServerLog(
                  "Error connecting to the session connectStreaming. Function <connectStreaming()>.",
                  error
              );
              setSessionError(
                  i18next.t(
                      "PreviewStreamingInfluencerComponentProblemConnectingSession"
                  )
              );
              setSessionConnected(false);
            });

        sendServerLog(
          "Error creating local tracks to the session connectStreaming. Function <createLocalTracks()> line 4440.",
          error
        );
      });
  };

  const connectStreaming = async () => {
    const env = JSON.parse(localStorage.getItem("env"));
    setSessionLoading(true);
    eventDAO
      .startStreaming(uuid, env.token)
      .then((response) => {
        try {
          try {
            sendServerLog(
              "StartStreaming. Function <connectStreaming()>. Line 4463.",
              response
            );
          } catch (error) {
            sendServerLog(
              "Error sending log. StartStreaming. Function <connectStreaming()>. Line 4468."
            );
          }

          const {
            data: {
              data: { token },
            },
          } = response;

          setStreamingState(response);

          connectWithTwilio(token, true);
        } catch (e) {
          sendServerLog(
            "Error parsing data connecting to the session connectStreaming. Function <connectStreaming()>. Line 1572.",
            e
          );
        }
      })
      .catch((error) => {
        // handle error
        setSessionConnected(false);
        setstreamingOn(false);
        try {
          setSessionError(
            i18next.t(
              "PreviewStreamingInfluencerComponentThereWasAProblemConnectingToSessionGotmyError"
            ) +
              error.response.data.error[0].error_code +
              "\n" +
              error.response.data.error[0].message +
              i18next.t(
                "PreviewStreamingInfluencerComponentThereWasAProblemConnectingToSessionGotmyErrorIfErrorPersist"
              )
          );
        } catch (error) {
          setSessionError(
            i18next.t(
              "PreviewStreamingInfluencerComponentErrorConnecctingContactSupport"
            )
          );
        }
        //updateReconnectionCounter();

        //setSessionError("There was a problem connecting to the session. Reload the website and try later. If error persist contact with gotmy support.");
        sendServerLog(
          "Error obtaining the session token. Function <connectStreaming()>. Line 1581.",
          error
        );
      });
  };

  const connectStreamingViewer = async () => {
    const env = JSON.parse(localStorage.getItem("env"));
    try {
      eventDAO
        .joinStreaming(uuid, env.token)
        .then((response) => {
          if (!response.data.success) {
            setSessionError(response.data.error[0].message);
            return null;
          } else {
            sendServerLog(
              "joinStreaming log. Function <joinStreaming()>. Line 4551.",
              response
            );
            const {
              data: {
                data: { token },
              },
            } = response;

            setStreamingState(response);

            console.log("connectStreamingViewer")

            connectWithTwilio(token, false);
          }
        })
        .catch(function(error) {
          // handle error
          setSessionConnected(false);
          setstreamingOn(false);
          setSessionReset(true);
          setStartingStreaming(false);
          setOVObject(null);
          sendServerLog(
            "Error obtaining session token with the server. Function <connectStreamingViewer()>. Line 1675.",
            error
          );
          try {
            setSessionError(
              error.response.data.error[0].message +
                "\nReload the website and try later."
            );
          } catch (error) {
            sendServerLog(
              "Error setting local error session. Function <connectStreamingViewer()>. Line 1681.",
              error
            );
            setSessionError(
              i18next.t(
                "PreviewStreamingInfluencerComponentErrorConnectingReload"
              )
            );
          }
        });
    } catch (error) {
      sendServerLog(
        "Error in connectStreamingViewer(). Function <connectStreamingViewer()>. Line 1687.",
        error
      );
    }
  };

  const reconnectSession = async (locationCalled) => {
    updateReconnectionCounterAndDisplay();
    resetStreamingState();
  };

  const reconnectSessionIntern = () => {
    reconnectCountdownCompleteState = true;
    startStreaming(true);
  };

  const reconnectCountdownComplete = () => {
    clearTimeout(reconnectCountdownCompleteTimer);
    reconnectCountdownCompleteState = false;
    reconnectCountdownCompleteTimer = setTimeout(reconnectSessionIntern, 4000);
  };

  const updateConnectedViewers = async (subcriberList = null) => {
    const response = await eventDAO.findById(uuid);

    let attendees_data = [];

    try {
      const { attendees_users } = response.data.data;
      attendees_data = attendees_users;
    } catch (error) {
      sendServerLog(
        "Error in updating connected viewers. Function <updateConnectedViewers()>. Line 1755.",
        error
      );
      attendees_data = [];
    }

    let subscriberData = subcriberList;

    if (!subscriberData) {
      subscriberData = subscribers;
    }

    const viewersConnected = mapConnectedViewersFromSubscribers(
      subscriberData,
      attendees_data
    ); //mapConnectedViewers(Object.keys(session.remoteConnections), eventattendees);

    setViewersConnected(viewersConnected);

    updateActiveUsers(subscriberData);
  };

  const composeNewConnectionToastMessage = (
    subscriber,
    hasJoinedMessage = true
  ) => {
    let subscriberUsername = getUsernameFromSubscriber(subscriber);
    let shareScreenStructure = subscriberUsername.split("-");

    if (
      subscriberUsername &&
      (shareScreenStructure.length > 1 &&
        shareScreenStructure[[shareScreenStructure.length - 1]] ==
          "ScreenShare")
    ) {
      //nothing to do
    } else {
      let message = "";

      if (hasJoinedMessage) {
        if (subscriberUsername) {
          message = (
            <p>
              <span className="newSubscriberConnection">
                {subscriberUsername}{" "}
              </span>{" "}
              joined the streaming!{" "}
            </p>
          );
        } else {
          message = "New viewer joined the streaming!";
        }
      } else {
        if (subscriberUsername) {
          message = (
            <p>
              <span className="newSubscriberConnection">
                {subscriberUsername}{" "}
              </span>{" "}
              left the streaming!{" "}
            </p>
          );
        } else {
          message = "Viewer left the streaming!";
        }
      }

      toast(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        closeButton: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const composeAlertToast = (message, typeToast, position = "top-right") => {
    switch (typeToast) {
      case "error":
        toast.error("⚠️ " + message, {
          position: position,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case "waring":
        toast.warn("⚠️ " + message, {
          position: position,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case "info":
        toast.error("⚠️ " + message, {
          position: position,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        toast("⚠️ " + message, {
          position: position,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
    }
  };

  const getUsernameFromSubscriber = (subscriber) => {
    let username = null;
    try {
      username = subscriber.identity;
    } catch (error) {
      sendServerLog(
        "Error getUsernameFromSubscriber. Function <getUsernameFromSubscriber()>. Line 1817.",
        error
      );
      username = null;
    }

    return username;
  };

  const updateReconnectionCounter = () => {
    setRetryingAttempt(retryingAttempt + 1);
    if (reconnectCountDownLength < MAX_RECONNECTCOUNTDOWN) {
      setReconnectCountDownLength(reconnectCountDownLength + 5);
      return reconnectCountDownLength + 5;
    } else {
      return reconnectCountDownLength;
    }
  };

  const updateReconnectionCounterAndDisplay = () => {
    setIsreconnecting({
      status: true,
      previousStatus: isReconnecting.status,
    });

    updateReconnectionCounter();
    setShowConnectionRetrying(true);
    return reconnectCountDownLength + 5;
  };

  const updateCurrentDevices = (
    newAvailableDevices,
    currentAvailableDevices = [],
    paramCurrentAudioDevice,
    paramCurrentVideoDevice
  ) => {
    const localAvailableDevices = newAvailableDevices;
    const localCurrentAudioDevice = paramCurrentAudioDevice;
    const localCurrentVideoDevice = paramCurrentVideoDevice;

    console.log(
      "updateCurrentDevices",
      newAvailableDevices,
      currentAvailableDevices,
      paramCurrentAudioDevice,
      paramCurrentVideoDevice
    );

    const isCurrentAudioDeviceInTheList = localAvailableDevices
      .filter((item) => item.kind === "audioinput")
      .find(
        (audioDevice) =>
          audioDevice.deviceId === localCurrentAudioDevice.deviceId
      );

    if (!isCurrentAudioDeviceInTheList) {
      const availableAudioDevicesLenght = localAvailableDevices.filter(
        (item) => item.kind === "audioinput"
      ).lenght;

      composeAlertToast(i18next.t("MicrophoneUnpluggedWarning"), "error");

      if (availableAudioDevicesLenght > 0) {
        //Select new "default" audio input TODO
        //hasAudioTracks.current = true;
      }else{
        hasAudioTracks.current = false;
      }
    }

    const isCurrentVideoDeviceInTheList = localAvailableDevices
      .filter((item) => item.kind === "videoinput")
      .find(
        (videoDevice) =>
          videoDevice.deviceId === localCurrentVideoDevice.deviceId
      );

    if (!isCurrentVideoDeviceInTheList) {
      const availableVideoDevicesLenght = localAvailableDevices.filter(
        (item) => item.kind === "videoinput"
      ).lenght;
      composeAlertToast(i18next.t("CameraUnpluggedWarning"), "error");

      if (availableVideoDevicesLenght > 0) {
        //Select new "default" video input TODO
        hasVideoTracks.current = true;
      }else{
        hasVideoTracks.current = false;
      }
    }
  };

  useEffect(() => {
    initEventData();
    addConnectionListener();
    if (isMobile) {
      document.body.style.overflowY = "hidden";
      document.body.style.overflowX = "hidden";
      document.body.style.backgroundColor = "black";
    }

    navigator.mediaDevices.ondevicechange = async function(event) {
      const devices = await getMediaDevicesAsync();

      try {
        updateCurrentDevices(
            devices,
            availableDevicesRef.current,
            currentAudioDeviceRef.current,
            currentVideoDeviceRef.current
        );
      } catch (error) {
        console.error("ondevicechange error", error);
      }

      //updateDeviceList();
    };
    getMediaDevices(true);

    DetectRTC.load();

    if (DetectRTC.browser.isFirefox) {
      setSelectStreamingDevices({
        ...selectStreamingDevices,
        //disableChangeCamera: true,
        disableChangeAudioOutputDevices: true,
      });
    }

    return () => {
      if (isMobile) {
        document.body.style.overflowY = "auto";
        document.body.style.overflowX = "auto";
        document.body.style.backgroundColor = "white";
      }
      try {
        navigator.mediaDevices.ondevicechange = null;
        session.disconnect();
      } catch (error) {
        console.error(error);
      }

      onLeaveLiveEvent(false);

      window.location.reload(false);
      //disconnectEvent();
    };
  }, []);

  const addConnectionListener = () => {};

  return {
    eventData,
    session,
    minimisedChat,
    setMinimisedChat,
    startStreaming,
    isRemoteApp,
    chats,
    activeUsers,
    viewersConnected,
    message,
    setMessage,
    onSendMessage,
    onLeaveLiveEvent,
    disconnectEvent,
    microphone,
    webcam,
    setMicrophone,
    onSetMicrophone,
    onSetWebcam,
    startingStreaming,
    onDeleteMessage,
    onBlockedChat,
    enableRiseHand,
    enabledOpenMic,
    onSetEnableRiseHand,
    onSetEnableOpenMic,
    onSetEnableChat,
    onStopRemoteCall,
    showChooseRaiseHand,
    setShowChooseRaiseHand,
    viewersWaiting,
    givePermissionsViewerStreaming,
    onRiseHandCanceled,
    sendingMessage,
    mainStreaming,
    myPublisher,
    userNameRaiseHand,
    mySpeaking_viewer,
    resourcesLoaded,
    subscribers,
    shareScreenSource,
    shareScreen,
    isSharingScreen,
    stopShareScreen,
    enabledChat,
    sessionError,
    sessionConnected,
    sessionLoading,
    setSessionError,
    sessionReset,
    isCreator,
    hasTurn,
    viewerPublisher,
    onSetMicrophoneViewer,
    onSetWebcamViewer,
    onSetRiseHand,
    risedHand,
    microphoneViewer,
    webcamViewer,
    setStreamingFinished,
    streamingFinished,
    feedBackText,
    setFeedBackText,
    onSendFeedback,
    feedBackScore,
    setFeedBackScore,
    renderRedirect,
    setRenderRedirect,
    showDisconnectDialog,
    setShowDisconnectDialog,
    onLeaveEventStandard,
    disabledStartStreaming,
    getMediaDevices,
    selectVideoAndAudioDevice,
    availableDevices,
    currentVideoDevice,
    currentAudioDevice,
    reconnectSession,
    showConnectionRetrying,
    reconnectCountDownLength,
    reconnectCountdownComplete,
    retryingAttempt,
    setNetworkStatus,
    networkStatus,
    currentOutputAudioDevice,
    selectStreamingDevices,
    isChangingSettings,
    toggleStreamingOptionsModal,
    audioTracks,
    publications,
    remotePublications,
    influencerConnected,
    screenShareTracks,
    currentDominantSpeaker,
    removeLocalAudioTrack,
    getLocalAudioTracks,
    composeAlertToast,
  };
};
