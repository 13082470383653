import React, { useState, useEffect } from "react";
import BeginsIn from "./BeginsIn";
import useWindowSize from "../../hooks/useWindowSize";
import playButton from "assets/img/playButton.png";
import styled from "styled-components";
const PreviewBox = ({
  mobileNav,
  event_date,
  startIn,
  purchased,
  isCreator,
  eventType,
  upcoming,
  live,
  event_uuid,
  event_cover_image,
  event_presentation_video,
}) => {
  const size = useWindowSize();
  const [boxHeight, setBoxHeight] = useState();
  const [playingPitch, setPlayingPitch] = useState(
    event_presentation_video !== null &&
      event_presentation_video !== undefined &&
      event_presentation_video !== ""
  );
  useEffect(() => {
    if (size.width < 1600) {
      setBoxHeight("425px");
    } else if (size.width > 1600) {
      setBoxHeight("500px");
    }
  }, [size]);
  const handlePlayVid = (val) => {
    setPlayingPitch(val);
  };

  return (
    <div
      className={`${!mobileNav && "mt-4"}`}
      style={{
        marginTop:
          mobileNav || playingPitch
            ? "0px"
            : (isCreator || purchased) && "-60px",
      }}
    >
      {!playingPitch ? (
        <EventImg>
          {true && (
            <BeginsIn
              event_date={event_date}
              upcoming={upcoming}
              live={live}
              purchased={purchased}
              isCreator={isCreator}
              startIn={startIn}
              event_uuid={event_uuid}
              eventType={eventType}
            />
          )}
          <img
            style={{
              maxHeight: boxHeight ? `${boxHeight}` : "425px",
              objectFit: "cover",
              marginTop: (!purchased || !upcoming) && "0px !important",
            }}
            className={`w-100 br-10 `}
            src={event_cover_image}
            alt=""
          />
          {event_presentation_video && (
            <img
              onClick={() => handlePlayVid(true)}
              src={playButton}
              className="playButton grow"
            />
          )}
        </EventImg>
      ) : (
        <video
          style={{
            width: "100%",
            maxHeight: boxHeight ? boxHeight : "425px",
            marginBottom: "-7px",
            backgroundColor: "black",
            objectFit: "contain",
          }}
          className=" mt-1 w-100 br-10 "
          controls={false}
          autoPlay={true}
          onEnded={() => handlePlayVid(false)}
        >
          <source src={event_presentation_video}></source>
        </video>
      )}
    </div>
  );
};

export default PreviewBox;

const EventImg = styled.div`
  position: relative;
  margin: 0 auto;
  .playButton {
    position: absolute;
    height: 65px;
    width: 65px;
    left: 22px;
    bottom: 22px;
    :hover {
      cursor: pointer;
    }
  }
`;
