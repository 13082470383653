// hook to simplify the referral component.
import {useState, useEffect} from 'react'
import useScreenSize from "../../hooks/useScreenSize"

 const useSizeFactors = () => {
    const [sizeFactor, setSizeFactor] = useState(1)
    const [inAppBrowse, setInAppBrowse] = useState(false)
    const { screenSize, ultraWide, width } = useScreenSize()

    function isFacebookApp() {
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    }

    useEffect(() => {
        const isFacebook = isFacebookApp()
        const browser = navigator.userAgent.toLowerCase()
        if (isFacebook || browser.includes("instagram") || browser.includes("linkedin") || browser.includes('discord') || browser.includes('facebook') || browser.includes("slack")) {
            setInAppBrowse(true)
        }
    }, [])

    useEffect(() => {
        if (screenSize) {
            var sizeFactor
            if (screenSize === "sm") {
                sizeFactor = 0.7
            }
            else if (screenSize === "md") {
                sizeFactor = 0.8
            }
            else if (screenSize === "lg") {
                sizeFactor = 0.9
            }
            else {
                sizeFactor = 1
            }
            setSizeFactor(sizeFactor)
        }

    }, [screenSize])


    return {sizeFactor, inAppBrowse, screenSize , ultraWide, width}
}
export default useSizeFactors