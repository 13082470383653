import {useState, useEffect} from 'react'
import useWindowSize from './useWindowSize'
const useMobileNav = ()=> {
    const windowSize = useWindowSize()
    const { width } = windowSize
    const [screenSize, setScreenSize] = useState("xl")
    const [ultraWide, setUltraWide] = useState(false) // trick to not create an xxl size(that would mean modifying existing xl conditions)
    useEffect(() => {
      if (width <= 900) {
        setScreenSize("sm")
        setUltraWide(false)

      }
      else  if (width>900 && width<1024){
        setScreenSize("md")
        setUltraWide(false)

      }
      else  if (width>1024 && width<1280){
        setScreenSize("lg")
        setUltraWide(false)

      }
      else if (width> 1280 && width<1600){
        setScreenSize("xl")
        setUltraWide(false)
      }
      else {
        setScreenSize("xl")
        setUltraWide(true)
      }
    }, [width])
  return {screenSize, ultraWide, width}
}

export default useMobileNav