import React, { Component } from 'react';
import cinco_estrellas from '../../../assets/img/5-estrellas.png';
import './style.scss';
import Stars from "components/comunes/ratings/stars"
import i18next from 'i18next';

function renderBasedOnSomeReviews(reviews) {
    if (reviews !== undefined && reviews !== null) {
        return (
            <p className="reviews mt-1">{i18next.t("RatingPointsComponentBasedOn")} {reviews} {i18next.t("RatingPointsComponentReviews")}</p>
        );
    } else {
        return null;
    }
}

const RatingPoints = (props) => {

    return (
        <div style={{ alignItems: 'start' }} className=" my-2   ">
            <Stars score={props.score ? Math.round(props.score) : 0} />
            <div className=" ">
                {renderBasedOnSomeReviews(props.reviews)}
            </div>
        </div>

    );

}

export default RatingPoints;

