import { useState, useEffect } from "react";

const useViewerTimezone = (user) => {
  const [viewerTimezone, setViewerTimezone] = useState("");

  useEffect(() => {
    if (user && user.user_location) {
      const userTimezone = JSON.parse(user.user_location);

      try {
        setViewerTimezone(userTimezone.data.zoneName);
      } catch (error) {}
    }
  }, [user]);
  return viewerTimezone;
};
export default useViewerTimezone;
