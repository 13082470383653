import {useEffect, useState} from 'react'
import { loadStripe } from "@stripe/stripe-js";
import {  STRIPE_PUBLIC_KEY} from '../environment/environment'

const useStripeObject  = ()=> {
    const [stripePromise, setStripeObject] = useState()

    useEffect (()=> {
        const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
        setStripeObject(stripePromise)
    },[])
    return stripePromise
}
export default useStripeObject