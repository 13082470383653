import React, {Component} from 'react';
import NavBar_general from "../../comunes/navbar";
import NavBar_Messages from "../../comunes/navbar_messages"
import './style.scss';
import Search_input_messages from "../../comunes/search_input_messages";
import Chat from "../../comunes/chat_demo";
import FilteredList from "../../comunes/chat"
class Message extends Component {
    constructor() {
        super();
        this.state = { items: [] };
        this.state = {
          showMe: true
        }    
        this.state = {
          hideToolTip: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.tooltipInstance = (
          <div onClick={this.handleChange}>
            <tooltip placement="bottom" className="in" id="tootltipbottom">
              Tooltip botton
            </tooltip>
          </div>
        );
      }
      handleChange(event){
        this.setState({hideToolTip:true});
      } 
      handleChange(){
        var x = document.getElementsByClassName("caja_none");
        x.style.display = "none";
        var x = document.getElementsByClassName("xd");
        x.style.display = "none";
      }
      operation(){
        this.setState({
          showMe:!this.state.showMe
        })
      }
    render() {
      const style = this.state.hideToolTip?{display:'none'}: {};
        return ( 
        <div class="messages_details">
        <header className="App-header">
            <NavBar_general/>
            <NavBar_Messages/>
        </header>
    <section className="container-fluid">
        <div className="row">
          <div class="chat2 col-3 ml-3">
            <div className="d-flex"><h2 className="title_messages">Messages</h2>
            <button className="Btn-rojo group-chat" onClick={()=>this.operation()}>Create group chat</button></div>
            {/* <textarea placeholder="Type a message here" class="form-control chat" rows="5" id="comment"></textarea> */}
            <Search_input_messages/>
            <FilteredList/>
            <Chat/>
          </div>
          <div className="col-5 date"><div className="bar"></div><p className="text-center ml-3">Today</p><div className="bar"></div></div>
         
        </div>
      </section>
    </div>
        );
    }
}

export default Message;
