import React, { useState, useEffect } from 'react';
import queryString from 'query-string'
import axios from "axios"
import { Api, SECOND_ACCESS_TOKEN_API, LOGIN_BACK_URL, INFLUENCER_ROLE } from "../../environment/environment"
import SweetAlert from "sweetalert2-react";
import * as actions from "../../store/actions/user";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal_login from './modal_login/index'
import { parseLocale } from 'utils/Localization'
import { UserDAO , EventDAO} from "daos";
const eventDAO = new EventDAO();
const userDAO = new UserDAO();

const autoInfluencer = async (token) => {
  try {
    const { data } = await axios.post(
      `${Api}influencer/auto_upgrade`,
      {},
      { headers: { "Access-Token": token, "Content-Type": "application/json" } }
    );
    localStorage.setItem("autoUpgrade", JSON.stringify({ status: true }))
    console.log({ data })
  }
  catch (e) {
    console.log(e)
  }
}

const InstagramPage =  (props)=> {
  console.log ({props})
  const [show, setShow] = useState(true)
  const [message, setMessage] = useState("")


  useEffect(()=>{
    //verifyFacebook()
  }, [])

  const verifyFacebook = async()=>{
    const values = queryString.parse(props.location.search)
    const code = values.code
    const redirect_url = LOGIN_BACK_URL + "facebook";
    const action = localStorage.getItem("facebookAction");
    const redirectUrlVerify = localStorage.getItem("redirectUrl");
    const path = window.location.pathname

    console.log({ path, redirect_url, action, code, redirectUrlVerify })
    if (action === 'login') {
      var referral = JSON.parse(localStorage.getItem("referral"))
      var landingTalentToken = JSON.parse(localStorage.getItem("LandingTalent"))
      
      const referral_code = referral ? referral.id : null

      if (code && (path === "/facebook/" || path === "/facebook")) {
        console.log ("calling login_facebook")
        axios.post(`${Api}auth/login_facebook`, { code: code, redirect_url: redirect_url, ...(referral_code) && { referral_code } }, {
          headers: { "Access-Token": SECOND_ACCESS_TOKEN_API, "Content-Type": "application/json" ,  "Content-Language": parseLocale()
        }
        }).then(async res => {
          let { data } = res;
          const env = {
            token: data.data.user_app_token
          };

          localStorage.setItem("env", JSON.stringify(env));
          if (referral_code || landingTalentToken === true) {
            await autoInfluencer(env.token)
            data.data.user_role = INFLUENCER_ROLE

            localStorage.removeItem("LandingTalent");

          }
          const purchasedEvents = await eventDAO.purchasedEvents(env.token);
          data.data.purchasedEvents= (purchasedEvents.data && purchasedEvents.data.data) || []; 
    
          props.setUser(data.data)
          props.getInfluencer(env.token)
    
    
          localStorage.setItem("env", JSON.stringify(env));
    
          let autoInf = JSON.parse(localStorage.getItem("autoUpgrade"))
          if (autoInf && autoInf.status === true) {
            console.log ("AUTOINF")
            // redirect
            localStorage.removeItem("referral")
            props.history.push("/publish_event")
          }

          else if (data.data.user_role === INFLUENCER_ROLE ) {
            props.history.push('/my_live_events')
          }
          else if ((!data.data.user_name || !data.data.user_lastname)) {
            props.history.replace("viewer_profile");
          }
          else {
            props.history.push(`/`)
          }
        })
          .catch(err => {
            console.log ("----------------------")
            console.log ({err})
            console.log ("----------------------")

            props.setError("There was a problem connecting with Facebook.") // USAR PARA ERROR 
            props.history.push("/");
            // document.location.reload();
          })

      }
    }
    else if (action === 'verify') {

      if (!code) return
      var myToken = JSON.parse(localStorage.getItem("env"));
      try {
        axios.post(`${Api}user/verify_facebook`, { code, redirect_url: redirect_url },
          {
            headers: {
              "Access-Token": myToken.token,
              "Content-Type": "application/json",
              "Content-Language": parseLocale()

            }
          }
        ).then(res => {
          setMessage('Your Facebook profile was verified!')
        })

      } catch (err) {
        props.setError("There was a problem connecting with Facebook.") // USAR PARA ERROR 
      } finally {
        props.history.push(redirectUrlVerify ? redirectUrlVerify : '/');
        props.onGetUser(myToken.token)
        localStorage.removeItem("facebookAction");
        localStorage.removeItem("redirectUrl");
      }
    }
    else {
      // alert ("MESSED UP")
    }
    
  }
 

  return (
    <Modal_login
      props={props}
    />
  );
  
}


const mapDispatchToProps = dispatch => {
  return {
    onGetUser: token => dispatch(actions.getUser(token)),
    getInfluencer: token => dispatch(actions.getInfluencer(token)),
    setUser: data => dispatch(actions.setUser(data)),

    setError: error => dispatch(actions.setError(error))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(InstagramPage));

