import { UserDAO } from "daos";
import { useEffect, useCallback, useState } from "react";

const userDAO = new UserDAO();

export const useMenuInfluencerLogic = () => {
  const [activities, setActivities] = useState([]);
  const { token } = JSON.parse(localStorage.getItem("env") || "{}");
  const initActivity = useCallback(async () => {
    const { data } = await userDAO.getMyAcitivtyInfluencer(token);
    setActivities(data.data.reverse());
  }, [token]);
  useEffect(() => {
    initActivity();
  }, [initActivity]);
  return { activities };
};
