import React, {Component} from 'react'
import './style.scss';
import Paypal from '../../../assets/img/icons/Paypal.svg';
import Stripe from '../../../assets/img/icons/Striple.svg';
class Verify extends Component {
    render(){
        return(
            <div className="container verify">
                <div className="row">
                    <div className="col-1 mr-5"></div>
                    <div className="col-4">
                        <div className="phone_number">
                            <h4>what's your number phone?</h4>
                            <div className="form-group d-flex">
                        <div>
                            <p className="mb-2 country_code grey" htmlFor="country code">country code</p>
                            <select className="modal_register_verifyxd mr-3">
                                <option value="0">0034</option>
                                <option value="1">0035</option>
                            </select></div>
                        <div className="country_select">
                        <div><p className="mb-2 mobile grey" htmlFor="mobile">Mobile phone number</p></div>
                            <input className="modal_register_verify"/>
                             </div>
                        </div>
                        </div>
                        <div className="based demo3">
                            <form>
                               <h5>Where are you based?</h5>
                               <div className="d-flex">
                               <input type="radio" id="acepto" name="acepto"/>
                                <label for="acepto"><span></span>I'm a usa person corporation</label>
                               {/* <p className="ml-3 mt-n1">I'm a usa person corporation</p> */}
                               </div>
                               <div className="d-flex">
                               <input type="radio" id="acepto2" name="acepto"/>
                                <label for="acepto2"><span></span>I'm a NON-usa person corporation</label>
                               
                               </div>
                               </form>
                               <form>
                               <h5>What are you?</h5>
                               <div className="d-flex">
                               <input type="radio" id="acepto3" name="acepto"/>
                                <label for="acepto3"><span></span>I'm an individual</label>
                               </div>
                               <div className="d-flex">
                               <input type="radio" id="acepto4" name="acepto"/>
                                <label for="acepto4"><span></span>I'm a corporation or represents a corporation</label>
                               </div>
                               </form>
                           </div>
                    </div>
                    <div className="col-7 method">
                        <div className="method_payment demo3">
                            <form>
                            <h5>How do you like to get paid?</h5>
                            <div className="d-flex information_detalles"><img src={Stripe}/><div><p className="m-0">Stripe Direct Deposit</p><p className="price_information">Fee is $ 0.25 per payout</p></div> <input type="radio"  id="acepto5" name="acepto"/> <label className="mt-2" for="acepto5"><span></span></label></div>
                            <div className="d-flex information_detalles"><img src={Paypal}/><div><p className="m-0">Paypal</p><p className="price_information">Fee is 1% per transferred, minimun 0.25$</p></div> <input type="radio"  id="acepto6" name="acepto"/> <label className="mt-2" for="acepto6"><span></span></label></div>
                            <h5>TAX information</h5>
                            <p className="mb-3">Current tax year gross earnings $36<br/> gotmy requires W-9 forms for US-based influencers who have earned<br/> above a certain amount of money from gotmy in a given tax years. This <br/> amounts $600 for influencers who reside in Massachusetts of Vermonc <br/> and $20.000 o somewhere </p>
                            
                            <p className="mb-1">Fill out W-9 form</p>
                            <p className="url_irs">https://www.irs.gov/pub/irs-pdf/fw9.pdf</p>
                            <br/>
                            <div className="d-flex">
                                <input type="checkbox"/>
                                <p className="ml-3 mt-n1">By creating account you accept the <br/> <span className="url_irs">Terms and Conditions</span></p>
                                
                            </div>
                            <div className="d-flex">
                            <input type="checkbox"/>
                                <p className="ml-3 mt-n1">By creating account you accept the <br/> <span className="url_irs">Policy Privacy</span></p>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Verify;