import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import Navbar from "components/comunes/navbar_request";
import { useForm } from "react-hook-form";
import SelectMeetingDetails from 'components/comunes_events/privateRequestForm/SelectMeetingDetails'
import { reasons } from 'utils/requestUtils'
import { EventRequestDAO } from "daos";
import { WithAlert } from "../../hocs/withAlert";
import useEditRequest from '../../hooks/useEditRequest'
import RequestDetail from './RequestDetail'
import useUserHour from '../../hooks/useUserHour'
import * as momenttz from 'moment-timezone';
import { getFormattedTime, addHourOffset } from "utils";
import useTimezone from '../../hooks/useTimezone'
import useTimezoneDifference from '../../hooks/useTimezoneDifference'
import useViewerTimezone from '../../hooks/useViewerTimezone'
import useMobileNav from '../../hooks/useMobileNav'

const eventRequestDAO = new EventRequestDAO();

const RequestEdit = ({ match, showAlert, history }) => {
    const {mobileNav} = useMobileNav()
    const currencies = useSelector((state)=>state.currencies.currencies)
    const { params: { id } } = match
    const { register, control, handleSubmit, errors, setError, getValues } = useForm();
    const { requestData, loading } = useEditRequest(id)
    const { influencer, request, user } = requestData
    const [initialFormValues, setinitialFormValues] = useState({})
    const [mainReason, setMainReason] = useState(reasons[0]);
    const [selectedOption, setSelectedOption] = useState();
    const [loadingInitialValues, setLoadingInitialValues ]=  useState(true)
    const [loadingTimes, setLoadingTimes ]=  useState(true)
    const [loadingDate, setLoadingDate ]=  useState(true)
    const { request_date, request_start_hour, request_end_hour } = request ? request : '';
    const [startTime, setStartTime] = useState('')
    const [startTimeStr, setStartTimeStr] = useState('')
    const [endTime, setEndTime] = useState('')
    const { timeZone } = useTimezone()
    const viewerTimezone = useViewerTimezone(user)
    const { difference } = useTimezoneDifference({ timezone1: viewerTimezone })
    const { timeStrings } = useUserHour({ request_date, request_start_hour , difference})
    /* Setting start and end time in Date format. [Date object ] */
    useEffect(() => {
            let timeStr, startArray, endArray
            let startTime = new Date()
            let endTime = new Date()
           
            if (typeof difference==="number"){
                    

                if ((timeStrings && Object.keys(timeStrings).length>0)   && (request_start_hour && request_start_hour.length>0)) {
                    timeStr = timeStrings.time
                    
                    startArray = timeStr.split(':')
                    startTime.setHours(parseInt(startArray[0]), parseInt(startArray[1]))
                    setStartTime(startTime)
                    setStartTimeStr (timeStr)
                }
                else {
                    setStartTime('')
                    startTime= ""
                }
                if (request_end_hour && typeof difference==='number') {
                    endArray = request_end_hour.split(':')
                    endTime.setHours(addHourOffset(parseInt(endArray[0]), difference), parseInt(endArray[1]))
                    setEndTime(endTime)
                }
                else {
                    setEndTime('')
                    endTime= ""
                }
                setinitialFormValues({ ...request, startTime, endTime })
            }
            else {
                setinitialFormValues({...request})
            }

        
    }, [request_end_hour,request_start_hour, timeStrings, difference])

    useEffect(()=> {
        if ((!loadingDate) && (!loadingTimes))
        {
            setLoadingInitialValues(false)
        }
    },[loadingTimes, loadingDate])
   
    /* Effect to load the times in  influencer POV. */
    useEffect(()=>{

        if ((((initialFormValues.startTime && initialFormValues.endTime && (typeof difference ==='number')) || 
        (timeStrings && (initialFormValues.startTime==="" && (!request_start_hour || request_start_hour.length===0))) ||
       ( Object.keys(initialFormValues).length>2 && (request_start_hour ===null && request_end_hour===null))))){
           setLoadingTimes(false)
       }
    },[initialFormValues, difference])
    
    /* Effect to confirm load of  the date in  influencer POV. */
    useEffect(()=>{
        
        if (Object.keys(initialFormValues).length>2 && Object.keys(timeStrings).length>0){

            setLoadingDate(false)
        }
    },[initialFormValues])
    const getDatesInStrings = (eventDate, startTime) => {
        if (eventDate && startTime) {
            let startTimeStr = getFormattedTime(startTime, 0)
            let hourMin = startTimeStr.split(':')
            eventDate.setHours(hourMin[0], hourMin[1])

            //Get month and days in 2 chars
            let month = (eventDate.getMonth() + 1) > 9 ? (eventDate.getMonth() + 1) : '0' + (eventDate.getMonth() + 1)
            let day = (eventDate.getDate()) > 9 ? (eventDate.getDate()) : '0' + (eventDate.getDate())
            let dateString = eventDate.getFullYear() + "-" + (month) + "-" + day + ' ' + startTimeStr;
            return ( dateString);
        }
    }

    const onSubmit = async (data) => {
        let utcDate, dateString, endTimeStr, startTimeStr
        // 
        if (data.ReactDatepicker) {
            dateString = getDatesInStrings(data.ReactDatepicker, data.TimePickerStart)
            utcDate = momenttz.tz(dateString, timeZone).valueOf().toString();
            endTimeStr = getFormattedTime(data.TimePickerEnd, -difference)
            startTimeStr = getFormattedTime(data.TimePickerStart, -difference)
        }
        const dataSent = {
            influencer_ide: influencer.influencer_ide,
            request_type: (selectedOption && Object.keys(selectedOption).length > 0) ? selectedOption.optionId : mainReason.request_type,
            request_reason: data.event_description,
            request_purpose: 'Private Request',
            request_num_attendees: 1,
            request_price_ticket: parseInt(data.price),
            // request_ticket_type: parseInt(data.price) > 0 ? 2 : 5,
            request_ticket_type: parseInt(data.price) > 0 ? 11 : 10,

            request_ide: requestData.request.request_ide,
            ...(startTimeStr && startTimeStr.length > 0) && { request_start_hour: startTimeStr },
            ...(endTimeStr && endTimeStr.length > 0) && { request_end_hour: endTimeStr },
            ...(utcDate && utcDate.length > 0) && { request_date: utcDate },
        };
        
        const { token } = JSON.parse(localStorage.getItem("env"));
        await eventRequestDAO.modifyEventRequestInfluencer(token, dataSent);
        showAlert("Request modified!");
        history.push('/private_meeting')

    }
    return (
        <div class="PrivateMeeting">
            {!loading &&
                <form>
                    <header className="App-header">
                        <Navbar onSend={handleSubmit(onSubmit)} />

                    </header>
                    <section className="container">
                        <div className="d-flex justify-content-center" style={{ paddingTop: '36px', width: '100%' }}>

                            <div className="d-none d-md-inline-block md:col-4 target_request">
                                <RequestDetail currencies={currencies} data={requestData} startTimeStr={startTimeStr} timeStrings={timeStrings}/>

                            </div>
                            <div style={{ marginLeft: !mobileNav&& '6rem' }} className=" col-md-5 p-2 target_request">
                                <SelectMeetingDetails date={timeStrings?timeStrings.day:""} month={timeStrings?timeStrings.numericMonth:""} data={requestData}  loading={loadingInitialValues} editing startTime={startTime} setStartTime={setStartTime}
                                    endTime={endTime} setEndTime={setEndTime} viewerTimezone={viewerTimezone} errors={errors} getValues={getValues} control={control}
                                    register={register} initialValues={initialFormValues} purposeActive={false} />
                            </div>
                        </div>

                    </section>
                </form>
            }

        </div>
    )
}


export default withRouter(WithAlert(RequestEdit))
