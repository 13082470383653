import React from 'react'
import ProfileSocial from "../../comunes_events/profile_social";
import styled from 'styled-components'
import i18next from 'i18next';

const AvaliableBox = ({ isInfluencer, available, handleAvailable }) => {
    return (
        <div >
            {isInfluencer && (
                <AvaliableDiv>
                    <h4>{i18next.t("AvailableBoxComponentAreYouAvailable")}</h4>
                    <p>
                    {i18next.t("AvailableBoxComponentAdvice")}</p>
                    {available !== undefined &&
                        <Button onClick={handleAvailable}>
                            {!available ? i18next.t("I'm unavailable") : i18next.t("AvailableBoxComponentUnavailable")}
                        </Button>
                    }
                </AvaliableDiv>
            )}
       

        </div>
    )
}
export default AvaliableBox

const Button = styled.button`
border-radius: 50px;
border: 1px solid black ;  
padding: 6px 18px;
font-size: 18px;
background-color: white;
margin-bottom: 12px;
:focus{
outline: none;
  box-shadow: none;
}
`
const AvaliableDiv = styled.div`
border: 1px solid #d8dce5;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  h3,h4 {
      margin-left:0px;
  }
`
