import React from 'react';
// import React, {Component, useState} from 'react';
import './style.scss';
import NavBar_liveEvents from "./navbar_profile";
import ViewerProfile2 from "./my_public_profile";
import FavoriteCategories from "./my_favorite_categories";
import {Switch,Route} from "react-router-dom";
function ViewerProfile(props) {
    return (
        <div className="Live-Events-Page">
            <NavBar_liveEvents />
            <Switch>
                <Route path="/viewer_profile/categories" exact component={FavoriteCategories}/>
                <Route path="/viewer_profile" exact component={ViewerProfile2}/>
                <Route path="/influencer_profile/:active"  exact component={ViewerProfile2}/>
            </Switch>
        </div>
    );
}

export default ViewerProfile;