import React, { useContext } from "react";
import "./style.scss";
import HomeHeader from "./header/index";
import Pasos from "./pasos/index";
import LiveEvents from "../comunes/liveEvents/index";
import UpcomingLiveEvents from "../comunes/upcomingLiveEvents";
import DiscoverByCat from "../comunes/discoverByCategory";
import Video from "../comunes/videos_seccion";
import { connect } from "react-redux";
import { SignContext } from "../../Sign";
import { Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import i18next from 'i18next';

function Home({ user, loading }) {

  const [sign] = useContext(SignContext);
  return (
    <div className="Home">
      {sign ? (
        <div>
          <Redirect to="/viewer_profile" />
        </div>
      ) : null}
      <HomeHeader />
      {user && (Object.keys(user).length === 0 && !loading) ? (
        <div>
          <Pasos />
        </div>
      ) : null}

      <div style={{ marginTop: '20px' }}>

        <LiveEvents />
        <div style={{ marginTop: '30px' }}>

          <UpcomingLiveEvents showAll limit={3} />

        </div>
        <DiscoverByCat title={i18next.t('DiscoverComponentDiscoverByCategory')} />
        <Video showAll />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    loading: state.user.loading
  };
};
export default connect(mapStateToProps)(Home);
