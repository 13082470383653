import { UserDAO } from "daos";
import { useEffect, useCallback, useState } from "react";
import {useSelector } from 'react-redux'
const userDAO = new UserDAO();

export const useMenuViewerLogic = () => {
  const user = useSelector (state=> state.user.user)
  const { token } = JSON.parse(localStorage.getItem("env") || "{}");
  const [activities, setActivities] = useState([]);
  
  const initActivity = useCallback(async () => {
    if (Object.keys(user).length>0){
      const  data  = await userDAO.getMyAcitivtyViewer(token);
      
      setActivities(data.data.data);
    }
  
  }, [user]);

  useEffect(() => {
    initActivity();
  }, []);

  return { activities };
};
