import React from "react";
import "./style.scss";
import map from "assets/img/icons/ic-map.svg";
import PropTypes from "prop-types";
import styled from 'styled-components'
import i18next from 'i18next';
import { B2B_PORT, SHOW_DEFAULT_ICONS } from "../../../environment/environment";
import styles from '../../../assets/css_general/general.scss'

const NombreCat = ({timeZone, event_description, hideLocation, title, category, hour, location, mode, upcoming }) => {
  
  return (
    <StyleNombreCat style={{ marginLeft: mode === 'modal' && "20px" }}>
      <h1 className="mt-md-3 mt-0 mb-md-2 mb-2">{title}</h1>
      {event_description && <h5 className="mt-md-1 mt-0 mb-md-2 mb-2">{event_description}</h5>}

      
     { !B2B_PORT && false && category &&  <h2  className="mb-md-3 mb-2 category">{category.cate_description}</h2>}
      {upcoming ?
        <div className="Hor mt-0"> 
          {" "}
         {SHOW_DEFAULT_ICONS && ( <img src={map} alt="" />)}
         <span className="">{i18next.t("NombreCatFechaComponentLiveAt")} {hour}  {timeZone}</span>
        </div>
        :
        <span className="Hor mt-0">
          {" "}
         {SHOW_DEFAULT_ICONS && ( <img src={map} alt="" />)}
        {i18next.t("NombreCatFechaComponentPlayedAt")} {hour}  {timeZone}
        </span>

      }

    </StyleNombreCat>
  );
};

NombreCat.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  hour: PropTypes.string.isRequired
};

export default NombreCat;

const StyleNombreCat = styled.div`
h1{
    font-size: 2.2rem;
    font-weight: 500 !important;

  }

  h2{
    color: ${styles.secondColor};
    font-size: 1.3rem;
    font-weight: 600 !important;
    margin-bottom: 10px;
  }
  .category {
    font-weight: 600;
  }

  .Hor{
    color: #7a7883;
    display: flex;
    font-weight: 600 !important;
    margin-top: 4px;
    font-size: 1.2rem;
    img{
      width: 15px;
      margin-right: 5px ;
    }
  }

  @media (max-width:768px){
    h1{
    font-size: 1.3rem;
    font-weight: 500 !important;
  }

  h2{
    font-size: 1rem;
    margin-bottom: 8px;
  }
  }
  @media (min-width:1600px){

h1 {
  font-size: 2.8rem;
}
h2{
  font-size: 1.4rem;
}
.Hor {
  font-size: 1.3rem;
}
}
`