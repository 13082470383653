import React from "react";
import "components/publish-live-events/style.scss";
// import LiveEventInformation from "../../components/publish-live-events/live_event_information/LiveEventInformation";
import LiveEventInformation from "components/publish-live-events/live_event_information/LiveEventInformation";
import PitchEvent from "components/publish-live-events/pitch_event";
import ThankYou from "components/publish-live-events/thank_you_video";
import { connect } from "react-redux";
import { usePublishLiveEventLogic } from "components/publish-live-events/PublishLiveEventLogic";
import Navbar from "components/publish-live-events/navbar_live_publish";
import SweetAlert from "sweetalert2-react";
import Sad from "assets/img/icons/sad-face-in-rounded-square.svg";
import { withRouter } from "react-router-dom";
import useAcceptedRequest from '../../hooks/useAcceptedRequest'

const PublishPrivateEvent = ({categories,languages,history,match,location}) => {
  const { ide } = match.params;
  const { requestData } = useAcceptedRequest(ide)

  const {influencer, request, user} = requestData

  const {  errorForm, setErrorForm, eventValues, onChangeEventValues, setEventValues,handleImageChange,
    loading, loadingImage, onSubmit, initiaLoading, onSaveInDraft, handlePitchVideoChange,
    handleThanksVideoChange,refPresentationVideo ,refThanksVideo, ticketTypes} = usePublishLiveEventLogic({requestData, privateMode:true, categories,languages,history});
  const { pathname } = location;

  return (
    <div className="Live-Events-Page">
      <div
        style={{ display: (!pathname.includes('pitch_video') && !pathname.includes('thank_you') ) ? "block" : "none" }}
      >
        {/* <Navbar submit={onSubmit} onSave={onSaveInDraft} loading={loading} /> */}
        <Navbar  submit={onSubmit} onSave={onSaveInDraft} loading={loading} isPrivate ide={ide} />
        <LiveEventInformation requestData={requestData} privateMode eventValues={eventValues}  categories={categories}  errorForm={errorForm}  setErrorForm={setErrorForm}
                      onChangeEventValues={onChangeEventValues} setEventValues={setEventValues}  handleImageChange={handleImageChange}
                      loading={loading}  loadingImage={loadingImage}  onSubmit={onSubmit} 
                      initiaLoading={initiaLoading}  onSaveInDraft={onSaveInDraft}  languages={languages} ticketTypes={ticketTypes}/>
      </div>
      <div
        style={{
          display: pathname.includes("pitch_video") ? "block" : "none"
        }}
      >
        <Navbar isPrivate ide={ide} submit={onSubmit} onSave={onSaveInDraft} loading={loading} />
        <PitchEvent
          onChange={handlePitchVideoChange}
          previousBlob={refPresentationVideo}
        />
      </div>
      <div
        style={{
          display: pathname.includes('thank_you')? "block" : "none"
        }}
      >
        <Navbar  isPrivate ide={ide} submit={onSubmit} onSave={onSaveInDraft} loading={loading} />
        <ThankYou
          onChange={handleThanksVideoChange}
          previousBlob={refThanksVideo}
        />
      </div> 

      {/* {/* <SweetAlert imageUrl={Sad} show={errorForm} title={errorForm} onConfirm={() => setErrorForm("")} /> */}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories,
    languages: state.languages.languages
  };
};

export default withRouter(connect(mapStateToProps)(PublishPrivateEvent));
