import { useState, useEffect } from "react";
import { EventDAO, EventRequestDAO } from "daos";
import * as yup from "yup";
import moment from "moment";
import i18next from "i18next";

const eventDAO = new EventDAO();
const eventRequestDAO = new EventRequestDAO();
const initialValues = {
  type: "",
  reason: "",
  num_attendees: "",
  price_ticket: "",
  date: "",
  start_hour: "",
  end_hour: "",
  purpose: "",
  customReason: ""
};
const schema = yup.object().shape({
  reason: yup.string().required("Reason is required"),
  num_attendees: yup.string().required("Attendees is required"),
  price_ticket: yup.string().required("Price ticket is required"),
  date: yup.string().required("Date is required"),
  start_hour: yup.string().required("Start hour is required"),
  end_hour: yup.string().required("End hour is required"),
  purpose: yup.string().required("Purpose is required")
});

export const useRequestPrivateLogic = ({
  match: {
    params: { event_uuid }
  },
  showAlert,
  history
}) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [initialLoading, setInitialLoading] = useState(true);
  const [eventData, setEventData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fatherReasonActivated, setFatherReasonActivated] = useState(
    FATHER_REASONS.FUN
  );

  const init = async () => {
    const {
      data: { data }
    } = await eventDAO.findById(event_uuid);
    setEventData(data);
    setInitialLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const onChangeFormValues = ({ target: { name, value } }) =>
    setFormValues(prevState => ({ ...prevState, [name]: value }));

  const onSubmit = async () => {
    if (loading) return;

    try {
      await schema.validate(formValues);
    } catch (err) {
      showAlert(err.errors[0]);
      return;
    }

    // setLoading(true);

    const data = {
      influencer_ide: eventData.influencer.influencer_ide,
      request_type: 0,
      request_reason:
        formValues.reason !== REASONS.OTHER_REASON
          ? REASONS_LABEL[Number(formValues.reason)]
          : formValues.customReason,
      request_purpose: formValues.purpose,
      request_num_attendees: formValues.num_attendees,
      request_price_ticket: formValues.price_ticket,
      request_date: moment(formValues.date).format("x"),
      request_start_hour: formValues.start_hour,
      request_end_hour: formValues.end_hour
    };

    try {
      const { token } = JSON.parse(localStorage.getItem("env"));
      await eventRequestDAO.create(data, token);
      showAlert("Request sended!");
      history.goBack();
    } catch (err) {
      const { status } = err.response;
      if (status === 400) {
        showAlert(err.response.data.error[0].message);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    formValues,
    initialLoading,
    onChangeFormValues,
    onSubmit,
    eventData,
    fatherReasonActivated,
    setFatherReasonActivated
  };
};

export const REASONS = Object.freeze({
  PARTY_APPERANCE: 1,
  PLAYING_GAMES: 2,
  HANGOUT: 3,
  OTHER_REASON: 4,
  MEETING_REQUEST: 5,
  CONSULTANT_REQUEST: 6,
  BUSINESS_PROPOSAL: 7,
  PARTNERSHIP: 8,
  COACHING_TREANING: 9,
  MASTER_CLASS: 10,
  WORKSHOP: 11,
  TUTORIAL: 12,
  TRAINING: 13,
  SHARING_EXPERIENCIES: 14
});

export const REASONS_LABEL = [
  "",
 i18next.t("PrivateMeetingJustForFunOptionName1"),
  "Playing Games",
  "Hangout",
  "Other reason",
  "Meeting Request",
  "Consultant Request",
  "Business Proposal",
  "Partnership",
  "Coaching/Training",
  "Marterclass",
  "Workshop",
  "Tutorial",
  "Sharing skills or experiencies"
];

export const FATHER_REASONS = {
  FUN: 0,
  BUSINESS: 1,
  EDUCATION: 2,
  OTHER_REASON: 3
};
