import { useEffect, useReducer, useCallback } from "react";
import { EventRequestDAO } from "daos";
import { actionTypes } from "./actions";
import reducer from "./reducer";
import statesEventRequest from "../../constants/statesEventRequest";
import { search } from "../../utils";
import i18next from "i18next";

const eventRequestDAO = new EventRequestDAO();

export const usePrivateMessageInfluencerLogic = ({ showAlert }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { token } = JSON.parse(localStorage.getItem("env"));

  const initRequests = useCallback(async () => {
    dispatch({ type: actionTypes.ON_GET_REQUESTS });
    try {
      const { data } = await eventRequestDAO.getMyRequestInfluencer(token);
      const requests = data.data;
      
      Object.keys(requests).forEach(
        key =>
          (requests[key] = requests[key].map(request =>
            initMessagesInRequest(request)
          ))
      );
      dispatch({
        type: actionTypes.ON_SUCCESS_GET_REQUEST,
        requests
      });
    } catch (err) {
      dispatch({ type: actionTypes.ON_FINISH_GET_REQUEST });
      const { status } = err.response ? err.response : '';
      if (status === 400) {
        showAlert(err.response.data.error[0].message);
      } else {
        showAlert("Sorry, there was an error.");
      }
    }
  }, [token, showAlert]);

  const onChangeSearchInput = e =>
    dispatch({ type: actionTypes.ON_SET_SEARCH_INPUT, value: e.target.value });

  const onPressRequest = requestActive =>
    dispatch({ type: actionTypes.ON_SET_REQUEST_ACTIVE, requestActive });

  /**
   * Set the messages of a request.
   * @param {Object} request
   * @returns {Object} return request with key "messages"
   */
  const initMessagesInRequest = data => {
    let messages = [];
    const { request, user } = data;
    const { request_state, request_updated_date, request_created_date, request_rejected_date, request_cancelled_date} = request;
    messages.push({
      data: {
        text: `Viewer ${user.user_name} ${user.user_lastname} ${i18next.t("PrivateMessageInfluencerLogicSentYou")}`
      },
      type: "text",
      author: "viewer",
      date: request_created_date
    });
    const labelRequestState = value =>
      Object.keys(statesEventRequest).find(
        key => statesEventRequest[key] === value
      );


    const hasBeenModifiedPreviously = request_updated_date !== request_created_date;
    const hasBeenModifiedAndRejected= request_rejected_date === request_updated_date
    switch (request_state) {
      case statesEventRequest.modified:
        messages = [...messages, addMessage({
          date: request_updated_date,
          text: `${i18next.t("PrivateMessageInfluencerLogicYouHaveModified")} ${user.user_name} ${user.user_lastname}`,
          author: 'me'
        })]
        break;
      case statesEventRequest.accepted:
        messages = [...messages, addMessage({
          date: request_updated_date,
          text: `${i18next.t("PrivateMessageInfluencerLogicYouHave")} ${labelRequestState(request_state)} ${i18next.t("PrivateMessageInfluencerLogicYouHaveTheRequest")}  ${user.user_name} ${user.user_lastname}`,
          author: 'me'
        })]
        break;
      case statesEventRequest.pendingPayment:
        messages = [...messages, addMessage({
          date: request_updated_date,
          text: `${i18next.t("PrivateMessageInfluencerLogicYouHaveAccepted")} ${user.user_name} ${user.user_lastname}`,
          author: 'me'
        })]
        break;
      case statesEventRequest.rejected:
        messages = [...messages, addMessage({
          date: request_updated_date,
          text: `${i18next.t("PrivateMessageInfluencerLogicYouHave")} ${labelRequestState(request_state)} ${i18next.t("PrivateMessageInfluencerLogicTheRequestMade")} ${user.user_name} ${user.user_lastname}`,
          author: 'me'
        })]
        break;
      case (statesEventRequest.acceptedModified || (statesEventRequest.rejected && hasBeenModifiedPreviously)):
        messages = [...messages, 
        addMessage({ date: request_updated_date,
          text: `${i18next.t("PrivateMessageInfluencerLogicYouHaveModified")} ${user.user_name} ${user.user_lastname}`,
          author: 'me'
        }),
        addMessage({
          date: request_updated_date,
          text: `Viewer ${user.user_name} ${user.user_lastname} ${i18next.t("PrivateMessageInfluencerLogicHasAccepted")}`,
          author: 'viewer'
        })
      ]
      break;
      case (statesEventRequest.rejected && !hasBeenModifiedPreviously):
          messages = [...messages, addMessage({
            date: request_updated_date,
            text: `${i18next.t("PrivateMessageInfluencerLogicYouHave")} ${labelRequestState(request_state)} ${i18next.t("PrivateMessageInfluencerLogicTheRequestMade")} ${user.user_name} ${user.user_lastname}`,
            author: 'me'
          })]
        break;

        case (statesEventRequest.pendingModified ):
          messages = [...messages, 
            addMessage({
              date: request_updated_date,
              text: `${i18next.t("PrivateMessageInfluencerLogicYouHaveModified")} ${user.user_name} ${user.user_lastname}`,
              author: 'me'
            }), 
            addMessage({
              date: request_updated_date,
              text: `Viewer ${user.user_name} ${user.user_lastname} has accepted your modifications!`,
              author: 'viewer'
            })
        ]
        break;
  
      case (statesEventRequest.cancelled ):
        
          messages =  parseInt(request_updated_date) > parseInt(request_created_date) ? [...messages, addMessage({
            date: request_updated_date,
            text: `${i18next.t("PrivateMessageInfluencerLogicYouHaveModified")} ${user.user_name} ${user.user_lastname}`,
            author: 'me'
          }),
          addMessage({
            date: request_updated_date,
            text: `Viewer ${user.user_name} ${user.user_lastname} ${i18next.t("PrivateMessageInfluencerLogicUserHasCancelled")}`,
            author: 'viewer'
          }),
        ]:
        [...messages, addMessage({
          date: request_updated_date,
          text: `Viewer ${user.user_name} ${user.user_lastname} ${i18next.t("PrivateMessageInfluencerLogicUserHasCancelled")}`,
          author: 'viewer'
        }),
      ]
        break;
      default:
        break;
    }
    request.messages = messages;
    return data;
  };
  const addMessage = ({ date, text, author }) => {
    let newMessage = {
      data: {
        text
      },
      type: "text",
      author,
      date: date
    }
    return newMessage
  }
  const onRejectRequest = async request_ide => {
    dispatch({ type: actionTypes.ON_REJECT_REQUEST });

    try {
      await eventRequestDAO.rejectRequest(token, {
        request_ide
      });
      dispatch({ type: actionTypes.ON_FINISH_REJECT_REQUEST });
      showAlert("Private request successfully rejected.");
      initRequests();
    } catch (err) {
      dispatch({ type: actionTypes.ON_FINISH_REJECT_REQUEST });
      
      const { status } = err.response ? err.response : '';
      if (status === 400) {
        showAlert(err.response.data.error[0].message);
      } else {
        showAlert("Sorry, there was an error.");
      }
    }
  };

  const onAcceptRequest = async request_ide => {
    dispatch({ type: actionTypes.ON_ACCEPT_REQUEST });

    try {
      await eventRequestDAO.acceptEventRequestInfluencer(token, {
        request_ide
      });
      dispatch({ type: actionTypes.ON_FINISH_ACCEPT_REQUEST });
      showAlert("Private request successfully accepted");
      initRequests();
    } catch (err) {
      dispatch({ type: actionTypes.ON_FINISH_ACCEPT_REQUEST });
      const { status } = err.response ? err.response : '';
      if (status === 400) {
        showAlert(err.response.data.error[0].message);
      } else {
        showAlert("Sorry, there was an error.");
      }
    }
  };

  /**
   * @param {Array} data
   * @returns {Array}
   */
  const getFilteredRequests = data => {
    const { searchInput } = state;
    if (!searchInput) return data;
    return data.filter(({ user }) =>
      search(`${user.user_name} ${user.user_lastname}`, searchInput)
    );
  };

  /* TODO: Delete. Useless ? */
  useEffect(() => {
    if (Object.keys(state.requests).length) {
      const requests = [
        ...(state.requests.pending || []),
        ...(state.requests.accepted || []),
        ...(state.requests.rejected || [])
      ];
      // dispatch({
      //   type: actionTypes.ON_SET_REQUEST_ACTIVE,
      //   requestActive: requests[0]
      // });
    }
  }, [state.requests]);

  useEffect(() => {
    initRequests();
  }, [initRequests]);

  return {
    state,
    onChangeSearchInput,
    onPressRequest,
    onRejectRequest,
    onAcceptRequest,
    getFilteredRequests
  };
};

const getInitialState = () => ({
  requests: [],
  loadingRequests: true,
  searchInput: "",
  requestActive: {},
  rejecting: false,
  accepting: false
});
