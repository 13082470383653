import { useEffect, useState, useCallback } from "react";
import { SearchDAO, EventDAO } from "daos";
const searchDAO = new SearchDAO();
const eventDAO = new EventDAO();

export const useSearchLogic = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    live_events: [],
    influencers: [],
    past_events: [],
    upcoming: []
  });
  const [trendingTags, setTrendingTags] = useState([]);
  const [query, setQuery] = useState("");
  const initSearch = useCallback(async () => {
    if (!query) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const response = await searchDAO.searchAll(query);
      setData(response.data.data);
      fetchTrendingTags()
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, [query]);

  
  const fetchTrendingTags = async ()=> {
    const tags = await eventDAO.getTrendingTags();
    setTrendingTags(tags.data.data);
  }
    useEffect(()=> {
      fetchTrendingTags()
    },[])
  useEffect(() => {
    initSearch();
  }, [query, initSearch]);

  return { loading, data, setQuery, trendingTags };
};
