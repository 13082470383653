//Event type integer
export const WEBRTC = 0;
export const RTMP = 1;

//Event type string
export const WEBRTC_STRING = "interactive";
export const RTMP_STRING = "streaming";

//Event type routes
export const WEBRTC_ROUTE = "streaming";
export const RTMP_ROUTE = "broadcast";
