import React, {Component} from 'react';

import './style.scss';
import map from "../../../assets/img/icons/ic-map.svg";
import FechaCat from "../header-streaming";
import logo from '../../../assets/img/logo-white.png';
import {Link} from "react-router-dom";
import EndCat from "../end-streaming";
class MenuStreaming extends Component {
    render() {
        return (
            <div className="FechaCat2">
                <div className="FechaCat">
                    <img src={logo} className="Logo2" alt="logo"/>
                    <FechaCat/>
                </div>
                <Link><button className="leave-streaming" data-toggle="modal" data-target="#myModal">Leave Live Event</button></Link>
                <EndCat/>
            </div>
        );
    }
}

export default MenuStreaming;

