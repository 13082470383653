import Avatar from '../CardUtils/Avatar'
import cinco_estrellas from "assets/img/5-estrellas.png";
import { connect } from "react-redux";
import map from "assets/img/icons/ic-map.svg";
import per from "assets/img/icons/ic-user.svg";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import useUserOrLocalTimeHour from "../../../hooks/useUserOrLocalTimeHour"
import React, {useState, useEffect} from 'react'
import {hideCategories} from "../../../environment/environment";

const PastEventCard = ({ hideDetails, bg_img,event, user_img, name, titulo, cat, uuid , categories}) => {
    const [category, setCategory]= useState('')
    const {time}= useUserOrLocalTimeHour(event? event.event_date: undefined, event)


    useEffect(() => {
      if (cat && categories){
        categories.forEach ((category)=> {
          if (category.cate_ide ===cat){
            setCategory (category.cate_description)
          }
        })
      }
    }, [cat, categories])
    return (
      <div style={{marginBottom:'35px'}} className="CardVideo col-12 col-md-3 grow">
        <div className="Shadow">
          <NavLink exact  to={`/event/${uuid}`}  activeClassName="active_live_events"  >
            <div className="Header" style={{ minWidth:"250px" , backgroundImage: `url(${bg_img})`, backgroundSize:'cover' }}>
              <div className="Time">{time.time}</div>
            </div>
                          {hideDetails!==true &&

            <div className="Footer">
              <div className="Negro">

               {user_img &&

                <Avatar user_avatar={user_img} />
               }

                <div>
                  {name && <span className="Nombre"> {name}</span>}
                  {/* <div className="Estrellas">
                    <img src={cinco_estrellas} alt="puntuacion" />
                  </div> */}
                </div>
              </div>

              <div className="d-flex flex-column">
                <span style={{fontSize:'20px', fontWeight:'600 !important'}} className="Titulo"> {titulo}</span>
                  {!hideCategories && (<span className="Cat"> {category}</span>)}
                <span className="Fecha"> {time.weekDayAndDate}</span>
              </div>
            </div>
}
          </NavLink>
        </div>
      </div>
    );
  };

  const mapStateToProps = state => {
    return {
      categories: state.categories.categories,
    };
  };

  export default connect(mapStateToProps)(PastEventCard);
