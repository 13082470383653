import React, {Component} from 'react';
import './style.scss';
import Paypal from '../../../assets/img/icons/Paypal.svg'
class PasarelasDePago extends Component {
    render() {
        return (
            <div className="pasarelas container">
                <div className="row">
                <div className="col-5 div_pasarelas"><h4 className="title_pasarelas">Payment Method</h4>
                    <div className="d-flex align-items-start "><img className="ml-3 mr-3" src={Paypal}/><div className="div_p_pasarelas"><p className="mb-0">Paypal</p>
                    <p className="mb-0 additional_per">Fee is 1% per transfered, minimun $ 0.25</p>
                    <p className="mb-0 change_method">Change a payment method</p></div>
                    </div>
                </div>
                <div className="col-7 d-flex all-transfer"><div className="w-100"><h2 className="mb-2 avalaible_balance">Avalaible Balance</h2>
                <h2 className="price_transfer mb-2">$ 8,000.00</h2>
                <p className="adittional_advance">Additional 3% advance fee</p></div>
                <div className="div_transfer">
                    <p>Amount to transfer</p>
                    <input className="input_transfer" type="text"></input>
                    <button className="button_transfer mt-2">Inmediate Transfer</button>
                </div>
                </div>
                </div>
            </div>
        );
    }
}

export default PasarelasDePago;