import React, {Component} from 'react';
import usr from '../../../assets/img/user.png';

import './style.scss';
import circulo from "../../../assets/img/logo-circulo.png";
import cinco_estrellas from "../../../assets/img/5-estrellas.png";

class ImagenUsuPuntuacionChat extends Component {
    render() {
        return (
            <div className="UsuPuntuacionChat">
                <div className="Div-foto_chat">
                    <img className="Foto" src={usr} alt=""/>
                    <p className="Nombre nombre_chat">Stella Richardson</p>
                </div>
            </div>
        );
    }
}

export default ImagenUsuPuntuacionChat;

