import React from "react";
import { Link } from "react-router-dom";
import usr from "../../../assets/img/user.png";
import "./style.scss";
import circulo from "../../../assets/img/logo-circulo.png";
import PropTypes from "prop-types";
import RatingPoints from "components/comunes_events/rating_points";
import { B2B_PORT, INFLUENCER_ICON_ENABLED } from "../../../environment/environment";
const ImagenUsuPuntuacion = ({
  score,
  userName,
  userFullName,
  userAvatar,
  margin,
  disabledLink,
  hideSpecialIcon
}) => {
  const renderUserAvatar = () => {
    return (
      <div className={"influencerAvatar"}>
        <img
          className="Foto"
          src={userAvatar}
          alt=""
          style={{ heigth: "50px" }}
        />
        {!hideSpecialIcon && INFLUENCER_ICON_ENABLED && (<img src={circulo} className="Icono" alt="" />)}
      </div>
    );
  };

  return (
    <div
      style={{ marginLeft: margin === 0 && "0px" }}
      className="UsuPuntuacion4"
    >
      {!disabledLink ? (
        <Link to={`/${userName}`} className="Div-foto">
          {renderUserAvatar()}
        </Link>
      ) : (
        renderUserAvatar()
      )}

      <Link
        to={`/${userName}`}
        style={{
          marginLeft: B2B_PORT ? "0px" :"20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: B2B_PORT ? "center" : ""
        }}
      >
        <span style={{ color: "#212529" }} className="Nombre">
          {B2B_PORT ? userFullName : userName}
        </span>
        {!B2B_PORT && (<RatingPoints score={score} />)}
        {/* <div className="Estrellas">
        <img src={cinco_estrellas} alt="puntuacion" />
        </div> */}
      </Link>
    </div>
  );
};

ImagenUsuPuntuacion.propTypes = {
  userName: PropTypes.string.isRequired,
  userAvatar: PropTypes.string.isRequired,
};

export default ImagenUsuPuntuacion;
