import React, { useState, useEffect } from "react";
import EventCardInfluencer from "../../comunes/Cards/EventCardInfluencer";
import Calendar from "../../comunes/calendar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import eventState from "../../../constants/eventState";
import moment from "moment";
import useMobileNav from "../../../hooks/useMobileNav";
import i18next from "i18next";
import { PRIVATE_MARKETPLACE } from "../../../environment/environment";

const UpcomingLiveEventsPage = ({ events, user, influencer }) => {
  const [reversedEvents, setReversedEvents] = useState([]); // Events reversed so that the new ones come first when looping
  const { mobileNav } = useMobileNav();

  useEffect(() => {
    if (events && events.length > 0) {
      events.sort(sortEvents);
      setReversedEvents(events);
    }
  }, [events]);

  function goToPublishPage() {
    window.location.href = `/publish_event`;
  }

  function sortEvents(firstEvent, secondEvent) {
    if (firstEvent.event_date < secondEvent.event_date) {
      return -1;
    }
    if (firstEvent.event_date > secondEvent.event_date) {
      return 1;
    }
    return 0;
  }
  function sortFirstRecentEvents(firstEvent, secondEvent) {
    if (firstEvent.event_date < secondEvent.event_date) {
      return 1;
    }
    if (firstEvent.event_date > secondEvent.event_date) {
      return -1;
    }
    return 0;
  }
  return (
    <div className="container Upcoming-live-events">
      <div className="row">
        <div className="col-md-8 d-flex Fila-live-events">
          <div className="col-12 mt-2 mt-md-0">
            {!mobileNav && (
              <h2>{i18next.t("InfluencerMyLiveEventsUpcoming")}</h2>
            )}
            {mobileNav && (
              <div className="mt-4 mb-4">
                <div onClick={goToPublishPage}>
                  <button className="Btn-rojo w-100">
                    {i18next.t("InfluencerMyLiveEventsPublish")}
                  </button>
                </div>
              </div>
            )}
          </div>

          {reversedEvents &&
            reversedEvents.length > 0 &&
            reversedEvents
              .filter(({ event_state }) => event_state === eventState.upcoming)
              .map(event => {
                return (
                  <>
                    <EventCardInfluencer
                      key={event.event_ide}
                      event={event}
                      user={user}
                      influencer={influencer}
                      cardsPerRow={2}
                      type="upcoming"
                    />
                  </>
                );
              })}

          {!events ||
            (events.filter(
              ({ event_state }) => event_state === eventState.upcoming
            ).length === 0 && (
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  fontSize: "22px"
                }}
              >
                {i18next.t("InfluencerMyLiveEventsNoEventYet")}
              </p>
            ))}
        </div>

        {!mobileNav && (
          <div className="col-md-4 ">
            {(user.user_can_publish || !PRIVATE_MARKETPLACE) && (
              <div onClick={goToPublishPage}>
                <button className="Btn-rojo w-100">
                  {i18next.t("InfluencerMyLiveEventsPublish")}
                </button>
              </div>
            )}
            <br />
            <br />
            <Calendar
              selectedDates={(events || []).map(event =>
                moment(parseInt(event.event_date)).format("YYYY-MM-DD")
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    events: state.user.events,
    user: state.user.user,
    influencer: state.user.influencer
  };
};

export default connect(mapStateToProps)(UpcomingLiveEventsPage);
