import React, { Component } from "react";
import usr from "../../../assets/img/user.png";

import "./style.scss";
import circulo from "../../../assets/img/logo-circulo.png";
import cinco_estrellas from "../../../assets/img/5-estrellas.png";

class ImagenUsuPuntuacionStreaming extends Component {
  render() {
    const { avatar } = this.props;
    return (
      <div className="UsuPuntuacion2">
        <div className="Div-foto">
          <img className="Foto" src={avatar} alt="" />
          <img src={circulo} className="Icono" alt="" />
        </div>

        <div>
    <span className="Nombre">{this.props.username ? this.props.username : "Stella Richardson"}</span>
          <div className="Estrellas" id="Estrella2">
            {/* <img src={cinco_estrellas} alt="puntuacion" /> */}
          </div>
        </div>
      </div>
    );
  }
}
export default ImagenUsuPuntuacionStreaming;
