import React, {useEffect, useState} from 'react'
import { withRouter } from "react-router-dom";
import NavBar_general from "components/comunes/navbar";
import styled from 'styled-components'
 const TermsOfService = () => {
    const [scrolled, setScrolled] = useState(false)
    useEffect(()=>{
        document.querySelector('body').scrollTo(0,0)   
       setScrolled(true)
       },[])

    return (
        <Container>
            {scrolled && 
            <> 
           
            <header classNameName="App-header">

                <NavBar_general />


            </header>
           

            <div className="container">
                <div className="row mt-md-5 mt-3">
                    <div className="col-md-12 mt-md-5 mt-3" align="left">
                        <h1 className=" mb-4 bold mt-5">Terms of Service</h1>
                        <p className="paragraph"><b>Updated as of Sep 24, 2020</b></p>
                        <p>gotmy is owned and operated by Got My Idol, Inc., a Delaware company. If you have any questions or comments about these Terms of Service (TOS), please contact us at support@gotmy.com.</p>
                        <p>By downloading or using gotmy you agree to these TOS, our Privacy Policy, and our Refund Policy.</p>
                        <p>If you are under the age of 16, you may not download or use gotmy. We do not knowingly collect or maintain information from children under age 16.</p>
                        <p>If you are between the ages of 16 and 17, you must get your parent or guardian to read and agree to our TOS and Privacy Policy.</p>

                        <p className="mt-4 paragraph"><b>I. General</b></p>
                            <p>YOU CONSENT TO RECEIVE COMMERCIAL E-MAIL, TEXT, SMS AND OTHER MESSAGES FROM US, AND AGREE THAT WE MAY USE YOUR EMAIL ADDRESS, PHONE NUMBER AND OTHER PERSONAL INFORMATION FOR THE PURPOSE OF INITIATING COMMERCIAL MESSAGES. Receiving these communications is a condition of using gotmy and you will not be able to opt out of receiving them.</p>
                            <p>We reserve the right to change these TOS at any time. You are responsible to keep yourself apprised of any such changes. If we make any material change to the TOS, we will provide you with a Notice when you open gotmy. If you object to any terms and conditions of the TOS or any subsequent changes or you become dissatisfied with gotmy in any way, your only option is to delete your account. Your continued use of gotmy after we post revised TOS means that you agree to the revisions.</p>
                            <p>You must be at least 13 years old to download gotmy. By requesting to use, or using gotmy, you represent and warrant that you are not required to register as a sex offender with any government entity.</p>
                            <p>Many features of gotmy are free, but your Internet provider’s or mobile operator’s fees and rates may still apply.</p>

                            <p className="mt-4  paragraph"><b>II. Conduct On gotmy</b></p>
                                <p>You may not use gotmy to:</p>
                                <ul className="list">
                                    <li><p>Upload or transmit any content (which we define below) that is unlawful, threatening, abusive, obscene, violent, patently offensive, invasive of another's privacy, contains personally identifiable information of another or promotes racism, bigotry, hatred or harm (including self-harm) or is otherwise objectionable;</p></li>
                                    <li><p>Upload or transmit images which contain nudity, weapons, violence, or drugs;</p></li>
                                    <li><p>Harm, stalk or otherwise harass another;</p></li>
                                    <li><p>Impersonate, or misrepresent your relationship with, any person or entity;</p></li>
                                    <li><p>Upload or transmit any content that you do not have a right to make available, or that infringes any patent, trademark, trade secret, copyright, privacy, or other proprietary rights of any party;</p></li>
                                    <li><p>Upload or transmit any unsolicited or unauthorized advertising, promotional materials, spam, chain letters, pyramid schemes or any other form of solicitation, or offer any contest, giveaway, or sweepstakes;</p></li>
                                    <li><p>Upload or transmit software viruses or any other harmful computer code, files or programs, or use any data mining, robots, or similar data gathering or extraction methods;</p></li>
                                    <li><p>Disrupt or impose an unreasonable burden on gotmy (or another person’s use of gotmy) or networks connected to gotmy, or breach or attempt to breach the security of gotmy; or register accounts or post content automatically, systematically, or programmatically.</p></li>
                                </ul>
                                <p>Keep your password private and secure, and always remember to log off of gotmy at the end of each session.</p>
                                <p>You are solely liable and responsible for your interactions with other users. We reserve the right to monitor disputes between you and other users, but we have no obligation to do so.</p>
                                <p>We reserve the right to investigate any suspected unauthorized uses of gotmy and we may take action in our sole discretion, including without limitation seeking legal, civil, criminal, or injunctive relief.</p>
                                <p>We may establish general practices and limits concerning use of gotmy, including without limitation the number of days that we retain content, the number and size of posts and messages you may send or receive, and the space we allocate on our servers for your use. We have no responsibility or liability for the deletion or failure to store any messages and other communications or other content. We may terminate accounts that are inactive for an extended period of time.</p>
                                <p>These guidelines are not exhaustive, and we have the right to make all judgments regarding the applicability of these guidelines – in letter or in spirit – in our sole and absolute discretion.</p>

                                <p className="mt-4  paragraph"><b>III. No Spam Policy</b></p>
                                    <p>You may not engage in any activity involving spam on gotmy. Violations of this policy may result in immediate termination of service and legal action against any spammer. Accordingly, you may not upload, post, email, transmit or otherwise make available junk mail, commercial advertisements, or any other form of commercial solicitation on gotmy. Violations of this policy could subject you or your agents to civil and criminal penalties.</p>

                                    <p className="mt-4  paragraph"><b>IV. Safety and Security</b></p>
                                        <p>WE DO NOT CURRENTLY CONDUCT CRIMINAL BACKGROUND SCREENINGS ON OUR USERS. We reserve the right, however, to conduct any criminal background check we deem appropriate, at any time and using available public records. BY AGREEING TO THESE TOS, YOU HEREBY AUTHORIZE ANY SUCH CHECK.</p>
                                        <p>IF YOU ARE UNDER THE AGE OF 18, WE ENCOURAGE YOU TO DISCUSS WITH YOUR PARENT(S) OR GUARDIAN(S) ONLINE SAFETY ISSUES FOR ALL OF YOUR SOCIAL MEDIA MEMBERSHIPS, INCLUDING YOUR GOTMY ACCOUNT.</p>

                                        <p className="mt-4 paragraph"><b>V. Content</b></p>
                                            <p>By content we mean all data, text, software, music, sound, photographs, graphics, artwork, video, pictures, images, posts, messages or other materials of any kind, whether publicly posted or privately transmitted. Your content is your sole responsibility. You represent and warrant that you own or have the necessary licenses, rights, consents and permissions to publish all of your content. Except as set forth in our Privacy Policy, we are not responsible for any content that you upload or transmit on gotmy. We do not control the posted content and, as such, we do not control its accuracy, integrity, quality or any other aspect. Under no circumstances are we liable in any way for any content, including but not limited to any errors or omissions in any content, or for any loss or damage of any kind incurred as a result of the use of any content.</p>
                                            <p>We do not claim ownership of your content, but you hereby grant us a perpetual, assignable, world-wide, royalty free, sub-licensable and non-exclusive license to use, distribute, reproduce, modify, adapt, combine, synchronize, create derivative works from, publicly perform and publicly display such content (including your user name and likeness) on gotmy or otherwise for any promotional and other commercial purpose, whether by us, our partners or other third parties, in our sole discretion. We may use your feedback, comments and suggestions without any obligation to compensate you for them. We may continue to use and make available any and all content and we will continue to have all of these rights even if your account is terminated.</p>
                                            <p>You acknowledge and agree that we do not promise to screen content, but that we have the right to do so. We have the right to remove any content that violates the TOS or that we find objectionable. You accept liability associated with the use of any content, including but not limited to your reliance on the accuracy, completeness, or usefulness of such content.</p>
You may not reproduce, republish, further distribute or publicly exhibit any content on gotmy that is not yours.

                                                <p className="mt-4 paragraph"><b>VI. Our Proprietary Rights</b></p>
                                                    <p>gotmy and the software used in connection with gotmy contain proprietary and confidential information that is protected by applicable intellectual property and other laws. You may not copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, rent, sell, assign, sublicense, infringe or otherwise transfer or attempt to transfer any rights in the software. You may not modify the software in any manner or form or to use modified versions of the software. You may not access gotmy by any means other than through an interfaces we provide.</p>

                                                    <p className="mt-4  paragraph"><b>VII. We May Contact You</b></p>
                                                        <p>You agree that we may communicate with you by email, text messaging, phone or otherwise; usually our communication will relate to administrative, security and other issues relating to gotmy, including invitations to participate in promotional activities, newsletters and other communications. We may include in our communications advertisements and other third party promotional materials, campaigns and tools. Receiving these communications is a condition of your use of gotmy, and you will not be able to opt out of receiving them. You may, however, block all communication from us by deleting your gotmy account.</p>

                                                        <p className="mt-4  paragraph"><b>VIII. Availability of Service</b></p>
                                                            <p>We may at any time and from time to time modify, restrict or discontinue gotmy or any part of gotmy, temporarily or permanently, with or without notice. We are not liable to you or to any third party for any modification, suspension or discontinuance of gotmy.</p>
                                                            <p>We may without prior warning or subsequent notice terminate your account and access to gotmy for any or no reason. We may decide to terminate your account for a number of different reasons, including without limitation (i) if you violate or fail to comply with the TOS, the Privacy Policy or other related agreements or guidelines, (ii) if you provide false information during registration or in your profile, (iii) if you do not use gotmy for an extended period of time, (iv) by request of law enforcement or other government agencies, (v) for discontinuance or changes made to gotmy or any part thereof, or (vi) technical or security issues or problems. We will make all termination decisions in our sole discretion and we will not be liable to you or any third party for any termination of your account or access to your content or gotmy.</p>

                                                            <p className="mt-4 paragraph"><b>IX. Third Party Links</b></p>
                                                                <p>gotmy may provide, or third parties may provide, links to other websites or resources. We have no control over such sites and resources and we are not responsible for their availability. We do not endorse and are not responsible or liable for any content, advertising, products, or other materials on or available from such sites or resources. You further agree that we will not be responsible or liable, directly or indirectly, for any damage or loss of any sort caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource.</p>

                                                                <p className="mt-4 paragraph"><b>X. Advertising</b></p>
                                                                    <p>We use third-party advertising companies to serve ads after you visit our website. These companies may use information (not including your name, address, email address or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you. If you would like more information about this practice and to know your choices about not having this information used by these companies, you can read more on this page.</p>

                                                                    <p className="mt-4  paragraph"><b>XI. Types of users</b></p>
                                                                        <p>Any gotmy user can be described as one of these three types:</p>
                                                                            <ul className="list">
                                                                                <li><p>User with or without a valid Viewer profile (hereinafter referred to as “Viewer”). Minimum details needed:</p></li>
                                                                                    <ul className="list">
                                                                                        <li><p>A notified email address.</p></li>
                                                                                    </ul>
                                                                                <li><p>User with a valid Viewer profile and a valid Influencer profile (hereinafter referred to as “Influencer”). Minimum details needed:</p></li>
                                                                                    <ul className="list">
                                                                                        <li><p>A notified email address.</p></li>
                                                                                        <li><p>At least one social network connected and verified by gotmy.</p></li>
                                                                                    </ul>
                                                                                <li><p>User with a valid Viewer profile and a valid and verified Influencer profile (hereinafter referred to as “Verified Influencer”). Minimum details needed:</p></li>
                                                                                    <ul className="list">
                                                                                        <li><p>A verified email address.</p></li>
                                                                                        <li><p>At least one social network connected and verified by gotmy.</p></li>
                                                                                        <li><p>A verified mobile phone number.</p></li>
                                                                                        <li><p>A notified residency (USA or non-USA).</p></li>
                                                                                        <li><p>A notified type of person (individual or corporation).</p></li>
                                                                                        <li><p>Stripe or Paypal account details to receive pay-outs.</p></li>
                                                                                        <li><p>IRS tax form filled and signed: W-8BEN form for all non-US individual influencers; W8-BEN-E for all non-USA corporation influencers; W-9 form for US-based influencers who have earned above a certain amount of money from gotmy in a given tax year ($600 for influencers who reside in Masschusetts or Vermont, and $20,000 elsewhere).</p></li>
                                                                                    </ul>
                                                                                </ul>

                                                                                    <p className="mt-4  paragraph"><b>XII. Pricing and Taxes</b></p>
                                                                                        <p>All prices are in United States Dollars (USD) unless otherwise specifically stated.</p>
                                                                                        <p>All prices stated on gotmy do not include any state or other local taxes that may apply to Viewer’s order. If taxes are applicable to Viewer’s order they will be added to Viewer’s order as a separate charge in addition to the total amount.</p>

                                                                                        <p className="mt-4  paragraph"><b>XIII. Payment Policy and Ticketing Fee for Viewers</b></p>
                                                                                            <p>Name, description, content and ticket price of public live events and private meeting events shall be defined by Influencers.</p>
                                                                                            <p>Total ticket price paid by a Viewer (hereinafter referred to as “Gross Ticket Price”) includes:</p>
                                                                                            <ul className="list">
                                                                                                <li><p>Ticket price as defined by Influencer (hereinafter referred to as “Net Ticket Price”).</p></li>
                                                                                                <li><p>A fee (hereinafter referred to as “Ticketing Fee”) of $0.50 per ticket, plus a variable fee of 5.9% of the amount of the ticket.</p></li>
                                                                                            </ul>
                                                                                            <p>This Ticketing Fee does not include any fees associated with converting USD to Viewer’s native currency.</p>
                                                                                            <p>Credit Card Charges: credit card will be charged by gotmy according to Gross Ticket Price. If Viewer has any questions about charges on its credit card statement, Viewer should contact gotmy at support@gotmy.com. gotmy may charge or authorize Viewer's credit card in advance of confirming ticket availability. If tickets are ultimately found to be unavailable, the Viewer's credit card will not be charged or Viewer will receive a full refund for the charged amount.</p>
                                                                                            <p>If Viewer selects to complete the transaction using a debit card, in some cases, gotmy may attempt to authorize a debit card multiple times, creating several holds on the Viewer's account. This often happens when a third-party credit card processing company requires additional security verification such as a CVV, Zip Code, or address, or when Viewer's information is incorrectly provided or mistyped. Though gotmy will only clear the Viewer's transaction once, the hold(s) will temporarily lower the Viewer's available balance. Any hold(s) may take up to several days to clear.</p>
                                                                                            <p>If Viewer selects to complete the transaction using a Third Party Payment Platform (Paypal, Stripe, etc.), such third party services may be subject to separate policies, terms of use, and or fees of said third parties and Viewer accepts the same by completing the transaction using the Third Party Payment Platform. The name on the transaction of the Viewer's Third Party Payment Platform account will be "gotmy" or similar. If Viewer has any questions about the transaction on the Third Party Payment Platform account, Viewer should contact support@gotmy.com.</p>
                                                                                            <p>International Orders placed by Viewer may be subject to delayed processing. gotmy recommends that Viewer contact their credit card company or financial institution prior to placing an order to prevent unnecessary delays or holds. gotmy shall not be responsible for delays, holds, or any extra fees associated with placing an International Order.</p>
                                                                                            <p>Viewer is responsible for any and all legal fees incurred by Viewer and/or gotmy associated with Viewer’s disputed charges and chargebacks for purchases made on gotmy. In no event will gotmy be responsible for such legal fees.</p>
                                                                                            <p>In order to protect Viewer from fraud, Viewer may be required to provide additional proof of identity on any order. Proof of identity may include but is not limited to a signed credit card authorization and/or photocopies of public documents such as a state driver's license or federal passport.</p>

                                                                                            <p className="mt-4  paragraph"><b>XIV. Pay-out Policy and Platform Fee for Influencers</b></p>
                                                                                                <p>Name, description, content and Net Ticket Price of public live events and private meeting events shall be defined by Influencers.</p>
                                                                                                <p>In order to proceed with any pay-out to an Influencer, this must already be or become a Verified Influencer, as described in clause “Types of users”.</p>
                                                                                                <p>Once a public or private event has successfully terminated, gotmy shall apply a 10% fee to the influencer for every ticket sold and not refunded (hereinafter referred to as “Platform Fee”) and transfer a 90% of the Net Ticket Price of every ticket sold and not refunded to the Verified Influencer’s available balance.</p>
                                                                                                <p>On the last working day of every month, an automatic payment (hereinafter referred to as “automatic Pay-out”) will be delivered to the Verified Influencer including the revenue (minus platform and ticketing fees) of all tickets sold and not refunded for their public and private events that took place 1 month ago (i.e. on Nov 30, 2020 a pay-out will be released covering revenue from all tickets sold for events terminated between Oct 1 and Oct 31, 2020), that have not been paid-out in advance.</p>
                                                                                                <p>Fees for automatic Pay-outs are:</p>
                                                                                                <ul className="list">
                                                                                                    <li><p>For residents in USA and Canada with domestic accounts</p></li>
                                                                                                    <ul className="list">
                                                                                                        <li><p>Using Stripe Direct Deposit: $3.</p></li>
                                                                                                        <li><p>Using Paypal: free.</p></li>
                                                                                                    </ul>
                                                                                                    <li><p>For residents outside USA and Canada with international accounts in USD:</p></li>
                                                                                                    <ul className="list">
                                                                                                        <li><p>Using Paypal: 2% of the transferred amount with $20 maximum.</p></li>
                                                                                                    </ul>
                                                                                                </ul>
                                                                                                <p>If Verified Influencer requests an “Immediate Transfer” of part or all available balance at any time, an additional 3% fee shall apply as follows:</p>
                                                                                                <ul className="list">
                                                                                                    <li><p>For residents in USA and Canada with domestic accounts</p></li>
                                                                                                    <ul className="list">
                                                                                                        <li><p>Using Stripe Direct Deposit: $3 plus 3% of the transferred amount.</p></li>
                                                                                                        <li><p>Using Paypal: 3% of the transferred amount.</p></li>
                                                                                                    </ul>
                                                                                                    <li><p>For residents outside USA and Canada with international accounts in USD:</p></li>
                                                                                                    <ul className="list">
                                                                                                        <li><p>Using Paypal: 5% of the transferred amount with $50 maximum.</p></li>
                                                                                                    </ul>
                                                                                                </ul>

                                                                                                <p className="mt-4  paragraph"><b>XV. Unlawful Ticket Reselling</b></p>
                                                                                                    <p>gotmy in no way condones the unlawful resale of tickets. Any unlawful attempt to resell tickets purchased through gotmy will result in gotmy terminating those tickets without compensation, as well as the customer being barred indefinitely from use of any gotmy services.</p>

                                                                <p className="mt-4 paragraph"><b>XVI. Non-Disclosure Clause</b></p>
                                                                    <p>Unless authorized by Influencer or gotmy, Viewer agrees to hold all information and terms related with private meeting events and negotiations in strict confidence and not to disclose it to any third party.</p>

                                                                    <p className="mt-4 paragraph"><b>XVII. Refund Policy</b></p>
                                                                        <p>Our refund policy is described at Refund Policy.</p>

                                                                        <p className="mt-4 paragraph"><b>XVIII. Disclaimer of Warranties</b></p>
                                                                            <p>YOU USE GOTMY AT YOUR SOLE RISK. WE PROVIDE GOTMY ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM OR THROUGH US SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TOS.</p>
                                                                            <p>WE DO NOT GUARANTEE THAT GOTMY WILL ALWAYS BE SAFE, SECURE OR ERROR-FREE OR THAT GOTMY WILL ALWAYS FUNCTION WITHOUT DISRUPTIONS, DELAYS OR IMPERFECTIONS. WE MAKE NO WARRANTY THAT (1) GOTMY WILL MEET YOUR REQUIREMENTS, (2) GOTMY WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (3) THE RESULTS THAT MAY BE OBTAINED FROM YOUR USE OF GOTMY WILL BE ACCURATE OR RELIABLE, (4) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED THROUGH GOTMY WILL MEET YOUR EXPECTATIONS, AND (5) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.</p>
                                                                            <p>ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH GOTMY IS DONE AT YOUR OWN DISCRETION AND RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR OR ANOTHERS’ DEVICE OR COMPUTER OR LOSS OF DATA THAT RESULTS, DIRECTLY OR INDIRECTLY, FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</p>

                                                                            <p className="mt-4 paragraph"><b>XIX. Limitation of Liability</b></p>
                                                                                <p>YOU ACKNOWLEDGE AND AGREE THAT WE CANNOT CONTROL THE CONDUCT OF GOTMY’S USERS AND THEREFORE CANNOT BE RESPONSIBLE FOR ANY HARM THEY MAY CAUSE. WE ARE NOT RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD PARTIES, AND YOU RELEASE US, OUR DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD PARTIES. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.</p>
                                                                                <p>YOU EXPRESSLY AGREE THAT WE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), AND INCLUDING WITHOUT LIMITATION RESULTING FROM: (1) THE USE OR THE INABILITY TO USE GOTMY; (2) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM GOTMY; (3) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR CONTENT, TRANSMISSIONS OR DATA; (4) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON GOTMY; OR (5) ANY OTHER MATTER RELATING TO GOTMY.</p>
                                                                                <p>OTHER USERS OF GOTMY WITH WHOM YOU ASSOCIATE, CONNECT, INTERACT AND/OR SHARE CONTENT COULD USE YOUR CONTENT AND/OR LIKENESS IN A MANNER YOU DISLIKE, DISAPPROVE OF OR OTHERWISE FIND OFFENSIVE. HOWEVER, YOU AGREE NOT TO ASSERT ANY CLAIMS, ACTIONS OR DEMANDS AGAINST US IN CONNECTION WITH SUCH USE OR ACTIVITIES, WHETHER SUCH USE OR ACTIVITIES ARE AUTHORIZED BY THESE TOS OR NOT.</p>
                                                                                <p>WE ARE NOT RESPONSIBLE FOR ANY OFFENSIVE, INAPPROPRIATE, OBSCENE, UNLAWFUL OR OTHERWISE OBJECTIONABLE CONTENT OR INFORMATION YOU MAY ENCOUNTER ON GOTMY. WE ARE NOT RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OR ANY USER OF GOTMY.</p>
                                                                                <p>WE ARE NOT LIABLE FOR ANY LOST OR DELETED INFORMATION OR DATA, WHETHER INTENTIONAL OR UNINTENTIONAL, NOR FOR THE TIMELINESS, DELETION, MIS-DELIVERY OR FAILURE TO STORE ANY USER COMMUNICATIONS OR PERSONALIZATION SETTINGS.</p>
                                                                                <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, AND TO THE EXTENT THAT A JUDGMENT IS RENDERED IN A COURT OF COMPETENT JURISDICTION DISREGARDING ONE OR MORE OF THE WARRANTY DISCLAIMERS AND LIABILITY LIMITATIONS CONTAINED HEREIN, OUR AGGREGATE LIABILITY TO YOU SHALL NOT EXCEED ONE DOLLAR ($1.00).</p>

                                                                                <p className="mt-4 paragraph"><b>XX. Exclusions and Limitations</b></p>
                                                                                    <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE WARRANTY LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR WARRANTIES AND LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</p>

                                                                                    <p className="mt-4 paragraph"><b>XXI. Indemnity</b></p>
                                                                                        <p>YOU WILL INDEMNIFY AND HOLD US AND OUR SUBSIDIARIES, AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS AND OTHER PARTNERS, HARMLESS FROM ANY CLAIM OR DEMAND, INCLUDING ANY ATTORNEYS' FEES, THAT WE (OR THE OTHER INDEMNITEES) MAY INCUR RESULTING FROM OR ARISING OUT OF YOUR ACTIONS AND/OR ANY INFORMATION OR CONTENT YOU SUBMIT, POST, TRANSMIT OR MAKE AVAILABLE THROUGH GOTMY, YOUR ACCESS AND USE OF GOTMY, YOUR CONNECTION TO GOTMY, YOUR VIOLATION OF THE TOS, YOUR TRANSMISSION OF OTHER USERS’ INFORMATION OR CONTENT, OR YOUR VIOLATION OF ANY RIGHTS OF ANOTHER.</p>

                                                                                        <p className="mt-4 paragraph"><b>XXII. Disputes and Arbitration</b></p>
                                                                                            <p>You agree that any dispute relating in any way to these TOS or gotmy, including without limitation your or third parties’ access to or use of gotmy, will be resolved by binding arbitration as discussed below and not through litigation in any court (except in small claims court if the claim is within the court’s jurisdiction and proceeds on an individual basis). Claims are subject to arbitration, regardless of what theory they are based on or whether they seek legal or equitable remedies. Arbitration applies to any and all such claims or disputes, whether they arose in the past, may currently exist, or may arise in the future. This arbitration agreement is entered into pursuant to the Federal Arbitration Act, 9 U.S.C. §§1-16 (FAA).</p>
                                                                                            <p>YOU HAVE THE RIGHT TO OPT OUT OF THIS AGREEMENT TO ARBITRATE IF YOU TELL US WITHIN 60 DAYS OF YOUR FIRST DOWNLOAD OR USE OF GOTMY THAT YOU WANT TO OPT OUT. IF YOU WANT TO OPT OUT, SEND A WRITTEN NOTICE ADVISING US OF YOUR DECISION. UNLESS YOU OPT OUT OF ARBITRATION, YOU ARE WAIVING THE RIGHT TO HAVE A DISPUTE HEARD BEFORE A JUDGE OR JURY, OR OTHERWISE TO BE DECIDED BY A COURT OR GOVERNMENTAL TRIBUNAL.</p>
                                                                                            <p>YOU AGREE NOT TO (1) SEEK TO PROCEED ON ANY CLAIM IN ARBITRATION AS A CLASS CLAIM OR CLASS ACTION OR OTHER COMPARABLE REPRESENTATIVE PROCEEDING; (2) SEEK TO CONSOLIDATE IN ARBITRATION ANY CLAIMS INVOLVING SEPARATE CLAIMANTS; (3) BE PART OF, OR BE REPRESENTED IN, ANY CLASS ACTION OR OTHER REPRESENTATIVE ACTION BROUGHT BY ANYONE ELSE; NOR (4) SEEK ANY AWARD OR REMEDY IN ARBITRATION AGAINST OR ON BEHALF OF ANYONE WHO IS NOT A NAMED PARTY TO THE ARBITRATION. IF THESE TERMS RELATING TO CLASS OR REPRESENTATIVE PROCEDURES ARE LEGALLY UNENFORCEABLE FOR ANY REASON, THEN THIS AGREEMENT TO ARBITRATE WILL BE INAPPLICABLE TO THAT CLAIM, AND THAT CLAIM WILL INSTEAD BE HANDLED THROUGH LITIGATION IN COURT RATHER THAN BY ARBITRATION. NO ARBITRATOR SHALL HAVE AUTHORITY TO ENTERTAIN ANY CLAIM ON BEHALF OF A PERSON WHO IS NOT A NAMED PARTY, NOR SHALL ANY ARBITRATOR HAVE AUTHORITY TO MAKE ANY AWARD FOR THE BENEFIT OF, OR AGAINST, ANY PERSON WHO IS NOT A NAMED PARTY.</p>
                                                                                            <p>Arbitration under these TOS and the Privacy Policy shall be conducted by a single arbitrator under the then current rules of the American Arbitration Association (AAA) or JAMS, at the election of the party filing a claim in arbitration. That organization will apply its rules in effect at the time the arbitration claim is filed. If there is a conflict between those rules and this arbitration provision, this arbitration provision and the TOS will control. Notwithstanding anything to the contrary in any current or future such rules or governing law, the arbitrator shall have no power to vary the terms of the TOS or to award either party attorneys’ fees. In the event that JAMS or the AAA is unable to handle the arbitration for any reason, then the matter shall be arbitrated by a neutral arbitrator selected by agreement of the parties, pursuant to the AAA rules of procedure.</p>
                                                                                            <p>The arbitrator’s award shall be binding and may be entered as a judgment in any court of competent jurisdiction.</p>
                                                                                            <p>We will pay any costs that are required to be paid by us under the arbitration administrator’s rules. Even if not otherwise required, we will reimburse you up to $500 for any initial arbitration filing fees that you have paid. We will also pay any fees of the arbitrator and the arbitration administrator for the first two days of any hearing. If you win the arbitration, we will reimburse you for any fees you paid to the arbitration organization and/or arbitrator. All other fees will be allocated according to the arbitration administrator’s rules and applicable law. If you are unable to afford any fees that you would otherwise be required to pay, you may request us to pay or reimburse them, and we will consider your request.</p>
                                                                                            <p>Rules and forms for filing an arbitration claim can be found at www.jamsadr.com or www.adr.org. Arbitration hearings will take place in the federal judicial district that includes your address at the time the arbitration is filed.</p>

                                                                                            <p className="mt-4 paragraph"><b>XXIII. Permitted Time For Filing A Claim or Lawsuit</b></p>
                                                                                                <p>Regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of gotmy or the TOS must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>

                                                                                                <p className="mt-4 paragraph"><b>XXIV. Digital Millennium Copyright Act Notification Policy</b></p>
                                                                                                    <p>It is our policy to respond to clear notices of claimed copyright infringement that fully comply with the Digital Millennium Copyright Act. In addition, we will promptly terminate without notice the accounts of those determined by us to be “repeat infringers”.</p>
                                                                                                    <p>Please note that under 17 U.S.C. Section 512(f), any person who knowingly materially misrepresents that material or activity is infringing, or that material or activity was removed or disabled by mistake or misidentification, may be subject to liability. gotmy may, at its discretion, share a copy of your notification or counter-notification with third parties (this may include sharing the information with the account holder engaged in the allegedly infringing activity or for publication).</p>

                                                                                                    <p><u>Notification of Infringement</u></p>
                                                                                                    <p>If you are a copyright owner or an agent thereof, and you believe that any material content hosted on our websites infringes your copyrights, then you may submit a written notification pursuant to the Digital Millennium Copyright Act (“DMCA”) by providing gotmy’s Designated Copyright Agent with the following information in writing:</p>
                                                                                                    <ul className="list">
                                                                                                        <li><p>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works.</p></li>
                                                                                                        <li><p>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit gotmy to locate the material. Providing a link to the live public or private event at which you believe there has been an infringement is the best way to help us locate content quickly.</p></li>
                                                                                                        <li><p>Include a statement that: </p></li>
                                                                                                        <ul className="list">
                                                                                                            <li><p>You have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</p></li>
                                                                                                            <li><p>The information in the notification is accurate, and a statement under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. (For example, “The information in this notification is accurate, and under penalty of perjury, I am the owner, or an agent authorized to act on behalf of the owner, of an exclusive right that is allegedly infringed.”)</p></li>
                                                                                                        </ul>
                                                                                                        <li><p>Information reasonably sufficient to permit gotmy to contact you, such as an address, telephone number, and, if available, an email address at which you may be contacted.</p></li>
                                                                                                        <li><p>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed  (typing your full legal name is sufficient).</p></li>
                                                                                                    </ul>
                                                                                                    <p>If you fail to comply with all of the requirements of the DMCA for notifications, we may not act on your communication.</p>

                                                                                                    <p><u>Counter-Notification</u></p>
                                                                                                    <p>If you elect to send us a counter notification, to be effective it must be a written communication that includes all of the following:</p>
                                                                                                    <ul className="list">
                                                                                                        <li><p>Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled.</p></li>
                                                                                                        <li><p>Include a statement that:</p></li>
                                                                                                        <ul className="list">
                                                                                                            <li><p>You consent to the jurisdiction of Federal District Court for the judicial district in which your address is located, or if your address is outside of the United States, the judicial district in which gotmy is located, and will accept service of process from the claimant. (For example, “I consent to the jurisdiction of the Federal District Court for the district in which my address is located, or if my address is outside of the United States, the judicial district in which gotmy is located, and will accept service of process from the claimant.”</p></li>
                                                                                                            <li><p>You swear, under penalty of perjury, that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled. (For example, “I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”</p></li>
                                                                                                        </ul>
                                                                                                        <li><p>Your full legal name, email address, physical address, and telephone number.</p></li>
                                                                                                        <li><p>Your physical or electronic signature (typing your full legal name is sufficient).</p></li>
                                                                                                    </ul>
                                                                                                    <p>If you fail to comply with all of the requirements of the DMCA for counter-notifications, we may not act on your communication.</p>

                                                                                                    <p><u>Retractions</u></p>
                                                                                                    <p>gotmy honors retractions of claimed copyright infringement notices from the party that originally submitted them, to the extent possible. If you are affected by a notice, you can reach out to the claimant directly to seek a retraction. Please note, however, that gotmy may not be able to reinstate certain content.</p>
                                                                                                    <p>If you wish to retract your claimed copyright infringement notice, please email dmca@gotmy.com from the same email address as your original notification. Note that we cannot process retractions that are sent from a different email address. Your retraction must include the following:</p>
                                                                                                    <ul className="list">
                                                                                                        <li><p>Clearly identify the material you wish to retract, include a specific URL</p></li>
                                                                                                        <li><p>The date of your original notification and the Claim ID, if applicable</p></li>
                                                                                                        <li><p>An electronic or physical signature (typing your full legal name will do)</p></li>
                                                                                                    </ul>

                                                                                                    <p><u>Designated Copyright Agent</u></p>
                                                                                                    <p>gotmy’s Designated Copyright Agent to receive notifications and counter-notifications of claimed infringement can be reached as follows:</p>
                                                                                                    <p>Email: dmca@gotmy.com</p>
                                                                                                    <p>Mailing Address: Atn. Copyright Agent, Got My Idol, Inc., 85 Grand Canal Dr, Ste 406, Miami, FL 33144, USA</p>
                                                                                                    <p>Phone: +1 (786) 453-7535</p>
                                                                                                    <p>Only DMCA-related notifications should be sent to gotmy’s Designated Copyright Agent. Any other requests for support or other communications should be directed to gotmy customer service.</p>
                                                                                                    <p><u>Repeat Infringer Policy</u></p>
                                                                                                    <p>gotmy will promptly terminate any user’s access to the gotmy Service if that user is determined by gotmy to be a “repeat infringer” of copyrighted works. gotmy may, at its sole discretion, limit access to the gotmy Service and/or terminate the accounts of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</p>

                                                                                                    <p className="mt-4 paragraph"><b>XXV. Miscellaneous</b></p>
                                                                                                        <p>You may not use gotmy where prohibited by law, and you agree to comply with all local rules regarding online conduct and acceptable content. These TOS are governed by and construed in accordance with the laws of the state of Florida without regard to conflicts of laws principles. Our failure or delay to exercise or enforce any right or provision of the TOS shall not constitute a waiver of such right or provision. If any provision of the TOS is found to be invalid by an arbitrator or pursuant to the disputes section above or a court of competent jurisdiction, you nevertheless agree that the arbitrator or court should endeavor to give effect to our intentions as reflected in these TOS. The other provisions of the TOS shall remain in full force and effect.</p>
                                                                                                        <p>These TOS were written in English. If you are reading a translation and it conflicts with the English version, please note that the English version controls.</p>

                                                                                                        <p className="mt-4 paragraph"><b>XXVI. Deleting Your Account</b></p>
                                                                                                            <p>You can delete your account by going to Settings and clicking the tab to delete your account.</p>

                                                                                                            <p className="mt-4 paragraph"><b>XXVII. Our Contact Information</b></p>
                                                                                                                <p>If you have questions regarding these Terms of Service, please contact us.</p>
                                                                                                                <p>Mailing Address: Atn. Legal Department, Got My Idol, Inc., 85 Grand Canal Dr, Ste 406, Miami, FL 33144, USA</p>
                                                                                                                <p>Web: www.gotmy.com</p>
                                                                                                                <p>Email: legal@gotmy.com</p>

        </div>
      </div>
    </div>
           </>
            }

        </Container>
    )
}
export default withRouter(TermsOfService)

const Container = styled.div`
.list{
    list-style-type: disc;
}

p{
    font-weight:200 !important;
    color:#333 !important;
}
`