import React, { useEffect, useState } from "react";
import SimpleLink from "../simpleLink";
import "./style.scss";
import logo from "../../../assets/img/logo-white.png";
import AppStore from "../../../assets/img/AppStoreBoton.png";
import PlayStore from "../../../assets/img/PlayStoreBoton.png";
import useMobileNav from "../../../hooks/useMobileNav";
import MobileFooter from "./MobileFooter";
import {
  googlePlayGotmyURL,
  appleStoreGotmyURL,
  BRAND_BLOG_URL,
  BRAND_FACEBOOK_URL,
  BRAND_INSTAGRAM_URL,
  BRAND_LINKEDIN_URL,
  BRAND_PINTEREST_URL,
  BRAND_TWITTER_URL,
  BRAND_WEBSITE_URL,
  BRAND_YOUTUBE_URL,
  B2B_PORT,
} from "../../../environment/environment";
import { Link } from "react-router-dom"; 
import styled from "styled-components";
import { useParams, useHistory, useLocation } from "react-router-dom";
import i18next from "i18next";
import { isMobile } from "react-device-detect";

const Footer = () => {
  const history = useHistory();
  const location = useLocation();
  console.log({ history, location });
  const { mobileNav } = useMobileNav();

  if (
    window.location.pathname.includes("api/payment/stripe_callback") ||
    window.location.pathname.includes("paypal_callback") ||
    window.location.pathname.includes("instagramSignUp") ||
    window.location.pathname.includes("streamingDacastMobile") ||
    window.location.pathname.includes("/talent") ||
    window.location.pathname.includes("/wellness") ||
    (window.location.pathname.includes("streaming") && isMobile)
  ) {
    return null;
  }

  return (
    <>
      {!mobileNav ? (
        <div className="Bg-blue">
          <div className="container">
            <div className="row">
              <div className="col-md-3 d-flex flex-column">
                <img src={logo} className="Logo" alt="logo" style={{
                  paddingLeft: 0,
                  marginLeft: 0
                }} />
                <span className="Font-peq">
                  {new Date().getFullYear()}{" "}
                  {i18next.t("FooterComponentGotmyCompName")} <br />{" "}
                  {i18next.t("FooterComponentAllRightsReserved")}
                </span>
              </div>
              <nav className="col-md-6 d-flex ">
                <ul>
                  <Link to="/terms/service">
                    {i18next.t("FooterComponentTermsOfService")}
                  </Link>
                  <Link to="/terms/privacy">
                    {i18next.t("FooterComponentPrivacyPolicy")}
                  </Link>
                </ul>
                <ul>
                  {BRAND_INSTAGRAM_URL &&(<SimpleLink href={BRAND_INSTAGRAM_URL}>
                    Instagram
                  </SimpleLink>)}
                  {BRAND_LINKEDIN_URL&&(<SimpleLink href={BRAND_LINKEDIN_URL}>
                    Linkedin
                  </SimpleLink>)}
                  {BRAND_TWITTER_URL&&(<SimpleLink href={BRAND_TWITTER_URL}>
                    Twitter
                  </SimpleLink>)}
                  {BRAND_FACEBOOK_URL && (<SimpleLink href={BRAND_FACEBOOK_URL}>
                    Facebook
                  </SimpleLink>)}
                  {BRAND_PINTEREST_URL&&(<SimpleLink href={BRAND_PINTEREST_URL}>
                    Pinterest
                  </SimpleLink>)}
                  {BRAND_YOUTUBE_URL&&(<SimpleLink href={BRAND_YOUTUBE_URL}>
                    Youtube
                  </SimpleLink>)}
                </ul>
                <ul>
                  {!B2B_PORT && (<a target="_blank" href="https://blog.gotmy.com">
                    {i18next.t("FooterComponentContact")}
                  </a>)}
                 {!B2B_PORT && ( <a target="_blank" href="https://blog.gotmy.com/business/">
                    {i18next.t("FooterComponentInvestors")}
                  </a>)}
                  {BRAND_BLOG_URL && (<a target="_blank" href={BRAND_BLOG_URL}>
                    {i18next.t("FooterComponentBlog")}
                  </a>)}
                  {BRAND_WEBSITE_URL && (<a target="_blank" href={BRAND_WEBSITE_URL}>
                    Website
                  </a>)}
                </ul>
              </nav>
              {!B2B_PORT && (<div className="col-md-3 text-white">
                <h5>{i18next.t("FooterComponentDownloadAppTitle")}</h5>
                <p>{i18next.t("FooterComponentDownloadAppDescription")}</p>
                <StoresContainer>
                  <a
                    id="appleIcon"
                    style={{ display: "block" }}
                    href={appleStoreGotmyURL}
                    target="_blank"
                  >
                    <img
                      style={{ maxHeight: "43px" }}
                      src={AppStore}
                      alt="App Store"
                    />
                  </a>
                  <a
                    style={{ display: "block" }}
                    className=""
                    href={googlePlayGotmyURL}
                    target="_blank"
                  >
                    <img
                      style={{ maxHeight: "43px" }}
                      src={PlayStore}
                      alt="Google Play"
                    />
                  </a>
                </StoresContainer>
              </div>)}
            </div>
          </div>
        </div>
      ) : (
        <MobileFooter />
      )}
    </>
  );
};
const StoresContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  #appleIcon {
    margin-bottom: 8px;
  }
  @media (min-width: 1000px) {
    flex-direction: row;
    #appleIcon {
      margin-right: 8px;
    }
  }
`;
export default Footer;
