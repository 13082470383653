import styled from 'styled-components'


export const SocialDiv = styled.div`
@media (max-width:768px){
  border:none;
}
border: 1px solid #d8dce5;
padding: 1.5rem;
margin-bottom: 2rem;
text-align:left;
border-radius: 8px;
text-align:left;


`
export const SocialNetwork = styled.div`
    display: flex;
    padding-bottom: 20px;
    p {
        font-size:20px;
        font-weight:500 !important;
        color: black !important
    }
`
export const SocialImg = styled.img`
    width:2.5rem;
    height:2.5rem;
    margin-right:16px;
`
export const TypeAndConnect = styled.div`
width:100%;
display:flex;
justify-content:space-between;
border-bottom: 1px solid #d8dce5;
`
export const ConnectButton = styled.button`
 border-radius: 50px;
 margin-bottom:12px;
  padding: 0 24px;
  font-size: 18px;
  border: 0;
  background-color: #ff5a60;
  color: #fff;
  width:130px;
  :focus{
    outline: none;
  box-shadow: none;
}
`
export const ActivatedButton = styled.button`
 border-radius: 50px;
  padding: 0 24px;
  margin-bottom:12px;
  width:130px;

  font-size: 18px;
  border: 0;
  background-color: white;
  color: black;
  border: 1px solid black;
  :focus{
outline: none;
  box-shadow: none;
}

`