import React from 'react';
// import React, {Component, useState} from 'react';
import './style.scss';
import NavBar_liveEvents from "./navbar_live_publish";
import LivePublish from "./live_event_information";
import PitchEvent from "./pitch_event";
import ThankYou from "./thank_you_video";
import {Switch,Route} from "react-router-dom";

function LiveEventsPagePublishPrivate(props) {
    return (
        <div className="Live-Events-Page">
            <NavBar_liveEvents/>
            <Switch>
                <Route path="/private_publish/thank_you"  exact component={ThankYou}/>
                <Route path="/private_publish/pitch_event" exact component={PitchEvent}/>
                <Route path="/private_publish" exact component={LivePublish}/>
            </Switch>
        </div>
    );
}

export default LiveEventsPagePublishPrivate;