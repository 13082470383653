import React, { useEffect, useState } from 'react';
import {useSelector } from "react-redux"
import './style.scss';
import { Table } from 'react-bootstrap';
import EventTableRow from "./EventTableRow"
import Loader from "components/comunes/loader";

const FilteredTable = ({eventsToShow, searchText}) => {
  const categories = useSelector((state)=>state.categories.categories)

  const events = useSelector ((state)=> state.user.events)
  const [reversedEvents, setReversedEvents] = useState()

  //Search function
  const isEventIncluded = (ev, searchText)=>{
    let title= ev.event_title.toLowerCase()
    let tags = ev.event_tags.toLowerCase()
    let description = ev.event_description.toLowerCase()
    let category = categories.find((category)=> category.cate_ide===ev.event_category_ide)
    let included = false
    if ( searchText==="" ||(category && category.cate_description.toLowerCase().includes(searchText.toLowerCase()))|| (title.includes(searchText.toLowerCase()) || tags.includes(searchText.toLowerCase()
     || description.includes(searchText.toLowerCase())))){
      included= true
    }
    return included
  }
  // TODO: grab event types from back
  function sortFirstRecentEvents(firstEvent, secondEvent){
    if ( firstEvent.event_date < secondEvent.event_date ){
      return 1;
    }
    if ( firstEvent.event_date > secondEvent.event_date ){
      return -1;
    }
    return 0;
  }

  useEffect(() => {
    if (events)
    {

    
    
      let reverse = events.sort(sortFirstRecentEvents)
      let filtered
      if ( eventsToShow==="all"){
        filtered= reverse.filter((ev)=>isEventIncluded(ev,searchText))

        setReversedEvents(filtered)
      }
      else if (eventsToShow==="private"){
         filtered = reverse.filter((ev)=>(ev.event_ticket_type===11|| ev.event_ticket_type===10) && (isEventIncluded(ev, searchText)))
        setReversedEvents(filtered)
      }
      else if (eventsToShow==="public"){
        filtered = reverse.filter((ev)=>(ev.event_ticket_type===2 ||ev.event_ticket_type===5 ) && (isEventIncluded(ev, searchText)))
        setReversedEvents(filtered)
      }
    }
  }, [events, eventsToShow, searchText])

  return (
              <Table responsive className="mt-4">
                    <thead>
                        <tr>
                        <th>Event Title</th>
                        <th>Date</th>
                        <th>Price</th>
                        <th>Sales</th>
                        <th>Amount</th>
                        </tr>
                    </thead>
                      
                      {reversedEvents? reversedEvents.map((event, i )=>{
                        // if (i<20){
                          return (
                            <tbody>

                              <EventTableRow key={event.event_uuid} event={event}/>
                              </tbody>

                          )
                        // }
                      })
                     :
                     <div style={{display:"flex", justifyItems:"center", justifyContent:"center", paddingTop: 100}}>


                       <Loader/>
                     </div>
                     }
                    </Table>
    );
  }

export default FilteredTable;