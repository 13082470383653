import React from "react";
import Attendees from "components/comunes_events/attendees_admin";
import cinco_estrellas from "assets/img/5-estrellas.png";
import fun from "assets/img/icons/ic-fun.svg";
import user2 from "assets/img/icons/ic-raisehand.svg";
import user3 from "assets/img/icons/ic-price.svg";
import Time from "assets/img/icons/ic-time-copy.svg";
import Check from "assets/img/icons/ic-check.svg";
import { stateArray } from "../../constants/statesEventRequest";
import {  newReasons } from '../../pages/requestPrivate/utils'
import { parsePriceByCurrencyJustSymbol,  findCurrencyByCurrencyCode } from "../../utils/currencyUtils";
const imagesPath = {
  minus: Check,
  plus: Time
};

const RequestDetail = ({ currencies, data, startTimeStr , timeStrings}) => {
  if (!Object.keys(data).length) return null;
  const { request, user } = data;
  const {
    request_currency,
    request_reason,
    request_num_attendees,
    request_price_ticket,
    request_date,
    request_type,
    request_state
  } = request;
  const { user_name,user_username,  user_lastname, user_location, user_avatar } = user;
  const requestState = stateArray.filter((state) => state.id === request_state)[0]
  const requestType = newReasons.filter((reason) => reason.request_type === request_type)

  return (
    <div className="">
      <Attendees userName={user_username} name={`${user_name} ${user_lastname}`}
        location={user_location && JSON.parse(user_location).city} avatar={user_avatar}
      />
      {/* <img className="stars_request" src={cinco_estrellas} alt="" /> */}
      <div className="d-flex mt-3 mb-3">
        <img src={imagesPath["plus"]} alt="" />
        <p  className={`mb-0 ml-2 ${requestState.name.includes('Pending') && 'rojo'}`}>{requestState.name}</p>

      </div>

      <div className="d-flex align-items-start"></div>
      <h2 className="mt-1 mb-5 details_request">Private request details</h2>
      <div   className="d-flex mb-3 information_detalles">
        <img src={fun} alt="" />
        <div style={{ width:'300px',    }}>
          <p className="m-0">{requestType && requestType.length > 0 && requestType[0].optionName}</p>
          <p className="price_information ">{requestType && requestType.length > 0 && requestType[0].secondaryName}</p>

        </div>
      </div>
      <div className="d-flex mb-3 information_detalles">
        <img src={user2} alt="" />
        <div>
          <p className="m-0">Attendees</p>
          <p className="price_information">{request_num_attendees} Attendees</p>
        </div>
      </div>
      <div className="d-flex mb-3 information_detalles">
        <img src={user3} alt="" />
        <div>
          <p className="m-0">Price per Ticket</p>
          <p className="price_information">{parsePriceByCurrencyJustSymbol(request_price_ticket, findCurrencyByCurrencyCode(request_currency,currencies)) }</p>
        </div>
      </div>
        <div className="d-flex information_detalles">
        <img src={user2} alt="" />
        <div>
          <p className="m-0">Date and time</p>
          {request_date && timeStrings ? 
          <p className="price_information">
            {`${timeStrings.dateStr}   ${(startTimeStr&& startTimeStr.length>0)? startTimeStr : 'at any time'}`} 
          </p>
          :
          <p className="price_information">
          Date and time not defined yet 
        </p>
}
        </div>
      </div>
    </div>
  );
};

export default  RequestDetail ;
