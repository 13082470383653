import React from "react";
import Tag from "../../../comunes/tag";
import PropTypes from "prop-types";
import i18next from 'i18next';

const tagsMax = 15;

const renderTag = (value, index) => {
  if (index < tagsMax) {
    return (
      <Tag name={value} key={index} />
    )
  } else {
    return null
  }
}

const TagsSearch = ({ data }) => {
  return (
    <div className="container">
      <h2>{i18next.t('TagsSearchComponentTrendingTags')}</h2>
      {data.map((value, index) => renderTag(value, index))}
    </div>

  );
};

TagsSearch.propTypes = {
  data: PropTypes.array.isRequired
};

export default TagsSearch;
