import React from "react";
import "./style.scss";
import logo from "assets/img/logo-circulo-white.png";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled from 'styled-components'
import i18next from "i18next";
const MenuPublish = ({ submit, saveInDraft, loading }) => {
  return (
    <Container>
      <div className="FechaCat13 PublishNav d-flex align-items-center">
        <img src={logo} className="Logo5" alt="logo" />
        <h2 className="mb-0 title_request">{i18next.t("InfluencerMyLiveEventsPublishNavPublishLiveEvent")}</h2>
      </div>
      {/* <button className="request-streaming send_request" onClick={saveInDraft}>
        Save
      </button> */}
      <div className="d-flex">
     
      <Link to={`/my_live_events`}>
        {/* <button className="request-streaming cancel">Cancel</button> */}
        <button className=" cancel">{i18next.t("InfluencerMyLiveEventsPublishNavCancel")}</button>
      </Link>
      <button className=" publish send_request" onClick={submit}>
        {!loading ? i18next.t("InfluencerMyLiveEventsPublishNavPublish") : i18next.t("InfluencerMyLiveEventsPublishNavLoading")+"..."}
      </button>
      </div>
    
    </Container>
  );
};

MenuPublish.propTypes = {
  submit: PropTypes.func.isRequired
};

export default MenuPublish;

const Container = styled.div `
display:flex;
justify-content: space-between;
width:100%;
align-items:center;
.publish{
  margin-left:8px;
  color:white;
  border-radius: 50px;
    padding: 0px 18px !important;
    font-size: 15px !important;
    border:none;
  background: #70ad47;
  /* margin-left:1rem; */
  height: 2rem;

}


`