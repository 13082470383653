import React from "react";
import { Accordion } from "react-bootstrap";
import NavBarGeneral from "./../comunes/navbar";
import "./style.scss";
import { AccordionItem, PayoutInformation, TaxInformation } from "./component";

export default () => (
  <>
    <NavBarGeneral />
    <div className="container__help">
      <Accordion>
        <AccordionItem
          title="System and Service Requirements FAQ"
          eventKey="0"
          children={null}
        />
        <AccordionItem
          title="Operational Support FAQ"
          eventKey="1"
          children={null}
        />
        <AccordionItem
          title="Technical Support FAQ"
          eventKey="2"
          children={null}
        />
        <AccordionItem
          title="Fees and Payout Information FAQ"
          eventKey="3"
          children={<PayoutInformation />}
        />
        <AccordionItem
          title="Tax Information FAQ"
          eventKey="4"
          children={<TaxInformation />}
        />
      </Accordion>
    </div>
  </>
);
