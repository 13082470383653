import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import logo from "assets/img/logo-white.png";
import Search_input_peq from "../../../comunes/search_input_peq";
import Menu from "../../../comunes/navbar/menu";
import Login_menu from "../../../comunes/login_menu";
import { EventDAO } from "daos";
import Navbar from "components/comunes/navbar"
import i18next from 'i18next';

const eventDAO = new EventDAO();

class NavBarSearch extends React.Component {
  state = {
    tags: []
  };
  async componentDidMount() {
    const { data } = await eventDAO.getTrendingTags();
    this.setState({ tags: data.data });
  }

  renderSignUpEnabledNavbar(isEnabled) {
    if (isEnabled) {
      return (
        <div className="d-flex justify-content-between w-100">
          <img src={logo} className="Logo" alt="logo" />
          <Menu />
          <Login_menu />
        </div>
      )
    } else {
      return (
        <div
          className="d-flex justify-content-evenly w-100"
          style={{
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            justifySelf: 'center',
            alignContent: 'center',
            alignItems: 'center',
            alignSelf: 'center'
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: '1.75%'
            }}
          >
            <img src={logo} className="Logo" alt="logo" />
          </div>
          <Menu />
        </div>
      )
    }
  }

  render() {

    return (
      <div className="Nav_search Bg-blue flex-column">
         <Navbar />
        <div style={{display:'flex', flexDirection:'column'}} 
        className="Bg-blue Texto-div">
        <Search_input_peq opacity={this.props.opacity}/>
          <div className="">
          <p className="text-white ">
            <p className="mt-3">
            {i18next.t('NavbarSearchComponentTrengindSearches')}:
              {' '}
              {this.state.tags.slice(0, 3).map((trend, i) => {
                return (
                  <>
                    <Link to={`/search/${trend}`} >
                      <span style={{ color: 'white' }}>{trend}</span>
                    </Link>
                    {i < 2 && ', '}
                  </>
                )
              })}
            </p>
          </p>
          </div>
         
        </div>
      </div>
    );
  }
}

export default NavBarSearch;
