import React, { useState, useEffect } from 'react'
import { CardElement } from '@stripe/react-stripe-js';
import paypalIcon from "assets/img/icons/Paypal.svg";
import { Link } from "react-router-dom";
import { parsePriceByCurrency } from "../../../utils/currencyUtils";
import i18next from 'i18next';
import { useSelector } from "react-redux"
import useIsUserLoggedIn from '../../../hooks/useIsUserLoggedIn'
import visaCardIcon from 'assets/img/creditCardsIcons/visa.png';
import masterCardIcon from 'assets/img/creditCardsIcons/mastercard.png';
import amexCardIcon from 'assets/img/creditCardsIcons/americanexpress.png';
import { B2B_PORT } from '../../../environment/environment';
const cardElementOptions = {
    style: {
        base: {
            width: '100%',
            fontSize: "17px",
            color: "#7a7883",
            "::placeholder": {
                color: '#b3b8c1'
            },
        },
        invalid: {
            // color: '#ffc7ee'
        },
    },
    hidePostalCode: true
}

const Checkout = ({ savedCard, setSavedCard, currency, paypal, setUsesPaypal, handlePurchase,
    email, setEmail, confirmEmail, setConfirmEmail, payment, privacy, setPrivacy, terms, setTerms, finalPrice }) => {
    const [cardStyle, setCardStyle] = useState(cardElementOptions)
    let { isUserLoggedIn } = useIsUserLoggedIn()
    if(B2B_PORT){
        isUserLoggedIn = false;
    }
    const userCards = useSelector((state) => state.user.userCardMethods)
    console.log({ userCards, paypal, savedCard })
    const handleInputChange = (e) => {
        if (e.target.name === 'privacy') {
            setPrivacy(!privacy)
        }
        else if (e.target.name === 'terms') {
            setTerms(!terms)
        }
    }
    const handlePaymentMethodClick = e => {
        const { target } = e
        console.log({target, id:target.id})
        if (target.id === 'paypal') {
            setUsesPaypal(true)
            setSavedCard({status: false})
        }
        if (target.id === 'card' ) {
            setUsesPaypal(false)
            setSavedCard({status: false})

        }
        if (target.id ==="savedCard"){
            setUsesPaypal(false)
            
            setSavedCard({status:true, stripe_payment_method_id: userCards[0].id})

        }
       
    }
    return (
        <>
            
            {parseFloat(finalPrice) > 0 &&
                <div className="">
                    <div className="mb-3" style={{ borderBottom: '1px solid #F4F2F2', paddingBottom: '8px', display: 'flex' }} >
                        <div style={{ width: '90%' }} className="w-100">
                            <CardElement options={cardStyle} />
                        </div>
                        <input id="card" checked={(!paypal && (!savedCard || savedCard.status ===false))} type="radio" name="payment" className="Radio-redonda" value={!paypal} onChange={handlePaymentMethodClick} />
                    </div>
                </div>}

                {(parseFloat(finalPrice ) > 0 && userCards && userCards.length>0 ) &&
                <div>
                    <div className="mb-3" style={{ borderBottom: '1px solid #F4F2F2', paddingBottom: '8px', display: 'flex' }} >
                        <div style={{ width: '90%' }} className="w-100">
                            <span><img style={{width:30,  height:30, marginRight:12}} src={ userCards[0] && userCards[0].card.brand ==="visa"? visaCardIcon:
                            userCards[0].card.brand ==="mastercard"? masterCardIcon: userCards[0].card.brand ==="amex"? amexCardIcon: visaCardIcon }/>                          </span>
                            <span style={{color: savedCard.status===true?"black": "rgb(179, 184, 193)"}} className="mr-2 tw-text-2xl"> ************</span>
                            <span style={{color: savedCard.status===true?"black": "rgb(179, 184, 193)"}}>{userCards[0] && userCards[0].card.last4}</span> 
                        </div>
                        <input id="savedCard" checked={( savedCard&& savedCard.status===true)} type="radio" name="payment" className="Radio-redonda" value={savedCard && savedCard.status ===true} onChange={handlePaymentMethodClick} />
                    </div>

                </div>
                }
            {parseFloat(finalPrice) > 0 &&
                <div className="d-flex align-items-center mb-3 Input-linea">
                    <img src={paypalIcon} alt="" />
                    <div className="d-flex flex-column ml-3 w-100">
                        <label htmlFor="" style={{
                            color: paypal ? '#7a7883' : '#b3b8c1',
                            display: 'flex', justifyContent: 'space-between'
                        }}>
                            Paypal
                    </label>
                    </div>
                    <input id="paypal" type="radio" checked={paypal} name="payment" className="Radio-redonda" value={paypal} onChange={handlePaymentMethodClick} />

                </div>
            }
            {!isUserLoggedIn &&
                <div className="mb-2">
                    <input required type="email" className="emailInput mb-2" name="email"
                        placeholder={i18next.t('CheckoutComponentEnterEmailAddress')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    />
                    <input required type="email" className="emailInput mb-2" name="confirmEmail"
                        placeholder={i18next.t('CheckoutComponentConfirmEmailAddress')}
                        value={confirmEmail}
                        onChange={(e) => setConfirmEmail(e.target.value.toLowerCase())}
                    />
                </div>
            }
            {false &&
                <>
                    <p className="Title mb-1">{i18next.t('CheckoutComponentPromoCode')}</p>
                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-column">
                            <label className="Lab" htmlFor="">
                                {i18next.t('CheckoutComponentEnterYourPromoCode')}</label>
                            <input className="Redondo" type="text" />
                        </div>
                    </div>
                </>
            }
            {!isUserLoggedIn &&
                <>
                    <div style={{ paddingLeft: '16px' }} className="form-check">
                        <label className="form-check-label">
                            <input style={{ display: 'block' }} type="checkbox" className="form-check-input"
                                id="privacy" name="privacy" onChange={handleInputChange} checked={privacy} />
                            {i18next.t('CheckoutComponentIAgreeWithThe')} <Link to="/terms/privacy">{i18next.t('CheckoutComponentPrivacyPolicy')}</Link>
                        </label>
                    </div>
                    <div style={{ paddingLeft: '16px' }} className="form-check">
                        <label className="form-check-label">
                            <input style={{ display: 'block' }} type="checkbox" className="form-check-input" id="terms" name="terms" onChange={handleInputChange}
                                checked={terms}
                            />
                            {i18next.t('CheckoutComponentIAgreeWithThe')} <Link to="/terms/service">{i18next.t('CheckoutComponentTermsOfService')}</Link>
                        </label>
                    </div>
                </>
            }
            {parseFloat(finalPrice) > 0 && !paypal && <span className="my-2" style={{ fontSize: '13px' }} >* {i18next.t('CheckoutComponentCardPaymentsAreProcessedByStripeDescription')}</span>}
            <div style={{ position: 'relative' }}>
                {payment.status !== 'loading' ?
                    <button style={{ position: 'relative', top: '0px' }} data-toggle="modal" data-dismiss="modal" data-target="#myModal2" className="Btn-rojo-b buy-btn mt-4"
                        onClick={handlePurchase}>
                        {parseFloat(finalPrice) > 0 ? <span> {i18next.t('CheckoutComponentPay')} {parsePriceByCurrency(finalPrice, currency)} </span> : <span> {i18next.t('CheckoutComponentBuyTicketForFree')}</span>}

                    </button>
                    :
                    <button disabled={true} style={{ position: 'relative', top: '0px' }} data-toggle="modal" data-dismiss="modal" data-target="#myModal2" className="Btn-rojo-b buy-btn mt-4"
                    >
                        {i18next.t('CheckoutComponentProcessingYourPayment')}...
                                            </button>
                }
            </div>
        </>
    )
}

export default Checkout