import React, { useRef } from 'react'
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import i18next from 'i18next';
import { EventDAO } from "daos";
import *  as ActionRoutes from './ActionRoutes';
import UploadVideoActionButton from './UploadVideoActionButton';
import EditActionsIcon from "assets/img/icons/pencil.svg";
import { isMobile } from "react-device-detect";

const eventDAO = new EventDAO();

const SpecialEventActions = ({ isCreator, hasAdvertisingEnabled, uuid, setErrorMessage, setLoading, setVideoUploadCompleted }) => {


    const validateVideo = (videoFile, route) => {
        if (!videoFile || !videoFile.type) {
            setErrorMessage("Sorry, something went wrong.");
            return;
            //error
        }

        var compatibleMimeType = false;

        if (videoFile.type === "video/mp4" || videoFile.type === "video/quicktime") {
            compatibleMimeType = true;
        }

        if (!compatibleMimeType) {
            //error
            setErrorMessage("Only supported .mp4 or .mov");
            return;
        }

        var videoDuration = 0;
        var videoSize = 0;
        const MAX_FILE_SIZE = 100 * 1024 * 1024; // X * MEGABYTES * KILOBYTES
        const MAX_FILE_DURATION = 20; // SECONDS

        if (videoFile.size > MAX_FILE_SIZE) {
            setErrorMessage("File size exceeded");
            return;
        }

        try {
            var video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = function () {
                window.URL.revokeObjectURL(video.src);
                var duration = video.duration;

                //alert(videoFile.name)

                if (duration > MAX_FILE_DURATION) {
                    //error
                    setErrorMessage("Video can not excceed " + MAX_FILE_DURATION + " duration.");
                    return;
                }

                submitVideo(videoFile, route);

            }

            video.src = URL.createObjectURL(videoFile);;


        } catch (error) {
            setErrorMessage("Sorry, something went wrong.");
        }


    }



    const handleVideoUpload = (videoFile) => {
        validateVideo(videoFile);
    }
    const handlePresentationVideoUpload = (videoFile) => {
        validateVideo(videoFile, ActionRoutes.PRESENTARION_VIDEO);
    }
    const handlePresentationVideoDesktopUpload = (videoFile) => {
        validateVideo(videoFile, ActionRoutes.PRESENTARION_VIDEO_DESKTOP);
    }
    const handleThanksVideoUpload = (videoFile) => {
        validateVideo(videoFile, ActionRoutes.THANKS_VIDEO);
    }
    const handleThanksVideoDesktopUpload = (videoFile) => {
        validateVideo(videoFile, ActionRoutes.THANKS_VIDEO_DESKTOP);
    }


    const submitVideo = async (videoFile, route) => {

        try {
            const values = new FormData();

            values.append("event_uuid", uuid);

            const env = JSON.parse(localStorage.getItem("env"));
            let response = null;

            console.log("Esto tiene submitVideo", videoFile, route)

            switch (route) {
                case ActionRoutes.PRESENTARION_VIDEO:
                    values.append("event_presentation_video", videoFile, videoFile.name);
                    console.log("pre respuesta de PRESENTARION_VIDEO", values)
                    setLoading(true);
                    response = await eventDAO.updatePresentationVideo(values, env.token);

                    console.log("respuesta de PRESENTARION_VIDEO", response)
                    break;
                case ActionRoutes.PRESENTARION_VIDEO_DESKTOP:
                    values.append("event_presentation_video", videoFile);
                    setLoading(true);
                    response = await eventDAO.updatePresentationVideoLandscape(values, env.token);
                    break;
                case ActionRoutes.THANKS_VIDEO:
                    values.append("event_thanks_video", videoFile);
                    setLoading(true);
                    response = await eventDAO.updateThanksVideo(values, env.token);
                    break;
                case ActionRoutes.THANKS_VIDEO_DESKTOP:
                    values.append("event_thanks_video", videoFile);
                    setLoading(true);
                    response = await eventDAO.updateThanksVideoLandscape(values, env.token);
                    break;
            } 

            setLoading(false);

            const data  = response.data;

            console.log("Esto tiene submitVideo result", data)

            if (data.success === false) {
                setErrorMessage("Sorry, something went wrong.");
                return;
            }else{
                setVideoUploadCompleted("Video uploaded successfully")
            }

        } catch (err) {

            setLoading(false);
            console.log("Error updating video", err)

            if (err && err.response) {
                const { status, data } = err.response;
                if (status === 404) {
                    setErrorMessage(data.error.message);
                } else if (status === 400) {
                    setErrorMessage(data.error[0].message);
                }
            }
            else {
                setErrorMessage("Sorry, something went wrong.");
                return;
            }
        }
    };

    if (!isCreator || !hasAdvertisingEnabled || isMobile) {
        return null;
    }

    return (
        <Fab
            alwaysShowTitle={true}
            mainButtonStyles={{
                backgroundColor: "#ff5a60"
            }}
            icon={<img
                style={{
                    width: 30
                }}
                src={EditActionsIcon} alt="" />}
        >
            <UploadVideoActionButton
                text="Upload promotional desktop video"
                handleSubmit={handlePresentationVideoDesktopUpload}
            />
            <UploadVideoActionButton
                text="Upload promotional mobile video"
                handleSubmit={handlePresentationVideoUpload}
            />
            <UploadVideoActionButton
                text="Upload post purchase promotional desktop video"
                handleSubmit={handleThanksVideoDesktopUpload}
            />
            <UploadVideoActionButton
                text="Upload post purchase promotional mobile video"
                handleSubmit={handleThanksVideoUpload}
            />
        </Fab>
    )
}

export default SpecialEventActions