import React, { useState, useEffect } from "react";
import PuntUsu from "../../comunes_events/imagen_usu_puntuacion";
import NombreCat from "../../comunes_events/nombre_cat_past";
import "./style.scss";
import "./modal.scss";
import Tags from "../../comunes/tag";
import { Link, withRouter } from "react-router-dom";
import Rating from "../../comunes_events/rating";
import NavBar_general from "../../comunes/navbar_influencer";
import Back from "../../comunes/back";
import NavBar_upcoming from "../../live_events_page_influencer/navbar_live_events";
import user from "assets/img/icons/ic-user.svg";
import user2 from "assets/img/icons/ic-raisehand.svg";
import user3 from "assets/img/icons/ic-price.svg";
import user5 from "assets/img/icons/ic-descuento.svg";
import LastImg from "assets/img/another-view2.png";
import Attendees from "../../comunes_events/attendees";
import { EventDAO } from "daos";
import { connect } from "react-redux";
import moment from "moment";
import Loader from "components/comunes/loader";
import Linkify from 'react-linkify';

const eventDAO = new EventDAO();

const initialValuesEventData = { event: {}, user: {}, influencer: {} };

const MyLiveEventInfluencer = ({ match, categories, languages }) => {
  const [eventData, setEvent] = useState(initialValuesEventData);
  const [loading, setLoading] = useState(true);
  const initEvent = async () => {
    const { id } = match.params;

    try {
      const response = await eventDAO.findById(id);
      const { data } = response.data;
      data.event.event_category = categories.find(
        category => category.cate_ide === data.event.event_category_ide
      );
      data.event.event_languages = languages.filter(
        language =>
          JSON.parse(data.event.event_lang_ides).indexOf(language.lang_ide) > -1
      );
      setEvent(data);
      setLoading(false);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (categories.length > 0 && languages.length > 0) initEvent();
  }, [categories, languages]);

  const {
    event_currency,
    event_title,
    event_category,
    event_description,
    event_languages,
    event_tags,
    event_date,
    event_start_hour,
    event_location,
    event_price_ticket,
    event_price_ticket_streaming,
    event_num_tickets_free,
    event_num_tickets_free_streaming,
    event_promotional_code_discount,
    event_uuid,
    event_cover_image
  } = eventData.event;
  const { user_username } = eventData.user;
  const {
    influencer: { influencer_avatar }
  } = eventData;

  return (
    <div class="upcoming-details">
      <header className="App-header">
        <NavBar_general />
        <NavBar_upcoming />
      </header>
      {!loading ? (
        <section className="Live-Event-Detail container-fluid">
          <div className="row">
            <Back
              text={"Back to my past event"}
              url={"/my_live_events/past_live_events"}
            />

            <div className="col-7 past mt-5">
              <PuntUsu
                userName={user_username}
                userAvatar={influencer_avatar}
              />
              <br />
              <NombreCat
                title={event_title}
                category={event_category.cate_description}
                hour={event_start_hour}
                month={moment.unix(parseInt(event_date) / 1000).format("MMMM")}
                year={moment(parseInt(event_date)).format("YYYY")}
                location={event_location && JSON.parse(event_location).city}
                day={moment(parseInt(event_date)).format("DD")}
              />
              <div>
                <Link to={`/preview_influencer/${event_uuid}`}>
                  <div className="box-streaming">
                    {" "}
                    <p className="start">20 Minutes to start streaming...</p>
                    <button className="hotspot">Start streaming</button>
                  </div>{" "}
                </Link>
                <img
                  className="w-100 br-10 img-past"
                  src={event_cover_image}
                  id="img_before_start"
                  alt=""
                />
                <div className="viewers-past">
                  <img src={user} alt=''/>
                  <p>845 Viewers</p>
                </div>
              </div>
              <p className="D-title">Live Event Description</p>
              <Linkify properties={{target: '_blank'}}>
              <p className="Desc">{event_description}</p>
              </Linkify>
              <p className="D-title">Language</p>
              <div className="d-flex align-items-center p-1 Cg ">
                {event_languages.map(language => (
                  <div key={language.lang_ide}>
                    <img
                    style={{height:'25px'}}
                      className="lenguage"
                      src={language.lang_image}
                      alt=""
                    />
                    &nbsp; {language.lang_description} &nbsp; &nbsp;
                  </div>
                ))}
              </div>
              <p className="D-title">Related Tags</p>
              {event_tags && event_tags.length>2 &&event_tags.split(",").map((tag, index) => (
                <Tags key={index} name={tag.replace(/[^a-zA-Z ]/g, "")} />
              ))}
              <br />
              <br />
              <br />
              <br />
              <br />
              <div className="d-flex div_private_information">
                <div>
                  <p className="information">Private Information</p>
                  <div className="d-flex information_detalles">
                    <img src={user} alt=""/>
                    <div>
                      <p className="m-0">Price per ticket</p>
                      <p className="price_information">
                        $ {event_price_ticket!==null? event_price_ticket: event_price_ticket_streaming}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex information_detalles">
                    <img src={user2} alt=""/>
                    <div>
                      <p className="m-0">Number of tickets</p>
                      <p className="price_information">
                        {event_num_tickets_free!==null? event_num_tickets_free : event_num_tickets_free_streaming}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex information_detalles">
                    <img src={user3} alt=""/>
                    <div>
                      <p className="m-0">Gross Revernus (sold out)</p>
                      <p className="price_information">$ 120.00</p>
                    </div>
                  </div>
                  <div className="d-flex information_detalles">
                    <img src={user} alt=""/>
                    <div>
                      <p className="m-0">Ticketing Fees</p>
                      <p className="price_information">$ 2.00</p>
                    </div>
                  </div>
                  <div className="d-flex information_detalles">
                    <img src={user5} alt=""/>
                    <div>
                      <p className="m-0">Discount Code</p>
                      <p className="price_information">
                        {" "}
                        {event_promotional_code_discount}%
                      </p>
                    </div>
                  </div>
                </div>
                <div className="politic_div">
                  <h4>Rescheduling policy</h4>
                  <p className="parrafo_politicas">
                    Once per event; to a later date/time; up to 24 hours before
                    the event; viewer can ask refund anytime; score shall be
                    affected
                  </p>
                  <h4>Cancellation policy</h4>
                  <p className="parrafo_politicas">
                    Up to 24 hours before the event, viewer will get full
                    refund, score shall be affected.
                  </p>
                  <h4>Influencer's delay longer than 10 minutes or no-show</h4>
                  <p className="parrafo_politicas">
                    Viewer will get full refund; score shall be heavily affected
                  </p>
                  <Link to={`/my_live_events`}>
                    <button
                      className="request-streaming cancel"
                      id="cancel_live_event"
                    >
                      Cancel live event
                    </button>
                  </Link>
                  <button
                    className="request-streaming send_request"
                    id="edit_live_event"
                  >
                    Edit Live Event
                  </button>
                </div>
              </div>
            </div>

            <div className="col-3 past_chat_event ml-5">
              <p className="D-title p-0 mb-3 mt-4">Share Live Event</p>
              <div className="d-flex justify-content-around">
                <i className="fab fa-facebook face"></i>
                <i className="fab fa-linkedin linkedin"></i>
                <i className="fab fa-twitter twitter "></i>
                <i className="fab fa-whatsapp whats"></i>
                <i className="fas fa-envelope google"></i>
                <i className="fas fa-link google"></i>
              </div>
              <p className="D-title p-0 mb-3 mt-5 ">Live chat playback</p>
              <div className="Attendes-div">
                {eventData.attendees_users.length > 0 && (
                  <>
                    <p className="D-title p-0 mb-3 mt-5 ">
                      {eventData.attendees_users.length} Attendees
                    </p>
                    <div className="Attendes-div relate-div">
                      {eventData.attendees_users.map((item, index) => (
                        <Attendees key={index} user={item} />
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="Live-Event-Detail container">
          <Loader />
        </section>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories,
    languages: state.languages.languages
  };
};

export default withRouter(connect(mapStateToProps)(MyLiveEventInfluencer));
