import { EventRequestDAO } from "daos";
import { useReducer, useEffect, useCallback } from "react";
import reducer from "./reducer";
import { actionTypes } from "./actions";
import statesEventRequest from "../../../constants/statesEventRequest";
import { search } from "../../../utils";
import i18next from 'i18next';
//i18next.t('UsePrivateMessageViewerLogicComponent')
const eventRequestDAO = new EventRequestDAO();

export const usePrivateMessageViewerLogic = ({ showAlert }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { token } = JSON.parse(localStorage.getItem("env"));

  const initRequests = useCallback(async () => {
    dispatch({ type: actionTypes.ON_GET_REQUEST });
    try {
      const { data } = await eventRequestDAO.getMyRequests(token);
      const requests = data.data;
      Object.keys(requests).forEach(
        key =>
          (requests[key] = requests[key].map(request =>
            initMessagesInRequest(request)
          ))
      );
      dispatch({
        type: actionTypes.ON_SUCCESS_GET_REQUEST,
        requests
      });
    } catch (err) {
      dispatch({ type: actionTypes.ON_FINISH_GET_REQUEST });
      const { status } = err.response;
      if (status === 400) {
        showAlert(err.response.data.error[0].message);
      } else {
        showAlert(i18next.t('UsePrivateMessageViewerLogicComponentSorryError'));
      }
    }
  }, [token, showAlert]);

  useEffect(() => {
    
    if (Object.keys(state.requests).length>0) {
      const requests = [
        ...(state.requests.pending || []),
        ...(state.requests.accepted || []),
        ...(state.requests.rejected || [])
      ]
    //   dispatch({
    //     type: actionTypes.ON_SET_REQUEST_ACTIVE,
    //     requestActive: requests[requests.length-1]
    //   });
    //   
    //   
    }
  }, [state.requests]);

  /**
   * Set the messages of a request.
   * @param {Object} request
   * @returns {Object} return request with key "messages"
   */

  const initMessagesInRequest = data => {
    let messages = [];
        const { request, influencer_user } = data;
    
    const { request_state, request_updated_date, request_created_date, request_rejected_date, request_cancelled_date, request_reason} = request;
    messages.push({
      data: {
        text: i18next.t('UsePrivateMessageViewerLogicComponentYourEventRequestHasBeenSentTo', {username: influencer_user.user_username})
      },
      type: "text",
      author: "me",
      date: request_created_date
    });
    const labelRequestState = value =>
      Object.keys(statesEventRequest).find(
        key => statesEventRequest[key] === value
      );


    const hasBeenModifiedPreviously = request_updated_date !== request_created_date;
    const hasBeenModifiedAndRejected= request_rejected_date === request_updated_date
    switch (request_state) {
      case statesEventRequest.modified:
        messages = [...messages, addMessage({
          date: request_updated_date,
          text: i18next.t('UsePrivateMessageViewerLogicComponentEventRequestModifiedBy', {username: influencer_user.user_username}),
          author: 'Influencer'
        })]
        break;
      
      case (statesEventRequest.accepted ) :
        messages = [...messages, addMessage({
          date: request_updated_date,
          text: i18next.t('UsePrivateMessageViewerLogicComponentHasBeenAcceptedBy', {username: influencer_user.user_username}),
          author: 'Influencer'
        })]
        break;
      case ( statesEventRequest.pendingPayment ) :
        messages = [...messages, addMessage({
          date: request_updated_date,
          text: i18next.t('UsePrivateMessageViewerLogicComponentHasBeenAcceptedBy', {username: influencer_user.user_username}),
          author: 'Influencer'
        })]
        break;
      case statesEventRequest.rejected:
        messages = [...messages, addMessage({
          date: request_updated_date,
          text: i18next.t('UsePrivateMessageViewerLogicComponentHasBeenRejectedBy', {username: influencer_user.user_username}),
          author: 'Influencer'
        })]
        break;
      case (statesEventRequest.acceptedModified || (statesEventRequest.rejected && hasBeenModifiedPreviously)):
        messages = [...messages, 
        addMessage({ date: request_updated_date,
          text: i18next.t('UsePrivateMessageViewerLogicComponentHasModifiedYourPrivateRequest', {username: influencer_user.user_username}),
          author: 'Influencer'
        }),
        addMessage({
          date: request_updated_date,
          text: i18next.t('UsePrivateMessageViewerLogicComponentHasAcceptedTheModifiedRequest'),
          author: 'me'
        })
      ]
      break;
      case (statesEventRequest.pendingModified ):
        messages = [...messages, 
        addMessage({ date: request_updated_date,
          text: i18next.t('UsePrivateMessageViewerLogicComponentHasModifiedYourPrivateRequest', {username: influencer_user.user_username}),
          author: 'Influencer'
        }),
        addMessage({
          date: request_updated_date,
          text: i18next.t('UsePrivateMessageViewerLogicComponentHasAcceptedTheModifiedRequest'),
          author: 'me'
        })
      ]
      break;
      case (statesEventRequest.rejected && !hasBeenModifiedPreviously):
          messages = [...messages, addMessage({
            date: request_updated_date,
            text: i18next.t('UsePrivateMessageViewerLogicComponentInfluencerHasCustomState', {username: influencer_user.user_username, custom: labelRequestState(request_state)}),
            // text: `You have ${labelRequestState(request_state)} the request for event made by ${user.user_name} ${user.user_lastname}`,
            author: 'Influencer'
          })]
        break;
      case (statesEventRequest.cancelled ):
        
          messages =  parseInt(request_updated_date) > parseInt(request_created_date) ? [...messages, addMessage({
            date: request_updated_date,
            text: i18next.t('UsePrivateMessageViewerLogicComponentHasModifiedYourPrivateRequest', {username: influencer_user.user_username}),
            author: 'Influencer'
            }),
          addMessage({
            date: request_updated_date,
            text: i18next.t('UsePrivateMessageViewerLogicComponentYouHaveCancelled'),
            author: 'me'
          }),
        ]:
        [...messages, addMessage({
          date: request_updated_date,
          text: i18next.t('UsePrivateMessageViewerLogicComponentYouHaveCancelled'),
          author: 'me'
      }),
      ]
        break;
      default:
        break;
    }
    request.messages = messages;
    return data;
  };
  const addMessage = ({ date, text, author }) => {
    let newMessage = {
      data: {
        text
      },
      type: "text",
      author,
      date: date
    }
    return newMessage
  }

   
  const onChangeSearchInput = e =>
    dispatch({ type: actionTypes.ON_SET_SEARCH_INPUT, value: e.target.value });

  const onRequestActive = requestActive =>
    dispatch({ type: actionTypes.ON_SET_REQUEST_ACTIVE, requestActive });

  const onAcceptRequest = async request_ide => {
    dispatch({ type: actionTypes.ON_ACCEPT_REQUEST });

    try {
      await eventRequestDAO.acceptEventRequestInfluencer(token, {
        request_ide
      });
      dispatch({ type: actionTypes.ON_FINISH_ACCEPT_REQUEST });
      showAlert(i18next.t('UsePrivateMessageViewerLogicComponentGreatEventCreated'));
      initRequests();
    } catch (err) {
      dispatch({ type: actionTypes.ON_FINISH_ACCEPT_REQUEST });
      const { status } = err.response;
      if (status === 400) {
        showAlert(err.response.data.error[0].message);
      } else {
        showAlert(i18next.t('UsePrivateMessageViewerLogicComponentSorryError'));
      }
    }
  };

  const onRejectRequest = async request_ide => {
    dispatch({ type: actionTypes.ON_REJECT_REQUEST });

    try {
      await eventRequestDAO.rejectRequest(token, {
        request_ide
      });
      dispatch({ type: actionTypes.ON_FINISH_REJECT_REQUEST });
      showAlert(i18next.t('UsePrivateMessageViewerLogicComponentPrivateRequestRejected'));
      initRequests();
    } catch (err) {
      dispatch({ type: actionTypes.ON_FINISH_REJECT_REQUEST });
      const { status } = err.response;
      if (status === 400) {
        showAlert(err.response.data.error[0].message);
      } else {
        showAlert(i18next.t('UsePrivateMessageViewerLogicComponentSorryError'));
      }
    }
  };

  /**
   * @param {Array} data
   * @returns {Array}
   */
  const getFilteredRequests = data => {
    const { searchInput } = state;
    if (!searchInput) return data;

    // return requests.filter((request) =>
    //   search(
    //     `${request.request_reason}`,
    //     // `${influencer_user.user_name} ${influencer_user.user_lastname} ${request.request_reason}`,
    //     searchInput
    //   )
    // );
    return data.filter(({ influencer_user }) =>
      search(
        `${influencer_user.user_name} ${influencer_user.user_lastname}`,
        searchInput
      )
    );
  };

  useEffect(() => {
    initRequests();
  }, [initRequests]);

  return {
    state,
    onRequestActive,
    onAcceptRequest,
    onRejectRequest,
    getFilteredRequests,
    onChangeSearchInput
  };
};

const getInitialState = () => ({
  requests: [],
  loadingRequests: true,
  requestActive: {},
  accepting: false,
  rejecting: false,
  searchInput: ""
});
