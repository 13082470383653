import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Api , LOGIN_BACK_URL} from "../../../../environment/environment";
import { withRouter } from "react-router-dom";
import SweetAlert from "sweetalert2-react";
import * as actions from '../../../../store/actions/user';
import {useDispatch } from 'react-redux'
import { parseLocale } from 'utils/Localization'

const VerifyInfluencerLinkedin = ({ history }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch()
  const verify = useCallback(async () => {
    const url = new URL(window.location.href.replace("#", ""));
    const code = url.searchParams.get("code");
    const actionLinkedin = localStorage.getItem("LinkedinAction");
    const redirectUrl = localStorage.getItem("redirectUrl");

    if (
      !code ||
      (actionLinkedin !== "verify" || !actionLinkedin)
    )
      return;

    const myToken = JSON.parse(localStorage.getItem("env"));
    try {
      await axios.post(
        `${Api}user/verify_linkedin`,
        { code, "redirect_url": LOGIN_BACK_URL },
        {
          headers: {
            "Access-Token": myToken.token,
            "Content-Type": "application/json",
            "Content-Language": parseLocale()

          }
        }
      );
      setErrorMessage("Your LinkedIn profile was verified!")
      dispatch(actions.getUser(myToken.token))
    } catch (err) {
      setErrorMessage("We were unable to verify your LinkedIn profile");
    } finally {
      history.push(redirectUrl? redirectUrl: '/');

      localStorage.removeItem("LinkedinAction");
      localStorage.removeItem("redirectUrl");

    }
  }, [history]);

  useEffect(() => {
    verify();
  }, [verify]);

  return (
    errorMessage && (
      <SweetAlert
        show={errorMessage}
        title={errorMessage}
        onConfirm={() => {
          setErrorMessage("");
          // history.push("/");
        }}
      />
    )
  );
};

export default withRouter(VerifyInfluencerLinkedin);
