
  export const reasons = [
     {
      name: 'Just for fun', 
      secondaryName: 'Have a good time with you',
      request_type: 0,
      options: [
        {optionId: 1, optionName:'Party Appearance' },
        {optionId: 2, optionName:'Show'},
        {optionId: 3, optionName:'Playing games' },
        {optionId: 4, optionName:'Hangout' },
        {optionId: 5, optionName:'Just For Fun (other)' },
      ]
    },
     {
      name: 'Business', 
      secondaryName: `I've a business proposal for you`,
      request_type: 6,
      options: [
        {optionId: 7, optionName:'Meeting Request' },
        {optionId: 8, optionName:'Consultant Request' },
        {optionId: 9, optionName:'Business Proposal' },
        {optionId: 10, optionName:'Partnership' },
        {optionId: 11, optionName:'Coaching/Training' },
      
      ]
    },
   
     {
      name: 'Education',
      secondaryName: 'I would like to learn from you',
 
      request_type:13,
      options: [
        {optionId: 14, optionName:'Masterclass' },
        {optionId: 16, optionName:'Tutorial' },
        {optionId: 15, optionName:'Workshop' },
        {optionId: 17, optionName:'Training' },
      
      ]
    },
     {
      name: 'Other reasons', 
      secondaryName: 'I have a special request',

      request_type:19,
      options: [
        {optionId:20, optionName: 'Sharing skills or experiences'},
        {optionId:21, optionName: "Coaching/Training (other)"}
      ]
    }
  ]
  export const reasonsES = [
    {
     name: 'Solo por diversión', 
     secondaryName: 'Pasar un buen rato',
     request_type: 0,
     options: [
       {optionId: 1, optionName:'Fiesta' },
       {optionId: 2, optionName:'Show'},
       {optionId: 3, optionName:'Jugar algún juego' },
       {optionId: 4, optionName:'Salir' },
       {optionId: 5, optionName:'Solo por diversión (otro)' },
     ]
   },
    {
     name: 'Negocios', 
     secondaryName: `Tengo una propuesta de negocio para ti`,
     request_type: 6,
     options: [
       {optionId: 7, optionName:'Convocatoria de reunión' },
       {optionId: 8, optionName:'Solicitud de consulta' },
       {optionId: 9, optionName:'Propuesta comercial' },
       {optionId: 10, optionName:'Asociación' },
       {optionId: 11, optionName:'Coaching/Entrenamiento' },
     
     ]
   },
  
    {
     name: 'Educación',
     secondaryName: 'Me gustaria aprender de ti',

     request_type:13,
     options: [
       {optionId: 14, optionName:'Masterclass' },
       {optionId: 16, optionName:'Tutorial' },
       {optionId: 15, optionName:'Taller' },
       {optionId: 17, optionName:'Formación' },
     
     ]
   },
    {
     name: 'Otras razones', 
     secondaryName: 'Tengo una solicitud especial',

     request_type:19,
     options: [
       {optionId:20, optionName: 'Compartir habilidades o experiencias'},
       {optionId:21, optionName: "Coaching/Entrenamiento (otro)"}
     ]
   }
 ]

  