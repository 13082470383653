import getUserLocale from 'get-user-locale';
import { IS_DEV_ENVIRONMENT, FORCE_LOCALIZATION } from '../environment/environment'
import { reasons, reasonsES } from '../utils/requestUtils'

const parseAvailableLocales = (locale) => {
  if (IS_DEV_ENVIRONMENT || FORCE_LOCALIZATION) {
    switch (locale) {
      case "es":
        return "es";
      default:
        return "en";
    }
  } else {
    return "en";
  }

}

export const parseLocale = () => {
  let locale = getUserLocale();

  try {
    if (locale.includes("-") || locale.includes("_")) {
      if (locale.includes("-")) {
        locale = locale.split("-")[0];
      } else {
        locale = locale.split("_")[0];
      }

      return parseAvailableLocales(locale);
    } else {
      return parseAvailableLocales(locale);
    }
  }
  catch (error) {
    locale = "en";
  }
}

export const parsePrivateRequestReasonsLocaleResources = () => {
  let locale = parseLocale();
  let resources = null;

  try {

    if(locale === "es"){
      resources = reasonsES;
    }else{
      resources = reasons;
    }
    
  }
  catch (error) {
    resources = reasons;
  }

  return resources;
}

