import React from "react";
import "./style.scss";
import logo from "../../../assets/img/logo-white.png";
import Menu from "../../comunes/navbar/menu";
import Login_menu from "../../comunes/login_menu";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

export default () => (
  <div className="Nav_search">
    <div className="Nav Bg-blue flex-column pb-0">
      <div className="d-flex justify-content-between w-100">
      <div className="d-flex">
      <a target="_blank" href="https://blog.gotmy.com/">
                    <img className="mr-3 " src={logo} className="Logo" alt="logo" />
                    </a>
                    <a style={{ color: "#FF5A60", alignSelf: 'flex-end',marginTop:'14px' }} className="align-bottom" target="_blank" href="https://blog.gotmy.com/" className="">
                        <h5>Blog</h5>

                    </a>
                </div>
        <Menu />
        <Login_menu />
      </div>
      {/*MENU LIVE EVENTS*/}
      <div className="Menu-live-Events">
        <div className="col-2"></div>
        {/* <NavLink
          exact
          to={`/message`}
          activeClassName="active_live_events"
          className="col-4"
        >
          Messages
        </NavLink> */}
        <NavLink
          exact
          to={`/message/private_request`}
          activeClassName="active_live_events"
          className="col-4"
        >
          Private Request
        </NavLink>
        <div className="col-2"></div>
      </div>
    </div>
  </div>
);
