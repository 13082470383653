import React, { useState, useEffect } from "react";
import Influencer_card from "../../../comunes/influencer_card";
import arrow from "assets/img/icons/ic-arrow-left.svg";
import { Link } from "react-router-dom";
import "./style.scss";
import PropTypes from "prop-types";
import { InfluencerDAO } from "daos";
import i18next from 'i18next';

const influencerDAO = new InfluencerDAO();

const Influencer_search = ({
  max,
  title,
  showAll,
  categoriesFilter,
  dataInjected
}) => {
  const [influencers, setInfluencers] = useState([]);
  const initInfluencers = async dataInjected => {
    try {
      let response;
      response = await influencerDAO.trendingInfluencers(categoriesFilter);
      setInfluencers(
        !dataInjected || !dataInjected.length
          ? response.data.data
          : dataInjected
      );
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    initInfluencers(dataInjected);
  }, [categoriesFilter]);

  return (
    <div className="container position-relative search_influencer">
      <div className="position-relative">

        {showAll && (
          <div style={{width:'100%', alignItems:'start', color:'#ff5a60'}} className="Show-all">
          <h2 style={{color:'#212529'}}>{title}</h2>
            <Link to={`/all_influencers`}>
              <span style={{ marginRight: '8px',color:'#ff5a60' }}>{i18next.t('InfluencerSearchComponentShowAll')}</span>
              <img src={arrow} alt="arrow" />
            </Link>
          </div>
        )}
      </div>
      <div className="row">
        {influencers.map((influencer, ind) => {
          console.log({influencer})
          if (ind < max) {

            return (
              <div
                className="col-lg-2 col-md-4 col-sm-4 col-xs-12 grow mb-5"
                key={influencer.influencer.influencer_ide}
              >
                <Influencer_card
                  name={`${influencer.user.user_username}`}
                  user_img={influencer.influencer.influencer_avatar}
                />
              </div>
            )
          }
        }
        )

        }
      </div>
    </div>
  );
};

Influencer_search.propTypes = {
  title: PropTypes.string.isRequired,
  showAll: PropTypes.bool,
  categoriesFilter: PropTypes.array,
  dataInjected: PropTypes.array
};

export default Influencer_search;
