import React, { useRef } from "react";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
import i18next from "i18next";
import { toast } from "react-toastify"; 
import { SHARE_ICONS_FLAT_BLACK } from "../../environment/environment";

const ShareEvent = ({ Url, title, isCreator, size }) => {
  const copyClipboardRef = useRef(null);
  return (
    <>
      <div className="d-flex justify-content-left align-content-start">
        <FacebookShareButton
          className="mr-3 grow"
          url={Url}
          title={
            isCreator
              ? i18next.t("ShareEventComponentInfluencerMessage", {
                  title: title,
                })
              : i18next.t("ShareEventComponentViewerMessage", { title: title })
          }
        >
          {" "}
          <FacebookIcon size={size ? size : 42} round={true} bgStyle={SHARE_ICONS_FLAT_BLACK ? {fill: "white"} : {} } iconFillColor={SHARE_ICONS_FLAT_BLACK ? "black" : "white"} />
        </FacebookShareButton>
        <TwitterShareButton
          className="mr-3 grow"
          url={Url}
          title={
            isCreator
              ? i18next.t("ShareEventComponentInfluencerMessage", {
                  title: title,
                })
              : i18next.t("ShareEventComponentViewerMessage", { title: title })
          }
        >
          {" "}
          <TwitterIcon size={size ? size : 42} round={true} bgStyle={SHARE_ICONS_FLAT_BLACK ? {fill: "white"} : {} } iconFillColor={SHARE_ICONS_FLAT_BLACK ? "black" : "white"}/>
        </TwitterShareButton>
        <LinkedinShareButton
          className="mr-3 grow "
          url={Url}
          title={
            isCreator
              ? i18next.t("ShareEventComponentInfluencerMessage", {
                  title: title,
                })
              : i18next.t("ShareEventComponentViewerMessage", { title: title })
          }
        >
          {" "}
          <LinkedinIcon size={size ? size : 42} round={true} bgStyle={SHARE_ICONS_FLAT_BLACK ? {fill: "white"} : {} } iconFillColor={SHARE_ICONS_FLAT_BLACK ? "black" : "white"}/>
        </LinkedinShareButton>
        <WhatsappShareButton
          className="mr-3 grow"
          url={Url}
          title={
            isCreator
              ? i18next.t("ShareEventComponentInfluencerMessage", {
                  title: title,
                })
              : i18next.t("ShareEventComponentViewerMessage", { title: title })
          }
        >
          {" "}
          <WhatsappIcon size={size ? size : 42} round={true} bgStyle={SHARE_ICONS_FLAT_BLACK ? {fill: "white"} : {} } iconFillColor={SHARE_ICONS_FLAT_BLACK ? "black" : "white"}/>
        </WhatsappShareButton>
        <EmailShareButton
          className="mr-3 grow"
          url={Url}
          subject={title}
          body={
            isCreator
              ? i18next.t("ShareEventComponentInfluencerMessage", {
                  title: title,
                })
              : i18next.t("ShareEventComponentViewerMessage", { title: title })
          }
        >
          <EmailIcon
            bgStyle={{ fill: "#E0462E" }}
            size={size ? size : 42}
            round={true}
            bgStyle={SHARE_ICONS_FLAT_BLACK ? {fill: "white"} : {} } iconFillColor={SHARE_ICONS_FLAT_BLACK ? "black" : "white"}
          />
        </EmailShareButton>
        
      </div>

      <p style={{
        marginTop: 25,
        marginBottom: 10
      }}>{i18next.t("ShareEventComponentOrCopyTheLinkHere")}:</p>
      <CopyToClipboard
        style={{
          width: "100%",
        }}
        onCopy={() => {
          toast(i18next.t("ShareEventComponentCopied"));
        }}
        text={Url}
      >
        <Clip style={{ height: "42px", width: "42px", marginTop: "15px" }}>
        <span className={"LinkInput"}>{Url}</span>
        </Clip>
      </CopyToClipboard>
    </>
  );
};
export default ShareEvent;

const Clip = styled.i`
  :hover {
    cursor: pointer;
  }
`;
