import React from 'react'
import one from "assets/img/01.png";
import two from "assets/img/02.png";
import three from "assets/img/03.png";
import i18next from 'i18next';

const LandingCopy = ({ screenSize, sizeFactor }) => {
    return (
        <div >
            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", justifyContent: "flex-end" }}>
                <div style={{ justifySelf: "center", textAlign: screenSize === "sm" ? "center" : "left", maxWidth: 700, marginLeft: (screenSize === "xl" || screenSize === "lg") ? 20 * sizeFactor : 0 }}>
                    <h1 className="title" style={{ marginBottom: 12 }}>
                        <span style={{ fontWeight: 500 }}>
                            {i18next.t("ReferralHeader")}
                        </span>

                    </h1>
                    <h2 className="subTitle" >
                        {i18next.t("ReferralSubHeader")}
                    </h2>
                </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>

            <div>  




                <div className="step mt-lg-2 mt-xl-4"  >
                    <div>
                        <div className="d-flex mt-4 mt-lg-2 mt-xl-4">
                            <img src={one} className="numberImg" />
                            <div className="stepHeader">
                                <span style={{ fontWeight: 600 }}>
                                    {i18next.t("ReferralGetStarted")}
                                </span>
                                <div className="stepSubHeader" >
                                    <span style={{ fontWeight: 500 }}>{i18next.t("ReferralGetStartedSubheading")} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="step mt-lg-5 mt-xl-5"  >
                    <div>
                        <div className="d-flex mt-4 mt-lg-4 mt-xl-4">
                            <img src={two} className="numberImg" />

                            <div className="stepHeader">
                                <span style={{ fontWeight: 600 }}>
                                    {i18next.t("ReferralGetTheirAttention")}
                                </span>
                                <div className="stepSubHeader">
                                    <span style={{ fontWeight: 500 }}> {i18next.t("ReferralGetTheirAttentionSubheading")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 0 }} className="step mt-lg-3 mt-xl-3"  >
                    <div>
                        <div className="d-flex mt-4 mt-lg-2 mt-xl-4">
                            <img src={three} className="numberImg" />

                            <div className="stepHeader">
                                <span style={{ fontWeight: 600 }}>
                                    {i18next.t("ReferralGetAccess")}
                                </span>
                                <div className="stepSubHeader">
                                    <span style={{ fontWeight: 500 }}>  {i18next.t("ReferralGetAccessSubheading")} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
export default LandingCopy