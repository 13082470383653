import React, { useEffect, useState } from "react";
import "./style.scss";
import Search_input_peq from "../../comunes/search_input_peq";
import { connect } from "react-redux";
import PastEventCard from '../../comunes/Cards/PastEventCard'
import moment from "moment";
import Loader from "components/comunes/loader";
import i18next from 'i18next';

i18next.t("PastLiveEventsComponent")
function Past_live_events({  events }) {
  const now = new Date()
  const [pastEvents, setPastEvents] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (events && events.length>0){
      
    let filteredEvents = events.filter((event) => new Date(parseInt(event.event.event_streaming_end_date)) < now)
    setPastEvents(filteredEvents)
    setLoading(false)
    }
  }, [events])

  return (
    <div>
      <div className="container Past-live-events">
      {!loading ? 
      <>
        <div className="row" style={{marginBottom:'25px'}}>
      { <div className="d-none d-md-block col-md-9"><h2>{i18next.t("PastLiveEventsComponentPastLiveEvents")}</h2> </div> }
          <div className="d-none d-md-inline col-md-3">
          <Search_input_peq />
          </div>
        </div>
        <div className="row">


          {pastEvents && 
           pastEvents.map((event, index)=> {
             return(
              <PastEventCard
              key={index}
              user_img={event.user.user_avatar}
               bg_img={event.event.event_cover_image}
               name={event.user.user_username}
               time={event.event.event_start_hour}
               titulo={event.event.event_title}
               uuid={event.event.event_uuid}
               cat={event.event.event_category_ide}
               fecha={`${moment(parseInt(event.event.event_date)).format(
                 "dddd"
               )} ${moment(parseInt(event.event.event_date)).format(
                 "DD-MM-YYYY"
               )}`}
              />
             )
           }) 
           }
          {!pastEvents || (pastEvents && pastEvents.length === 0) &&
            <p style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", fontSize: "22px" }}>
             {i18next.t("PastLiveEventsComponentThereareNoPastEventsYet")}
          </p>
          }
        </div>
        </>
        :
        <Loader/>
  }
      </div>
      
    </div>
  );
}

const mapStateToProps = state => {
  return {
    categories: state.categories.categories,
    events: state.user.user.purchasedEvents
  };
};

export default connect(mapStateToProps)(Past_live_events);
