import React, { useState, useEffect } from 'react';
import "./styles.scss";
import NavBarStreaming from "../comunes_events/cat-fecha-city-pre";
import StopCallIcon from "assets/img/icons/hangCall.png";
import CameraDisabled from "assets/img/icons/ic-camera-off.svg";
import CameraEnabled from "assets/img/icons/ic-camera-on-white.svg";
import logo from "assets/img/icons/reloadLogo.png";
import logoCirculo from "assets/img/icons/reloadLogo.png";
//import logoCirculo from "assets/img/icons/reloadselec.png";
import Request from "assets/img/icons/ic-requests.svg";
import Open from "assets/img/icons/ic-raisehand.svg";
import OpenMicEnabled from "assets/img/icons/ic-openModeWhite.svg";
import enterFullScreen from "assets/img/icons/switch-to-full-screen-button.svg";
import exitFullScreen from "assets/img/icons/full-screen-exit.svg";
import MicroDisabled from "assets/img/icons/ic-streaming-mic-off.svg";
import MicroEnabled from "assets/img/icons/ic-streaming-mic-white.svg";
import chatBubble from "assets/img/icons/chat-bubble.png";
import { withRouter, Link, Redirect } from "react-router-dom";
//import { usePreviewLogic } from "./PreviewStreamingLogicOld";
import { usePreviewLogic } from "./PreviewStreamingLogic";
import { connect } from "react-redux";
import { Row, Col, Container, Card } from "react-bootstrap";
import iconUser from "assets/img/icons/user-grey.svg";
import icTime from "assets/img/icons/ic-time.svg";
import icDelete from "assets/img/icons/ic-delete.svg";
import logoGotmy from "assets/img/logo.png"
import information from "assets/img/icons/information.svg"
import * as actions from "./../../store/actions";
import AttendeesModerator from "components/comunes_events/attendees_moderator";
import UserModerator from "components/comunes_events/attendees_influencer";
import ExitModerator from "assets/img/icons/ic-detele.svg";
import Draggable, { DraggableCore } from 'react-draggable';
import HLSPlayer from './components/HLSPlayer'
import { Spinner } from "react-bootstrap";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import { Modal as ModalBoostrap, Button, ListGroup, ListGroupItem } from "react-bootstrap";
import warning from "assets/img/icons/warning.svg";
import SweetAlert from "sweetalert2-react";
import PuntUsu from "../comunes_events/imagen_usu_puntuacion_streaming";
import StarRatingComponent from "react-star-rating-component";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Offline, Online, Detector } from "react-detect-offline";
import { IS_DEV_ENVIRONMENT } from "environment/environment";
import ReactTooltip from 'react-tooltip';
import i18next from 'i18next';
import styles from '../../assets/css_general/general.scss'
import { IoWarningOutline } from "react-icons/io5";
import { IconContext } from "react-icons";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import {isMobile} from "react-device-detect";

const maxCharacters = 200;

const PreStreamingInfluencer = ({
  match,
  user,
  influencer,
  //isInfluencer,
  history,
  onSetOptionsModerators
}) => {
  const {
    eventData,
    streamingData,
    message,
    setMessage,
    sessionError,
    onSendMessage,
    onLeaveLiveEvent,
    disconnectEvent,
    setSessionError,
    isCreator,
    streamingFinished,
    setStreamingFinished,
    feedBackText,
    setFeedBackText,
    onSendFeedback,
    feedBackScore,
    setFeedBackScore,
    renderRedirect,
    setRenderRedirect,
    showDisconnectDialog,
    setShowDisconnectDialog,
    onLeaveEventStandard,
    setNetworkStatus,
    networkStatus,
    chats,
    chatDisabled,
    streamInitialized,
    activeViewers,
    scrollDownChat,
    streamingError,
    setStreamingError,
    ticketInUse,
    socketIoConnected,
    socketIoInitialized
  } = usePreviewLogic({
    user,
    influencer,
    //isInfluencer,
    history,
    uuid: match.params.id,
    onSetOptionsModerators
  });

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const [backgroundAnimation, setBackgroundAnimation] = useState("initializeVideoDescriptionBackground");
  const [streamingInitiated, setStreamingInitiated] = useState(false);
  const [instructionsVisibility, setInstructionsVisibility] = useState(true);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [sessionReady, setSessionReady] = useState(false)
  const [showViewersModal, setShowViewersModal] = useState(false)
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [retryPlayer, setRetryPlayer] = useState(false);
  const [streamConnected, setStreamConnected] = useState(true);
  const [showInstructionsRTMP, setShowInstructionsRTMP] = useState(true);

  const handle = useFullScreenHandle();

  function closeModal() {
    setShowViewersModal(!showViewersModal);
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount


  useEffect(() => {

    if (eventData.event && eventData.event.influencer && user && user.user_ide) {
      setIsInfluencer(eventData.event.influencer.influencer_user_ide == user.user_ide);
    }
  }, [eventData.event, user])


  useEffect(() => {
    if (!sessionReady) {
      //setSessionReady(true);
      if (sessionError) {

        setSessionReady(false);
      }
    }
  }, [sessionError])


  const submitMessage = () => {

    if (message.length <= maxCharacters && message && message.trim()) {
      onSendMessage();
    }
  }


  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      submitMessage();
    }
  }

  const renderOptionsFab = () => {
    if (!isCreator) {
      return null;
    }

    return (
      <Fab
        mainButtonStyles={{
          backgroundColor: styles.mainColor,
          backgroundImage: styles.mainColor,
        }}
        icon={<IoWarningOutline
          size={35}
        />}
        alwaysShowTitle={true}
        onClick={() => {
          setShowInstructionsRTMP(true)
        }}
      >
      </Fab>
    )
  }

  const parseSupportMessage = (text) => {
    var result = text.replace("%email", '<a href = "mailto: support@gotmy.com">support@gotmy.com</a>')

    return result;
  }

  const renderSideBar = () => {

    if (!isCreator) {
      return null;
    }

    return (
      <ModalBoostrap
        show={showInstructionsRTMP}
        onHide={() => { setShowInstructionsRTMP(false) }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBoostrap.Header closeButton>
          <ModalBoostrap.Title id="contained-modal-title-vcenter">
            {i18next.t('StreamingHLSComponentInstructionsHeader')}
          </ModalBoostrap.Title>
        </ModalBoostrap.Header>
        <ModalBoostrap.Body>
          <p>{i18next.t('StreamingHLSComponentReminderInstructionsDescription')}</p>
          <ListGroup
            style={{
              marginBottom: "0.5%"
            }}
          >
            <ListGroup.Item>RTMP URL ({i18next.t('StreamingHLSComponentReminderInstructionsServer')}): {streamingData.influencerStreamingData.primary_server}</ListGroup.Item>
            <ListGroup.Item>STREAM NAME ({i18next.t('StreamingHLSComponentReminderInstructionsStreamName')}): {streamingData.influencerStreamingData.stream_name}</ListGroup.Item>
          </ListGroup>
          <p>
            {i18next.t('StreamingHLSComponentReminderInstructionsDescriptionExtended')}
          </p>


        </ModalBoostrap.Body>

        <ModalBoostrap.Footer>
          <Button
            className='normalConfirmationButton'
            onClick={() => {
              setShowInstructionsRTMP(false)
            }}>{i18next.t('StreamingHLSComponentReminderInstructionsClose')}</Button>
        </ModalBoostrap.Footer>

      </ModalBoostrap>
    );
  }




  const renderConfirmationCloseEvent = () => {
    if (isInfluencer) {
      return (
        <ModalBoostrap centered show={showDisconnectDialog} onHide={() => { setShowDisconnectDialog(false) }}>
          <ModalBoostrap.Header closeButton>
            <ModalBoostrap.Title>{i18next.t('PreviewStreamingInfluencerComponentDoYouWantToFinishTheEvent')}</ModalBoostrap.Title>
          </ModalBoostrap.Header>

          <ModalBoostrap.Body>
            <p>{i18next.t('PreviewStreamingInfluencerComponentDoYouWantToFinishTheEventBody')}</p>
          </ModalBoostrap.Body>

          <ModalBoostrap.Footer>
            <Button
              onClick={() => {
                onLeaveLiveEvent()
              }}
              className='normalConfirmationButton'>{i18next.t('PreviewStreamingInfluencerComponentYes')}</Button>
            <Button
              onClick={() => {
                onLeaveEventStandard()
              }}
              className='specialConfirmationButton'>{i18next.t('PreviewStreamingInfluencerComponentDisconnectNotFinishingEvent')}</Button>
            <Button
              onClick={() => {
                setShowDisconnectDialog(false)
              }}
              className='normalConfirmationButton'>{i18next.t('PreviewStreamingInfluencerComponentNo')}</Button>
          </ModalBoostrap.Footer>
        </ModalBoostrap>
      )
    } else {
      return (
        <ModalBoostrap show={showDisconnectDialog} onHide={() => { setShowDisconnectDialog(false) }}>
          <ModalBoostrap.Header closeButton >
            <ModalBoostrap.Title>{i18next.t('PreviewStreamingInfluencerComponentDoYouWantToGoOutFromTheEvent')}</ModalBoostrap.Title>
          </ModalBoostrap.Header>

          <ModalBoostrap.Body>
            <p>{i18next.t('PreviewStreamingInfluencerComponentDoYouWantToGoOutFromTheEventBody')}</p>
          </ModalBoostrap.Body>

          <ModalBoostrap.Footer>
            <Button
              onClick={() => {
                onLeaveEventStandard()
              }}
              className='normalConfirmationButton'>{i18next.t('PreviewStreamingInfluencerComponentYes')}</Button>
            <Button
              onClick={() => {
                setShowDisconnectDialog(false)
              }}
              className='normalConfirmationButton'>{i18next.t('PreviewStreamingInfluencerComponentNo')}</Button>
          </ModalBoostrap.Footer>
        </ModalBoostrap>
      )
    }
  }

  const renderStreamFinishedModal = () => {

    var eventDataParsed = null;

    try {
      const {
        event: {
          event_title,
          event_start_hour,
          event_location,
          event_date,
          //event_category: { cate_description }
        },
        influencer: { influencer_avatar },
        user: { user_username }
      } = eventData.event;

      eventDataParsed = eventData.event;

    }
    catch {

    }

    if (!isInfluencer && eventDataParsed) {
      const {
        event: {
          event_title,
          event_start_hour,
          event_location,
          event_date,
          //event_category: { cate_description }
        },
        influencer: { influencer_avatar },
        user: { user_username }
      } = eventData.event;


      return (
        <ModalBoostrap
          show={streamingFinished}
        >
          <ModalBoostrap.Body>
            <div className="modal-body modal_rating_body">
              <div className="d-flex flex-column align-items-center">
                <div className="Div-g w-100">
                  <PuntUsu username={user_username} avatar={influencer_avatar} />
                  <div className="d-flex align-items-center flex-column">
                    <h2 className="mb-1" style={{
                      textAlign: 'center'
                    }}>{i18next.t('PreviewStreamingInfluencerComponentThanksForAttending')}</h2>
                    <h3>{i18next.t('PreviewStreamingInfluencerComponentWriteAReview')}</h3>
                    <div class="aling-item-center">
                      <StarRatingComponent
                        name="feedback"
                        value={feedBackScore}
                        starCount={5}
                        onStarClick={nextValue => setFeedBackScore(nextValue)}
                      />
                    </div>
                    <div class="form-group w-70 ml-3">
                      <textarea
                        placeholder={i18next.t('PreviewStreamingInfluencerComponentReviewPlaceholder')}
                        class="form-control message"
                        rows="5"
                        id="comment"
                        value={feedBackText}
                        onChange={e => setFeedBackText(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="Div-m d-flex m-auto justify-content-center w-100">

                  <div>
                    <button
                      className="Btn-rojo mt-4 feedback"
                      onClick={() => {
                        onSendFeedback();
                      }}
                    >
                      {i18next.t('PreviewStreamingInfluencerComponentSendFeedback')}
                    </button>
                  </div>
                </div>
                <div className="Div-m d-flex m-auto justify-content-center w-100">
                  <div>
                    <button
                      className="Btn-rojo mt-4 feedback"
                      onClick={() => {
                        setRenderRedirect(true);
                      }}
                    >
                      {i18next.t('PreviewStreamingInfluencerComponentSkip')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBoostrap.Body>
        </ModalBoostrap>
      )
    } else {
      return null;
    }
  }

  const retryOnError = () => {
    setRetryPlayer(true);

    setTimeout(() => {
      setRetryPlayer(false);
    }, 500)
  }

  const onStreamConnected = (mode) => {
    setStreamConnected(mode);
  }

  const renderHLSPlayer = () => {

    if (!eventData.event || !streamingData.posterURL || !socketIoInitialized ) {
      return null;
    }

    return (
      <HLSPlayer
        streamingData={streamingData}
        retryOnError={retryOnError}
        onStreamConnected={onStreamConnected}
        ticketInUse={!socketIoConnected}
      />
    );
  }

  const renderStreamLoader = () => {


    return (
      <div
        style={{
          display: (streamConnected && streamInitialized && !ticketInUse) ? 'none' : 'flex'
        }}
        className="loaderStreamingModal"
      >
        <Col
          className={"align-items-center"}
          style={{
            textAlign: 'center',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            justifyItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <img src={logoGotmy}
            style={{
              width: "20%"
            }}
          />
          <Spinner
            style={{
              width: 70,
              height: 70
            }}
            animation="grow" variant="danger" />

          <h2>{i18next.t('HLSStreamComponentLoadingStream')}</h2>
          <h3>{i18next.t('HLSStreamComponentLoadingStreamDescription')}</h3>

        </Col>
      </div>
    );

  }

  const renderChat = () => {

    return (
      <Col
        lg={{ size: 3 }}
        md={12}
        className="special_border_radius chatContainer messageContainerViewerRTMP animate__animated animate__fadeIn"
        sm="12"
        style={{
          backgroundColor: "#252233",
          color: 'white',
        }}
      >
        <h2 className="mb-0 rojo " style={{ textAlign: 'center', paddingTop: '5%', paddingBottom: '5%' }}>{i18next.t('PreviewStreamingInfluencerComponentLiveChat')}</h2>
        <div
          id="messageContainerViewer"
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            marginTop: "3%",
            marginBottom: "1.5%"
          }}
        >
          <ul
            className="list-unstyled chatSize"
            style={{
              wordBreak: 'break-word'
            }}
          >
            {chats.map(({ avatar, username, message, uuid }, index) => {

              return (
                <li className="list_chat_details_streaming" key={uuid}>
                  <Row>
                    <Col
                      xs={{ span: 2, offset: 1 }}
                      md={{ span: 2, offset: 1 }}
                      lg={{ span: 1, offset: 1 }}
                    >
                      <img
                        className="messageUserAvatar"
                        style={{
                          borderRadius: "50%"
                        }}
                        src={avatar}
                        alt=""
                      />
                    </Col>
                    <Col
                      xs={{ span: 8 }}
                      md={{ span: 6, offset: 1 }}
                      lg={{ span: 8 }}
                    >
                      <p
                        className="rojo forceBold"
                        style={{
                          fontSize: "17px",
                          marginBottom: '0.25rem'
                        }}
                      >
                        {username}
                      </p>
                      <p style={{}}>{message}</p>
                    </Col>
                  </Row>
                </li>
              )

            })}

            {scrollDownChat()}

            {!chatDisabled && (<h3
              className='chatDisabled'
            >{i18next.t('PreviewStreamingInfluencerComponentChatTemporarilyDisabled')}</h3>)}
          </ul>
        </div>
        <Row
          className="col-md-12 animate__animated animate__fadeInUp chatInput"
        >
          <Col xs="12">
            <textarea
              className="form-control message_chat"
              rows="5"
              disabled={!chatDisabled}
              value={message}
              onKeyDown={onEnterPress}
              onChange={event => {
                setMessage(event.target.value)
              }}
              style={{ width: "100% !important", right: 0 }}
            />
            <button
              className="Btn-rojo mt-4 send_influencer"
              onClick={() => {
                submitMessage()
              }}
              disabled={!chatDisabled}
              style={{ left: "0px !important" }}
            >
              {i18next.t('PreviewStreamingInfluencerComponentSend')}
            </button>
            <p className="caracteres_chat_influencer"
              style={{
                color: message.length > maxCharacters ? "red" : "white"
              }}
            >{message.length}/{maxCharacters}</p>
          </Col>
        </Row>
      </Col>

    )

    return null;
  }



  var DetectRTC = require('detectrtc');
  DetectRTC.load();

  if (!eventData.event) return null;

  var eventLocation = null;

  try {
    eventLocation = JSON.parse(eventData.event.event.event_location).city
  } catch {
    eventLocation = ""
  }


  return (
    <div className="streaming">
      {renderOptionsFab()}
      {
        <Detector
          render={({ online }) => {
            return null
          }}
          onChange={(state) => {


            setNetworkStatus({
              status: state,
              previousStatus: networkStatus.status
            });

          }}
        />
      }

      {renderConfirmationCloseEvent()}
      {renderRedirect ? (<Redirect to={isInfluencer ? "/my_live_events" : "/"} />) : null}
      <header className="app-streaming dropShadow">
        <NavBarStreaming
          onLeaveLiveEvent={() => { onLeaveLiveEvent() }}
          eventLocation={eventLocation}
          setShowDisconnectDialog={setShowDisconnectDialog}
          to={`/event/${match.params.id}`}
        />
      </header>
      <Container
        fluid="sm"
        style={isMobile ? {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center'
        } : {

        }}
      >

        {renderSideBar()}


        <SweetAlert
          imageUrl={warning}
          show={sessionError}
          title={sessionError}
          onConfirm={() => setSessionError(null)}
        />

        <SweetAlert
          imageUrl={warning}
          show={streamingError}
          title={streamingError}
          text={i18next.t("HLSBroadcastComponentTicketInUseDescription")}
          onConfirm={() => {
            setStreamingError("");
            setRenderRedirect(true);
          }}
        />

        <Row
          className="col-md-12 streamingContainer"
        >
          <Col
            lg="8"
            md="12"
            sm="12"
            className={"special_border_radius mediaPlayer streamingLayout"}
            style={{
              justifyContent: 'center',
              verticalAlign: 'middle',
              display: 'flex',
              marginRight: '1%',
              padding: 0
            }}
          >
            {renderStreamLoader()}
            {renderHLSPlayer()}
          </Col>

          {isMobile && (
              <Row
              className="col-md-12 "
              style={{
                marginTop: 20.305,
                padding: 0,
              }}
            >
            <Col
                style={{
                  padding: 0,
                  paddingLeft: 5
                }}
            >
              {activeViewers} {i18next.t('HLSBroadcastComponentWatchingNow')}
            </Col>

          </Row>)}

          <Col
            lg="3"
            md="12"
            sm="12"
            className={"special_border_radius mediaPlayerChatContainer streamingLayout"}
            style={{
              justifyContent: 'center',
              verticalAlign: 'middle',
              display: 'flex',
              marginRight: '1%',
              padding: 0
            }}
          >
            {renderChat()}
          </Col>

        </Row>

        {!isMobile && (<Row
            className="col-md-12 viewersContainerRTMP"
        >
          <Col

          >
            {activeViewers} {i18next.t('HLSBroadcastComponentWatchingNow')}
          </Col>

        </Row>)}

      </Container>
      {renderStreamFinishedModal()}
    </div >
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    influencer: state.user.influencer,
    isInfluencer: state.user.isInfluencer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetOptionsModerators: optionsModerators =>
      dispatch(actions.setOptionsModerators(optionsModerators))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PreStreamingInfluencer)
);
