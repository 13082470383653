import i18next from 'i18next';
import React, { useRef } from 'react'
import { Modal } from "react-bootstrap";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components'
import "./style.scss"
const ModalCrop = ({ mobileNav, onHandleInputChange, setCroppedImageUrl, handleImageChange, crop, setCrop, loadedImg, setModalCrop, modalCrop, modalTitle }) => {
    const imageRef = useRef(null)

    function getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg', 1);
        });
    }
    const onCropComplete = (crop) => {
        
        makeClientCrop(crop)
    }
    const onCropChange = (crop, percentCrop) => {
        
        setCrop(crop)
    }
    const onImageLoaded = (image) => {
        imageRef.current = image
        
        // return false
    }
    const makeClientCrop = async (crop) => {
        if (imageRef && crop.width && crop.height) {
            
            const croppedImageUrl = await getCroppedImg(
                imageRef.current,
                crop,
                'fakestim.jpeg'
            );
            
            setCroppedImageUrl(croppedImageUrl);
            handleImageChange(croppedImageUrl)
        }
    }
    const upload =( )=> {
        document.getElementById("selectImage").click()
    }
    return (
        <Modal style={{ display: 'flex', justifyContent: 'center', minWidth: !mobileNav? '600px' : 'auto'}} show={modalCrop} onHide={() => setModalCrop(false)}>

            <Modal.Header closeButton>
                <div style={{ fontSize: '18px' }} className="text-left   w-100">
                    <span className="">{modalTitle}</span>
                </div>
            </Modal.Header>
            <Modal.Body style={{ padding: '0px' }}>
                <Cont style={{ width: '100%', backgroundColor: "black", padding: '16px' }} className="text-center d-flex flex-column justify-content-center align-items-center">
                    {loadedImg && (
                        <ReactCrop src={loadedImg} crop={crop} ruleOfThirds onImageLoaded={onImageLoaded}
                                    onComplete={onCropComplete} onChange={onCropChange} />)
                    }

                </Cont>
                <div className="text-center d-flex p-3 justify-content-end">
            
                    <div style={{ display: 'grid' }}>
                        <ChangeImg className="changeImg" id='plus' onClick={upload}>{i18next.t("ModalCropComponentUploadPhoto")}</ChangeImg>
                        <input id='selectImage' hidden type="file" onChange={onHandleInputChange} />
                    </div>
                    <SaveBtn onClick={() => setModalCrop(false)} className=" send_request saveBtn">{i18next.t("ModalCropComponentSave")}</SaveBtn>
                </div>
            </Modal.Body>


        </Modal>
        // </div>
    )
}
export default ModalCrop

const Cont = styled.div`

    .changeImg{
      

    }

    .saveBtn {
        border-radius: 50px;
        background: #ff5a60;
        height: 34px;
    border: 0;
    color:#fff;
    margin-left: 1rem;
    padding: 0px 25px;

    }
  
`
const ChangeImg = styled.button`
  margin-right:12px;
border-radius: 50px;
    /* padding: 0px 19px; */
    font-size: 15px;
    padding: 6px 12px;
        border: 0;
    background-color: #B4B8C1;
    color: #fff;
    right: 2rem;
`
const SaveBtn = styled.button`
  border-radius: 50px;
        background: #ff5a60;
        height: 34px;
    border: 0;
    color:#fff;
    margin-left: 1rem;
    padding: 0px 25px;
`