import React, { useState, useEffect } from "react";
import Attendees from "../../../comunes_events/attendees_admin";
import fun from "assets/img/icons/ic-fun.svg";
import user2 from "assets/img/icons/ic-raisehand.svg";
import user3 from "assets/img/icons/ic-price.svg";
import Time from "assets/img/icons/ic-time-copy.svg";
import edit from "assets/img/icons/ic-edit.svg";
import tick from "assets/img/tick.png"
import { Link } from "react-router-dom";
import Check from "assets/img/icons/ic-check.svg";
import PropTypes from "prop-types";
import rejected from "assets/img/rejected.png"
import useUserHour from "../../../../hooks/useUserHour"
import { useSelector } from "react-redux";
import { parsePriceByCurrencyJustSymbol, findCurrencyByCurrencyCode } from "../../../../utils/currencyUtils";

import statesEventRequest, { stateArray } from "../../../../constants/statesEventRequest";
import { reasons, newReasons } from '../../../../pages/requestPrivate/utils'
import { parsePrivateRequestReasonsLocaleResources } from '../../../../utils/Localization'
import i18next from "i18next";

const imagesPath = {
  minus: Time,
  plus: Check
};

const RequestDetail = ({ isMobile, data, onReject, onAccept, rejecting, acepting }) => {
  const currencies = useSelector((state) => state.currencies.currencies)

  const [request, setRequest] = useState([])
  const [influencer_user, setInfluencerUser] = useState([])

  const { event_uuid, request_currency, request_reason, request_date, request_num_attendees, request_price_ticket, request_type,
    request_ide, request_state, request_start_hour, request_end_hour } = request ? request : '';
  const { userTime, timeStrings } = useUserHour({ request_date })
  const { user_username, user_name, user_lastname, user_location, user_avatar } = influencer_user ? influencer_user : "";
  const requestState = stateArray.filter((state) => state.id === request_state)[0]
  // const startDate = new Date(parseInt(request_date))
  const [startDate, setStartDate] = useState("")
  var requestType = parsePrivateRequestReasonsLocaleResources().filter((reason) => reason.request_type === request_type || reason.options.find((option) => option.optionId === request_type ))
  var optionData = null;

  try{
    if((requestType !== null && requestType !== undefined) && requestType[0].request_type !== request_type){
      optionData = requestType[0].options.find((option) => option.optionId === request_type );
  
      try{
        requestType[0].secondaryName = optionData.optionName;
      }catch(error){
        
      }
    }
  }catch(error){
    
  }
  

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setRequest(data.request)
      setInfluencerUser(data.influencer_user)
      setStartDate(new Date(parseInt(data.request.request_date)))
    }
  }, [data])
  
  return (
    <div style={{ borderLeft: isMobile ? 'none' : '1px solid #D8DCE5', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyItems: 'center' }} className="col-md-3 target_request px-0 pt-4 pl-md-2 pl-lg-5">
      {isMobile && <h2 style={{ fontSize: '20px' }} className=" mb-4 details_request">{i18next.t('RequestDetailDetailsRequestDetails')}</h2>}
      { user_username &&
        <Attendees isInfluencer
          userName={user_username}
          name={`${user_name} ${user_lastname}`}
          location={user_location && JSON.parse(user_location).city}
          avatar={user_avatar}
        />
      }
      {requestState && Object.keys(requestState).length > 0 &&
        <div className="d-flex my-3">
          {requestState && Object.keys(requestState).length > 0 && (requestState.name.includes("Accepted") || requestState.name.includes("Rejected") || requestState.name.includes("Pending") || requestState.name.includes("Modified")) &&
            <img style={{ width: '22px', height: '22px' }} src={requestState.name.includes('Pending') ? imagesPath["plus"] :
              requestState.name.includes('Accepted') ? tick : requestState.name.includes('Rejected') ? rejected : edit
            } alt=""></img>
          }
          {requestState.name.includes('Pending') && <p className="mb-0 ml-2 rojo">{i18next.t("RequestDetailPendingConfirmation")}</p>}
          {requestState.name.includes('Accepted') && <p className="mb-0 ml-2 ">{i18next.t("RequestDetailAccepted")}</p>}
          {requestState.name.includes('Rejected') && <p className="mb-0 ml-2 ">{i18next.t("RequestDetailAccepted")}</p>}
          {requestState.name.includes('Cancelled') && <p className="mb-0 ml-2 ">{i18next.t("RequestDetailCancelledByViewer")}</p>}
          {requestState.name.includes('Modified') && (!requestState.name.includes('Accepted') && !requestState.name.includes('Payment')) &&
            <p className="mb-0 ml-2 ">{i18next.t("RequestDetailModified")}</p>}
        </div>
      }
      <div className="d-flex align-items-start"></div>
      {user_username && !isMobile && <h2 style={{ fontSize: '18px' }} className="mt-4 mb-4 details_request">{i18next.t("RequestDetailDetails")}</h2>}
      {user_username === undefined && !isMobile && <h2 style={{ fontSize: '18px' }} className="mt-4 mb-4 details_request">{i18next.t("RequestDetailDetailsDescription")} </h2>}


      {user_username &&
        <div className="d-flex information_detalles">
          <img className='mr-lg-3 mr-1' src={fun} alt="" />
          <div>
            <p className="m-0">{requestType && requestType.length > 0 && requestType[0].name}</p>
            <p className="price_information ">{requestType && requestType.length > 0 && requestType[0].secondaryName}</p>
          </div>
        </div>
      }
      {user_username &&

        <div className="d-flex information_detalles">
          <img className='mr-lg-3 mr-1' src={user2} alt="" />
          <div>
            <p className="m-0">{i18next.t('RequestDetailDetailsAttendees')}</p>
            <p className="price_information">{request_num_attendees} {i18next.t('RequestDetailDetailsAttendees')}</p>
          </div>
        </div>
      }
      {user_username &&

        <div className="d-flex information_detalles">
          <img className='mr-lg-3 mr-1' src={user3} alt="" />
          <div>
            <p className="m-0">{i18next.t('RequestDetailDetailsPricePerTicket')}</p>
            <p className="price_information"> {parsePriceByCurrencyJustSymbol(request_price_ticket, findCurrencyByCurrencyCode(request_currency, currencies))}</p>
          </div>
        </div>
      }
      {user_username &&

        <div className="d-flex information_detalles">
          <img className='mr-lg-3 mr-1' src={user2} alt="" />
          <div>
            <p className="m-0">{i18next.t('RequestDetailDetailsDateAndTime')}</p>
            {request_start_hour && startDate ?
              <>
                <p className=" mb-1 price_information">
                  {(request.request_date !== null) ? i18next.t('RequestDetailDetailsSpecificDateAndHour', { specificDate: timeStrings.dateStr, request_start_hour: request_start_hour, request_end_hour: request_end_hour }) : i18next.t('RequestDetailDetailsSpecificAnyTimeAndHour', { specificDate: timeStrings.dateStr, request_start_hour: request_start_hour, request_end_hour: request_end_hour })}
                </p>
              </> :
              request_start_hour && !startDate ?
                <p className=" mb-1 price_information">
                  {i18next.t('RequestDetailDetailsUndefinedDateFrom', { request_start_hour: request_start_hour, request_end_hour: request_end_hour })}
                </p>
                :
                (request && request.request_date && startDate) ?
                  <p className=" mb-1 price_information">
                    {i18next.t('RequestDetailDetailsDateAnyTime', { date: timeStrings.dateStr })}
                  </p>
                  :
                  <p className=" mb-1 price_information">
                    {i18next.t('RequestDetailDetailsDateAndTimeNotDefined')}
                  </p>
            }
          </div>
        </div>
      }


      {request_state === statesEventRequest.modified && (
        <div className="mt-3">
          <button style={{ backgroundColor: '#FF5A60', color: "white", border: 0, padding: 12, borderRadius: "1.1rem" }} className=" send_request" onClick={() => onAccept(request_ide)}
            disabled={acepting || rejecting}
          >
            {!acepting ? i18next.t('RequestDetailDetailsAcceptProposal') : i18next.t('RequestDetailDetailsAccepting')}
          </button>
          <button
            style={{ backgroundColor: '#B4B8C1', color: "white", border: 0, padding: 12, borderRadius: "1.1rem" }}
            className=" cancel"
            onClick={() => onReject(request_ide)}
            disabled={acepting || rejecting}
          >
            {!rejecting ? i18next.t('RequestDetailDetailsReject') : i18next.t('RequestDetailDetailsLoading')}
          </button>
        </div>
      )}

      {event_uuid &&
        <div className="text-center mt-4">
          <Link to={`/event/${event_uuid}`}>
            <button style={{ backgroundColor: '#ff5a60', padding: '8px 25px', marginBottom: '1rem', border: 'none', color: 'white', borderRadius: '20px' }} className=" send_request">
              {i18next.t('RequestDetailDetailsCheckTheEvent')}
            </button>
          </Link>
        </div>
      }
    </div>
  );
};

RequestDetail.propTypes = {
  data: PropTypes.array.isRequired,
  onReject: PropTypes.func.isRequired,
  rejecting: PropTypes.bool,
  acepting: PropTypes.bool
};

export { RequestDetail };
