import React from "react";

const TaxInformation = () => (
  <div className="item_help">
    <h3 className="g_color">The basics</h3> <br/>

    <p>
      You will receive a 1099-K form if you are an Influencer subject to US tax
      and youearned over $20,000 in a calendar year.New law requires that
      residents of <span className="g_strong">Vermont</span> and{" "}
      <span className="g_strong">Massachusetts</span> receive a1099-K form if
      earnings exceed $600 in a calendar year.If you are an international
      Influencer (non-US Influencer), you’ll need tocomplete a W-8BEN form for
      our records. We will not issue a 1099 form.
      <span className="g_strong">gotmy</span> does not withhold any taxes from
      your funds raised on <span className="g_strong">gotmy</span>.
      Pleaseconsult your tax professional to understand your tax obligations.
    </p>

    <h3 className="g_color">FAQ For Your Accountan</h3>
    <h3 className="g_color">General Disclaimer:</h3> <br/>
    <p>
      We have received many questions related to income tax, sales tax and
      valueadded taxes. Although we are not permitted to provide tax advice, we
      areproviding this FAQ to share information. We recommend that you consult
      withyour tax professional to learn about your particular country, and your
      particularcircumstances.Please note whether each question is referring to
      Influencers or Viewers andwhether each question is referring to USA
      persons (USA) or non-USA persons(International)
      <span className="g_color">as defined by the IRS</span>.
    </p>

    <h3 className="g_color">Questions Related to Tax Deductions</h3>
    <h3 className="g_color">
      I’m an Influencer. Is the money I receive from gotmy “taxableincome”?
    </h3> <br/>
    <p>
      In most jurisdictions, the money you receive from your Viewers is
      taxableincome. However, see next question if you are a charity or NPO.
    </p>
    <h3 className="g_color">
      I’m an Influencer who is registered as a charity or NPO. Is themoney I
      receive from gotmy taxable income?
    </h3> <br/>
    <p>
      If you are a legally recognized not-for-profit company (for example, a
      USA501(C)(3) charity), then you might not need to pay tax on your
      <span className="g_color">gotmy</span> income.
    </p>
    <h3 className="g_color">
      I am a USA Influencer. Can I offset my gotmy income withdeductible
      expenses that are related to the project and accountedfor in the same tax
      year?
    </h3> <br/>
    <p>
      Please consult with your tax advisor in regard to the deductibility of
      expenses.
    </p>
    <h3 className="g_color">
      Who is responsible for paying US income tax on the moneyreceived from
      gotmy?
    </h3> <br/>
    <p>
      USA Influencers are responsible for filing/paying taxes on the money
      receivedfrom <span className="g_color">gotmy</span>. Your professional tax
      advisor can assist you to determine if youneed to make quarterly tax
      payments to the Internal Revenue Service.
    </p>

    <h3 className="g_color">Tax Forms:</h3>
    <h3 className="g_color">What is a USA tax identification number?</h3> <br/>
    <p>
      For USA Influencers who are individuals/persons: the tax ID is your
      socialsecurity number.For USA Influencers who are corporations,
      partnerships, LLC or other type ofentity, the tax ID is your Federal
      Employer Identification Number (FEID) or EIN(Employer Identification
      Number (EIN).For non-US-persons (international Influencers), the USA tax
      identificationnumber is not required.
    </p>
    <h3 className="g_color">
      I am a USA Influencer. Why do I have to provide my taxidentification
      number (TIN) to <span className="g_color">gotmy</span>?
    </h3> <br/>
    <p>
      USA Influencers generally need to file a tax return that includes the
      moneyreceived from <span className="g_color">gotmy</span>.{" "}
      <span className="g_color">gotmy</span> follows the IRS guidance that USA
      Influencersprovide <span className="g_color">gotmy</span> with a W-9 form
      (or equivalent) which includes the Influencer’sTIN.
    </p>
    <h3 className="g_color">What is a W-9 form?</h3> <br/>
    <p>
      The W-9 form is used in a wide range of payment situations, but
      mostcommonly, taxpayers fill them out when they are working as a
      freelancer orindependent contractor rather than an employee. The purpose
      of collectingform W-9 is to record your tax identification number, along
      with your name andaddress so <span className="g_color">gotmy</span> can
      notify the IRS of the amount earned in a calendar year.
      <span className="g_color">gotmy</span> requires Influencers to submit form
      W-9 once they meet qualifications toreceive form 1099-K in a calendar
      year.Influencers living in the United States who earn over $20,000 in
      sales in acalendar year are required to submit the W-9 form.Influencers
      living in <span className="g_color">Vermont</span> or{" "}
      <span className="g_color">Massachusetts</span> who earn over$600in a
      calendar year aresubject to receive form 1099-K.A US person is any US
      citizen or US person as defined by the IRS.
    </p>
    <p>
      <strong>Note</strong>:We’re unable to offer tax advice. We recommend
      reaching out to a localtax advisor for additional assistance and
      clarification.Everything below is takenfrom the{" "}
      <a target="_blank" href="https://www.irs.gov/">
        IRS website
      </a>
    </p>
    <p>
      U.S. law treats U.S. persons and foreign persons differently for tax
      purposes.Therefore, it is important to be able to distinguish between
      these two types oftaxpayers.
    </p>

    <p className="strong">United States Persons</p>
    <p>The term''UnitedStates person'' means:</p>
    <p className="tabulation_x1">- A citizen orresidentof the United States</p>
    <p className="tabulation_x1">- A domestic partnership</p>
    <p className="tabulation_x1">- A domestic corporation</p>
    <p className="tabulation_x1">- Any estate other than a foreign estate</p>
    <p className="tabulation_x1">- Any trust if:</p>
    <p className="tabulation_x2">
      - A court within the United States is able to exercise primary supervision
      over the administration of the trust, and
    </p>
    <p className="tabulation_x2">
      - One or more United States persons have the authority to controlall
      substantial decisions of the trust
    </p>
    <p className="tabulation_x1">
      Any other person that is not a foreign person.
    </p>

    <p className="strong">Foreign Persons</p>
    <p>A foreign person includes:</p>
    <p className="tabulation_x1">- Nonresident alienindividual</p>
    <p className="tabulation_x1">- Foreign corporation</p>
    <p className="tabulation_x1">- Foreign partnership</p>
    <p className="tabulation_x1">- Foreign trust</p>
    <p className="tabulation_x1">- A foreign estate</p>
    <p className="tabulation_x1">
      - Any other person that is not a U.S. person
    </p>

    <p>
      Generally, the U.S. branch of a foreign corporation or partnership is
      treated as aforeign person. Refer to Internal Revenue Code section
      7701(a)(31) for thedefinition of a foreign estate and a foreign trust.
    </p>

    <p className="strong">
      Check-the-box Entities(See Form 8832 and Instructions)
    </p>
    <p>
      For Federal tax purposes, certain business entities automatically are
      classifiedas corporations. Other business entities may choose how they are
      classified for Federal tax purposes. Except for a business entity
      automatically classified as acorporation, a business entity with at least
      two members can choose to beclassified as either an association taxable as
      a corporation or a partnership, anda business entity with a single member
      can choose to be classified as either anassociation taxable as a
      corporation or disregarded as an entity separate fromits owner.
    </p>

    <p>
      <span className="strong">Note</span>: Changes to Treasury Regulation
      section 301.7701–2(c)(2)(iv) providethat, with respect to wages paid after
      December 31, 2008, a disregarded entityis treated as a separate entity for
      purposes of employment taxes imposed underSubtitle C of the Internal
      Revenue Code and related reporting requirements. Inaddition, the separate
      entity is treated as a corporation for purposes ofemployment taxes imposed
      under Subtitle C and related reportingrequirements. Therefore, the entity,
      rather than the owner, is considered to bethe employer of any individual
      performing services for the entity.These recent changes adversely impact
      the FICA and FUTA exceptions thatapply to family employment(perIRC
      sections 3121(b)(3) and 3306(c)(5)) and situations in which both the
      employer and the employee are members of areligious faith(perIRC section
      3127) because the recent changes regard adisregarded entity as a separate
      entity and treats the separate entity as acorporation for employment tax
      purposes. However, new temporary regulationsextend the exceptions from
      taxes under IRC sections 3121(b)(3)(concerning individuals who work for
      certain family members), 3127(concerning members of religious faiths), and
      3306(c)(5)(concerningpersons employed by childrenand spouses and children
      under 21 employed by their parents) to entities thatare disregarded as
      separate from their owners for federal tax purposes. Formore details,
      refer toT.D. 9554, Extending Religious and Family Member FICAand FUTA
      Exceptions to Disregarded Entities.The temporary regulations also clarify
      the existing rule that the owners ofdisregarded entities, except for
      qualified subchapter S subsidiaries, areresponsible for backup withholding
      and related information reportingrequirements under section 3406.
    </p>

    <h3 className="g_color">What is a W8-BEN form</h3> <br/>
    <p>
      The USA tax authority is called the Internal Revenue Service (IRS). To
      complywith IRS regulations <span className="g_strong">gotmy</span>{" "}
      requires all International (Non- USA) Influencers tocomplete a W8-BEN form
      where the Influencer certifies that all of the incomewas earned outside
      the USA. <span className="g_strong">gotmy</span> requires W8-BEN forms
      from allinternational Influencers prior to receipt of payment.{" "}
      <span className="g_strong">gotmy</span> has an on-line formwhich contains
      all of the information required on a W-8BEN form by the IRS. Thison-line
      form is called a "substitute W-8BEN form" since it’s a virtual
      equivalentof the IRS’ paper form.
    </p>

    <h3 className="g_color">Why do I have to fill out a W-8BEN?</h3> <br/>
    <p>
      Any Influencer that is not a USA taxpayer must complete a W-8BEN form.
    </p>

    <h3 className="g_color">What does gotmy do with the W-8BEN forms?</h3> <br/>
    <p>
      <span className="g_strong">gotmy</span> retains these forms in its files.
    </p>

    <h3 className="g_color">
      {" "}
      Iam a US citizen living outside of the US which tax form should I fillout?
      I have not paid US taxes; I don't earn income in the US nor do Ilive or
      own anything in the US.
    </h3> <br/>
    <p>
      Based on the instruction on the W-8BEN if you are a US citizen you should
      fill out a W-9. Your income from <span className="g_strong">gotmy</span>{" "}
      may be subject to 1099 reporting.Please see th eW-8BEN form for more
      instructions. To determine your tax responsibilities please consult with a
      tax professional.
    </p>

    <h3 className="g_color">Will I be sent a 1099 form and when?</h3> <br/>
    <p>
      You will only receive a Form 1099-K from{" "}
      <span className="g_strong">gotmy</span> if you are located in the
      UnitedStates and reach one of the following thresholds:
    </p>
    
    <table>
      <tr>
        <th>US State</th>
        <th>Eearnings</th>
      </tr>
      <tr>
        <td>All US States</td>
        <td>$20k+ USD in a calendar year</td>
      </tr>
      <tr>
        <td>Massachusetts</td>
        <td>$600+ USD in a calendar year</td>
      </tr>
      <tr>
        <td>Vermont</td>
        <td>$600+ USD in a calendar Year</td>
      </tr>
    </table>
    <br /><br />

    <p>
      <span className="strong">
        If the above is not applicable, then you will not receive a Form 1099-K
        from
      </span>{" "}
      <span className="g_strong">gotmy</span> 1099-K: If you receive a 1099-K
      from us, the dollar amount in Box 1 consists of the gross receipts from
      all funds you received in your Influencer balance in the corresponding
      calendar year (excluding <span className="g_strong">gotmy</span>’s
      platform and payment processing fees).
    </p>
    <p>
      <span className="g_strong">gotmy</span> cannot provide tax advice so
      please consult a tax professional. Please be sure to appropriately report
      all income to the relevant tax authorities. Even if you do not receive a
      1099 form you should still check with your local tax authority to make
      sure you have properly reported your taxable income
    </p>
    <p>
      If you have multiple accounts on <span className="g_strong">gotmy</span>{" "}
      that use the same tax TIN (SSN or EIN), you will only receive one combined
      1099-K for all your earnings across those pages. To find your 1099-K, you
      would need to log into each of your{" "}
      <span className="g_strong">gotmy</span> accounts to find the statement. If
      you see that your 1099-K statement is unavailable, please check your other{" "}
      <span className="g_strong">gotmy</span> accounts that use the same tax
      TIN. If you still cannot locate it, send us an email to
      <span className="g_strong">gotmy</span>.
    </p>

    <p>
      Some Influencers may receive a 1099-K from PayPal that includes the
      payouts they have received from <span className="g_strong">gotmy</span>{" "}
      along with other payments they received on PayPal. Make sure that you do
      not double report this income, but please consult with a tax professional
      for specific tax advice.
    </p>

    <p>
      For more information about your financial metrics, you can review your
      Balances and Statements.
    </p>

    <p>
      <span className="strong">Note</span>: 1099-K's are sent by our payout
      providers Stripe and PayPal dependent on your{" "}
      <span className="g_strong">gotmy</span> payouts. If you need an additional
      copy of the form, you'll need to reach out to the provider (Stripe or
      PayPal) where you received your monthly payouts for the year.
    </p>

    <h3 className="g_color">What are gross earnings and how do I see them?</h3> <br/>
    <p>
      Gross earnings are the total amount of tickets processed during the year
      minus <span className="g_strong">gotmy</span>’s platform fees and payment
      processing fees. Head on over to your tax information on the payout
      preferences page and see your Year gross earnings or income and what to
      report on.
    </p>

    <h3 className="g_color">Tax Withholding:</h3>
    <h3 className="g_color">
      I am a USA Influencer. Did <span className="g_strong">gotmy</span>{" "}
      withhold any taxes from my payments?
    </h3> <br/>
    <p>
      <span className="g_strong">gotmy</span> does not withhold any taxes from
      USA Influencers. You can see your earnings by visiting your Balance/Income
      Statement in your Influencer profile.{" "}
    </p>
  </div>
);

export { TaxInformation };
