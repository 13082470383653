import React from 'react';
// import React, {Component,useState} from 'react';

import './style.scss';
import circulo from "../../../assets/img/logo-circulo.png";
import cinco_estrellas from '../../../assets/img/5-estrellas.png';
import { Link, withRouter } from "react-router-dom";

function Influencer_card(props) {

    /*PARA HACER LA PUNTUACON EN FUTURO*/
    /*IF PUNTUACON = 1 DEVULEVO IMAGEN DE 1 ESTRELLA , SI ES 2 IMAGEN 2 etc*/ 
    return (

         <a href={"/" + props.name}>
            <div className="Influencer_card ">

                <div className="Div-img">
                    <img style={{objectFit:'cover'}} className="Foto" src={props.user_img} alt="" />
                    <img src={circulo} className="Icono" alt="" />
                </div>

                <div className="Div-txt">
                    <p> {props.name}</p>
                    {/* <img className="Puntuacion" src={cinco_estrellas} alt="" /> */}

                </div>
            </div>
        </a>

    );
}

export default Influencer_card;

