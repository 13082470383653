import React, { Component } from "react";
import OpenViduVideoComponent from "./OvVideo";
import OpenViduAudioComponent from "./OVAudio";
import Draggable from "react-draggable";
import { ToastContainer } from "react-toastify";
import screenShare from "assets/img/icons/screenShare.svg";
import stopScreenShare from "assets/img/icons/stopScreenSharing.svg";
import enterFullScreen from "assets/img/icons/switch-to-full-screen-button.svg";
import exitFullScreen from "assets/img/icons/full-screen-exit.svg";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import i18next from "i18next";
import { isMobile } from "react-device-detect";

const wp = window.innerWidth;
const hp = window.innerHeight;

export default class UserVideoComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      volumeLevel: parseFloat(0.5),
      shouldSwapMainViewWithRemote: false,
      selfSharingScreenViewPosition: {
        x: 0,
        y: hp * 0.1,
      },
      remotePosition: {
        x: wp * 0.4,
        y: hp * -0.4,
      },
    };
  }

  swapMainViewWithRemote() {
    this.setState({
      shouldSwapMainViewWithRemote: !this.state.shouldSwapMainViewWithRemote,
    });
  }

  updateVolume(value) {
    this.setState({
      volumeLevel: parseFloat(value) / 100,
    });

    let audios = [...document.getElementsByTagName("audio")];
    audios.forEach((audio) => (audio.volume = parseFloat(value) / 100));

    let videos = [...document.getElementsByTagName("video")];
    videos.forEach((video) => (video.volume = parseFloat(value) / 100));
  }

  getUsernameFromSubscriber(participantStream) {
    var username = "";
    try {
      username = participantStream.participant.identity;
    } catch {
      username = null;
    }

    return username;
  }

  renderOpenModeViews() {
    if (this.props.isOpenModeEnabled) {
      return (
        <div
          style={{
            display: "none",
          }}
        >
          {this.props.audioTracks.map((sub, i) => {
            if (
              this.getUsernameFromSubscriber(sub) !=
              this.props.mySpeaking_viewer
            ) {
              return (
                <div key={i + "OpenViduAudioComponentConainer"}>
                  <OpenViduAudioComponent
                    minimisedChat={this.props.minimisedChat}
                    setMinimisedChat={this.props.setMinimisedChat}
                    key={
                      i +
                      "OpenViduAudioComponentConainer" +
                      this.getUsernameFromSubscriber(sub)
                    }
                    updateVolume={this.updateVolume.bind(this)}
                    volumeLevel={this.state.volumeLevel}
                    setVolume={true}
                    streamManager={sub}
                  />
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      );
    } else {
      return null;
    }
  }

  selectMainStreaming() {
    if (this.props.shareScreenSource) {
      return this.props.shareScreenSource;
    } else if (
      this.state.shouldSwapMainViewWithRemote &&
      this.props.remoteManager
    ) {
      return this.props.remoteManager;
    } else {
      return this.props.streamManager;
    }
  }

  setOptionModalVisibility(shouldBeOpen) {
    this.props.toggleStreamingOptionsModal(shouldBeOpen);
  }

  renderDominantSpeakerParticipant() {
    var currentDominantSpeaker = this.props.currentDominantSpeaker;

    if (
      currentDominantSpeaker &&
      currentDominantSpeaker.enabled &&
      currentDominantSpeaker.participant
    ) {
      return (
        <div className="dominantSpeakerElement">
          <img src={currentDominantSpeaker.imageSrc}></img>
          <p>{currentDominantSpeaker.participant.identity}</p>
          <h4>{i18next.t("UserVideoComponentIsSpeaking")}</h4>
        </div>
      );
    } else {
      return null;
    }
  }

  renderOverlayElement(visible = true) {
    if (this.videoRef != null && this.props.streamManager && visible) {
      return (
        <div
          className="VideoOverlay"
          id="streamControls"
          style={{
            position: "absolute",
            float: "left",
            width: this.state.videoWidth,
            height: this.state.videoHeight,
          }}
          onMouseMove={() => {
            this.showOverlay(1000);
          }}
        >
          <div className="buttonManager noselect">
            <div id={"volumeLevel"} className={"volumeSlider"}>
              <img
                className={"volumeController noselect"}
                src={this.chooseVolumeIcon()}
              />
              <Slider
                min={0}
                max={100}
                defaultValue={50}
                onChange={(value) => {
                  if (this.props.updateVolume) {
                    this.props.updateVolume(value);
                  }

                  this.setState({
                    volumeLevel: value,
                  });
                }}
              />
            </div>

            {this.props.shareScreen && (
              <div
                className={
                  this.props.isSharingScreen
                    ? "screenSharebtnSharing shareScreenContainer"
                    : "screenSharebtn shareScreenContainer"
                }
              >
                <img
                  className={" overlayButtons noselect"}
                  src={
                    this.props.isSharingScreen ? stopScreenShare : screenShare
                  }
                  onClick={() => {
                    if (this.props.shareScreen && !this.props.isSharingScreen) {
                      this.props.shareScreen();
                    } else if (
                      this.props.stopShareScreen &&
                      this.props.isSharingScreen
                    ) {
                      this.props.stopShareScreen();
                    }
                  }}
                />
                {this.props.isSharingScreen && (
                  <p>{i18next.t("UserVideoComponentSharingScreen")}</p>
                )}
              </div>
            )}

            <img
              className={"fullscreenbtn overlayButtons noselect"}
              src={this.props.isFullScreen ? exitFullScreen : enterFullScreen}
              onClick={() => {
                if (this.props.isFullScreen) {
                  this.props.exitFullScreen();
                } else {
                  this.props.enterFullScreen();
                }
              }}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  handleDragRemoteView = (e, position) => {
    if (this.props.isFullScreen) {
      const { x, y } = position;
      this.setState({ remotePosition: { x, y } });
    }
  };

  handleDragSelfSharingScreenView = (e, position) => {
    if (this.props.isFullScreen) {
      const { x, y } = position;
      this.setState({ selfSharingScreenViewPosition: { x, y } });
    }
  };

  render() {
    return (
      <div className="streamcomponent">
        {this.props.streamManager !== undefined ? (
          <div className="streamcomponent">
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />

            {this.renderOpenModeViews()}

            {this.props.mySpeaking_viewer &&
            this.props.remoteManager &&
            !this.props.hideRemoteView ? (
              <Draggable
                position={this.state.remotePosition}
                onDrag={this.handleDragRemoteView.bind(this)}
                bounds={"parent"}
              >
                <div
                  id="video-rise-hand"
                  className={
                    this.props.isFullScreen
                      ? "remoteFullScreen"
                      : "animate__animated animate__fadeInRight remoteViewContainer"
                  }
                  style={isMobile ? {
                    position: "absolute",
                  } : {
                    position: "absolute",
                    maxWidth: "25%"
                  }}
                >
                  {this.props.mySpeaking_viewer && (
                    <OpenViduVideoComponent
                      isLandscape={this.props.isLandscape}
                      minimisedChat={this.props.minimisedChat}
                      setMinimisedChat={this.props.setMinimisedChat}
                      specialClass={"remoteVideoPlayer"}
                      influencerConnected={true}
                      participant={this.props.remoteManager}
                      mode={this.props.mode}
                      showOverlay={false}
                      isRemote={true}
                      remoteView={true}
                      isRemoteApp={this.props.isRemoteApp}
                      renderRemoteOverlay={!this.props.shareScreenSource}
                      width={hp * 0.112}
                      height={hp * 0.2}
                      updateVolume={this.updateVolume.bind(this)}
                      volumeLevel={this.state.volumeLevel}
                      enterFullScreen={() => {}}
                      exitFullScreen={() => {}}
                      isFullScreen={false}
                      setVolume={true}
                      swapRemoteView={this.swapMainViewWithRemote.bind(this)}
                      publications={this.props.remotePublications}
                      streamManager={this.props.streamManager}
                    />
                  )}
                </div>
              </Draggable>
            ) : null}

            {false &&
            this.props.screenShareTracks &&
            !this.props.hideRemoteView ? (
              <Draggable
                position={this.state.selfSharingScreenViewPosition}
                onDrag={this.handleDragSelfSharingScreenView.bind(this)}
                bounds={"parent"}
              >
                <div
                  id="video-main-streaming-sharing-screen"
                  className={
                    this.props.isFullScreen
                      ? "remoteFullScreen"
                      : "animate__animated animate__fadeInLeft"
                  }
                  style={{
                    position: "absolute",
                    bottom: "5vh",
                  }}
                >
                  {this.props.streamManager && (
                    <OpenViduVideoComponent
                      minimisedChat={this.props.minimisedChat}
                      setMinimisedChat={this.props.setMinimisedChat}
                      specialClass={"selfSharingVideoPlayer"}
                      participant={this.props.remoteManager}
                      influencerRemote={true}
                      publications={this.props.publications}
                      mode={this.props.mode}
                      showOverlay={false}
                      renderRemoteOverlay={false}
                      isRemote={true}
                      height={hp * 0.1406}
                      width={hp * 0.25}
                      updateVolume={this.updateVolume.bind(this)}
                      volumeLevel={this.state.volumeLevel}
                      enterFullScreen={() => {}}
                      exitFullScreen={() => {}}
                      isFullScreen={false}
                      setVolume={true}
                      streamManager={this.props.streamManager}
                    />
                  )}
                </div>
              </Draggable>
            ) : null}

            {this.renderDominantSpeakerParticipant()}
            <OpenViduVideoComponent
              minimisedChat={this.props.minimisedChat}
              setMinimisedChat={this.props.setMinimisedChat}
              isMainScreen={true}
              participant={null}
              publications={this.props.publications}
              isInfluencer={this.props.isInfluencer}
              streamingInitiated={this.props.streamingInitiated}
              setModalVisibility={this.setOptionModalVisibility.bind(this)}
              width={this.props.width}
              enterFullScreen={this.props.enterFullScreen}
              exitFullScreen={this.props.exitFullScreen}
              isFullScreen={this.props.isFullScreen}
              updateVolume={this.updateVolume.bind(this)}
              volumeLevel={this.state.volumeLevel}
              height={this.props.height}
              isRemote={false}
              renderRemoteOverlay={true}
              setVolume={false}
              mode={this.props.mode}
              showOverlay={true}
              shareScreen={this.props.shareScreen}
              stopShareScreen={this.props.stopShareScreen}
              screenShareTracks={this.props.screenShareTracks}
              isSharingScreen={this.props.isSharingScreen}
              influencerConnected={this.props.influencerConnected}
              streamManager={this.selectMainStreaming()}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
