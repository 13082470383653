import React, { useState, useEffect } from "react";
import DiaMes from "components/comunes_events/dia_mes";
import PuntUsu from "components/comunes_events/imagen_usu_puntuacion";
import NombreCat from "components/comunes_events/nombre_cat_fecha";
import "./style.scss";
import "./modal.scss";
import Tags from "components/comunes/tag";
import NavBar_general from "components/comunes/navbar";
import NavbarEventsInfluencer from "components/live_events_page_influencer/navbar_live_events";
import Back from "components/comunes/back";
import NavBar_upcoming from "components/live_events_page/navbar_live_events";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import Loader from "components/comunes/loader";
import Linkify from 'react-linkify';
import ShareEvent from './ShareEvent'
import AttendeesEvent from './AttendeesEvent'
import { SECURE_LOGIN_BACK_URL, IS_SIGN_UP_ENABLED, B2B_PORT } from '../../environment/environment'
import useEvent from '../../hooks/useEvent'
import useMobileNav from '../../hooks/useMobileNav'
import * as actions from "./../../store/actions";
import ModalBuyEvent from "./ModalBuyEvent";
import ModalSuccess from "./ModalSuccess";
import RequestPrivate from "./RequestPrivate";
import PrivateInfo from "./PrivateInfo";
import BuyButton from "./BuyButton"
import SweetAlert from "sweetalert2-react";
import Sad from "assets/img/icons/sad-face-in-rounded-square.svg";
import Completed from "assets/img/icons/completed.svg";
import ViewerCount from './ViewerCount'
import PreviewBox from './PreviewBox'
import SpecialEventActions from './SpecialActionsFloatButton/SpecialEventActions'
import useStripeObject from "../../hooks/useStripeObject"
import useUserOrLocalTimeHour from "../../hooks/useUserOrLocalTimeHour"
import useIsUserLoggedIn from "../../hooks/useIsUserLoggedIn"
import useLocalStorage from "../../hooks/useLocalStorage"
import { parsePriceByCurrency } from "../../utils/currencyUtils";

import { Elements } from '@stripe/react-stripe-js';
import { isAndroid } from "react-device-detect";
import { googlePlayGotmyURL, appleStoreGotmyURL, BRAND_NAME, HOME_PAGE } from '../../environment/environment'
import { Helmet } from "react-helmet";
import axios from 'axios'
import { ACCESS_TOKEN_API, Api, LOGIN_BACK_URL } from '../../environment/environment'
import { parseLocale } from '../../utils/Localization'
import 'moment/locale/es';
import i18next from 'i18next';


let headers = { "Access-Token": ACCESS_TOKEN_API, "Content-Language": parseLocale() };
function renderHeaderTagsAndtitle(eventData) {

    let pageTitle = BRAND_NAME + " | " + eventData.event_title;
    if(!eventData.event_title){
        pageTitle = BRAND_NAME;
    }

    let cannonicalURL = B2B_PORT ? HOME_PAGE : "https://gotmy.com/event/" + eventData.event_uuid 
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{pageTitle}</title>
            <meta name="og:description" content={eventData.event_description ? eventData.event_description : BRAND_NAME } />
            <meta property="og:title" content={eventData.event_title ? eventData.event_title : BRAND_NAME} />
            <meta property="og:url" content={HOME_PAGE} />
            <meta property="og:image" content={eventData.event_cover_image} />
            <link rel="canonical" href={cannonicalURL} />
        </Helmet>
    )
}

const EventPage = ({ match, location, currencies, languages, user, onGetUser }) => {
    const [noSuccessTicket, setNoSuccessTicket, clearTicketSuccess] = useLocalStorage("ticketSuccess")

    useEffect(() => {
        if (noSuccessTicket) {
            setErrorMessage(noSuccessTicket.message ? noSuccessTicket.message : "This ticket has probably expired!")
            clearTicketSuccess()
        }
    }, [])
    const { isUserLoggedIn } = useIsUserLoggedIn()
    const { renderBuyButton, eventData, loading, upcoming, live, purchased, isCreator, eventType, openTicketing, firstLoad, hasAdvertisingEnabled } = useEvent(languages, match.params.id, user)
    const stripePromise = useStripeObject()
    const { currency, event_presentation_video_landscape, event_num_attendees, event_title, event_category, event_description, event_languages,
        event_tags, event_date, event_num_attendees_streaming, event_num_tickets_free_streaming, event_price_ticket_streaming, event_cover_image, event_streaming_start_date,
        event_location, event_uuid, event_price_ticket, event_thanks_video_landscape, event_num_tickets_free, event_promotional_code_discount, ticket, ticket_streaming, event_is_demo } = eventData.event;
    const { isInfluencer } = user
    const { user_username, user_name } = eventData.user;
    const { influencer: { influencer_avatar, influencer_ranking } } = eventData;
    const [modalBuyEvent, setModalBuyEvent] = useState(false)
    const [inAppBrowse, setInAppBrowse] = useState(false)
    const [modalPurchaseSuccess, setModalPurchaseSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");
    const [videoUploadCompletedMessage, setVideoUploadCompleted] = useState("");
    const [loadingPaymentConfirmation, setLoadingPaymentConfirmation] = useState(false);
    const [confirmPaypal, setConfirmPaypal] = useState({});
    const [email, setEmail] = useState(''); // email used in purchase
    const [confirmEmail, setConfirmEmail] = useState(''); // email used in purchase
    let now = moment();
    let futureDate = moment(parseInt(event_date));
    let countDownDate = "";

    try {
        now = moment().locale(parseLocale());
        
    }
    catch {

    }

    try {
        console.log("DIFF data",futureDate.diff(now,"days"))
        
    }
    catch {

    }

    

    const eventStart = moment(parseInt(event_date))
    const startIn = eventStart.diff(now,"days") + " " + i18next.t("days") + " " + i18next.t("and") + " " + eventStart.diff(now,"hours")%24 + " " +  i18next.t("EventHappeningCardComponentHours");
    const { mobileNav } = useMobileNav()
    const { time } = useUserOrLocalTimeHour(event_date, eventData.event)
    useEffect(() => {
        const browser = navigator.userAgent.toLowerCase()
        if (browser.includes("instagram") || browser.includes("linkedin") || browser.includes('discord') || browser.includes("slack")) {
            setInAppBrowse(true)
        }
    }, [])


    /* Effect to update user when purchase is successful */
    useEffect(() => {
        const env = JSON.parse(localStorage.getItem("env"))
        if (modalPurchaseSuccess && env && env.token) {
            onGetUser(env.token)
        }
    }, [modalPurchaseSuccess])
    /* Effect checking if we're on a callback from paypal. if so, trigger getPaypalOrderStatus */
    useEffect(() => {
        let success = true
        let params = location.search
        let paypalId
        let paypalIntent = JSON.parse(localStorage.getItem('paypalIntent'))
        let storedToken = null
        let email
        if (paypalIntent) {
            storedToken = paypalIntent.token
            if (paypalIntent.email) {
                email = paypalIntent.email
                setEmail(email)
            }
        }
        if (params && params.includes('paypal_callback_error')) {
            // TODO: Manage error
            success = false
            paypalId = params.substring(params.indexOf("=") + 1);
        }
        else if (params.includes("paypal_callback")) {
            paypalId = params.substring(
                params.indexOf("=") + 1,
                params.indexOf("&Payer")
            );
        }
        if (paypalId === storedToken) {
            setLoadingPaymentConfirmation(true)
            setConfirmPaypal({ success, paypalId })
            getPaypalOrderStatus({ success, paypalId })

        }
        localStorage.removeItem('paypalIntent')
    }, [location])

    const getTags = () => {
        try{
            return JSON.parse(event_tags);
        }catch(error){
            return [];
        }
    }

    // Call to the API to get the status after the redirect 
    const getPaypalOrderStatus = async (paypalPayment) => {
        try {
            // 1. CALL GET API to confirm payment success or failure
            let callback = paypalPayment.success ? 'paypal_callback' : "paypal_callback_error"
            // let confirmationStatus = await axios.get(`${Api}payment/${callback}`, { headers })
            let confirmationStatus = await axios.get(`${Api}payment/${callback}`)
            // 2. Get status of purchase from backend
            const status = await axios.post(`${Api}payment/paypal_order`, { paypal_order_id: paypalPayment.paypalId }, { headers });
            if (status.data && (status.data.data.status === 'COMPLETED' || status.data.data.status === 'APPROVED')) {
                setLoadingPaymentConfirmation(false)
                setModalPurchaseSuccess(true)
            }
            else {
                setLoadingPaymentConfirmation(false)
                setErrorMessage(i18next.t('EventPageComponentErrorProcessingEventPayment'))
            }
        }
        catch (err) {
            setLoadingPaymentConfirmation(false)
            setErrorMessage(i18next.t('EventPageComponentErrorProcessingEventPayment'))
        }
    }

    return (
        <div className="upcoming-details">
            <header className="App-header dropShadow">
                {isCreator  && <NavbarEventsInfluencer />}
                {(!isCreator && (!purchased || B2B_PORT)) && <NavBar_general />}
                {(!isCreator && purchased && !B2B_PORT) && <NavBar_upcoming />} 
                {
                    renderHeaderTagsAndtitle(eventData.event)
                }
            </header>

            <SpecialEventActions hasAdvertisingEnabled={hasAdvertisingEnabled}  isCreator={isCreator}
                                  uuid={event_uuid}
                setErrorMessage={setErrorMessage}
                setVideoUploadCompleted={setVideoUploadCompleted}
                setLoading={setLoadingPaymentConfirmation}
            />

            {(!loading && !loadingPaymentConfirmation) ? (

                <section className={`container`}>
                    <SweetAlert animation={false} imageUrl={Sad} show={errorMessage} title="Error :("
                        text={errorMessage} onConfirm={() => setErrorMessage("")}
                    />
                    <SweetAlert animation={true} imageUrl={Completed} show={videoUploadCompletedMessage} title="Completed"
                        text={videoUploadCompletedMessage} onConfirm={() => setVideoUploadCompleted("")}
                    />
                    {modalPurchaseSuccess &&
                        <ModalSuccess email={email} setModalPurchaseSuccess={setModalPurchaseSuccess} isUserLoggedIn={isUserLoggedIn} modalPurchaseSuccess={modalPurchaseSuccess} event_thanks_video={event_thanks_video_landscape} />
                    }
                    {modalBuyEvent &&
                        <Elements stripe={stripePromise}>
                            <ModalBuyEvent
                                currency={currency}
                                confirmEmail={confirmEmail}
                                setConfirmEmail={setConfirmEmail}
                                email={email}
                                setEmail={setEmail}
                                match={match}
                                mobileNav={mobileNav} modalDetail={true} setModalDetail={setModalBuyEvent} onGetUser={onGetUser}
                                setModalPurchaseSuccess={setModalPurchaseSuccess} eventData={eventData} setErrorMessage={setErrorMessage} />

                        </Elements>
                    }
                    <div className="row mt-6">
                        {mobileNav &&
                            (<div style={{ marginTop: mobileNav ? '0px' : purchased ? "30px" : (!purchased && !isCreator) ? "60px" : isCreator && '60px' }} className={` col-12`}>

                                {!mobileNav && <Back text={i18next.t('EventPageComponentGoBack')}
                                    url={`${purchased ? "/live_events" : (!purchased && !isCreator) ? "/" : isInfluencer && "/my_live_events/past_live_events"}`} />
                                }
                                {mobileNav &&
                                    <>
                                        <PreviewBox mobileNav event_date={event_date} event_presentation_video={event_presentation_video_landscape} event_cover_image={event_cover_image}
                                            upcoming={upcoming} live={live} purchased={purchased} isCreator={isCreator} eventType={eventType} startIn={startIn} event_uuid={event_uuid} />
                                        <div className="row p-2 m-2 Shadow-lg rounded-md">
                                            <div className="col-3 row aling-center">
                                                <DiaMes day={time.day} month={time.month} />
                                            </div>
                                            <div className="col-9">
                                                <NombreCat upcoming={upcoming} title={event_title}
                                                    category={event_category} hour={time.time}
                                                    timeTotal={time}
                                                    timeZone={time.gmtOffset}
                                                    year={moment(event_date).format("YYYY")} location={event_location && JSON.parse(event_location).city}
                                                    day={time.day}
                                                />

                                            </div>
                                        </div>
                                        {renderBuyButton && (
                                            <BuyButton mobileNav={mobileNav}
                                                setModalBuyEvent={setModalBuyEvent}
                                                inAppBrowse={inAppBrowse}
                                                upcoming={upcoming}
                                                live={live}
                                                num_tickets_free={event_num_tickets_free !== null ? event_num_tickets_free : event_num_tickets_free_streaming}
                                                event_price={event_price_ticket !== null ? event_price_ticket : event_price_ticket_streaming}
                                                currency={currency}
                                                openTicketing={openTicketing}
                                                purchased={purchased}
                                                isCreator={isCreator}
                                                firstLoad={firstLoad}
                                                marginTop={-8}
                                            />
                                        )}

                                    </>
                                }
                            </div>
                        )}

                        {!mobileNav &&
                            <div className="pl-4 mt-4">
                                <DiaMes day={time.day} month={time.month} />
                            </div>
                        }
                        <div className="col-md-7 mt-4">
                            <div className="mb-6">
                                <PuntUsu score={influencer_ranking} userFullName={user_name} userName={user_username} userAvatar={influencer_avatar} />
                            </div>

                            {!mobileNav && <NombreCat upcoming={upcoming} title={event_title}
                                timeTotal={time}
                                timeZone={time.gmtOffset}

                                category={event_category} hour={time.time}
                                year={moment(event_date).format("YYYY")} location={event_location && JSON.parse(event_location).city}
                                day={time.day}
                            />}
                            {!mobileNav && <PreviewBox event_date={event_date} event_presentation_video={event_presentation_video_landscape} event_cover_image={event_cover_image}
                                upcoming={upcoming} live={live} purchased={purchased} isCreator={isCreator} eventType={eventType} startIn={startIn} event_uuid={event_uuid} />
                            }
                            {!upcoming && <ViewerCount eventData={eventData} />}
                            <div style={{ paddingLeft: mobileNav && "1rem" }}>
                                <h5 className="D-title mt-5">{i18next.t('EventPageComponentLiveEventDescription')}</h5>
                                <Linkify properties={{ target: '_blank' }}>
                                    <p className="Desc" style={{
                                        whiteSpace: "pre-line"
                                    }}>{event_description}</p>
                                </Linkify>
                                <h5 className="D-title mt-5">{i18next.t('EventPageComponentLanguage')}</h5>
                                <div className="d-flex align-items-center p-1 Cg ">
                                    {event_languages.map(language => (
                                        <div key={language.lang_ide}>
                                            <img style={{ height: '30px' }}
                                                className="lenguage"
                                                src={language.lang_image}
                                                alt=""
                                            />
                    &nbsp; {language.lang_description} &nbsp; &nbsp;
                                        </div>
                                    ))}
                                </div>

                                {event_tags && getTags().length > 0 && getTags()[0].length > 0 &&
                                    <>

                                        <h5 className="D-title mt-5">{i18next.t('EventPageComponentRelatedTags')}</h5>
                                        {event_tags.length > 2 && event_tags.split(",").map((tag, index) => (
                                            <Tags key={index} name={tag.replace(/[^a-zA-Z ]/g, "")} />
                                        ))}
                                        <br />

                                    </>
                                }
                            </div>
                            {!isCreator && (upcoming) && !mobileNav && (IS_SIGN_UP_ENABLED) && false && <RequestPrivate userName={user_username} event_uuid={event_uuid} />}

                            {isCreator && <PrivateInfo currency={currency} upcoming={upcoming}
                                event_num_attendees_streaming={event_num_attendees_streaming}
                                event_num_attendees={event_num_attendees}
                                ticket_streaming={ticket_streaming} ticket={ticket}
                                event_price_ticket={event_price_ticket} event_price_ticket_streaming={event_price_ticket_streaming}
                                event_promotional_code_discount={event_promotional_code_discount} />}
                        </div>


                        <div className=" col-md-3 past_chat_event  mt-5 ShareBuyContainer">
                        {renderBuyButton &&

                            <BuyButton mobileNav={mobileNav}
                                setModalBuyEvent={setModalBuyEvent}
                                inAppBrowse={inAppBrowse}
                                upcoming={upcoming}
                                live={live}
                                num_tickets_free={event_num_tickets_free !== null ? event_num_tickets_free : event_num_tickets_free_streaming}
                                event_price={event_price_ticket !== null ? event_price_ticket : event_price_ticket_streaming}
                                currency={currency}
                                openTicketing={openTicketing}
                                purchased={purchased}
                                isCreator={isCreator}
                                firstLoad={firstLoad}
                            />


}
                            {mobileNav || true &&

                                <div style={{ padding: "1rem" }} className="mb-5">
                                    <h5 className="D-title p-0 mb-2">{i18next.t('EventPageComponentShareLiveEvent')}</h5>

                                    <ShareEvent isCreator={isCreator} Url={B2B_PORT ? (event_is_demo ? `${HOME_PAGE}/event/${event_uuid}` : HOME_PAGE) : `${SECURE_LOGIN_BACK_URL}/event/${event_uuid}`} title={event_title} />
                                    <AttendeesEvent eventData={eventData} />

                                </div>

                            }
                            

                            {!mobileNav || true &&
                                <>
                                    <h5 className="D-title p-0 mb-3 mt-4">{i18next.t('EventPageComponentShareLiveEvent')}</h5>
                                    <ShareEvent isCreator={isCreator} Url={B2B_PORT ? (event_is_demo ? `${HOME_PAGE}/event/${event_uuid}` : HOME_PAGE) : `${SECURE_LOGIN_BACK_URL}/event/${event_uuid}`} title={event_title} />
                                    <AttendeesEvent eventData={eventData} />
                                </>
                            }

                        </div>
                    </div>
                </section>
            ) : (
                <section className="Live-Event-Detail container mt-6">
                    <Loader />
                </section>
            )}
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onGetUser: token => dispatch(actions.getUser(token))
    };
};

const mapStateToProps = state => {
    return {
        categories: state.categories.categories,
        currencies: state.currencies.currencies,
        languages: state.languages.languages,
        user: state.user
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventPage));
