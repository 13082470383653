import React, {Component} from 'react';
import './style.scss';
import ImgRelated from '../../../assets/img/image (28).png'
class RelatedVideos extends Component {
    render() {
        return (
           <div className="Related-div d-flex">
               <div className="parrafo_related"><p className="date_related">Monday, 18/12/2019</p>
               <p className="title_related">A Right Media mix Can Make The Difference</p>
               <p className="category_related">Nature, OutDoors & Oets</p></div>
               <img src={ImgRelated} className="img_related"></img>
               <div className="Time2">
                       04:30</div>
           </div>
        );
    }
}
export default RelatedVideos;

