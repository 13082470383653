import React from 'react'
import {  parsePriceByCurrency } from "../../utils/currencyUtils";
import i18next from 'i18next';

 const BuyButton = ({mobileNav,setModalBuyEvent,
     inAppBrowse, upcoming, live,
     num_tickets_free, event_price, 
     currency, marginTop}) => {
    return (
        <>
            {  (
<button  style={{ position: (mobileNav && !inAppBrowse) && 'fixed', marginTop: marginTop }} disabled={num_tickets_free === 0} className="Btn-rojo-b buy-btn mt-3 grow px-4" onClick={() => setModalBuyEvent(true)}>
    {(num_tickets_free > 0 && parseFloat(event_price) > 0) && i18next.t('EventPageComponentBuyTicketFor') + ` ${parsePriceByCurrency(event_price, currency)}`}
    {(num_tickets_free > 0 && parseFloat(event_price) === 0) &&  i18next.t('EventPageComponentBuyTicketForFree')}
    {num_tickets_free === 0 && i18next.t('EventPageComponentSorryEventSoldOut')}
</button>

)}
        </>
    )
}

export default BuyButton