import * as yup from "yup";

export const schema = yup.object().shape({
    confirmPassword: yup
      .string()
      .required("Repeat password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    password: yup
      .string()
      .required("Password is required")
      .test(
        "format",
        "The password must have at least 8 characters 1 uppercase 1 lowercase and 1 number",
        value => {
          const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
          return reg.test(value);
        }
      )
  }); 