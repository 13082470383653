/* returns user time from MS and local user timezone 

if request_date is not a date and request_start_hour is defined, it returns a date object with request_start_hour hour and user's tz

*/
import {getFormattedTimeFromStr} from "utils"
import {useState, useEffect } from 'react'
import {useSelector} from 'react-redux'
import * as momenttz from 'moment-timezone';
import useTimezone from './useTimezone'
const useUserHour = ({request_date, request_start_hour, difference})=> {
    const [userTime, setUserTime] = useState('')
    const [timeStrings, setTimeStrings]= useState('')
    const {timeZone} = useTimezone()
    
  useEffect(()=> {
    let userTime
    let time
    let day
    let month
    let numericMonth
    let dateStr
    
    
      
    if (request_date  && timeZone.length>0){
      userTime = momenttz(parseInt(request_date)).tz(timeZone).format('HH:mm')
      setUserTime (userTime)
       time = momenttz(parseInt(request_date)).tz(timeZone).format('HH:mm')
       day =  parseInt( momenttz(parseInt(request_date)).tz(timeZone).format('DD'))
       month = momenttz(parseInt(request_date)).tz(timeZone).format('MMMM').substring(0, 3).toUpperCase()
       dateStr = (momenttz(parseInt(request_date)).tz(timeZone).format('DD/MM/YYYY')) // used to set the month in the date object and user's perspective
       numericMonth = parseInt(momenttz(parseInt(request_date)).tz(timeZone).format('MM')) -1// used to set the month in the date object and user's perspective
      setTimeStrings ({time, day, month, numericMonth, dateStr })

    }

    /* Case when the request of a private event doesn't come with
    request_date but it does come with request_start_hour */
    else if  ( request_start_hour && difference){
       
      let startTime=  getFormattedTimeFromStr(request_start_hour, difference)
      setTimeStrings ({time:startTime, day:"", month:"", numericMonth:"", dateStr:""})

    }
    // Case of no request_date, no request_start_hour
    else { 
      setTimeStrings({time:"", day:"", month:"", numericMonth:"", dateStr:""})
    }
  },[request_date, timeZone, difference])

  return {userTime, timeStrings}
}

export default useUserHour 