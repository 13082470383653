import React, { Component } from "react";
import "./styles.scss";
import MicrophoneIcon from "assets/img/icons/microphone.svg";

const WIDTH_DEFAULT = 200;
const HEIGHT_DEFAULT = 50;

const MAX_VOLUME_LEVEL = 0.1;
const MIN_VOLUME_LEVEL = 0.008;
const MAX_CIRCLE_ELEMENTS = 5;
const VOLUME_LEVEL_RANGE = MAX_VOLUME_LEVEL / 5;

class AudioVisualiser extends Component {
  constructor(props) {
    super(props);
    if (this.props.type !== "circle") {
      this.canvas = React.createRef();
    }
  }

  componentDidUpdate() {
    if (this.props.type !== "circle") {
      this.draw();
    }
  }

  draw() {
    const { audioData } = this.props;
    const type = this.props.type;
    const canvas = this.canvas.current;
    const height = canvas.height;
    const width = canvas.width;
    const volume = this.props.volume;
    const context = canvas.getContext("2d");

    if (type === "tick") {
      let x = 0;
      const sliceWidth = (width * 1.0) / audioData.length;

      context.lineWidth = 2;
      context.strokeStyle = "#ff5a60";
      context.clearRect(0, 0, width, height);

      context.beginPath();
      context.moveTo(0, height / 2);
      for (const item of audioData) {
        const y = (item / 255.0) * height;
        context.lineTo(x, y);
        x += sliceWidth;
      }
      context.lineTo(x, height / 2);
      context.stroke();
    } else {
      context.clearRect(0, 0, width, height);
      try {
        if (this.props.meter.checkClipping()) {
          context.fillStyle = "red";
        } else {
          context.fillStyle = "green";
        }
      } catch (error) {}

      context.fillRect(0, 0, volume * width * 1.4, height);
    }
  }

  parseRenderVisualizer() {
    const type = this.props.type;

    if (type === "circle") {
      var dotElementStyle = "AudioVisualiserDot ";
      var firstDotElementStyle = dotElementStyle;
      var secondDotElementStyle = dotElementStyle;
      var thirdDotElementStyle = dotElementStyle;
      var fourthDotElementStyle = dotElementStyle;
      var fithDotElementStyle = dotElementStyle;

      if (this.props.volume > VOLUME_LEVEL_RANGE * 4) {
        firstDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        secondDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        thirdDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        fourthDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        fithDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
      } else if (this.props.volume > VOLUME_LEVEL_RANGE * 3) {
        firstDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        secondDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        thirdDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        fourthDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        fithDotElementStyle = dotElementStyle;
      } else if (this.props.volume > VOLUME_LEVEL_RANGE * 2) {
        firstDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        secondDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        thirdDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        fourthDotElementStyle = dotElementStyle;
        fithDotElementStyle = dotElementStyle;
      } else if (this.props.volume > VOLUME_LEVEL_RANGE * 1) {
        firstDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        secondDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        thirdDotElementStyle = dotElementStyle;
        fourthDotElementStyle = dotElementStyle;
        fithDotElementStyle = dotElementStyle;
      } else if (this.props.volume > MIN_VOLUME_LEVEL) {
        firstDotElementStyle = dotElementStyle + "AudioVisualiserDotActive";
        secondDotElementStyle = dotElementStyle;
        thirdDotElementStyle = dotElementStyle;
        fourthDotElementStyle = dotElementStyle;
        fithDotElementStyle = dotElementStyle;
      } else {
        firstDotElementStyle = dotElementStyle;
        secondDotElementStyle = dotElementStyle;
        thirdDotElementStyle = dotElementStyle;
        fourthDotElementStyle = dotElementStyle;
        fithDotElementStyle = dotElementStyle;
      }

      return (
        <div className={"AudioVisualiserContainer"}>
          <img
            src={MicrophoneIcon}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
          <div className={firstDotElementStyle}></div>
          <div className={secondDotElementStyle}></div>
          <div className={thirdDotElementStyle}></div>
          <div className={fourthDotElementStyle}></div>
          <div className={fithDotElementStyle}></div>
        </div>
      );
    } else {
      return (
        <canvas
          width={this.props.width || WIDTH_DEFAULT}
          height={this.props.height || HEIGHT_DEFAULT}
          ref={this.canvas}
        />
      );
    }
  }

  render() {
    return this.parseRenderVisualizer();
  }
}

export default AudioVisualiser;
