import React from "react";
import { Link, withRouter } from "react-router-dom";
import "./style.scss";
import user2 from "assets/img/icons/ic-user.svg";
import user3 from "assets/img/icons/ic-edit.svg";
import user4 from "assets/img/icons/ic-billing.svg";
import user5 from "assets/img/icons/ic-settings.svg";
import user6 from "assets/img/icons/ic-help.svg";
import user7 from "assets/img/icons/ic-wallet.svg";
import payout from "assets/img/icons/ic-ganancia.svg";
import circularLogo from "assets/img/logo-circulo-white.png";
import { connect, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { INFLUENCER_ICON_ENABLED, PRIVATE_MARKETPLACE, RESTRICTED_VIEWS } from "../../../environment/environment"
import i18next from 'i18next';

const Login_menu = ({ history, user, influencer, onLogoutUser }) => {
  const isInfluencer = useSelector(state => state.user.isInfluencer)
  const env = JSON.parse(localStorage.getItem("env") || "{}");
  const logout = e => {
    e.preventDefault();
    // localStorage.clear();
    localStorage.removeItem('env')

    const ref = window.location.href;
    if (ref.charAt(ref.length - 1) == "/") {
      window.location.reload();
    } else {
      history.push("/");
    }
  };
  const handleLogOut = async () => {
    await onLogoutUser()
    localStorage.clear();
    const ref = window.location.href;
    if (ref.charAt(ref.length - 1) == "/") {
      window.location.reload();
    } else {
      history.push("/");
    }

  }
  return (
    <>
      {isInfluencer ? (
        <>
          <div className="dropdown userNavMenu">
            <a className="dropdown-toggle" data-toggle="dropdown">
              <div className="Logued-div">
                <div>
                  {user&& user.user_username? 
                    <span className="Name">
                    {user.user_username}
                  </span>
                  :
                  <span className="Name">
                    {user.name} {user.user_name}
                  </span>
              
                }
                  {!PRIVATE_MARKETPLACE && (<span className="Mode">{i18next.t('InfluencerDropdownMenuInfluencerMode')}</span>)}
                </div>
                <div className="profile-img-container">
                  <img src={influencer.influencer_avatar} alt="" />
                  {INFLUENCER_ICON_ENABLED && (<img src={circularLogo} className="circularLogo" alt="" />)}
                </div>
              </div>
            </a>
            <div className="dropdown-menu profile" id="profile_influencer">
              {!PRIVATE_MARKETPLACE && (<>
              <Link to="/profile_influencer">
                <a className="dropdown-item" href="#">
                  <img src={user2}></img>{i18next.t('InfluencerDropdownMenuMyProfile')}
                </a>
              </Link>
              <Link to={`/influencer_profile/active`}>
                <a className="dropdown-item" href="#">
                  <img src={user3}></img>{i18next.t('InfluencerDropdownMenuEdit')}
                </a>
              </Link>
              <Link to={`/my_live_events`}>
                <a className="dropdown-item" href="#">
                  <img src={user2}></img>{i18next.t('InfluencerDropdownMenuMyLiveEvents')}
                </a>
              </Link>
              {<Link to={`/my_balance`}>
                <a className="dropdown-item" href="#">
                  <img src={user7}></img>{i18next.t('InfluencerDropdownMenuMyBalance')}
                </a>
              </Link>
              }
              {<Link to={`/my_influencer_payout_methods`}>
                <a className="dropdown-item" href="#">
                  <img src={payout}></img>{i18next.t('InfluencerDropdownMenuMyPayoutMethods')}
                </a>
              </Link>
              }
              {!RESTRICTED_VIEWS && <Link to={`/billing`}>
                <a className="dropdown-item" href="#">
                  <img src={user4}></img>{i18next.t('InfluencerDropdownMenuBilling')}
                </a>
              </Link>
              }
              {!RESTRICTED_VIEWS && <a className="dropdown-item" href="#">
                <img src={user5}></img>{i18next.t('InfluencerDropdownMenuSettings')}
              </a>}
              <a className="dropdown-item" href="https://support.gotmy.com/hc/en-us" target="_blank" >
                <img src={user6} alt=""></img>{i18next.t('InfluencerDropdownMenuHelp')}
                </a>
              <Link to={`/`}>
                <div className="d-flex justify-content-center mt-3">
                  <button className="Btn-rojo boton_go">
                  {i18next.t('InfluencerDropdownGoViewer')}                  </button>
                </div>
              </Link>
              </>)}
              <div className="div_logout" onClick={handleLogOut}>
                <span className="LogOut"> {i18next.t('InfluencerDropdownLogout')}       </span>
              </div>
            </div>
          </div>
        </>
      ) : !PRIVATE_MARKETPLACE && (
          <button
            className="Btn-rojo"
            data-toggle="modal"
            data-target="#myModal_sign"
          >
            Join gotmy for FREE
          </button>
        )}
    </>
  );
};
const mapDispatchToProps = dispatch => {
  return {
    onLogoutUser: () => dispatch(actions.logoutUser()),
  };
};
const mapStateToProps = state => {
  return {
    user: state.user.user,
    influencer: state.user.influencer
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login_menu));
