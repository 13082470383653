import React , {useEffect, useState} from 'react'
import moment from "moment"
import {useSelector} from "react-redux"
import {Link} from "react-router-dom"
import {findCurrencyByCurrencyCode} from "utils/currencyUtils"
 const EventTableRow = ({event}) => {
    const categories = useSelector((state)=>state.categories.categories)
  const [category, setCategory] = useState()
  const [formatedDate, setFormatedDate] = useState()

const currencies = useSelector ((state)=>state.currencies)
    useEffect(()=>{
        if (categories && event ){
          let category = categories.find((category)=> category.cate_ide===event.event_category_ide)
          if (category){
              setCategory (category.cate_description)
          }
          setFormatedDate(moment(parseInt( event.event_date)).format("dddd D/M/YYYY"))
        }
      },[categories, event])
    return (
       
        <tr key={event.event_ide} >
        <td className="py-5 px-3 ">
            <Link to={`/event/${event.event_uuid}`}>
          <div className="d-flex ">
              <div className="mr-3" >
              <img className="Shadow-md rounded-md grow" style={{ height: 75, maxWidth:100, objectFit:"cover"  }}  src={event.event_cover_image}/> 
              </div>
          <div>
          <p  style={{color:"black"}} className= "mb-2">

          {event.event_title}
          </p>
          <p style={{ fontSize:14, color: "#ff5a60"}} >
            {category}
          </p >
          </div>

          </div>

            </Link>
          </td>
          
        <td className="py-5 px-3 "> {formatedDate} </td>
        <td className="py-5  px-3" >  {event.event_price_ticket} {findCurrencyByCurrencyCode(event.event_currency, currencies.currencies)? findCurrencyByCurrencyCode(event.event_currency, currencies.currencies).currency_symbol:""} </td>
        <td className="py-5  px-3"> {event.event_num_attendees} </td>
        <td className="py-5  px-3"> {` ${event.event_price_ticket * event.event_num_attendees} ${findCurrencyByCurrencyCode(event.event_currency, currencies.currencies)? findCurrencyByCurrencyCode(event.event_currency, currencies.currencies).currency_symbol:""}` } </td>

</tr>
    )
}
export default EventTableRow
