import React from 'react'
import { Link } from "react-router-dom";
import i18next from 'i18next';

 const NoEvents = ({message}) => {
    return (
        <div style={{justifySelf: 'center'}}>
        <p style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%",fontSize: "22px"}}>
                  {message}
                </p>    
                <p style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%",fontSize: "22px"}}>   
                <Link to="/">{i18next.t("NoEventsComponentDiscoverNewEvents")}</Link></p>
        </div>
    )
}
export default NoEvents