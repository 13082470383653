import axios from "axios";
import {
  Api,
  ACCESS_TOKEN_API,
  SECOND_ACCESS_TOKEN_API,
  IS_DEV_ENVIRONMENT
} from "../environment/environment";
import * as LogTypes from './../constants/LogTypes'
import URI from "urijs";
import { version } from "../../package.json";
import { parseLocale } from '../utils/Localization'

var DetectRTC = require('detectrtc');

let headers = {
  "Access-Token": SECOND_ACCESS_TOKEN_API,
  "Content-Language": parseLocale()
};

const refreshHeaders = (accessToken) => {
  DetectRTC.load();
  headers = {};

  headers["Access-Token"] = accessToken;
  headers["Platform"] = "Web/" + DetectRTC.browser.name + "/" + DetectRTC.browser.fullVersion + "/OS:" + DetectRTC.osName + DetectRTC.osVersion;
  headers["Platform-Display"] = DetectRTC.displayResolution;
  headers["Video-Permission"] = DetectRTC.isWebsiteHasWebcamPermissions;
  headers["Microphone-Permission"] = DetectRTC.isWebsiteHasMicrophonePermissions;
  headers["Microphone"] = DetectRTC.hasMicrophone;
  headers["Camera"] = DetectRTC.hasWebcam;
  headers["isWebSocketsBlocked"] = DetectRTC.isWebSocketsBlocked;
  headers["isWebSocketsSupported"] = DetectRTC.isWebSocketsSupported;
  headers["Content-Language"] = parseLocale();
  headers["Build"] = version;

}


export default class EventDAO {
  findLivingEvents() {
    return axios.get(`${Api}events/living`, { headers });
  }
  getTicketType() {
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    if (env.token) {
      refreshHeaders(env.token);
    }

    return axios.get(`${Api}tickets/types`, { headers });
  }

  findById(id) {
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    if (env.token) {
      refreshHeaders(env.token);
    }

    return axios.get(`${Api}events/${id}`, { headers });
  }

  findByIdWithSpecialToken(id, token) {
    refreshHeaders(token);

    return axios.get(`${Api}events/${id}`, { headers });
  }

  findUpcomingEvents(categories) {
    const url = new URI(`${Api}events/upcoming`);
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    if (env.token) {
      refreshHeaders(env.token);
    }
    if (categories) url.addSearch("categories", categories);
    return axios.get(url, { headers });
  }

  findPastEvents(categories) {
    const url = new URI(`${Api}events/past`);
    if (categories) url.addSearch("categories", categories);
    return axios.get(`${url}`, { headers });
  }

  create(data, token) {
    console.log ("IN CREATE")
    if (token) {
      refreshHeaders(token);
    }

    headers["Content-Type"] = "multipart/mixed";

    return axios.post(`${Api}events/create`, data, {
      headers
    });
  }

  updateThanksVideo(data, token) {
    return axios.post(`${Api}events/updateThanksVideo`, data, {
      headers: {
        "Access-Token": token,
        "Content-Type": "multipart/mixed"
      }
    });
  }
  updateThanksVideoLandscape(data, token) {
    return axios.post(`${Api}events/updateThanksVideoLandscape`, data, {
      headers: {
        "Access-Token": token,
        "Content-Type": "multipart/mixed"
      }
    });
  }
  updatePresentationVideo(data, token) {
    return axios.post(`${Api}events/updatePresentationVideo`, data, {
      headers: {
        "Access-Token": token,
        "Content-Type": "multipart/mixed"
      }
    });
  }
  updatePresentationVideoLandscape(data, token) {
    return axios.post(`${Api}events/updatePresentationVideoLandscape`, data, {
      headers: {
        "Access-Token": token,
        "Content-Type": "multipart/mixed"
      }
    });
  }

  findMyFavoriteEvents(token) {
    refreshHeaders(token);
    return axios.get(`${Api}events/favorites`, { headers });
  }

  purchaseEvent(token, purchaseData) {
    refreshHeaders(token);
    return axios.post(`${Api}events/purchase`, purchaseData, { headers });
  }
  purchaseEventNoLogin(token, paymentData) {
    refreshHeaders(token);
    return axios.post(`${Api}events/purchase_no_login`, paymentData, { headers });
  }

  purchasedEvents(token) {
    refreshHeaders(token);
    return axios.get(`${Api}user/purchased_events`, { headers });
  }

  startStreaming(event_uuid, token) {
    refreshHeaders(token)

    return axios.post(
      `${Api}events/streaming/start_streaming`,
      {
        event_uuid: event_uuid,
        platform: "web",
        type: "go"//type: "group-small"
      },
      { headers }
    );
  }


  joinStreaming(event_uuid, token) {
    refreshHeaders(token);

    return axios.post(
      `${Api}events/streaming/join_streaming`,
      {
        event_uuid: event_uuid,
        platform: "web"
      },
      { headers }
    );
  }


  startStreamingRTMP(event_uuid, token) {
    refreshHeaders(token);

    return axios.post(
      `${Api}events/streaming/start_pure_streaming`,
      {
        event_uuid: event_uuid,
        platform: "web"
      },
      { headers }
    );
  }

  joinStreamingRTMP(event_uuid, token) {
    refreshHeaders(token);

    return axios.post(
      `${Api}events/streaming/join_pure_streaming`,
      {
        event_uuid: event_uuid,
        platform: "web"
      },
      { headers }
    );
  }

  stopStreaming(event_uuid, token) {
    refreshHeaders(token);
    return axios.post(
      `${Api}events/streaming/stop_streaming`,
      { event_uuid },
      { headers }
    );
  }

  stopStreamingRTMP(event_uuid, token) {
    refreshHeaders(token);
    return axios.post(
      `${Api}events/streaming/stop_pure_streaming`,
      { event_uuid },
      { headers }
    );
  }


  joinStreamingShareScreen(event_uuid, token) {
    refreshHeaders(token);
    return axios.post(
      `${Api}events/streaming/join_streaming_screen_share`,
      {
        event_uuid: event_uuid,
        platform: "web"
      },
      { headers }
    );
  }


  shareScreenReady(event_uuid, connection_id, token) {
    refreshHeaders(token);
    return axios.post(
      `${Api}events/streaming/screen_share_ready`,
      {
        event_uuid: event_uuid,
        connection_id: connection_id
      },
      { headers }
    );
  }



  influencerReady(event_uuid, connection_id, token) {
    refreshHeaders(token);
    return axios.post(
      `${Api}events/streaming/influencer_ready`,
      {
        event_uuid: event_uuid,
        connection_id: connection_id
      },
      { headers }
    );
  }


  stopShareScreen(event_uuid, token) {
    refreshHeaders(token);
    return axios.post(
      `${Api}events/streaming/close_streaming_screen_share`,
      {
        event_uuid: event_uuid
      },
      { headers }
    );
  }


  sendEventMessage(event_uuid, message, token) {
    refreshHeaders(token);
    return axios.post(
      `${Api}events/streaming/messages`,
      { event_uuid, message },
      { headers }
    );
  }

  sendEventMessageSocketIoMessage(event_uuid, message, token) {
    refreshHeaders(token);
    return axios.post(
      `${Api}events/streaming/messages_new`,
      { event_uuid, message },
      { headers }
    );
  }

  getEventMessage(event_uuid, token) {
    refreshHeaders(token);
    return axios.get(`${Api}events/streaming/messages`, { event_uuid }, { headers });
  }

  reviewEvent(data, token) {
    refreshHeaders(token);
    return axios.post(`${Api}events/review`, data, { headers });
  }

  toggleRiseHand(event_uuid, mode = null, token) {
    refreshHeaders(token);

    var body = { event_uuid: event_uuid };
    if (mode != null && mode != undefined) {
      body = { event_uuid: event_uuid, enabled: mode };
    }

    return axios
      .post(`${Api}events/streaming/toggle_raise_hand`, body, { headers })
      .then(function (response) {
        // handle success
      })
      .catch(function (error) {
        // handle error
      })
      .finally(function () {
        // always executed
      });
  }


  toggleEnableChat(event_uuid, mode = null, token) {
    refreshHeaders(token);

    var body = { event_uuid: event_uuid };
    if (mode != null && mode != undefined) {
      body = { event_uuid: event_uuid, enabled: mode };
    }

    return axios
      .post(`${Api}events/streaming/toggle_chat_enabled`, body, { headers })
      .then(function (response) {
        // handle success
      })
      .catch(function (error) {
        // handle error
      })
      .finally(function () {
        // always executed
      });
  }


  toggleOpenMic(event_uuid, mode = null, token) {
    refreshHeaders(token);

    var body = { event_uuid: event_uuid };
    if (mode != null && mode != undefined) {
      body = { event_uuid: event_uuid, enabled: mode };
    }

    return axios
      .post(`${Api}/events/streaming/toggle_open_mic`, body, { headers })
      .then(function (response) {
        // handle success
      })
      .catch(function (error) {
        // handle error
      })
      .finally(function () {
        // always executed
      });
  }

  allowMic(data, token) {
    refreshHeaders(token);
    return axios.post(`${Api}events/streaming/allow_mic`, data, { headers });
  }

  getCurrentStateStreaming(uuid, token) {
    refreshHeaders(token);
    return axios.get(`${Api}events/streaming/streaming_state/${uuid}`, { headers });
  }

  raiseHand(event_uuid, mode = null, token) {
    refreshHeaders(token);

    var body = { event_uuid: event_uuid };
    if (mode != null && mode != undefined) {
      body = { event_uuid: event_uuid, enabled: mode };
    }

    return axios
      .post(`${Api}events/streaming/raise_hand`, body, { headers })
      .then(function (response) {
        // handle success
        console.log("raiseHand", response)
      })
      .catch(function (error) {
        console.log("raiseHand", error)
        // handle error
      })
      .finally(function () {
        // always executed
      });
  }


  sendServerLog(logData, event_uuid = null, log_type = LogTypes.STREAMING_LOG, token) {
    refreshHeaders(token);

    var device_info = DetectRTC;

    try {
      var req_body = {
        message: "Web: " + logData.message,
        data: {
          data: logData.data,
          deviceInfo: device_info
        },
        event_uuid: event_uuid,
        log_type: log_type
      }

      return axios
        .post(`${Api}log/add`, req_body, { headers })
        .then(function (response) {
          // handle success

        })
        .catch(function (error) {

          // handle error
        })
        .finally(function () {
          // always executed
        });
    } catch {

    }
  }


  getTrendingTags() {
    return axios.get(`${Api}events/trending_tags`, { headers });
  }
}
