import React, { Component } from 'react';
import user from "../../../assets/img/image (37).png"
import './style.scss';
import Rating from "../../../assets/img/5-estrellas.png";
import { Table } from 'react-bootstrap';
import Search from '../search_input_peq';
import map from "../../../assets/img/icons/ic-map.svg";
import usr from "../../../assets/img/user.png";
import id from '../../../assets/img/icons/ic-billing.svg';
import status from '../../../assets/img/icons/ic-time-copy.svg';
import date from '../../../assets/img/icons/ic-event.svg';
import paypal from '../../../assets/img/icons/Paypal.svg';
import logo from '../../../assets/img/logo.png';
import Close from '../../../assets/img/icons/ic-detele.svg';
class FilteredTableTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [
        {
          "img": user,
          "title": "Party NFS HOT PURSUIT",
          "category": "Sports",
          "date": "74573656-mdp",
          "date2": "Paid with Paypal",
          "days": "20 days left",
          "price":"Monday, 24 Jul 2019",
          "max" : "13:00 pm",
          "sales": usr,
          "name": "Cynthia Morris",
          "amount":"$400.00",
          "rating": Rating,
          "href": `/stats/live_stats/stats-detail`,
        },
        {
          "img": user,
          "title": "Private meeting for birthday with Linnie Butler",
          "category": "Private Meeting",
          "date": "74573656-mdp",
          "date2": "Paid with Paypal",
          "days": "20 days left",
          "price":"Monday, 24 Jul 2019",
          "max" : "13:00 pm",
          "sales": usr,
          "name": "Cynthia Morris",
          "amount":"$400.00",
          "rating": Rating,
          "href": `/stats/live_stats/stats-detail`,
          },
        {
          "img": user,
          "title": "Getting Cheap Airfare For Last",
          "category": "Travel & Experiences",
          "date": "74573656-mdp",
          "date2": "Paid with Paypal",
          "days": "20 days left",
          "price":"Monday, 24 Jul 2019",
          "max" : "13:00 pm",
         "sales": usr,
         "name": "Cynthia Morris",
          "amount":"$400.00",
          "rating": Rating
          },
        {
          "img": user,
          "title": "Getting Cheap Airfare For Last",
          "category": "Sports",
          "date": "74573656-mdp",
          "date2": "Paid with Paypal",
          "days": "20 days left",
          "price":"Monday, 24 Jul 2019",
          "max" : "13:00 pm",
          "sales": usr,
          "name": "Cynthia Morris",
          "amount":"$400.00",
          "rating": Rating
          },
          {
          "img": user,
          "title": "Getting Cheap Airfare For Last",
          "category": "Sports",
          "date": "74573656-mdp",
          "date2": "Paid with Paypal",
          "days": "20 days left",
          "price":"Monday, 24 Jul 2019",
          "max" : "13:00 pm",
          "sales": usr,
          "name": "Cynthia Morris",
          "amount":"$400.00",
          "rating": Rating
          },   
        ]
      };
    }
  render() {
    const { tasks } = this.state;
    return (   
      <div className="balanced_container">
        <div className="modal" id="myModal" >
          <div className="modal-dialog">
              <div className="modal-content modal_rating">
              <img data-dismiss="modal" src={Close} className="Cruz" alt=""/>
                  <div className="modal-body modal_rating_body">
                    <div className="d-flex flex-column align-items-center">
                        <div className="Div-g">
                          <div className="d-flex align-items-center flex-column">
                            <img src={usr} alt=''/>
                            <p className="mb-2">Cynthia Morris</p>
                            <h2>$ 400.00</h2>
                            <div className="d-flex align-items-center mb-4"><img className="mr-4" alt='' src={id}/><div className="modal_content_balance"><p className="mb-0">Transaction ID</p><p className="mb-0">768483732</p></div></div>
                            <div className="d-flex align-items-center mb-4"><img className="mr-4" alt='' src={status}/><div className="modal_content_balance"><p className="mb-0">Transaction status</p><p className="mb-0">Success</p></div></div>
                            <div className="d-flex align-items-center mb-4"><img className="mr-4" alt='' src={date}/><div className="modal_content_balance"><p className="mb-0">Date</p><p className="mb-0">12/01/2019 - 12:45 PM</p></div></div>
                            <div className="d-flex align-items-center mb-4"><img className="mr-4" alt='' src={paypal}/><div className="modal_content_balance"><p className="mb-0">Payment Method</p><p className="mb-0">Paypal</p></div></div>
                          </div>
                          <img className="w-100 logo_modal" src={logo}/>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
        <div className="d-flex search_div_balance"><h2 id="all_transactions">All Transactions</h2> 
      <div className="d-flex w-100 justify-content-end"><Search/>
      <select id="" className="mb-4 select_review">
      <option value="seven_days">Order by most populary</option>
      <option value="month">Order by</option>
      <option value="six_month">Order by</option>
      <option value="year">Order by</option>
    </select></div></div>
              <Table responsive className="mt-4">
                    <thead>
                        <tr>
                        <th>Live Event Detail</th>
                        <th>ID Transaction</th>
                        <th>Date</th>
                        <th>Viewer</th>
                        <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.map(task =><tr data-toggle="modal" data-target="#myModal" className="table_balance"><td><span className="span_table">{task.title}<br/><span className="table_category">{task.category}</span></span></td><td><p className="mb-0">{task.date}</p><p className="mb-0 grey">{task.date2}</p><p className="grey">{task.days}</p></td><td><p className="mb-0">{task.price}</p><p className="grey">{task.max}</p></td><td><div className="d-flex"><img className="mr-3" src={task.sales}/><div className="d-flex flex-column justify-content-center"><span>{task.name}</span> <span className="Hor" id="country_balance"> <img src={map} alt=""/>Country</span></div></div></td><td><p>{task.amount}</p></td><td></td></tr>)}     
                    </tbody>
                    </Table></div>
    );
  }
}
export default FilteredTableTransfer;