import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as EventTypes from "../../constants/EventTypes";
import useWindowSize from "../../hooks/useWindowSize";
import { isMobile } from "react-device-detect";
import {
  hideNotReadyContent,
  disabledStartStreaming,
  IS_INFLUENCER_STREAMING_ENABLED,
  IS_VIEWER_STREAMING_ENABLED
} from "../../environment/environment";
import moment from "moment";
import i18next from "i18next";

const renderViewStreaming = (
  aboutToBegin,
  isCreator,
  eventType,
  purchased,
  startIn,
  event_uuid,
  upcoming,
  live
) => {
  let streamingRoute = "";

  if (eventType && eventType.code !== null) {
    if (eventType.code === EventTypes.WEBRTC) {
      streamingRoute = EventTypes.WEBRTC_ROUTE;
    } else {
      streamingRoute = EventTypes.RTMP_ROUTE;
    }
  }

  const goToMobileStreaming = () => {
    window.location.href = `/mobileStreaming/${event_uuid}`;
    //window.location.reload();
  };

  if (isMobile && eventType.code === EventTypes.WEBRTC) {
    if (isCreator && IS_INFLUENCER_STREAMING_ENABLED) {
      return (
        <BoxStreaming className={"BoxStreaming"} style={{}}>
          <div className="linkToStreaming w-100" onClick={goToMobileStreaming}>
            <p className="start">
              {i18next.t("BeginInComponentStreaming")}{" "}
              {live
                ? i18next.t("BeginInComponentBegan")
                : upcoming && i18next.t("BeginInComponentBegins")}{" "}
              {startIn}{" "}
            </p>

            <Button className={"Btn-rojo"}>
              {i18next.t("BeginInComponentStartStreaming")}
            </Button>
          </div>
        </BoxStreaming>
      );
    } else if (purchased && IS_VIEWER_STREAMING_ENABLED) {
      return (
        <BoxStreaming className={"BoxStreaming"} style={{}}>
          <div className="linkToStreaming w-100" onClick={goToMobileStreaming}>
            {!aboutToBegin && (
              <p className="start">
                {i18next.t("BeginInComponentStreaming")}{" "}
                {live
                  ? i18next.t("BeginInComponentBegan")
                  : upcoming && i18next.t("BeginInComponentBegins")}{" "}
                {startIn}{" "}
              </p>
            )}
            {aboutToBegin && (
              <p className="start">The host hasn't started the event yet! </p>
            )}

            {live && (
              <Button className={"Btn-rojo"}>
                {i18next.t("BeginInComponentViewStreaming")}
              </Button>
            )}
          </div>
        </BoxStreaming>
      );
    }
  }

  if (isCreator && IS_INFLUENCER_STREAMING_ENABLED) {
    return (
      <BoxStreaming className={"BoxStreaming"} style={{}}>
        <Link
          className="linkToStreaming w-100"
          to={`/${streamingRoute}/${event_uuid}`}
        >
          <p className="start">
            {i18next.t("BeginInComponentStreaming")}{" "}
            {live
              ? i18next.t("BeginInComponentBegan")
              : upcoming && i18next.t("BeginInComponentBegins")}{" "}
            {startIn}{" "}
          </p>

          <Button className={"Btn-rojo"}>
            {i18next.t("BeginInComponentStartStreaming")}
          </Button>
        </Link>
      </BoxStreaming>
    );
  } else if (purchased && IS_VIEWER_STREAMING_ENABLED) {
    return (
      <BoxStreaming className={"BoxStreaming"} style={{}}>
        <Link
          className="linkToStreaming w-100"
          to={`/${streamingRoute}/${event_uuid}`}
        >
          {!aboutToBegin && (
            <p className="start">
              {i18next.t("BeginInComponentStreaming")}{" "}
              {live
                ? i18next.t("BeginInComponentBegan")
                : upcoming && i18next.t("BeginInComponentBegins")}{" "}
              {startIn}{" "}
            </p>
          )}
          {aboutToBegin && (
            <p className="start">The host hasn't started the event yet! </p>
          )}

          {live && (
            <Button className={"Btn-rojo"}>
              {i18next.t("BeginInComponentViewStreaming")}
            </Button>
          )}
        </Link>
      </BoxStreaming>
    );
  }
};

const BeginsIn = ({
  event_date,
  startIn,
  purchased,
  isCreator,
  eventType,
  upcoming,
  live,
  event_uuid
}) => {
  const size = useWindowSize();
  const [extraTop, setExtraTop] = useState("6.4rem");
  var now = new Date().getTime();
  const [aboutToBegin, setAboutToBegin] = useState();
  useEffect(() => {
    var now = new Date().getTime();
    if (live) {
      setAboutToBegin(false);
    } else if (event_date && upcoming) {
      if (now > parseInt(event_date)) {
        setAboutToBegin(true);
      } else if (now < parseInt(event_date)) {
        setAboutToBegin(false);
      }
    }
  }, [event_date, upcoming, live]);
  useEffect(() => {
    if (isCreator || (live && !purchased)) {
      if (size.width < 1600) {
        setExtraTop("6.5rem");
      } else if (size.width > 1600) {
        setExtraTop("7.2rem");
      }
    } else {
      setExtraTop("");
    }
  }, [isCreator, upcoming, live, purchased, size]);

  if (!disabledStartStreaming) {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          top: 0
        }}
      >
        {(purchased || isCreator) && (upcoming || live)
          ? renderViewStreaming(
              aboutToBegin,
              isCreator,
              eventType,
              purchased,
              startIn,
              event_uuid,
              upcoming,
              live
            )
          : (upcoming || live) && (
              <BoxStreaming className={"BoxStreaming"} style={{}}>
                {" "}
                {!aboutToBegin && (
                  <p className="start">
                    {i18next.t("BeginInComponentStreaming")}{" "}
                    {live
                      ? i18next.t("BeginInComponentBegan")
                      : upcoming &&
                        now < parseInt(event_date) &&
                        i18next.t("BeginInComponentBegins")}{" "}
                    {startIn}{" "}
                  </p>
                )}
                {aboutToBegin && (
                  <p className="start">
                    The host hasn't started the event yet!{" "}
                  </p>
                )}
                {(purchased || isCreator) && (
                  <Button>
                    {purchased
                      ? i18next.t("BeginInComponentViewStreaming")
                      : isCreator &&
                        i18next.t("BeginInComponentStartStreaming")}
                  </Button>
                )}
              </BoxStreaming>
            )}
      </div>
    );
  } else {
    return null;
  }
};

export default BeginsIn;

const BoxStreaming = styled.div`
  width: 100%;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.5);
  height: auto;
  border-radius: 10px 10px 0 0;
  p {
    color: #fff;
    font-size: 20px;
    margin-left: 20px;
  }

  @media (max-width: 1400px) {
    p {
      width: 50%;
      margin-right: 5%;
      margin-left: 5%;
    }
  }

  @media (min-width: 1600px) {
    height: auto;
    p {
      font-size: 22px;
      margin-left: 22px;
    }
  }

  @media (min-width: 768px) {
    height: auto;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .linkToStreaming {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
const Button = styled.button`
  margin-right: 20px;
  border-radius: 50px;
  font-size: 18px;
  border: 0;

  color: #fff;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;

  @media (min-width: 1600px) {
    height: 55px;
    font-size: 22px;
  }
`;
