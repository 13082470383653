import axios from "axios";
import {
  Api,
  ACCESS_TOKEN_API,
  SECOND_ACCESS_TOKEN_API
} from "./../environment/environment";

let headers = { "Access-Token": SECOND_ACCESS_TOKEN_API };

export default class ChatDAO {
  findAll(token) {
    return axios.get(`${Api}chat/all`, {
      headers: { "Access-Token": token }
    });
  }
}
