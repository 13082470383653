/* hook to handle the state and redirects related to the event purchase */
import { useState, useEffect, useRef } from 'react'
import { EventDAO } from "daos";
import { CardElement } from '@stripe/react-stripe-js';
import { ACCESS_TOKEN_API, Api,B2B_PORT,HOME_PAGE_PAYPAL_REDIRECT} from '../environment/environment'
import useStateRef from './useStateRef'
import axios from "axios";
import useIsUserLoggedIn from './useIsUserLoggedIn'

import i18next from 'i18next';
let headers = { "Access-Token": ACCESS_TOKEN_API };

const eventDAO = new EventDAO();

const useBuyEvent = ({ match, finalPrice, email, confirmEmail, redirect, setRedirect, event_uuid, stripe, elements,
    onGetUser, usesPaypal, privacy, terms , ticket, ticket_streaming, savedCard}) => {
    let {isUserLoggedIn} = useIsUserLoggedIn()
    if(B2B_PORT){
        isUserLoggedIn = false;
    }
    const [payment, setPayment] = useState({})
    const urlRef = useRef(0) // used to track that the iframe url has changed, and access the url when the url belongs to gotmy
    const [paymentIntent, setPaymentIntent, paymentIntentRef] = useStateRef({}) // used for both stripe and paypal
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    useEffect(() => {
        if (redirect.status === true && !usesPaypal) {
            var iframe = document.getElementById("iframe");
            iframe.addEventListener('load', srcChange)
        }
        return () => {
            var iframe = document.getElementById("iframe");
            if (iframe) {
                iframe.removeEventListener('load', srcChange)
            }
        }
    }, [redirect.status])

    /* Event listener when the iframe changes url  (load event) */
    function srcChange() {
        try { 
            if ((!usesPaypal) && redirect && urlRef.current > 0) {
                var redirectUrl = document.getElementById("iframe").contentWindow.location.href;
                if (redirectUrl.includes(redirect.return_url)) {
                    const param = redirectUrl.split('?').pop()
                    setRedirect({ ...redirect, status: false })
                    // Get status!!
                    getStripeIntentStatus(param)
                }
            }
            urlRef.current++;

        }
        catch (e) {

        }

    }
  // Listen to changes in local storage, finish paypal transaction
  const paypalCallbackListener = (e)=> {
      e.preventDefault()
    let paypalPayment
    if (e.key==='paypal'){
        paypalPayment = JSON.parse (e.newValue)
        if (paypalPayment.paypalId===paymentIntentRef.current.id){
            getPaypalOrderStatus(paypalPayment)
        }
    }
    localStorage.removeItem(e.key)
}
    // Call to the API to get the status after the redirect 
    const getPaypalOrderStatus = async (paypalPayment) => {
        try {

            // 1. CALL GET API to confirm payment success or failure
            let callback = paypalPayment.success? 'paypal_callback' : "paypal_callback_error"
            // let confirmationStatus = await axios.get(`${Api}payment/${callback}`, { headers })
            let confirmationStatus = await axios.get(`${Api}payment/${callback}`)
            // 2. Get status of purchase from backend
            const status = await axios.post(`${Api}payment/paypal_order`, { paypal_order_id: paymentIntentRef.current.id }, { headers });
            setRedirect({ ...redirect, status: false })

            if (status.data && (status.data.data.status === 'COMPLETED'|| status.data.data.status === 'APPROVED' ) ){
                setPayment({ status: 'success' })
                onGetUser(env.token);
            }
            else {
                setPayment({ status: 'error', message: i18next.t('UseBuyEventComponentErrorProcessingEventPayment')})
            }
        }
        catch (err) {
            setPayment({ status: 'error', message:  i18next.t('UseBuyEventComponentErrorProcessingEventPayment')})

        }
    }


    // Call to the API to get the status after the redirect 
    const getStripeIntentStatus = async () => {
        try {

            const status = await axios.post(`${Api}payment/stripe_intent`, { payment_intent: paymentIntent.id }, { headers });
            if (status.data && status.data.data.status === 'succeeded') {
                setPayment({ status: 'success' })
                onGetUser(env.token);
            }
            else {
                setPayment({ status: 'error', message: i18next.t('UseBuyEventComponentErrorProcessingEventPayment')})
            }
        }
        catch (err) {
        }
    }

    const cardPurchaseLoggedIn = async (purchaseParam) => {
        const { stripe_token } = purchaseParam ? purchaseParam : {}
        const purchaseObject = parseFloat(finalPrice) > 0 ? { event_uuid, stripe_token: (stripe_token && stripe_token.id)? stripe_token.id:"", paypal: usesPaypal, 
            stripe_payment_method_id : (savedCard && savedCard.status===true)? savedCard.stripe_payment_method_id: "", type:ticket?"interactive": "streaming", remember: true } : { event_uuid, type:ticket?"interactive": "streaming" }
        console.log ({purchaseObject})
        try {
            const env = JSON.parse(localStorage.getItem("env") || "{}");
            const result = await eventDAO.purchaseEvent(env.token, purchaseObject);
            const paymentIntent = result.data.data.payment_intent
            setPaymentIntent(paymentIntent)
            if (paymentIntent.next_action && paymentIntent.next_action.redirect_to_url) {
                setRedirect({ status: true, ...paymentIntent.next_action.redirect_to_url })
            }
            else if (paymentIntent.status === 'succeeded' || paymentIntent === 'succeeded') {
                setPayment({ status: 'success' })
                if (isUserLoggedIn) {
                    onGetUser(env.token);

                }
            }
        }
        catch (err) {
            const message = err.response.data.error.map(({ message }) => message).join(",")
            setPayment({ status: "error", message: message })
        }
    };

    const cardPurchaseNodLoggedIn = async (purchaseParam) => {
        const { stripe_token } = purchaseParam ? purchaseParam : {}
        try {
            const purchaseObject = parseFloat(finalPrice) > 0 ? { event_uuid, email, stripe_token: stripe_token.id, paypal: usesPaypal , type:ticket?"interactive": "streaming"} : { event_uuid, email , type:ticket?"interactive": "streaming"}
            const result = await eventDAO.purchaseEventNoLogin(ACCESS_TOKEN_API, purchaseObject);
            const paymentIntent = result.data.data.payment_intent
            setPaymentIntent(paymentIntent)
            if (paymentIntent.next_action && paymentIntent.next_action.redirect_to_url) {
                setRedirect({ status: true, ...paymentIntent.next_action.redirect_to_url })
            }
            else if (paymentIntent.status === 'succeeded' || paymentIntent === 'succeeded') {
                setPayment({ status: 'success' })
            }
        }
        catch (err) {
            const message = err.response.data.error.map(({ message }) => message).join(",")
            setPayment({ status: "error", message: message })
        }
    }
    /* Calls to backend to get the url of paypal in logged in and new users */
    const paypalPurchase = async () => {
        let purchaseObject
        let paymentIntent 
        try {
            if (isUserLoggedIn) {
                purchaseObject = parseFloat(finalPrice) > 0 ? 
                            {type:ticket?"interactive": "streaming", event_uuid, paypal: usesPaypal,cancel_url:`${HOME_PAGE_PAYPAL_REDIRECT}${match.url}?paypal_callback_error` ,
                            return_url:`${HOME_PAGE_PAYPAL_REDIRECT}${match.url}?paypal_callback`} : { event_uuid, type:ticket?"interactive": "streaming" }

                const env = JSON.parse(localStorage.getItem("env") || "{}");
                let result = await eventDAO.purchaseEvent(env.token, purchaseObject);
                paymentIntent = result.data.data.payment_order
                setPaymentIntent(paymentIntent)
                paypalRedirect(paymentIntent)
            }
            else {
                purchaseObject = parseFloat(finalPrice) > 0 ? 
                            {type:ticket?"interactive": "streaming", event_uuid, email, paypal: usesPaypal,cancel_url:`${HOME_PAGE_PAYPAL_REDIRECT}${match.url}?paypal_callback_error` ,
                              return_url:`${HOME_PAGE_PAYPAL_REDIRECT}${match.url}?paypal_callback`} : { event_uuid, email , type:ticket?"interactive": "streaming"}
                let result = await eventDAO.purchaseEventNoLogin(ACCESS_TOKEN_API, purchaseObject);
                paymentIntent = result.data.data.payment_order
                setPaymentIntent(paymentIntent)
                paypalRedirect(paymentIntent)
            }
            
        }
        catch (err) {
            const message = err.response.data.error.map(({ message }) => message).join(",")
            setPayment({ status: "error", message: message })
        }

    }
  
    const paypalRedirect = async ( paymentIntent)=>{
        if (paymentIntent.status ==='CREATED'){
            let storageItem= {token:paymentIntent.id,
            ...(email && {email})
            }
            await localStorage.setItem('paypalIntent',JSON.stringify(storageItem))
            // await localStorage.setItem('paypalIntent',paymentIntent.id)
            //TODO: store event in event listeener
            // window.addEventListener("storage", paypalCallbackListener)
            // setRedirect({ status: true, url:paymentIntent.links[0].href })
            window.location.href= paymentIntent.links[0].href
        
        }

    }

    const handlePurchase = async (e) => {
        e.preventDefault()
        let result
        let card
        if ((!privacy || !terms) && !isUserLoggedIn) {
            alert( i18next.t('UseBuyEventComponentAcceptTemsError'))
        }
        else if ( !isUserLoggedIn && (!email || email.length===0)) {
                alert( i18next.t('UseBuyEventComponentPleaseEnterYourEmailAddress'))
        }
        else if (!isUserLoggedIn && (email!==confirmEmail)){
            alert( i18next.t('UseBuyEventComponentEmailsShouldMatch'))
        }
        else {
            setPayment({ status: 'loading' })
            if (finalPrice === 0) {
                if (!isUserLoggedIn) {
                    cardPurchaseNodLoggedIn()
                }
                else {
                    cardPurchaseLoggedIn()
                }
            }
            else {
                if (!usesPaypal) {
                    if (!stripe || !elements) {
                        console.log ("RETURN")
                        return;
                    }
                    else {
                        if (savedCard.status !==true){
                            console.log ("SAVEDCARD IS FALSE MATE")
                            card = elements.getElement(CardElement);
                            result = await stripe.createToken(card);
                            console.log ({result})
                            if (result.error) {
                                alert(result.error.message);
                                setPayment({})
                            }
                        }
                        if (!isUserLoggedIn) {
                            cardPurchaseNodLoggedIn({ stripe_token: result ?result.token :""})
                        }
                        else {
                            console.log ({result})
                            cardPurchaseLoggedIn({ 
                                
                                stripe_token:  result? result.token: "" })
                        }
                    }
                }
                else {
                    paypalPurchase()
                }

            }
        }
    }
    return { payment, handlePurchase }
}
export default useBuyEvent