export const parsePriceByCurrencyJustSymbol = (price, currency) => {
    try {
        return currency.currency_format.replace("%s", price).replace("%n", currency.currency_name).replace("%c", "").replace("(", "").replace(")","");
    }
    catch (error) {
        console.error("Error parsePriceByCurrencyJustSymbol:", error);
        return price;
    }
}
export const parseCurrencyCode = (currency) => {
    try {
        return currency.currency_code.toUpperCase();
    }
    catch (error) {
        console.error("Error parseCurrencyCode:", error);
        return "";
    }
}
// Returns price with the correct format
export const parsePriceByCurrency = (price, currency) => {
    console.log ({currency})
    try {
        return currency.currency_format.replace("%s", price).replace("%n", currency.currency_name).replace("%c", currency.currency_code.toUpperCase());
    }
    catch (error) {
        console.error("Error parsePriceByCurrency:", error);
        return price;
    }
}
export const findCurrencyByCurrencyCode = (currency_code, currencies) => {
    try{
        return currencies.find(currencyItem => currencyItem.currency_code == currency_code);
    }
    catch (error){
        return null;
    }
}
export const parsePriceByCurrencyCode = (price, currency_code, currencies) => {
    try {
        var currencyData = findCurrencyByCurrencyCode(currency_code, currencies);
        return parsePriceByCurrency(price, currencyData);
    }
    catch (error) {
        console.error("Error parsePriceByCurrencyCode:", error);
        return price;
    }
}