import axios from "axios";
import { Api, ACCESS_TOKEN_API } from "./../environment/environment";
import { version } from "../../package.json";
import { parseLocale } from '../utils/Localization'



var DetectRTC = require('detectrtc');

let headers = { 
  "Access-Token": ACCESS_TOKEN_API,
  "Content-Language": parseLocale() 
};

const refreshHeaders = (accessToken) => {
  DetectRTC.load();
  headers = {};

  headers["Access-Token"] = accessToken;
  headers["Platform"] = "Web/" + DetectRTC.browser.name + "/" + DetectRTC.browser.fullVersion + "/OS:" + DetectRTC.osName + DetectRTC.osVersion;
  headers["Platform-Display"] = DetectRTC.displayResolution;
  headers["Video-Permission"] = DetectRTC.isWebsiteHasWebcamPermissions;
  headers["Microphone-Permission"] = DetectRTC.isWebsiteHasMicrophonePermissions;
  headers["Microphone"] = DetectRTC.hasMicrophone;
  headers["Camera"] = DetectRTC.hasWebcam;
  headers["isWebSocketsBlocked"] = DetectRTC.isWebSocketsBlocked;
  headers["isWebSocketsSupported"] = DetectRTC.isWebSocketsSupported;
  headers["Content-Language"] = parseLocale();
  headers["Build"] = version;

}

export default class CurrencyDAO {
  findAll() {
    return axios.get(`${Api}currency/all`, { headers });
  }
}


