import React, { Component } from 'react';
import user from "../../../assets/img/image (16).png";
import Delete from "../../../../src/assets/img/icons/ic-delete.svg";
import Time from "../../../../src/assets/img/icons/ic-time.svg";
import User from "../../../../src/assets/img/icons/ic-streaming-timeout-on.svg"
import './style.scss';
class FilteredListStreamingModerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [
        {
          "name": "Stella de la Cruz",
          "id": 1,
          "message": "You still have to do kakuna, isn't necessary to wash knife after this? ",
          "url": user,
          "delete": Delete,
          "time": Time,
          "user": User,
        },
        {
            "name": "Harriet Moreno",
            "id": 2,
            "message": "Amazing experience",
            "url": user,
            "delete": Delete,
            "time": Time,
            "user": User,
          },
        {
            "name": "Clark Cobb",
            "id": 3,
            "message": "You still have to do kakuna, isn't necessary to wash knife after this? ",
            "url": user,
            "delete": Delete,
            "time": Time,
            "user": User,
          },
        {
            "name": "Jane McGreen",
            "id": 4,
            "message": "I like this guy",
            "url": user,
            "delete": Delete,
            "time": Time,
            "user": User,
          },
          {
            "name": "Barry Allen",
            "id": 5,
            "message": "Hello dude, nice play",
            "url": user,
            "delete": Delete,
            "time": Time,
            "user": User,
          },
        
      ]
    };
  }
  render() {
    const { tasks } = this.state;
    return (
      <div className="chat-streaming">
        <ul className="list_chat list-unstyled">
        {tasks.map(task => <li className="list_chat_details_streaming"><div className=""><img className="img_user_chat2" src={task.url}/><div><h3 className="m-0" id="title_name2">{task.name}</h3> <p className="last_message2 m-0">{task.message}</p><img className="banned" src={task.time}></img><img className="banned" src={task.delete}></img><img className="banned" src={task.user}></img></div></div></li>)}
      </ul>
      </div>
    );
  }
}
export default FilteredListStreamingModerator;