import React from "react";
import Navbar from "../comunes/navbar_influencer";
import Video from "../comunes/videos_seccion";
import "./style.scss";
import { Link } from "react-router-dom";
import map from "../../assets/img/icons/ic-map.svg";
import RatingPoints from "../comunes_events/rating_points";
import SocialButtons from "../comunes/social/SocialButtons/SocialButtons";
import Loader from "components/comunes/loader";
import { useProfileInfluencerLogic } from "./ProfileInfluencerLogic";
import UpcomingLiveEvents from "components/comunes/upcomingLiveEvents";
import { eventState } from "./../../constants";
import Linkify from 'react-linkify';
import {RESTRICTED_VIEWS} from "../../environment/environment"
import i18next from 'i18next';

import {SlideCards}  from '../comunes/upcomingLiveEvents/SlideCards'
import useMobileNav from '../../hooks/useMobileNav'
const ProfileInfluencer = () => {
  const { data, loading } = useProfileInfluencerLogic();
  const {mobileNav} = useMobileNav()
  if (loading) return <Loader />;

  const {
    categories, languages, user, influencer: { influencer_aboutme, influencer_avatar },
    user: { user_name, user_lastname, user_username, user_location,
      user_linkedin_data, user_twitter_data, user_instagram_data }
  } = data;
  const upcomingEvents = data.events
    .filter(event => event.event_state === eventState.upcoming)
    .map(event => {
      const item = { event, user };
      return item;
    });
  const pastEvents = data.events
    .filter(event => event.event_state === eventState.past)
    .map(event => {
      const item = { event, user };
      return item;
    });

  return (
    <div>
      <header>
        <Navbar />
      </header>
      <div className="container profile_div">
        <div className="row">
      
          <div className="d-md-flex px-1 px-md-0">
            <div>
            {mobileNav ? 
                    <img style={{objectFit:"cover", maxHeight:"15rem", width:"100%"}} className="mb-3" src={influencer_avatar} alt="" />
                    : 
              <img className="img_profile_influencer" src={influencer_avatar} />
            }
            </div>
            <div className="about_user">
              <h2 className="mb-1 name_profile">
                {user_name} {user_lastname}
              </h2>
              <p className="username_profile mb-1">@{user_username}</p>
              <div className="d-flex mt-2">
                <Link to="/friends">
                  <p>
                    <span className="friends">0</span>{" "}
                    <span className="grey">{i18next.t('InfluencerProfileFollowers')}</span>
                  </p>
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <Link to="/followers">
                  <p>
                    <span className="followers">0</span>{" "}
                    <span className="grey">{i18next.t('InfluencerProfileFollowing')}</span>
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div className="datos_influencer_profile">
            <RatingPoints />
            <div className="mb-3">
              <h4>{i18next.t('InfluencerProfileSkills')}</h4>

              {categories.map(({ cate_ide, cate_description }) => (
                <p style={{ fontSize: "16px" }} className="username_profile mb-1" key={cate_ide}>
                  {cate_description}
                </p>
              ))}
            </div>
            <div className="mb-3">
              <h4>{i18next.t('InfluencerProfileLanguages')}</h4>
              <div className="d-flex align-items-center p-md-1 Cg ">
                {languages.map(({ lang_ide, lang_description, lang_image }) => (
                  <span style={{ marginRight: '16px' }} key={lang_ide}>
                    <img  style={{height:'25px'}} className="lenguage" src={lang_image} alt="" />
                  &nbsp; {lang_description}
                  </span>
                ))}
              </div>

            </div>
            <div className="mb-3">
              <h4 className="mt-3">{i18next.t('InfluencerProfileFollowMe')}</h4>
              <div className="social-icons">
                <SocialButtons 
                linkedinLink={user_linkedin_data ? JSON.parse(user_linkedin_data).vanityName: undefined}
                twitterLink={user_twitter_data? JSON.parse(user_twitter_data).screen_name : undefined}
                />
              </div>

            </div>

          </div>
        </div>
        <div className="ml-md-5 aboutme_div_influencer">
          <h5 style={{fontSize:'1.5rem'}}>{i18next.t('InfluencerProfileAboutMe')}</h5>
          <Linkify properties={{ target: '_blank' }}><p className="aboutme_influencer">{influencer_aboutme}</p></Linkify>

          <p className="grey">
            <img src={map} />
            {user_location && JSON.parse(user_location).city}
          </p>
        </div>
        {(upcomingEvents.length > 0 && !mobileNav) ? 
        (
          <div className="ml-2 mt-4">
            <UpcomingLiveEvents dataInjected={upcomingEvents} />
          </div>
        ): 

      (upcomingEvents.length > 0 && mobileNav) && 

      <>
      <h2 style={{fontSize:'22px', marginBottom:'16px'}}>{i18next.t('InfluencerProfileUpcoming')}</h2>
      <SlideCards
      loading={loading}
      noMargin
        events={upcomingEvents}/>
        </>
      }

        {pastEvents.length > 0 &&  <Video pastVideos={pastEvents} noMargin />}
      </div>
    </div>
  );
};

export default ProfileInfluencer;
