import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

const SearchInputMessage = ({ value, onChange }) => (
  <div className="Search-div-messages mt-3 mb-3 pr-5">
    <i className="fas fa-search"></i>
    <input
      type="text"
      className="form-control"
      placeholder="Search messages"
      value={value}
      onChange={onChange}
    />
  </div>
);

SearchInputMessage.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SearchInputMessage;
