import React, { useState } from "react";
import logo from "assets/img/gotmy-n.png";
import "./style.scss";
import Delete from "assets/img/icons/ic-delete.svg";
import Sad from "assets/img/icons/sad-face-in-rounded-square.svg";
import { useModalLoginLogic } from "./ModalLoginLogic";
import SweetAlert from "sweetalert2-react";
import { withRouter } from "react-router-dom";
import { LinkedIn } from "react-linkedin-login-oauth2";
import {
  LINKEDIN_CLIENTID,
  INFLUENCER_ONLY_MODE,
  googlePlayGotmyURL,
  appleStoreGotmyURL,
  PRIVATE_MARKETPLACE,
} from "../../../../../environment/environment";
import AppleLogin from "../../../appleLogin/AppleLogin";
import ModalDownloadApp from "../../../../public_profile_influencer/ModalDownloadApp";
import styled from "styled-components";
import useMobileNav from "../../../../../hooks/useMobileNav";
import styles from "../../../../../assets/css_general/general.scss";
import i18next from "i18next";

/**
 * @param {Object} history, React Router Object
 */
const ModalLogin = ({ history, referral, onRecoverClick, onCloseClick }) => {
  const {
    loading,
    onSubmit,
    errorForm,
    formValues,
    twitterLogin,
    setErrorForm,
    onChangeFormValues,
    linkedinLogin,
    facebookLogin,
  } = useModalLoginLogic(history);
  const { mobileNav } = useMobileNav();
  const { email, password } = formValues;
  const [loginAttempt, setLoginAttempt] = useState(false);
  return (
    <div className="modal Modales-login Modal-login" id="myModal_login">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
           {!PRIVATE_MARKETPLACE && ( <div
              data-dismiss="modal"
              style={{
                width: 25,
                height: 25,
                cursor: "pointer",
                position: "absolute",
                top: 20,
                right: 20,
                zIndex: 1,
              }}
              onClick={onCloseClick}
            >
              <svg
                className="svg-icon "
                viewBox="0 0 20 20"
                style={{
                  width: 25,
                  height: 25,
                }}
              >
                <path
                  fill={styles.mainColor}
                  d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
                ></path>
              </svg>
            </div>
            )}<h6>{i18next.t("ModalLoginComponentWelcomeTo")}</h6>
            <img src={logo} className="Logo" alt="" />
            <form noValidate onSubmit={onSubmit}>
              <div className="form-group">
                <label htmlFor="email">
                  {i18next.t("ModalLoginComponentEmailAddress")}
                </label>
                <input
                  required
                  type="email"
                  className="form-control modal_register"
                  name="email"
                  placeholder={i18next.t("ModalLoginComponentEmailPlaceholder")}
                  value={email}
                  onChange={onChangeFormValues}
                />
              </div>

              <div className="form-group">
                <label htmlFor="passwrod">
                  {i18next.t("ModalLoginComponentPassword")}
                </label>
                <input
                  required
                  type="password"
                  className="form-control modal_register"
                  name="password"
                  placeholder={i18next.t(
                    "ModalLoginComponentPasswordPlaceholder"
                  )}
                  value={password}
                  onChange={onChangeFormValues}
                />
              </div>

              <button type="submit" className="Btn-rojo" disabled={loading}>
                {!loading
                  ? i18next.t("ModalLoginComponentCotinue")
                  : i18next.t("ModalLoginComponentLoading") + "..."}
              </button>
            </form>

            <a
              data-toggle="modal"
              data-target="#myModal_recover"
              data-dismiss="modal"
              className="Login Mr-32"
              onClick={onRecoverClick}
            >
              {i18next.t("ModalLoginComponentRecoverPassword")}
            </a>
            {!PRIVATE_MARKETPLACE && (
              <>
                <div className="Or-continue">
                  {i18next.t("ModalLoginComponentOrSignInWith")}
                </div>
                <div
                  className="Botones-fl"
                  style={{
                    width: "440px",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    style={{
                      width: mobileNav && "300px",
                      marginBottom: "8px",
                      padding: "6px",
                      border: "none",
                      color: "white",
                      borderRadius: "16px",
                    }}
                    className="Btn-face"
                    onClick={facebookLogin}
                  >
                    <i className="fab fa-facebook"></i> Facebook
                  </button>
                  <button
                    style={{
                      width: mobileNav && "300px",
                      marginBottom: "8px",
                      padding: "6px",
                      border: "none",
                      color: "white",
                      borderRadius: "16px",
                    }}
                    className="Btn-linkedin"
                    onClick={linkedinLogin}
                  >
                    <i className="fab fa-linkedin"></i> Linkedin
                  </button>
                  <button
                    style={{
                      width: mobileNav && "300px",
                      marginBottom: "8px",
                      padding: "6px",
                      border: "none",
                      color: "white",
                      borderRadius: "16px",
                    }}
                    className=" Btn-twitter"
                    onClick={twitterLogin}
                  >
                    <i className="fab fa-twitter"></i> Twitter
                  </button>
                  <span style={{ cursor: "pointer", marginBottom: "8px" }}>
                    <AppleLogin width={mobileNav ? 300 : 140} />
                  </span>
                </div>
                <p>{i18next.t("ModalLoginComponentAreYouNotRegistered")}</p>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#myModal_sign"
                  data-dismiss="modal"
                  className="Login"
                >
                  {" "}
                  {i18next.t("ModalLoginComponentSignUp")}
                </a>

                {loginAttempt && (
                  <div style={{ padding: "14px", fontSize: "17px" }}>
                    <span>
                      {i18next.t("ModalLoginComponentDownloadOurAppOnThe")}{" "}
                      <DownloadLink href={appleStoreGotmyURL} target="_blank">
                        {i18next.t("ModalLoginComponentAppStore")}
                      </DownloadLink>{" "}
                      {i18next.t("ModalLoginComponentOr")}or{" "}
                      <DownloadLink href={googlePlayGotmyURL} target="_blank">
                        Google Play
                      </DownloadLink>
                      {i18next.t(
                        "ModalLoginComponentToRegisterAndEnjoyOurLiveStreams"
                      )}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <SweetAlert
        imageUrl={Sad}
        show={errorForm}
        title={errorForm}
        onConfirm={() => setErrorForm("")}
      />
    </div>
  );
};

export default withRouter(ModalLogin);
const DownloadLink = styled.a`
  color: var(--color-primary-color);
  /* display: block; */
  cursor: pointer;
  font-weight: 600;
`;
