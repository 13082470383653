import { useState } from "react";
import { useDispatch} from 'react-redux'
import { UserDAO } from "../../../../../daos";
import * as actions from "../../../../../store/actions";

// import $ from "jquery";
import * as yup from "yup";

const initialFormValues = {
  phone: "",
  code: ""
};

const schema = yup.object().shape({
  phone: yup.string().required("Phone number is required"),
  code: yup.string().required("Verification code is required")
});

const userDAO = new UserDAO();

/**
 * Logic of ModalRegister=
 * @returns {Object}
 */
export const useModalVerifyPhoneLogic = history => {
  const dispatch = useDispatch()
  const [phoneValue, setPhoneValue] = useState()
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errorForm, setErrorForm] = useState("");
  const [phase, setPhase] = useState("PHONE");
  const [showAlertGoodCredentials, setShowAlertGoodCredentials] = useState(
    false
  );
  const [loading, setLoading] = useState(false);

  /**
   * Handle form changes.
   * @param {Object} event, Event trigerred for the input form
   */
  const onChangeFormValues = event => {
    const updatedValues = JSON.parse(JSON.stringify(formValues));
    const { name, value } = event.target;
    updatedValues[name] = value;
    setFormValues(updatedValues);
  };

  const sendCode = async () => {
    const myToken = JSON.parse(localStorage.getItem("env"));

    formValues.phone = phoneValue;
    const phone = formValues.phone;
    try {
      await userDAO.influencerVerificationCode({ phone }, myToken.token);
      setPhase("CODE");
    } catch (err) {
      
      if (err && err.response && err.response.status === 400) {
        setErrorForm(
          err.response.data.error.map(item => item.message).join(", ")
        );
      } else {
        setErrorForm("Sorry, something went wrong.");
      }
    }
  };

  const onSubmit = async event => {
    const myToken = JSON.parse(localStorage.getItem("env"));

    if (loading) return;
    event.preventDefault();
    try {
      await schema.validate(formValues);
    } catch (err) {
      setErrorForm(err.errors[0]);
      return;
    }
    setLoading(true);
    try {
      await userDAO.influencerVerificationCode(formValues, myToken.token);
      await dispatch(actions.getUser(myToken.token));
    } catch (err) {
      if (err.response.status === 400) {
        setErrorForm(
          err.response.data.error.map(item => item.message).join(", ")
        );
      } else {
        setErrorForm("Sorry, something went wrong.");
      }
    } finally {
      window.$("#myModal_verify_phone").modal("hide");
      setLoading(false);
    }
  };

  return {
    formValues,
    onChangeFormValues,
    onSubmit,
    errorForm,
    setErrorForm,
    showAlertGoodCredentials,
    setShowAlertGoodCredentials,
    loading,
    sendCode,
    phase,
    phoneValue,
    setPhoneValue
  };
};
