import React from 'react'
import i18next from 'i18next';
import { countCharacters, avoidLetters, verifyRegex } from "../../../../utils";
import { NEW_PUBLISH_PAGE } from "../../../../environment/environment"

 const SetEventTitle = ({onChangeEventValues, event_title}) => {
    return (
        <div className=" mt-3">
        
        
        <p className="mb-1 small_grey">
          {i18next.t('LiveEventInformationEventTitleCharLeftPart1')}
          {countCharacters(event_title.length, 120)}{" "}
          {i18next.t('LiveEventInformationEventTitleCharLeftPart2')}
        </p>
        
        <input className="input_title_video" type="text" name="event_title" maxLength="120"
          onChange={onChangeEventValues} value={event_title} placeholder={i18next.t('LiveEventInformationPlaceholderEventTitle')}
        />
      </div>
    )
}
export default SetEventTitle