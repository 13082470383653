import React, { Component } from 'react';

import Sad from "assets/img/tick.png";
import NavBar_general from "../comunes/navbar";
import { isMobile } from "react-device-detect";
import './style.scss';
import i18next from 'i18next';

const wp = window.innerWidth;
const hp = window.innerHeight;

const iconSize = wp * 0.175;

class ErrorNotFound extends Component {
    render() {
        return (
            <section className="Not-Found-Section">
                {
                    !isMobile ? (
                        <header className="App-header">
                            <NavBar_general />
                        </header>
                    ) :
                        null
                }
                <div className="container" style={{ marginTop: hp * 0.15 }}>
                    <div className="row">
                        <div className="col-12 mb-5 text-center">
                            <img style={{ width: iconSize, height: iconSize }} src={Sad} alt="" />
                            <h2>{i18next.t("SuscriptionSuccessComponentTitle")}</h2>
                            <h3> {i18next.t("SuscriptionSuccessComponentDescription")}
                            </h3>
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default ErrorNotFound;