import React from "react";
import "./style.scss";
import map from "assets/img/icons/ic-map.svg";
import usr from "assets/img/user.png";
import stars from "assets/img/5-estrellas.png";
import check from "assets/img/icons/ic-check.svg";
import PropTypes from "prop-types";

const UserModerator = ({ data = [] }) => {
  return (
    <div className="user_moderator container-fluid">
      <div className="d-flex">
        <div className="d-flex flex-row">
          {data.map(({ user_name, user_lastname, user_avatar }) => (
            <div className="d-flex flex-column align-items-center ml-4 card_banned">
              <img className="Foto_ban" src={user_avatar} alt="" />
              <span className="Nombre">
                {user_name} {user_lastname}
              </span>
              <button className="button_view_moderator">
                <img src={check} alt=''/>
                Moderator
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

UserModerator.propTypes = {
  data: PropTypes.array.isRequired
};

export default UserModerator;
