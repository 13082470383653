import React, { useState } from "react";
import Camera from "../../comunes/camera";
import { countCharacters } from "../../../utils/";
import styled from 'styled-components'

const ThankYou = ({
  onChange,
  thanksMessage,
  handleThanksMessageChange,
  previousBlob
}) => {
  const [message, setMessage] = useState("");

  return (
    <div>
      <ThanksContainer>
        <div>
          <div className="markdown">

          <h2 className="mb-2 ">Thank you video</h2>
          <p className="">
            When viewers buy tickets for this live event, we'll show them this
            thank you video to let them know how much they mean to you and build
            on a meaningful connection with them.
          </p>

          </div>
          <div className="my-4 ">
            <p className="mb-2 small_grey">
              You have {countCharacters(message.length, 220)} characters left
            </p>
            <textarea
              className="textArea textarea_request"
              rows={3}
              onChange={e => setMessage(e.target.value)}
              maxLength="220"
            ></textarea>
          </div>
        </div>
        <Camera onChange={onChange} previousBlob={previousBlob} />
      </ThanksContainer>
    </div>
  );
};

export default ThankYou;
const ThanksContainer = styled.div`
margin-bottom:20px;
padding-top:12rem;
min-width:600px;
max-width:800px;
margin:auto;
text-align:left;
.markdown {
  max-width:700px;
}
p{
  color: #7a7883;
}
h2{
  font-size:26px;
}
`