import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

const DiaMes = ({ day, month }) => (
  <div className="DiaMes">
    <span className="Dia">{day}</span>
    <span className="Mes">{month}</span>
  </div>
);

DiaMes.propTypes = {
  day: PropTypes.number.isRequired,
  month: PropTypes.string.isRequired
};

export default DiaMes;
