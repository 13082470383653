import React from "react";
import Attendees from "../comunes_events/attendees_admin";
import Navbar from "../comunes/navbar_request";
import "./style.scss";
import cinco_estrellas from "../../assets/img/5-estrellas.png";
import fun from "../../assets/img/icons/ic-fun.svg";
import user from "../../assets/img/icons/ic-business.svg";
import user2 from "../../assets/img/icons/ic-raisehand.svg";
import user3 from "../../assets/img/icons/ic-price.svg";

import {
  useRequestPrivateLogic,
  REASONS,
  FATHER_REASONS
} from "./RequestPrivateLogic";
import { WithAlert } from "../../hocs/withAlert";
import { avoidLetters } from "../../utils";

const RequestPrivateMeeting = ({ match, showAlert, history }) => {
  const {
    formValues,
    onChangeFormValues,
    onSubmit,
    initialLoading,
    eventData,
    fatherReasonActivated,
    setFatherReasonActivated
  } = useRequestPrivateLogic({ match, showAlert, history });

  if (initialLoading) return null;
  const {
    reason,
    num_attendees,
    price_ticket,
    date,
    start_hour,
    end_hour,
    purpose,
    customReason
  } = formValues;
  const { influencer, user: userData } = eventData;

  return (
    <div class="PrivateMeeting">
      <header className="App-header">
        <Navbar onSend={onSubmit} />
      </header>
      <section className="container">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-4 target_request">
            <Attendees
              name={userData.user_name + " " + userData.user_lastname}
              location={
                userData.user_location &&
                JSON.parse(userData.user_location).city
              }
              avatar={influencer.influencer_avatar}
            />
            <img className="stars_request" src={cinco_estrellas} />
            <h2 className="private_want">
              What kind of private event do you want?
            </h2>
            <div className="d-flex information_detalles">
              <img
                src={fun}
                className="pointer"
                onClick={() => setFatherReasonActivated(FATHER_REASONS.FUN)}
              />
              <div>
                <p className="m-0">Just for fun</p>
                <p className="request_p">Have a good time with you</p>
              </div>
            </div>
            {fatherReasonActivated === FATHER_REASONS.FUN && (
              <>
                <div className="demo">
                  <input type="radio" id="acepto" name="acepto" />
                  <label
                    for="acepto"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.PARTY_APPERANCE
                        }
                      })
                    }
                  >
                    <span></span>Party Appearance
                  </label>
                </div>
                <div className="demo2">
                  <input type="radio" id="acepto2" name="acepto" />
                  <label
                    for="acepto2"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.PLAYING_GAMES
                        }
                      })
                    }
                  >
                    <span></span>Playing Games
                  </label>
                </div>
                <div className="demo3">
                  <input type="radio" id="acepto3" name="acepto" />
                  <label
                    for="acepto3"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.HANGOUT
                        }
                      })
                    }
                  >
                    <span></span>Hangout
                  </label>
                </div>
                <div className="demo4">
                  <input type="radio" id="acepto4" name="acepto" />
                  <label
                    for="acepto4"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.OTHER_REASON
                        }
                      })
                    }
                  >
                    <span></span>Other reason
                  </label>
                </div>
                {reason === REASONS.OTHER_REASON && (
                  <div className="div-reason">
                    <p className="mb-0 small_grey">What reason?</p>
                    <input
                      className="reason"
                      type="text"
                      name="customReason"
                      value={customReason}
                      onChange={onChangeFormValues}
                    ></input>
                  </div>
                )}
              </>
            )}
            <div className="d-flex information_detalles">
              // eslint-disable-next-line jsx-a11y/alt-text
              <img
                src={user}
                className="pointer"
                onClick={() =>
                  setFatherReasonActivated(FATHER_REASONS.BUSINESS)
                }
              />
              <div>
                <p className="m-0">Business</p>
                <p className="price_information">
                  I've a business proposal for you
                </p>
              </div>
            </div>
            {fatherReasonActivated === FATHER_REASONS.BUSINESS && (
              <>
                <div className="demo">
                  <input type="radio" id="acepto1" name="acepto" />
                  <label
                    for="acepto1"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.MEETING_REQUEST
                        }
                      })
                    }
                  >
                    <span></span>Meeting Request
                  </label>
                </div>
                <div className="demo">
                  <input type="radio" id="acepto2" name="acepto" />
                  <label
                    for="acepto2"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.CONSULTANT_REQUEST
                        }
                      })
                    }
                  >
                    <span></span>Consultant Request
                  </label>
                </div>
                <div className="demo">
                  <input type="radio" id="acepto3" name="acepto" />
                  <label
                    for="acepto3"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.BUSINESS_PROPOSAL
                        }
                      })
                    }
                  >
                    <span></span>Business Proposal
                  </label>
                </div>
                <div className="demo">
                  <input type="radio" id="acepto4" name="acepto" />
                  <label
                    for="acepto4"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.PARTNERSHIP
                        }
                      })
                    }
                  >
                    <span></span>Partnership
                  </label>
                </div>
                <div className="demo">
                  <input type="radio" id="acepto5" name="acepto" />
                  <label
                    for="acepto5"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.COACHING_TREANING
                        }
                      })
                    }
                  >
                    <span></span>Coaching/Training
                  </label>
                </div>
                <div className="demo4">
                  <input type="radio" id="acepto6" name="acepto" />
                  <label
                    for="acepto6"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.OTHER_REASON
                        }
                      })
                    }
                  >
                    <span></span>Other reason
                  </label>
                </div>
                {reason === REASONS.OTHER_REASON && (
                  <div className="div-reason">
                    <p className="mb-0 small_grey">What reason?</p>
                    <input
                      className="reason"
                      type="text"
                      name="customReason"
                      value={customReason}
                      onChange={onChangeFormValues}
                    ></input>
                  </div>
                )}
              </>
            )}
            <div className="d-flex information_detalles">
              <img
                src={user2}
                className="pointer"
                onClick={() =>
                  setFatherReasonActivated(FATHER_REASONS.EDUCATION)
                }
              />
              <div>
                <p className="m-0">Education</p>
                <p className="price_information">
                  I would like to learn from you
                </p>
              </div>
            </div>
            {fatherReasonActivated === FATHER_REASONS.EDUCATION && (
              <>
                <div className="demo">
                  <input type="radio" id="acepto1" name="acepto" />
                  <label
                    for="acepto1"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.MASTER_CLASS
                        }
                      })
                    }
                  >
                    <span></span>Masterclass
                  </label>
                </div>
                <div className="demo">
                  <input type="radio" id="acepto2" name="acepto" />
                  <label
                    for="acepto2"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.WORKSHOP
                        }
                      })
                    }
                  >
                    <span></span>Workshop
                  </label>
                </div>
                <div className="demo">
                  <input type="radio" id="acepto3" name="acepto" />
                  <label
                    for="acepto3"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.TUTORIAL
                        }
                      })
                    }
                  >
                    <span></span>Tutorial
                  </label>
                </div>
                <div className="demo">
                  <input type="radio" id="acepto4" name="acepto" />
                  <label
                    for="acepto4"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.TRAINING
                        }
                      })
                    }
                  >
                    <span></span>Workshop
                  </label>
                </div>
                <div className="demo4">
                  <input type="radio" id="acepto5" name="acepto" />
                  <label
                    for="acepto5"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.OTHER_REASON
                        }
                      })
                    }
                  >
                    <span></span>Other reason
                  </label>
                </div>
                {reason === REASONS.OTHER_REASON && (
                  <div className="div-reason">
                    <p className="mb-0 small_grey">What reason?</p>
                    <input
                      className="reason"
                      type="text"
                      name="customReason"
                      value={customReason}
                      onChange={onChangeFormValues}
                    ></input>
                  </div>
                )}
              </>
            )}
            <div className="d-flex information_detalles">
              <img
                src={user3}
                className="pointer"
                onClick={() =>
                  setFatherReasonActivated(FATHER_REASONS.OTHER_REASON)
                }
              />
              <div>
                <p className="m-0">Other reason</p>
                <p className="price_information">I have a special request</p>
              </div>
            </div>
            {fatherReasonActivated === FATHER_REASONS.OTHER_REASON && (
              <>
                <div className="demo">
                  <input type="radio" id="acepto1" name="acepto" />
                  <label
                    for="acepto1"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.SHARING_EXPERIENCIES
                        }
                      })
                    }
                  >
                    <span></span>Sharing skills or expereiencies
                  </label>
                </div>
                <div className="demo4">
                  <input type="radio" id="acepto2" name="acepto" />
                  <label
                    for="acepto2"
                    onClick={() =>
                      onChangeFormValues({
                        target: {
                          name: "reason",
                          value: REASONS.OTHER_REASON
                        }
                      })
                    }
                  >
                    <span></span>Other reason
                  </label>
                </div>
                {reason === REASONS.OTHER_REASON && (
                  <div className="div-reason">
                    <p className="mb-0 small_grey">What reason?</p>
                    <input
                      className="reason"
                      type="text"
                      name="customReason"
                      value={customReason}
                      onChange={onChangeFormValues}
                    ></input>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col-1"></div>
          <div className="col-6 people">
            <h2 className="private_want meeting_people mt-0 mb-1">
              How many people do you want the private meeting for?
            </h2>
            <form className="d-flex">
              <div className="mr-4">
                <p className="mb-1 small_grey">Attendees</p>
                <input
                  className="input_people"
                  type="text"
                  name="num_attendees"
                  value={num_attendees}
                  onChange={onChangeFormValues}
                  onKeyPress={avoidLetters}
                />
              </div>
              <div>
                <p className="mb-1 small_grey">Max. price per ticket</p>
                <input
                  className="input_people"
                  type="text"
                  value={price_ticket}
                  name="price_ticket"
                  onChange={onChangeFormValues}
                />{" "}
              </div>
            </form>
            <h2 className="private_want meeting_people mt-4 mb-2">
              When would you like the meeting
            </h2>
            <form className="">
              <div className="demo3">
                <input type="checkbox" id="acepto5" name="acepto" />
                <label for="acepto5">
                  <span></span>I do not care about the date and time
                </label>
              </div>
              <div className="demo3">
                <input type="checkbox" id="acepto6" name="acepto" />
                <label for="acepto6">
                  <span></span>I'd like a specific date and time
                </label>
              </div>
            </form>
            <form className="d-flex">
              <div className="mr-4">
                <p className="mb-1">Select date</p>
                <input
                  className="input_people_date"
                  type="date"
                  name="date"
                  value={date}
                  onChange={onChangeFormValues}
                />
              </div>
              <div className="mr-3">
                <p className="mb-1">Start time</p>
                <input
                  className="input_people"
                  type="time"
                  name="start_hour"
                  value={start_hour}
                  onChange={onChangeFormValues}
                />
              </div>
              <div>
                <p className="mb-1">End time</p>
                <input
                  className="input_people"
                  type="time"
                  name="end_hour"
                  value={end_hour}
                  onChange={onChangeFormValues}
                />{" "}
              </div>
            </form>
            <h2 className="private_want meeting_people mt-4 mb-2">
              Explain your purpose for meeting
            </h2>
            <form className="d-flex">
              <div className="mr-4">
                <p className="mb-0 small_grey">Limit is 1000 characters</p>
                <p className="small_grey">
                  You can add external links using http://
                </p>
                <textarea
                  className="textarea_request"
                  name="purpose"
                  value={purpose}
                  onChange={onChangeFormValues}
                ></textarea>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WithAlert(RequestPrivateMeeting);
