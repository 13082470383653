import React, { useEffect, useState } from "react";
import Search_input_peq from "components/comunes/search_input_peq";
import EventCard from '../../comunes/Cards/EventCard'
import Calendar from "components/comunes/calendar";
import { connect } from "react-redux";
import { UserDAO } from "daos";
import Loader from "components/comunes/loader";
import moment from "moment";
import NoEvents from "components/comunes/utils/NoEvents"
import i18next from 'i18next';

const userDAO = new UserDAO();

const UpcomingLiveEventsPage = ({ categories }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const now = new Date()

  const initEvents = async () => {
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    const { token } = env;
    if (!token) {
      setLoading(false);
      return;
    }

    try {
      const response = await userDAO.findMyPurchasedEvents(token);
      let data = response.data.data.map(item => {
        item.event.event_category = categories.find(
          category => category.cate_ide === item.event.event_category_ide
        );
        return item;
      });
      let filteredData = data.filter((event) => new Date(parseInt(event.event.event_streaming_end_date)) > now)
      setEvents(filteredData);
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (categories.length > 0) initEvents();
  }, [categories]);
  return (
    <div className="container Upcoming-live-events">
      {!loading ? (
        <div className="row">

          {!events.length &&
            <div className="row col-md-12 mt-3">
              <div className="col-md-4"></div>
              <NoEvents message={i18next.t("UpcomingLiveEventsPageComponentYouHaveNotPurschasedAnyLiveEventYet")} />
              {/* <div className="col-md-1"></div> */}
              <div className="col-md-3">  <Search_input_peq /></div>
            </div>
          }


          <div className="col-md-8 mt-4 mt-md-0 d-flex Fila-live-events">
            <div className="col-12">
              {events && events.length > 0 && <h2 className="d-none d-md-block">{i18next.t("UpcomingLiveEventsPageComponentUpcomingLiveEvents")}</h2>}
            </div>

            {events.length > 0 &&
              events.map((event, index) => {
                return (
                  <>
                    <EventCard
                      extraStyles="col-md-6"
                      showFavorite={false}
                      showCountdown={true}
                      key={index}
                      event={event}
                    />
                  </>
                )
              })}
          </div>

          {events.length > 0 && (
            <>
              <div className="col-md-1"></div>
              <div className="col-md-3 d-none d-md-block">
                <Search_input_peq />
                <br />
                <br />
                <div className= "d-none d-md-block" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Calendar
                    selectedDates={events.map(event =>
                      moment(parseInt(event.event.event_date)).format(
                        "YYYY-MM-DD"
                      )
                    )}
                  />
                </div>

              </div>
            </>
          )}

        </div>
      ) : (
          <Loader />
        )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  };
};

export default connect(mapStateToProps)(UpcomingLiveEventsPage);
