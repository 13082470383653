import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import fun from "assets/img/icons/ic-fun.svg";
import user2 from "assets/img/icons/ic-raisehand.svg";
import user3 from "assets/img/icons/ic-price.svg";
import edit from "assets/img/icons/ic-edit.svg";
import tick from "assets/img/tick.png"
import rejected from "assets/img/rejected.png"
import Attendees from "../../comunes_events/attendees_admin";
import PropTypes from "prop-types";
import statesEventRequest, { stateArray } from "../../../constants/statesEventRequest";
import { newReasons } from '../../../pages/requestPrivate/utils'
import useUserHour from "../../../hooks/useUserHour"
import useIsFutureDate from "../../../hooks/useIsFutureDate"
import useTimezoneDifference from '../../../hooks/useTimezoneDifference'
import useViewerTimezone from '../../../hooks/useViewerTimezone'
import { parsePriceByCurrencyJustSymbol,  findCurrencyByCurrencyCode } from "../../../utils/currencyUtils";
import { useSelector } from "react-redux";

import {  addHourOffset } from "utils";
import i18next from "i18next";

const RequestDetail = ({ isMobile,data, imagesPath, onReject, onAccept, rejecting, acepting, creatingEvent }) => {
  const currencies = useSelector((state)=>state.currencies.currencies)
  const [request, setRequest] = useState([])
  const [user, setUser] = useState([])
  const [influencerEndTime, setInfluencerEndTime] = useState()
  const [startDate, setStartDate] = useState('')
  const [timeZone, setTimeZone] = useState('')
  const { event_uuid, request_reason, request_date,request_currency, request_num_attendees, request_price_ticket, request_type,
    request_updated_date, request_created_date, request_ide, request_state, request_start_hour, request_end_hour } = request? request: '';
  const {userTime, timeStrings} = useUserHour({request_date})
  const {isFuture} = useIsFutureDate({msDate:request_date})
  const requestState = stateArray.filter((state) => state.id === request_state)[0]
  const { user_name, user_lastname, user_location, user_avatar } = user? user : '';
  const viewerTimezone = useViewerTimezone(user)
  const { difference } = useTimezoneDifference({ timezone1: viewerTimezone })
  const requestType = newReasons.filter((reason) => reason.request_type === request_type)
  

  useEffect (( )=> {
    if (typeof difference ==='number' && request_end_hour && request_end_hour.length>0 ){
      let endArray = request_end_hour.split(':')
      endArray[0]= addHourOffset(parseInt(endArray[0]), difference)
      setInfluencerEndTime(endArray.join(':'))
    }
  },[difference, request_end_hour ])
  useEffect(()=>{
    if (data && Object.keys(data).length>0){
      setRequest(data.request)
      setUser(data.user)
      setStartDate(new Date(parseInt(data.request.request_date)))
    }
  },[data])
  return (
    <div style={{ borderLeft: (creatingEvent!==true && (isMobile!==true)) && '1px solid #D8DCE5' , display:'flex', flexDirection:'column', alignItems: 'center', justifyItems: 'center'}} className={` ${creatingEvent!==true && "pl-4 col-md-3 pt-4  target_request"} `}>
      
      <div className="">
        {user_name && 
        
      <Attendees
        name={`${user_name} ${user_lastname}`}
        location={user_location && JSON.parse(user_location).city}
        avatar={user_avatar}
        isInfluencer={false}
      />
        }
      <div className="d-flex mt-3">
        {requestState && (requestState.name.includes("Accepted") || requestState.name.includes("Rejected")  || requestState.name.includes("Pending") || requestState.name.includes("Modified")) &&
          <img style={{ width: '22px', height: '22px' }} src={requestState.name.includes('Pending') ? imagesPath["plus"] :
            requestState.name.includes('Accepted') ? tick :requestState.name.includes('Rejected') ? rejected : edit
          } alt=""></img>
        }

        {requestState && requestState.name.includes('Pending') && <p className="mb-0 ml-2 rojo">{requestState.name}</p>}
        {creatingEvent!==true && requestState && requestState.name.includes('Accepted') && <p className="mb-0 ml-2 ">{i18next.t("RequestDetailAccepted")}</p>}
        {creatingEvent && requestState && requestState.name.includes('Accepted') && <p className="mb-0 ml-2 ">{i18next.t("RequestConfirmed")}</p>}
        {requestState && requestState.name.includes('Rejected') && <p className="mb-0 ml-2 ">{i18next.t("RequestDetailRejected")}</p>}
        {requestState && requestState.name.includes('Cancelled') && <p className="mb-0 ml-2 ">{i18next.t("RequestDetailCancelledByViewer")}</p>}
        { requestState && requestState.name.includes('Modified') && !requestState.name.includes('Payment') && !requestState.name.includes('Accepted') &&
          <p className="mb-0 ml-2 ">{requestState && requestState.name}</p>}
      </div>
      <div className="d-flex align-items-start"></div>

      {creatingEvent!==true && user_name &&  <h2 style={{fontSize:'18px'}} className=" mb-4 mt-4 details_request">{i18next.t("RequestDetailDetails")}</h2>}
      {creatingEvent && user_name &&<h4 style={{fontSize:'18px'}} className=" mb-4 mt-4 details_request">{i18next.t("RequestDetailDetails")}</h4>}
      {user_name===undefined &&<h4 style={{fontSize:'18px'}} className=" mb-4 mt-4 details_request">{i18next.t("RequestDetailDetailsDescription")}</h4>}
     
      {user_name!==undefined && 
      <div className="d-flex information_detalles">
        <img className='mr-3' src={fun} alt="" />
        <div>
          <p className="m-0">{requestType && requestType.length > 0 && requestType[0].optionName}</p>
          <p className="price_information ">{requestType && requestType.length > 0 && requestType[0].secondaryName}</p>
        </div>
      </div>
}
{user_name!==undefined && 

      <div className="d-flex information_detalles">
        <img className='mr-3' src={user2} alt="" />
        <div>
          <p className="m-0">Attendees</p>
          <p className="price_information">{request_num_attendees} Attendees</p>
        </div>
      </div>
}
{user_name!==undefined && 

      <div className="d-flex information_detalles">
        <img className='mr-3' src={user3} alt="" />
        <div>
          <p className="m-0">Price per Ticket</p>
          {/* <p className="price_information">$ {request_price_ticket}</p> */}
          <p className="price_information"> {parsePriceByCurrencyJustSymbol(request_price_ticket, findCurrencyByCurrencyCode(request_currency, currencies))}</p>

        </div>
      </div>
}
{user_name!==undefined && 

        <div className="d-flex information_detalles">
          <img className='mr-3' src={user2} alt="" />
          <div>
            <p className="m-0">Date and time</p>
            {(request_start_hour && request) && (request.request_date &&  startDate) ? 
            <>
            <p className=" mb-1 price_information">
              {`${(request.request_date!==null)? timeStrings.dateStr : "Any date"} from ${userTime} to ${influencerEndTime}`}
            </p>
            </>:
            request_start_hour && (request && request.request_date ===null) ?
            <p className=" mb-1 price_information">
              {`Any date, from ${userTime} to ${influencerEndTime}`}
            </p>
            : (request && request.request_date &&  startDate)?
            <p className=" mb-1 price_information">
              {`${startDate.toLocaleString().split(' ')[0]} at any time` } 
            </p>
            
            :
            <p className=" mb-1 price_information">
              {`Undefined date`}
            </p>
            }
          </div>
        </div>
}
      {
        request_state === statesEventRequest.pending && (
          <div className="mt-3">
           {
          
           <button
              className="request-streaming send_request"
              style={{backgroundColor:`${((isFuture && request_start_hour)) ? '#ff5a60' : '#ff8484'}`}}
              onClick={() => onAccept(request_ide)}
              disabled={acepting || rejecting || !(isFuture && request_start_hour)}
            >
              {!acepting ? "Accept proposal" : "Acepting..."}
            </button>}

            
            <button
              className="request-streaming cancel"
              onClick={() => onReject(request_ide)}
              disabled={acepting || rejecting}
            >
              {!rejecting ? "Reject" : "Loading..."}
            </button>
            {request_state === statesEventRequest.pending && (
              <>
                <p style={{ fontWeight: 500 }}>
                  {i18next.t("RequestDetailYouCanEdit")}
                <br />
                {i18next.t("RequestDetailYouCanEdit2")}
              </p>
                <Link to={`/request_edit/${request_ide}`}>
                  <p className="rojo">{i18next.t("RequestDetailYouCanEditRequest")}</p>
                </Link>
              </>
            )}
          
          </div>
        )}
          {requestState && requestState.name.includes('Accepted') && (creatingEvent!==true) && (request.event_uuid===null) &&
              <div className="text-center mt-4">
                <Link to={`/private_publish/${request_ide}`}>
                  <button className="request-streaming send_request">
                    Create Private  Event
              </button>
                </Link>
              </div>
            }
          {request.event_uuid && (creatingEvent!==true)&&
              <div className="text-center mt-4">
                <Link to={`/event/${request.event_uuid}`}>
                <button style={{backgroundColor: '#ff5a60', padding:'8px 25px', marginBottom: '1rem', border:'none', color:'white', borderRadius:'20px'}} className=" send_request">
                    Check the event
              </button>
                </Link>
              </div>
            }


      </div>
    </div>
  );
};

RequestDetail.propTypes = {
  data: PropTypes.array.isRequired,
  imagesPath: PropTypes.array.isRequired,
  onReject: PropTypes.func.isRequired,
  rejecting: PropTypes.bool,
  acepting: PropTypes.bool
};

export { RequestDetail };
