import React, { useState, useEffect , useRef} from "react";
import "./style.scss";
import PropTypes from "prop-types";
import moment from "moment";
import modesUser from "../../../constants/modesUser";
import { reasons } from "../../../pages/requestPrivate/utils"
import styled from 'styled-components'
import circulo from "assets/img/logo-circulo.png";
import useMobilenav from "../../../hooks/useMobileNav"

/**
 * @param {Object} props
 * @param {Array} props.data, Chat data
 * @param {Array} props.onPressItem, Triggered when item is pressed
 * @param {String} props.modeUser, Current mode showing in the app (viewer/influencer).
 * You should use constant modesUser, located in the constatns folder
 */
const FilteredList = ({ data, onPressItem, modeUser, isViewer , setShowMobileDetails}) => {
  const [selectedChat, setSelectedChat] = useState(data[0])
  const [sortedData, setSortedData] = useState([])
  let initialised = useRef(false) 
  const {mobileNav} = useMobilenav()

  useEffect(()=> {
    if (data ){ 
      data.sort((a,b) => (a.request.request_updated_date < b.request.request_updated_date) ? 1 : ((b.request.request_updated_date < a.request.request_updated_date) ? -1 : 0)); 
    }
    setSortedData(data)
    if (initialised.current===false){
      initialised.current=true
      setSelectedChat(data[0])
      onPressItem(data[0])

    }
  },[data])

  var now = moment()
  const handleChatClicked = (item)=> {
    
    setSelectedChat(item)      
    onPressItem(item)
  }
  return (
    <div
      id={"chatDivContainer"}
      className=" list-unstyled"
      style={{ height: "700px", maxHeight: "700px", overflow: "auto" }}
    >
      {sortedData  && sortedData.map(item => {
        const { request, user: viewer_user, influencer_user } = item;
        const user = modeUser === modesUser.influencer ? viewer_user : influencer_user;
        const requestType = request && reasons.filter((reason) => reason.request_type === request.request_type)
        const lastInteraction= moment(parseInt(request.request_updated_date ||
          request.request_created_date))
        const timeAgo = now.to(lastInteraction)
      
          return (
          <li 

            className="list_chat_details px-md-4 px-2"
            onClick={() => handleChatClicked(item)}
            style={{ background : item.request.request_ide === selectedChat.request.request_ide && '#F7F8FA', 
            cursor: "pointer", padding:'4px 12px' , borderBottom:'1px solid #F7F8FA'}}
          >
            <div className="d-flex align-content-center">
            {isViewer ?
              <img   className="img_user_chat"   src={user.user_avatar}   alt=""  style={{ alignclf:'center' }}  />
              :
              <div className="">

<div style={{position:'relative'}}>
              <img style={{alignSelf:'center', border: '1px solid #ff5a60' , padding:'1.5px'}} className="img_user_chat"   src={user.user_avatar}   alt=""    />
                    <img src={circulo} style={{bottom:'0',position:'absolute', right: "0", width: '16px', height:'16px'}} alt="" />
                </div>
              </div>
                
            }
              <div className="w-100 ml-3">
                <div className=" d-flex justify-content-between">

                  {<Main style={{fontWeight:'500 !important'}} className="mb-0">{request.request_purpose && request.request_purpose.slice(0, 30)}</Main>}
                  {  <p className="mb-0 chat_time">
                    {timeAgo}
                  </p>
      }
      {mobileNav && <BasicButton  onClick={()=>setShowMobileDetails(true)}>Show details</BasicButton>}
                </div>

                <Main className="m-0" id="">
                {user.user_name} {user.user_lastname}
              </Main>
              <p  className="chat_time">{request.request_reason && request.request_reason.slice(0, 30)}</p>
              </div>

            </div>
            
          </li>
        );
      })}
    </div>
  );
};
const BasicButton = styled.button`
border:0;
color:red;
background:none;
cursor:'pointer';
:focus {
  border:none;
  outline:none;
}
`
FilteredList.propTypes = {
  data: PropTypes.array.isRequired,
  onPressItem: PropTypes.func.isRequired,
};

FilteredList.defaultProps = {
  data: []
};

export default FilteredList;

const Main= styled.p`

font-weight:500 !important;
`