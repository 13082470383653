import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from 'styled-components'
import moment from 'moment'
import typeActitiy from "../../constants/typeActitiy";
import { withRouter } from 'react-router-dom'
import i18next from "i18next";

const Activity = ({ data, type, history }) => {
    const [user, setUser] = useState({})
    const [event, setEvent] = useState({})
    const {
        // activity_from_user: { user_avatar, user_username, user_name, user_lastname },
        // activity_event: { event_title, event_cover_image, event_uuid },
        activity_timestamp
    } = data;

    useEffect(() => {
        if (data) {

            if (type === typeActitiy.new_event) {
                setUser(data.activity_from_influencer)
            }
            else {
                setUser(data.activity_from_user)
            }

            if (data.activity_event) {
                setEvent(data.activity_event)
            }
        }
    }, [data])
    const handleActivityClick = () => {
        if (type === typeActitiy.ticket_sold) {
            history.push(`/event/${event.event_uuid}`)
        }
    }

    var now = moment()

    const timeAgo = now.to(parseInt(activity_timestamp))

    return (
        <div className="" style={{ padding: "5px 10px", maxWidth: '480px' }}>
            <div className="" style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div >
                    {user &&
                        <div style={{ display: 'flex', maxWidth: '340px' }}>

                            <img alt="avatar"
                                className="user_emergency"
                                src={user.user_avatar}
                                style={{ width: 45, height: 45, borderRadius: "50%", marginRight: '12px', objectFit: 'cover' }} />

                            <div onClick={handleActivityClick} style={{ borderBottom: '2px solid #F3F3F5', cursor: 'pointer' }}>

                                {type === typeActitiy.ticket_sold ?
                                    (event && <Span>
                                        {i18next.t("ActivityComponentBookedYourEvent", { username: user.user_username, eventTitle: event.event_title })}
                                    </Span>) :
                                    type === typeActitiy.follow ?
                                        <Span>
                                            {i18next.t("ActivityComponentStartedFollowingYou", { username: user.user_username })}
                                        </Span> :
                                        type === typeActitiy.new_event ?
                                            <Span>{i18next.t("ActivityComponentYouHaveTicketsFor")} </Span> :
                                            type === typeActitiy.request &&
                                            <Span>
                                                {i18next.t("ActivityComponentMadeAPrivateRequest", { name: user.user_name, surname: user.user_lastname })}
                                            </Span>

                                }
                                <span style={{ fontSize: '14px', marginTop: '4px', marginLeft: '4px' }}>
                                    {timeAgo}
                                </span>
                            </div>

                        </div>
                    }
                </div>


                {
                    type === typeActitiy.ticket_sold && <div onClick={handleActivityClick} style={{ position: 'relative', cursor: 'pointer' }}>
                        <img
                            alt="event"
                            className="img_activity"
                            src={event.event_cover_image}
                            style={{ width: 55, height: 55, borderRadius: 5, objectFit: 'cover' }}
                        />
                    </div>
                }


            </div>

        </div>
    );
};

const Span = styled.span`
color : black !important;
font-weight:500 !important;
margin-top:0px !important;
:hover{
  color:black !important;
}
`
export default withRouter(Activity)