import React, {Component} from 'react';
import { Card, Button } from 'react-bootstrap';
import './style.scss';
import Discover from '../../../assets/img/icons/ic-discover.svg';
import Calendar from '../../../assets/img/icons/ic-addcalendar.svg';
import Gain from '../../../assets/img/icons/ic-ganancia.svg';
import Fun from '../../../assets/img/icons/ic-fun.svg';
class Chart extends Component{
  constructor(props){
    super(props);
  }

  render(){
    return (
      <div className="cards d-flex">
       <Card style={{ width: '18rem', margin: '0 2rem 4rem 0' }}>
            <Card.Img variant="top" src={Discover} />
            <Card.Body>
                <Card.Title>Total Curent Balance</Card.Title>
                <Card.Title id="divider" style={{fontSize: '2em'}}>
                    $8,200.00
                </Card.Title>
                <Card.Text style={{fontSize: '0.8em'}}>13% INCREASE</Card.Text>
            </Card.Body>
            </Card>
            <Card style={{ width: '18rem', margin: '0 2rem 4rem 0' }}>
            <Card.Img variant="top" src={Calendar}/>
            <Card.Body>
                <Card.Title>March 31, 2019</Card.Title>
                <Card.Title id="divider" style={{fontSize: '2em'}}>
                    $4,000.00
                </Card.Title>
                <Card.Text style={{fontSize: '0.8em'}}>Payment scheduled</Card.Text>
            </Card.Body>
            </Card>
            <Card style={{ width: '18rem', margin: '0 2rem 4rem 0' }}>
            <Card.Img variant="top" src={Gain} />
            <Card.Body>
                <Card.Title>April 31, 2019</Card.Title>
                <Card.Title id="divider" style={{fontSize: '2em'}}>
                $ 4,000.00
                </Card.Title>
                <Card.Text style={{fontSize: '0.8em'}}>Payment scheduled</Card.Text>
            </Card.Body>
            </Card>
            <Card style={{ width: '18rem', margin: '0 2rem 4rem 0' }}>
            <Card.Img variant="top" src={Fun}/>
            <Card.Body>
                <Card.Title>Unavailable Balance</Card.Title>
                <Card.Title id="divider" style={{fontSize: '2em'}}>
                    $ 200.00
                </Card.Title>
                <Card.Text style={{fontSize: '0.8em'}}>Events not finalized yet</Card.Text>
            </Card.Body>
            </Card>
      </div>
    )
  }
}

export default Chart;
