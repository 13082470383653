import React, { Component } from 'react';
import queryString from 'query-string'
import axios from "axios"
import { Api, SECOND_ACCESS_TOKEN_API, LOGIN_BACK_URL } from "../../environment/environment"
import SweetAlert from "sweetalert2-react";
import * as actions from "../../store/actions/user";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { parseLocale } from 'utils/Localization'

class InstagramPage extends Component {
  state = { show: true, message: '' }

  componentDidMount = () => {
    const values = queryString.parse(this.props.location.search)
    const code = values.code
    const redirect_url = LOGIN_BACK_URL + "instagram";
    const action = localStorage.getItem("facebookAction");
    const redirectUrlVerify = localStorage.getItem("redirectUrl");

    const path = window.location.pathname


    console.log({ path, redirect_url, action, code, redirectUrlVerify })
    const userToken = JSON.parse(localStorage.getItem("env"));

    //Disabled till instagram login will be available
    if (false && (!userToken || !userToken.token)) {
      var referral = JSON.parse(localStorage.getItem("referral"))
      
      const referral_code = referral ? referral.id : null


      if (code && (path === "/instagram/" || path === "/instagram")) {
        axios.post(`${Api}auth/login_facebook`, { code: code, redirect_url: redirect_url, ...(referral_code) && { referral_code } }, {
          headers: {
            "Access-Token": SECOND_ACCESS_TOKEN_API,
            "Content-Type": "application/json",
            "Content-Language": parseLocale()

          }
        }).then(res => {
          const { data } = res;
          const env = {
            token: data.data.user_app_token
          };

          localStorage.setItem("env", JSON.stringify(env));
          if (
            !data.data.user_name &&
            !data.data.user_lastname
          ) {
            this.props.history.push("/viewer_profile");
          } else {
            this.props.history.push(`/profile`);
          }

          document.location.reload();


        })
          .catch(err => {
            this.props.setError("There was a problem connecting with Facebook.") // USAR PARA ERROR 
            this.props.history.push("/");
            document.location.reload();
          })

      }
    }
    else {
      var myToken = JSON.parse(localStorage.getItem("env"));

      if (!code) return

      try {
        axios.post(
          `${Api}user/verify_instagram`,
          { code, redirect_url: redirect_url },
          {
            headers: {
              "Access-Token": myToken.token,
              "Content-Type": "application/json",
              "Content-Language": parseLocale()

            }
          }
        ).then(res => {
          this.setState({ message: 'Your Instagram profile was verified!' })
        })
        .catch(error =>{
          console.log("Instagram", code, error, );
          try{
            this.props.setError(error.response.data.error[0].message) // USAR PARA ERROR 
          }catch(error){
            this.props.setError("There was a problem connecting with Instagram.") // USAR PARA ERROR 
          }
        })

      } catch (err) {
        this.props.setError("There was a problem connecting with Instagram.") // USAR PARA ERROR 
      } finally {
        this.props.history.push('/viewer_profile');
        this.props.onGetUser(myToken.token)
        localStorage.removeItem("facebookAction");
        localStorage.removeItem("redirectUrl");

      }
    }

  }

  render() {
    return (
      <>
        {this.message && (
          <SweetAlert
            show={this.message}
            title={this.message}
            onConfirm={() => {
              this.setState({ message: '' });
            }}
          />
        )}

      </>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onGetUser: token => dispatch(actions.getUser(token)),

    setError: error => dispatch(actions.setError(error))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(InstagramPage));

