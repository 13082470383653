import React, { useState, useEffect } from "react";
import Card from "./card_upcoming";
import "./style.scss";
import arrow from "assets/img/icons/ic-arrow-left.svg";
import close from "assets/img/icons/close-maincolor.svg";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { EventDAO } from "daos";
import { connect } from "react-redux";
import EventCard from "../Cards/EventCard";
import useMobileNav from '../../../hooks/useMobileNav'
import { SlideCards } from './SlideCards'
import styled from 'styled-components'
import i18next from 'i18next';

const eventDAO = new EventDAO();
const mock = [1, 2, 3];

const UpcomingLiveEvents = ({
  showAll,
  categories,
  categoriesFilter,
  dataInjected,
  limit,
  customTitle,
  closeSection
}) => {

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const {mobileNav} = useMobileNav()
  const init = async () => {
    if (events.length===0){
      try {
        let response
       if ((dataInjected===undefined) || dataInjected.length===0){
          response= await eventDAO.findUpcomingEvents(categoriesFilter)
         if (dataInjected ===undefined) {
           response = await eventDAO.findUpcomingEvents(categoriesFilter);
         }
         if (dataInjected && dataInjected.length === 0) {
           response = await eventDAO.findUpcomingEvents(categoriesFilter);
         }
       }
                const prevData =
          dataInjected && dataInjected.length>0 ? dataInjected : response.data.data;
        const data = prevData.map(item => {
          item.event.event_category = categories.find(
            category => category.cate_ide === item.event.event_category_ide
          );
          return item;
        })
        setEvents(data);
        setLoading(false);
      } catch (err) {
        throw err;
      }
    }

  };
  // const init = async dataInjected => {
  //   if (events.length===0){
  //     try {
  //       let response;
  //       if (dataInjected ===undefined) {
  //         response = await eventDAO.findUpcomingEvents(categoriesFilter);
  //       }
  //       if (dataInjected && dataInjected.length === 0) {
  //         response = await eventDAO.findUpcomingEvents(categoriesFilter);
  //       }
  //       const prevData =
  //         dataInjected && dataInjected.length ? dataInjected : response.data.data;
  //       const data = prevData.map(item => {
  //         item.event.event_category = categories.find(
  //           category => category.cate_ide === item.event.event_category_ide
  //         );
  //         return item;
  //       });
  //       
  //       setEvents(data);
  //       setLoading(false);
  //     } catch (err) {
  //       throw err;
  //     }
  //   }
    
  // };

  useEffect(() => {
    if (categories.length > 0) init(dataInjected);
  }, [categories, categoriesFilter, mobileNav]);

  const env = JSON.parse(localStorage.getItem("env") || "{}");

  return (
    <section className="Home-upcoming-live">
      <Container className="container">
        <div className="row">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'end' }} className="col-12">
            <h2 className="">{customTitle || i18next.t('UpcomingLiveEventsComponentUpcomingLiveEvents')}</h2>

            {showAll && (
              <Link to={`/all_upcoming_live_events`}>
                <div className="Show-all">
                  <span >{i18next.t('UpcomingLiveEventsComponentShowAll')}</span>
                  <img src={arrow} alt="arrow" />
                </div>
              </Link>
            )}

            {closeSection && (
               <div className="Show-all" onClick={closeSection} style={{cursor: "pointer", display: "flex", alignItems: "center"}}>
                 <img src={close} alt="close" style={{ width: 35, height: 35}} />
               </div>
            )}


          </div>

          {!loading ? (
            <>
              {
                !limit
                  ? events.map((event, index) => (

                    <EventCard
                      extraStyles="col-12 col-lg-4 col-md-6"
                      showFavorite={env.token}
                      showCountdown={false}
                      key={index}
                      event={event}
                      uniqueEvent={events.length < 2}
                    />
                  ))
                  :
                  // ((!mobileNav && events && events.length>0) || (mobileNav &&  events &&  events.length===1) ) ? events
                  ((!mobileNav && events && events.length > 0)) ? events
                    .splice(0, limit)
                    .map((event, index) => (
                      <EventCard
                        extraStyles="col-12 col-lg-4 col-md-6"
                        showFavorite={env.token}
                        showCountdown={false}
                        key={index}
                        event={event}
                        uniqueEvent={events.length < 2}
                      />
                    )
                    )
                    : (mobileNav && events && events.length > 0) ?
                      <SlideCards
                        loading={loading}
                        events={events.splice(0, limit)} /> :

                      !events.length === 0 && (
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            fontSize: "22px"
                          }}
                        >
                          {i18next.t('UpcomingLiveEventsComponentNoEventsInThisCategoryMessage')}
                        </p>
                      )}
            </>
          ) : (
              mock.map((item, index) => (
                <Card
                  key={index}
                  event={{ event: {}, user: {} }}
                  loading={true}
                />
              ))
            )}
        </div>
      </Container>
    </section>
  );
};

UpcomingLiveEvents.propTypes = {
  showAll: PropTypes.bool.isRequired,
  categoriesFilter: PropTypes.array,
  dataInjected: PropTypes.array,
  limit: PropTypes.number
};

const Container = styled.div`

@media (max-width:768px){
  h2{
    margin:0px 16px;

    font-size:22px;
  }
}

`
const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  };
};

export default connect(mapStateToProps)(UpcomingLiveEvents);
