import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import {Link} from "react-router-dom";

const Tag = ({ name }) => 

{
  
  return ( 
    <Link to = {`/search/${name}`}>
      <div className="Tag grow">
      # {name}    
      </div>
      </Link>
  )
}

Tag.propTypes = {
  name: PropTypes.string.isRequired
};

export default Tag;
