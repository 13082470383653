import React from "react";
import "./style.scss";
import map from "assets/img/icons/ic-map.svg";

const NombreCatPast = ({
  title,
  category,
  hour,
  year,
  location,
  day,
  month
}) => {
  

  return (
    <div className="NombreCat">
      <h1>{title}</h1>
      <h2>{category}</h2>
      <span className="Hor">
        {" "}
        <img src={map} alt="" />
        Live  at {hour}
        {/* Live From {location}, {day}th {month}, {year} at {hour} */}
      </span>
    </div>
  );
};

export default NombreCatPast;
