import AppleLogin from 'react-apple-login';
import React from 'react'
import {appleCallback, appleClientId, LOGIN_BACK_URL} from '../../../environment/environment'

const LoginWithApple = ({width})=> {
    return (
      <AppleLogin  
      clientId={appleClientId} 
      // redirectURI={'https://gotmy.com/'} 
      redirectURI={LOGIN_BACK_URL} 
      scope={'email name'}
      responseType={"code id_token"} 

      responseMode={'form_post'}  
      designProp={
        {
            height: 35,
            width: width? width:140,
            color: "black",
            border: true,
            type: "sign-in",
            border_radius: 40,
            scale: 1,
            locale: "en_US", 
         }
       }
      />
    );
  
}

export default LoginWithApple