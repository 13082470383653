import {useState, useEffect } from 'react'
import * as momenttz from 'moment-timezone';
import {useSelector} from 'react-redux'
const useTimezoneDifference =( {timezone1})=>{
    const [difference, setDifference] = useState ()
    const [timezone2, setTimezone2] = useState('')
    const userData = useSelector (state=> state.user.user )
    useEffect(()=>{
         if(userData && userData.user_location) { 
            const userTimezone = JSON.parse(userData.user_location)    
            try {
                setTimezone2(userTimezone.data.zoneName);
              } catch (error) {}
         }
    },[userData])

    useEffect(()=> {

        if ((timezone1 && timezone1.length>0) && (timezone2 && timezone2.length>0)){
            var offset1 = momenttz().tz(timezone1).utcOffset()/60
            var offset2 = momenttz().tz(timezone2).utcOffset()/60
            setDifference(offset2 - offset1)
    
        }
    },[timezone1, timezone2])
    return {difference}
}
export default useTimezoneDifference