import { actionTypes, actions } from "./actions";

export default (state, action) => {
  switch (action.type) {
    case actionTypes.ON_SUCCESS_GET_CHATS:
      return actions.onSuccessGetChats(state, action);
    case actionTypes.ON_ERROR_GET_CHATS:
      return actions.onErrorGetChats(state, action);
    case actionTypes.ON_SET_SEARCH_INPUT:
      return actions.onSetSearchInput(state, action);
    default:
      throw new Error();
  }
};
