
import React from 'react'
import  useMyPublicProfileLogic  from "./MyPublicProfileLogic";

const MyFavoriteCategories = (props) => {
    const { history, match, categories ,userValues} = props

    const { onChangeCategories, setSelectedCategory} = useMyPublicProfileLogic({ history, match });
    // const {name,lastname,username,birthdate,biography,avatar,location} = userValues;
    return (
        <div id="fix_category">
      <div className="container viewer_categories">
        <div>
      
          <p style={{ color:'grey', fontSize:'1.1rem'}} >
            We can show recommend to you Live Events and video contents with
            your preferences.
          </p>
        </div>
        <form id="form_update" className="form_category" noValidate>
          {categories.map(category => {
            if (category.cate_active) {
              return(
            <div className="grow" key={category.cate_ide}>
              <input
                value={category.cate_ide}
                name="categories-user"
                type="checkbox"
                checked={userValues.categories.indexOf(category.cate_ide) > -1}
              />
              <label
                onClick={() => {
                  props.onUpdateCategories(category)
                  setSelectedCategory(
                    category.cate_ide === 0 ? "0" : category.cate_ide
                  );
                }}
                style={{ position: "relative", marginRight: "6px" }}
              >
                <img
                  className="img_categorias"
                  style={{
                    willchange: "transform",
                    width: "100%",
                    height: "auto"
                  }}
                  src={category.cate_image}
                  alt=""
                />
                <p
                  style={{
                    position: "absolute",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    width: "120px",
                    fontSize: "15px",
                    zIndex: 1,
                    bottom: 0,
                    left: "5px"
                  }}
                >
                  {category.cate_description}
                </p>
              </label>
            </div>
          )}
        }
          
          
          )}
        </form>
      </div>
    </div>
    )
}
export default MyFavoriteCategories