import React, { useState, useEffect } from "react";
import Card from "./card";
import PastEventCard from '../../comunes/Cards/PastEventCard'
import "./style.scss";
import arrow from "assets/img/icons/ic-arrow-left.svg";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { EventDAO } from "daos";
import { connect } from "react-redux";
import moment from "moment";
import Loader from "components/comunes/loader";
import styled from 'styled-components'
import useMobileNav from '../../../hooks/useMobileNav'
import {SliderVideo} from './SliderVideo'
import i18next from 'i18next';

const eventDAO = new EventDAO();

function Video({ pastVideos, noMargin, showAll, categories, categoriesFilter }) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const { mobileNav } = useMobileNav()

  const init = async () => {
    try {
      const response = await eventDAO.findPastEvents(categoriesFilter);
      const data = response.data.data.map(item => {
        item.event.event_category = categories.find(
          category => category.cate_ide === item.event.event_category_ide
        );
        return item;
      });
      setEvents(data);
      setLoading(false);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    if (events.length > 0) {
      setLoading(false)
    }
  }, [mobileNav])
  useEffect(() => {
    if (pastVideos && pastVideos.length > 0) {
      setEvents(pastVideos)
    }
    else {
      if (categories.length > 0) init();

    }
  }, [categories, categoriesFilter, pastVideos]);

  const hasLoaded = events.length > 0;

  return (
    <section className="Home-upcoming-live">
      <Container className="container">
        <div className="row">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'end' }} className="col-12">
            <h2>{i18next.t('VideosSeccionComponentPastEvents')}</h2>

            {showAll && (
              <Link to={`/all_videos`}>
                <div className="Show-all">
                  <span>{i18next.t('VideosSeccionComponentShowAll')}</span>
                  <img src={arrow} alt="arrow" />
                </div>
              </Link>
            )}
          </div>

          {(hasLoaded && !mobileNav) ? (
            events.map((event, index) => {
              if (index < 4) {
                return (
                  <PastEventCard key={index} event={event.event} user_img={event.user.user_avatar} bg_img={event.event.event_cover_image}
                    name={event.user.user_username} titulo={event.event.event_title}
                    uuid={event.event.event_uuid} cat={event.event.event_category_ide}
                  />
                )
              }
            })
          ) : (hasLoaded && mobileNav) ?
              <SliderVideo noMargin={noMargin}
                events={events} loading={loading} />

              : (
                <Loader />
              )}
        </div>
      </Container>
    </section>
  );
}
const Container = styled.div`

@media (max-width:768px){

  h2{
    margin:0px 16px;
    font-size:22px;
  }
}

`
Video.propTypes = {
  showAll: PropTypes.object.isRequired,
  categoriesFilter: PropTypes.array
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  };
};

export default connect(mapStateToProps)(Video);


