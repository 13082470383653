import React, {Component} from 'react';
import NavBar_streaming from '../../comunes/navbar';
import './style.scss';
import Friend from './card'
import {Link} from "react-router-dom";
import Arrow from '../../../assets/img/icons/ic-arrow-right.svg'
class Friends extends Component {  
    render() {
        return (
            <div class="streaming">
                <header className="app-streaming">
                <NavBar_streaming/>
                </header>
                <div className="container-fluid streaming">
                    <div className="row">
                        <div className="col-md-11 d-flex flex-column div_ban" id="div_friends">
                       <Link to = {`/profile`}><p><img src={Arrow}/>Back to my profile</p></Link>
                       <h2 className="ml-2 mb-0">Friends</h2>
                       <Friend/>
                        </div>
                    </div>                   
                </div>
            </div>
        );
    }
}

export default Friends;