import React, {Component} from 'react';
import usr from '../../../assets/img/user.png';
import Attendees from "../attendees_admin";
import Attendees2 from "../attendees_participant";
import './style.scss';
import circulo from "../../../assets/img/logo-circulo.png";
import cinco_estrellas from "../../../assets/img/5-estrellas.png";
import {Link} from "react-router-dom";
class ImagenUsuPuntuacionGroup extends Component {
    constructor() {
        super();
        this.state = { items: [] };
        this.state = {
          showMe: true
        }
      }
      operation(){
        this.setState({
          showMe:!this.state.showMe
        })
      }
    render() {
        return (
            
            <div className="UsuPuntuacionGroup">
                
                {
                this.state.showMe?
                
                <div className="Div-foto_moderator">

                    <img className="Foto" src={usr} alt=""/>
                    <p className="Nombre nombre_moderator">Motorbike Group!!</p>
                    {/* <img src={circulo} className="Icono" alt=""/> */}
                </div>
                :null
            }
                {
                this.state.showMe?<div><Link to={`/message`}><p className="parrafo_moderator" onClick={()=>this.operation()}>Leave group chat</p></Link>
                
                <div className="img_small"><h2 className="gm mt-3 mb-3">Administrator</h2>
                <Attendees/>
                <h2 className="gm mt-3 mb-3"> 13 Participants</h2>

                 <div className="Attendes-div">
                    <Attendees2/>
                    <Attendees2/>
                    <Attendees2/>
                    <Attendees2/>
                    <Attendees2/>
                    <Attendees2/>
                    <Attendees2/>
                    <Attendees2/>
                    <Attendees2/>
                    <Attendees2/>
                    </div></div></div>
                     :null
                }
            </div>
        );
    }
}

export default ImagenUsuPuntuacionGroup;

