import React from 'react';
// import React, {Component, useState} from 'react';
import './style.scss';
import Overview from "./overview";
import LiveStats from "./live_event_stats";
import RatingReview from "./rating_reviews";
import Details from "./live_event_stats/stats_details_event"
import {Switch,Route} from "react-router-dom";
import Navbar from './navbar_stats'

function Stats(props) {
    return (
        <div className="Live-Events-Page">
            <Navbar/>
            <Switch>
                <Route path="/stats/live_stats/stats-detail" exact component={Details}/>
                <Route path="/stats/rating_review"  exact component={RatingReview}/>
                <Route path="/stats/live_stats" exact component={LiveStats}/>
                <Route path="/stats" exact component={Overview}/>
            </Switch>
        </div>
    );
}

export default Stats;