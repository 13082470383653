import React, { useState, useEffect } from "react";
import "./style.scss";
import Filtro_select from "../../../comunes/filtro";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";


const Filters_search = ({ history, categories }) => {
  const [idFhaterCategory, setIdFhaterCategory] = useState('');
  const [idChildrenCategory, setIdChildrenCategory] = useState('');
  const [showBounce, setShowBounce] = useState(false);
  const handleGetFatherCategory = (id) => setIdFhaterCategory(id);
  const handleGetChildrenCategory = (id) => setIdChildrenCategory(id);
  const handleSearch = () => {
    const paramChildren = idChildrenCategory ? `,${idChildrenCategory}` : '';
    history.replace(`/search/category/${idFhaterCategory}${paramChildren}`);
  } 

  useEffect(() => {
    if(idFhaterCategory || idChildrenCategory) {
      handleSearch();
      setShowBounce(true);
    }
  },[idFhaterCategory,idChildrenCategory]);

  return (
    <div className="Filters_search container mt-5">
      <div className="row">
        <div className="col-6 col-md-3 col-sm-6 p-2"  onClick={() => setShowBounce(false)}>
          <Filtro_select
            options={categories.map(cat => {
              return { value: cat.cate_ide === 0 ? '0' : cat.cate_ide, label: cat.cate_description };
            })}
            getFatherCategory={handleGetFatherCategory}
            getChildrenCategory={handleGetChildrenCategory}
            search={handleSearch}
          />
        </div>
       {false &&  <div className={showBounce ? 'col-6 col-md-3 col-sm-6 p-2 bounceIn'  : 'col-6 col-md-3 col-sm-6 p-2'}>
          <Filtro_select
            // disabled={!idFhaterCategory ? true : false}
            disabled={true}
            options={
              categories.filter(newCat =>  {
                return newCat.cate_parent_id === idFhaterCategory;
              }).map(c =>  {
                return  {value: c.cate_ide, label: c.cate_description };
              })
            }
            getChildrenCategory={handleGetChildrenCategory}
            search={handleSearch}
          />
        </div>
}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  };
};

export default withRouter(connect(mapStateToProps)(Filters_search));
