import React from "react";
import "./style.scss";
import Menu from "../../comunes/navbar_publish";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { RESTRICTED_VIEWS } from '../../../environment/environment'
import useMobileNav from '../../../hooks/useMobileNav'
import i18next from "i18next";
const NavbarLiveEventPublish = ({ isPrivate, ide, submit, onSave, loading }) => {
  const {mobileNav} = useMobileNav()
  return (
    <div className="Nav_search">
      <div className="Nav Nav2 Bg-blue flex-column pb-0">
        <div className="d-flex justify-content-between w-100">
          <Menu submit={submit} saveInDraft={onSave} loading={loading} />
        </div>
        <div className="Menu-live-Events-publish">
          <NavLink
            exact
            to={isPrivate ? `/private_publish/${ide}` : `/publish_event`}
            activeClassName="active_live_events"
            className="col-6"
          >
          {i18next.t("LiveEventInformationLiveEventInformation")}
          </NavLink>
          {!RESTRICTED_VIEWS &&
            <>
              <NavLink
                exact
                to={isPrivate ? `/private_publish/${ide}/pitch_video` : `/publish_event/pitch_video`}
                activeClassName="active_live_events"
                className="col-4"
              >
                Pitch Video
          </NavLink>
              <NavLink
                exact
                to={isPrivate ? `/private_publish/${ide}/thank_you` : `/publish_event/thank_you`}
                activeClassName="active_live_events"
                className="col-4"
              >
                Thank you video
          </NavLink>
            </>
          }
        </div>
      </div>
    </div>
  );
};

NavbarLiveEventPublish.propTypes = {
  submit: PropTypes.func.isRequired
};

export default NavbarLiveEventPublish;
