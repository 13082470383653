/* returns true if date is future, false if it is past, undefined if msDate doesn't correspond to date */ 
import {useState, useEffect} from 'react'

const useIsFutureDate = ({msDate}) => {
  const [isFuture, setIsFuture] = useState()

  useEffect(()=>{
      var now = new Date()
    if (msDate){
        var dateObj = new Date (parseInt(msDate))
        if ( typeof dateObj.getMonth==='function'){

            if (now < dateObj){
                setIsFuture(true)
            }
            else {
                setIsFuture(false)
            }

        }
        else {
            setIsFuture()
        }
       
    }
    else{
        setIsFuture()
    }

  },[msDate])
  return {isFuture}
}
export default useIsFutureDate