import React, { useState, useEffect } from "react";
import Navbar from "../comunes/navbar";
import arrow from "assets/img/icons/ic-arrow-left.svg";
import Video from "../comunes/videos_seccion";
import "./style.scss";
import EventCard from "../comunes/Cards/EventCard";
import map from "assets/img/icons/ic-map.svg";
import { UserDAO, LanguageDAO, EventDAO } from "daos";
import Loader from "components/comunes/loader";
import Linkify from 'react-linkify';
import useMobileNav from "../../hooks/useMobileNav"
import i18next from 'i18next';

const userDAO = new UserDAO();
const languageDAO = new LanguageDAO();
const eventDAO = new EventDAO();

const Profile = () => {
  const {mobileNav} = useMobileNav()
  const [user, setUser] = useState({});
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  const initData = async () => {
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    if (!env.token) return;
    try {
      let response = await userDAO.findByToken(env.token);
      setUser(response.data.data);

      const allLanguages = await languageDAO.findAll();
      response = await userDAO.findLanguages(env.token);
      setLanguages(
        response.data.data.map(item => {
          const language = allLanguages.data.data.find(
            lang => lang.lang_ide === item.lang_ide
          );

          return language || {};
        })
      );
      const liveEvents = await eventDAO.purchasedEvents(env.token);
      let filteredEvents = liveEvents.data.data.filter((event) => new Date(parseInt(event.event.event_streaming_end_date)) > (new Date()))
      setEvents(filteredEvents);
      setLoading(false);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    initData();
  }, []);

  const {
    user_username,  user_name,  user_lastname,  user_avatar,  user_aboutme,  user_location,  user_linkedin_data,  user_twitter_data,  user_instagram_data} = user;

  return (
    <div>
      <header>
        <Navbar />
      </header>
      {!loading ? (
        <div className="container profile_div">
          <div className="row">

            <div style={{ width: '100%' }} className="d-xl-flex user_profile ">
              <div className=" ">
                <div style={{ display: "flex", flexDirection: 'column' }} className="d-flex ">
                  <div className="d-md-flex">
                    {mobileNav ? 
                    <img style={{objectFit:"cover", maxHeight:"15rem", width:"100%"}} className="mb-3" src={user_avatar} alt="" />
                    : 
                    <img className="img_profile" src={user_avatar} alt="" />
                    }
                    <div className="about_user">
                      <h2 className="mb-1 name_profile">
                        {user_name}&nbsp;{user_lastname}
                      </h2>
                      <p className="username_profile mb-2">{`@${user_username}`}</p>
                      {user_location && JSON.parse(user_location).city.length>0 &&
                      <p style={{ marginLeft: '-4px' }} className="grey">
                        <img src={map} alt="" />
                        {user_location && JSON.parse(user_location).city}
                      </p> }
                    </div>
                  </div>

                  {user_aboutme &&
                    <div className="about_div my-md-5 mb-3">
                      <h5>{i18next.t("ProfileComponentAboutMe")}</h5>
                      <Linkify properties={{target: '_blank'}}><p id="about">{user_aboutme}</p></Linkify>
                      

                    </div>
                  }
                </div>
              </div>


              <div className="mt-0 mt-md-0 right_side_profile" >
                {languages.length> 0 && 
                <>
                <h5>{i18next.t("ProfileComponentLanguages")}</h5>
                <div className="d-flex align-items-center p-1 Cg ">
                  {languages.map(language => (
                    <div className="mr-4" key={language.lang_ide}>
                      <img
                      style={{width:'25px', height:'25px'}}
                        className="lenguage"
                        src={language.lang_image}
                        alt=""
                      />
                    &nbsp;{language.lang_description}
                    </div>
                  ))}
                </div>
                </>
}
              </div>
            </div>

          </div>

          <div className="ml-2 mt-4">
            <div className="row">
              <h2 className="subsectionTitle col ">{i18next.t("ProfileComponentUpcomingLiveEvents")}</h2>
              <p className="showall col text-right">
                {i18next.t("ProfileComponentShowAll")}
                <img src={arrow} alt="" />
              </p>
            </div>
            <div className="row">
              {events.map((event, index) => (
                
                <EventCard
                  extraStyles="col-12 col-lg-4 col-md-6"
                  showFavorite={false}
                  showCountdown={true}
                  key={index}
                  event={event}
                />
              
                // <LiveEventsProfile key={index} data={event} />
              ))}
              {!events.length && (
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    fontSize: "22px"
                  }}
                >
                  {i18next.t("ProfileComponentYouHaveNotAttendedAnyLiveEventYet")}
                </p>
              )}
              {/* <NoEvents message="It seems that you haven't attended any live event yet"/> */}
            </div>
          </div>

          <div>
            <Video />
          </div>
        </div>
      ) : (
          <div className="container profile_div">
            <Loader />
          </div>
        )}
    </div>
  );
};

export default Profile;
