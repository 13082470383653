
import {useState, useEffect, useCallback } from 'react'
import { EventRequestDAO } from "daos";
import moment from "moment"
const eventRequestDAO = new EventRequestDAO();

const useEditRequest = (id) => {

    const { token } = JSON.parse(localStorage.getItem("env"));
    const [requestData, setRequestData] = useState({});
    const [loading, setLoading] = useState(true);
  
    const initData = useCallback(async () => {  
      try {
        const {
          data: { data }
        } = await eventRequestDAO.getMyRequestInfluencer(token);
        const request = data.pending.find(
          ({ request: { request_ide } }) => request_ide.toString() === id
        );
        if (request) {
            
          setRequestData(request);
        } else {
          
        }
        setLoading(false);

      } catch (err) {
        
      } finally {
        setLoading(false);
      }
    })

    useEffect (() => {
        initData();
      }, [])
      return { requestData, loading }
}
export default useEditRequest