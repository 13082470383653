import React from 'react'
import circulo from "assets/img/logo-circulo.png";
import {Container, AvatarImg, Icon} from './styles'
import { INFLUENCER_ICON_ENABLED } from '../../../environment/environment';
const Avatar = ({ user_avatar }) => {
    return (
        <Container>
            <AvatarImg  src={user_avatar} alt="" />
            {INFLUENCER_ICON_ENABLED && (<Icon src={circulo} className="" alt="" />)}
        </Container>
    )
}
export default Avatar