/* returns 
    if user logged in && user_location exists, time in user location

    else { 
        time in event location
    }


time and date from MS and local user timezone */

import { useState, useEffect } from "react";
import * as momenttz from "moment-timezone";
import moment from "moment";
import { useSelector } from "react-redux";
const useUserOrLocalTimeHour = (request_date, event, isCreator) => {
  const user = useSelector((state) => state.user.user);
  const [timeZone, setTimeZone] = useState("");
  const [time, setTime] = useState("");
  const [gmtOffset, setGmtOffset] = useState();

  useEffect(() => {
    let userTimezone = null;

    try {
      userTimezone = JSON.parse(user.user_location);
    } catch (error) {}
    console.log("useSUserLocal",userTimezone)
    if (
      user &&
      user.user_location &&
      user.user_location.length > 0 &&
      userTimezone &&
      userTimezone.data &&
      userTimezone.data.zoneName && (!isCreator || isCreator === undefined)
    ) {
      try {
        setTimeZone(userTimezone.data.zoneName);
      } catch (error) {}

      try {
        const gmtPrefix =
          userTimezone.data.gmtOffset / 3600 > 0
            ? "GMT+"
            : userTimezone.data.gmtOffset / 3600 === 0
            ? "GMT"
            : "GMT";

        setGmtOffset(
          gmtPrefix + (userTimezone.data.gmtOffset / 3600).toString()
        );
      } catch (error) {}
    } else {
      if (event && event.event_location && event.event_location.length > 0) {
        //TIMEZONE OF EVENT LOCATION
        const tz = JSON.parse(event.event_location);
        setTimeZone(tz.data.zoneName);
      }
      // Offset of the browser
      
      let timedifference = momenttz().toString();
      console.log("useSUserLocal 2",timedifference, moment.tz.guess())
      let gmtPrefix = timedifference.substring(timedifference.indexOf("GMT"));
      gmtPrefix = "(" + gmtPrefix.substr(0, 6) + " " + moment.tz.guess() + ")";
      setGmtOffset(gmtPrefix);
    }
  }, [user, event, isCreator]);

  useEffect(() => {
    let time;
    let day;
    let month;
    let weekDayAndDate;
    if (request_date && request_date.length > 0 && timeZone.length > 0) {
      if (user && user.user_location && user.user_location.length > 0) {
        time = momenttz(parseInt(request_date))
          .tz(timeZone)
          .format("hh:mm A");
        time = time.charAt(0) === "0" ? time.substr(1) : time;
        day = momenttz(parseInt(request_date))
          .tz(timeZone)
          .format("DD");
        month = momenttz(parseInt(request_date))
          .tz(timeZone)
          .format("MMMM")
          .substring(0, 3)
          .toUpperCase();
        weekDayAndDate = `${momenttz(parseInt(request_date))
          .tz(timeZone)
          .format("dddd")} ${momenttz(parseInt(request_date))
          .tz(timeZone)
          .format("DD-MM-YYYY")}`;
      } else {
        time = momenttz(parseInt(request_date)).format("hh:mm A");
        time = time.charAt(0) === "0" ? time.substr(1) : time;
        day = momenttz(parseInt(request_date)).format("DD");
        month = momenttz(parseInt(request_date))
          .format("MMMM")
          .substring(0, 3)
          .toUpperCase();
        weekDayAndDate = `${momenttz(parseInt(request_date)).format(
          "dddd"
        )} ${momenttz(parseInt(request_date)).format("DD-MM-YYYY")}`;
      }
      setTime({ time, day, month, weekDayAndDate, gmtOffset });
    }
  }, [request_date, timeZone]);

  return { time };
};

export default useUserOrLocalTimeHour;
