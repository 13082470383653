import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./styles.scss";

const ButtonManagerCollapser = ({
  onClick,
  icon,
  label,
  isFirstElement,
  isLastElement,
  tooltipText,
  specialClassName
}) => {
  const renderButtonElementContent = () => {
    return (
      <div
        className={buttonStyle}
        onClick={() => {
          try {
            onClick();
          } catch (error) {
            console.error("ButtonManagerCollapser ", label," error:", error)
          }
        }}
      >
        <img src={icon} />
      </div>
    );
  };

  var buttonStyle = "ButtonManagerCollapser";

  if (isFirstElement) {
    buttonStyle = buttonStyle + " ButtonManagerItemFirstElement";
  }

  if (isLastElement) {
    buttonStyle = buttonStyle + " ButtonManagerItemLastElement";
  }

  if (specialClassName) {
    buttonStyle = buttonStyle + " ButtonManagerCollapserAbsolutePosition";
  }

  if (tooltipText) {
    return (
      <OverlayTrigger
        placement={"top"}
        overlay={
          <Tooltip
            style={{
              marginBottom: "0.5%",
            }}
          >
            {tooltipText}
          </Tooltip>
        }
      >
        {renderButtonElementContent()}
      </OverlayTrigger>
    );
  }else{
    return renderButtonElementContent();
  }
};

export default ButtonManagerCollapser;
