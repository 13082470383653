import React from "react";
import Filters_search from "../../search_page/search_by_category/filters_search";
import Back from "../../comunes/back";
import NavBar_search from "../../search_page/search/navbar_search";
import UpcomingLiveEvents from "../../comunes/upcomingLiveEvents";
import i18next from 'i18next';

function AllUpcomingLiveEvents(props) {
  return (
    <div>
      <NavBar_search /> 

      <div className="container">
        <div className="row mt-4">
          <Back text={i18next.t('AllUpcomingLiveEventsComponentBackToSearch')} url={"/search"} />
        </div>
      </div>

      <div className="container">
        <Filters_search />
      </div>

      <UpcomingLiveEvents />
    </div>
  );
}

export default AllUpcomingLiveEvents;
