import axios from "axios";
import { Api, ACCESS_TOKEN_API } from "./../environment/environment";
import { parseLocale } from '../utils/Localization'

export default class CategoryDAO {
  findAll() {
    const headers = { 
      "Access-Token": ACCESS_TOKEN_API,
      "Content-Language": parseLocale()
    };
    return axios.get(`${Api}categories/all`, { headers });
  }
}
