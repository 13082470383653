import React, { Component } from "react";
import "./styles.scss";
import swapView from "assets/img/icons/swap.svg";
import resizeView from "assets/img/icons/resize.svg";
import screenShare from "assets/img/icons/screenShare.svg";
import stopScreenShare from "assets/img/icons/stopScreenSharing.svg";
import enterFullScreen from "assets/img/icons/switch-to-full-screen-button.svg";
import exitFullScreen from "assets/img/icons/full-screen-exit.svg";
import lowVolume from "assets/img/icons/volume.svg";
import highVolume from "assets/img/icons/volume-level.svg";
import muteVolume from "assets/img/icons/mute.svg";
import settings from "assets/img/icons/settings.svg";
import logoGotmy from "assets/img/logo.png"
import { Spinner } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import i18next from 'i18next';
import ReactTooltip from "react-tooltip"
import ExpandIcon from "assets/img/icons/expandIcon.svg"
import { isMobile } from "react-device-detect";

let idleTimer = null;
let idleState = false;

let idleTimerRemoteView = null;
let idleStateRemoteView = false;

export default class OpenViduVideoComponent extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();

    this.state = {
      videoHeight: 0,
      videoWidth: 0,
      videoContainerHeight: 0,
      overlayTimer: null,
      idleState: false,
      volumeLevel: this.props.volumeLevel || 0.5,
      resizeFactor: 1.2,
      resizeMode: true,
      previousStreamManager: null,
      shareScreenSwitched: false,
      shareScreenData: null
    }
  }

  showOverlay(time) {

    clearTimeout(idleTimer);
    if (idleState == true) {
      var element = document.getElementById('streamControls');

      if (element && element.classList) {
        element.classList.remove("inactive");
      }
    }
    idleState = false;
    idleTimer = setTimeout(function () {
      var element = document.getElementById('streamControls');
      if (element && element.classList) {
        element.classList.add("inactive");
      }
      idleState = true;
    }, time);
  }


  showOverlayRemoteView(time) {

    clearTimeout(idleTimerRemoteView);
    if (idleStateRemoteView == true) {
      var element = document.getElementById('streamControlsRemoteView');

      if (element && element.classList) {
        element.classList.remove("inactive");
      }
    }
    idleStateRemoteView = false;
    idleTimerRemoteView = setTimeout(function () {
      var element = document.getElementById('streamControlsRemoteView');
      if (element && element.classList) {
        element.classList.add("inactive");
      }
      idleStateRemoteView = true;
    }, time);
  }

  updateOverlayDimensions() {

    let element = document.getElementById('video-Element');

    if (element) {
      const height = element.clientHeight;
      const width = element.clientWidth;

      if (height != this.state.videoHeight) {
        this.setState({
          videoHeight: height
        })
      }

      if (width != this.state.videoWidth) {
        this.setState({
          videoWidth: width
        })
      }
    }
  }

  attachTrack(track, participant) {
    var trackSidOrName = track.name;

    try {
      if (track.name.includes("microphone")) {
        try {
          trackSidOrName = "Track-" + track.sid;
        } catch (error) {

        }

      } else {
        try {
          trackSidOrName = "Track-" + track.name;
        } catch (error) {

        }
      }
    } catch (error) {
      console.log("Error: ", error)
      console.error(error)
    }


    // If the attached Track is a VideoTrack that is published by the active
    // Participant, then attach it to the main video as well.
    if (track.kind !== 'data') {
      var previousHTMLElement = document.getElementById(trackSidOrName);
      var container = document.getElementById("video-element-remote");

      if (!previousHTMLElement || track._attachments && track._attachments.size == 0) {
        if (container && this.props.isRemote && track.kind === 'video') {
          container.innerHTML = null;
        }

        var appendedChild = this.videoRef.current.appendChild(track.attach());
        appendedChild.id = trackSidOrName;
      }
    }
  }


  /**
 * Detach a Track from the DOM.
 * @param track - the Track to be detached
 * @param HTMLElement - the HTMLElement that is playing the Track
 */
  detachTrack(track, HTMLElement) {
    // Detach the Participant's Track from the thumbnail.
    try {
      track.detach(HTMLElement);
      HTMLElement.remove();

    } catch (error) {
      console.error(error);
    }
  }

  removeAndDetachTrack(track) {
    var trackSidOrName = "";

    try {

      if (track.name.includes("microphone")) {
        try {
          trackSidOrName = "Track-" + track.trackSid;
        } catch (error) {

        }

      } else {
        try {
          trackSidOrName = "Track-" + track.name;
        } catch (error) {

        }
      }
    } catch (error) {
      console.log("Error removeAndDetachTrack OVVIDEO: ", error)
      console.error(error)
    }

    var previousHTMLElement = document.getElementById(trackSidOrName);

    if (previousHTMLElement) {
      this.detachTrack(track, previousHTMLElement);
    }
  }

  
  removeAndDetachPreviousTracks() {
    this.props.publications.forEach(publication => {
      if (publication.track.kind === "video") {
        this.removeAndDetachTrack(publication.track);
      }
    });
  }

  updateViews() {



    if (this.state.shareScreenData != this.props.screenShareTracks) {

      if (!this.props.screenShareTracks) {
        this.removeAndDetachTrack(this.state.shareScreenData.track);
      }

      this.setState({
        shareScreenData: this.props.screenShareTracks
      });
    }

    if (this.props.screenShareTracks) {

      try {
        try {

          this.removeAndDetachPreviousTracks();

          if (this.props.screenShareTracks.track) {
            this.attachTrack(this.props.screenShareTracks.track, this.props.screenShareTracks.participant);
          }
          try {
            // Once the TrackPublication is subscribed to, attach the Track to the DOM.
            this.props.screenShareTracks.track.on('subscribed', track => {
              this.attachTrack(this.props.screenShareTracks.track, this.props.screenShareTracks.participant);
            });

          } catch (error) {
            console.error(error);
          }

        } catch (error) {
          console.error(error)
        }

        try {
          this.props.publications.forEach(publication => {
            try {

              if (publication.track.kind === "audio") {

                if (publication.track) {
                  this.attachTrack(publication.track, publication.participant);
                }

                // Once the TrackPublication is subscribed to, attach the Track to the DOM.
                publication.track.on('subscribed', track => {
                  this.attachTrack(track, publication.participant);
                });
              }
            } catch (error) {
              console.error(error)
            }
          })
        } catch (error) {
          console.log("updateViews error catch", this.props, this.props.publications);
          console.error(error);
        }

      } catch (error) {
        console.log("updateViews error catch", this.props, this.props.publications);
        console.error(error);
      }
    } else {
      if (this.props.isRemote) {

        try {
          this.props.publications.forEach(publication => {
            try {

              if (publication.participant.identity === this.props.participant.identity && publication.track.kind === "video") {

                if (publication.track) {
                  this.attachTrack(publication.track, publication.participant);
                }

                // Once the TrackPublication is subscribed to, attach the Track to the DOM.
                publication.track.on('subscribed', track => {

                  this.attachTrack(track, publication.participant);
                });
              }

            } catch (error) {
              console.error(error)
            }
          })
        } catch (error) {
          console.log("updateViews error catch", this.props, this.props.publications);
          console.error(error);
        }
      } else {

        try {
          this.props.publications.forEach(publication => {
            try {
              if (publication.track) {
                this.attachTrack(publication.track, publication.participant);
              }

              // Once the TrackPublication is subscribed to, attach the Track to the DOM.
              publication.track.on('subscribed', track => {

                this.attachTrack(track, publication.participant);
              });
            } catch (error) {
              console.error(error)
            }
          })
        } catch (error) {
          console.log("updateViews error catch", this.props, this.props.publications);
          console.error(error);
        }
      }
    }

    this.setVolume(this.props.volumeLevel);

  }

  componentWillUnmount() {
    this.removeAndDetachPreviousTracks();
  }


  componentDidUpdate(props) {

    this.updateViews();

    this.updateOverlayDimensionsWithDelay();

    if (this.props.volumeLevel != this.state.volumeLevel) {
      this.setVolume(this.props.volumeLevel);
    }
  }

  setVolume(value) {
    if (this.props.streamManager && this.videoRef && this.props.setVolume) {
      const element = document.getElementById('video-element-remote');

      if (element) {
        element.volume = parseFloat(value);
      }
    }
  }
  handleExpandChat (){
    ReactTooltip.hide();
    this.props.setMinimisedChat(false)

  }
  handleResize() {

    const element = document.getElementById('video-container');
    var height = this.state.videoContainerHeight;

    if (element) {
      height = element.innerHeight;

      if (height) {
        this.setState({
          videoContainerHeight: height
        })
      }
    }

  }



  componentDidMount() {
    this.handleResize();

    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  updateOverlayDimensionsWithDelay() {
    setTimeout(this.updateOverlayDimensions.bind(this), 250);
  }

  chooseVolumeIcon() {
    var value = this.state.volumeLevel;
    if (value == 0) {
      return muteVolume;
    } else if (0 < value && value < 50) {
      return lowVolume;
    } else if (50 < value) {
      return highVolume;
    } else {
      return lowVolume;
    }
  }

  renderOverlayElement(visible = true) {
    if (!isMobile && visible) {
      return (
        <div
          className="VideoOverlay"
          id="streamControls"
          style={{
            position: 'absolute',
            bottom: 0,
            width: "100%",
            height: '100%'
          }}
          onMouseMove={() => {
            this.showOverlay(1000);
          }}
        >
          <div
            className="TopbuttonManager noselect"
          >
            <ReactTooltip
              globalEventOff='click'
              eventOff='click'
              place="bottom" type="dark" effect="solid"
            />

            {this.props.minimisedChat === true &&
              <img src={ExpandIcon}
              className="grow"
                style={{ width: 18, height: 18, cursor: "pointer", position: "absolute", top: 74, right: "2.2%" }}
                data-tip={"Expand Chat"} data-event-off='click'
                onClick={ ()=> { this.handleExpandChat() }}
             />

            }

            {this.props.isInfluencer && false &&  (<img
              className={"settingsController overlayButtons noselect"}
              src={settings}
              onClick={() => {
                if (this.props.setModalVisibility) {
                  this.props.setModalVisibility(true)
                }
              }}
            />)}

          </div>

          <div
            className="buttonManager noselect"
          >
            <div
              id={"volumeLevel"}
              className={"volumeSlider"}
            >
              <img
                className={"volumeController noselect"}
                src={this.chooseVolumeIcon()}
              />
              <Slider
                min={0} max={100} defaultValue={50} onChange={(value) => {

                  if (this.props.updateVolume) {
                    this.props.updateVolume(value);
                  }

                  this.setState({
                    volumeLevel: value
                  })
                }} />
            </div>


            {
              this.props.shareScreen && (<div
                className={this.props.isSharingScreen ? "screenSharebtnSharing shareScreenContainer" : "screenSharebtn shareScreenContainer"}
              >
                <img
                  className={" overlayButtons noselect"}
                  src={this.props.isSharingScreen ? stopScreenShare : screenShare}
                  onClick={() => {

                    if (this.props.shareScreen && !this.props.isSharingScreen) {
                      this.props.shareScreen();
                    } else if (this.props.stopShareScreen && this.props.isSharingScreen) {
                      this.props.stopShareScreen();
                    }

                  }}
                />
                {this.props.isSharingScreen && (
                  <p>{i18next.t('OVVideoComponentSharingYourScreen')}</p>
                )}
              </div>)}



            <img
              className={"fullscreenbtn overlayButtons noselect"}
              src={this.props.isFullScreen ? exitFullScreen : enterFullScreen}
              onClick={() => {

                if (this.props.isFullScreen) {
                  this.props.exitFullScreen();
                } else {
                  this.props.enterFullScreen();
                }
              }}
            />
          </div>
        </div>
      )
    } else {
      return null;
    }
  }

  resizeView() {

    if (this.state.resizeMode) {
      //medium
      this.setState({
        resizeMode: false,
        resizeFactor: 1.6
      })
    } else {
      //medium
      this.setState({
        resizeMode: true,
        resizeFactor: 1.2
      })
    }

  }

  renderRemoteOverlay(visible = true) {
    return null;
    if (this.videoRef != null && this.props.streamManager && visible) {
      return (
        <div
          className="VideoOverlayRemoteView"
          id="streamControlsRemoteView"
          style={{
            position: 'absolute',
            float: 'left',
            width: "100%",
            height: '100%'
            //height: '20vh'
          }}

          onMouseMove={() => {
            this.showOverlayRemoteView(1000);
          }}
        >

          <div
            className="buttonManagerRemoteView noselect"
          >

            {false && (<img
              className={"swapScreen overlayButtons noselect"}
              src={swapView}
              onClick={() => {

                if (this.props.swapRemoteView) {
                  this.props.swapRemoteView();
                }

              }}
            />)}
            <img
              className={"resizeScreen overlayButtons noselect"}
              src={resizeView}
              onClick={() => {
                this.resizeView();
              }}
            />
          </div>
        </div>
      )
    } else {
      return null;
    }
  }

  getNicknameTag() {
    try {
      return this.props.participant.identity;
    }
    catch {
      return '';
    }
  }

  renderNotReadyModal(visible) {

    if (visible) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            zIndex: 5
          }}
        >
          <Col
            className={"align-items-center"}
            style={{
              textAlign: 'center',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <img src={logoGotmy}
              style={{
                width: "20%"
              }}
            />
            <Spinner
              style={{
                width: 70,
                height: 70
              }}
              animation="grow" variant="danger" />

            <h2>{i18next.t('OVVideoComponentLoadingStreaming')}</h2>
            <h3>{i18next.t('OVVideoComponentItSeemsTheInfluencerIsNotReadyYet')}</h3>

          </Col>
        </div>
      );
    } else {
      return null;
    }
  }


  renderBackgroundLoader(visible) {

    if (visible) {
      return (
        <div
          className={"remote-video-loader"}
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            width: '100%',
            minHeight: '90%',
            display: 'flex',
            zIndex: -1
          }}
        >
          <Col
            className={"align-items-center"}
            style={{
              textAlign: 'center',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {/* <img src={logoGotmy}
              style={{
                width: this.props.isRemoteApp ? "90%" : "60%"
              }}
            /> */}
            <Spinner
              style={{
                width: this.props.isRemoteApp ? 70 : 35,
                height: this.props.isRemoteApp ? 70 : 35,
              }}

              animation="grow" variant="danger" />

            <p>{i18next.t('OVVideoComponentLoadingVideo')}</p>
          </Col>
        </div>
      );
    } else {
      return null;
    }
  }


  render() {
    console.log({ props: this.props })
    var videoElementStyle = this.props.isRemote ? {
      //maxHeight: this.props.isRemoteApp ? 'none' : 'none',
      maxWidth: this.props.isRemoteApp ? '12.5vw' : '25vw',
      minHeight: this.props.isRemoteApp ? '10vh' : '5vh',
      objectFit: 'contain',
      width: 'auto !important',
      height: 'auto !important'
    } : {
        objectFit: 'contain',
      }

      if(isMobile && this.props.isRemote){
        if( this.props.isLandscape){
          videoElementStyle =  {
            maxHeight:'45vh',
            maxWidth: '35vw',
            objectFit: 'contain',
            width: 'auto !important',
            height: 'auto !important'
          }
        }else{
          videoElementStyle =  {
            maxHeight:'45vh',
            maxWidth: '45vw',
            objectFit: 'contain',
            width: 'auto !important',
            height: 'auto !important'
          }
        }
       
      }

    var mode = null;
    var width = null;
    var height = null;

    this.updateOverlayDimensionsWithDelay();

    mode = this.props.mode;
    width = this.props.width;
    height = this.props.height;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
        className={this.props.isMainScreen ? "fullVideoContainer" : ""}
      >

        {this.renderNotReadyModal(!this.props.influencerConnected)}
        {this.renderOverlayElement(this.props.showOverlay)}
        {this.renderRemoteOverlay(this.props.isRemote && this.props.renderRemoteOverlay)}
        {this.renderBackgroundLoader(this.props.isRemote)}

        <div
          id={this.props.isRemote ? "video-element-remote" : "video-Element"}
          className={this.props.specialClass}
          onMouseMove={() => {
            this.showOverlay(1000);
          }}

          style={videoElementStyle}
          ref={this.videoRef} >

        </div>

        {!this.props.isMainScreen && (
          <div
            className="viewerDataContainer"
          >
            <p
              className="viewerDataContent"
              style={this.props.influencerRemote ? {
                color: "#ff5a60"
              } : null}
            >
              {this.getNicknameTag()}
            </p>
          </div>
        )}
      </div>
    );
  }
}
