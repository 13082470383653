export const actions = Object.freeze({
  onSuccessGetChats: (state, { chats }) => ({
    ...state,
    chats,
    loadingChats: false
  }),
  onErrorGetChats: (state, { error }) => ({ ...state, errorChat: error }),
  onSetSearchInput: (state, { value }) => ({ ...state, searchInput: value })
});

export const actionTypes = {
  ON_SUCCESS_GET_CHATS: "ON_SUCCESS_GET_CHATS",
  ON_ERROR_GET_CHATS: "ON_ERROR_GET_CHATS",
  ON_SET_SEARCH_INPUT: "ON_SET_SEARCH_INPUT"
};
