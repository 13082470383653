import React, { useEffect, useState, useRef } from "react";
import { BROADCAST_EVENTS_ENABLED, alternativePublishPage } from "../../../environment/environment";
import { connect } from "react-redux";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Loader from "components/comunes/loader";
import LoadingOverlay from "react-loading-overlay";
import { countCharacters, verifyRegex } from "../../../utils";
import { findCurrencyByCurrencyCode } from "../../../utils/currencyUtils";
import {
  EVENT_TYPE_BROADCAST,
  EVENT_TYPE_INTERACTIVE,
  streamingTypes,
} from "../../../utils/EventStreamingTypes";
import ChooseLanguage from "components/comunes/ChooseLanguage/ChooseLanguage";
import "react-image-crop/dist/ReactCrop.css";
import ModalCrop from "./ModalCrop";
import useMobileNav from "../../../hooks/useMobileNav";
import useClickOutside from "../../../hooks/useClickOutside";
import i18next from "i18next";
import { Link } from "react-router-dom";
import ModalEventInfo from "../ModalEventInfo";
import { NEW_PUBLISH_PAGE } from "../../../environment/environment";
import { getFormattedTime } from "./utils/publishEventUtils";
import { useSelector } from "react-redux";
import "../style.scss";
import "./style.scss";
import PromoCode from "./publishComponents/PromoCode";
import ChooseTicketTypes from "./publishComponents/ChooseTicketTypes";
import ChooseCategory from "./publishComponents/ChooseCategory";
import SetEventTitle from "./publishComponents/SetEventTitle";
import NewInfluencerEmail from "./publishComponents/NewInfluencerEmail";
import SetCurrency from "./publishComponents/SetCurrency";
import SetTicketDetails from "./publishComponents/SetTicketDetails";
import SetCoverImage from "./publishComponents/SetCoverImage";
import SetDefaultCoverImages from "./publishComponents/SetDefaultCoverImages";
import ChooseDateAndLocation from "./publishComponents/ChooseDateAndLocation";
import PublicEventOptions from "./publishComponents/PublicEventOptions";
import SelectStreamingType from "./publishComponents/SelectStreamingType";
import EventPreview from "./publishComponents/EventPreview";
import ShowExtraPriceInformation from "./publishComponents/ShowExtraPriceInformation";
import { roundHourToclouserQuarter } from "../../../utils/timeAndDateUtils";
import imagePlaceHolder from "assets/img/imagePlaceHolder.jpg";
import imagePlaceHolder2 from "assets/img/imagePlaceHolder2.jpg";
import imagePlaceHolder3 from "assets/img/imagePlaceHolder3.jpg";

import moment from "moment";

const thumbnailImages = [
  imagePlaceHolder,
  imagePlaceHolder2,
  imagePlaceHolder3
];

const LiveEventInformation = ({
  massiveBroadcast,
  setMassiveBroadcast,
  submit,
  loadedImg,
  handleImageLoad,
  eventValues,
  privateMode,
  categories,
  currencies,
  onChangeEventValues,
  onChangeUserEmail,
  onChangeConfirmUserEmail,
  userEmail,
  confirmUserEmail,
  setEventValues,
  handleImageChange,
  loading,
  loadingImage,
  initiaLoading,
  languages,
  user,
  ticketTypes,
  openTicket,
  setOpenTicket,
  publicEvent,
  setPublicEvent,
  initDefaultImage,
  defaultImage
}) => {
  const {
    event_title,
    event_description,
    event_currency,
    event_tags,
    event_date,
    event_location,
    event_num_attendees,
    event_ticket_type,
    event_price_ticket,
    event_promotional_code_discount,
    event_cover_image,
    event_promotional_code,
  } = eventValues;
  const [modalEventInfo, setModalEventInfo] = useState(false);
  const [startDate, setStartDate] = useState(
    roundHourToclouserQuarter(new Date())
  );
  const [endDate, setEndDate] = useState(
    roundHourToclouserQuarter(
      new Date(
        moment()
          .add(1, "hours")
          .valueOf()
      )
    )
  );
  const [selectedTicket, setSelectedTicket] = useState({});
  const [grossRev, setGrossRev] = useState(0);
  const [dateObj, setDateObj] = useState(new Date());
  const { mobileNav } = useMobileNav();
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const [modalCrop, setModalCrop] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const imageRef = useRef(null);
  const [currency, setCurrency] = useState({});
  const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 16 / 9 });
  const [startPickerOpen, setStartPickerOpen] = useState(false);
  const [endPickerOpen, setEndPickerOpen] = useState(false);
  const startPickerRef = useRef(null);
  const endPickerRef = useRef(null);
  const influencerInfo = useSelector((state) => state.user.influencer);
  const [ticketTypesDisplay, setTicketTypesDisplay] = useState();
  useClickOutside(endPickerRef, () => setEndPickerOpen(false));
  console.log({ event_cover_image });
  useClickOutside(startPickerRef, () => setStartPickerOpen(false));
  const [streamingType, setStreamingType] = useState(streamingTypes[0]);
  const onChangeStreamingType = (e) => {
    if (e.target.value === "Broadcast") {
      setMassiveBroadcast(true);
      setStreamingType(
        streamingTypes.filter(
          (type) => type.event_type === EVENT_TYPE_BROADCAST
        )[0]
      );
    } else {
      setStreamingType(
        streamingTypes.filter(
          (type) => type.event_type === EVENT_TYPE_INTERACTIVE
        )[0]
      );
      setMassiveBroadcast(false);
    }
  };

  useEffect(() => {
    let newSelectedTicket = {};
    let event_values_ticket_type = eventValues.event_ticket_type;
    if (ticketTypes) {
      ticketTypes.forEach((ticket) => {
        if (ticket.type_id === event_values_ticket_type) {
          newSelectedTicket = ticket;
        }
      });
    }
    if (
      selectedTicket === undefined ||
      !selectedTicket ||
      eventValues.event_ticket_type !== selectedTicket.type_id
    ) {
      setSelectedTicket(newSelectedTicket);
    }
  }, [event_ticket_type, ticketTypes, ticketTypesDisplay]);

  useEffect(() => {
    let ticketTypesDisplay = ticketTypes.filter(
      (type) => type.event_type === streamingType.event_type
    );
    if (ticketTypesDisplay && ticketTypesDisplay.length > 0) {
      setSelectedTicket(ticketTypesDisplay[0]);
    }
    setTicketTypesDisplay(ticketTypesDisplay);
  }, [streamingType, event_ticket_type, ticketTypes]);

  useEffect(() => {
    let modifiedEventValues = {};
    if (selectedTicket.max_ticket_price === 0) {
      modifiedEventValues.event_price_ticket = selectedTicket.max_ticket_price;
    }
    if (selectedTicket.max_attendees === 1) {
      modifiedEventValues.event_num_attendees = selectedTicket.max_attendees;
    }
    if (!selectedTicket.discount_code_enabled) {
      modifiedEventValues.event_promotional_code_discount = "";
    }
    setEventValues({
      ...eventValues,
      event_ticket_type: selectedTicket.type_id,
      ...modifiedEventValues,
    });
  }, [selectedTicket]);

  useEffect(() => {
    setGrossRev(event_price_ticket * event_num_attendees);
  }, [event_price_ticket, event_num_attendees]);
  useEffect(() => {
    if (event_currency && event_currency.length > 0) {
      let curr = findCurrencyByCurrencyCode(event_currency, currencies);
      setCurrency(curr);
    }
  }, [event_currency]);
  const onChangePrice = (e) => {
    console.log("ON CHANGE PRICE", e.target.value);
    let eventPrice = e.target.value;
    let priceInt = parseInt(eventPrice);
    if (
      NEW_PUBLISH_PAGE ||
      (!priceInt || priceInt <= selectedTicket.max_ticket_price)
    ) {
      if (!eventPrice.includes(",")) {
        if (e.target.value.includes(".")) {
          eventPrice = e.target.value.slice(0, e.target.value.indexOf(".") + 3);
        }
        setEventValues({ ...eventValues, event_price_ticket: eventPrice });
      }
    } else {
      if (selectedTicket.max_ticket_price) {
        setEventValues({
          ...eventValues,
          event_price_ticket: selectedTicket.max_ticket_price.toString(),
        });
      }
    }
  };

  const onUpdateLanguage = (selected) => {
    let eventLangIdes = selected.map((item) => item.lang_ide);
    setEventValues({ ...eventValues, event_lang_ides: eventLangIdes });
  };
  const onChangeLocationValues = (locationValue) => {
    setEventValues({ ...eventValues, event_location: locationValue });
  };
  const onChangeDatePickerValues = (date) => {
    console.log("onChangeDatePicker");
    updateEventDate(date, eventValues.event_start_hour);
  };
  const onChangeTime = (date, period, e) => {
    const time = getFormattedTime(date);
    if (period === "start") {
      setStartDate(date);
      updateEventDate(dateObj, time);
    } else if (period === "end") {
      setEndDate(date);
      setEventValues({ ...eventValues, event_end_hour: time });
    }
  };
  const updateEventDate = (eventDate, hour) => {
    console.log("UPDATE EVENT DATE ", eventDate, hour);
    if (NEW_PUBLISH_PAGE) {
    }
    if (eventDate && hour.length === 5) {
      setDateObj(eventDate);
      let hourMin = hour.split(":");
      eventDate.setHours(hourMin[0], hourMin[1]);
      //Get month and days in 2 chars
      let month =
        eventDate.getMonth() + 1 > 9
          ? eventDate.getMonth() + 1
          : "0" + (eventDate.getMonth() + 1);
      let day =
        eventDate.getDate() > 9
          ? eventDate.getDate()
          : "0" + eventDate.getDate();
      let dateString =
        eventDate.getFullYear() + "-" + month + "-" + day + " " + hour;
      console.log({ dateString, hour });
      setEventValues({
        ...eventValues,
        event_date: dateString,
        event_start_hour: hour,
      });
    }
  };

  useEffect(() => {}, [loadedImg]);
  const onHandleTicketNumber = (e) => {
    let ticketNumber = parseInt(e.target.value);
    if (!ticketNumber || ticketNumber <= selectedTicket.max_attendees) {
      setEventValues({ ...eventValues, event_num_attendees: e.target.value });
    }
    if (ticketNumber > selectedTicket.max_attendees) {
      setEventValues({
        ...eventValues,
        event_num_attendees: selectedTicket.max_attendees.toString(),
      });
    }
  };

  const onHandleInputChange = (e) => {
    setModalCrop(true);
    handleImageLoad(e);
  };
  const handleEditPhoto = () => {
    if (event_cover_image !== "") {
      setModalCrop(true);
    }
  };

  console.log("user here", user);

  return (
    <>
      <LoadingOverlay
        active={loadingImage}
        spinner
        text={i18next.t("LiveEventInformationLoadingImage") + "..."}
      >
        <>
          <div
            className="PrivateMeeting"
            style={{ paddingTop: !mobileNav ? "2rem" : "0rem" }}
          >
            {modalEventInfo && (
              <ModalEventInfo
                modalEventInfo={modalEventInfo}
                setModalEventInfo={setModalEventInfo}
                massiveBroadcast={massiveBroadcast}
                disabledMassive={
                  !(
                    influencerInfo &&
                    influencerInfo.influencer_massive_streaming
                  )
                }
              />
            )}
            {modalCrop && (
              <ModalCrop
                mobileNav={mobileNav}
                onHandleInputChange={onHandleInputChange}
                setCroppedImageUrl={setCroppedImageUrl}
                handleImageChange={handleImageChange}
                crop={crop}
                setCrop={setCrop}
                loadedImg={loadedImg}
                setModalCrop={setModalCrop}
                modalCrop={modalCrop}
                modalTitle={i18next.t(
                  "LiveEventInformationPlaceholderEditCropper"
                )}
              />
            )}
            <EventPreview
              upcoming={true}
              event_description={event_description}
              event_price={event_price_ticket}
              event_num_attendees={event_num_attendees}
              loadedImg={event_cover_image}
              event_location={""}
              event_title={event_title}
              event_date={event_date}
              event_category={"default"}
              time={"11:15 AM"}
              showPreview={showPreview}
              setShowPreview={setShowPreview}
              imagePlaceHolder={defaultImage}
              user_username={
                user && user.user_username ? user.user_username : ""
              }
              influencer_avatar={
                user && user.user_avatar ? user.user_avatar : ""
              }
            />
            <section className="container publish_section">
              {!initiaLoading ? (
                <div className="row">
                  <div className="col-md-1"></div>
                  <div
                    style={{
                      marginTop: "-1rem",
                      margin: NEW_PUBLISH_PAGE && "auto",
                    }}
                    className={`${
                      NEW_PUBLISH_PAGE
                        ? " col-12 col-md-8 target_request"
                        : "col-12 col-md-6 target_request"
                    }`}
                  >
                    {NEW_PUBLISH_PAGE !== true ||
                      (true && (
                        <>
                          <h2 className="private_want mb-3">
                            {i18next.t("LiveEventInformationCoverImage")}
                          </h2>
                          <p className="small_grey">
                            {i18next.t("LiveEventInformationShowImage")}
                          </p>
                        </>
                      ))}
                    <div className="d-flex align-items-start">
                      <form className="list-fun">
                        <SetCoverImage
                          onHandleInputChange={onHandleInputChange}
                          event_cover_image={event_cover_image}
                          handleEditPhoto={handleEditPhoto}
                          defaultPlaceHolder={defaultImage}
                          mobileNav={mobileNav}
                        />
                        <SetDefaultCoverImages
                          data={thumbnailImages}
                          handlePhoto={(index) => {
                            initDefaultImage(thumbnailImages[index])
                          }}
                          modalVisibility={showImagePreview}
                          setModalVisibility={setShowImagePreview}
                        />

                        {NEW_PUBLISH_PAGE !== true && (
                          <div>
                            <h2 className="mt-3">
                              {i18next.t("LiveEventInformationDescription")}
                            </h2>
                          </div>
                        )}
                        <div
                          style={{ alignItems: "center" }}
                          className="d-flex"
                        >
                          <div
                            style={{
                              alignItems: "center",
                              marginLeft: NEW_PUBLISH_PAGE !== true && "8px",
                              justifyContent: "space-between",
                            }}
                            className={`d-flex ${NEW_PUBLISH_PAGE &&
                              "mt-3"} mb-3`}
                          >
                            {BROADCAST_EVENTS_ENABLED &&
                              (NEW_PUBLISH_PAGE !== true || alternativePublishPage) && (
                                <SelectStreamingType
                                  setModalEventInfo={setModalEventInfo}
                                  onChangeStreamingType={onChangeStreamingType}
                                  influencerInfo={influencerInfo}
                                  streamingTypes={streamingTypes}
                                />
                              )}
                          </div>
                        </div>

                        {NEW_PUBLISH_PAGE !== true && alternativePublishPage !== true && (
                          <div className="d-flex ">
                            <ChooseCategory
                              onChangeEventValues={onChangeEventValues}
                              categories={categories}
                            />
                            <ChooseTicketTypes
                              event_ticket_type={event_ticket_type}
                              onChangeEventValues={onChangeEventValues}
                              ticketTypesDisplay={ticketTypesDisplay}
                              privateMode={privateMode}
                            />
                          </div>
                        )}
                        <SetEventTitle
                          onChangeEventValues={onChangeEventValues}
                          event_title={event_title}
                        />

                        <div className=" mt-3">
                          {true && (
                            <p className="mb-1 small_grey">
                              {i18next.t("LiveEventInformationEventYouHave")}
                              {countCharacters(
                                event_description.length,
                                NEW_PUBLISH_PAGE ? 250 : 1000
                              )}{" "}
                              {i18next.t(
                                "LiveEventInformationEventYouHaveCharsLeft"
                              )}
                            </p>
                          )}
                          <textarea
                            placeholder={i18next.t(
                              "LiveEventInformationPlaceholderEventDescription"
                            )}
                            className={`${NEW_PUBLISH_PAGE &&
                              "mt-1"} textArea textarea_request`}
                            name="event_description"
                            value={event_description}
                            onChange={onChangeEventValues}
                            maxLength={NEW_PUBLISH_PAGE ? 250 : 1000}
                          />

                          {NEW_PUBLISH_PAGE && (
                            <ChooseDateAndLocation
                              setStartPickerOpen={setStartPickerOpen}
                              startPickerOpen={startPickerOpen}
                              startDate={startDate}
                              onChangeLocationValues={onChangeLocationValues}
                              event_location={event_location}
                              dateObj={dateObj}
                              endPickerOpen={endPickerOpen}
                              endPickerRef={endPickerRef}
                              onChangeTime={onChangeTime}
                              onChangeDatePickerValues={
                                onChangeDatePickerValues
                              }
                              startPickerRef={startPickerRef}
                              setEndPickerOpen={setEndPickerOpen}
                              endDate={endDate}
                              publicEvent={publicEvent}
                              setPublicEvent={setPublicEvent}
                              openTicket={openTicket}
                              setOpenTicket={setOpenTicket}
                            />
                          )}
                          {NEW_PUBLISH_PAGE && (
                            <SetTicketDetails
                              selectedTicket={selectedTicket}
                              event_num_attendees={event_num_attendees}
                              onHandleTicketNumber={onHandleTicketNumber}
                              onChangePrice={onChangePrice}
                              currency={currency}
                              eventValues={eventValues}
                            />
                          )}
                          {NEW_PUBLISH_PAGE && false && (
                            <PublicEventOptions
                              publicEvent={publicEvent}
                              setPublicEvent={setPublicEvent}
                              hideOpenTicket={true}
                              openTicket={openTicket}
                              setOpenTicket={setOpenTicket}
                            />
                          )}
                          {NEW_PUBLISH_PAGE && (
                            <div
                              style={{
                                width: "100%",
                                justifyContent: "flex-end",
                              }}
                              className="d-flex "
                            >
                              {true && (
                                <button
                                  disabled={false && event_cover_image === ""}
                                  type="button"
                                  onClick={() => setShowPreview(true)}
                                  style={{
                                    padding: "4px 16px",
                                    backgroundColor: "white",
                                    color: "black",
                                    borderRadius: 25,
                                    border: "1px solid #333",
                                    cursor:
                                      event_cover_image === ""
                                        ? "auto"
                                        : "pointer",
                                  }}
                                  className="mt-3  mr-3 publish send_request"
                                >
                                  {!loading
                                    ? i18next.t("InfluencerPublishSeeEvent")
                                    : i18next.t(
                                        "ModalRecoverPasswordComponentLoading"
                                      ) + "..."}
                                </button>
                              )}

                              <button
                                style={{
                                  padding: "4px 16px",
                                  backgroundColor: "#70ad47",
                                  color: "white",
                                  borderRadius: 25,
                                  border: 0,
                                  cursor: "pointer",
                                }}
                                className="mt-3  publish send_request"
                                onClick={submit}
                              >
                                {!loading
                                  ? i18next.t("InfluencerPublishPublishEvent")
                                  : i18next.t(
                                      "ModalRecoverPasswordComponentLoading"
                                    ) + "..."}
                              </button>
                            </div>
                          )}
                        </div>
                        {NEW_PUBLISH_PAGE !== true && (
                          <>
                            <div className=" mt-3">
                              <p className="mb-2 small_grey">
                                {i18next.t(
                                  "LiveEventInformationChooseLanguages"
                                )}
                              </p>
                              <ChooseLanguage
                                isInfluencer={false}
                                extraStyles={{ height: "4rem" }}
                                languages={languages}
                                onUpdateLanguage={onUpdateLanguage}
                              />
                            </div>
                            <div className=" mt-3">
                              <p className="mb-2 small_grey">
                                {" "}
                                {i18next.t("LiveEventInformationRelatedTags")}
                              </p>
                              <TagsInput
                                addKeys={[188, 9, 13, 32]}
                                value={event_tags}
                                maxTags={5}
                                inputProps={{
                                  placeholder:
                                    event_tags.length === 5
                                      ? i18next.t("LiveEventInformationMaxTags")
                                      : i18next.t(
                                          "LiveEventInformationAddTags"
                                        ),
                                }}
                                onChange={(value) => {
                                  setEventValues({
                                    ...eventValues,
                                    event_tags: value,
                                  });
                                }}
                              />
                            </div>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                  {NEW_PUBLISH_PAGE !== true && (
                    <div className="col-12 col-md-4 mt-md-5 mt-md-1 people-live">
                      {!user.user_email && (
                        <NewInfluencerEmail
                          onChangeConfirmUserEmail={onChangeConfirmUserEmail}
                          onChangeUserEmail={onChangeUserEmail}
                          userEmail={userEmail}
                          confirmUserEmail={confirmUserEmail}
                        />
                      )}
                      <div className="date-time ">
                        <ChooseDateAndLocation
                          setStartPickerOpen={setStartPickerOpen}
                          startPickerOpen={startPickerOpen}
                          startDate={startDate}
                          onChangeLocationValues={onChangeLocationValues}
                          dateObj={dateObj}
                          endPickerOpen={endPickerOpen}
                          endPickerRef={endPickerRef}
                          onChangeTime={onChangeTime}
                          onChangeDatePickerValues={onChangeDatePickerValues}
                          startPickerRef={startPickerRef}
                          setEndPickerOpen={setEndPickerOpen}
                          endDate={endDate}
                        />
                      </div>

                      <div className="tickets_publish mt-5 mt-md-0">
                        <h2 className="private_want meeting_people mt-0 mb-3">
                          {" "}
                          {i18next.t("LiveEventInformationTicketing")}{" "}
                        </h2>
                        <form className="">
                          <SetCurrency
                            onChangeEventValues={onChangeEventValues}
                            selectedTicket={selectedTicket}
                            currencies={currencies}
                          />
                          <SetTicketDetails
                            selectedTicket={selectedTicket}
                            event_num_attendees={event_num_attendees}
                            onHandleTicketNumber={onHandleTicketNumber}
                            onChangePrice={onChangePrice}
                            currency={currency}
                            eventValues={eventValues}
                          />
                        </form>

                        <PublicEventOptions
                          publicEvent={publicEvent}
                          setPublicEvent={setPublicEvent}
                          openTicket={openTicket}
                          setOpenTicket={setOpenTicket}
                        />

                        {NEW_PUBLISH_PAGE !== true&& false && (
                          <ShowExtraPriceInformation
                            event_price_ticket={event_price_ticket}
                            massiveBroadcast={massiveBroadcast}
                            grossRev={grossRev}
                            currency={currency}
                            selectedTicket={selectedTicket}
                          />
                        )}

                        <div
                          style={{
                            display: "flex",
                            marginTop: 12,
                            justifyContent: "flex-start",
                          }}
                        >
                          <Link to={`/my_live_events`}>
                            <button className=" cancel">
                              {i18next.t("InfluencerPublishCancel")}
                            </button>
                          </Link>
                          <button
                            style={{
                              marginLeft: 12,
                              padding: "4px 12px",
                              backgroundColor: "rgb(255, 90, 96)",
                              color: "white",
                              borderRadius: 25,
                              border: 0,
                              cursor: "pointer",
                            }}
                            className=" publish send_request"
                            onClick={submit}
                          >
                            {!loading
                              ? i18next.t("InfluencerPublishMenuPublishEvent")
                              : i18next.t(
                                  "ModalRecoverPasswordComponentLoading"
                                ) + "..."}
                          </button>
                        </div>
                      </div>
                      {false && (
                        <PromoCode
                          setEventValues={setEventValues}
                          eventValues={eventValues}
                          event_promotional_code={event_promotional_code}
                          event_promotional_code_discount={
                            event_promotional_code_discount
                          }
                          verifyRegex={verifyRegex}
                        />
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <section className="container publish_section">
                  <Loader />
                </section>
              )}
            </section>
          </div>
        </>
      </LoadingOverlay>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories.categories,
    currencies: state.currencies.currencies,
    languages: state.languages.languages,
  };
};

export default connect(mapStateToProps)(LiveEventInformation);
