import React, { Component } from 'react';
import './style.scss';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Navbar from "../comunes/navbar";
import Loader from "../comunes/loader";
import useInfluencerTransactionsLogic from "./InfluencerTransactionsLogic";
import moment from "moment";

import calendarIcon from "../../assets/img/icons/CalendarGrey.png";

const MyTransactions = ({ user }) => {

    const { transactions, filterTransactions } = useInfluencerTransactionsLogic({ user });

    return (
        <div>
            <header>
                <Navbar />
            </header>
            <div className="container container_billing" id="billing">
                <div className="header_stats"><div><h2>Transactions</h2></div>
                    <div className="Search-div Search-div-custom search">
                        <i className="fas fa-search"></i>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={event => filterTransactions(event.target.value)} />
                    </div>
                </div>
                {!transactions ? (<Loader />): null}

                {transactions &&  transactions.map(transaction => {
                    
                    return (
                        <div className="margin-top-10">
                            <div>
                                <img src={calendarIcon} className="padding-right-10" />
                                <span className="inline-grid">
                                {transaction && transaction.transaction_date &&   <div>{moment(parseInt(transaction.transaction_date)).format("L")}</div>}
                                    {transaction && transaction.event && <div className="font-weight-700">Ticket bought for event {transaction.event.event_title}</div>}
                                    {transaction && transaction.transaction_ide && <div className="font-weight-700">Transaction ID {transaction.transaction_ide}</div>}
                                    {transaction && transaction.user &&  <div className="font-weight-700">Full Name {transaction.user.user_name}</div>}
                                    {transaction && transaction.amount &&  <div>$ {transaction.amount} USD</div>}
                                </span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(MyTransactions);