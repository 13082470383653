import React, { useState, useEffect } from "react";
import Search_input_peq from "components/comunes/search_input_peq";
import Calendar from "components/comunes/calendar";
import { connect } from "react-redux";
import { EventDAO, UserDAO } from "daos";
import Loader from "components/comunes/loader";
import EventCard from "../../comunes/Cards/EventCard";
import moment from "moment";
import NoEvents from "components/comunes/utils/NoEvents"
import i18next from 'i18next';

i18next.t("FavoriteLiveEventsComponent")
const eventDAO = new EventDAO();
const userDAO = new UserDAO();

const FavoriteLiveEvents = ({ categories }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const initEvents = async () => {
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    const { token } = env;
    if (!token) {
      setLoading(false);
      return;
    }
    try {
      const response = await eventDAO.findMyFavoriteEvents(token);
      const data = response.data.data.map(item => {
        item.event.event_category = categories.find(
          category => category.cate_ide === item.event.event_category_ide
        );
        return item;
      });
      setEvents(data);
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (categories.length > 0) 
    initEvents();
  }, [categories]);

  const onRemoveFavoriteEvent = uuid => {
    const indexEvent = events.findIndex(
      event => event.event.event_uuid === uuid
    );
    const updatedEvents = JSON.parse(JSON.stringify(events));
    updatedEvents.splice(indexEvent, 1);
    setEvents(updatedEvents);
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    userDAO.removeFavoriteEvent(env.token, uuid);
  };

  return (
    <div className="container Upcoming-live-events">
      {!loading ? (
        <>
        <div className="row">
          <div className="col-md-9 d-flex Fila-live-events">
            {events.length > 0 && <div className="d-none d-md-block col-12">
              <h2>{i18next.t("FavoriteLiveEventsComponentFavoriteLiveEvents")}</h2>
            </div>
}
          </div>
          {events.length > 0 && (
            <div className="d-none d-md-block col-md-3">
              
                <Search_input_peq />
                <br />
                <br />
                       
            </div>
          )}

        </div>
        <div className="row mt-4">
          <div className="col-md-12 d-flex Fila-live-events">
            <div className="col-md-8">

            {events.length > 0 &&
              events.map((event, index) => (
                <>
                  <EventCard  extraStyles="col-md-6"  showFavorite={true}  showCountdown={false}
                              key={index}  event={event} 
                               onRemoveFavorite={() =>
                    onRemoveFavoriteEvent(event.event.event_uuid)
                    }
                  />
                </>
              ))}


            </div>
            {/* <div className="col-md-1"></div> */}
            <div className="d-md-block d-none col-md-3">
            <Calendar
                  selectedDates={events.map(event =>
                    moment(parseInt(event.event.event_date)).format(
                      "YYYY-MM-DD"
                    )
                  )}
                />  
            </div>

          </div>  
            
          {!events.length &&
            <div className="row col-md-12">
              <div className="col-md-4"></div>
              <NoEvents message={i18next.t("FavoriteLiveEventsComponentYouHaveNotAddedMyFavouriteEventYet")}/>
              <div className="col-md-1"></div>
              <div className="col-md-3">  <Search_input_peq /></div>
            </div>
          }
          
        </div>
        </>
      ) : (
          <Loader />
        )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  };
};

export default connect(mapStateToProps)(FavoriteLiveEvents);
