import React from 'react'
import i18next from 'i18next';

 const ChooseTicketTypes = ({onChangeEventValues, ticketTypesDisplay , privateMode, event_ticket_type}) => {
    return (
        <div style={{ width: '50%' }} className="ml-2">
        <p className="mb-1 small_grey">{i18next.t('LiveEventInformationSelectEventType')}</p>
        <select className="select_category" value={event_ticket_type} name="event_ticket_type" onChange={onChangeEventValues}>
          {ticketTypesDisplay && ticketTypesDisplay.length > 0 &&
            ticketTypesDisplay.map(ticket => {
              return (
                <option disabled={privateMode} id={ticket.type_id} value={ticket.type_id} key={ticket.type_id}>
                  {ticket.name}
                </option>)}
            )}
        </select>
      </div>
    )
}
export default ChooseTicketTypes