import React, { Component } from 'react';
import CanvasJSReact from '../stats/canvasjs.react/canvasjs.react';
import './App.css';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
class LineChart extends Component {	
	render() {
		const options = {
			width: 1000,
			// title:{
			// 	text: "Chart Title",
			// 	fontColor: "#2f4f4f",
			// 	fontSize: 30,
			// 	padding: 10,
			// 	margin: 30,
			// 	backgroundColor: "#FFFFE0",
			// 	borderThickness: 1,
			// 	cornerRadius: 5,
			// 	fontWeight: "bold",
			// 	horizontalAlign: "left",
			// },
				theme: "light2",
				legend: {
					verticalAlign: "top",
					horizontalAlign: 'left'
				  },	  
				animationEnabled: true,	
				toolTip: {
					shared: true,
					content: "{name}: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {y}",
					backgroundColor: "#2F2D3A",
					fontColor: "white",
					borderThickness: 0,
					cornerRadius: 5 
				},
				
				data: [{
					legendMarkerType: "circle",
					markerBorderColor : "white",
					markerBorderThickness: 2,
					color: '#FF5A60',
					axisYType: "secondary",
					type: "spline",
					name: "Influencers",
					showInLegend: true,
					dataPoints: [
						{ y: 25, label: "6/12" },
						{ y: 22, label: "7/12" },
						{ y: 22, label: "8/12" },
						{ y: 28, label: "9/12" },
						{ y: 22, label: "10/12" },
						{ y: 20, label: "11/12" },
						{ y: 26, label: "12/12" },
					]
				},
				{
					
					color: '#B5B9C2',
					axisYType: "secondary",
					markerBorderColor : "white",
					markerBorderThickness: 2,
					type: "spline",
					name: "Viewers",
					showInLegend: true,
					legendMarkerType: "circle",
					dataPoints: [
						{ y: 22, label: "6/12" },
						{ y: 23, label: "7/12" },
						{ y: 25, label: "8/12" },
						{ y: 22, label: "9/12" },
						{ y: 22, label: "10/12" },
						{ y: 25, label: "11/12" },
						{ y: 22, label: "12/12" },
					]
				}]
		}
		
		return (
		<div className="profile_stats">
			<h2>Viewers Live Event Stats</h2>
			<select className="profile_stats_select">
				<option value="seven_days">Last 7 Days</option>
				<option value="month">Last Month</option>
				<option value="six_month">Last 6 Month</option>
				<option value="year">Last Year</option>
			</select>
			<div className="users_stats"><span>18</span><p>Viewers</p>
			<span>2</span><p>Influencers</p>
			<span>354</span><p>Shared</p>
			</div>
			<CanvasJSChart options = {options} 
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}
}
 
export default LineChart      