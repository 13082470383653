import React from 'react';
// import React, {Component,useState} from 'react';
// import {Link} from "react-router-dom";
import Estadisticas from '../../comunes/graphic';
import Cards from '../../comunes/stats_two';
import LineChart from '../../comunes/graphic_line'
function Overview(props) {

    return (
        <div class="PrivateMeeting">
        {/* <header className="App-header">
            <Navbar/>
        </header> */}
    <section className="container publish_section">
        <Cards/>
       <Estadisticas/>
       <LineChart/>
      </section>
    </div>

    );
}

export default Overview;