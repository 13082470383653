import React from 'react'


const SearchEvent = ({ searchText, setSearchText  }) => {
    return (



      <div style={{opacity:90, display:"flex", justifyContent:"center", alignItems:"center"
    , position:"relative"}} className="">
      {/* <div className="Search-div-peq">         */}
      <i
          className="fas fa-search"
          style={{ cursor: "pointer", position:"absolute", left:10 }}
          onClick={() => {
            // if (text) {
            //   history.push(`/search/${text}`);
            // }
          }}
        ></i>
        <input
        style={{borderRadius:20, padding:"20px 20px 20px 32px"}}
          type="text"
          className="form-control"
          placeholder="Search"
          value={searchText}
          onChange={event => setSearchText(event.target.value)}
        
        />
      </div>
    );
  };
  
  export default (SearchEvent);
  