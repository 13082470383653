import { useEffect, useState } from "react";
import { UserDAO } from "daos";
import { useDispatch } from 'react-redux'
import * as actions from "../../store/actions";

const userDAO = new UserDAO();

const usePayoutLogic = ({user}) => {
  const dispatch = useDispatch()
  const [paypalEmail, setPaypalEmail] = useState('');
  const [tmpPaypalEmail, seTtmpPaypalEmail] = useState('');
  const [errorForm, setErrorForm] = useState("");
  const [showModalPaypal, setShowModalPaypal] = useState(false);
  const handleCloseModalPaypal = () => setShowModalPaypal(false);
  const handleShowModalPaypal = () => setShowModalPaypal(true);

  useEffect(() => {
    if (user.influencer && user.influencer.paypal_payout_email) {
      setPaypalEmail(user.influencer.paypal_payout_email)
    }
  }, [user]);

  const updatePaypalEmail = async () => {
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    const { token } = env;
    let data = { paypal_payout_email: tmpPaypalEmail };

    try {
      await userDAO.updateInfluencer(token, data);
    } catch (err) {
      if (err === undefined) {
        setErrorForm("Sorry, something went wrong.");
        return
      }
      if (err.response.status === 400) {
        setErrorForm(
          err.response.data.error.map(item => item.message).join(", ")
        );
      } else {
        setErrorForm("Sorry, something went wrong.");
      }

      return;
    }
    dispatch(actions.getInfluencer(env.token))
    handleCloseModalPaypal()
  }

  return { paypalEmail, updatePaypalEmail, tmpPaypalEmail, seTtmpPaypalEmail, setPaypalEmail, errorForm, setErrorForm, showModalPaypal, handleCloseModalPaypal, handleShowModalPaypal };

};

export default usePayoutLogic