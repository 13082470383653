import React, { Component } from "react";
import "./style.scss";
import logo from "../../../assets/img/logo-white.png";
import user from "../../../assets/img/user.png";
import Menu from "./menu_influencer";
import Login_menus from "../login_menu_influencer";
import useMobileNav from "../../../hooks/useMobileNav";
import MobileNavInfluencer from "./menu_influencer/mobile/MobileNavInfluencer";
import { Link } from "react-router-dom";
import { B2B_PORT } from "../../../environment/environment";

const NavBarInfluencer = () => {
  const { mobileNav } = useMobileNav();

  if (!mobileNav)
    return (
      <div className="w-100" style={{ zIndex: 9 }}>
        <div className="Nav Bg-blue">
          {!B2B_PORT ? (
            <div className="d-flex">
              <a target="_blank" href="https://blog.gotmy.com/">
                <img className="mr-3 " src={logo} className="Logo" alt="logo" />
              </a>
              <a
                style={{
                  color: "#FF5A60",
                  alignSelf: "flex-end",
                  marginTop: "14px",
                }}
                className="align-bottom"
                target="_blank"
                href="https://blog.gotmy.com/"
                className=""
              >
                <h5>Blog</h5>
              </a>
            </div>
          ) : (
            <div className="d-flex">
              <img className="mr-3 " src={logo} className="Logo" alt="logo" />
            </div>
          )}
          <Menu />
          <Login_menus />
        </div>
      </div>
    );
  else {
    return <MobileNavInfluencer />;
  }
};

export default NavBarInfluencer;
