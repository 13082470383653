import styled from "styled-components"
export const Container = styled.div`

font-family: CircularStd;

@media(max-width:900px){
    position:relative;
}
.Shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  
  }
display:flex;
flex-direction: column;
width: 100%;
margin: 0 auto;
position: absolute;
top:0;
padding:0 46px 0 46px;
@media (max-width:900px){
    padding:0 10px 0 10px;
}
bottom:0;
.referralImg{
    width:575px;
    height:575px;
    margin-bottom:18px;
    @media(min-width:900px) and (max-width:1200px){
        width:450px;
        height:450px;
    }
    @media(max-width:900px){
        width:325px;
        height:325px;
    }
}
.gmLogo{


    width:220px;
    height: 70px;
    
    @media(max-width:900px){
        width:100px;
        height:33px;
    }
}
.title{
    font-size:44px;
    color: #ff5a60;
    font-family: FuturaStd;

    @media(max-width:900px){
        font-size:36px;
    }
}
.subTitle{
    font-family: CircularStd;
    font-size:24px;
    margin-top:-10px;
    margin-bottom:0px;
    color:white;
    @media(max-width:900px){
        font-size:22px;
    }
}
.numberImg{
   
   
    @media(max-width:900px){
        width:60px;
        height:60px;
    }
    @media (min-width:900px) and (max-width:1200px){
        width:65px;
        height:65px;
      
    }
    @media(min-width:1200px){
        width:80px;
        height:80px;
     
    }
}
.stepSubHeader{
    font-family: CircularStd;
    margin-top:-10px;
    font-size:18px;
    color:white;
    max-width:450px;
    @media(max-width:900px){
        font-size:15px;
    }

}
 .stepHeader{
    font-family: FuturaStd;

     margin-top:-6px;
     align-self:center;
     margin-bottom:12px;
     margin-left:12px;
     color: #ff5a60;
     font-weight:700;
     font-size:36px;

 @media(min-width:900px) and (max-width:1200px){
        font-size:32px;
    }
     @media(max-width:900px){
         font-size:28px;
     } }
 .stepNumber{
     font-size:100px;
     font-weight:700;
     color:white;
    @media(min-width:900px) and (max-width:1200px){
        font-size:90px;
    }
     @media(max-width:900px){
         font-size:80px;
     }
 }
.step{
    width:100%;
    margin-top:12px;
    text-align:left;
    display:flex;
    justifyContent:center;
}
`
