import React, {useState, useEffect} from "react";
// import Video from "../past_live_events/card";
import "./style.scss";
import Search_input_peq from "../../comunes/search_input_peq";
import { connect } from "react-redux";
import eventState from "../../../constants/eventState";
import EventCardInfluencer from '../../comunes/Cards/EventCardInfluencer'
import useMobileNav from '../../../hooks/useMobileNav'
import i18next from 'i18next';

const Past_live_events = ({ events, user, influencer }) => {
  const {mobileNav} = useMobileNav()
  const [loading , setLoading] = useState(true)
  const [pastEvents, setPastEvents] = useState([])
  useEffect(()=> {
    if (mobileNav&& true || mobileNav ===false){
      setLoading(false)
    }
  }, [mobileNav])
  useEffect(()=>{
    if (events && events.length>0){
      let filteredEvents = events.filter( ( event)=> event.event_state === eventState.past)
      let sortedEvents  = filteredEvents.sort(sortFirstRecentEvents)
      console.log({sortedEvents})
      setPastEvents( sortedEvents )
    }
  },[events])

  function sortFirstRecentEvents(firstEvent, secondEvent){
    if ( firstEvent.event_date < secondEvent.event_date ){
      return 1;
    }
    if ( firstEvent.event_date > secondEvent.event_date ){
      return -1;
    }
    return 0;
  }


  
  return (
    <div>
      {!loading && 
      <div className="container Past-live-events">
        {!mobileNav && 
        
        <>
        
        <Search_input_peq />
        <h2>{i18next.t("PastLiveEventsMyPast")}</h2>
        
        </>
}
        <div className="row mt-3 mt-md-0">
          {pastEvents && pastEvents.length> 0 && pastEvents
            .filter(({ event_state }) => event_state === eventState.past)
            .map(event => (
                <EventCardInfluencer event={event} user={user} influencer={influencer} cardsPerRow={3} type='past'/>
            ))}

          {!pastEvents && (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                fontSize: "22px"
              }}
            >
              {i18next.t("PastLiveEventsMyPastNoEventsYet")}
            </p>
          )}
        </div>
      </div>
}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    events: state.user.events,
    user: state.user.user,
    influencer: state.user.influencer
  };
};

export default connect(mapStateToProps)(Past_live_events);
