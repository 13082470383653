import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import categoriesReducer from "./store/reducers/categories";
import currenciesReducer from "./store/reducers/currencies";
import languagesReducer from "./store/reducers/languages";
import userReducer from "./store/reducers/user";
import streamingReducer from "./store/reducers/streaming";
import socialMediaVerificator from "./utils/socialMediaVerificator";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

socialMediaVerificator()

const rootReducer = combineReducers({
  currencies: currenciesReducer,
  categories: categoriesReducer,
  languages: languagesReducer,
  user: userReducer,
  streaming: streamingReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <App />

  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

serviceWorker.unregister();
