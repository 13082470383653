import React from 'react';
import './style.scss';

const SimpleLink = ({ href, children, ...props }) => {
  return (
    <li>
      <a href={href}>
        {children}
      </a>
    </li>
  );
};

export default SimpleLink;
