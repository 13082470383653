import React, {Component} from 'react';

import './style.scss';
import map from "../../../assets/img/icons/ic-map.svg";
import logo from '../../../assets/img/logo-white.png';

class EndCat extends Component {
    render() {
        return (
            <p><span className="Hor Hor3">At end 5:30pm</span></p>
        );
    }
}

export default EndCat;
