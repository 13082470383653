import React, { Component } from "react";
import "./style.scss";
import map from "assets/img/icons/ic-map.svg";
import PropTypes from "prop-types";
import Request from "assets/img/icons/ic-requests.svg";
import { Row, Col, Container } from "react-bootstrap";
import i18next from 'i18next';

class Attendees extends Component {


  render() {
    const resourcesVerified = this.props.user && this.props.user.user_avatar;

    if (!resourcesVerified) return null;
    const { onSelectUser, onUnselectUser, selectedUser } = this.props;
    const {
      user: { user_avatar, user_name, user_lastname, user_username, user_location }
    } = this.props;

    

    return (
      <div className="AttendeesInfluencerChoose">
        <Row>
          <React.Fragment>

            <Col
              xl={{ span: 7 }}
              lg={12}
            >
              <Row>
                <Col
                xs={12}
                xl={3}
                >
                  <img className="Foto" src={user_avatar != undefined ? user_avatar : Request} alt="" />
                </Col>
                <Col
                xs={12}
                xl={{ span: 8, offset: 1 }}
                >
                  <div 
                  style={{
                    display:"flex",
                    alignItems:'center'

                  }}
                  className="">
                    <span className="Nombre">
                      {user_name} {user_lastname}
                    </span>
                    {/* <span className="Hor">
                {" "}
                <img src={map} alt="" />
                {user_location}
              </span> */}
                  </div>
                </Col>

              </Row>

            </Col>
            <Col
              xl={{ span: 4, offset: 1 }}
              lg={12}
            >
              <button
                onClick={() => {
                  if (!selectedUser) {
                    onSelectUser(user_username);
                  } else {
                    onUnselectUser(user_username);
                  }
                }}
                className="button_moderator Btn-rojo"
                style={selectedUser ? onlineStyle : {}}
              >
                {selectedUser ? i18next.t("AttendeesInfluencerComponentOnline") : i18next.t("AttendeesInfluencerComponentChoose")}
              </button>
            </Col>
          </React.Fragment>
        </Row>
      </div>
    );
  }
}

Attendees.propTypes = {
  data: PropTypes.array.isRequired,
  onSelectUser: PropTypes.func.isRequired
};

const onlineStyle = {
  background: "#fff",
  color: "black",
  border: "1px solid",
  display: 'none'
};



export default Attendees;
