export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const SET_CURRENCIES = "SET_CURRENCIES";

export const GET_LANGUAGES = "GET_LANGUAGES";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";
export const LOAD_USER = "LOAD_USER";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const GET_INFLUENCER = "GET_INFLUENCER";
export const SET_INFLUENCER = "SET_INFLUENCER";
export const SET_INFLUENCER_STATS = "SET_INFLUENCER_STATS";

// payment 
export const FETCH_PAYMENT_METHODS = 'fetch_payment_methods';

// streaming
export const SET_MODERATOR = "SET_MODERATOR";
export const SET_OPTION_MODERATORS = "SET_OPTION_MODERATORS";
