
export const logger = function () {
    var oldConsoleLog = null;
    var oldConsoleError = null;
    var oldConsoleWarn = null;
    var pub = {};

    pub.enableLogger = function enableLogger() {
        if (oldConsoleLog == null)
            return;

        window['console']['log'] = oldConsoleLog;
    };

    pub.disableLogger = function disableLogger() {
        oldConsoleLog = console.log;
        window['console']['log'] = function () { };
    };

    pub.enableErrorLogger = function enableErrorLogger() {
        if (oldConsoleLog == null)
            return;

        window['console']['error'] = oldConsoleError;
    };

    pub.disableErrorLogger = function disableErrorLogger() {
        oldConsoleLog = console.error;
        window['console']['error'] = function () { };
    };

    pub.enableWarningLogger = function enableWarningLogger() {
        if (oldConsoleLog == null)
            return;

        window['console']['warn'] = oldConsoleWarn;
    };

    pub.disableWarningLogger = function disableWarningLogger() {
        oldConsoleLog = console.error;
        window['console']['warn'] = function () { };
    };

    return pub;
}();