import axios from "axios";
import { Api, SECOND_ACCESS_TOKEN_API } from "./../environment/environment";

let headers = { "Access-Token": SECOND_ACCESS_TOKEN_API };

export default class SearchDAO {
  searchAll(query) {

    const env = JSON.parse(localStorage.getItem("env") || "{}");
    if (env.token) {
      headers["Access-Token"] = env.token;
    }
    return axios.post(`${Api}search/`, { query }, { headers });
  }
}
