import React from "react";
// import React, { Component, useState } from "react";
import "./style.scss";
import Photo from "../../../assets/img/icons/ic-photo-white.svg";
// import { update } from "../../../login";
var avatar;
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    avatar = "";
    this.state = { file: "", imagePreviewUrl: "" };
    this.state = {
      showMe: true
    };
    this.state = {
      hideToolTip: false
    };
  }

  _handleSubmit(e) {
    e.preventDefault();
    
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }

  operation() {
    this.setState({
      showMe: !this.state.showMe
    });
  }
  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} />;
    } else {
      $imagePreview = (
        <div className="previewText">
          <form onSubmit={e => this._handleSubmit(e)}>
            <label className="file_input" for="file-input">
              <img
                onClick={() => this.operation()}
                className="input_label"
                src={Photo}
              />
            </label>
            <input
              className="fileInput"
              id="file-input"
              type="file"
              onChange={e => this._handleImageChange(e)}
            />
          </form>
        </div>
      );
    }
    const style = this.state.hideToolTip ? { display: "none" } : {};
    return (
      <div className="previewComponent">
        <div className="imgPreview">{$imagePreview}</div>
        <form
          noValidate
          onSubmit={(e => this._handleSubmit(e), this.onSubmit)}
          style={style}
        >
          <label
            style={style}
            className="file_input"
            id="file-input-change"
            for="file-input"
          >
            <img className="input_label" id="input_label_change" src={Photo} />
          </label>
          <input
            className="fileInput"
            id="file-input"
            type="file"
            name="photo"
            onChange={e => this._handleImageChange(e)}
          />
        </form>
      </div>
    );
  }
}

export default ImageUpload;

// {/* <div>
//   <icon src={url}>
//     <p>
//       {ger}
//     </p>

//   </icon> */}

// </div>
