import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/comunes/loader";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Rally = () => {
  const query = useQuery();

  useEffect(() => {
    const originParam = query.get("origin");
    const rallyOauthURL = query.get("rallyOAUTH");
    const rallyCode = query.get("code");
    const previousOrigin = localStorage.getItem("rallyOrigin");

    if (previousOrigin && rallyCode) {
      localStorage.removeItem("rallyOrigin");
      window.location.replace(`${previousOrigin}/rally?code=${rallyCode}`);
    } else if (originParam) {
      localStorage.setItem("rallyOrigin", originParam);
      window.location.replace(rallyOauthURL);
    }
  }, [query]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Loader />
    </div>
  );
};

export default Rally;
