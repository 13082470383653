import React, {Component} from 'react';
import './style.scss';
import map from "../../../../assets/img/icons/ic-map.svg";
import usr from "../../../../assets/img/user.png";
import stars from '../../../../assets/img/5-estrellas.png';
import circulo from '../../../../assets/img/logo-circulo.png';
class User_following extends Component {
    render() {
        return (
            <div className="user_bans">
              <div className="d-flex">  
                <div className="d-flex flex-row">
                    <div className = "d-flex flex-column align-items-center ml-4 card_banned">
                    <img className="Foto_ban foto_friend" src={usr} alt=""/>
                    <img src={circulo} className="Icono" alt=""/>
                    <span className="Nombre ">Minnie Lawrence</span>
                    <img className="mt-1" src={stars} alt=""/>
                </div>
                <div className = "d-flex flex-column align-items-center ml-4 card_banned">
                    <img className="Foto_ban foto_friend" src={usr} alt=""/>
                    <img src={circulo} className="Icono" alt=""/>
                    <span className="Nombre">Minnie Lawrence</span>
                    <img className="mt-1" src={stars} alt=""/>
                </div>
                <div className = "d-flex flex-column align-items-center ml-4 card_banned">
                    <img className="Foto_ban foto_friend" src={usr} alt=""/>
                    <img src={circulo} className="Icono" alt=""/>
                    <span className="Nombre">Minnie Lawrence</span>
                   <img className="mt-1" src={stars} alt=""/>
                </div>
              </div>
            </div>
        </div>
        );
    }
}
export default User_following;

