import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'


const useIsUserLoggedIn = ()=>{
    const[isUserLoggedIn, setIsUserLoggedIn] = useState()
    const user = useSelector (state=>state.user)
    useEffect(()=>{
        if (Object.keys(user.user).length>0 ){
            setIsUserLoggedIn(true)
        }
        else {
            setIsUserLoggedIn(false)
        }

    },[user])

    return {isUserLoggedIn}
}

export default useIsUserLoggedIn