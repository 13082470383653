import React from "react";
import "./style.scss";
import logo from "assets/img/logo-circulo-white.png";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled from 'styled-components'

const NavBarProfilePut = ({ onSave, loading, title, isInfluencer }) => {
  return (
    // <div className="FechaCatPublish w-100">
    //   <div
    //     className="FechaCat13 d-flex align-items-center"
    //     style={{ padding: "20px !important" }}
    //   >
    //     <img src={logo} className="Logo5" alt="logo" />
    //     <h2 className="mb-0 title_request">{title}</h2>
    //   </div>
    //   <button
    //     type="submit"
    //     form="form_update"
    //     className="request-streaming send_request"
    //     onClick={onSave}
    //   >
    //     {!loading ? "Save" : "Loading...."}
    //   </button>
    //   <Link to={isInfluencer ? `/my_live_events` : `/`}>
    //     {" "}
    //     <button className="request-streaming cancel">Cancel</button>
    //   </Link>
    // </div>


<Container>
      <div className="FechaCat13 PublishNav d-flex align-items-center">
        <img src={logo} className="Logo5" alt="logo" />
        <h2 className="mb-0 title_request">{title} </h2>
      </div>
      {/* <button className="request-streaming send_request" onClick={saveInDraft}>
        Save
      </button> */}
      <div className="d-flex">
     
      <Link to={isInfluencer ? `/my_live_events` : `/`}>
        {/* <button className="request-streaming cancel">Cancel</button> */}
        <button className=" cancel">Cancel</button>
      </Link>
      <button className=" saveBtn send_request" onClick={onSave}>
        {!loading ? "Save" : "Loading..."}
      </button>
      </div>
    
    </Container>
  );
};

NavBarProfilePut.propTypes = {
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

NavBarProfilePut.defaultProps = {
  title: "Viewer Profile"
};

export default withRouter(NavBarProfilePut);
const Container = styled.div `
 @media(max-width: 768px){
    padding: 10px 16px !important;
  }
padding:17px 32px;
     background: #312F3D;
display:flex;
justify-content: space-between;
width:100%;
align-items:center;
.saveBtn{
  margin-left:8px;
  color:white;
  border-radius: 50px;
    padding: 0px 18px !important;
    font-size: 15px !important;
    border:none;
  background: #ff5a60;
  /* margin-left:1rem; */
  height: 2rem;

}


`