import React , {useState, useEffect} from 'react';

 const Stars = ({score})=>  {

     const [estrellas, setEstrellas] = useState({estre1:"" , estre2:"", estre3:"", estre4:"", estre5:""})

    useEffect(()=>{


        if (!isNaN(score)){
        
        let estre1, estre2, estre3, estre4, estre5
    switch (score) {
      case 0:
        estre1 = require('assets/Rating/Grey.png');
        estre2 = require('assets/Rating/Grey.png');
        estre3 = require('assets/Rating/Grey.png');
        estre4 = require('assets/Rating/Grey.png');
        estre5 = require('assets/Rating/Grey.png');
        break;
      case 1:
        estre1 = require('assets/Rating/Red.png');
        estre2 = require('assets/Rating/Grey.png');
        estre3 = require('assets/Rating/Grey.png');
        estre4 = require('assets/Rating/Grey.png');
        estre5 = require('assets/Rating/Grey.png');
        break;

      case 2:
        estre1 = require('assets/Rating/Red.png');
        estre2 = require('assets/Rating/Red.png');
        estre3 = require('assets/Rating/Grey.png');
        estre4 = require('assets/Rating/Grey.png');
        estre5 = require('assets/Rating/Grey.png');


        break;
      case 3:

        estre1 = require('assets/Rating/Red.png');
        estre2 = require('assets/Rating/Red.png');
        estre3 = require('assets/Rating/Red.png');
        estre4 = require('assets/Rating/Grey.png');
        estre5 = require('assets/Rating/Grey.png');

        break;
      case 4:
        estre1 = require('assets/Rating/Red.png');
        estre2 = require('assets/Rating/Red.png');
        estre3 = require('assets/Rating/Red.png');
        estre4 = require('assets/Rating/Red.png');
        estre5 = require('assets/Rating/Grey.png');

        break;
      case 5:
        estre1 = require('assets/Rating/Red.png');
        estre2 = require('assets/Rating/Red.png');
        estre3 = require('assets/Rating/Red.png');
        estre4 = require('assets/Rating/Red.png');
        estre5 = require('assets/Rating/Red.png');
        break;
    }
    setEstrellas({estre1, estre2, estre3, estre4, estre5})
    console.log ({estre1, estre2, estre3})
        }
    },[score])

 return (

        <div style={{ display:"flex", flexDirection: 'row' }}>
            
          <img src={estrellas.estre1}
            style={{ width: 18, height: 18, }}
          />
          <img src={estrellas.estre2}
            style={{ width: 18, height: 18, marginLeft: 2 }}
          />
          <img src={estrellas.estre3}
            style={{ width: 18, height: 18, marginLeft: 2 }}
          />
          <img src={estrellas.estre4}
            style={{ width: 18, height: 18, marginLeft: 2 }}
          />
          <img src={estrellas.estre5}
            style={{ width: 18, height: 18, marginLeft: 2 }}
          />
        </div>



      );
}

export default Stars