import React from 'react'
import i18next from 'i18next';

const SetCurrency = ({selectedTicket,  currencies,onChangeEventValues}) => {
    return (
        <div className="mb-3" >

            <p className="mb-1 small_grey">{i18next.t('LiveEventInformationCurrency')}</p>
            <select disabled={selectedTicket && selectedTicket.name === "Free"} className="select_category" name="event_currency" onChange={onChangeEventValues}>
                {currencies.map(currency => {
                    return (
                        <option id={currency.currency_ide} value={currency.currency_code} key={currency.currency_code}>
                            {currency.currency_name}
                        </option>
                    )
                }
                )}
            </select>
        </div>
    )
}
export default SetCurrency