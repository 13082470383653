import React, {Component} from 'react';
import usr from '../../../assets/img/user.png';

import './style.scss';
import circulo from "../../../assets/img/logo-circulo.png";
import cinco_estrellas from "../../../assets/img/5-estrellas.png";

class ImagenUsuPuntuacionModerato extends Component {
    render() {
        return (
            <div className="UsuPuntuacion3">

                <div className="Div-foto_moderator">

                    <img className="Foto" src={usr} alt=""/>
                    <p className="Nombre nombre_moderator">Stella Richardson</p>
                    {/* <img src={circulo} className="Icono" alt=""/> */}
                    
                </div>
                <p className="parrafo_moderator">View Moderator</p>
                 {/* <div>
                    
                    <div className="Estrellas" id="Estrella2">
                        <img src={cinco_estrellas} alt="puntuacion"/>
                    </div>
                </div>  */}

            </div>
        );
    }
}

export default ImagenUsuPuntuacionModerato;

