import React from "react";
import logo from "assets/img/gotmy-n.png";
import "./../style.scss";
import InputWithLabel from "./../../../input_with_label";
import { useModalRecoverLogic } from "./ModalRecoverLogic";
import { WithAlert } from "../../../../../hocs/withAlert";
import i18next from "i18next";
import { PRIVATE_MARKETPLACE } from "../../../../../environment/environment";
import styles from "../../../../../assets/css_general/general.scss";

const Modal_recover = ({ showAlert, onCloseClick }) => {
  const { email, setEmail, onSubmit, loading } = useModalRecoverLogic({
    showAlert,
  });

  return (
    <div className="modal Modales-login Modal-recover" id="myModal_recover">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div
              data-dismiss={PRIVATE_MARKETPLACE ? "none" : "modal"}
              style={{
                width: 25,

                height: 25,
                cursor: "pointer",
                position: "absolute",
                top: 20,
                right: 20,
                zIndex: 1,
              }}
              onClick={onCloseClick}
            >
              <svg
                className="svg-icon "
                viewBox="0 0 20 20"
                style={{
                  width: 25,
                  height: 25,
                }}
              >
                <path
                  fill={styles.mainColor}
                  d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
                ></path>
              </svg>
            </div>
            <h6>
              {i18next.t("ModalRecoverPasswordComponentRecoverYourPassoword")}
            </h6>
            <img src={logo} className="Logo" alt="" />
            <InputWithLabel
              label={i18next.t("ModalRecoverPasswordComponentEmailAddress")}
              placeholder={i18next.t(
                "ModalRecoverPasswordComponentEmailAddresPlaceholder"
              )}
              type={"text"}
              id={"email"}
              name={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <button
              className="Btn-rojo mt-1"
              onClick={onSubmit}
              disabled={!email || loading}
            >
              {!loading
                ? i18next.t("ModalRecoverPasswordComponentResetMyPassword")
                : i18next.t("ModalRecoverPasswordComponentLoading") + "..."}
            </button>

            {!PRIVATE_MARKETPLACE && (
              <>
                <p>
                  {i18next.t("ModalRecoverPasswordComponentAreYouRegistered")}
                </p>
                <a
                  data-toggle="modal"
                  data-target="#myModal_sign"
                  data-dismiss="modal"
                  className="Login"
                  href=""
                >
                  {i18next.t("ModalRecoverPasswordComponentSignUp")}
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithAlert(Modal_recover);
