import moment from "moment";
import * as momenttz from 'moment-timezone';

/**
 * Update a object
 * @param {Object} oldObject
 * @param {Object} updatedProperties
 * @returns {Object}
 */
export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

/* adds offset to a number of hours */
export const addHourOffset = (hours, offset) => {
  let offsetHour
  ((hours + offset) < 0) ?
      offsetHour = 24 + (hours + offset)
      :
      ((hours + offset) < 24) ?
          offsetHour = hours + offset
          :
          offsetHour = (hours + offset) - 24
  
  return offsetHour
}
/* Get time str [hr:min] from date, and add an offset of hours to the str if specified

*/
export const getFormattedTime = (hourDate, offset = 0) => {
  
  let hours = hourDate.getHours()
  let minutes = hourDate.getMinutes()
  if (typeof hourDate.getHours === 'function') {
    ((hours + offset) < 0) ?
      hours = 24 + (hours + offset)
      :
      ((hours + offset) < 24) ?
        hours += offset
        :
        hours = (hours + offset) - 24
    const timeComponents = [hours, minutes];
    return timeComponents
      .map(component => {
        const pad = (component < 10) ? '0' : '';
        return pad + component;
      })
      .join(':');
  }
  else return undefined
}
/* Get time str [hr:min] from hour string , and add an offset of hours to the str if specified

*/
export const getFormattedTimeFromStr = (hourStr, offset = 0) => {
  let hourArr = hourStr.split(":")
  let hours = parseInt(hourArr[0])
  let minutes = hourArr[1]
  if (typeof hours ==="number") {
    ((hours + offset) < 0) ?
      hours = 24 + (hours + offset)
      :
      ((hours + offset) < 24) ?
        hours += offset
        :
        hours = (hours + offset) - 24
    const timeComponents = [hours, minutes];
    return timeComponents
      .map(component => {
        const pad = (component < 10) ? '0' : '';
        return pad + component;
      })
      .join(':');
  }
  else return undefined
}

export const getMsFromDateStrAndZonename = (dateStr, zoneName) => {


}
// add hours and mins to event date and return it in milliseconds
export const updateEventDate = (eventDate, hour, timeZone) => {
  let months = (eventDate.getMonth() + 1).length > 1 ? (eventDate.getMonth() + 1).toString() : '0' + (eventDate.getMonth() + 1).toString()
  let days = (eventDate.getDate() + 1).length > 1 ? (eventDate.getDate()).toString() : '0' + (eventDate.getDate()).toString()

  let soloDate = eventDate.getFullYear() + "-" + months + "-" + days;
  soloDate = hour ? soloDate + " " + hour : soloDate
  if (eventDate) {
    let hourMin = hour.split(':')
    eventDate.setHours(hourMin[0], hourMin[1])
    let msDate = momenttz.tz(soloDate, timeZone).format('x')
    
    return msDate
  }
}


/**
 * Returns the difference between two dates in hours.
 * @param {String} date, Date
 * @returns {String}
 */
export const diffWithCurrentDate = value => {
  if (!value) return "";
  const currentDate = parseInt(moment(new Date()).format("hh"));
  const date = parseInt(value.split(":")[0]);
  return currentDate - date;
};

export const countCharacters = (characters, numberToSubtract) =>
  numberToSubtract - parseInt(characters);

/**
 * Avoid writing letters in input.
 * @param {Object} e, Event triggered by the input.
 */
export const avoidLetters = e => {
  const tecla = document.all ? e.keyCode : e.which;
  if (tecla === 13 || tecla === 8) return true;
  const patron = /[1234567890\d]/;
  const te = String.fromCharCode(tecla);
  if (!patron.test(te)) {
    e.preventDefault();
  }
};

/**
 * Avoid writing numbers in input
 * @param {Object} e, Event triggered by the input
 */
export const avoidNumbers = e => {
  const tecla = document.all ? e.keyCode : e.which;

  if (tecla == 13 || tecla == 8) return true;

  const patron = /[A-Za-zÁáÉéÍíÓóÓúÚÜüñÑ\s]/;
  const te = String.fromCharCode(tecla);
  if (!patron.test(te)) {
    e.preventDefault();
  }
};

/**
 * Simple search function
 * @param {String} value, String where coincidence is searched
 * @param {String} query, Value to search
 * @return {Boolean}
 */
export const search = (value, query) =>
  value
    .toLowerCase()
    .split("")
    .filter(
      v =>
        query
          .trim("")
          .split("")
          .indexOf(v) >= 0
    ).length > 2;

/* Verify a regex and prevent default if !match */
export const verifyRegex = (e, patron) => {
  let numb, a
  let decimal = false
  

  const tecla = document.all
    ? e.keyCode
    : e.which;
  // if (tecla === 13 || tecla === 8  || tecla === 46)
  if (tecla === 13 || tecla === 8)
    return true;
  const te = String.fromCharCode(tecla);
  if (e.target.value.includes('.')) {
    a = e.target.value.split('.')
    numb = a[1]
    decimal = true
  }
  
  if ((e.keyCode === 44 || e.which === 44) || (e.which === 46 && (e.target.value.includes('.') || e.target.value.length === 0))) {
    e.preventDefault();
  }

  if ((decimal && numb && numb.length >= 2)) {
    if (!/^(?!^0\.00$)(([1-9][\d]{0,6})|([0]))\.[\d]{2}$/.test(te)) {
      e.preventDefault();
    }
  }

  if (!patron.test(te)) {
    e.preventDefault();
  }


}