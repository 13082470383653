import React from 'react'
import Attendees from "components/comunes_events/attendees";
import i18next from 'i18next';
import { B2B_PORT } from '../../environment/environment';

const AttendeesEvent = ({ eventData}) => { 
    if(B2B_PORT){
        return null;
    }
    return (
        <div className="" style={{ overflowX: "hidden" }}>
        {eventData.attendees_users.length > 0 && (
            <>
                <h6 className="D-title p-0 mb-3 mt-5 ">
                    {eventData.attendees_users.length} {i18next.t('AttendeesEventComponentAttendee')}{eventData.attendees_users.length> 1 && "s"}</h6>
                <div className="Attendes-div relate-div">
                    {eventData.attendees_users.map((item, index) => (
                        <Attendees key={index} user={item} />
                    ))}
                </div>
            </>
        )}
    </div>
    )
}
export default AttendeesEvent