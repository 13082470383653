import React, { useEffect, useState } from "react";
import withClearCache from "./ClearCache";
import MainApp from "./MainApp"

const ClearCacheComponent = withClearCache(MainApp);


function App() {
  if (window.location.host.startsWith("www.")) {
    window.location = window.location.protocol + "//" + window.location.host.replace("www.","") + window.location.pathname;
  }
  
  return <ClearCacheComponent />;
}

export default App;
