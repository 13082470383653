import React from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import PropTypes from "prop-types";
const { Toggle, Collapse } = Accordion;
const { Header, Body } = Card;

const AccordionItem = ({ title, children, eventKey }) => (
  <Card>
    <Header>
      <Toggle
        as={Button}
        variant="link"
        eventKey={eventKey}
        className="accordion_item"
      >
        {title}
      </Toggle>
    </Header>
    <Collapse eventKey={eventKey}>
      <Body>{children}</Body>
    </Collapse>
  </Card>
);

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  eventKey: PropTypes.string.isRequired
};

export { AccordionItem };
