import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import {
  Api,
  LOGIN_BACK_URL,
  ACCESS_TOKEN_API,
  INFLUENCER_ROLE
} from "../../../../environment/environment";
import { withRouter } from "react-router-dom";
import SweetAlert from "sweetalert2-react";
import { connect } from "react-redux";
import * as actions from '../../../../store/actions/user';
import { parseLocale } from 'utils/Localization'
import { UserDAO , EventDAO} from "daos";
const eventDAO = new EventDAO();
const userDAO = new UserDAO();

const VerifyLinkedinLogin = ({ history, setUser, getInfluencer }) => {
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    verifyLinkedin();
  }, []);

  const autoInfluencer = async (token) => {
    try {
      const { data } = await axios.post(
        `${Api}influencer/auto_upgrade`,
        { },
        {headers: { "Access-Token": token, "Content-Type": "application/json" , "Content-Language": parseLocale()
      }}
      );
      localStorage.setItem("autoUpgrade", JSON.stringify({status:true}))
      console.log({ data })
    }
    catch (e){
      console.log(e)
    }
  }
  const verifyLinkedin = async () => {
    const url = new URL(window.location.href.replace("#", ""));
    const code = url.searchParams.get("code");
    const actionLinkedin = localStorage.getItem("LinkedinAction");
    var referral = JSON.parse(localStorage.getItem("referral"))
    var landingTalentToken = JSON.parse(localStorage.getItem("LandingTalent"))

    const referral_code = referral ? referral.id : null

    if (
      !code ||
      (actionLinkedin !== "login" || !actionLinkedin)
    )
      return;

    try {

      let { data } = await axios.post(`${Api}auth/login_linkedin`,
      { code, "redirect_url": LOGIN_BACK_URL, ...(referral_code) && { referral_code } },
      {
        headers: {"Access-Token": ACCESS_TOKEN_API, "Content-Type": "application/json","Content-Language": parseLocale()}
      });
      const env = {
        token: data.data.user_app_token
      }
      if (referral_code || landingTalentToken === true) {
        await autoInfluencer(env.token)
        data.data.user_role = INFLUENCER_ROLE

        localStorage.removeItem("LandingTalent");
      }
      const purchasedEvents = await eventDAO.purchasedEvents(env.token);
      data.data.purchasedEvents= (purchasedEvents.data && purchasedEvents.data.data) || []; 
      setUser(data.data)
      getInfluencer(env.token)
      localStorage.setItem("env", JSON.stringify(env));
      let autoInf = JSON.parse(localStorage.getItem("autoUpgrade"))
      if (autoInf && autoInf.status ===true){
        history.push("/publish_event")
      }
      else if (data.data.user_role === INFLUENCER_ROLE ) {
       history.push('/my_live_events')
     }
      else if ((!data.data.user_name || !data.data.user_lastname) ) {
        history.replace("viewer_profile");
      }
      else {
        history.push(`/`);
      }
    } catch (err) {
      setErrorMessage('LinkedIn login error. Contact support@gotmy.com');
    } finally {
      localStorage.removeItem("LinkedinAction");
    }


  }
  return (
    errorMessage && (
      <SweetAlert
        show={errorMessage}
        title={errorMessage}
        onConfirm={() => {
          setErrorMessage("");
          history.push("/");
        }}
      />
    )
  );
};


const mapDispatchToProps = dispatch => {
  return {
    setUser: data => dispatch(actions.setUser(data)),
    getUser: token => dispatch(actions.getUser(token)),
    getInfluencer: token => dispatch(actions.getInfluencer(token))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(VerifyLinkedinLogin));

