import { useState, useEffect, useCallback } from "react";
import { LanguageDAO, UserDAO, CategoryDAO } from "daos";
import * as yup from "yup";
import moment from "moment";
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { connect , useSelector} from "react-redux";
import * as actions from "../../store/actions";
import axios from 'axios'
toast.configure();

const dateLimit = moment(new Date())
  .subtract(16, "y")
  .format("YYYY-MM-DD");

const initialValuesUser = {
  name: "",
  username: "",
  birthdate: "",
  biography: "",
  country: "0",
  categories: [],
  languages: [],
  avatar: "",
  location: "",
  validated_mobile: false
};

const languagueDAO = new LanguageDAO();
const userDAO = new UserDAO();
const categoryDAO = new CategoryDAO();

const schema = yup.object().shape({
  biography: yup.string().trim().required(),
  location: yup.string().required(),
  languages: yup.array().required(),
});

let auxLanguages = [];

const useBecomeInfluencerLogic = ({ history, match, user }) => {
  const categories = useSelector (state=> state.categories.categories)
  const languages = useSelector (state=> state.languages.languages)
  const [userValues, setUserValues] = useState(initialValuesUser);
  const [countries, setCountries] = useState([]);
  // const [categories, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  // const [languages, setLanguages] = useState([]);
  const [languagesSelected, setLanguagesSelected] = useState([]);
  const [showErrorValidation, setShowErrorValidation] = useState(false);
  const [missingFields, setMissingFields] = useState({message: '', missingFields: ''});
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [loadingImage, setLoadingImage] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [avaliable, setAvaliable] = useState(undefined);
  const [alreadyInfluencer, setAlreadyInfluencer] = useState(false);
  const [showModalCategories, setShowModalCategories] = useState(false);
  const [tabKeySelected, setTabKeySelected] = useState("profile");
  const [loadedImg , setLoadedImg] = useState(false);

  /** Get parameter to detect if you want to be influencer */
  const isInfluencer =
    Object.keys(match.params > 0) && match.params.active ? true : false;
  useEffect(()=>{
    const env = JSON.parse(localStorage.getItem("env") || "{}");

    const { token } = env;

    if (!token) {
      history.push("/");
      return;
    }
    if (Object.keys(user).length >0 && categories && categories.length >0 && languages && languages.length>0){
      initUser()

    }
  },[user, categories, languages])
  // Get the categories and set them in the state.
  const initCategory = async () => {
    try {
      const response = await categoryDAO.findAll();
      const { data } = response.data;
      // setCategory(data);
    } catch (err) {
      throw err;
    }
  };

  // Get the languages and set them in the state.
  // const initLanguages = async () => {
  //   try {
  //     const response = await languagueDAO.findAll();
  //     const { data } = response.data;
  //     auxLanguages = data;
  //     setLanguages(data);
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  // Obtains the information of the logged-in user and sets them in the state.
  const initUser = async () => {
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    const { token } = env;
    const updatedValues = JSON.parse(JSON.stringify(userValues));

    // User not logged in, throw back
    if (!token) {
      history.push("/");
      return;
    }

    try {
      // const response = await userDAO.findByToken(token);
      // const { data } = response.data;
      updatedValues.name = user.user_name;
      updatedValues.lastname = user.user_lastname;
      updatedValues.avatar = user.user_avatar;
      const birthdate = moment.unix(parseInt(user.user_birthdate) / 1000);
      updatedValues.birthdate = birthdate.format("YYYY-MM-DD");
      updatedValues.country = user.user_city;
      updatedValues.username = user.user_username;
      updatedValues.biography = user.user_aboutme;
      updatedValues.validated_mobile = user.user_validated_mobile;
      try {
        updatedValues.location =
          user.user_location && JSON.parse(user.user_location).city;
      } catch (err) {
        
      }
      const categoryUser = await userDAO.findCategories(token);
      const languagesUser = await userDAO.findLanguages(token);
      const langSelected = languagesUser.data.data
      .map(item => {
        const detail = languages.find(
          lang => lang.lang_ide === item.lang_ide
          );
          return detail;
        })
        .filter(lang => lang);
      updatedValues.languages = langSelected.map(lang => lang.lang_ide);
      updatedValues.categories = categoryUser.data.data.map(
        cat => cat.cate_ide
      );
      if (!isInfluencer) {
        setUserValues(updatedValues);
        

      }
      
      setLanguagesSelected(langSelected);

      if (isInfluencer) {
        userDAO
          .profileInfluencer(token)
          .then(data => {
            if (data.data && data.data.influencer) {
              const { influencer } = data.data;
              updatedValues.biography = influencer.influencer_aboutme;
              updatedValues.avatar = influencer.influencer_avatar;
              updatedValues.available = influencer.influencer_receive_private_request
              setAvaliable(updatedValues.available)
              setUserValues(updatedValues);
              
              setAlreadyInfluencer(true);
            }
          })
          .catch(err => {
            
            setUserValues(updatedValues);
          });
      }
    } catch (err) {
      throw err;
    } finally {

      await readValuesLocalStorage(updatedValues);
      setLoadingInitial(false);
    }
  };

  const readValuesLocalStorage = async(userValues) => {
    // const updatedValues = JSON.parse(JSON.stringify(userValues));
    

    let savedValues ={}
    try {
      let biography = localStorage.getItem("biography");
      if (biography) savedValues.biography = biography
    } catch(error) {}

    try {
      let location = JSON.parse(localStorage.getItem("location"));
      if (location) savedValues.location = location

    } catch(error) {}

    try {
      var languages = localStorage.getItem("languages");
      if (languages) {
        var languagesParsed = JSON.parse(languages);
        savedValues.languages = languagesParsed.map(lang => lang.lang_ide);
        setLanguagesSelected(languagesParsed);
      }
    } catch(error) {}

    try {
      var image = localStorage.getItem("image");
      if (image) {
        savedValues.avatar = image;
      }
    } catch(error) {}
    
    setUserValues({...userValues, ...savedValues});
    // setUserValues(updatedValues);
  };

  const handleAvaliable = () => {
    
    setUserValues({...userValues, available:!avaliable})
    setAvaliable(!avaliable);
  }
  // const init = useCallback(async () => {
  //   // await initCountries();
  //   await initLanguages();
  //   await initCategory();
  //   initUser();
  // }, []);

  // useEffect(() => {
  //   init();
  // }, []);

  useEffect(() => {
    if (languages.length > 0) {
      setTimeout(() => {
        if (document.querySelector(".rbt-aux")) {
          document.querySelector(".rbt-aux").style.display = "none";
        }
      }, 2000);
    }
  }, [languages]);

  useEffect(() => {
    if (user) {
      

      const updatedValues = JSON.parse(JSON.stringify(userValues));
      updatedValues.validated_mobile = user.user_validated_mobile;
      
      setUserValues(updatedValues);
    }
  }, [user]);

  /**
   * Handles the form changes and updates the values for the user in the state.
   * @param {Object} event, Event triggered for the form
   */
  const onChangeUserValues = event => {
    const { name, value } = event.target;
    const updatedValues = JSON.parse(JSON.stringify(userValues));
    updatedValues[name] = value;
    setUserValues(updatedValues);

    localStorage.setItem(name, value); // Used when user comes back from validating social network
  };

  const onChangeDatePickerValues = date => {
    const updatedValues = JSON.parse(JSON.stringify(userValues));
    updatedValues['birthdate'] = date;
    setUserValues(updatedValues);
  };
  const onChangeLanguageValues = languages => {
    
    const updatedValues = JSON.parse(JSON.stringify(userValues));
    updatedValues['languages'] = languages;
    

    setUserValues(updatedValues);
  };
  const onChangeLocationValues = location => {
    
    const updatedValues = JSON.parse(JSON.stringify(userValues));
    updatedValues['location'] = location;
    setUserValues(updatedValues);
    localStorage.setItem("location", JSON.stringify(location)); // Used when user comes back from validating social network
  };

  /**
   * Handle changes to the category checkbox
   * @param {Number} value, Category Id
   */
  const onChangeCategories = value => {
    const updatedUserValues = JSON.parse(JSON.stringify(userValues));
    const { categories } = updatedUserValues;
    const index = categories.indexOf(value);

    if (index < 0) {
      categories.push(value);
    } else {
      categories.splice(index, 1);
    }
    updatedUserValues["categories"] = categories;
    setUserValues(updatedUserValues);
  };

  /**
   * Handle image changes.
   * @param {Object} event, Event trigered for the input file
   */
  const handleImageChange = async url => {
    setLoadingImage(true);
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };    
   
    const compresedFile = await imageCompression(
      url,
      options
    );
    const base64 = await imageCompression.getDataUrlFromFile(compresedFile);
    
    const updatedUserValues = JSON.parse(JSON.stringify(userValues));
    updatedUserValues.avatar = base64;

    localStorage.setItem("image", base64); // Used when user comes back from validating social network
    setUserValues(updatedUserValues);
    setLoadingImage(false);
  };

  const handleImageLoad = async (event)=> {
    const reg = new RegExp("(.*?).(jpg|png|jpeg)$");
    if (!reg.test(event.target.value)) {
      toast("Supported formats are JPG, PNG and JPEG!");
      return;
    }
    else {
      setLoadedImg(false)
      setLoadingImage(true);
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };    
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setLoadedImg(reader.result )
        
      }
      );
      reader.readAsDataURL(event.target.files[0]);
      setLoadingImage(false);
    }

  }

  /**
   * Send the information to the API.
   * @param {Object} event
   */
  const onSubmit = async event => {
    event.preventDefault();
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    const { token } = env;
    const valid = await schema.isValid(userValues);

    // Social network validation
    try {
      const response = await userDAO.findByToken(token);
      const user = response.data.data;
      const validateSocialNetworks = hasValidSocialNetworks(user);
      if (!validateSocialNetworks) {
        setMissingFields({message: "At least one social network is required!"})
        setShowErrorValidation(true);
        return;
      }
    } catch (error) {
      setMissingFields({message: "Something went wrong"});
      setShowErrorValidation(true);
      return;
    }

    try{
      await schema.validate (userValues)
    } catch(error) {
      let missingFields = [] 
      schema._nodes.map((node, i)=> {
        if (userValues[node]==='' || userValues[node].length===0){
          missingFields.push(node)
        }
      })
      if (missingFields.length>0 ) {
        setMissingFields({message: error.message})
      }
      else {
        setMissingFields({message: error.message})
      }
      
      setShowErrorValidation(true);
      return;
    }

    setUpdating(true);

    try {
      const data = {
            user_aboutme: userValues.biography,
            user_avatar: userValues.avatar
      };

      try {
        const code = await geocodeByAddress(userValues.location);
        
        const latLng = await getLatLng(code[0]);
        data.user_location = { city: userValues.location, lat: latLng.lat,
          lng: latLng.lng
        };
      } catch (err) {
        
        return;
      }

      const avatar = data.user_avatar || data.influencer_avatar;
      if (!avatar.includes("base64")) {
        delete data.user_avatar;
        delete data.influencer_avatar;
      }

      if (!isInfluencer) {
        try {
          await userDAO.update(token, data);
        } catch (err) {
          return;
        }
      }

      if (!isInfluencer) {
        try {
          await userDAO.requestUpgradeInfluencer(token);
        } catch (err) {
          return;
        }
      }
    
      await userDAO.setLanguages(token, {
        languages: userValues.languages
      });

      // await userDAO.setCategories(token, {
      //   categories: userValues.categories
      // });

      history.push("/profile");

      try {
        localStorage.removeItem("location");
        localStorage.removeItem("image");
        localStorage.removeItem("biography");
        localStorage.removeItem("languages");
        localStorage.removeItem("categories");
      } catch (error) {
        
      }
      window.location.reload();
    } catch (err) {
      // TODO: handler this!
      throw err;
    } finally {
      setUpdating(false);
    }
  };

  return {
    userValues,languagesSelected,onChangeUserValues,onChangeDatePickerValues,
    countries,languages,categories,setLanguagesSelected, onSubmit,showErrorValidation, 
    setShowErrorValidation, missingFields, setMissingFields,onChangeCategories,setUserValues,
    setCountries, handleImageChange, loadingInitial,setLoadingInitial,loadingImage,
    updating, isInfluencer, avaliable,  handleAvaliable,showModalCategories,
    setShowModalCategories,tabKeySelected, setTabKeySelected,  selectedCategory,  setSelectedCategory,
    onChangeLanguageValues,  onChangeLocationValues, handleImageChange, loadedImg, handleImageLoad
  };
};

const hasValidSocialNetworks = user => {
  if (!user.user_twitter_data && !user.user_instagram_data && !user.user_linkedin_data && !user.user_facebook_data) {
    return false;
  }
  return true;
}
export default useBecomeInfluencerLogic

