import React, {Component, useContext} from 'react';
import './style.scss';
import map from "../../../assets/img/icons/ic-map.svg";
import logo from '../../../assets/img/logo-circulo-white.png';
import {Link} from "react-router-dom";
import {LogueadoContext} from "../../../Store";
import { Redirect } from 'react-router-dom';
import {SignContext} from '../../../Sign'
function MenuProfileInfluencer(props) {  
    const [logueado,setLogueado] = useContext(LogueadoContext);
    const [sign, setSign] = useContext(SignContext)
    return ( 
        <div className="FechaCatPublish w-100">
        <div className="FechaCat13 PublishNav d-flex align-items-center">
            <img src={logo} className="Logo5" alt="logo"/>
            <h2 className="mb-0 title_request">My Influencer Profile</h2>
        </div>
        {/* onClick={ setSign(false), () =>setLogueado(true)} */}
        <Link to={`/`}> <button  onClick={() => setSign(false)} className="request-streaming cancel">Cancel</button></Link>
    </div>
    );
}

export default MenuProfileInfluencer;




