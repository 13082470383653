import { useReducer, useCallback, useEffect } from "react";
import reducer from "./reducer";
import { ChatDAO } from "daos";
import { actionTypes } from "./actions";

const chatDAO = new ChatDAO();

export const useMessageInfluencerLogic = () => {
  const { token } = JSON.parse(localStorage.getItem("env"));
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const initChats = useCallback(async () => {
    try {
      const { data } = await chatDAO.findAll(token);
      dispatch({ type: actionTypes.ON_SUCCESS_GET_CHATS, chats: data.data });
    } catch (err) {
      // TODO: get error info
      dispatch({ type: actionTypes.ON_ERROR_GET_CHATS, error: "" });
    }
  }, [token]);

  const onChangeSearchInput = e =>
    dispatch({ type: actionTypes.ON_SET_SEARCH_INPUT, value: e.target.value });

  useEffect(() => {
    initChats();
  }, [initChats]);

  return { state, onChangeSearchInput };
};

const getInitialState = () => ({
  chats: [],
  loadingChats: true,
  errorChat: "",
  searchInput: ""
});
