import React from "react";
import { Modal } from "react-bootstrap";
import logob from "../../assets/img/gotmy-n.png";
import i18next from "i18next";

const ModalCTA = ({
  modalCTA,
  setModalCTA,
  facebookRegister,
  instagramRegister,
  twitterRegister,
  linkedinRegister,
  screenSize,
  inAppBrowse,
  extraText
}) => {
  return (
    <Modal show={modalCTA} onHide={() => setModalCTA(false)}>
      <Modal.Body>
        <div className="text-center d-flex flex-column justify-content-center align-items-center">
          <img src={logob} className=" Logo" alt="logo" />
          {extraText && (<p>{extraText}</p>)}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width:
                  screenSize === "sm" || screenSize === "md" || inAppBrowse
                    ? "300px"
                    : 350,
                backgroundColor: "#3b5998",
                marginBottom: "12px",
                padding: "8px",
                border: "none",
                color: "white",
                borderRadius: "16px",
              }}
              className="Btn-face grow "
              onClick={facebookRegister}
            >
              <i className="fab  fa-facebook"></i>{" "}
              {i18next.t("ReferralSignUpFacebook")}
            </button>
            <button
              className="grow Btn-instagram"
              style={{
                width:
                  screenSize === "sm" || screenSize === "md" || inAppBrowse
                    ? "300px"
                    : "350px",
                marginBottom: "12px",
                padding: "8px",
                border: "none",
                color: "white",
                borderRadius: "16px",
              }}
              onClick={instagramRegister}
            >
              <i className="fab fa-instagram"></i>{" "}
              {i18next.t("ReferralSignUpInstagram")}
            </button>
            <button
              style={{
                width:
                  screenSize === "sm" || screenSize === "md" || inAppBrowse
                    ? "300px"
                    : "350px",
                backgroundColor: "#1da1f2",
                marginBottom: "12px",
                padding: "8px",
                border: "none",
                color: "white",
                borderRadius: "16px",
              }}
              className=" Btn-twitter grow"
              onClick={twitterRegister}
            >
              <i className="fab  fa-twitter"></i>{" "}
              {i18next.t("ReferralSignUpTwitter")}
            </button>
            <button
              style={{
                width:
                  screenSize === "sm" || screenSize === "md" || inAppBrowse
                    ? "300px"
                    : 350,
                backgroundColor: "#0e76a8",
                marginBottom: "12px",
                padding: "8px",
                border: "none",
                color: "white",
                borderRadius: "16px",
              }}
              className="Btn-linkedin grow"
              onClick={linkedinRegister}
            >
              <i className="fab  fa-linkedin"></i>{" "}
              {i18next.t("ReferralSignUpLinkedin")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModalCTA;
