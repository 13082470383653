import React, { lazy, Suspense } from "react";
import i18next from "i18next";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import { openPopupWidget } from "react-calendly";
import FreshChat from "react-freshchat";
import { Link } from "react-router-dom";
/**
 * HOOKS
 */
import { FRESHCHAT_ID_B2B } from "../../environment/environment";
import useMobileNav from "../../hooks/useMobileNav";
/**
 * STYLE
 */
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./B2BLanding.scss";
/**
 * IMAGES
 */
import logoGotmy from "assets/img/logo-white.png";
/**
 * LAZY COMPONENTS
 */
const Section1 = lazy(() => import('./Sections/Section1'));
const Section2 = lazy(() => import('./Sections/Section2'));
const Section3 = lazy(() => import('./Sections/Section3'));
const Section4 = lazy(() => import('./Sections/Section4'));
const Section5 = lazy(() => import('./Sections/Section5'));
const Section6 = lazy(() => import('./Sections/Section6'));

const B2BLanding = () => {
  const { mobileNav } = useMobileNav();

  const openCalendy = () => {
    openPopupWidget({
      url: "https://calendly.com/gotmy/b2b"
    });
  };

  return (
    <section className="b2bLanding">
      <FreshChat token={FRESHCHAT_ID_B2B} onInit={() => { }} />
      <Container fluid>
        <Row
          className="b2bEnrolHeader b2bCenterElements"
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            zIndex: 999
          }}
        >
          <Col
            xs={12}
            className="b2bCenterElements"
          >
            <img className="b2bGotmyIcon" src={logoGotmy} alt="Gotmy logo" loading="lazy" />
          </Col>
        </Row>
        <Suspense fallback={<Spinner animation="border" />}>
          <Section1 mobileNav={mobileNav} onClickKnowMore={openCalendy} />
          <Section2 mobileNav={mobileNav} onClickKnowMore={openCalendy} />
          <Section3 mobileNav={mobileNav} />
          <Section4 mobileNav={mobileNav} />
          <Section5 mobileNav={mobileNav} />
          <Section6 mobileNav={mobileNav} onClickKnowMore={openCalendy} />
        </Suspense>
        <Row className="b2bFooter b2bCenterElements">
          <Col xs={12} className={"footerTerms"}>
            <p
              style={{
                width: "100%",
                textAlign: "center",
                color: "#b3b8c1",
                fontWeight: "300 !important"
              }}
            >
              ©{new Date().getFullYear()}{" "}
              {i18next.t("MobileFooterComponentGotmyCopyright")}
            </p>
            <p
              style={{
                width: "100%",
                textAlign: "center",
                color: "white",
                fontWeight: "300 !important"
              }}
            >
              <Link to="/terms/privacy">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>Aviso de privacidad</a>
              </Link>{" "}
              |{" "}
              <Link to="/terms/service">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>Términos y condiciones</a>
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default B2BLanding;
