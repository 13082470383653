import React, { useState, useEffect } from "react";
import NavBar_search from "../../search_page/search/navbar_search";
import Influencer_search from "../../search_page/search/influencer_search";
import UpcomingLiveEvents from "../../comunes/upcomingLiveEvents";
import Video from "../../comunes/videos_seccion";
import Back from "../../comunes/back";
import Filters_search from "./filters_search";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import i18next from 'i18next';

const SearchByCategory = ({ match, categories }) => {
  const [category, setCategory] = useState({});
  const { id } = match.params;
  const initCategory = () => {
    setCategory(categories.find(cat => cat.cate_ide === parseInt(id)));
  };

  useEffect(() => {
    if (categories.length > 0) initCategory();
  }, [categories, id]);

  const { cate_description } = category;
  return (
    <div className="Search">
      <NavBar_search />

      <div className="container">
        <div className="row mt-4">
        <Back text={i18next.t('SearchByCategoryComponentBackToSearch')} url={"/search"} />
        </div>
      </div>

      <div className="container my-3">
        <h1 className="mt-3 ">{cate_description}</h1>
        <Filters_search />
      </div>

      <Influencer_search
        title={i18next.t('SearchByCategoryComponentTrendingInfluencers')}
        showAll
        categoriesFilter={[id]}
      />

      <UpcomingLiveEvents categoriesFilter={[id]} />

      <Video categoriesFilter={[id]} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  };
};

export default withRouter(connect(mapStateToProps)(SearchByCategory));
