import styled from 'styled-components'

export const PublicProfileContainer = styled.div`

    .textInput {
        padding: 16px;
        /* font-weight: 500 !important; */
        font-size:20px;
    }
    .colProfile{
        @media (max-width:768px){
            border:none;
            padding: 0px;
        }
    border: 1px solid #D8DCE5;
    border-radius:8px;
    margin: 0px auto;
    width: 100%;
    max-width: 900px;
    padding: 30px;
  }
  .biographyTextTags{
      display:flex;
      justify-content:space-between;
  }
  #fileInputIcon{
    position: absolute;
    bottom: 20px;
    right:20px;
  }
    h2 {
        font-weight: 600;
    }
    #inputGroupsTop{
        display:flex;
    }
    #topRightForm {
        margin-top:40px;
        width:100%;
        @media(max-width:768px){
            margin-left:0px;
        }
    }

    .bottomGroup { 

    }

`

export const CategoriesContainer = styled.div``