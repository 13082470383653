import React, {Component} from 'react';

import './style.scss';
import map from "../../../assets/img/icons/ic-map.svg";
import usr from "../../../assets/img/user.png";
import Delete from '../../../assets/img/icons/ic-detele.svg'
import circulo from "../../../assets/img/logo-circulo.png";


class AttendeesParticipant extends Component {
    render() {
        return (
            // <div className="Attendees">
            //   <div className="d-flex">
            //       <img className="Foto" src={usr} alt=""/>
            //             <div className="d-flex flex-column">
            //             <img src={circulo} className="Icono" alt=""/> 
                    
            //         </div>
            //   </div>
            // </div>
            <div className="UsuPuntuacionParticipant">
            <div className="Div-foto">
                <img className="Foto" src={usr} alt=""/>
                {/* <img src={circulo} className="Icono" alt=""/> */}
            </div>
            <div>
                  <span className="Nombre">Breet Cros</span>
                  <span className="Hor_admin ml-1"> <img src={map} alt=""/>Country</span>
            </div>
            <img className="ml-auto mr-3 delete" src={Delete} alt=''></img>
        </div>
        );
    }
}
export default AttendeesParticipant;

