export const JUST_FOR_FUN = 0
export const PARTY_APPEARANCE = 1
export const SHOW = 2
export const PLAYING_GAMES = 3
export const HANGOUT = 4
export const OTHER_REASON_JUST_FOR_FUN = 5
export const BUSINESS = 6
export const MEETING_REQUEST = 7
export const CONSULTANT_REQUEST = 8
export const BUSINESS_PROPOSAL = 9
export const PARTNERSHIP = 10
export const COACHING_TRAINING = 11
export const OTHER_REASON_BUSINESS = 12
export const EDUCATION = 13
export const MASTERCLASS = 14
export const WORKSHOP = 15
export const TUTORIAL = 16
export const TRAINING = 17
export const OTHER_REASON_EDUCATION = 18
export const OTHER_REASON = 19
export const SHARING_SKILLS_OR_EXPERIENCES = 20
export const COACHING_TRAINING_OTHER = 21