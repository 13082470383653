import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import Navbar from "components/comunes/navbar_request";
import { useForm } from "react-hook-form";
import Attendees from "components/comunes_events/attendees_admin";
import useInfluencerData from '../../hooks/useInfluencerData'
import useTimezone from '../../hooks/useTimezone'
import SelectMeetingType from 'components/comunes_events/privateRequestForm/SelectMeetingType'
import SelectMeetingDetails from 'components/comunes_events/privateRequestForm/SelectMeetingDetails'
import { reasons } from 'utils/requestUtils'
import { updateEventDate, getFormattedTime } from "utils";
import {  EventRequestDAO } from "daos";
import { WithAlert } from "../../hocs/withAlert";
import { useSelector } from 'react-redux'
import * as momenttz from 'moment-timezone'; 
import i18next from 'i18next';

const eventRequestDAO = new EventRequestDAO();

const RequestPrivate = ({ match, showAlert, history }) => {
    const userData = useSelector (state=> state.user.user )
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const {timeZone} = useTimezone()

    const { params: { influencerId} } = match
    const { register, control, handleSubmit, errors, setError , getValues } = useForm();
    const { influencerData: {influencer, user}, initialLoading } = useInfluencerData(influencerId)
    const [mainReason, setMainReason] = useState(reasons[1]);
    const [selectedOption, setSelectedOption] = useState();
    const onSubmit = async (data) => {
        
        let utcDate, dateString, endTimeStr, startTimeStr
        // TODO: check that begin date > now  a.getTime(). If not set error .
        // TODO: request ticket type fix
        if (data.ReactDatepicker){
             dateString = getDatesInStrings(data.ReactDatepicker, data.TimePickerStart? data.TimePickerStart : undefined)
             utcDate = momenttz.tz(dateString, timeZone).valueOf().toString();
             
        }
        if (data.TimePickerEnd && typeof data.TimePickerEnd.getMonth ==='function') endTimeStr = getFormattedTime(data.TimePickerEnd, 0 )
        if (data.TimePickerStart && typeof data.TimePickerStart.getMonth ==='function') startTimeStr = getFormattedTime(data.TimePickerStart, 0 )
        const dataSent = {
            influencer_ide: influencer.influencer_ide,
            request_type: (selectedOption &&  Object.keys(selectedOption).length>0) ? selectedOption.optionId:  mainReason.request_type,
            request_reason: data.event_description,
            request_purpose: i18next.t("RequestPrivateComponentPrivateRequestTitle"),
            request_num_attendees: 1,
            request_price_ticket: parseInt(data.price),
            request_ticket_type: parseInt(data.price)>0? 11 : 11, 
            // request_currency: parseInt(data.price)>0? data.event_currency: "",
            request_currency: data.event_currency,
            ...data.event_start_hour && { request_start_hour:  data.event_start_hour} ,
            ...(startTimeStr && startTimeStr.length>0) && { request_start_hour:  startTimeStr} ,
            ...(endTimeStr && endTimeStr.length>0) && {request_end_hour: endTimeStr },
            ...(utcDate && utcDate.length>0 ) && {request_date:  utcDate},
          };

          try{
            
              const { token } = JSON.parse(localStorage.getItem("env"));
              await eventRequestDAO.create(dataSent, token);
              showAlert(i18next.t("RequestPrivateComponentRequestSent"));
              history.push('/message/private_request')
          }
          catch(e){
              showAlert(i18next.t("RequestPrivateComponentErrorSendingRequest"))
          }
    }

    const getDatesInStrings = (eventDate, startTime) => {
        if (eventDate  ) {
        let startTimeStr=  startTime ? getFormattedTime(startTime, 0) : '12:00'
        
          let hourMin = startTimeStr.split(':')
          
          eventDate.setHours(hourMin[0], hourMin[1])
          //Get month and days in 2 chars
          let month = (eventDate.getMonth() + 1) > 9 ? (eventDate.getMonth() + 1) : '0' + (eventDate.getMonth() + 1)
          let day = (eventDate.getDate()) > 9 ? (eventDate.getDate()) : '0' + (eventDate.getDate())
          let dateString = eventDate.getFullYear() + "-" + (month) + "-" + day + ' ' + startTimeStr;
          return(  dateString   );
        }
      }
    return (
        <div class="PrivateMeeting">
            <form>
                <header className="App-header">
                    <Navbar onSend={handleSubmit (onSubmit)} />

                </header>
                <section className="container">
                    <div className="d-flex justify-content-center" style={{ paddingTop: '36px', width: '100%' }}>
                        
                        <div className="col-4 target_request">
                            {
                            userData && influencer  && user.user_name &&
                                <Attendees name={user.user_name + " " + user.user_lastname} location={user.user_location && JSON.parse(user.user_location).city}
                                    avatar={influencer.influencer_avatar} />
                            }
                            <SelectMeetingType editing={false}
                                selectedOption={selectedOption} setSelectedOption={setSelectedOption}
                                register={register}
                                mainReason={mainReason} setMainReason={setMainReason} />

                        </div>
                        <div style={{ marginLeft: '6rem' }} className="  col-5 p-2 target_request">
                        <SelectMeetingDetails startTime= {startTime} setStartTime={setStartTime}
                                endTime={endTime} setEndTime={setEndTime} errors={errors} getValues={getValues} control={control} register={register} />                              
                        </div>
                    </div>

                </section>
            </form>
        </div>
    )
}


export default withRouter(WithAlert(RequestPrivate))
