import React, { Component } from 'react';
import './style.scss';
import Tablebilling from '../comunes/table_billing';
import Navbar from "../comunes/navbar_influencer";
import Search from '../comunes/search_input_peq';
class Billing extends Component {
  render() {
    
    return (
    <div>
        <header>
            <Navbar/>
        </header>
        
            <div className="container container_billing" id="billing">
            <div className="d-flex header_stats"><h2>Billing</h2><Search/>   </div>
                <div className="d-flex">
                    <select className="select_events_stats" id="select_stats_event">
                        <option value="grapefruit">All invoice types</option>
                        <option value="lime">Show event private</option>
                        <option value="coconut">Show public event</option>
                    </select>
                    <select className="select_events_stats">
                        <option value="grapefruit">Order by date</option>
                        <option value="lime">Order by </option>
                    </select>
                
                </div>
                <Tablebilling/>
            </div>
    </div>
    );
  }
}
export default Billing;