import React, { useState, useEffect } from "react";
import "./styles.scss";
import NavBarStreaming from "../comunes_events/cat-fecha-city-pre";
import StopCallIcon from "assets/img/icons/hangCall.png";
import CameraDisabled from "assets/img/icons/ic-camera-off.svg";
import CameraEnabled from "assets/img/icons/ic-camera-on-white.svg";
import Exit from "assets/img/icons/exit.svg";
import logo from "assets/img/icons/reloadLogo.png";
import logoCirculo from "assets/img/icons/reloadLogo.png";
import Request from "assets/img/icons/ic-requests.svg";
import OpenMicEnabled from "assets/img/icons/ic-openModeWhite.svg";
import MicroDisabled from "assets/img/icons/ic-streaming-mic-off.svg";
import MicroEnabled from "assets/img/icons/ic-streaming-mic-white.svg";
import chatBubble from "assets/img/icons/chat-bubble.png";
import { withRouter, Link, Redirect } from "react-router-dom";
import { usePreviewLogic } from "./InteractiveStreamingLogic";
import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import iconUser from "assets/img/icons/user-grey.svg";
import logoGotmy from "assets/img/logo.png";
import * as actions from "../../store/actions";
import AttendeesModerator from "components/comunes_events/attendees_moderator";
import UserModerator from "components/comunes_events/attendees_influencer";
import ExitModerator from "assets/img/icons/ic-detele.svg";
import UserVideoComponent from "./utils-streaming/UserVideoComponent";
import StreamingOptions from "./utils-streaming/StreamingOptions";
import InstructionRow from "./components/InstructionRow";
import ButtonManager from "./components/ButtonManager";
import { Spinner } from "react-bootstrap";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Modal from "react-modal";
import { Modal as ModalBoostrap, Button } from "react-bootstrap";
import warning from "assets/img/icons/warning.svg";
import SweetAlert from "sweetalert2-react";
import PuntUsu from "../comunes_events/imagen_usu_puntuacion_streaming";
import StarRatingComponent from "react-star-rating-component";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Detector } from "react-detect-offline";
import ReactTooltip from "react-tooltip";
import { withOrientationChange } from "react-device-detect";
import i18next from "i18next";
import { isMobile } from "react-device-detect";
import compose from "recompose/compose";
import Chat from "./components/chat/Chat";
const maxCharacters = 200;

const InteractiveStreamingUI = ({
  match,
  user,
  influencer,
  history,
  onSetOptionsModerators,
  isLandscape,
  isPortrait,
}) => {
  const {
    eventData,
    session,
    minimisedChat,
    setMinimisedChat,
    startStreaming,
    chats,
    viewersConnected,
    isRemoteApp,
    message,
    setMessage,
    onSendMessage,
    onLeaveLiveEvent,
    disconnectEvent,
    microphone,
    webcam,
    enabledChat,
    onSetMicrophone,
    onSetWebcam,
    startingStreaming,
    onDeleteMessage,
    onBlockedChat,
    onSetEnableChat,
    enableRiseHand,
    onSetEnableRiseHand,
    showChooseRaiseHand,
    onStopRemoteCall,
    onSetEnableOpenMic,
    enabledOpenMic,
    setShowChooseRaiseHand,
    viewersWaiting,
    givePermissionsViewerStreaming,
    onRiseHandCanceled,
    sendingMessage,
    mainStreaming,
    myPublisher,
    userNameRaiseHand,
    resourcesLoaded,
    mySpeaking_viewer,
    subscribers,
    shareScreenSource,
    stopShareScreen,
    isSharingScreen,
    shareScreen,
    activeUsers,
    setSessionError,
    sessionError,
    sessionConnected,
    sessionLoading,
    sessionReset,
    isCreator,
    hasTurn,
    viewerPublisher,
    onSetMicrophoneViewer,
    onSetWebcamViewer,
    onSetRiseHand,
    microphoneViewer,
    webcamViewer,
    risedHand,
    feedBackText,
    setFeedBackText,
    onSendFeedback,
    feedBackScore,
    setFeedBackScore,
    streamingFinished,
    renderRedirect,
    setRenderRedirect,
    showDisconnectDialog,
    setShowDisconnectDialog,
    onLeaveEventStandard,
    disabledStartStreaming,
    getMediaDevices,
    selectVideoAndAudioDevice,
    availableDevices,
    currentVideoDevice,
    currentAudioDevice,
    currentOutputAudioDevice,
    selectStreamingDevices,
    toggleStreamingOptionsModal,
    isChangingSettings,
    reconnectSession,
    showConnectionRetrying,
    reconnectCountDownLength,
    reconnectCountdownComplete,
    retryingAttempt,
    setNetworkStatus,
    networkStatus,
    isReconnecting,
    audioTracks,
    publications,
    remotePublications,
    influencerConnected,
    screenShareTracks,
    currentDominantSpeaker,
    removeLocalAudioTrack,
    getLocalAudioTracks,
    composeAlertToast,
  } = usePreviewLogic({
    user,
    influencer,
    history,
    uuid: match.params.id,
    onSetOptionsModerators,
  });

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const [backgroundAnimation, setBackgroundAnimation] = useState(
    "initializeVideoDescriptionBackground"
  );
  const [streamingInitiated, setStreamingInitiated] = useState(false);
  const [instructionsVisibility, setInstructionsVisibility] = useState(true);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [sessionReady, setSessionReady] = useState(false);
  const [showViewersModal, setShowViewersModal] = useState(false);
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [showButtons, setShowButtons] = useState(isMobile ? false : true);
  const handle = useFullScreenHandle();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    console.log("Orientation", isLandscape, isPortrait);
  }, [isLandscape, isPortrait]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    if (eventData && eventData.influencer && user && user.user_ide) {
      setIsInfluencer(
        eventData.influencer.influencer_user_ide == user.user_ide
      );
    }
  }, [eventData, user]);

  useEffect(() => {
    if (session && !sessionReady) {
      if (!sessionError) {
        setSessionReady(true);
      }

      if (sessionConnected && !sessionReady) {
        setSessionReady(true);
      }
    }
  }, [sessionConnected]);

  useEffect(() => {
    if (session && !sessionReady) {
      if (sessionError) {
        setSessionReady(false);
      }
    }
  }, [sessionError]);

  useEffect(() => {
    if (streamingInitiated && !sessionLoading && !sessionConnected) {
      setBackgroundAnimation("startingStreamingVideoDescriptionBackground");
      setStreamingInitiated(false);
    }
  }, [sessionLoading]);

  useEffect(() => {
    if (sessionReset) {
      setInstructionsVisibility(true);
      setStreamingInitiated(false);
      setBackgroundAnimation("initializeVideoDescriptionBackground");
    }
  }, [sessionReset]);

  const startStreamingAction = async () => {
    if (!disabledStartStreaming) {
      var result = await startStreaming();

      if (result) {
        setBackgroundAnimation("startingStreamingVideoDescriptionBackground");
        setStreamingInitiated(true);
      }
    }
  };

  const submitMessage = () => {
    if (message.length <= maxCharacters && message && message.trim()) {
      onSendMessage();
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      submitMessage();
    }
  };

  const toggleViewerModal = () => {
    setShowViewersModal(!showViewersModal);
  };

  const renderConfirmationCloseEvent = () => {
    if (isInfluencer) {
      return (
        <ModalBoostrap
          show={showDisconnectDialog}
          onHide={() => {
            setShowDisconnectDialog(false);
          }}
        >
          <ModalBoostrap.Header closeButton>
            <ModalBoostrap.Title>
              {i18next.t(
                "PreviewStreamingInfluencerComponentDoYouWantToFinishTheEvent"
              )}
            </ModalBoostrap.Title>
          </ModalBoostrap.Header>

          <ModalBoostrap.Body>
            <p>
              {i18next.t(
                "PreviewStreamingInfluencerComponentDoYouWantToFinishTheEventBody"
              )}
            </p>
          </ModalBoostrap.Body>

          <ModalBoostrap.Footer>
            <Button
              onClick={() => {
                onLeaveLiveEvent();
              }}
              className="normalConfirmationButton"
            >
              {i18next.t("PreviewStreamingInfluencerComponentYes")}
            </Button>
            <Button
              onClick={() => {
                onLeaveEventStandard();
              }}
              className="specialConfirmationButton"
            >
              {i18next.t(
                "PreviewStreamingInfluencerComponentDisconnectNotFinishingEvent"
              )}
            </Button>
            <Button
              onClick={() => {
                setShowDisconnectDialog(false);
              }}
              className="normalConfirmationButton"
            >
              {i18next.t("PreviewStreamingInfluencerComponentNo")}
            </Button>
          </ModalBoostrap.Footer>
        </ModalBoostrap>
      );
    } else {
      return (
        <ModalBoostrap
          show={showDisconnectDialog}
          onHide={() => {
            setShowDisconnectDialog(false);
          }}
        >
          <ModalBoostrap.Header closeButton>
            <ModalBoostrap.Title>
              {i18next.t(
                "PreviewStreamingInfluencerComponentDoYouWantToGoOutFromTheEvent"
              )}
            </ModalBoostrap.Title>
          </ModalBoostrap.Header>

          <ModalBoostrap.Body>
            <p>
              {i18next.t(
                "PreviewStreamingInfluencerComponentDoYouWantToGoOutFromTheEventBody"
              )}
            </p>
          </ModalBoostrap.Body>

          <ModalBoostrap.Footer>
            <Button
              onClick={() => {
                onLeaveEventStandard();
              }}
              className="normalConfirmationButton"
            >
              {i18next.t("PreviewStreamingInfluencerComponentYes")}
            </Button>
            <Button
              onClick={() => {
                setShowDisconnectDialog(false);
              }}
              className="normalConfirmationButton"
            >
              {i18next.t("PreviewStreamingInfluencerComponentNo")}
            </Button>
          </ModalBoostrap.Footer>
        </ModalBoostrap>
      );
    }
  };

  const renderTime = (remainingTime) => {
    if (remainingTime === 0) {
      return (
        <div className="timer-reconnecting">
          <p>Reconnecting</p>
          <Spinner
            style={{
              width: 50,
              height: 50,
            }}
            animation="grow"
            variant="danger"
          />
        </div>
      );
    }

    return (
      <div className="timer">
        <div
          className="text"
          style={{
            color: "black",
          }}
        >
          {i18next.t("PreviewStreamingInfluencerComponentRetryingIn")}
        </div>
        <div className="value">{remainingTime}</div>
        <div
          className="text"
          style={{
            color: "black",
          }}
        >
          {i18next.t("PreviewStreamingInfluencerComponentSeconds")}
        </div>
      </div>
    );
  };

  const renderRetryingConnecton = () => {
    return (
      <ModalBoostrap
        id="retryConnectionModal"
        centered
        show={showConnectionRetrying || !networkStatus.status}
      >
        {networkStatus.status ? (
          <ModalBoostrap.Body>
            <Row>
              <Col
                style={{
                  textAlign: "center",
                }}
              >
                <p>
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentConnectionProblemsMessage"
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CountdownCircleTimer
                  key={retryingAttempt}
                  isPlaying
                  duration={reconnectCountDownLength}
                  colors={[["#ff5a60", 1]]}
                  onComplete={() => {
                    reconnectCountdownComplete();
                  }}
                >
                  {({ remainingTime }) => {
                    return renderTime(remainingTime);
                  }}
                </CountdownCircleTimer>
              </Col>
              <Col></Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "1.5vh",
                }}
              >
                <Button
                  onClick={() => {
                    disconnectEvent();
                  }}
                  className="normalConfirmationButton"
                >
                  {i18next.t("PreviewStreamingInfluencerComponentDisconnect")}
                </Button>
              </Col>
            </Row>
          </ModalBoostrap.Body>
        ) : (
          <ModalBoostrap.Body>
            <Row>
              <Col
                style={{
                  textAlign: "center",
                }}
              >
                <p>
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentInternetConnectionLost"
                  )}
                </p>
                <p>
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentInternetConnectionLostBody"
                  )}
                </p>
                <p>
                  <span style={{ color: "#ff5a60", fontWeight: "bold" }}>
                    gotmy
                  </span>{" "}
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentInternetConnectionLostBodyReconnect"
                  )}
                </p>
                <Spinner
                  style={{
                    width: 70,
                    height: 70,
                  }}
                  animation="grow"
                  variant="danger"
                />
              </Col>
            </Row>
          </ModalBoostrap.Body>
        )}
      </ModalBoostrap>
    );
  };

  const renderStreamFinishedModal = () => {
    var eventDataParsed = null;

    try {
      const {
        event: { event_title, event_start_hour, event_location, event_date },
        influencer: { influencer_avatar },
        user: { user_username },
      } = eventData;

      eventDataParsed = eventData;
    } catch {}

    if (!isInfluencer && eventDataParsed) {
      const {
        event: { event_title, event_start_hour, event_location, event_date },
        influencer: { influencer_avatar },
        user: { user_username },
      } = eventData;

      return (
        <ModalBoostrap show={streamingFinished}>
          <ModalBoostrap.Body>
            <div className="modal-body modal_rating_body">
              <div className="d-flex flex-column align-items-center">
                <div className="Div-g w-100">
                  <PuntUsu
                    username={user_username}
                    avatar={influencer_avatar}
                  />
                  <div className="d-flex align-items-center flex-column">
                    <h2
                      className="mb-1"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {i18next.t(
                        "PreviewStreamingInfluencerComponentThanksForAttending"
                      )}
                    </h2>
                    <h3>
                      {i18next.t(
                        "PreviewStreamingInfluencerComponentWriteAReview"
                      )}
                    </h3>
                    <div class="aling-item-center">
                      <StarRatingComponent
                        name="feedback"
                        value={feedBackScore}
                        starCount={5}
                        onStarClick={(nextValue) => setFeedBackScore(nextValue)}
                      />
                    </div>
                    <div class="form-group w-70 ml-3">
                      <textarea
                        placeholder={i18next.t(
                          "PreviewStreamingInfluencerComponentReviewPlaceholder"
                        )}
                        class="form-control message"
                        rows="5"
                        id="comment"
                        value={feedBackText}
                        onChange={(e) => setFeedBackText(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="Div-m d-flex m-auto justify-content-center w-100">
                  <div>
                    <button
                      className="Btn-rojo mt-4 feedback"
                      onClick={() => {
                        onSendFeedback();
                      }}
                    >
                      {i18next.t(
                        "PreviewStreamingInfluencerComponentSendFeedback"
                      )}
                    </button>
                  </div>
                </div>
                <div className="Div-m d-flex m-auto justify-content-center w-100">
                  <div>
                    <button
                      className="Btn-rojo mt-4 feedback"
                      onClick={() => {
                        setRenderRedirect(true);
                      }}
                    >
                      {i18next.t("PreviewStreamingInfluencerComponentSkip")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBoostrap.Body>
        </ModalBoostrap>
      );
    } else {
      return null;
    }
  };

  const renderChatAndViewersQueue = () => {
    if (minimisedChat !== true) {
      return (
        <Chat
          isInfluencer={isInfluencer}
          showChooseRaiseHand={showChooseRaiseHand}
          minimisedChat={minimisedChat}
          setMinimisedChat={setMinimisedChat}
          setShowChooseRaiseHand={setShowChooseRaiseHand}
          enabledChat={enabledChat}
          userNameRaiseHand={userNameRaiseHand}
          chats={chats}
          enableRiseHand={enableRiseHand}
          submitMessage={submitMessage}
          sendingMessage={sendingMessage}
          onEnterPress={onEnterPress}
          message={message}
          setMessage={setMessage}
          viewersWaiting={viewersWaiting}
          givePermissionsViewerStreaming={givePermissionsViewerStreaming}
          onRiseHandCanceled={onRiseHandCanceled}
          session={session}
          isMobile={isMobile}
          isLandscape={isLandscape}
          showMobileInput={!showButtons && !showViewersModal}
        />
      );
    }

    // else {
    //   return (
    //     <Col
    //       lg={{ size: 3 }}
    //       md={12}
    //       className="special_border_radius chatContainer animate__animated animate__fadeIn"
    //       sm="12"
    //       style={{
    //         backgroundColor: "#252233",
    //         color: 'white',
    //       }}
    //     >
    //       <h2 className="mb-0 rojo" style={{ textAlign: 'center', paddingTop: '5%', paddingBottom: '5%' }}>{i18next.t('PreviewStreamingInfluencerComponentLiveChat')}</h2>
    //       <div
    //         id="messageContainerViewer"
    //         style={{
    //           overflowY: "auto",
    //           overflowX: "hidden",
    //           marginTop: "3%",
    //           marginBottom: "1.5%"
    //         }}
    //       >
    //         <ul
    //           className="list-unstyled chatSize"
    //           style={{
    //             wordBreak: 'break-word'
    //           }}
    //         >
    //           {enabledChat && chats.map(({ avatar, username, message, uuid }) => (
    //             <li className="list_chat_details_streaming" key={uuid}>
    //               <Row>
    //                 <Col xs={{ span: 2, offset: 1 }} md={{ span: 2, offset: 1 }} lg={{ span: 1, offset: 1 }}>
    //                   <img className="messageUserAvatar"
    //                     style={{borderRadius: "50%"}}
    //                     src={avatar}
    //                     alt=""
    //                   />
    //                 </Col>
    //                 <Col
    //                   xs={{ span: 8 }}
    //                   md={{ span: 6, offset: 1 }}
    //                   lg={{ span: 8 }}
    //                 >
    //                   <p
    //                     className="rojo forceBold"
    //                     style={{
    //                       fontSize: "17px",
    //                       marginBottom: '0.25rem'
    //                     }}
    //                   >
    //                     {username}
    //                   </p>
    //                   <p style={{}}>{message}</p>
    //                 </Col>
    //               </Row>
    //             </li>
    //           ))}

    //           {!enabledChat && (<h3
    //             className='chatDisabled'
    //           >{i18next.t('PreviewStreamingInfluencerComponentChatTemporarilyDisabled')}</h3>)}
    //         </ul>
    //       </div>
    //       <Row
    //         className="col-md-12 animate__animated animate__fadeInUp chatInput"
    //       >
    //         <Col xs="12">
    //           <textarea className="form-control message_chat" rows="5"
    //                     disabled={!session || !enabledChat} value={message}
    //             onKeyDown={onEnterPress}
    //             onChange={event => {
    //               setMessage(event.target.value)
    //             }}
    //             style={{ width: "100% !important", right: 0 }}
    //           />
    //           <button
    //             className="Btn-rojo mt-4 send_influencer"
    //             onClick={() => {
    //               submitMessage()
    //             }}
    //             disabled={!session || sendingMessage}
    //             style={{ left: "0px !important" }}
    //           >
    //             {i18next.t('PreviewStreamingInfluencerComponentSend')}
    //                   </button>
    //           <p className="caracteres_chat_influencer"
    //             style={{
    //               color: message.length > maxCharacters ? "red" : "white"
    //             }}
    //           >{message.length}/{maxCharacters}</p>
    //         </Col>
    //       </Row>
    //     </Col>

    //   )
    // }
  };

  const renderButtonManager = () => {
    if (isInfluencer) {
      return (
        <Row style={{ marginTop: "10px" }}>
          <Col
            className={`animate__animated ${
              session ? "animate__fadeIn" : "d-none"
            }`}
            xs={minimisedChat ? "12" : "8"}
            style={{
              padding: "0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginLeft: 18 }}>
                <img src={iconUser} alt="" />
              </div>
              {session && (
                <div
                  style={{ color: "grey", fontWeight: "bold" }}
                  className="viewerModalButton"
                  data-tip={i18next.t(
                    "PreviewStreamingInfluencerComponentTooltipShowOnlineViewersPanel"
                  )}
                  onClick={toggleViewerModal}
                >
                  {activeUsers}{" "}
                  {i18next.t("PreviewStreamingInfluencerComponentViewers")}
                  <Modal
                    className="viewerModal"
                    isOpen={showViewersModal}
                    onRequestClose={() => {
                      setShowViewersModal(false);
                    }}
                    style={{}}
                  >
                    <Row className="centerContent">
                      <Col xs={{ span: 12 }}>
                        <h1 className="rojo">
                          {i18next.t(
                            "PreviewStreamingInfluencerComponentViewersConnected"
                          )}
                        </h1>
                      </Col>
                    </Row>

                    <Row className="viewerList">
                      {viewersConnected.map((viewer, i) => {
                        if (viewer) {
                          return (
                            <div
                              className={` ${
                                isMobile
                                  ? "mobileCenterContentViewerItem"
                                  : "centerContentViewerItem"
                              }`}
                              key={i}
                            >
                              <img
                                className={` ${
                                  isMobile
                                    ? "mobileViewerItemAvatar"
                                    : "viewerItemAvatar"
                                }`}
                                style={{
                                  borderRadius: "50%",
                                }}
                                src={viewer.avatar}
                                alt=""
                              />
                              <p
                                className="rojo forceBold"
                                style={{
                                  fontSize: "20px",
                                }}
                              >
                                {viewer.username === user.user_username
                                  ? i18next.t(
                                      "PreviewStreamingInfluencerComponentYou"
                                    )
                                  : viewer.username}
                              </p>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Row>

                    <Row className="centerContent absoluteBottomModal">
                      <Col xs={{ span: 12 }}>
                        <button
                          className="Btn-rojo mt-4"
                          onClick={null /*toggleViewerModal*/}
                        >
                          {i18next.t(
                            "PreviewStreamingInfluencerComponentClose"
                          )}
                        </button>
                      </Col>
                    </Row>
                  </Modal>
                </div>
              )}
            </div>
            {false && (
              <div
                style={{ display: "flex", marginRight: minimisedChat && 60 }}
              >
                {session && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <ReactTooltip
                      globalEventOff="click"
                      eventOff="click"
                      place="bottom"
                      type="dark"
                      effect="solid"
                    />
                    <div
                      data-tip={
                        microphone
                          ? i18next.t(
                              "PreviewStreamingInfluencerComponentTooltipDisableMicrophone"
                            )
                          : i18next.t(
                              "PreviewStreamingInfluencerComponentTooltipEnableMicrophone"
                            )
                      }
                      data-event-off="click"
                      className="mic-off"
                      onClick={() => {
                        ReactTooltip.hide();
                        onSetMicrophone();
                      }}
                      style={{
                        backgroundColor: microphone ? "#ff5a60" : "#666666",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        justifyItems: "center",
                      }}
                    >
                      <img
                        onClick={onSetMicrophone}
                        style={{
                          width: 30,
                          height: 30,
                        }}
                        src={microphone ? MicroEnabled : MicroDisabled}
                        alt=""
                      />
                    </div>
                    <div
                      data-tip={
                        webcam
                          ? i18next.t(
                              "PreviewStreamingInfluencerComponentTooltipDisableCamera"
                            )
                          : i18next.t(
                              "PreviewStreamingInfluencerComponentTooltipEnableCamera"
                            )
                      }
                      className="vid-off"
                      onClick={() => {
                        ReactTooltip.hide();
                        onSetWebcam();
                      }}
                      style={{
                        backgroundColor: webcam ? "#ff5a60" : "#666666",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        justifyItems: "center",
                      }}
                    >
                      <img
                        style={{
                          width: 30,
                          height: 30,
                        }}
                        src={webcam ? CameraEnabled : CameraDisabled}
                        alt=""
                      />
                    </div>
                  </div>
                )}

                {session && (
                  <div
                    data-tip={
                      enableRiseHand
                        ? i18next.t(
                            "PreviewStreamingInfluencerComponentTooltipDisableRaiseHand"
                          )
                        : i18next.t(
                            "PreviewStreamingInfluencerComponentTooltipEnableRaiseHand"
                          )
                    }
                    className="riseHandButton"
                    onClick={() => {
                      ReactTooltip.hide();
                      onSetEnableRiseHand();
                    }}
                    style={{
                      backgroundColor: enableRiseHand ? "#ff5a60" : "#666666",
                    }}
                  >
                    <img
                      style={{
                        width: 30,
                      }}
                      src={enableRiseHand ? logoCirculo : logo}
                      alt=""
                    />
                  </div>
                )}
                {session && mySpeaking_viewer && (
                  <div
                    data-tip={i18next.t(
                      "PreviewStreamingInfluencerComponentTooltipDisableRaiseHandStopViewersTurn"
                    )}
                    className="logo-stopCall"
                    onClick={() => {
                      ReactTooltip.hide();
                      onStopRemoteCall();
                    }}
                  >
                    <img
                      src={StopCallIcon}
                      style={{
                        width: 25,
                        height: 25,
                      }}
                      alt=""
                    />
                  </div>
                )}
                {session && (
                  <div
                    data-tip={
                      enabledOpenMic
                        ? i18next.t(
                            "PreviewStreamingInfluencerComponentTooltipDisableOpenMic"
                          )
                        : i18next.t(
                            "PreviewStreamingInfluencerComponentTooltipEnableOpenMic"
                          )
                    }
                    className="logo-off"
                    onClick={() => {
                      ReactTooltip.hide();
                      onSetEnableOpenMic();
                    }}
                    style={{
                      backgroundColor: enabledOpenMic ? "#ff5a60" : "#666666",
                    }}
                  >
                    <img
                      src={!enabledOpenMic ? OpenMicEnabled : OpenMicEnabled}
                      alt=""
                    />
                  </div>
                )}

                {session && (
                  <div
                    data-tip={
                      enabledChat
                        ? i18next.t(
                            "PreviewStreamingInfluencerComponentTooltipDisableChat"
                          )
                        : i18next.t(
                            "PreviewStreamingInfluencerComponentTooltipEnableChat"
                          )
                    }
                    className="riseHandButton"
                    onClick={() => {
                      ReactTooltip.hide();
                      onSetEnableChat();
                    }}
                    style={{
                      backgroundColor: enabledChat ? "#ff5a60" : "#666666",
                    }}
                  >
                    <img
                      style={{
                        width: 22,
                        height: 22,
                      }}
                      src={chatBubble}
                      alt=""
                    />
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      );
    } else {
      return (
        <Row style={{ marginTop: "10px" }}>
          <Col
            className={`animate__animated ${
              session ? "animate__fadeIn" : "d-none"
            }`}
            xs="8"
            style={{
              padding: "0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <img src={iconUser} alt="" />
              </div>
              {session && (
                <div
                  style={{ color: "grey", fontWeight: "bold" }}
                  className="viewerModalButton"
                  data-tip={i18next.t(
                    "PreviewStreamingInfluencerComponentTooltipShowOnlineViewersPanel"
                  )}
                  onClick={toggleViewerModal}
                >
                  <ReactTooltip
                    globalEventOff="click"
                    eventOff="click"
                    place="bottom"
                    type="dark"
                    effect="solid"
                  />
                  {activeUsers}{" "}
                  {i18next.t("PreviewStreamingInfluencerComponentViewers")}
                  <Modal
                    className="viewerModal"
                    isOpen={showViewersModal}
                    onRequestClose={() => {
                      setShowViewersModal(false);
                    }}
                    style={{}}
                  >
                    <Row className="centerContent">
                      <Col xs={{ span: 12 }}>
                        <h1 className="rojo">
                          {i18next.t(
                            "PreviewStreamingInfluencerComponentViewersConnected"
                          )}
                        </h1>
                      </Col>
                    </Row>

                    <Row className="viewerList">
                      {viewersConnected.map((viewer, i) => {
                        if (viewer) {
                          return (
                            <div
                              className={` ${
                                isMobile
                                  ? "mobileCenterContentViewerItem"
                                  : "centerContentViewerItem"
                              }`}
                              key={i}
                            >
                              <img
                                className={` ${
                                  isMobile
                                    ? "mobileViewerItemAvatar"
                                    : "viewerItemAvatar"
                                }`}
                                style={{
                                  borderRadius: "50%",
                                }}
                                src={viewer.avatar}
                                alt=""
                              />
                              <p
                                className="rojo forceBold"
                                style={{
                                  fontSize: "20px",
                                }}
                              >
                                {viewer.username === user.user_username
                                  ? i18next.t(
                                      "PreviewStreamingInfluencerComponentYou"
                                    )
                                  : viewer.username}
                              </p>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Row>

                    <Row className="centerContent absoluteBottomModal">
                      <Col xs={{ span: 12 }}>
                        <button
                          className="Btn-rojo mt-4"
                          onClick={toggleViewerModal}
                        >
                          {i18next.t(
                            "PreviewStreamingInfluencerComponentClose"
                          )}
                        </button>
                      </Col>
                    </Row>
                  </Modal>
                </div>
              )}
            </div>

            {false && (
              <div style={{ display: "flex" }}>
                {session && (hasTurn || enabledOpenMic) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      className="mic-off"
                      data-tip={
                        microphone
                          ? i18next.t(
                              "PreviewStreamingInfluencerComponentTooltipDisableMicrophone"
                            )
                          : i18next.t(
                              "PreviewStreamingInfluencerComponentTooltipEnableMicrophone"
                            )
                      }
                      onClick={() => {
                        ReactTooltip.hide();
                        onSetMicrophone();
                      }}
                      style={{
                        backgroundColor: microphone ? "#ff5a60" : "#666666",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        justifyItems: "center",
                      }}
                    >
                      <img
                        style={{
                          width: 30,
                          height: 30,
                        }}
                        src={microphone ? MicroEnabled : MicroDisabled}
                        alt=""
                      />
                    </div>
                  </div>
                )}

                {session && hasTurn && (
                  <div
                    className="vid-off"
                    data-tip={
                      webcam
                        ? i18next.t(
                            "PreviewStreamingInfluencerComponentTooltipDisableCamera"
                          )
                        : i18next.t(
                            "PreviewStreamingInfluencerComponentTooltipEnableCamera"
                          )
                    }
                    onClick={() => {
                      ReactTooltip.hide();
                      onSetWebcam();
                    }}
                    style={{
                      backgroundColor: webcam ? "#ff5a60" : "#666666",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      justifyItems: "center",
                    }}
                  >
                    <img
                      style={{
                        width: 30,
                        height: 30,
                      }}
                      src={webcam ? CameraEnabled : CameraDisabled}
                      alt=""
                    />
                  </div>
                )}
                {session && enableRiseHand && !hasTurn && (
                  <div
                    className="riseHandButton"
                    data-tip={
                      !risedHand
                        ? i18next.t(
                            "PreviewStreamingInfluencerComponentTooltipRaiseUpTheHand"
                          )
                        : i18next.t(
                            "PreviewStreamingInfluencerComponentTooltipRaiseDownTheHand"
                          )
                    }
                    onClick={() => {
                      ReactTooltip.hide();
                      onSetRiseHand();
                    }}
                    style={{
                      backgroundColor: risedHand ? "#ff5a60" : "#666666",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      justifyItems: "center",
                    }}
                  >
                    <img
                      style={{
                        width: 30,
                        height: 30,
                      }}
                      src={risedHand ? logoCirculo : logo}
                      alt=""
                    />
                  </div>
                )}
                {enabledOpenMic && (
                  <div
                    className="openModeIndicator"
                    data-tip={i18next.t(
                      "PreviewStreamingInfluencerComponentTooltipOpenModeIsEnabled"
                    )}
                    style={{
                      backgroundColor: "#ff5a60",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      justifyItems: "center",
                    }}
                  >
                    <img
                      style={{
                        width: 30,
                        height: 30,
                      }}
                      src={OpenMicEnabled}
                      alt=""
                    />
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      );
    }
  };

  const renderStreamingInstructions = () => {
    if (!instructionsVisibility) {
      return null;
    }

    if (disabledStartStreaming) {
      return (
        <div
          className={
            streamingInitiated
              ? "animate__animated animate__fadeOut vertical-center"
              : "vertical-center"
          }
          onAnimationEnd={() => {
            if (streamingInitiated) {
              setInstructionsVisibility(false);
            }
          }}
        >
          <Row className="align-items-center ">
            <Col
              className={
                streamingInitiated
                  ? "animate__animated animate__fadeOut"
                  : "animate__animated animate__fadeInLeft"
              }
              md="12"
              lg="12"
            >
              <div style={{ textAlign: "center" }}>
                <h2 className="white mb-2 welcome_live ">
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentWeAreSorryButWeCanNotStartStreaming"
                  )}
                </h2>
                <h4 className="white font-size">
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentWeAreSorryButWeCanNotStartStreamingBody"
                  )}
                </h4>
              </div>
              <div
                style={{ textAlign: "center" }}
                className="d-none d-lg-block"
              ></div>
            </Col>
          </Row>
        </div>
      );
    }

    if (user.user_ide == eventData.influencer.influencer_user_ide) {
      //Introduction layout
      return (
        <div
          className={
            streamingInitiated
              ? "animate__animated animate__fadeOut vertical-center"
              : "vertical-center"
          }
          onAnimationEnd={() => {
            if (streamingInitiated) {
              setInstructionsVisibility(false);
            }
          }}
        >
          <Row className="align-items-center ">
            <Col
              className={
                streamingInitiated
                  ? "animate__animated animate__fadeOut"
                  : "animate__animated animate__fadeInLeft"
              }
              md="12"
              lg="6"
            >
              <div style={{ textAlign: "center" }}>
                <h2 className="white mb-2 welcome_live ">
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentWelcomeMessage"
                  )}
                </h2>
                <h4 className="white font-size">
                  {i18next.t("PreviewStreamingInfluencerComponentInstructions")}
                </h4>
              </div>
              <div
                style={{ textAlign: "center" }}
                className="d-none d-lg-block"
              >
                <button
                  className="Btn-rojo mt-4 feedback start_button_width"
                  onClick={() => {
                    startStreamingAction();
                  }}
                >
                  {!startingStreaming
                    ? i18next.t(
                        "PreviewStreamingInfluencerComponentStartStreaming"
                      )
                    : i18next.t(
                        "PreviewStreamingInfluencerComponentStartingStreaming"
                      )}
                </button>
              </div>
            </Col>
            <Col
              className={
                streamingInitiated
                  ? "animate__animated animate__fadeOut"
                  : "animate__animated animate__fadeInRight"
              }
              md="12"
              lg={{ span: 5, offset: 1 }}
            >
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentOpenMode"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentOpenModeDescription"
                )}
                iconSrc={OpenMicEnabled}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentRaiseHand"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentRaiseHandDescription"
                )}
                iconSrc={logo}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentStopTurn"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentStopTurnDescription"
                )}
                iconSrc={StopCallIcon}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentCameraOnOff"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentCameraOnOffDescription"
                )}
                iconSrc={CameraDisabled}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentMicrophoneOnOff"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentMicrophoneOnOffDescription"
                )}
                iconSrc={MicroDisabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12" className="d-lg-none">
              <div style={{ textAlign: "center" }}>
                <button
                  className="Btn-rojo mt-4 feedback start_button"
                  onClick={() => {
                    startStreamingAction();
                  }}
                >
                  {!startingStreaming
                    ? i18next.t(
                        "PreviewStreamingInfluencerComponentStartStreaming"
                      )
                    : i18next.t(
                        "PreviewStreamingInfluencerComponentStartingStreaming"
                      )}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      );
    } else {
      //Introduction layout
      return (
        <div
          className={
            streamingInitiated
              ? "animate__animated animate__fadeOut vertical-center"
              : "vertical-center"
          }
          onAnimationEnd={() => {
            if (streamingInitiated) {
              setInstructionsVisibility(false);
            }
          }}
        >
          <Row className="align-items-center ">
            <Col
              className={
                streamingInitiated
                  ? "animate__animated animate__fadeOut"
                  : "animate__animated animate__fadeInLeft"
              }
              md="12"
              lg="6"
            >
              <div style={{ textAlign: "center" }}>
                <h2 className="white mb-2 welcome_live ">
                  {i18next.t("PreviewStreamingInfluencerComponentWelcomeTo")}{" "}
                  {eventData.event.event_title}
                </h2>
                <h4 className="white font-size">
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentInstructionsViewer"
                  )}
                </h4>
              </div>
              <div
                style={{ textAlign: "center" }}
                className="d-none d-lg-block"
              >
                <button
                  className="Btn-rojo mt-4 feedback start_button_width"
                  onClick={() => {
                    startStreamingAction();
                  }}
                >
                  {!startingStreaming
                    ? i18next.t(
                        "PreviewStreamingInfluencerComponentJoinStreaming"
                      )
                    : i18next.t(
                        "PreviewStreamingInfluencerComponentJoiningStreaming"
                      )}
                </button>
              </div>
            </Col>
            <Col
              className={
                streamingInitiated
                  ? "animate__animated animate__fadeOut"
                  : "animate__animated animate__fadeInRight"
              }
              md="12"
              lg={{ span: 5, offset: 1 }}
            >
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentOpenMode"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentOpenModeDescriptionViewer"
                )}
                iconSrc={OpenMicEnabled}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentRaiseHand"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentRaiseHanddDescriptionViewer"
                )}
                iconSrc={logo}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentCameraOnOff"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentCameraOnOffDescription"
                )}
                iconSrc={CameraDisabled}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentMicrophoneOnOff"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentMicrophoneOnOffDescription"
                )}
                iconSrc={MicroDisabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12" className="d-lg-none">
              <div style={{ textAlign: "center" }}>
                <button
                  className="Btn-rojo mt-4 feedback start_button"
                  onClick={() => {
                    startStreamingAction();
                  }}
                >
                  {!startingStreaming
                    ? i18next.t(
                        "PreviewStreamingInfluencerComponentJoinStreaming"
                      )
                    : i18next.t(
                        "PreviewStreamingInfluencerComponentJoiningStreaming"
                      )}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  };

  const renderMobileInstructions = () => {
    if (session) {
      return null;
    }

    if (disabledStartStreaming) {
      return (
        <div
          className={
            streamingInitiated ? "animate__animated animate__fadeOut" : ""
          }
          onAnimationEnd={() => {
            if (streamingInitiated) {
              setInstructionsVisibility(false);
            }
          }}
        >
          <Row className="align-items-center ">
            <Col
              className={
                streamingInitiated
                  ? "animate__animated animate__fadeOut"
                  : "animate__animated animate__fadeInLeft"
              }
              md="12"
              lg="12"
            >
              <div style={{ textAlign: "center" }}>
                <h2 className="white mb-2 welcome_live ">
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentWeAreSorryButWeCanNotStartStreaming"
                  )}
                </h2>
                <h4 className="white font-size">
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentWeAreSorryButWeCanNotStartStreamingBody"
                  )}
                </h4>
              </div>
              <div
                style={{ textAlign: "center" }}
                className="d-none d-lg-block"
              ></div>
            </Col>
          </Row>
        </div>
      );
    }

    if (user.user_ide == eventData.influencer.influencer_user_ide) {
      //Introduction layout
      return (
        <div
          className={
            streamingInitiated
              ? "animate__animated animate__fadeOut mobileInstructions"
              : " mobileInstructions"
          }
          onAnimationEnd={() => {
            if (streamingInitiated) {
              setInstructionsVisibility(false);
            }
          }}
        >
          <Row className="align-items-center mobileInstructionsList">
            <Col
              className={
                streamingInitiated
                  ? "animate__animated animate__fadeOut"
                  : "animate__animated animate__fadeInLeft"
              }
              md="12"
              lg="6"
            >
              <div style={{ textAlign: "center" }}>
                <h2 className="white mb-2 welcome_live ">
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentWelcomeMessage"
                  )}
                </h2>
                <h4 className="white font-size">
                  {i18next.t("PreviewStreamingInfluencerComponentInstructions")}
                </h4>
              </div>
              <div
                style={{ textAlign: "center" }}
                className="d-none d-lg-block"
              >
                <button
                  className="Btn-rojo mt-4 feedback start_button_width"
                  onClick={() => {
                    startStreamingAction();
                  }}
                >
                  {!startingStreaming
                    ? i18next.t(
                        "PreviewStreamingInfluencerComponentStartStreaming"
                      )
                    : i18next.t(
                        "PreviewStreamingInfluencerComponentStartingStreaming"
                      )}
                </button>
              </div>
            </Col>
            <Col
              className={
                streamingInitiated
                  ? "animate__animated animate__fadeOut"
                  : "animate__animated animate__fadeInRight"
              }
              md="12"
              lg={{ span: 5, offset: 1 }}
            >
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentOpenMode"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentOpenModeDescription"
                )}
                iconSrc={OpenMicEnabled}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentRaiseHand"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentRaiseHandDescription"
                )}
                iconSrc={logo}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentStopTurn"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentStopTurnDescription"
                )}
                iconSrc={StopCallIcon}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentCameraOnOff"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentCameraOnOffDescription"
                )}
                iconSrc={CameraDisabled}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentMicrophoneOnOff"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentMicrophoneOnOffDescription"
                )}
                iconSrc={MicroDisabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12" className="d-lg-none">
              <div style={{ textAlign: "center" }}>
                <button
                  className="Btn-rojo mt-4 feedback start_button"
                  onClick={() => {
                    startStreamingAction();
                  }}
                >
                  {!startingStreaming
                    ? i18next.t(
                        "PreviewStreamingInfluencerComponentStartStreaming"
                      )
                    : i18next.t(
                        "PreviewStreamingInfluencerComponentStartingStreaming"
                      )}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      );
    } else {
      //Introduction layout
      return (
        <div
          className={
            streamingInitiated
              ? "animate__animated animate__fadeOut  mobileInstructions"
              : " mobileInstructions"
          }
          onAnimationEnd={() => {
            if (streamingInitiated) {
              setInstructionsVisibility(false);
            }
          }}
        >
          <Row className="align-items-center mobileInstructionsList">
            <Col
              className={
                streamingInitiated
                  ? "animate__animated animate__fadeOut"
                  : "animate__animated animate__fadeInLeft"
              }
              md="12"
              lg="6"
            >
              <div style={{ textAlign: "center" }}>
                <h2 className="white mb-2 welcome_live ">
                  {i18next.t("PreviewStreamingInfluencerComponentWelcomeTo")}{" "}
                  {eventData.event.event_title}
                </h2>
                <h4 className="white font-size">
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentInstructionsViewer"
                  )}
                </h4>
              </div>
              <div
                style={{ textAlign: "center" }}
                className="d-none d-lg-block"
              >
                <button
                  className="Btn-rojo mt-4 feedback start_button_width"
                  onClick={() => {
                    startStreamingAction();
                  }}
                >
                  {!startingStreaming
                    ? i18next.t(
                        "PreviewStreamingInfluencerComponentJoinStreaming"
                      )
                    : i18next.t(
                        "PreviewStreamingInfluencerComponentJoiningStreaming"
                      )}
                </button>
              </div>
            </Col>
            <Col
              className={
                streamingInitiated
                  ? "animate__animated animate__fadeOut"
                  : "animate__animated animate__fadeInRight"
              }
              md="12"
              lg={{ span: 5, offset: 1 }}
            >
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentOpenMode"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentOpenModeDescriptionViewer"
                )}
                iconSrc={OpenMicEnabled}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentRaiseHand"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentRaiseHanddDescriptionViewer"
                )}
                iconSrc={logo}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentCameraOnOff"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentCameraOnOffDescription"
                )}
                iconSrc={CameraDisabled}
              />
              <InstructionRow
                optionTitle={i18next.t(
                  "PreviewStreamingInfluencerComponentMicrophoneOnOff"
                )}
                description={i18next.t(
                  "PreviewStreamingInfluencerComponentMicrophoneOnOffDescription"
                )}
                iconSrc={MicroDisabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12" className="d-lg-none">
              <div style={{ textAlign: "center" }}>
                <button
                  className="Btn-rojo mt-4 feedback start_button"
                  onClick={() => {
                    startStreamingAction();
                  }}
                >
                  {!startingStreaming
                    ? i18next.t(
                        "PreviewStreamingInfluencerComponentJoinStreaming"
                      )
                    : i18next.t(
                        "PreviewStreamingInfluencerComponentJoiningStreaming"
                      )}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  };

  const renderViewersPanel = () => {
    return (
      <div className={"ViewersPanel viewersCounter"}>
        {session && (
          <div className={"viewersCounter"}>
            <img src={iconUser} alt="" style={{ marginLeft: 18 }} />
            {activeUsers}{" "}
            {isMobile
              ? ""
              : i18next.t("PreviewStreamingInfluencerComponentViewers")}
          </div>
        )}
        {session && (
          <div
            style={{ color: "grey", fontWeight: "bold" }}
            className="viewerModalButton"
            data-tip={i18next.t(
              "PreviewStreamingInfluencerComponentTooltipShowOnlineViewersPanel"
            )}
            onClick={toggleViewerModal}
          >
            <Modal
              className="viewerModal"
              isOpen={showViewersModal}
              onRequestClose={() => {
                setShowViewersModal(false);
              }}
              style={{}}
            >
              <Row className="centerContent">
                <Col xs={{ span: 12 }}>
                  <h3 className="rojo ViewersPanelHeader">
                    {i18next.t(
                      "PreviewStreamingInfluencerComponentViewersConnected"
                    )}
                  </h3>
                </Col>
              </Row>

              <Row className="viewerList">
                {viewersConnected.map((viewer, i) => {
                  if (viewer) {
                    var isViewerWaitingForTurn = viewersWaiting.find(
                      (viewerWaiting) =>
                        viewerWaiting.user_username === viewer.username
                    );

                    return (
                      <div
                        className={` ${
                          isMobile
                            ? "mobileCenterContentViewerItem"
                            : "centerContentViewerItem"
                        }`}
                        key={i}
                      >
                        <img
                          className={` ${
                            isMobile
                              ? "mobileViewerItemAvatar"
                              : "viewerItemAvatar"
                          }`}
                          style={{
                            borderRadius: "50%",
                          }}
                          src={viewer.avatar}
                          alt=""
                        />
                        <p
                          className="rojo forceBold"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          {viewer.username === user.user_username
                            ? i18next.t(
                                "PreviewStreamingInfluencerComponentYou"
                              )
                            : viewer.username}
                        </p>
                        {isViewerWaitingForTurn && isInfluencer && (
                          <Button
                            className="Btn-rojo viewerPanelGiveTurnButton"
                            onClick={() => {
                              givePermissionsViewerStreaming(viewer.username);
                            }}
                          >
                            {i18next.t(
                              "PreviewStreamingInfluencerComponentGiveTurn"
                            )}
                          </Button>
                        )}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </Row>

              <Row className="centerContent absoluteBottomModal">
                <Col xs={{ span: 12 }}>
                  <button
                    className="Btn-rojo"
                    onClick={null /*toggleViewerModal*/}
                  >
                    {i18next.t("PreviewStreamingInfluencerComponentClose")}
                  </button>
                </Col>
              </Row>
            </Modal>
          </div>
        )}
      </div>
    );
  };

  const renderMobileLeaveButton = () => {
    if (!session) {
      return null;
    }

    return (
      <div className={"leaveEventButtonManager"}>
        <img
          src={Exit}
          alt="exit"
          onClick={() => {
            setShowDisconnectDialog(true);
          }}
        />
      </div>
    );
  };

  const renderMobileViewersWaiting = () => {
    if (!session) {
      return null;
    }

    if (isInfluencer && viewersWaiting && viewersWaiting.length > 0) {
      return (
        <div
          className="MobileViewersWaitingWarning"
          onClick={() => {
            toggleViewerModal(true);
          }}
        >
          {viewersWaiting.length}{" "}
          {i18next.t("AttendeesModeratorComponentViewersWaiting")}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderRedirectComponent = () => {
    if (renderRedirect) {
      return <Redirect to={isInfluencer ? "/my_live_events" : "/"} />;
    } else {
      return null;
    }
  };

  const renderButtonManagerPanel = () => {
    return (
      <ButtonManager
        toggleStreamingOptionsModal={toggleStreamingOptionsModal}
        isInfluencer={isInfluencer}
        showParticipantsButton={session}
        eventStarted={session}
        toggleViewerModal={toggleViewerModal}
        microphone={microphone}
        onSetMicrophone={onSetMicrophone}
        webcam={webcam}
        enabledChat={enabledChat}
        onSetWebcam={onSetWebcam}
        hasTurn={hasTurn}
        onSetEnableChat={onSetEnableChat}
        enableRiseHand={enableRiseHand}
        onSetEnableRiseHand={onSetEnableRiseHand}
        showChooseRaiseHand={showChooseRaiseHand}
        onStopRemoteCall={onStopRemoteCall}
        onSetEnableOpenMic={onSetEnableOpenMic}
        enabledOpenMic={enabledOpenMic}
        onSetRiseHand={onSetRiseHand}
        risedHand={risedHand}
        mySpeaking_viewer={mySpeaking_viewer}
        onStopRemoteCall={onStopRemoteCall}
        isLandscape={isLandscape}
        showButtons={showButtons}
        setShowButtons={setShowButtons}
      />
    );
  };

  const renderMobileChat = () => {
    if (!session) {
      return null;
    }

    return renderChatAndViewersQueue();
  };

  if (!resourcesLoaded) return null;

  var eventLocation = null;

  try {
    eventLocation = JSON.parse(eventData.event.event_location).city;
  } catch {
    eventLocation = "";
  }

  if (isMobile) {
    return (
      <div
        className={"MobileStreamContainer"}
        style={
          !session
            ? {
                backgroundColor: "#252233",
              }
            : null
        }
      >
        <SweetAlert
          imageUrl={warning}
          show={sessionError}
          title={sessionError}
          onConfirm={() => setSessionError(null)}
        />
        {renderMobileInstructions()}
        {renderButtonManagerPanel()}
        {renderViewersPanel()}
        {renderMobileLeaveButton()}
        {renderMobileViewersWaiting()}
        {renderConfirmationCloseEvent()}
        {renderRedirectComponent()}
        {renderMobileChat()}
        {renderStreamFinishedModal()}
        {session && (
          <Row
            className=""
            style={
              !sessionReady
                ? {
                    height: 0,
                    width: 0,
                  }
                : {
                    alignItems: "center",
                    alignContent: "center",
                    width: "100%",
                    height: "100%",
                  }
            }
          >
            <UserVideoComponent
              isLandscape={isLandscape}
              minimisedChat={minimisedChat}
              setMinimisedChat={setMinimisedChat}
              currentDominantSpeaker={currentDominantSpeaker}
              publications={publications}
              remotePublications={remotePublications}
              isInfluencer={isInfluencer}
              streamingInitiated={streamingInitiated}
              toggleStreamingOptionsModal={toggleStreamingOptionsModal}
              sessionReady={sessionReady && !instructionsVisibility}
              hideRemoteView={false}
              enterFullScreen={handle.enter}
              exitFullScreen={handle.exit}
              height={"100%"}
              isFullScreen={fullscreenMode}
              streamManager={myPublisher}
              remoteManager={
                isInfluencer
                  ? mainStreaming
                  : hasTurn
                  ? viewerPublisher
                  : mainStreaming
              }
              isRemoteApp={isRemoteApp}
              mySpeaking_viewer={mySpeaking_viewer}
              isOpenModeEnabled={enabledOpenMic}
              openModeSubscribers={subscribers}
              audioTracks={audioTracks}
              disableOverlay={false}
              shareScreen={isInfluencer ? shareScreen : null}
              shareScreenSource={shareScreenSource}
              isSharingScreen={isSharingScreen}
              stopShareScreen={stopShareScreen}
              influencerConnected={influencerConnected}
              screenShareTracks={screenShareTracks}
            />
          </Row>
        )}
      </div>
    );
  }

  return (
    <div className="streaming">
      <StreamingOptions
        session={session}
        isInfluencer={isInfluencer}
        hasTurn={hasTurn}
        getMediaDevices={getMediaDevices}
        availableDevices={availableDevices}
        selectVideoAndAudioDevice={selectVideoAndAudioDevice}
        selectStreamingDevices={selectStreamingDevices}
        isChangingSettings={isChangingSettings}
        toggleStreamingOptionsModal={toggleStreamingOptionsModal}
        currentVideoDevice={currentVideoDevice}
        currentAudioDevice={currentAudioDevice}
        currentOutputAudioDevice={currentOutputAudioDevice}
        removeLocalAudioTrack={removeLocalAudioTrack}
        getLocalAudioTracks={getLocalAudioTracks}
        composeAlertToast={composeAlertToast}
      />
      {
        <Detector
          render={({ online }) => {
            return null;
          }}
          onChange={(state) => {
            setNetworkStatus({
              status: state,
              previousStatus: networkStatus.status,
            });
          }}
        />
      }
      {renderConfirmationCloseEvent()}
      {renderRetryingConnecton()}
      {renderRedirect ? (
        <Redirect to={isInfluencer ? "/my_live_events" : "/"} />
      ) : null}
      <header className="app-streaming dropShadow">
        <NavBarStreaming
          onLeaveLiveEvent={() => {
            onLeaveLiveEvent();
          }}
          eventLocation={eventLocation}
          setShowDisconnectDialog={setShowDisconnectDialog}
          to={`/event/${match.params.id}`}
        />
      </header>
      <Container fluid="sm">
        <SweetAlert
          imageUrl={warning}
          show={sessionError}
          title={sessionError}
          onConfirm={() => setSessionError(null)}
        />
        <Row className="col-md-12 streamingFullContainer">
          <Col
            id={"mainContainer"}
            className={
              `special_border_radius mainVideoContainer ${
                streamingInitiated || sessionReady
                  ? "mainVideoContainerVideo streamingContainer"
                  : "mainVideoContainerInstructions"
              } ` + backgroundAnimation
            }
            style={{
              backgroundColor: !influencerConnected ? "white" : "black",
              justifyContent: "center",
              verticalAlign: "middle",
              display: "flex",
              marginRight: "12px",
            }}
          >
            {renderStreamingInstructions()}
            {!sessionReady && (streamingInitiated && !instructionsVisibility) && (
              <Row
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
                className={
                  streamingInitiated && !instructionsVisibility && !session
                    ? "animate__animated animate__fadeIn vertical-center"
                    : `${
                        sessionReady
                          ? " animate__animated animate__fadeOut vertical-center "
                          : " "
                      }`
                }
              >
                <Col
                  className={
                    streamingInitiated && !instructionsVisibility
                      ? "animate__animated animate__fadeIn align-items-center"
                      : "d-none"
                  }
                  style={{
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    justifyItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={logoGotmy}
                    style={{
                      width: "20%",
                    }}
                  />
                  <Spinner
                    style={{
                      width: 70,
                      height: 70,
                    }}
                    animation="grow"
                    variant="danger"
                  />
                  <p>
                    {i18next.t("PreviewStreamingInfluencerComponentLoading")}
                  </p>
                </Col>
              </Row>
            )}

            {session && (
              <Row
                className=""
                style={
                  !sessionReady
                    ? {
                        height: 0,
                        width: 0,
                      }
                    : {
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        height: "100%",
                      }
                }
              >
                <div
                  className="streamingContainer"
                  id="video-container"
                  onDoubleClick={() => {
                    if (fullscreenMode) {
                      handle.exit();
                    } else {
                      handle.enter();
                    }
                  }}
                >
                  <FullScreen
                    className={"fullVideoContainer"}
                    handle={handle}
                    onChange={(state) => {
                      setFullscreenMode(state);
                    }}
                  >
                    <UserVideoComponent
                      minimisedChat={minimisedChat}
                      setMinimisedChat={setMinimisedChat}
                      currentDominantSpeaker={currentDominantSpeaker}
                      publications={publications}
                      remotePublications={remotePublications}
                      isInfluencer={isInfluencer}
                      streamingInitiated={streamingInitiated}
                      toggleStreamingOptionsModal={toggleStreamingOptionsModal}
                      sessionReady={sessionReady && !instructionsVisibility}
                      hideRemoteView={false}
                      enterFullScreen={handle.enter}
                      exitFullScreen={handle.exit}
                      height={"100%"}
                      isFullScreen={fullscreenMode}
                      streamManager={myPublisher}
                      remoteManager={
                        isInfluencer
                          ? mainStreaming
                          : hasTurn
                          ? viewerPublisher
                          : mainStreaming
                      }
                      isRemoteApp={isRemoteApp}
                      mySpeaking_viewer={mySpeaking_viewer}
                      isOpenModeEnabled={enabledOpenMic}
                      openModeSubscribers={subscribers}
                      audioTracks={audioTracks}
                      disableOverlay={false}
                      shareScreen={isInfluencer ? shareScreen : null}
                      shareScreenSource={shareScreenSource}
                      isSharingScreen={isSharingScreen}
                      stopShareScreen={stopShareScreen}
                      influencerConnected={influencerConnected}
                      screenShareTracks={screenShareTracks}
                    />
                  </FullScreen>
                </div>
              </Row>
            )}
          </Col>
          {renderChatAndViewersQueue()}
        </Row>{" "}
        {renderButtonManager()}
      </Container>
      <ButtonManager
        toggleStreamingOptionsModal={toggleStreamingOptionsModal}
        isInfluencer={isInfluencer}
        showParticipantsButton={session}
        eventStarted={session}
        toggleViewerModal={toggleViewerModal}
        microphone={microphone}
        onSetMicrophone={onSetMicrophone}
        webcam={webcam}
        enabledChat={enabledChat}
        onSetWebcam={onSetWebcam}
        hasTurn={hasTurn}
        onSetEnableChat={onSetEnableChat}
        enableRiseHand={enableRiseHand}
        onSetEnableRiseHand={onSetEnableRiseHand}
        showChooseRaiseHand={showChooseRaiseHand}
        onStopRemoteCall={onStopRemoteCall}
        onSetEnableOpenMic={onSetEnableOpenMic}
        enabledOpenMic={enabledOpenMic}
        onSetRiseHand={onSetRiseHand}
        risedHand={risedHand}
        mySpeaking_viewer={mySpeaking_viewer}
        onStopRemoteCall={onStopRemoteCall}
        isLandscape={isLandscape}
        showButtons={showButtons}
        setShowButtons={setShowButtons}
      />
      {renderStreamFinishedModal()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    influencer: state.user.influencer,
    isInfluencer: state.user.isInfluencer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetOptionsModerators: (optionsModerators) =>
      dispatch(actions.setOptionsModerators(optionsModerators)),
  };
};

const enhance = compose(
  withRouter,
  withOrientationChange,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(InteractiveStreamingUI);
