import React, { useState, useEffect, forwardRef } from "react";
import i18next from "i18next";
import AutocompleteLocation from "components/comunes/autocompleteLocation/AutocompleteLocation";
import DatePicker from "react-date-picker";
import TimePicker from "react-datepicker";
import AddCalendar from "assets/img/icons/ic-addcalendar.svg";
import Clock from "assets/img/icons/clock.svg";
import Open from "assets/img/icons/ic-openModeWhite.svg";
import { NEW_PUBLISH_PAGE } from "../../../../environment/environment";
import { useSelector } from "react-redux";
import PublicEventOptions from "./PublicEventOptions";
// TODO: If location === false, no location component
const ChooseDateAndLocation = ({
  setStartPickerOpen,
  startPickerOpen,
  startDate,
  onChangeLocationValues,
  dateObj,
  endPickerOpen,
  endPickerRef,
  onChangeTime,
  onChangeDatePickerValues,
  startPickerRef,
  setEndPickerOpen,
  endDate,
  publicEvent,
  setPublicEvent,
  openTicket,
  setOpenTicket,
  event_location
}) => {
  const user_location = useSelector((state) => state.user.user.user_location);
  const [location, setLocation] = useState(null);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
      <div className={"iconContainer"}>
        <img
          onClick={() => setStartPickerOpen(!startPickerOpen)}
          src={Clock}
          alt=""
        />
      </div>
    </div>
  ));

  useEffect(() => {
    let locationObj;

    if (user_location && user_location !== "") {
      try {
        locationObj = JSON.parse(user_location);
        setLocation(locationObj);
      } catch (e) {
        console.log({ e });
      }
    }

    console.log("ChooseDateAndLocation", user_location);
  }, [user_location]);

  return (
    <form className={`${NEW_PUBLISH_PAGE && "mt-4"}`}>
      {NEW_PUBLISH_PAGE !== true && (
        <h2>{i18next.t("LiveEventInformationDateAndTime")}</h2>
      )}
      <div
        className={` ${NEW_PUBLISH_PAGE &&
          "d-flex d-flex align-items-center row"} mb-4`}
      >
        <div className={"col-6 col-sm-6 col-lg-4"}>
          {true && (
            <p className="mb-1 small_grey">
              {i18next.t("LiveEventInformationEventDate")}
            </p>
          )}
          <DatePicker
            minDate={new Date()}
            value={dateObj}
            clearIcon={null}
            format="MM-dd-yyy"
            className="date-picker"
            onChange={onChangeDatePickerValues}
            disabled={false}
            name="event_date"
            calendarIcon={<img src={AddCalendar} alt="calendar" />}
          />
        </div>
        {NEW_PUBLISH_PAGE && (
          <div className="col-6 col-sm-6 col-lg-4">
            <p className="mb-1 small_grey">
              {i18next.t("LiveEventInformationStartTime")}
            </p>
            <div
              ref={startPickerRef}
              onClick={() => setStartPickerOpen(!startPickerOpen)}
              style={{ width: "100%" }}
              className="TimePickerContainer"
            >
              <TimePicker
                wrapperClassName={"TimePickerContainerWrapper"}
                open={startPickerOpen}
                selected={startDate}
                preventOpenOnFocus={true}
                onChange={(date) => onChangeTime(date, "start")}
                readOnly
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat="h:mm aa"
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        )}
        {NEW_PUBLISH_PAGE && (
          <div className={"col-sm-12 col-lg-4"}>
            <PublicEventOptions
              newSkin={true}
              publicEvent={publicEvent}
              setPublicEvent={setPublicEvent}
              openTicket={openTicket}
              setOpenTicket={setOpenTicket}
              hideOpenTicket={true}
            />
          </div>
        )}
      </div>

      {( location === undefined ||
        location === "" ||
        location === null ||
        Object.keys(location).length === 0 ||
        (location.city && location.city === "Default")|| !NEW_PUBLISH_PAGE) && (
        <div className="mb-4">
          <p className="mb-1 small_grey">
            {i18next.t("LiveEventInformationLocation")}
          </p>
          <AutocompleteLocation
            onChangeLocationValues={onChangeLocationValues}
            userValues={undefined}
            initialLocation={event_location}
          />
        </div>
      )}
      {NEW_PUBLISH_PAGE !== true && (
        <div
          style={{ flexWrap: "wrap" }}
          className={`d-flex ${
            NEW_PUBLISH_PAGE ? "" : "justify-content-between"
          }`}
        >
          <div
            style={{ minWidth: "150px", maxWidth: "160px" }}
            className="mr-3"
          >
            <p className="mb-1 small_grey">
              {i18next.t("LiveEventInformationStartTime")}
            </p>
            <div
              ref={startPickerRef}
              onClick={() => setStartPickerOpen(!startPickerOpen)}
              style={{ position: "relative" }}
              className=""
            >
              <TimePicker
                open={startPickerOpen}
                selected={startDate}
                preventOpenOnFocus={true}
                onChange={(date) => onChangeTime(date, "start")}
                readOnly
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat="h:mm aa"
              />
              <img
                onClick={() => setStartPickerOpen(!startPickerOpen)}
                src={Clock}
                alt=""
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "11px",
                  height: "18px",
                  width: "18px",
                  right: "12px",
                }}
              />
            </div>
          </div>
          <div style={{ minWidth: "150px", maxWidth: "160px" }}>
            <p className="mb-1 small_grey">
              {i18next.t("LiveEventInformationEndTime")}
            </p>
            <div
              ref={endPickerRef}
              onClick={() => setEndPickerOpen(!endPickerOpen)}
              style={{ position: "relative" }}
              className=""
            >
              <TimePicker
                open={endPickerOpen}
                readOnly
                selected={endDate}
                onChange={(date) => onChangeTime(date, "end")}
                showTimeSelect
                timeIntervals={15}
                showTimeSelectOnly
                timeCaption="Time"
                dateFormat="h:mm aa"
              />
              
              <img
                src={Clock}
                alt=""
                onClick={() => setEndPickerOpen(!endPickerOpen)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "11px",
                  height: "18px",
                  width: "18px",
                  right: "12px",
                }}
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
};
export default ChooseDateAndLocation;
