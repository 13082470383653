import React, { useEffect } from "react";
import Photo from "../../assets/img/icons/ic-photo-white.svg";
import Menu from "../comunes/navbar_profile";
import { withRouter } from "react-router-dom";
import SkillCategories from "../viewer_profile/my_favorite_categories/skill_categories";
import MyFavoriteCategories from '../../components/viewer_profile/my_public_profile/MyFavoriteCategories';
import BecomeInfluencer from './BecomeInfluencer'
import useBecomeInfluencerLogic from "./BecomeInfluencerLogic";
import { Spinner } from "react-bootstrap";
import Loader from "components/comunes/loader";
import { Modal } from "react-bootstrap";
import "./style.scss";
import moment from "moment";
import { PublicProfileContainer } from './styles'
import { connect } from "react-redux";

const dateLimit = moment(new Date())
  .subtract(16, "y")
  .format("YYYY-MM-DD");

const BecomeInfluencerParent = ({ history, match, user }) => {
  const {
    onChangeUserValues, onChangeLocationValues, onChangeDatePickerValues, userValues, categories, languages,
    setLanguagesSelected, missingFields, setMissingFields, onSubmit, showErrorValidation, setShowErrorValidation, onChangeCategories, setSelectedCategory,
    setUserValues, setCountries, handleImageChange, languagesSelected, loadingInitial, loadingImage, updating,
    isInfluencer, avaliable, handleAvaliable, showModalCategories, setShowModalCategories, tabKeySelected,
    setTabKeySelected, selectedCategory, loadedImg, handleImageLoad } = useBecomeInfluencerLogic({ history, match, user });
    
    const {location, name, lastname, username, birthdate, biography, user_avatar:avatar } = user;
    console.log ({user})
    useEffect (( )=>{console.log('USER VALUES CHANGED', {userValues})},[userValues])
  const onUpdateLanguage = (lang) => {
    setLanguagesSelected(lang)
    setUserValues({ ...userValues, languages: lang.map(item => item.lang_ide) });

    try {
      localStorage.setItem("languages", JSON.stringify(lang));
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{



  },[userValues])
  const resetErrorValidation = () => {
    setShowErrorValidation(false)
    setMissingFields('')
  }

  const onUpdateCategories = (category) => {
    onChangeCategories(category.cate_ide);
    setSelectedCategory(
      category.cate_ide === 0 ? "0" : category.cate_ide
    );
  }

  const imagePreview = user && user.user_avatar ? (
    <>
      {!loadingImage ? (
        <>
          <img src={userValues.avatar} alt="avatar" />
          <form id="form_update" noValidate>
            <label
              className="file_input"
              id="fileInputIcon"
              htmlFor="file-input"
            >
              <img className="input_label grow" id="input_label_change" src={Photo} alt="" />
            </label>
          </form>
        </>
      ) : (
          <Loader />
        )}
    </>
  ) : (
      <div className="previewText">
        {!loadingImage ? (
          <form>
            <label className="file_input" htmlFor="file-input">
              <img className="input_label" src={Photo} alt="avatar" />
            </label>
          </form>
        ) : (
            <Spinner animation="border" />
          )}
      </div>
    );
  const publicInfoTabProps =
  {
    userValues, loadingInitial, showErrorValidation, missingFields, resetErrorValidation,
    isInfluencer, imagePreview, onChangeUserValues, onChangeLocationValues, languages, onChangeDatePickerValues, dateLimit,
    onUpdateLanguage, avaliable, languagesSelected, handleAvaliable, handleImageChange, loadedImg, handleImageLoad
  }
  return (
    <PublicProfileContainer>
      <div>
        <div>
          <Menu onSave={onSubmit} loading={updating} isInfluencer={isInfluencer} title="Influencer requirements" />
        </div>
        <div className="t">
          <div className="tab-content">
            <BecomeInfluencer {...publicInfoTabProps} />
            {!loadingInitial ? (
                <>
              <div className="row">
              <div style={{ margin: !isInfluencer && "0 auto" }} className={`${isInfluencer && "col-xl-7 "}  colProfile`}>

                <div className="container viewer_categories">
                  <h2 className="mb-2 categories-h2">
                    Add two skills categories
                    </h2>
                  <p style={{ color: 'grey', fontSize: '1.1rem' }}>
                    To be discovered on gotmy, it's recommended to choose one
                    or two master categories that match your activity
                    </p>

                  <SkillCategories categories={categories} userCategories={userValues.categories}
                    idCategory={selectedCategory} onSave={onSubmit} loader={updating} onChangeCategory={onChangeCategories}
                  />

                  <button data-toggle="modal" data-target="#myModal_categories" data-dismiss="modal" className=" Btn-rojo button_profile_influencer" onClick={() => setShowModalCategories(true)}>
                    Add category
                    </button>
                </div>
              </div>
              </div>
              </>
            ): (<> </>) 
          }
          </div>
        </div>
      </div>

      <Modal
        show={showModalCategories}
        onHide={() => setShowModalCategories(!showModalCategories)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="mb-2 categories-h2">
              Select your favorite category
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MyFavoriteCategories userValues={userValues} categories={categories} onUpdateCategories={onUpdateCategories} history={history} match={match} />
        </Modal.Body>
      </Modal>
    </PublicProfileContainer>
  );
};


const mapStateToProps = state => {
  return {
    user: state.user.user
  };
};

export default withRouter(connect(mapStateToProps)(BecomeInfluencerParent));
