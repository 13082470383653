import React , {useState, useEffect} from "react";
import "./style.scss";
import NavBar_liveEvents from "./navbar_live_events";
import UpcomingLiveEventsPage from "./upcoming_live_events";
import Past_live_events from "./past_live_events";
import FavoriteLiveEvents from "./favorite_live_events";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

const LiveEventsPage = ({events}) => {
  const now = new Date()
  const [pastEvents, setPastEvents] = useState([])
  useEffect(() => {
    if (events && events.length>0){
    let filteredEvents = events.filter((event) => new Date(parseInt(event.event.event_streaming_end_date)) < now)
    setPastEvents(filteredEvents)
    }
  }, [events])

  return(
  <div className="Live-Events-Page">
    <NavBar_liveEvents />
    <Switch>
      <Route
        path="/live_events/favorite_live_events"
        component={FavoriteLiveEvents}
      />
      {/* {pastEvents && pastEvents.length>0 && <Route
        path="/live_events/past_live_events"
        component={Past_live_events}
      />
} */}
       <Route
        path="/live_events/past_live_events"
        component={Past_live_events}
      />

      <Route path="/live_events" exact component={UpcomingLiveEventsPage} />
    </Switch>
  </div>
)};
const mapStateToProps = state => {
  return {
    events: state.user.user.purchasedEvents
  };
};

export default connect(mapStateToProps)(LiveEventsPage);