import { useEffect, useState } from "react";

const useInfluencerTransactionsLogic = ({user}) => {

  const [transactions, setTransactions] = useState(null);

  useEffect(() => {
    if (user.myStats && user.myStats.data && user.myStats.data.transactions && user.myStats.data.transactions.transactions) {
      setTransactions(user.myStats.data.transactions.transactions)
    }
  }, [user]);

  const filterTransactions = (searchTerm) => {
    const filteredTransactions = user.myStats.data.transactions.transactions.filter(function (transaction) {

      if (transaction.event && transaction.event.event_title){


        return transaction.event.event_title.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
    setTransactions(filteredTransactions);
  }

  return {transactions, filterTransactions};

};

export default useInfluencerTransactionsLogic