import React from "react";
import Card from "./card";
import "./style.scss";
import arrow from "assets/img/icons/ic-arrow-left.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from 'styled-components'
import i18next from 'i18next';

const LiveEvents = ({ showAll, title, categories }) => {
  return (
    <section className="Home-discover">
      <Container className="container">
        <div className="row">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'end' }} className="col-12">
            <h2>{title}</h2>

            {showAll && (
              <div className="Show-all">
                <span >{i18next.t('DiscoverByCategoryComponentShowAll')}</span>
                <img src={arrow} alt="arrow" />
              </div>
            )}
          </div>

          {categories
            .filter(cate => !cate.cate_parent_id && cate.cate_image)
            .map((category, index) => {
              if (category.cate_active === true) {
                return (
                  <Card
                    key={index}
                    img={category.cate_image}
                    id={category.cate_ide}
                    cat={category.cate_description}
                  />
                )
              }
            })}
        </div>
      </Container>
    </section>
  );
};
const Container = styled.div`

@media (max-width:768px){
  h2{
    margin:16px;
    font-size:22px;
  }
}

`
LiveEvents.propTypes = {
  showAll: PropTypes.bool,
  title: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  };
};

export default connect(mapStateToProps)(LiveEvents);
