import React, { Component } from "react";
import "./style.scss";
class StarRating extends Component {
  render() {
    return (
      <div class="container">
        <div class="row">
          <div class="rating">
            <input type="radio" id="star5" name="rating" value="5" />
            <label className="star" for="star5" title="Meh">
              5 stars
            </label>
            <input type="radio" id="star4" name="rating" value="4" />
            <label className="star" for="star4" title="Kinda bad">
              4 stars
            </label>
            <input type="radio" id="star3" name="rating" value="3" />
            <label className="star" for="star3" title="Kinda bad">
              3 stars
            </label>
            <input type="radio" id="star2" name="rating" value="2" />
            <label className="star" for="star2" title="Sucks big tim">
              2 stars
            </label>
            <input type="radio" id="star1" name="rating" value="1" />
            <label className="star" for="star1" title="Sucks big time">
              1 star
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default StarRating;
