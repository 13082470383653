import React, { Component } from "react";
import AudioVisualiser from "./AudioVisualiser";
import * as MeterUtils from "./volume-meter";

class AudioAnalyser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioData: new Uint8Array(0),
      volume: 0,
      audioSource: this.props.audio,
    };
    this.tick = this.tick.bind(this);
    this.wave = this.wave.bind(this);
  }

  componentDidMount() {
    this.initializeAndConnectAnalyzer();
  }

  componentDidUpdate() {
    try {
      const newAudioDeviceId = this.props.audio.id;
      const currentAudioDeviceId = this.state.audioSource.id;

      if (newAudioDeviceId !== currentAudioDeviceId) {
        this.stopAudioAnalyzer();
        this.initializeAndConnectAnalyzer();
      }
    } catch (error) {
      console.error(error);
    }
  }

  initializeAndConnectAnalyzer() {
    this.audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    this.analyser = this.audioContext.createAnalyser();
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    this.source = this.audioContext.createMediaStreamSource(this.props.audio);
    this.meter = MeterUtils.createAudioMeter(this.audioContext);
    this.source.connect(this.meter);
    this.source.connect(this.analyser);

    this.rafId = requestAnimationFrame(
      this.props.type === "tick" ? this.tick : this.wave
    );
    this.setState({
      audioSource: this.props.audio,
    });
  }

  stopAudioAnalyzer() {
    cancelAnimationFrame(this.rafId);
    this.analyser.disconnect();
    this.source.disconnect();
  }

  tick() {
    this.analyser.getByteTimeDomainData(this.dataArray);
    this.setState({ audioData: this.dataArray });
    this.rafId = requestAnimationFrame(this.tick);
  }

  wave() {
    this.analyser.getByteTimeDomainData(this.dataArray);
    this.setState({ audioData: this.dataArray, volume: this.meter.volume });
    this.rafId = requestAnimationFrame(this.wave);
  }

  componentWillUnmount() {
    this.stopAudioAnalyzer();
  }

  render() {
    return <AudioVisualiser audioData={this.state.audioData} volume={this.state.volume} type={this.props.type} meter={this.meter} type={"circle"} />;
  }
}

export default AudioAnalyser;
