import React, { useEffect, useState } from 'react';
import queryString from 'query-string'
import axios from "axios"
import { Api, SECOND_ACCESS_TOKEN_API, LOGIN_BACK_URL } from "../../environment/environment"
import SweetAlert from "sweetalert2-react";
import * as actions from "../../store/actions/user";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'
import { parseLocale } from 'utils/Localization'
import {   EventDAO} from "daos";
const eventDAO = new EventDAO();

const ApplePage = ({history, location, match,setError})=> {
    const [show, setShow] = useState(true)
    const dispatch = useDispatch()

    useEffect(()=> {
        appleLogin()
    }, [location])
  const appleLogin = async () => {
    const token = location.search.split('t=')[1]
    
    var referral =  JSON.parse(localStorage.getItem ("referral"))
    const referral_code = referral? referral.id :null 

    
    if (token && token.length>0) {
      axios.post(`${Api}auth/login_apple`, { token,...(referral_code) && {referral_code}
      
      }, {
        headers: {
          "Access-Token": SECOND_ACCESS_TOKEN_API,
          "Content-Type": "application/json",
          "Content-Language": parseLocale()

        }
      }).then(async res => {
        const { data } = res;
        const env = {
          token: data.data.user_app_token
        };
        
        localStorage.setItem("env", JSON.stringify(env));
        if (env.token) {

          const purchasedEvents = await eventDAO.purchasedEvents(env.token);
          data.data.purchasedEvents= (purchasedEvents.data && purchasedEvents.data.data) || []; 

            dispatch(actions.setUser(data.data))
            dispatch(actions.getInfluencer(env.token))
          }

        if (
          !data.data.user_name &&
          !data.data.user_lastname
        ) {
          history.push("/viewer_profile");
        } else {
          history.push(`/profile`);
        }
        // document.location.reload();
        

      })
        .catch(err => {

          setError("There was a problem connecting with Apple.")
          history.push("/");

          document.location.reload();
          // setState({redirect:true})
        })
        ;
    }
  }
 
    return (
      <>

      </>
    );
  
}


const mapDispatchToProps = dispatch => {
  return {
    setError: error => dispatch(actions.setError(error))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(ApplePage));

