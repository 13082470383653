import styled from 'styled-components'


export const Container = styled.div`
margin-right: 0px;
position:relative;
`
export const AvatarImg = styled.img`
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #ff5a60;
    margin-right: 20px;
    align-self: center;

    height: 50px;
    width: 50px;
    @media (min-width: 1600px){
        height: 55px !important;
    width: 55px !important;
    }
`


export const Icon = styled.img`
    position: absolute;
    bottom: -3px;
    right: 18px;
    width: 20px;
    vertical-align: middle;
    border-style: none;

`