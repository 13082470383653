import React from 'react';
import { Card, Button } from 'react-bootstrap';
import './style.scss';
import Discover from '../../../assets/img/icons/ic-discover.svg';
import Calendar from '../../../assets/img/icons/ic-addcalendar.svg';
import Gain from '../../../assets/img/icons/ic-ganancia.svg';
import {useSelector , useDispatch} from "react-redux"
// import {getInfluencer} from "../../../actions/user"
import i18next from "i18next"
import * as actions from "../../../store/actions";

  const Chart = (props)=>{
    const dispatch = useDispatch()
    const stats = useSelector(state=> state.user.myStats.data)
    const events = useSelector(state=> state.user.events)
    
    const handleCurrencySelect = (currency)=>{
    const env = JSON.parse(localStorage.getItem("env") || "{}");
      dispatch(actions.getInfluencer(env.token , currency)) 
    }
    return (
      <div  style={{justifyContent:"center"}} className="cards d-flex flex-wrap">
       <Card style={{ width: '18rem', margin: '0 2rem 4rem 0' , backgroundColor: "#FF5A5F", color: "white"}}>
            <Card.Img variant="top" src={Discover} />
            <Card.Body>
                <Card.Title>{i18next.t("StatsCardTotal")}</Card.Title>
                {events  && <Card.Title id="divider" style={{fontSize: '2em'}}>
                    {events.length}
                </Card.Title>

  }
            </Card.Body>
            </Card>
            <Card style={{ width: '18rem', margin: '0 2rem 4rem 0', backgroundColor: "#FF5A5F", color: "white" }}>
            <Card.Img variant="top" src={Calendar}/>
            <Card.Body>
                <Card.Title>{i18next.t("StatsCardTotalSales")}</Card.Title>
                {stats && stats.booking&& stats.booking.booking_resume && 
                
                    <>
                <Card.Title id="divider" style={{fontSize: '1.5em'}}>
                    {stats && stats.booking.booking_resume.booking_total}
                </Card.Title>
                <Card.Text style={{fontSize: '0.8em'}}>{` ${Math.abs(stats.booking.booking_resume.percentage.toFixed(2)) } ${stats.booking.booking_resume.percentage > 0? "% INCREASE" : 
                stats.booking.booking_resume.percentage === 0 ? i18next.t("StatsCardTotalChange"): i18next.t("StatsCardTotalDecrease")}`}</Card.Text>
    </>
  }
            </Card.Body>
            </Card>
            <Card style={{ width: '18rem', margin: '0 2rem 4rem 0' , backgroundColor: "#FF5A5F", color: "white"}}>
            <Card.Img variant="top" src={Gain} />
            <Card.Body>
                <Card.Title>{i18next.t("StatsCardTotalTotalBalance")}</Card.Title>
                { stats && stats.transactions && stats.transactions.transactions_resume&&  stats.transactions.transactions_resume.total_available &&
                <>
                 <Card.Title id="divider" style={{fontSize: '18px'}}>
                <div style={{alignItems:"center"}} className="d-flex ">
               {stats && stats.transactions.transactions_resume.total_available}
                  <select onChange={(e)=>handleCurrencySelect(e.target.value)} className=" select-css ml-2 " default="usd" id="inlineFormCustomSelect" >
        { stats && 
          stats.currencies.map((currency)=>{if (currency.currency_code!=="free")return (<option  value={currency.currency_code}>{currency.currency_name}</option>)}
          )
        }
      </select>
                  </div>
                </Card.Title>

                <Card.Text style={{fontSize: '0.8em'}}>{` ${Math.abs(stats.transactions.transactions_resume.percentage) } ${stats.transactions.transactions_resume.percentage > 0? i18next.t("StatsCardTotalIncrease") : i18next.t("StatsCardTotalDecrease")  }`}</Card.Text>
                </>
  }
            </Card.Body>
            </Card>
            {/* <Card style={{ width: '18rem', margin: '0 2rem 4rem 0', backgroundColor: "#FF5A5F", color: "white" }}>
            <Card.Img variant="top" src={Fun}/>
            <Card.Body>
                <Card.Title>Total Profile Views</Card.Title>
                <Card.Title id="divider" style={{fontSize: '1.5em'}}>
                    2.394
                </Card.Title>
                <Card.Text style={{fontSize: '0.8em'}}>13% INCREASE</Card.Text>
            </Card.Body>
            </Card> */}
      </div>
    )
  

  }

export default Chart;
