import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import typeActitiy from "../../constants/typeActitiy";
import Activity from './Activity'
import useMobileNav from '../../hooks/useMobileNav'
import useComponentVisible from '../../hooks/useComponentVisible'
import i18next from 'i18next';
const Activities = ({ activities }) => {
    const ref = useRef(null);
    const {mobileNav } = useMobileNav()
    const [showAll, setShowAll] = useState(false)

    const handleShowAll= (e)=> {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    
        setShowAll(true)
        const span = ref.current; // corresponding DOM node
        span.className = "dropdown-menu profile show";
    
    }
 
    return (
        <div style={{position:'relative'}}>
 <ActivityBox >
            <div
                style={{
                    textAlign:'left',
                    maxHeight: "450px",
                    width: !mobileNav? '460px' : '400px',
                    overflowY: "auto",
                    overflowX: "hidden"
                }}
            >
                {activities.map((activity, index) => {
                    const { activity_type } = activity;
                    
                    if ((showAll) || (!showAll && index<4)){
                        // 
                        // return <Activity type={activity_type} data={activity} key={index} />;
                    switch (activity_type) {
                        //   case typeActitiy.request:
                        //     return <PrivateMeting data={activity} key={index} />;
                        case typeActitiy.follow:
                            return <Activity type={activity_type} data={activity} key={index} />;
                        // case typeActitiy.new_event:
                        //     return <Activity type={activity_type} data={activity} key={index} />;
                        case typeActitiy.ticket_sold:
                            return <Activity type={activity_type} data={activity} key={index} />;
                        default:
                            return null;
                    }
                // }
                }})}
                 {!showAll &&
                <div ref={ref} onClick={handleShowAll} className="showAll">
                    <span className="LogOut ">{i18next.t("ActivitiesShowAll")}</span>
                </div>
}
            </div>
            {!activities.length && (
                <p styles="margin-left: -199px">{i18next.t("ActivitiesNoActivities")}</p>
            )}
           
            
        </ActivityBox>

        </div>
       
    )
}

const ActivityBox = styled.div`
.showAll{
    margin-top: 0.4rem;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 18px;
}
/* box-shadow: 1px 1px 20px 20px #00000026; */
border: 1px solid rgba(0, 0, 0, 0.15);/* border:1px solid #123; */
border-radius:12px;
z-index:999;
background-color:white;
    position: absolute;
    transform: translate3d(861px, 77px, 0px);
    top: -3rem;
    left: -74rem;
    will-change: transform;
display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0rem;
    padding-top: 1rem;
    @media(max-width:768px){

        left:-55rem;

        ::before{
            left:6% !important;
        }
    }
    ::before{
        /* box-shadow: 1px 1px 20px 20px #00000026; */
        left:23%;
        content: "";
    position: absolute;
    top: -13px;
    left: 73%;
    width: 0;
    height: 0;
    border-width: 0 14px 17px;
    border-style: solid;
    border-color: transparent transparent white;
    box-sizing: border-box;
    }
`
export default Activities