import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import {
  Api,
  SECOND_ACCESS_TOKEN_API,
  INFLUENCER_ROLE
} from "../../../../environment/environment";
import { withRouter } from "react-router-dom";
import SweetAlert from "sweetalert2-react";
import * as actions from '../../../../store/actions/user';
import { connect } from "react-redux";
import { parseLocale } from 'utils/Localization'
import { UserDAO , EventDAO} from "daos";
const eventDAO = new EventDAO();
const userDAO = new UserDAO();

const VerifyTwitterLogin = ({ history, setUser, getInfluencer }) => {
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    
    verifyTwitter();
  }, []);
  const autoInfluencer = async (token) => {
    try {
      const { data } = await axios.post(
        `${Api}influencer/auto_upgrade`,
        { },
        {headers: { "Access-Token": token, "Content-Type": "application/json" , "Content-Language": parseLocale()
      }}
      );
      localStorage.setItem("autoUpgrade", JSON.stringify({status:true}))
      console.log({ data })
    }
    catch (e){
      console.log(e)
    }
  }

  const verifyTwitter = async () => {
    const url = new URL(window.location.href.replace("#", ""));
    const oauth_token = url.searchParams.get("oauth_token");
    const oauth_verifier = url.searchParams.get("oauth_verifier");
    const actionTwitter = localStorage.getItem("twitterAction");
    var referral =  JSON.parse(localStorage.getItem ("referral"))
    var landingTalentToken = JSON.parse(localStorage.getItem("LandingTalent"))
    // localStorage.removeItem("referral")
    const referral_code = referral? referral.id :null 

    if (
      !oauth_token ||
      !oauth_verifier ||
      (actionTwitter !== "login" || !actionTwitter)
    )
      return;

    try {
      let { data } = await axios.post(
        `${Api}auth/login_twitter`,
        { oauth_token, oauth_verifier: oauth_verifier.split("/")[0], ...(referral_code) && {referral_code} },
        {
          headers: {
            "Access-Token": SECOND_ACCESS_TOKEN_API,
            "Content-Type": "application/json",
            "Content-Language": parseLocale()

          }
        }
      );
      const env = {
        token: data.data.user_app_token
      };
      if (referral_code || landingTalentToken === true) {
        await autoInfluencer(env.token)
        data.data.user_role = INFLUENCER_ROLE

        localStorage.removeItem("LandingTalent");
      }
      const purchasedEvents = await eventDAO.purchasedEvents(env.token);
      data.data.purchasedEvents= (purchasedEvents.data && purchasedEvents.data.data) || []; 

       setUser(data.data)
       getInfluencer(env.token)
      localStorage.setItem("env", JSON.stringify(env));
      let autoInf = JSON.parse(localStorage.getItem("autoUpgrade"))


      if (autoInf && autoInf.status ===true){
        history.push("/publish_event")
      }
      
     else if (data.data.user_role === INFLUENCER_ROLE ) {
       history.push('/my_live_events')
     }
      else if ((!data.data.user_name || !data.data.user_lastname) ) {
        history.replace("viewer_profile");
      }
      
      else {
        history.push(`/`)
      }
    } catch (err) {
      setErrorMessage('Twitter login error. Contact support@gotmy.com');
    } finally {
      localStorage.removeItem("twitterAction");
    }
  }

  

  return (
    errorMessage && (
      <SweetAlert
        show={errorMessage}
        title={errorMessage}
        onConfirm={() => {
          setErrorMessage("");
          history.push("/");
        }}
      />
    )
  );
};
const mapDispatchToProps = dispatch => {
  return {
    setUser: data => dispatch(actions.setUser(data)),
    getInfluencer: token => dispatch(actions.getInfluencer(token))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(VerifyTwitterLogin));

