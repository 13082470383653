import { actionTypes, actions } from "./actions";

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ON_GET_REQUESTS:
      return actions.onGetRequests(state);
    case actionTypes.ON_SUCCESS_GET_REQUEST:
      return actions.onSuccessGetRequest(state, action);
    case actionTypes.ON_FINISH_GET_REQUEST:
      return actions.onFinishGetRequest(state);
    case actionTypes.ON_SET_SEARCH_INPUT:
      return actions.onSetSearchInput(state, action);
    case actionTypes.ON_SET_REQUEST_ACTIVE:
      return actions.onSetRequestActive(state, action);
    case actionTypes.ON_REJECT_REQUEST:
      return actions.onRejectRequest(state, action);
    case actionTypes.ON_FINISH_REJECT_REQUEST:
      return actions.onFinishRejectRequest(state);
    case actionTypes.ON_ACCEPT_REQUEST:
      return actions.onAcceptRequest(state);
    case actionTypes.ON_FINISH_ACCEPT_REQUEST:
      return actions.onFinishAcceptRequest(state);
    default:
      throw new Error();
  }
};

export default reducer;
