import React from "react";
import "./style.scss";
import FechaCat from "../header-streaming";
import logo from "assets/img/logo-white.png";
import EndCat from "../end-streaming";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import i18next from 'i18next';

const MenuStreamingPre = ({ eventLocation, onLeaveLiveEvent, to, history, setShowDisconnectDialog }) => {
  return (
    <div className="FechaCat2">
      <div className="FechaCat">
        <img src={logo} className="Logo3" alt="logo" />
        <FechaCat
          eventLocation={eventLocation}
        />
      </div>
      <button
        className="leave-streaming"
        onClick={() => {

         if(setShowDisconnectDialog){
          setShowDisconnectDialog(true);
         }
        }}
      >
         {i18next.t("MenuStreamingPreComponentLeaveLiveEvent")}
      </button>
    </div>
  );
};

MenuStreamingPre.propTypes = {
  onLeaveLiveEvent: PropTypes.func
};

export default withRouter(MenuStreamingPre);
