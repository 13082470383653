import React, {Component} from 'react';
import './style.scss';
import Menu from "../../comunes/navbar_publish_private";
import {NavLink} from "react-router-dom";
import i18next from 'i18next';

class NavBar_liveEventsPublishPrivate extends Component {
    render() {
        return (
            <div className="Nav_search">
                <div className="Nav Nav2 Bg-blue flex-column pb-0">
                    <div className="d-flex justify-content-between w-100">
                  <Menu/>
                    </div>
                    {/*MENU LIVE EVENTS*/}
                    <div className="Menu-live-Events-publish">
                        <NavLink exact to={`/private_publish`} activeClassName="active_live_events"  className="col-4 ">
                        {i18next.t('InfluencerPublishEventMenuInfo')}
                        </NavLink>
                        <NavLink exact to={`/private_publish/pitch_video`} activeClassName="active_live_events" className="col-4 ">
                        {i18next.t('InfluencerPublishEventMenuPitch')}
                        </NavLink>
                        <NavLink  exact to={`/private_publish/thank_you`} activeClassName="active_live_events" className="col-4">
                        {i18next.t('InfluencerPublishEventMenuThanks')}
                        </NavLink>
                        
                    </div>

                </div>

            </div>
        );
    }
}

export default NavBar_liveEventsPublishPrivate;