import React, { useState, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import i18next from "i18next";
import { withRouter, Link, Redirect } from "react-router-dom";
import AttendeesModerator from "components/comunes_events/attendees_moderator";
import UserModerator from "components/comunes_events/attendees_influencer";
import ExitModerator from "assets/img/icons/ic-detele.svg";
import Smile from "assets/img/icons/smile.svg";
import ChatMessage from "./ChatMessage";
import "../../styles.scss";
import ReactTooltip from "react-tooltip";
import ExpandIcon from "assets/img/icons/expandIcon.svg";
import EmojiPicker from "./EmojiPicker";
import useClickOutside from "../../../../hooks/useClickOutside";
import styles from '../../../../assets/css_general/general.scss';

const Chat = ({
  minimisedChat,
  setMinimisedChat,
  isInfluencer,
  showChooseRaiseHand,
  enableRiseHand,
  setShowChooseRaiseHand,
  userNameRaiseHand,
  enabledChat,
  viewersWaiting,
  chats,
  submitMessage,
  sendingMessage,
  message,
  setMessage,
  givePermissionsViewerStreaming,
  onRiseHandCanceled,
  session,
  isMobile,
  showMobileInput,
  isLandscape
}) => {
  const maxCharacters = 200;
  const emojiPickerRef = useRef(null);

  const [emojiPicker, setEmojiPicker] = useState(false);
  // const [emojiNumber, setEmojiNumber] = useState(0) // used for styling.
  // const [emojiMessage, setEmojiMessage] = useState(false) // used for styling.
  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      submitMessage();
    }
  };
  useClickOutside(emojiPickerRef, () => {
    if (emojiPicker) {
      setEmojiPicker(false);
    }
  });

  const handleAddEmoji = (emojiObject) => {
    if (emojiObject) {
      // setEmojiNumber(emojiNumber+1)
      setMessage(message + emojiObject.emoji);
    }
  };
  const handleEmojiPicker = () => {
    if (enabledChat) {
      setEmojiPicker(!emojiPicker);
    }
  };

  const renderMobileChatInput = () => {

    if(!showMobileInput){
      return null;
    }

    if(!enabledChat){
      return (
        <h3 className="mobileDisabledChat">
        {i18next.t(
          "PreviewStreamingInfluencerComponentChatTemporarilyDisabled"
        )}
      </h3>
      )
    }
    
    return (
      <input
        disabled={false}
        placeholder={i18next.t("PreviewStreamingSendMessage")}
        className={"mobileChatInput"}
        type="text"
        disabled={!session || !enabledChat}
        value={message}
        onKeyDown={onEnterPress}
        onChange={(event) => {
          setMessage(event.target.value);
        }}
      ></input>
    );
  };

  const renderMobileChat = () => {
    if(!showMobileInput){
      return null;
    }

    if(!enabledChat){
      return null;
    }

    return (
      <div
      id={"mobileChat"}
      className={` ${
        isLandscape ? "MobileChatContainerLandscape" : "MobileChatContainer"
      }`}
    >
      <ul
        className="mt-1 list-unstyled "
        style={{ wordBreak: "break-word" }}
      >
        {enabledChat &&
          chats.map(
            ({ avatar, username, message, uuid, emojiMessage }) => (
              <ChatMessage
                uuid={uuid}
                avatar={avatar}
                message={message}
                username={username}
                messageStyle={{
                  backgroundColor: "##fff0",
                  color: "white",
                  padding: "4px 12px",
                  fontSize: "15px",
                  marginBottom: "0.15rem",
                }}
              />
            )
          )}

        {!enabledChat && (
          <h3 className="mobileDisabledChat">
            {i18next.t(
              "PreviewStreamingInfluencerComponentChatTemporarilyDisabled"
            )}
          </h3>
        )}
      </ul>
    </div>
    )
  }

  if (isMobile) {
    return (
      <>
      {renderMobileChat()}
      {renderMobileChatInput()}
      </>
    )
  }

  return (
    <Col
      className={`chatMain special_border_radius animate__animated ${
        isInfluencer ? "chatContainer" : "chatContainerViewer"
      } animate__fadeIn`}
      style={{
        // display:"flex",
        // flexDirection:"column",
        // justifyContent:"space-between",
        backgroundColor: styles.mainBackground, 
        color: "white",
      }}
    >
      <div></div>
      {isInfluencer && (
        <div className="d-flex request_moderator" style={{ display: "none" }}>
          <img
            src={Request}
            className="request_img"
            style={{ display: "none" }}
          ></img>
          <div className="ml-4" style={{ display: "none" }}>
            <p className="mb-0 mt-3 rojo">
              {i18next.t("PreviewStreamingInfluencerComponentChatModeration")}
            </p>
            <Link to="/change_moderator">
              <p className="rojo">
                {i18next.t(
                  "PreviewStreamingInfluencerComponentChooseViewerAsModerator"
                )}
              </p>
            </Link>
          </div>
        </div>
      )}
      <div style={{ position: "relative" }}>
        <ReactTooltip
          globalEventOff="click"
          eventOff="click"
          place="bottom"
          type="dark"
          effect="solid"
        />
        {!minimisedChat && !(!session || !enabledChat) && (
          <img
            data-tip={"Collapse"}
            data-event-off="click"
            onClick={() => {
              ReactTooltip.hide();
              setMinimisedChat(true);
            }}
            style={{
              width: 18,
              height: 18,
              cursor: "pointer",
              position: "absolute",
              top: 32,
              left: 4,
            }}
            src={ExpandIcon}
          />
        )}
        {isInfluencer && !showChooseRaiseHand ? (
          <h3
            className="mb-0 rojo"
            style={{
              marginTop: "12px",
              marginBottom: "18px",
              textAlign: "center",
            }}
          >
            {i18next.t("PreviewStreamingInfluencerComponentRaiseHandQueue")}
          </h3>
        ) : null}
      </div>

      {!showChooseRaiseHand ? (
        <>
          <div
            style={{ marginBottom: "15px" }}
            onClick={() => setShowChooseRaiseHand(!showChooseRaiseHand)}
          >
            {isInfluencer && (
              <AttendeesModerator
                data={viewersWaiting}
                isEnabled={enableRiseHand}
              />
            )}
          </div>

          <h3 className="mb-0 rojo" style={{ textAlign: "center" }}>
            {i18next.t("PreviewStreamingInfluencerComponentLiveChat")}
          </h3>

          <div
            id={isInfluencer ? "messageContainer" : "messageContainerViewer"}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              marginTop: "2%",
              marginBottom: "1%",
            }}
          >
            <ul
              className="mt-1 list-unstyled chatSize"
              style={{ wordBreak: "break-word" }}
            >
              {enabledChat &&
                chats.map(
                  ({ avatar, username, message, uuid, emojiMessage }) => (
                    <ChatMessage
                      uuid={uuid}
                      avatar={avatar}
                      message={message}
                      username={username}
                    />
                  )
                )}

              {!enabledChat && (
                <h3 className="chatDisabled">
                  {i18next.t(
                    "PreviewStreamingInfluencerComponentChatTemporarilyDisabled"
                  )}
                </h3>
              )}
            </ul>
          </div>
          <Row
            style={{ height: isInfluencer ? "21%" : "22%" }}
            className="col-md-12 animate__animated animate__fadeInUp chatInput"
          >
            <Col xs="12">
              <div ref={emojiPickerRef} style={{ position: "relative" }}>
                {emojiPicker && (
                  <div style={{ position: "absolute", bottom: 55 }}>
                    <EmojiPicker handleAddEmoji={handleAddEmoji} />
                  </div>
                )}
                <textarea
                  placeholder={i18next.t("PreviewStreamingSendMessage")}
                  className="form-control placeholder-text"
                  rows="1"
                  disabled={!session || !enabledChat}
                  value={message}
                  onKeyDown={onEnterPress}
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                  style={{
                    height: "calc(1.5em + 0.75rem + 2px)",
                    borderRadius: 6,
                    position: "relative",
                    right: "1rem",
                    width: "100% !important",
                    right: 0,
                  }}
                />
                <img
                  src={Smile}
                  style={{
                    width: 20,
                    height: 20,
                    position: "absolute",
                    right: 10,
                    bottom: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => handleEmojiPicker(!emojiPicker)}
                />
              </div>
              <button
                style={{
                  left: "0px !important",
                  backgroundColor: (!session || sendingMessage) && "#908E99",
                }}
                className="Btn-rojo mt-3 send_influencer"
                onClick={() => {
                  submitMessage();
                }}
                disabled={!session || sendingMessage}
              >
                {i18next.t("PreviewStreamingInfluencerComponentSend")}
              </button>

              <p
                className="caracteres_chat_influencer"
                style={{
                  bottom: "-0.5rem",
                  color: message.length > maxCharacters ? "red" : "white",
                  marginBottom: "0.1rem",
                }}
              >
                {message.length}/{maxCharacters}
              </p>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <div style={{ textAlign: "center", marginTop: "5%" }}>
            {isInfluencer && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <h2
                  className="Choose_raise rojo"
                  style={{ textAlign: "center", fontSize: "1.75rem" }}
                >
                  {isInfluencer &&
                    i18next.t(
                      "PreviewStreamingInfluencerComponentChooseRaiseHand"
                    )}{" "}
                </h2>
                {isInfluencer && (
                  <img
                    style={{ position: "absolute", top: 30, right: -3 }}
                    className="exit_moderator"
                    onClick={() => setShowChooseRaiseHand(false)}
                    src={ExitModerator}
                    alt=""
                  />
                )}
              </div>
            )}
            {Array.isArray(viewersWaiting) && viewersWaiting.length == 0 ? (
              <p>
                {i18next.t("PreviewStreamingInfluencerComponentNoUsersWaiting")}
              </p>
            ) : null}
            <div className="raiseHandQueueContainer">
              {isInfluencer &&
                Array.isArray(viewersWaiting) &&
                viewersWaiting.map((user) => (
                  <UserModerator
                    user={user}
                    onSelectUser={givePermissionsViewerStreaming}
                    onUnselectUser={onRiseHandCanceled}
                    selectedUser={
                      user != undefined &&
                      user.user_username === userNameRaiseHand
                    }
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </Col>
  );
};
export default Chat;
