import React from 'react'
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import styled from 'styled-components'
const  AutocompleteLocation = ({userValues , initialLocation, onChangeLocationValues}) => {
    return (
        <div  >
        <GooglePlacesAutocomplete
          onSelect={value => { 
            console.log("onSelect", value)
              if (userValues) {
                  let updatedValues = JSON.parse(
              JSON.stringify(userValues)
            );
            updatedValues.location = value.description;
                  }
            onChangeLocationValues(value.description)
          }}
          initialValue={initialLocation}
          inputStyle={{ width: "100%", height: '3rem', padding: '16px', borderRadius:'5rem', border: '1px solid #D8DCE5', boxShadow:'none', cursor:'pointer'}}
        />
      </div>
    )
}

export default AutocompleteLocation

const Container = styled.div`

  cursor: pointer;

`