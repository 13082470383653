import * as actionTypes from "./actionTypes";
import { CurrencyDAO } from "daos";


const currencyDAO = new CurrencyDAO();

export const setCurrencies = currencies => {
  return {
    type: actionTypes.SET_CURRENCIES,
    currencies
  };
};

export const getCurrencies = () => {
    return async dispatch => {
      try {
        const response = await currencyDAO.findAll();
        let allCurrencies = response.data.data
        const currencies= allCurrencies.filter(currency => currency.currency_active===true)     
        dispatch(setCurrencies(currencies));
      } catch (err) {
        throw err;
      }
    };
  };
  