import { useState, useEffect } from "react";
import * as yup from "yup";
import { UserDAO , EventDAO} from "daos";
import { LINKEDIN_CLIENTID, LOGIN_BACK_URL, FACEBOOK_CLIENTID, INFLUENCER_ONLY_MODE } from "../../../../../environment/environment";
import { linkedinLogin, twitterLogin } from "../../../../../utils/socialLogin";
import { useSelector, useDispatch } from 'react-redux'
import * as actions from "../../../../../store/actions";
import i18next from 'i18next';
const eventDAO = new EventDAO();


const initialFormValues = {
  email: "",
  password: ""
};
const schema = yup.object().shape({
  password: yup
    .string()
    .required(i18next.t("ModalLoginLogicComponentPasswordRequiredError"))
    .test(
      "format",
    i18next.t("ModalLoginLogicComponentPasswordFormatError"),
      value => {
        // const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        const reg = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}/;
        return reg.test(value);
      }
    ),
  email: yup
    .string()
    .email(i18next.t("ModalLoginLogicComponenEmailFormatErrorMessage"))
    .required(i18next.t("ModalLoginLogicComponentEmailRequired"))
});


const userDAO = new UserDAO();

/**
 * Logic of ModalLogin
 * @param {Object} history, React Router Object
 * @returns {Object}
 */
export const useModalLoginLogic = history => {
  const dispatch = useDispatch()
  const isInfluencer = useSelector(state => state.user.isInfluencer)
  const [formValues, setFormValues] = useState(initialFormValues),
    [errorForm, setErrorForm] = useState(""),
    [loading, setLoading] = useState(false),
    [showAlertGoodCredentials, setShowAlertGoodCredentials] = useState(false);


  useEffect(() => {
    if (isInfluencer && INFLUENCER_ONLY_MODE) {
      history.push('/my_live_events')

    }
  }, [isInfluencer])
  /**
   * Handle form changes.
   * @param {Object} event, Event trigerred for the input form
   */
  const onChangeFormValues = event => {
    const updatedValues = JSON.parse(JSON.stringify(formValues));
    const { name, value } = event.target;
    updatedValues[name] = value;
    setFormValues(updatedValues);
  };

  /**
   * Evaluate the response of the login request
   * @param {Object} event, Event triggered for the form
   */

  const twitterLogin = async () => {
    try {
      // let returnUrl =  'www.dev.gotmy.com/'
      const res = await userDAO.requestTwitterToken();
      
      const token = res.data.oauth_token;
      localStorage.setItem("twitterAction", "login");
      // TODO: move this url to constant
      // window.open(
      //   "https://api.twitter.com/oauth/authenticate?oauth_token=" + token
      // );
      window.location.href = "https://api.twitter.com/oauth/authenticate?oauth_token=" + token
    } catch (err) {
      if (err && err.response && err.response.status === 400) {
        setErrorForm(
          err.response.data.error.map(item => item.message).join(", ")
        );
      } else {
        setErrorForm(i18next.t("ModalLoginLogicComponentSomethingWentWrong"));
      }
    }
  };

  const linkedinLogin = async () => {
    const backURL = LOGIN_BACK_URL;
    localStorage.setItem("LinkedinAction", "login");
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENTID}&redirect_uri=${backURL}&scope=r_basicprofile,r_emailaddress,w_member_social,r_liteprofile&state=login`;
  };


  const facebookLogin = async () => {
    const backURL = LOGIN_BACK_URL;
    localStorage.setItem("facebookAction", "login");

    console.log("se ha seteado la accion de facebook", localStorage.getItem("facebookAction"));
    window.location.href = `https://www.facebook.com/v8.0/dialog/oauth?client_id=${FACEBOOK_CLIENTID}&redirect_uri=${backURL}/facebook&state=login&auth_type=rerequest&scope=email`;
  };

  const onSubmit = async event => {
    if (loading) return;
    event.preventDefault();

    try {
      await schema.validate(formValues);
    } catch (err) {
      setErrorForm(err.errors[0]);
      return;
    }
    
    setLoading(true);

    try {
      const response = await userDAO.login(formValues);
      let { data } = response;

      const env = {
        token: data.data.user_app_token
      }
      localStorage.setItem("env", JSON.stringify(env));
      
      if (env.token) {
        const purchasedEvents = await eventDAO.purchasedEvents(env.token);
        data.data.purchasedEvents= (purchasedEvents.data && purchasedEvents.data.data) || []; 

        // await dispatch(actions.getUser(env.token))
        dispatch(actions.setUser(data.data))
        const myPaymentMethods = await userDAO.getUserPaymentMethods(env.token);
        dispatch(actions.setUserPaymentMethods(myPaymentMethods.data));

        
        await dispatch(actions.getInfluencer(env.token))
        
      }
      try{
        document.getElementById("myModal_login").style.display = "none";
        document.querySelector(".modal-backdrop").style.display = "none";
      }catch(error){

      }
      setShowAlertGoodCredentials(true);
      if (!INFLUENCER_ONLY_MODE) {
        if (data.data.user_role === 0) {

          if (
            !data.data.user_name ||
            !data.data.user_location
          ) {
            history.push("/viewer_profile");
          } else {
            history.push(`/`);
          }
        }

        else if (data.data.user_role === 1) { // INFLUENCER
          /* If compulsory fields, modal */
          /* else, go to my_live_events */

          

          history.push('/my_live_events')



        }
        
        // document.location.reload(); NOT NECESSARY WITH THE NEW ACTIONS, NO BLANK RELOAD!!
      }
      else {     
        // const influencerResponse = await userDAO.profileInfluencer(env.token);
        // if (Object.keys(influencerResponse.data.influencer).length>0 ){
        //   history.push('/my_live_events')
        
        //   
  

      }

    } catch (err) {
      const { status, data } = err.response ? err.response : '';

      console.error("Error logining user", err)
      if (status === 404) {
        setErrorForm(data.error[0].message);
      } else if (status === 400) {
        setErrorForm(data.error[0].message);
      } else {
        setErrorForm(i18next.t("ModalLoginLogicComponentSomethingWentWrong"));
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onSubmit,
    errorForm,
    formValues,
    twitterLogin,
    linkedinLogin,
    facebookLogin,
    setErrorForm,
    onChangeFormValues,
    showAlertGoodCredentials,
    setShowAlertGoodCredentials
  };
};
