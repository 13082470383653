import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CardElement } from '@stripe/react-stripe-js';
import useStripeObject from "../../hooks/useStripeObject"
import Payment from "payment";
import * as actions from "../../store/actions/user";

const useViewerPaymentMethodsLogic = ({user}) => {
  const dispatch= useDispatch()
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [cvc, setCvc] = useState('');
  const [expiry, setExpiry] = useState('');
  const [focus, setFocus] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState("");
  const [paymentMethods, setPaymentMethods] = useState(null);
  const stripePromise = useStripeObject()
const [error, setError] = useState({})
  useEffect(() => {
    if (user.userCardMethods) {
      setPaymentMethods(user.userCardMethods)
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'name') {
      setName(value);
    }

    if (name === 'number') {
      e.target.value = formatCreditCardNumber(e.target.value);
      setNumber(value);
    }

    if (name === 'cvc') {
      e.target.value = formatCVC(e.target.value);
      setCvc(value);
    }

    if (name === 'expiry') {
      e.target.value = formatExpirationDate(e.target.value);
      setExpiry(value);
    }
  }

  const savePaymentMethod = async () => {
    let errorMessage = ""
    const myToken = JSON.parse(localStorage.getItem("env"));
    let expMonth = expiry.split("/")[0];
    let expYear = expiry.split("/")[1];
    var stripe  = await stripePromise;

    if (!stripe) {
      return
    }
    
    try {
      await dispatch(actions.addStripePaymentMethod(myToken.token, number,expMonth, expYear,cvc, stripe._apiKey));
    } catch (error) {
      console.log({error: error.response})
      if (error.response.status === 500) {
        errorMessage= "Something went wrong"
        dispatch(actions.setError(errorMessage));
        handleCloseModal();
      }
      
      if (error.response.status < 500) {
        // dispatch(actions.setError(error.response.data.error.message + error.response.data.error.param));
        dispatch(actions.setError(error.response.data.error.message ));
        console.log ({error: error.data})
        handleCloseModal();
      }
      // setError({status:true, message:error.response.data.error.message})
      return
    }
    handleCloseModal();
  }

  const deletePaymentMethod = async method => {

    const myToken = JSON.parse(localStorage.getItem("env"));
    try {
      await dispatch(actions.deletePaymentMethod(myToken.token, method));
    } catch (error) {
      dispatch(actions.setError("Something went wrong"));
    }
  }

  const clearNumber = (value = "") => {
    return value.replace(/\D+/g, "");
  }
  
  const formatCreditCardNumber = (value) => {
    if (!value) {
      return value;
    }
  
    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;
  
    switch (issuer) {
      case "amex":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 15)}`;
        break;
      case "dinersclub":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
        break;
    }
  
    return nextValue.trim();
  }
  
  const formatCVC =(value, prevValue, allValues = {}) => {
    const clearValue = clearNumber(value);
    let maxLength = 4;
  
    if (allValues.number) {
      const issuer = Payment.fns.cardType(allValues.number);
      maxLength = issuer === "amex" ? 4 : 3;
    }
  
    return clearValue.slice(0, maxLength);
  }
  
  const formatExpirationDate = (value) => {
    const clearValue = clearNumber(value);
  
    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
  
    return clearValue;
  }
  
  const formatFormData = (data) => {
    return Object.keys(data).map(d => `${d}: ${data[d]}`);
  }

  return { error, paymentMethods, cvc, setCvc, expiry, setExpiry, focus, setFocus, name, setName, number, setNumber, 
    handleInputChange, formatCreditCardNumber, formatCVC, 
    formatExpirationDate, formatFormData, savePaymentMethod, deletePaymentMethod,
    showModal, setShowModal, handleCloseModal, handleShowModal};

};

export default useViewerPaymentMethodsLogic