import React, { useRef, useEffect, useState } from 'react'
import { Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import VideoIcon from "assets/img/icons/video-camera-white.svg";

const UploadVideoActionButton = ({ text, handleSubmit, specialIcon }) => {

    const actionButtonInputReference = useRef(null);
    const [actionButtonIcon, setActionButtonIcon] = useState(VideoIcon);

    useEffect(() => {
        if (specialIcon) {
          setActionButtonIcon(specialIcon);
        }
      }, []);

    const onFileChange = (data) => {

        console.log("Datos del input onFileChange", data, data.target.files[0]);

        handleSubmit(data.target.files[0]);
    }

    const handleActionClick = () => {
        actionButtonInputReference.current.click();
    }

    return (
        <div>
            <Action
                children={<img
                    style={{
                        width: 30
                    }}
                    src={actionButtonIcon} alt="" />}
                style={{
                    backgroundColor: "#ff5a60",
                    outline: "none"
                }}
                text={text}
                onClick={handleActionClick}
            >
            </Action>
            <input type="file" ref={actionButtonInputReference} style={{ display: 'none' }} onChange={onFileChange} />
        </div>
    )
}

export default UploadVideoActionButton