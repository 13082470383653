import React , { useEffect , useState} from 'react'
import { withRouter } from "react-router-dom";
import NavBar_general from "components/comunes/navbar";
import styled from 'styled-components'
 const PrivacyPolicy = () => {
     const [scrolled, setScrolled] = useState(false)
     useEffect(()=>{
         document.querySelector('body').scrollTo(0,0)   
        setScrolled(true)
        },[])
    return (
        <Container>
            {scrolled && 
            <>
            
            <header className="App-header">

                <NavBar_general />


            </header>


            <div className="container">
                <div className="row mt-md-5 mt-3">
                    <div className="col-md-12 mt-md-5 mt-3" align="left">
                        <h1 className=" mb-4 bold mt-5">Privacy Policy</h1>
                        <p className="paragraph"><b>Updated as of Sep 24, 2020</b></p>
                        <p>gotmy is owned and operated by Got My Idol, Inc., a Delaware company. If you have any questions or comments about this Privacy Policy, please contact us at legal@gotmy.com.</p>
                        <p>By downloading or using our Service you agree to this Privacy Policy and our Terms of Service.</p>
                        <p>When you create a Viewer and/or an Influencer profile on gotmy, your personal information may be visible also to other users of gotmy.</p>
                        <p>If you are under the age of 16, you may not download or use gotmy. We do not knowingly collect or maintain personal information from children under age 16.</p>
                        <p>If you are between the ages of 16 and 17, you must be old enough to consent to the processing of your personal information in your country. In some countries, you must get your parent or guardian to read and agree to our Privacy Policy and our Terms of Service.</p>

                        <br/><p className="paragraph"><b>I. What This Privacy Policy Covers</b></p>
                            <p>This Privacy Policy describes how our mobile applications and websites collect, store, use, and share your personal information. It also describes how you can control and protect your privacy on our Service.</p>

                            <br/><p className="paragraph"><b>II. Controlling and Protecting Your Privacy</b></p>
                                <ul className="list">
                                    <li><p><b>Account Settings</b>. You can change your account settings at any time. You should review these settings regularly.</p></li>
                                    <li><p><b>Deleting Your Account</b>. You can edit or delete information that you upload to our Service and you can delete your account (including your Viewer and/or Influencer Profile), but you cannot recall or delete information that others have copied. When you delete your account, some or all of your information may remain on our servers, but you will not be able to access it.</p></li>
                                    <li><p><b>Deleting Your Information and Content</b>. You can request us to delete your posted information and content. To do so, send an email to support@gotmy.com from the email address associated with your account, requesting deletion.</p></li>
                                    <li><p><b>Location Information</b>. We may collect your location when you first register with our Service and each time you log in on a mobile device. We do not “track” your location, but we may collect location information from your device or browser when you log in or use our Service. In addition, as with most location-enabled applications, we may access your location any time your device is on, whether or not you are logged-in to our Service. But we do not share your precise location with other users.</p></li>
                                    <li><p><b>Password</b>. Store your password in a safe place, and do not share it with anyone. If you think someone has your password, you should change it immediately.</p></li>
                                    <li><p><b>Posting Information</b>. Posting personal information on public areas of our Service will make it publicly available. The personal information you post or share with others may in turn be shared by them with still other users and it may also show up when someone uses a public search engine (even if that person is not a user of our Service). Do not post personal information in public areas of our Service that you want to keep private.</p></li>
                                    <li><p><b>Third Parties</b>. We are not responsible for (and don’t have any control over) the privacy policies of third party websites and apps. We encourage you to read the privacy policies of each and every website and app you visit.</p></li>
                                    <li><p><b>Opting Out of Receiving Cookies</b>. You may set your browser to block some or all cookies. However, our Service might not fully function if you disable cookies. If you use multiple devices, browsers or computers, you will need to opt out of receiving cookies on each one. Also, if you change devices or computers you will need to repeat this opt-out process. Many Internet browsers automatically accept cookies. You can instruct your browser, by editing its options, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit.</p></li>
                                    <li><p><b>Do Not Track Signals</b>. Some Internet browsers include the ability to transmit “Do Not Track” signals, but there are no uniform standards for this. We do not process or respond to “Do Not Track” signals.</p></li>
                                </ul>

                                <br/><p className="paragraph"><b>III. Information We Collect</b></p>
                                    <p>We collect different types of personal information about you and your activities. We collect personal information when you register, log into, and use our Service, including the features you use, the pages and screens you visit, and the information you enter and share in your Viewer and/or Influencer profile on our Service. The specific examples in the following bullets are not meant to be exhaustive.</p>
                                    <ul className="list">
                                        <li><p><b>Email Address and Phone Number</b>. We may require a valid email address or phone number to register for our Service. This email will be stored in our servers while you have an account with any of our Services. We will use this email address to contact you, but you may opt out of promotional email contact in the Settings tab. We may use your phone number to verify your account, but will generally not otherwise contact you by phone.</p></li>
                                        <li><p><b>Viewer and/or Influencer Profile Information You Provide</b>. We collect the personal information that you provide to create your profiles on our Service.</p></li>
                                        <li><p><b>Automatically Collected Information</b>. We receive and record information from your device or browser, including your mobile device identification (AdID (Google), IDFA (Apple), device ID, etc.), IP address, cookie and beacon information, and geographic location. Unless you have disabled location collection at the device level, we will continue to collect location information even if you have opted out of sharing location information on your profile.</p></li>
                                        <li><p><b>Activity and Usage Information Including Your Content</b>. We collect information about the features you use, the pages and screens you visit, and your transactions with us and with our partners and vendors, including information about your use of products or features offered through our Service. We and certain third-party partners may access your private chats and postings in order to improve your experience on our Service, for content moderation, for safety and legal process purposes.</p></li>
                                        <li><p><b>Third Party Platform Registration</b> (such as Facebook, Twitter, Instagram, LinkedIn, Apple App Store or Google Play Store). When you download or register for our Service by or through a third party platform we may also access and/or collect certain information from your third party platform account or by cookies placed on your device by such third party site. For example, if you create your account on our Service using Facebook, we may use the following information from Facebook to establish your account: your email address, hometown, gender, profile photo, date of birth, friends list and userID. In addition, in the course of providing our Service on third party platforms, such as the Apple App Store or the Google Play Store, the platforms may collect information about you or receive information from us in order for that platform to provide and analyze their services. Please note that these platforms are developed and administered by people or companies not affiliated with us or any of our Services and that we are not responsible for the actions of those people or companies. You should carefully review the terms of service and privacy policies of the platforms that you use to access our Service.</p></li>
                                        <li><p>We may also collect information about you from other sources, such as other services and other users of our Service.</p></li>
                                    </ul>

                                    <br/><p className="paragraph"><b>IV. How We Use and Disclose the Information We Collect</b></p>
                                        <p>We process personal information: (i) to execute transactions that you request, (ii) when you provide your explicit consent, (iii) for our legitimate business interests such as maintaining our books and records, securing and protecting the integrity of our Service, and for Service development; and (iv) to meet applicable legal requirements.</p>
                                        <p>Specifically, we use and disclose your information in the following ways:</p>
                                        <ul className="list">
                                            <li><p><b>To customize your experience on our Service</b>, including suggestions about features you may enjoy or people you may want to meet.</p> </li>
                                            <li><p><b>To provide customer service</b> in response to questions or concerns you may bring to our attention.</p></li>
                                            <li><p><b>For marketing purposes</b>. We share information with companies we do not own, including information collected from cookies, such as your location, IP address, AdID or IDFA and certain demographic information, in order to allow our marketing partners to optimize our ad campaigns.</p></li>
                                            <li><p><b>For research</b>. To conduct research, including the numbers and types of visitors, and analyze traffic on our Service. We share some user data with companies we do not own to conduct this research.</p></li>
                                            <li><p><b>For development and testing</b>. To develop and test new products and features, and improve our current ones, including by conducting surveys of our users, which might be conducted with or by companies we do not own.</p></li>
                                            <li><p><b>Bug fixes and product improvement</b>. To find and fix technology problems. We send data to companies we do not own in order to analyze bugs in our websites and apps so that we can keep them running smoothly.</p></li>
                                            <li><p><b>Policy enforcement</b>. To enforce our Privacy Policy and Terms of Service. We send data to companies we do not own for the following reasons:</p></li>
                                            <ul className="list">
                                                <li><p>to verify accounts and activity;</p></li>
                                                <li><p>combat harmful conduct, such as abusive behavior and other violations of our Terms of Service;</p></li>
                                                <li><p>detect and prevent spam;</p></li>
                                                <li><p>detect and prevent fraud;</p></li>
                                                <li><p>maintain the integrity of our Service; and</p></li>
                                                <li><p>promote safety and security on our Service, such as monitoring for illegal activity and reporting to the appropriate authorities.</p></li>
                                            </ul>
                                            <li><p><b>Contacting you</b>. To contact you by email, text messaging, phone, autodialer or otherwise, including to invite you to participate in surveys and contests, and provide you updates and information about the Service and our other products and services. You can opt out of receiving promotional email, text messages or push notifications at any time in the Settings tab.</p></li>
                                            <li><p><b>Cookies and mobile ad identification</b>. We may set and access cookies or use AdID or IDFA information on your device or computer to customize your experience. These files help us with the following:</p></li>
                                            <ul className="list">
                                                <li><p><b>Authentication</b>: Cookies are used to keep you logged into our site as you navigate through different pages.</p></li>
                                                <li><p><b>Analytics and performance</b>: We use cookies to analyze platform performance and monitor how our visitors use the site. These help us to identify and fix bugs, understand and improve our services, and research and test new features.</p></li>
                                            </ul>
                                            <li><p><b>Cooperation with law enforcement</b>. We cooperate with government and law enforcement officials to enforce and comply with the law. We report threats of violence or self-harm and other illegal activities proactively, and we may disclose information about you to government or law enforcement officials in order to: (1) protect the safety and security of our users and members of the public or (2) satisfy subpoenas, court orders, or other governmental requests.</p></li>
                                            <li><p><b>Business transfers</b>. We may share your personal information with another business entity in connection with a proposed or actual sale, assignment, merger or transfer of all or part of our business or the acquisition of all or part of another business. In such cases, we would require any such business entity to honor the terms of this Privacy Policy.</p></li>
                                            <li><p><b>Management of our company</b>. We will process your information as needed to maintain our financial books and records, ensure the integrity and security of our systems and resources, operate our work environment, and respond to any potential compromise of anyone’s personal information.</p></li>
                                            <li><p><b>Service providers</b>. We transfer information to vendors, service providers, and other partners who support our business, such as providing technical infrastructure services, analyzing how our services are used, measuring the effectiveness of ads and services, providing customer service, facilitating payments, or conducting research and surveys. These partners agree to adhere to confidentiality obligations consistent with this Privacy Policy and the agreements we enter into with them.</p></li>
                                            <li><p><b>Personal Information</b>. Except for “Cooperation with Law Enforcement,” “Business Transfers” and “Service Providers” (all described above), in connection with account verification (and then only for that purpose), or to enforce our rights under this Privacy Policy and our Terms of Service, we do not share the following personal information with any third party not owned by Got My Idol, Inc. for any reason: your exact date of birth, your first name, your last name, your address, your phone number(s), or your email address.</p></li>
                                        </ul>

                                        <br/><p className="paragraph"><b>V. Data Security and Storage</b></p>
                                            <p>We use industry standard security measures to prevent the loss, misuse and alteration of the information under our control. However, we cannot guarantee that our security measures will prevent “hackers” from illegally obtaining this information. We will store and maintain your personal information for as long as necessary (i) for the purposes for which it was collected, (ii) to meet our current and future legal obligations, including compliance with our records retention practices, and (iii) as permitted to meet our legitimate interests. Our Service is hosted in the United States and we maintain your information in the United States and elsewhere on the cloud. If you are outside the United States, you agree to have your data transferred to and processed in the United States and elsewhere. When we transfer personal data outside of the European Economic Area, we ensure an adequate level of protection for the rights of data subjects based on the adequacy of the receiving country’s data protection laws and contractual obligations placed on the recipient of the data. A copy of these safeguards may be made available by writing to us at the address provided in the Contact Information section below.</p>

                                            <br/><p className="paragraph"><b>VI. Access to Your Personal Information</b></p>
                                                <p>If you wish to obtain a copy of your personal information, please contact support@gotmy.com. In order to protect the data of others, requests will only be honored that come from the email used to set up your account on our Service.</p>

                                                <br/><p className="paragraph"><b>VII. Deletion of Your Personal Information</b></p>
                                                    <p>You may delete your account at any time, and your personal information will be deleted in the normal course of business pursuant to our current data retention practices. You may request to have all your personal information deleted by contacting support@gotmy.com. We will comply with all deletion requests to the extent permissible by law and will not delete data that we are legally required to maintain (such as data subject to a current and valid preservation order or transaction data required for audit or substantiation purposes).</p>

                                                    <br/><p className="paragraph"><b>VIII. California Resident Rights</b></p>
                                                        <p>If you are a resident of the State of California, you have the following data protection rights:</p>
                                                        <ul className="list">
                                                            <li><p><b>California Privacy Rights</b>. California Civil Code Section 1798.83 permits our users who are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please contact us by email at support@gotmy.com. Please be advised that we are only required to respond to one request per user each year.</p></li>
                                                            <li><p>Our <b>California Do Not Track Notice</b>. We do not currently respond or take any action with respect to web browser “do not track” signals or other mechanisms that provide users the ability to exercise choice regarding the collection of personal information about that user’s online activities over time and across third-party web sites or online services. We may allow third parties, such as companies that provide us with analytics tools, to collect personal information about your online activities over time and across different apps or web sites when you use our Service.</p></li>
                                                            <li><p><b>Deletion of Content</b> from California Residents. If you are a California resident under the age of 18 and a registered user of the Service, California Business and Professions Code Section 22581 permits you to remove content or information you have publicly posted. If you wish to remove such content or information and you specify which content or information you wish to be removed, please contact us at support@gotmy.com and we will do so in accordance with applicable law. Please be aware that after removal you will not be able to restore removed content or information. In addition, such removal does not ensure complete or comprehensive removal of the content or information you have posted and there may be circumstances in which the law does not require or allow us to enable removal of the content or information.</p></li>
                                                        </ul>

                                                        <br/><p className="paragraph"><b>IX. European Economic Area Residents</b></p>
                                                            <p>If you are a resident of the European Economic Area, you have the following data protection rights:</p>
                                                            <ul className="list">
                                                                <li><p>If you wish to access, correct, update or request deletion, restrict processing, object to processing, or request porting of your personal information, you can do so at any time by contacting us at support@gotmy.com. Please see sections VI and VII above for more information.</p></li>
                                                                <li><p>You have the right to opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the "unsubscribe" or "opt-out" link in the marketing emails we send you. You can manage your account settings and email marketing preferences in the Settings section.</p></li>
                                                                <li><p>Similarly, if we have collected and processed your personal information with your consent, then you can withdraw your consent at any time in the Settings section. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p></li>
                                                                <li><p>You have the right to complain to a data protection authority about our collection and use of your personal information. For more information, please contact your local data protection authority.</p></li>
We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws. Notwithstanding the foregoing, we reserve the right to keep any information in our archives that we deem necessary to comply with our legal obligations, resolve disputes and enforce our agreements.<br/><br/>
</ul>

                                                                    <br/><p className="paragraph"><b>X. Changes to this Privacy Policy</b></p>
                                                                        <p>We may revise this Privacy Policy from time to time to reflect new or changed features and services, changes in the law, or changes in our use or collection of personal information. We display an effective date on this Privacy Policy so you can tell when there has been a change. If we make a change regarding our use or disclosure of personal information (date of birth, first or last name, physical address, phone number, email address, or location), we will provide a notice when you open our Service. If you object to any terms and conditions of the Privacy Policy or any subsequent changes or you become dissatisfied with our Service or Got My Idol, Inc. in any way, your only option is to delete your account. Your continued use of our Service after we post a revised Privacy Policy means that you agree to the revisions.</p>

                                                                        <br/><p className="paragraph"><b>XI. Language</b></p>
                                                                            <p>This Privacy Policy was written in English. If you are reading a translation and it conflicts with the English version, please note that the English version controls.</p>

                                                                            <br/><p className="paragraph"><b>XII. Our Contact Information</b></p>
                                                                                <p>For all requests concerning the security of your data, please contact our data protection officer at privacy@gotmy.com.</p>
                                                                                <p>If you have a particularly sensitive request, please contact our data protection officer via postal mail, as email communication cannot be guaranteed to be secure.</p>
                                                                                <p>If you have questions regarding this Privacy Policy, please contact us. If you are located in the European Union we are the controller of your personal information.</p>
                                                                                <p>Mailing Address: Atn. Data Protection, Got My Idol, Inc., 85 Grand Canal Dr, Ste 406, Miami, FL 33144, USA</p>
                                                                                <p>Web: www.gotmy.com</p>
                                                                                <p>Email: privacy@gotmy.com</p>
         
 

        </div>
      </div>
    </div>

          </>  
          }
        </Container>
    )
}
export default withRouter(PrivacyPolicy)
// export default PrivacyPolicy

const Container = styled.div`
.list{
list-style-type: disc;
}

p{
 font-weight:200 !important;
    color:#333 !important;
}
`