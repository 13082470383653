import React from 'react';
// import React, {Component,useState} from 'react';
import './style.scss';
import Photo from "../../../assets/img/icons/ic-photo-white.svg";
class ImageUploadChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {file: '',imagePreviewUrl: ''};
    this.state = {
      showMe: true
    }
    this.state = {
      hideToolTip: false
    }
  }

  _handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
    
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }
  handleChange(event){
    this.setState({hideToolTip:true});
  } 
  handleChange(){
    var x = document.getElementsByClassName("caja_none");
    x.style.display = "none";
    var x = document.getElementsByClassName("xd");
    x.style.display = "none";
  }
  operation(){
    this.setState({
      showMe:!this.state.showMe
    })
  }
  render() {
    let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (<img src={imagePreviewUrl} />);
    } else {
      $imagePreview = (<div className="previewText" id="previewText2"><form onSubmit={(e)=>this._handleSubmit(e)}>
         <label className="file_input" for="file-input">
            <img onClick={()=>this.operation()} className="input_label" id="inputId" src={Photo}/>
        </label>
      <input className="fileInput" id="file-input"
        type="file" 
        onChange={(e)=>this._handleImageChange(e)} />
    </form></div>);
    }
    const style = this.state.hideToolTip?{display:'none'}: {};
    return (
      <div className="previewComponent">
        
        <div className="imgPreview d-flex" id="imgIcon">
          {$imagePreview}
          <form><input type="text" className="inputNameGroup"></input></form>
        </div>
        
      </div>
      
    )
  }
}
  
export default ImageUploadChat

