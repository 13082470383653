import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
 const useTimezone = () => {
    const userData = useSelector (state=> state.user.user )

    const [timeZone, setTimeZone] = useState('')
    useEffect(()=>{
         if(userData && userData.user_location) { 
            const userTimezone = JSON.parse(userData.user_location)
            try {
               setTimeZone(userTimezone.data.zoneName);
             } catch (error) {}
         }
    },[userData])
    return {timeZone}
}

export default useTimezone