import styled from 'styled-components'

export const EventCardInfluencerContainer = styled.div`
.link_past{
  width: 25%;
}
.CardVideo {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-width: 114%;
    width: 120%;
  .Shadow {
    border-radius: 7px;
    //margin-right: 2rem;
  }

  .Header {
    position: relative;

    height: 150px;

    background: linear-gradient(
                    rgba(17, 16, 22, 0.85),
                    rgba(17, 16, 22, 0.85)
    );
    background-size: cover;
    border-radius: 7px 7px 0 0;


    .Time {
      position: absolute;

      bottom: 5px;

      right: 5px;

      background-color: rgba(0, 0, 0, 0.64);

      color: white;

      text-align: center;

      padding: 0px;

      border-radius: 14px;

      width: 52px;

      font-weight: 600;

      display: flex;

      justify-content: center;

      align-items: center;

      padding-bottom: 2px;

      font-size: 13px;
    }

  }

  .Div-asis {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: center;
    text-align: end;
    margin-left: auto;
    margin-right: 5px;
  }

  .Asistentes {
    font-size: 18px;
    display: flex;
    justify-content: flex-end;

    img {
      width: 17px;
      margin-right: 5px;
    }

  }

  .Tickets {
    font-size: 13px;
  }

  .Footer {
    padding: 10px;

    padding-bottom: 30px;




    .Pais {
      color: $color-grey-primary;
      font-size: 13px;
      display: flex;

      img {
        margin-right: 6px;
        width: 10px;
      }
    }

  }

  .Negro {
    display: flex;
    background-color: white;
    bottom: 0;
    color: black;
    width: 100%;

    .Foto {
      border-radius: 50%;
      border: 1px solid $color-primary-color;
      margin-right: 20px;
      align-self: center;
      width: 50px;
    }

    .Div-foto {
      position: relative;
      margin-right: 0;
    }

    .Icono {
      position: absolute;
      bottom: 0px;
      right: 18px;
      width: 20px;
    }

    .Nombre {
      font-size: 19px;
      display: block;
    }


    .Pais {
      color: $color-grey-primary;
      font-size: 13px;
      display: flex;

      img {
        margin-right: 6px;
        width: 15px;
      }
    }

    .Estrellas {
      margin-left: auto;
      margin-right: 15px;

      img {
        width: 75px;
      }
    }
  }

  .Titulo {
    font-size: 19px;
    display: block;
    margin-top: 15px;
    font-weight: 600;
    color:black;
  }

  .Cat {
    color: $color-primary-color;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
    margin-top:  0px;
  } .Fecha {
      color: $color-grey-primary;
      font-size: 14px;
      font-weight: 500;
    }
}








`
export const EventHappeningContainer = styled.div`
@media(max-width:768px ){
  /* margin:16px; */
}
    display: flex;
    flex-direction: column;
    .backgroundImage {
      background-size:'cover !important';
    }
    .Header {
      position: relative;
  
      height: 200px;
      background-size: cover;
      border-radius: 7px 7px 0 0;
  
      .Titulo {
        color: white;
        font-size: 22px;
        position: absolute;
        bottom: 50px;
        left: 15px;
        font-weight: 500;
      }
  
      .Tiempo {
        background-color: $color-primary-color;
        color: white;
        border-radius: 50px;
        padding: 5px 20px;
        font-size: 16px;
        position: absolute;
        top: 17px;
        left: 15px;
      }
  
      .Categoria {
        color: white;
        font-size: 16px;
        position: absolute;
        bottom: 20px;
        left: 15px;
      }
    }
  
    .Footer {
      display: flex;
      padding: 10px;
  
      .Foto {
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid $color-primary-color;
        margin-right: 30px;
        align-self: center;
      }
  
      .Div-foto {
        position: relative;
      }
  
      .Icono {
        position: absolute;
        bottom: 0px;
        right: 28px;
        width: 17px;
      }
  
      .Nombre {
        font-weight: 600;
        font-size: 16px;
        display: block;
        margin-bottom: 5px;
      }
  
      .Pais {
        color: $color-grey-primary;
        font-size: 14px;
        display: flex;
  
        img {
          margin-right: 4px;
          width: 15px;
        }
      }
  
      .Estrellas{
        padding-top: 7px;
        margin-left: auto;
        margin-right: 15px;
      }

`


export const EventCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  .Start{
    position: absolute;
    left: 20px;
    top: 20px;
    color: white;
    font-size: 16px;
    border-radius: 15px;
    padding: 3px 13px;
    background-color: #ff5a60;
}
  .Header {
    position: relative;

    height: 180px;
    background-size: cover;
    border-radius: 7px 7px 0 0;
    .Guardar {
      z-index: 99;
    }
    .saveCircle{
      position: absolute;
      top: 10px;
      right: 10px;

      padding: 10px;
      border-radius: 50%;
      background-color: #000;
      opacity: 60%;
      border: 0.5px solid #949494 ;
    }

    @media (min-width: 1600px) {
      .saveCircle {
        padding: 15px;
      }
      height:220px;
    }
  }

  .Negro {
    @media (min-width: 1600px) {
     padding:10px; 
     .Foto{
      height: 55px !important;
      width: 55px !important;
     } 
    }
    display: flex;
    padding: 8px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    color:white;
    width: 100%;
    .Foto {
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid $color-primary-color;
      margin-right: 20px;
      align-self: center;
      height: 50px ;
      width: 50px ;
    }

    .Div-foto {
      position: relative;
    }

    .Icono {
      position: absolute;
      bottom: 0px;
      right: 18px;
      width: 20px;
    }

    .Nombre {
      font-size: 18px;
      display: block;
    }

    .Pais {
      color: $color-grey-primary;
      font-size: 13px;
      display: flex;

      img {
        margin-right: 6px;
        width: 15px;
      }
    }

    .Estrellas{
      margin-left: auto;
      margin-right: 15px;
      img{
        width: 75px;
      }
    }
  }
  .Div-asis{
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: center;
    text-align: end;
    margin-left: auto;
    margin-right: 5px;
  }
  .Asistentes{
    font-size: 19px;
    display: flex;
    justify-content: flex-end;
    img{
      width: 17px;
      margin-right: 5px;
    }

  }
  .Tickets{
    font-size: 13px;
    font-weight: 600;
  }

  .Footer {
    letter-spacing: 0.025em;
    display: flex;
    padding:6px;
      .Texto{
        margin-left: 25px;
        display: flex;
        flex-direction: column;

      .Tit{
      font-size: 20px;
        font-weight: 500 !important;
        padding-top: 5px;
        color:black;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 250px;
        
      }
      .Cat{
        color: $color-primary-color;
        font-size: 14px;
        font-weight: 500;
        @media (min-width: 1600px) {
          font-size:16px;
          
        }
      }
      .Hor{

        font-size:15px;
        color: $color-grey-primary;
        display:flex;
        font-weight: 500;
        margin-top: 4px;
        img {
          margin-top:-2px;
          margin-right: 6px;
          width: 17px;
        }
      }
    }
    .Fecha{
      display: flex;
      margin-top:-4px;
      flex-direction: column;
      justify-content: start;
      margin-left: 5px;
      .Dia{
        font-size: 30px;
        font-weight: 500 !important;
        color:black;
      }
      .Mes{
        font-weight: 500 !important;
        margin-top:-8px;
        color: $color-primary-color;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
    .Pais {
      color: $color-grey-primary;
      font-size: 13px;
      display: flex;

      img {
        margin-right: 6px;
        width: 10px;
      }
    }
  }


  @media (min-width: 1600px) {
      padding:8px;
      .Fecha{
        .Mes{
          margin-top: -4px;
        }
        .Dia{
          font-size: 34px;
        }
      }
      .Texto{ 
        .Tit{font-size:22px;}
      .Cat{font-size:16px;}
      .Hor {
        font-size: 18px;
      }
     
    }
    } 

    @media (min-width: 900px) and (max-width: 1250px) {
  
    .Footer {
      .Hor{
        color: $color-grey-primary;
        display:flex;
        font-weight: 500;
        margin-top: 4px;
        width: 90%;
        img {
          margin-right: 6px;
          width: 17px;
        }
      }
    }
  } 



   @media (min-width: 1600px) {
  .CardUpcoming{

    .Header{
      height:225px;
    }
   
  }

  } 

`
    
  




