import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import PastEventCard from '../../comunes/Cards/PastEventCard'

const variants = {
    enter: direction => ({
        x: direction > 0 ? 1000 : -1000,
        opacity: 0
    }),
    center: {
        x: 0,
        opacity: 1
    },
    exit: direction => {
        return {
            x: direction > 0 ? -1000 : 1000,
            opacity: 0.9
        };
    }
};
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

export const SliderVideo = ({ noMargin, events, loading }) => {
    const env = JSON.parse(localStorage.getItem("env") || "{}");

    const [[page, direction], setPage] = useState([0, 0]);

    const paginate = direction => {
        setPage([page + direction, direction]);
    };

    const index = wrap(0, events.length, page);

    return (
        <div className="mb-5" key={page + 99} style={{ margin: noMargin ? '16px 0px' : '16px', position: 'relative', width: '100%', height: 270 }}>
            <AnimatePresence custom={direction} key={page}>
                <motion.div
                    key={page + 99}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    drag="x"
                    transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 }
                    }}
                    dragConstraints={{ left: 0, right: 0 }}
                    style={{
                        cursor: 'pointer',
                        height: 270,
                        width: "100%",
                        position: "absolute",
                        left: 0,
                        top: 0
                    }}
                    dragElastic={0.9}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -500) {
                            paginate(1);
                        } else if (swipe > 500) {
                            paginate(-1);
                        }
                    }}
                >
                    {events.length > 0 && <PastEventCard key={index} user_img={events[index].user.user_avatar} bg_img={events[index].event.event_cover_image}
                        name={events[index].user.user_username} time={events[index].event.event_start_hour} titulo={events[index].event.event_title}
                        uuid={events[index].event.event_uuid} cat={events[index].event.event_category_ide}
                        event={events[index].event}
                    />}
                </motion.div>

            </AnimatePresence>
            <div className="next" onClick={() => paginate(1)}>
                {"‣"}
            </div>
            <div className="prev" onClick={() => paginate(-1)}>
                {"‣"}
            </div>
        </div>
    )
}
