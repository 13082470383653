import React, { useEffect, useState, useRef } from "react";
import SweetAlert from "sweetalert2-react";
import Sad from "../../assets/img/icons/sad-face-in-rounded-square.svg";
import AutocompleteLocation from '../comunes/autocompleteLocation/AutocompleteLocation'
import moment from "moment";
import AvailableBox from "../comunes/social/AvailableBox";
import SocialConnectBox from "../comunes/social/SocialConnectBox";
import ChooseLanguage from '../comunes/ChooseLanguage/ChooseLanguage'
import Loader from "components/comunes/loader";
import ModalCrop from "../publish-live-events/live_event_information/ModalCrop";
import useMobileNav from '../../hooks/useMobileNav'

const BecomeInfluencer = ({ userValues, loadingInitial, showErrorValidation, missingFields, resetErrorValidation,
  isInfluencer, imagePreview, onChangeUserValues,
  onChangeLocationValues, languages, onChangeDatePickerValues,
  onUpdateLanguage, avaliable, languagesSelected, handleAvaliable, handleImageChange, loadedImg, handleImageLoad }) => {
  const { location, name, lastname, username, birthdate, biography, avatar } = userValues;
  const dateLimit = moment(new Date()).subtract(16, "y").format("YYYY-MM-DD");
  const [modalCrop, setModalCrop] = useState(false)
  const [croppedImageUrl, setCroppedImageUrl] = useState('')
  const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: 1 / 1 })
  const imageRef = useRef(null)
  const onHandleInputChange = e => {
    setModalCrop(true)
    handleImageLoad(e)
  }
  const handleEditPhoto = () => {
    
    setModalCrop(true)
  }
  const { mobileNav } = useMobileNav()

  return (
    <div className="container public_profile">
      {!loadingInitial ? (
        <>
          <SweetAlert imageUrl={Sad} show={showErrorValidation} title={missingFields.message}
            text={missingFields.missingFields} onConfirm={() => resetErrorValidation()} />
          <div className="row">
            <div style={{ margin: !isInfluencer && "0 auto" }} className={`${isInfluencer && "col-xl-7 "}  colProfile`}>
              <form noValidate>
                <div id="inputsGroupTop" className="information_profile">
                  <div>
                    <div style={{ marginLeft: '-45px' }} className="previewComponent">
                      <h2 className="profile_title">Profile Image</h2>
                      <div style={{ height: '14rem', width: '14rem' }} className="imgPreview" onClick={handleEditPhoto}>{imagePreview}</div>
                      <ModalCrop
                        mobileNav={mobileNav}
                        onHandleInputChange={onHandleInputChange}
                        setCroppedImageUrl={setCroppedImageUrl}
                        handleImageChange={handleImageChange}
                        crop={crop}
                        setCrop={setCrop}
                        loadedImg={loadedImg}
                        setModalCrop={setModalCrop}
                        modalCrop={modalCrop}
                        modalTitle="Profile Image" />
                    </div>
                  </div>

                  <div id="topRightForm" className="">
                    <div className=" ">
                    </div>
                    <div className="d-xl-flex">
                      <div className="form-group  d-flex ">
                        <div className="country_select">
                          <label>Location</label>
                          <label className="required">*</label>
                          {
                            userValues && userValues.location ? 
                            <div  >
                              <AutocompleteLocation onChangeLocationValues={onChangeLocationValues}
                                userValues={userValues} initialLocation={userValues.location} />
                            </div>
                            : 
                            <div  >
                              <AutocompleteLocation onChangeLocationValues={onChangeLocationValues}
                                userValues={userValues} initialLocation={''} />
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottomGroup ">
                  <div className="biographyTextTags">
                    <div className="required-field field">
                      <label>Biography</label>
                      <label className="required">*</label>
                    </div>
                    <p>
                      {biography ? 500 - biography.length + " remaining" : "500 characters remaining"}
                    </p>
                  </div>
                  <textarea style={{ padding: 24, borderRadius: '8px', width: '100%' }} name="textarea" rows="6"
                    maxLength="500" className="textarea_update" type="text"
                    value={biography} onChange={onChangeUserValues} name="biography" />
                  <div className="required-field field">
                    <label>Choose your language </label>
                    <label className="required">*</label>
                  </div>
                  {!loadingInitial && <ChooseLanguage isInfluencer={isInfluencer} languagesSelected={languagesSelected}
                    languages={languages} onUpdateLanguage={onUpdateLanguage} />}
                </div>
              </form>
              <div style={{ padding: mobileNav && '0px' }} className={`${isInfluencer && " mt-4 mt-md-0 col-xl-5"}`}>
                <AvailableBox
                  isInfluencer={isInfluencer}
                  available={avaliable}
                  handleAvailable={handleAvaliable} />
                <SocialConnectBox />
              </div>

              {!userValues.validated_mobile ?
                <div>
                  <div className="required-field field">
                    <label>(Can be added later)</label>
                  </div>
                  <button
                    className="Btn-rojo-join boton_go"
                    data-toggle="modal"
                    data-target="#myModal_verify_phone">
                    Add phone
                  </button>
                </div> :
                <button
                  className="Btn-rojo-join boton_go">
                  Phone verified
                    </button>
              }
            </div>
          </div>
        </>
      ) : (
          <Loader />
        )}
    </div>

  )
}
export default BecomeInfluencer