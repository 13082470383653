import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import EventCard from "../Cards/EventCard";
import EventHappeningCard from "../Cards/EventHappeningCard";


const variants = {
    enter: direction => ({
        x: direction > 0 ? 1000 : -1000,
        opacity: 0
    }),
    center: {
        x: 0,
        opacity: 1
    },
    exit: direction => {
        return {
            x: direction > 0 ? -1000 : 1000,
            opacity: 0
        };
    }
};
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};
export const SlideCards = ({ events, loading, happening, noMargin }) => {
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    
    const [[page, direction], setPage] = useState([0, 0]);

    const paginate = direction => {
        setPage([page + direction, direction]);
    };

    const index = wrap(0, events.length, page);


    return (
        <div key={page + 99} style={{ margin: noMargin? '0px' : '16px', position: 'relative', width: '100%', height: 270 }}>
            <AnimatePresence custom={direction} key={page}>
                <motion.div
                    key={page + 99}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    drag="x"
                    transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 }
                    }}
                    dragConstraints={{ left: 0, right: 0 }}
                    style={{
                        cursor: 'pointer',
                        // zIndex:20,
                        height: 270,
                        width: "100%",
                        position: "absolute",
                        left: 0,
                        top: 0
                    }}
                    dragElastic={0.9}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -500) {
                            paginate(1);
                        } else if (swipe > 500) {
                            paginate(-1);
                        }
                    }}
                >
                    {(events.length > 0 && !happening) ?
                        <EventCard
                            loading={false}
                            extraStyles="col-12 col-lg-4 col-md-6"
                            showFavorite={env.token}
                            showCountdown={false}
                            key={index + 99}
                            event={events[index]}
                            uniqueEvent={true}
                        /> : (events.length > 0 && happening) &&
                        <EventHappeningCard
                            key={index + 99}
                            event={events[index]} />
                    }
                </motion.div>

            </AnimatePresence>
            { events.length > 1 && <div className="next" onClick={() => paginate(1)}>
                {"‣"}
            </div>
            }
            { events.length > 1 && <div className="prev" onClick={() => paginate(-1)}>
                {"‣"}
            </div>
            }
        </div>
    )
}
