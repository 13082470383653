import React, { useRef } from "react";
import Photo from "assets/img/icons/ic-photo-white.svg";
import imagePlaceHolder from "assets/img/imagePlaceHolder.jpg";

const SetCoverImage = ({
  event_cover_image,
  defaultPlaceHolder,
  handleEditPhoto,
  mobileNav,
  onHandleInputChange,
}) => {
  const actionButtonInputReference = useRef(null);

  const handleActionClick = () => {
    actionButtonInputReference.current.click();
  };

  return (
    <>
      <div
        style={{
          border: "1px solid #D8DCE5",
          borderRadius: "8px",
          width: "100%",
          height: "100%",
        }}
      >
        {event_cover_image !== "" ? (
          <img
            onClick={handleActionClick}
            style={{
                cursor: "pointer",
                width: "100%",
              }}
            src={event_cover_image}
            alt=""
          />
        ) : (
          <img
            onClick={handleActionClick}
            style={{
              cursor: "pointer",
              width: "100%",
            }}
            src={defaultPlaceHolder}
            alt=""
          />
        )}
      </div>
      <form noValidate>
        <label
          for="file"
          className="file_input"
          id="file-input-change"
          for="file-input"
        >
          <img
            className="input_label grow"
            id="input_label_change"
            src={Photo}
            alt="Select cover"
          />
        </label>
        <input
          className="fileInput"
          id="file-input"
          type="file"
          name="event_cover_image"
          accept="image/png, image/gif, image/jpeg"
          ref={actionButtonInputReference}
          onChange={onHandleInputChange}
        />
      </form>
    </>
  );
};
export default SetCoverImage;
