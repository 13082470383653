import compose from "recompose/compose";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import Photo from "../../../assets/img/icons/ic-photo-white.svg";
import Menu from "../../comunes/navbar_profile";
import { withRouter } from "react-router-dom";
import SweetAlert from "sweetalert2-react";
import Sad from "../../../assets/img/icons/sad-face-in-rounded-square.svg";
import PublicInfoTab from './PublicInfoTab'
import useMyPublicProfileLogic from "./MyPublicProfileLogic";
import { Spinner } from "react-bootstrap";
import Loader from "components/comunes/loader";
import { Modal, Tabs, Tab } from "react-bootstrap";
import "./style.scss";
import VerifyInfluencer from "components/influencer_profile/verify_influencer_profile";
import SkillCategories from "../my_favorite_categories/skill_categories";
import moment from "moment";
import DatePicker from "react-date-picker";
import MyFavoriteCategories from './MyFavoriteCategories'
import { PublicProfileContainer } from './styles'
import { RESTRICTED_VIEWS } from "../../../environment/environment";
import useMobileNav from '../../../hooks/useMobileNav'
import i18next from 'i18next';

const dateLimit = moment(new Date())
  .subtract(16, "y")
  .format("YYYY-MM-DD");

const MyPublicProfile = ({ history, match, user }) => {

  useEffect(() => {

    try {
      var birthdate = 0;

      try {
        birthdate = moment.unix(parseInt(user.user.user_birthdate) / 1000);
      }
      catch (error) {
        console.error(error)
      }

      setUserValues({
        ...userValues,
        name: user.user.user_name,
        lastname: user.user.user_lastname,
        username: user.user.user_username,
        birthdate: birthdate.format("YYYY-MM-DD")
      })
    } catch (error) {
      console.error(error)
    }

    try{
      console.log("user.isInfluencer", user, user.isInfluencer)
      setUserInfluencer(user.isInfluencer)
    }catch(error){

    }

  }, [user])

  

  const { mobileNav } = useMobileNav()
  const [userInfluencer, setUserInfluencer] = useState(user.isInfluencer);
  const {
    onChangeUserValues, onChangePresentationVideo, onChangeLocationValues, onChangeDatePickerValues, userValues, presentationVideo, categories, languages,
    setLanguagesSelected, missingFields, setMissingFields, onSubmit, showErrorValidation, setShowErrorValidation, onChangeCategories, setSelectedCategory,
    setUserValues, setCountries, handleImageChange, languagesSelected, loadingInitial, loadingImage, updating,
    isInfluencer, avaliable, handleAvaliable, showModalCategories, setShowModalCategories, tabKeySelected,
    setTabKeySelected, selectedCategory, loadedImg, handleImageLoad } = useMyPublicProfileLogic({ history, match, user });

    useEffect(() => {
      console.log("presentationVideo 2 ", presentationVideo)
  
    }, [presentationVideo])

  const { avatar } = userValues;
  const onUpdateLanguage = (lang) => {
    setLanguagesSelected(lang)
    setUserValues({ ...userValues, languages: lang.map(item => item.lang_ide) });
  }
  const resetErrorValidation = () => {
    setShowErrorValidation(false)
    setMissingFields('')
  }

  const onUpdateCategories = (category) => {
    onChangeCategories(category.cate_ide);
    setSelectedCategory(
      category.cate_ide === 0 ? "0" : category.cate_ide
    );
  }

  const imagePreview = avatar ? (
    <>
      {!loadingImage ? (
        <>
          <img style={{ maxHeight: mobileNav && '15rem', objectFit: mobileNav && 'cover', width: 'mobileNav' && '100%' }} src={avatar} alt="avatar" />
          <form id="form_update" noValidate>
            <label
              className="file_input"
              id="fileInputIcon"
              htmlFor="file-input"
            >
              <img className="input_label grow" id="input_label_change" src={Photo} alt="" />
            </label>
          </form>
        </>
      ) : (
        <Loader />
      )}
    </>
  ) : (
    <div className="previewText">
      {!loadingImage ? (
        <form>
          <label className="file_input" htmlFor="file-input">
            <img className="input_label" src={Photo} alt="avatar" />
          </label>
        </form>
      ) : (
        <Spinner animation="border" />
      )}
    </div>
  );
  const publicInfoTabProps =
  {
    userValues, loadingInitial, showErrorValidation, missingFields, resetErrorValidation,
    isInfluencer, imagePreview, onChangeUserValues, onChangePresentationVideo, onChangeLocationValues, languages, onChangeDatePickerValues, dateLimit,
    onUpdateLanguage, avaliable, languagesSelected, handleAvaliable, handleImageChange, loadedImg, handleImageLoad, userInfluencer, user
  }
  return (
    <PublicProfileContainer>
      <div>
        <div>
          <Menu onSave={onSubmit} loading={updating} isInfluencer={isInfluencer} title={isInfluencer ? "My Influencer Profile" : "Viewer Profile"} />
        </div>
        <div className="t">
          <Tabs
            defaultActiveKey="profile"
            activeKey={tabKeySelected}
            onSelect={k => setTabKeySelected(k)}
          >
            <Tab style={{ marginRight: !mobileNav && '45px' }} eventKey="profile" title={i18next.t("MyPublicProfilePublicProfile")}>
              <div className="tab-content">

                <PublicInfoTab presentationVideo={presentationVideo} {...publicInfoTabProps} />
              </div>
            </Tab>
            <Tab style={{ marginRight: '45px' }} eventKey="categories" title={i18next.t("MyPublicProfileFavCat")}>
              <div className="">
                {!isInfluencer ? (
                  <MyFavoriteCategories userValues={userValues} onUpdateCategories={onUpdateCategories} categories={categories} history={history} match={match} />
                ) : (
                  <div className="container viewer_categories">
                    <h2 className="mb-2 categories-h2">
                    {i18next.t("MyPublicProfileInfluencerAddCategories")}                    </h2>
                    <p style={{ color: 'grey', fontSize: '1.1rem' }}>
                    {i18next.t("MyPublicProfileInfluencerAddCategoriesDescription")}                    </p>
                    {userValues.username && (
                      <SkillCategories categories={categories} userCategories={userValues.categories}
                        idCategory={selectedCategory} onSave={onSubmit} loader={updating} onChangeCategory={onChangeCategories}
                      />
                    )}
                    <button data-toggle="modal" data-target="#myModal_categories" data-dismiss="modal" className=" Btn-rojo button_profile_influencer" onClick={() => setShowModalCategories(true)}>
                    {i18next.t("MyPublicProfileInfluencerAddCategoriesAdd")}
                    </button>
                  </div>
                )}
              </div>
            </Tab>

            {isInfluencer && false && (
              <Tab
                eventKey="verifiy-influencer"
                title="Verify Influencer Profile"
              >
                <div className="tab-content">
                  <VerifyInfluencer />
                </div>
              </Tab>
            )}
          </Tabs>
        </div>
      </div>

      <Modal
        show={showModalCategories}
        onHide={() => setShowModalCategories(!showModalCategories)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="mb-2 categories-h2">
              Select your favorite category
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MyFavoriteCategories userValues={userValues} categories={categories} onUpdateCategories={onUpdateCategories} history={history} match={match} />
        </Modal.Body>
      </Modal>
    </PublicProfileContainer>
  );
};


const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const enhance = compose(withRouter, connect(mapStateToProps))
export default enhance(MyPublicProfile);

