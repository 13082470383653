import React, { useState, useEffect } from "react";
import "./styles.scss";
import videojs from 'video.js';
import { parseLocale } from '../../../../utils/Localization'

var playerGlobal = null;
var playerError = false;

const HLSPlayer = ({ streamingData, onStreamConnected, ticketInUse }) => {

  videojs.options.autoplay = 'any';

  useEffect(() => {
    videojs('my-player',
      {
        controls: true,
        autoplay: 'any',
        preload: 'auto',
        liveui: false,
        bigPlayButton: true,
        techCanOverridePoster: false,
        suppressNotSupportedError: true,
        languages: {
          es: {
            Play: "Reproducir",
            Pause: "Pausa",
            Replay: "Repetir",
            Duration: "Duración",
            LIVE: "EN VIVO",
            Loaded: "Cargado",
            Fullscreen: "Pantalla completa",
            NonFullscreen: "Salir pantalla completa",
            Mute: "Silenciar",
            Unmute: "Con sonido"
          }
        },
        language: parseLocale(),
        poster: streamingData.posterURL
      }, function () {

        playerGlobal = this;

        this.on('error', function (event) {
          onStreamConnected(false);
          playerError = true;

          setTimeout(() => {
            playerError = false;
            playerGlobal.src({ type: 'application/x-mpegURL', src: String(streamingData.src), withCredentials: false });
            checkPlayerReady();
          }, 5000);
        })

        this.on('loadeddata', function (event) {
          onStreamConnected(true);
        })
      });
  }, []);

  const checkPlayerReady = () => {
    setTimeout(() => {
      if (!playerError) {
        onStreamConnected(true);

        try{
          playerGlobal.play();
        }
        catch(error){

        }
      }
    }, 2000)
  }

  useEffect(() => {

    if(ticketInUse && playerGlobal){
      try{
        playerGlobal.dispose();
      }catch(error){

      }
    }

  }, [ticketInUse]);

  return (

    <video
      id="my-player"
      className="vjs-matrix video-js"
      playsinline
      playsInline
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain"
      }}
    >
      <source src={streamingData.src} type="application/x-mpegURL" />
      <p className="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
    <a href="https://videojs.com/html5-video-support/" target="_blank">
          supports HTML5 video
    </a>
      </p>
    </video>
  );
};

export default HLSPlayer;
