import React, { useEffect, useState, useRef } from "react";
import SweetAlert from "sweetalert2-react";
import Sad from "../../../assets/img/icons/sad-face-in-rounded-square.svg";
import AutocompleteLocation from "../../comunes/autocompleteLocation/AutocompleteLocation";
import DatePicker from "react-date-picker";
import moment from "moment";
import AvailableBox from "../../comunes/social/AvailableBox";
import SocialConnectBox from "../../comunes/social/SocialConnectBox";
import ChooseLanguage from "../../comunes/ChooseLanguage/ChooseLanguage";
import Loader from "components/comunes/loader";
import AddCalendar from "../../../assets/img/icons/ic-addcalendar.svg";
import ModalCrop from "../../publish-live-events/live_event_information/ModalCrop";
import useMobileNav from "../../../hooks/useMobileNav";
import i18next from "i18next";
import "../../../assets/css_general/video-react.css";
import {
  Player,
  BigPlayButton,
  ControlBar,
  PlayToggle,
  VolumeMenuButton,
  FullscreenToggle,
} from "video-react";

const PublicInfoTab = ({
  userValues,
  loadingInitial,
  showErrorValidation,
  missingFields,
  resetErrorValidation,
  isInfluencer,
  imagePreview,
  onChangeUserValues,
  onChangePresentationVideo,
  presentationVideo,
  onChangeLocationValues,
  languages,
  onChangeDatePickerValues,
  onUpdateLanguage,
  avaliable,
  languagesSelected,
  handleAvaliable,
  handleImageChange,
  loadedImg,
  handleImageLoad,
  userInfluencer,
  user,
}) => {
  const {
    location,
    name,
    lastname,
    username,
    birthdate,
    biography,
    avatar,
  } = userValues;
  const dateLimit = moment(new Date())
    .subtract(16, "y")
    .format("YYYY-MM-DD");
  const [modalCrop, setModalCrop] = useState(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 1 / 1 });
  const imageRef = useRef(null);
  const uploadPresentationVideoRef = useRef(null);

  console.log("user", user);

  const handlePresentationVideoClick = () => {
    uploadPresentationVideoRef.current.click();
  };

  const onHandleInputChange = (e) => {
    setModalCrop(true);
    handleImageLoad(e);
  };
  const handleEditPhoto = () => {
    setModalCrop(true);
  };
  const { mobileNav } = useMobileNav();

  const videoPreview = () => {
    var influencerData = null;

    try {
      influencerData = user.influencer;
    } catch (error) {}

    if (userInfluencer && presentationVideo && presentationVideo.videoURL) {
      return (
        <div className="InfluencerPresentationEditProfile">
          <div className="InfluencerPresentationVideo InfluencerPresentationEditProfile">
            <Player
              autoPlay={false}
              muted={true}
              loop={true}
              src={presentationVideo.videoURL}
            >
              <BigPlayButton position="center" />
            </Player>
          </div>
        </div>
      );
    } else if (userInfluencer && influencerData.influencer_presentation_video) {
      return (
        <>
          <div className="">
            <label>{i18next.t("PublicInfoTabCurrentCoverVideo")}</label>
          </div>
          <div className="InfluencerPresentationEditProfile">
            <div className="InfluencerPresentationVideo InfluencerPresentationEditProfile">
              <Player
                autoPlay={false}
                muted={true}
                loop={true}
                src={influencerData.influencer_presentation_video}
              >
                <BigPlayButton position="center" />
              </Player>
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="container public_profile">
      {!loadingInitial ? (
        <>
          <SweetAlert
            imageUrl={Sad}
            show={showErrorValidation}
            title={missingFields.message}
            text={missingFields.missingFields}
            onConfirm={() => resetErrorValidation()}
          />
          <div className="row">
            <div
              style={{ margin: !isInfluencer && "0 auto" }}
              className={`${isInfluencer && "col-xl-7 "}  colProfile`}
            >
              <form noValidate>
                <div
                  style={{ marginTop: mobileNav && "0px" }}
                  id="inputsGroupTop"
                  className="information_profile"
                >
                  <div>
                    <div className="d-block d-md-none">
                      <div
                        style={{ position: "relative" }}
                        className="imgPreviewMobile"
                        onClick={handleEditPhoto}
                      >
                        {imagePreview}
                      </div>
                    </div>
                    <div
                      style={{
                        marginLeft: !mobileNav && "-45px",
                        display: mobileNav && "flex",
                        flexDirection: mobileNav && "column",
                        alignContent: "center",
                      }}
                      className="d-none d-md-block previewComponent"
                    >
                      <h2 className="profile_title">
                        {i18next.t("PublicInfoTabPublicInfo")}
                      </h2>
                      <div
                        style={{ height: "14rem", width: "14rem" }}
                        className="imgPreview"
                        onClick={handleEditPhoto}
                      >
                        {imagePreview}
                      </div>
                      <ModalCrop
                        mobileNav={mobileNav}
                        onHandleInputChange={onHandleInputChange}
                        setCroppedImageUrl={setCroppedImageUrl}
                        handleImageChange={handleImageChange}
                        crop={crop}
                        setCrop={setCrop}
                        loadedImg={loadedImg}
                        setModalCrop={setModalCrop}
                        modalCrop={modalCrop}
                        modalTitle="Edit your Avatar"
                      />
                    </div>
                  </div>

                  <div id="topRightForm" className="px-2 px-md-0">
                    <div className=" ">
                      <div className="form-group ">
                        <div className="required-field">
                          <label htmlFor="name">
                            {i18next.t("PublicInfoTabFirstName")}
                          </label>
                          <label className="required">*</label>
                        </div>
                        <input
                          required
                          type="text"
                          className="form-control textInput modal_register modal_update"
                          style={{ height: "3rem" }}
                          name="name"
                          value={name}
                          onChange={onChangeUserValues}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="name">
                          {i18next.t("PublicInfoTabLastName")}
                        </label>
                        <label className="required">*</label>

                        <input
                          required
                          style={{ height: "3rem" }}
                          type="text"
                          className="form-control modal_register modal_update textInput"
                          name="lastname"
                          value={lastname}
                          onChange={onChangeUserValues}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="required-field">
                        <label htmlFor="username">
                          {i18next.t("PublicInfoTabChooseUsername")}
                        </label>
                        <label className="required">*</label>
                      </div>
                      <input
                        type="text"
                        className="form-control textInput modal_register modal_update"
                        style={{ height: "3rem" }}
                        name="username"
                        value={username}
                        onChange={onChangeUserValues}
                      />
                    </div>
                    <div className="d-xl-flex">
                      <div className="form-group  d-flex ">
                        <div className="country_select">
                          <p>{i18next.t("PublicInfoTabLocation")}</p>
                          <div>
                            <AutocompleteLocation
                              onChangeLocationValues={onChangeLocationValues}
                              userValues={userValues}
                              initialLocation={location}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="birthday-field">
                        <label htmlFor="birthday">
                          {i18next.t("PublicInfoTabBday")}
                        </label>
                        <br />
                        <DatePicker
                          value={
                            moment(birthdate).isValid()
                              ? new Date(birthdate)
                              : new Date(dateLimit)
                          }
                          clearIcon={null}
                          format="MM-dd-yyy"
                          className="date-picker"
                          onChange={onChangeDatePickerValues}
                          maxDate={new Date()}
                          name="birthdate"
                          calendarIcon={
                            <img src={AddCalendar} alt="calendar" />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottomGroup px-2 px-md-0">
                  <div className="biographyTextTags">
                    <p>{i18next.t("PublicInfoTabBiography")}</p>
                    <p>
                      {biography
                        ? 500 - biography.length + " remaining"
                        : "500 characters remaining"}
                    </p>
                  </div>
                  <textarea
                    style={{ padding: 24, borderRadius: "8px", width: "100%" }}
                    name="textarea"
                    rows="4"
                    maxLength="500"
                    className="textarea_update"
                    type="text"
                    value={biography}
                    onChange={onChangeUserValues}
                    name="biography"
                  />
                  {userInfluencer && (
                    <div
                      className="form-group "
                      onClick={handlePresentationVideoClick}
                    >
                      <div className="">
                        <label>{i18next.t("PublicInfoTabCoverVideo")}</label>
                      </div>
                      <input
                        readOnly={true}
                        style={{
                          height: "3rem",
                          cursor: "pointer",
                          backgroundColor: "rgba(0, 0, 0, 0)",
                        }}
                        type="text"
                        className="form-control modal_register modal_update textInput"
                        value={
                          presentationVideo &&
                          presentationVideo.file &&
                          presentationVideo.file.name
                            ? presentationVideo.file.name
                            : i18next.t("PublicInfoTabSelectAVideoFile")
                        }
                      />
                      <input
                        required
                        type="file"
                        ref={uploadPresentationVideoRef}
                        className="form-control modal_register modal_update"
                        style={{ display: "none" }}
                        name="presentationVideo"
                        onChange={onChangePresentationVideo}
                      />
                    </div>
                  )}
                  {videoPreview()}

                  <div className="required-field field">
                    <label>
                      {i18next.t("PublicInfoTabChooseYourLanguage")}{" "}
                    </label>
                  </div>
                  {!loadingInitial && (
                    <ChooseLanguage
                      isInfluencer={isInfluencer}
                      languagesSelected={languagesSelected}
                      languages={languages}
                      onUpdateLanguage={onUpdateLanguage}
                    />
                  )}
                </div>
              </form>
              <div
                style={{ padding: mobileNav && "0px" }}
                className={`${isInfluencer && " mt-4 mt-md-0 "}`}
              >
                <AvailableBox
                  isInfluencer={isInfluencer}
                  available={avaliable}
                  handleAvailable={handleAvaliable}
                />
                <SocialConnectBox />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default PublicInfoTab;
