import React, {Component} from 'react';
import NavBar_streaming from '../comunes_events/menu-moderator';
import './style.scss';
import User_ban from '../comunes_events/user_banned';
import {Link} from "react-router-dom";
class ModeratorBanned extends Component {  
    render() {
        return (
            <div class="streaming">
                <header className="app-streaming">
                <NavBar_streaming/>
                </header>
               
                <div className="container-fluid streaming">
                    <div className="row">
                        <div className="col-md-9 d-flex flex-column div_ban">
                       <h2 className="titulo_ban">Banned Viewers</h2>
                       <p className = "parrafo_ban">Permanently removes a Viewer from chat for the remaince of the event</p>
                       <Link to = {`/view-streaming`}><button className="leave_moderation">leave Moderation</button></Link>
                       <User_ban/>
                        </div>
                    </div>                   
                </div>
            </div>
        );
    }
}

export default ModeratorBanned;