import React from "react";
import "./style.scss";
import map from "assets/img/icons/ic-map.svg";
import circulo from "assets/img/logo-circulo.png";
import {Link } from 'react-router-dom'
const AttendeesAdmin = ({ name,userName,  location, avatar , isInfluencer}) => {
  return (
    <div className="UsuPuntuacion7">
    <div>


      <div className="Div-foto">
        <img className="Foto" src={avatar} alt="" />
  {isInfluencer && <img src={circulo} className="Icono" alt="" /> }
      </div>
    </div>
      <div className="div-nombre-admin ml-2">
         
        {userName?  <Link to={`/${userName}`}>
         <span style={{fontSize:'23px', fontWeight:500, color:'black'}} 
         className="Nombre">{userName? userName : name}</span>
         </Link>
         :
         <span style={{fontSize:'23px', fontWeight:500, color:'black'}} 
         className="Nombre">{userName? userName : name}</span>
         }
        <span className="Hor_admin ml-1">
          {" "}
          <img src={map} alt="" />
          {location}
        </span>
      </div>
    </div>
  );
};

export default AttendeesAdmin;
