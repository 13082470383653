import React, { Component } from 'react';
import { connect } from "react-redux";
import './style.scss';
import Tablebilling from '../comunes/table_billing';
import Navbar from "../comunes/navbar";
import useBillingLogic from "./BillingLogic";

const  BillingViewer = ({user}) => {

  const { purchasedEvents, filterPurchasedEvents } = useBillingLogic({user});

  return (
    <div>
        <header>
            <Navbar/>
        </header>
        <div className="container container_billing" id="billing">
            <div className="d-flex header_stats"><h2>Billing</h2>
            <div className="Search-div-peq search">
                  <i className="fas fa-search"></i>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={event => filterPurchasedEvents(event.target.value)}/>
                </div> 
            </div>
                <Tablebilling events={purchasedEvents}/>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default  connect(mapStateToProps)(BillingViewer);