// TODO: replace by Hook component!
import React from "react";
import MediaCapturer from "react-multimedia-capture";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import "./style.scss";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "webrtc-adapter";
import RecordRTC from "recordrtc";
import "videojs-record/dist/css/videojs.record.css";
import "videojs-record/dist/videojs.record.js";

const MAX_TIME_VIDEO = 15;
let intervalVideoChange = null;

class VideoExample extends React.Component {
  constructor() {
    super();
    this.state = {
      granted: false,
      rejectedReason: "",
      recording: false,
      paused: false,
      timeVideo: 0,
      finishedVideo: false,
      blob: null
    };
    this.refStopButton = React.createRef();
  }

  componentDidMount() {
    if (this.props.previousBlob) {
      this.setState({ blob: this.props.previousBlob, finishedVideo: true });
    }

    // instantiate Video.js
    this.player = videojs(this.videoNode, videoJsOptions, () => {
      // print version information at startup
      var version_info =
        "Using video.js " +
        videojs.VERSION +
        " with videojs-record " +
        videojs.getPluginVersion("record") +
        " and recordrtc " +
        RecordRTC.version;
      videojs.log(version_info);
    });

    // user clicked the record button and started recording
    this.player.on("startRecord", () => {
      intervalVideoChange = setInterval(() => {
        if (this.state.timeVideo === MAX_TIME_VIDEO) {
          this.refStopButton.click();
          return clearInterval(intervalVideoChange);
        }
        this.setState({ timeVideo: this.state.timeVideo + 1 , recording: true});
      }, 1000);
    });

    // user completed recording and stream is available
    this.player.on("finishRecord", () => {
      this.setState({
        finishedVideo: true,
        timeVideo: 0,
        blob: this.player.recordedData,
        recording:false
      });
      clearInterval(intervalVideoChange);
      if (this.player.recordedData.size > 0)
        this.props.onChange(this.player.recordedData);
    });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <div ref="app">
        {/* <h3 style={{ padding: "0 13rem" }}>Video Recorder</h3> */}
        {/* <h3 style={{marginLeft:'0px'}} className={"mb-4"} >Video Recorder</h3> */}
        <div data-vjs-player>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              overflow:'hidden',
              borderRadius:'10px'
            }}
          >
            <video
              id="myVideo"
              ref={node => (this.videoNode = node)}
              className="video-js vjs-default-skin"
              playsInline
            ></video>
          </div>
        </div>
        {this.state.recording && <ProgressBar style={{ marginTop: "15px",backgroundColor: "#80808057" }}
          now={(this.state.timeVideo / MAX_TIME_VIDEO) * 100}
        />
          }
      </div>
    );
  }
}

VideoExample.propTypes = {
  onChange: PropTypes.func.isRequired
};

const videoJsOptions = {
  controls: true,
  width: 800,
  height: 450,
  fluid: false,
  plugins: {
    record: {
      audio: true,
      video: true,
      maxLength: 15,
      debug: true,
      videoMimeType: "video/webm;codecs=H264"
    }
  }
};

export default VideoExample;
