export default {
  pending: 0,
  accepted: 1,
  rejected: 2,
  cancelled: 3,
  modified: 4,
  acceptedModified: 5,
  pendingPayment: 6,
  pendingModified: 7,
};

export const stateArray = [

  {name: 'Pending Confirmation', id:  0},
  {name: 'Accepted', id: 1},
  {name: 'Rejected', id: 2},
  {name: 'Cancelled', id: 3},
  {name: 'Modified', id: 4},
  {name: 'Accepted and Modified', id: 5},
  {name: 'Pending Payment', id: 6},
  {name: 'Pending Payment after Modified', id: 7},

]