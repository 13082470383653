import React, {useState, useContext} from 'react';
export const LogueadoContext = React.createContext(true);
const Store = ({children}) => {
    const [logueado, setLogueado] = useState(true);
    return (
        <div>
        <LogueadoContext.Provider value={[logueado, setLogueado]}>
            {children}
        </LogueadoContext.Provider>
        </div>
    );
}
export default Store;