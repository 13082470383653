import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";

const ChatMessage = ({ uuid, message, username, avatar, emojiMessage, messageContainerStyle, messageStyle }) => {
  return (
    <li className=" mb-3 list_chat_details_streaming" key={uuid}>
      <Row>
        {/* <Col xs={{ span: 2, offset: 1 }} md={{ span: 2, offset: 1 }} lg={{ span: 1, offset: 1 }}> */}
        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 1 }}>
          <img
            className="messageUserAvatar"
            style={{ borderRadius: "50%" }}
            src={avatar}
            alt=""
          />
        </Col>
        <Col xs={{ span: 8 }} md={{ span: 6, offset: 1 }} lg={{ span: 8 }}>
          <p
            className="rojo forceBold"
            style={{ fontSize: "14px", marginBottom: "0.15rem" }}
          >
            {username}
            <span style={{ fontSize: 14, color: "white" }}></span>
          </p>
          <div
            style={!messageContainerStyle ? {
              borderRadius: 14,
              width: "fit-content",
            } : messageContainerStyle}
          >
            <span
              style={!messageStyle ? {
                backgroundColor: "#312d44",
                padding: "4px 12px",
                fontSize: "15px",
                marginBottom: "0.15rem",
              } : messageStyle}
            >
              {message}
            </span>
          </div>
        </Col>
      </Row>
    </li>
  );
};
export default ChatMessage;
