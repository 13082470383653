import axios from "axios"
import queryString from 'query-string'
import { Api, SECOND_ACCESS_TOKEN_API } from "../environment/environment"
import { parseLocale } from 'utils/Localization'

const myToken = JSON.parse(localStorage.getItem("env"))

const influencerFormPath = '/#/new_influencer_profile'

const displayModal = (msg) => {
    const darkBackground = document.createElement('div')
    darkBackground.id = 'influencer-congrats'
    
    const div = document.createElement('div')
    const span = document.createElement('span')
    const p = document.createElement('p')
    p.innerText = msg
    div.appendChild(span)
    div.appendChild(p)
    darkBackground.appendChild(div)
    span.addEventListener('click',e=>{
        darkBackground.remove()
    })
    document.body.appendChild(darkBackground)
}

  
const verifyTwitter = async () => {
    const url = new URL(window.location.href)
    const oauth_token = url.searchParams.get("oauth_token")
    const oauth_verifier = url.searchParams.get("oauth_verifier")
    if(oauth_token && oauth_verifier){
        try{
            let res = await axios.post(`${Api}user/verify_twitter`, { oauth_token, oauth_verifier }, {
                headers: {
                    "Access-Token": myToken,
                    "Content-Type": "application/json",
                    "Content-Language": parseLocale()

                }
            })
            
            if(res.data.success){
                window.location.href = influencerFormPath
            }else{
                displayModal('Verification failed, try again')
            }
        }catch(e){
            displayModal('Verification failed, try again.')
        }
    }
}
const verifyFacebook = () => {}
const verifyLinkedin = async () => {
    const url = new URL(window.location.href)
    const code = url.searchParams.get("code")
    const state = url.searchParams.get("state")
    const path = window.location.pathname
    if(code && state === 'Verify' && (path === "/linkedin/" || path === "/linkedin")){
        try{
            let res = await axios.post(`${Api}user/verify_linkedin`, { code }, {
                headers: {
                    "Access-Token": myToken.token,
                    "Content-Type": "application/json",
                    "Content-Language": parseLocale()

                }
            })
            
            if(res.data.success){
                window.location.href = influencerFormPath
            }else{
                displayModal('Verification failed, try again')
            }
        }catch(e){
            
            displayModal('Verification failed, try again.')
        }
    }
}

const verifyLinkedinRegister = () => {
    const url = new URL(window.location.href)
    const code = url.searchParams.get("code")
    const state = url.searchParams.get("state")
    const path = window.location.pathname
    if(code && state === 'Register' && (path === "/linkedin/" || path === "/linkedin")){
        alert('TODO: register')
    }
}

const verifyLinkedinLogin = () => {
    const url = new URL(window.location.href)
    const code = url.searchParams.get("code")
    const state = url.searchParams.get("state")
    const path = window.location.pathname
    if(code && state === 'Login' && (path === "/linkedin/" || path === "/linkedin")){
        axios.post(`${Api}auth/login_linkedin`, { code }, {
            headers: {
                "Access-Token": SECOND_ACCESS_TOKEN_API,
                "Content-Type": "application/json",
                "Content-Language": parseLocale()

            }
        }).then(res => {
            const { data } = res;
            const env = {
                token: data.data.user_app_token
            };
            
            localStorage.setItem("env", JSON.stringify(env));
            if (!data.data.user_name && !data.data.user_lastname) {
                window.location.href = "/#/viewer_profile"
            } else {
                window.location.href = "/#/profile"
            }
            document.location.reload();
            // this.setState({redirect:true})
            
        }).catch(err=>{
            displayModal('Verification failed, try again')
            // this.setState({redirect:true})
            
        })
    }
}

export const socialMediaVerificator = () => {
    // verifyTwitter()
    // verifyFacebook()
    // verifyLinkedin()

    // verifyLinkedinRegister()

    // verifyLinkedinLogin()
}
export default socialMediaVerificator