/* returns influencer  time and date from MS and local user timezone */

import { useState, useEffect } from "react";
import * as momenttz from "moment-timezone";

const useUserHour = (request_date, userData) => {
  const [timeZone, setTimeZone] = useState("");
  const [influencerTime, setInfluencerTime] = useState("");
  useEffect(() => {
    if (userData && userData.user_location) {
      const userTimezone = JSON.parse(userData.user_location);
      try {
        setTimeZone(userTimezone.data.zoneName);
      } catch (error) {}
    }
  }, [userData]);

  useEffect(() => {
    let time;
    if (request_date && timeZone.length > 0) {
      time = momenttz(parseInt(request_date))
        .tz(timeZone)
        .format("HH:mm");
      var day = momenttz(parseInt(request_date))
        .tz(timeZone)
        .format("DD");
      var month = momenttz(parseInt(request_date))
        .tz(timeZone)
        .format("MMMM")
        .substring(0, 3)
        .toUpperCase();
      setInfluencerTime({ time, day, month });
    }
  }, [request_date, timeZone]);

  return { influencerTime };
};

export default useUserHour;
