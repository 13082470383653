import React, { useState, useEffect } from "react";
import { EventDAO, UserDAO } from "daos";
import typeEventsStreaming from "../../constants/typeEventsStreaming";
import { STREAMING_SPACE, DEBUG_LOGS } from "environment/environment";
import uuidv3 from "uuid/v3";
import { toast } from 'react-toastify';
import * as LogTypes from '../../constants/LogTypes'
import { isMobile } from "react-device-detect";
import { IS_DEV_ENVIRONMENT, isGotmyApp } from "../../environment/environment";
import io from "socket.io-client";
import { useLocation, Switch } from 'react-router-dom';
import { setError } from "../../store/actions";
import i18next from "i18next";
import Config from '../../environment/configSelector'
//const ENDPOINT = IS_DEV_ENVIRONMENT ? "https://socket.prod.gotmy.com" : "https://socket.dev.gotmy.com";
//const ENDPOINT = "https://socket.dev.gotmy.com";

const ENDPOINT = IS_DEV_ENVIRONMENT
  ? Config.DEV_SOCKETIO_ENDPOINT
  : Config.SOCKETIO_ENDPOINT;

const trimNewlines = require('trim-newlines');

const CHATS_LIMIT = 250;

const eventDAO = new EventDAO();
const userDAO = new UserDAO();

var userDataGlobal = null;
var eventDataGlobal = null;

var globalChats = [];
var globalSocket = null;

let eventOkTimer = null;
const SOCKETIO_CONFIRMATION_SIGNAL_TIMEOUT = 5000;

export const usePreviewLogic = ({
  user,
  influencer,
  uuid
}) => {
  const [resourcesLoaded, setResourcesLoaded] = useState(false);
  const [message, setMessage] = useState("");
  const [eventData, setEventData] = useState({
    event: null,
    streamingData: null
  });
  const [streamingData, setStreamingData] = useState({
    influencerStreamingData: {
      stream_name: "",
      primary_server: ""
    },
    src: "",
    posterURL: null,//"https://dev.gotmy.com/public/static/img/event/event-1612609370403.jpeg"
  });
  const [sessionError, setSessionError] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [streamingFinished, setStreamingFinished] = useState(false);
  const [feedBackText, setFeedBackText] = useState("");
  const [feedBackScore, setFeedBackScore] = useState(null);
  const [renderRedirect, setRenderRedirect] = useState(false);
  const [showDisconnectDialog, setShowDisconnectDialog] = useState(false);
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [socket, setSocket] = useState(null);
  const [activeViewers, setActiveViewers] = useState(0);
  const [chats, setChats] = useState([]);
  const [connectSocketIO, setConnectSocketIO] = useState(false);
  const [socketConnectionErrorMessage, setSocketConnectionErrorMessage] = useState(false);
  const [socketConnectionErrorMessageDisplayed, setSocketConnectionErrorMessageDisplayed] = useState(false);
  const [ticketInUse, setTicketInUse] = useState(false);
  const [socketIoConnected, setSocketIoConnected] = useState(false);
  const [socketIoInitialized, setSocketIoInitialized] = useState(false);
  const [session, setSession] = useState(null);
  const [chatDisabled, setChatDisabled] = useState(false);
  const [networkStatus, setNetworkStatus] = useState({
    status: true,
    previousStatus: true
  });
  const [streamInitialized, setStreamInitialized] = useState(false);
  const [streamingError, setStreamingError] = useState("");
  const [influencerConnected, setInfluencerConnected] = useState(false);
  const [refreshToken, setRefreshToken] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const env = JSON.parse(localStorage.getItem("env"));
    if (!env) {
      disableStartStreaming("You should be logged in to attend this event.");
      setRenderRedirect(true);
    }


    if (eventData.event && eventData.event.influencer && user && user.user_ide) {
      setIsInfluencer(eventData.event.influencer.influencer_user_ide == user.user_ide);
      if (eventData.event.influencer.influencer_user_ide == user.user_ide) {
        setInfluencerConnected(true);
      }

      if (shouldDisableStreaming(eventData.event)) {
        disableStartStreaming("You need a ticket to attend this event.");
        setRenderRedirect(true);
      }
    }


  }, [eventData.event, user])

  useEffect(() => {
    console.log("useffect connectSocketIO y user", connectSocketIO, user)
    if (user && connectSocketIO && !socketIoConnected) {
      connectSocketIOFunction(user);
    }
  }, [connectSocketIO, user])


  useEffect(() => {
    console.log("eventData changed", eventData)


  }, [eventData])

  useEffect(() => {
    if (!location.pathname.includes("broadcast")) {
      onLeaveLiveEvent(false);
    }
  }, [location]);



  const setRefreshTokenDelay = (specialDelay = 400, updateSessionData = false) => {
    setTimeout(() => {
      if (updateSessionData) {
        //updateStreamingState();
      }
      setRefreshToken(Date.now());

      scrollDownChat();
    }, specialDelay);
  }

  const onSendFeedback = async () => {

    if (feedBackScore == null) {
      setSessionError("Please rate the event");
    } else {
      const data = {
        event_uuid: uuid,
        message: feedBackText,
        review_score: feedBackScore
      };

      const env = JSON.parse(localStorage.getItem("env"));
      try {
        const response = await eventDAO.reviewEvent(data, env.token);

        if (response.data.success) {
          setRenderRedirect(true);
        } else {
          try {
            setSessionError(response.data.error[0], message);
          } catch (error) {
            sendServerLog("Error getUsernameFromSubscriber. Function <onSendFeedback()>. Line 235.", error);
            setSessionError("There was an error sending your review");
          }

        }

      } catch (err) {
        sendServerLog("Error sending review onSendFeedback. Function <onSendFeedback()>. Line 242.", err);

      } finally {

      }
    }
  };

  const fetchStreamingState = async () => {
    const env = JSON.parse(localStorage.getItem("env"));
    return await eventDAO.getCurrentStateStreaming(uuid, env.token);
  };


  const updateStreamingState = async () => {

    const response = await fetchStreamingState();


    setStreamingState(response);
  }



  const pushNewMessage = async (message) => {
    const {
      user: { user_avatar, user_username },
      message_ide
    } = message;

    var updatedChats = globalChats;

    console.log(" pushNewMessage esto tiene chats", chats, updatedChats);

    if(updatedChats.length > CHATS_LIMIT){
      updatedChats.shift();
    }

    updatedChats.push({
      avatar: user_avatar,
      username: user_username,
      message: message.message,
      uuid: message_ide
    })

    console.log(" pushNewMessage esto tiene chats", chats, updatedChats);

    setChats(updatedChats);
    globalChats = updatedChats;

    console.log("Se ha introducido un mensaje pushNewMessage", {
      avatar: user_avatar,
      username: user_username,
      message: message.message,
      uuid: message_ide
    })

    setRefreshTokenDelay(800);
  }

  const setStreamingState = (response) => {
    const {
      data: {
        data: {
          current_state: { messages, raised_hands, raised_hand_enabled, speaking_viewer, viewers, open_mic, chat_enabled }
        }
      }
    } = response;
    const updatedChats = [];

    messages.forEach(message => {
      const {
        user: { user_avatar, user_username },
        message_ide
      } = message;

      updatedChats.push({
        avatar: user_avatar,
        username: user_username,
        message: message.message,
        uuid: message_ide
      });
    });

    setChats(updatedChats);
    globalChats = updatedChats;
  }

  useEffect(() => {

    console.log("Cambian los chats pushNewMessage", chats)

    scrollDownChat()

  }, [chats, globalChats]);


  const scrollDownChat = (error) => {
    if (document.getElementById("messageContainerViewer") != null && document.getElementById("messageContainerViewer") != undefined) {
      document
        .getElementById("messageContainerViewer")
        .scrollTo(0, document.querySelector("#messageContainerViewer").scrollHeight);
    }
  }

  const handleError = (error) => {

  }

  const handleInitializeEventError = (error) => {
    alert("EVENT DATA OR TOKEN NOT VALID, SHOULD REDIRECT WEBVIEW MOBILE")
  }

  const handleNoTicketEventError = (error) => {
    alert("User has no ticket");
  }

  const findUserData = async (userToken) => {
    try {
      var response = await userDAO.findByToken(userToken);
      const userData = response.data.data;

      return userData;
    } catch (error) {
      console.error(error);
      handleError(error);

      return null;
    }
  }

  const findEventData = async (userToken) => {
    try {
      var response = await eventDAO.findByIdWithSpecialToken(uuid, userToken);
      const eventData = response.data.data;

      return eventData;
    } catch (error) {
      console.error(error);
      handleError(error);

      return null;
    }
  }

  const startEvent = async (userToken, uuid, eventDataLocal, userDataLocal) => {
    try {
      var response = await eventDAO.startStreamingRTMP(uuid, userToken);
      const eventSession = response.data.data;

      console.log("startEvent DATA", eventSession, eventSession.session.direct_playback_urls.hls, eventSession.session.direct_playback_urls.hls[0]);
      setSession(eventSession);

      setStreamInitialized(true);

      try {
        setStreamingData({
          ...streamingData,
          influencerStreamingData: eventSession.session.source_connection_information,
          src: eventSession.session.player_hls_playback_url,
          posterURL: eventDataLocal.event.event_cover_image
        })

        setChatDisabled(true);
      } catch (error) {

      }

      setConnectSocketIO(true);

      return eventSession;
    } catch (error) {
      console.error(error);
      handleError(error);

      return null;
    }
  }

  const joinEvent = async (userToken, uuid, eventDataLocal, userDataLocal) => {
    try {
      var response = await eventDAO.joinStreamingRTMP(uuid, userToken);
      const eventSession = response.data.data;

      console.log("joinStreamingRTMP DATA", eventSession, eventSession.session.direct_playback_urls.hls, eventSession.session.direct_playback_urls.hls[0]);

      setStreamInitialized(true);

      try {
        setStreamingData({
          ...streamingData,
          src: eventSession.session.player_hls_playback_url,
          posterURL: eventDataLocal.event.event_cover_image
        })

        setChatDisabled(true);

        setConnectSocketIO(true);
      } catch (error) {

      }

      return eventSession;
    } catch (error) {
      console.error(error);
      handleError(error);

      return null;
    }
  }

  // Get Data event
  const initEventData = async () => {

    var env = JSON.parse(localStorage.getItem("env"));

    const userToken = env.token;

    var userDataResult = await findUserData(userToken);
    var eventDataResult = await findEventData(userToken);


    if (!userDataResult || !eventDataResult) {
      handleInitializeEventError();
    }

    userDataGlobal = userDataResult;
    eventDataGlobal = eventDataResult;

    setEventData({
      ...eventData,
      event: eventDataResult
    });

    const isEventInfluencer = eventDataResult.user.user_ide === userDataResult.user_ide;
    const RTMPEvent = eventDataResult.event.ticket_streaming !== null;
    const hasTicket = eventDataResult.event.is_purchased;

    if (!hasTicket && !isEventInfluencer) {
      handleNoTicketEventError();
    }

    setIsCreator(isEventInfluencer);
    setResourcesLoaded(true);

    if (isEventInfluencer) {
      startEvent(userToken, uuid, eventDataResult, userDataResult);
    } else {
      joinEvent(userToken, uuid, eventDataResult, userDataResult)
    }
  }

  // Get Data event
  const initEventData2 = async () => {
    eventDAO.findById(uuid)
      .then(response => {
        const {
          data: { data }
        } = response;

        var isCreator = data.influencer.influencer_user_ide === user.user_ide;

        setEventData({
          ...eventData,
          event: data
        });

        //TODO TEMPORAL REMOVE THIS
        /*
        setChatDisabled(true);
            setStreamingData({
              ...streamingData,
              posterURL: data.event.event_cover_image
            })
            */
        //REMOVE PREVIOUS

        console.log("initEventData", data, streamingData, isCreator, user, data)

        setIsCreator(isCreator)
        setResourcesLoaded(true);


        const env = JSON.parse(localStorage.getItem("env"));


        console.log("isCreator", data.influencer.influencer_user_ide, user.user_ide, data.influencer.influencer_user_ide == user.user_ide);
        if (data.influencer.influencer_user_ide === user.user_ide) {

          console.log("startStreamingRTMP se lanza", uuid)
          eventDAO.startStreamingRTMP(uuid, env.token)
            .then(responseStartStreamingRTMP => {
              const {
                data: { data }
              } = responseStartStreamingRTMP;

              console.log("startStreamingRTMP result", responseStartStreamingRTMP, responseStartStreamingRTMP)

              /*
              setChatDisabled(true);
              setStreamingData({
                ...streamingData,
                posterURL: data.event.event_cover_image
              })
              */
              //console.log("responseStartStreamingRTMP", responseStartStreamingRTMP, data)

              //setStreamingData(data);

            })
            .catch(error => {
            });

        } else {
          eventDAO.joinStreamingRTMP(uuid, env.token)
            .then(responseStartStreamingRTMP => {
              const {
                data: { data }
              } = responseStartStreamingRTMP;



              setChatDisabled(true);
              setStreamingData({
                ...streamingData,
                posterURL: data.event.event_cover_image
              })

              console.log("responseStartStreamingRTMP", responseStartStreamingRTMP, data)

              //setStreamingData(data);

            })
            .catch(error => {
            });

        }


      })
      .catch(error => {
      });
  };

  const shouldDisableStreaming = (data) => {

    if (data && (data.event.is_purchased || (data.influencer && user && data.influencer.influencer_user_ide == user.user_ide))) {
      return false;
    } else {
      return true;
    }
  }

  const disableStartStreaming = (message) => {
    setSessionError(message);
  }

  const sendServerLog = (message, exception = null) => {
    const env = JSON.parse(localStorage.getItem("env"));

    if (!env || !env.token) {
      return null;
    }

    var canParseError = false;
    try {
      var stringifyResult = JSON.stringify(exception);

      if (stringifyResult) {
        canParseError = true;
      }
    }
    catch {

    }
  }

  /**
   * - Disconnect local streaming.
   * - It does not affect other viewers
   */
  const onLeaveEventStandard = () => {

    setStreamingFinished(true);
    try {
      onLeaveEvent();
    } catch (error) {
      sendServerLog("Error exiting the event without session. Function <onLeaveEventStandard()> Line 689.", error);
      setSessionError("There was a problem finishing the event. \nPlease try again in few seconds.")
    }

  };

  /**
   * - Close streaming on the server.
   * - Send the signal to the other viewers that the event ends
   */
  const onLeaveLiveEvent = async (sendClosingSessionWithServer = true) => {

    var isClosed = false;

    try {
      const env = JSON.parse(localStorage.getItem("env"));

      var response = await eventDAO.stopStreamingRTMP(uuid, env.token);

      if (response.data.success) {
        isClosed = true;
      }

      console.log("onLeaveLiveEvent", response)
      socket.disconnect();
    } catch (error) {
      console.error(error);
    }

    if (isClosed) {
      setRenderRedirect(true);
    } else {
      setSessionError("There was an error closing the event.");
    }

  };

  /**
  * - Close streaming on the server.
  * - Navigate to user profile
  */
  const disconnectEvent = () => {
    try {
      socket.disconnect();
    } catch (error) {
      console.error(error);
    }

    setRenderRedirect(true);


  };


  // Send message on the chat
  const onSendMessage = async () => {
    var adaptedMessage = trimNewlines(message.trim());
    const data = {
      adaptedMessage,
      avatar: isInfluencer ? influencer.influencer_avatar : user.user_avatar,
      influencerMode: isInfluencer,
      username: user.user_username,
      uuid: uuidv3(message, STREAMING_SPACE)
    };

    const env = JSON.parse(localStorage.getItem("env"));
    await eventDAO.sendEventMessageSocketIoMessage(uuid, adaptedMessage, env.token);
    setMessage("");
  };


  const parseUserDataForSocketIOSignal = () => {
    var responseData = null;

    var isInfluencerLocal = eventDataGlobal.influencer.influencer_user_ide == userDataGlobal.user_ide;

    if (isInfluencerLocal) {
      responseData = {
        influencerData: {
          username: userDataGlobal.user_username,
          userId: userDataGlobal.user_ide
        },
        uuid: uuid
      }
    } else {
      responseData = {
        viewerData: {
          username: userDataGlobal.user_username,
          userId: userDataGlobal.user_ide
        },
        uuid: uuid
      }
    }

    return responseData;
  }

  const resentEventSignal = (localSocket) => {

    console.log("resentEventSignal", eventOkTimer);
    localSocket.emit("event", parseUserDataForSocketIOSignal());

    clearTimeout(eventOkTimer);

    if(window.location.pathname.includes("broadcast")){
      eventOkTimer = setTimeout(() => {
        resentEventSignal(localSocket)
      }, SOCKETIO_CONFIRMATION_SIGNAL_TIMEOUT);
    }else{
      globalSocket.disconnect();
      localSocket.disconnect();
    }



  }

  const connectSocketIOFunction = (userDataResult) => {
    console.log("USEEFFECT SOCKETIO")
    try {
      const localSocket = io.connect(ENDPOINT);
      globalSocket = localSocket;

      localSocket.on("connect", data => {
        console.log("EVENTO: connect", data)
        setSocketConnectionErrorMessage(false);
        setSocketConnectionErrorMessageDisplayed(false);

        setSocketIoConnected(true);
        setSocketIoInitialized(true);

        localSocket.emit("connectOk", parseUserDataForSocketIOSignal());
        localSocket.emit("event", parseUserDataForSocketIOSignal());

        eventOkTimer = setTimeout(() => {
          resentEventSignal(localSocket);
        }, SOCKETIO_CONFIRMATION_SIGNAL_TIMEOUT);

        setSocket(localSocket);

        console.log("connect: se manda", parseUserDataForSocketIOSignal())

      });

      localSocket.on("eventOk", data => {
        clearTimeout(eventOkTimer);
        console.log("EVENTO: eventOk", data)
      });


      localSocket.on("userAlreadyConnected", data => {
        const {username, socketId} = data;
        console.log("EVENTO: userAlreadyConnected", data,eventOkTimer, localSocket)

        if(username === userDataGlobal.user_username && !isInfluencer && socketId !== localSocket.id){
          setStreamingError(i18next.t("HLSBroadcastComponentTicketInUse"));

          localSocket.emit("userAlreadyConnectedOk", parseUserDataForSocketIOSignal());
          clearTimeout(eventOkTimer);

          console.log("EVENTO: userAlreadyConnected 2",eventOkTimer)
          localSocket.disconnect();

          globalSocket.disconnect();

          setTicketInUse(true);


          console.log("EVENTO: userAlreadyConnected 3",eventOkTimer, localSocket)

        }

      });


      localSocket.on("viewersUpdated", data => {

        if (data !== null && data !== undefined) {
          setActiveViewers(data);
        }

        localSocket.emit("viewersUpdatedOk", parseUserDataForSocketIOSignal())

        console.log("EVENTO: viewersUpdated", data, localSocket)
      });

      localSocket.on("newMessage", data => {

        pushNewMessage(data);

        localSocket.emit("newMessageOk", parseUserDataForSocketIOSignal())
        console.log("EVENTO: newMessage", data)
      });

      localSocket.on("updateEvent", data => {
        console.log("EVENTO: updateEvent", data)

        //updateStreamingState();

        localSocket.emit("updateEventOk", parseUserDataForSocketIOSignal())
        console.log("EVENTO: updateEventOk se manbda", data)
      });

      localSocket.on("finishEvent", data => {
        console.log("EVENTO: finishEvent", data)

        setStreamingFinished(true);
        try {
          onLeaveEvent();
        } catch (error) {
          sendServerLog("Error disconnecting from event finished signal (viewer). Function <session.on(STREAMING_FINISHED)>.", error);
        }

        localSocket.emit("finishEventOk", parseUserDataForSocketIOSignal())

      });

      localSocket.on("connect_error", data => {
        setSocketConnectionErrorMessage(true);
        if(!socketConnectionErrorMessageDisplayed ){
          setSessionError(i18next.t("HLSBroadcastComponentSocketIoError"))
        }
        console.log("EVENTO: connect_error", data)
      });

      localSocket.on("connect_timeout", data => {
        console.log("EVENTO: connect_timeout", data)
      });

      localSocket.on("reconnect", data => {
        setSocketIoConnected(true);
        console.log("EVENTO: reconnect", data)
      });

      localSocket.on("reconnect_attempt", data => {
        console.log("EVENTO: reconnect_attempt", data)
      });

      localSocket.on("reconnecting", data => {
        console.log("EVENTO: reconnecting", data)
      });

      localSocket.on("reconnect_error", data => {
        //setSocketIoConnected(false);
        console.log("EVENTO: reconnect_error", data)
      });

      localSocket.on("reconnect_failed", data => {
        console.log("EVENTO: reconnect_failed", data)
      });

      localSocket.on("disconnect", () => {
        console.log("EVENTO: disconnect")
        setSocketIoConnected(false);
      });



    } catch (error) {
      console.error("USEEFFECT SOCKETIO", error)
    }
  }

  const onLeaveEvent = () => {
    try {
      socket.disconnect();
    } catch (error) {
      console.error(error);
    }

    try {

      if (isInfluencer) {
        setRenderRedirect(true);
      }
    } catch (error) {
      sendServerLog("Error onLeaveEvent. Function <onLeaveEvent()>. Line 1341.", error);
    }
  };

  useEffect(() => {
    initEventData();

    return () => {
      //onLeaveLiveEvent(false);
      onLeaveEvent();
    };
  }, []);


  return {
    eventData,
    streamingData,
    message,
    sessionError,
    setMessage,
    onSendMessage,
    onLeaveLiveEvent,
    disconnectEvent,
    setSessionError,
    isCreator,
    streamingFinished,
    setStreamingFinished,
    feedBackText,
    setFeedBackText,
    onSendFeedback,
    feedBackScore,
    setFeedBackScore,
    renderRedirect,
    setRenderRedirect,
    showDisconnectDialog,
    setShowDisconnectDialog,
    onLeaveEventStandard,
    setNetworkStatus,
    networkStatus,
    chats,
    streamInitialized,
    chatDisabled,
    activeViewers,
    scrollDownChat,
    streamingError,
    setStreamingError,
    ticketInUse,
    socketIoConnected,
    socketIoInitialized
  };
};
