export const roundHourToclouserQuarter = (date) => {
  // Getting minutes
  var mins = date.getMinutes();

  // Getting hours
  var hrs = date.getHours();
  var m = (parseInt((mins + 7.5) / 15) * 15) % 60;

  // Converting '09:0' to '09:00'
  m = m < 10 ? "0" + m : m;
  var h = mins > 52 ? (hrs === 23 ? 0 : ++hrs) : hrs;

  // Converting '9:00' to '09:00'
  h = h < 10 ? "0" + h : h;

  return date.setHours(h, m, 0);
};

export const roundHourToclouserQuarterToString = (date) => {
  // Getting minutes
  var mins = date.getMinutes();

  // Getting hours
  var hrs = date.getHours();
  var m = (parseInt((mins + 7.5) / 15) * 15) % 60;

  // Converting '09:0' to '09:00'
  m = m < 10 ? "0" + m : m;
  var h = mins > 52 ? (hrs === 23 ? 0 : ++hrs) : hrs;

  // Converting '9:00' to '09:00'
  h = h < 10 ? "0" + h : h;

  return h + ":" + m;
};
