
export const reasons = [
  {
    name: 'Just for fun',
    secondaryName: 'Have a good time with you',
    request_type: 0,
    options: [
      { optionId: 1, optionName: 'Party Appearance' },
      { optionId: 2, optionName: 'Playing Games' },
      { optionId: 3, optionName: 'Hangout' },
      { optionId: 4, optionName: 'Other reason', secondaryName: "What reason?" },
    ]
  },
  {
    name: 'Business',
    secondaryName: `I've a business proposal for you`,
    request_type: 1,
    options: [
      { optionId: 5, optionName: 'Meeting Request' },
      { optionId: 6, optionName: 'Consultant Request' },
      { optionId: 7, optionName: 'Business Proposal' },
      { optionId: 8, optionName: 'Partnership' },
      { optionId: 9, optionName: 'Coaching/Training' },
      { optionId: 4, optionName: 'Other reason', secondaryName: "What reason?" },

    ]
  },

  {
    name: 'Education',
    secondaryName: 'I would like to learn from you',

    request_type: 2,
    options: [
      { optionId: 10, optionName: 'Masterclass' },
      { optionId: 11, optionName: 'Tutorial' },
      { optionId: 12, optionName: 'Workshop' },
      { optionId: 4, optionName: 'Other reason', secondaryName: "What reason?" },

    ]
  },
  {
    name: 'Other reason',
    secondaryName: 'I have a special request',

    request_type: 3,
    options: [
      { optionId: 14, optionName: 'Sharing skills or experiencies' },
      { optionId: 4, optionName: 'Other reason', secondaryName: "What reason?" }
    ]
  }
]

export const newReasons = [{ request_type: 0, name: 'Just for fun', optionName: 'Party Appearance', secondaryName: 'Have a good time with you' },
{ request_type: 1, name: 'Just for fun', optionName: 'Party Appearance', secondaryName: 'Have a good time with you', },
{ request_type: 2, name: 'Just for fun', optionName: 'Show', secondaryName: 'Have a good time with you', },
{ request_type: 3, name: 'Just for fun', optionName: 'Playing Games', secondaryName: 'Have a good time with you', },
{
  request_type: 4, name: 'Just for fun', optionName: 'Hangout', secondaryName: 'Have a good time with you',
}, { request_type: 5, name: 'Just for fun', optionName: 'Just For Fun (other)' },
{ request_type: 6, name: 'Business', optionName: 'Business', secondaryName: `I've a business proposal for you`, },
{ request_type: 7, name: 'Business', optionName: 'Meeting Request', secondaryName: `I've a business proposal for you`, },
{ request_type: 8, name: 'Business', optionName: 'Consultant Request', secondaryName: `I've a business proposal for you`, },

{
  request_type: 9, name: 'Business', optionName: 'Business Proposal', secondaryName: `I've a business proposal for you`,
}, {
  request_type: 10, name: 'Business', optionName: 'Partnership', secondaryName: `I've a business proposal for you`,
},
{
  request_type: 11, name: 'Business', optionName: 'Coaching/Training', secondaryName: `I've a business proposal for you`,
},
{ request_type: 13, name: 'Education', optionName: 'Education', secondaryName: 'I would like to learn from you', },
{ request_type: 14, name: 'Education', optionName: 'Masterclass', secondaryName: 'I would like to learn from you', },
{ request_type: 15, name: 'Education', optionName: 'Workshop', secondaryName: 'I would like to learn from you', },
{
  request_type: 16, name: 'Education', optionName: 'Tutorial', secondaryName: 'I would like to learn from you',
}, {
  request_type: 17, name: 'Education', optionName: 'Training', secondaryName: 'I would like to learn from you',
},
]
