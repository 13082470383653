import React from 'react'
import styled from 'styled-components'
import circulo from "assets/img/logo-circulo.png";
import moment from "moment";
import {Link } from 'react-router-dom'
import statesEventRequest from "../../../constants/statesEventRequest";
import i18next from "i18next";

//i18next.t("RequestMessagesComponent")

const Avatars = ({isViewer, imgUrl}) => {
    return (
        <div className="">
            {isViewer ?
                <ImgUser  src={imgUrl} alt="" />
                :
                <div style={{position:'relative'}}>
                    <ImgInfluencer  src={imgUrl} alt="" />
                    <img src={circulo} style={{position:'absolute', right: "0", bottom:'0px', width: '16px', height:'16px'}} alt="" />
                </div>
            }
        </div>

    )
}

const RequestMessages = ({ requestActive, isViewer }) => {
    const { influencer, user, request } = requestActive? requestActive: []

    var now = moment()
    return (
        <div>
            {(request && request.messages) ?
            <>
            {request.messages.map((message) => {

                return (
                    <MessageRow>
                        {message.author === 'me' &&
                            <Avatars isViewer={isViewer &&  message.author==='me'? true :  (!isViewer &&  message.author==='me' )  ?  false:  true} imgUrl={isViewer ? user.user_avatar : influencer.influencer_avatar}/>
                        }
                        <MessageArea>
                        <MessageBox className="me" style={{ backgroundColor: message.author === 'me' ? 'white' : '#F4F7F9', 
                                                        borderRadius: message.author==='me' &&  '12px 12px 12px 0' ,border: message.author !== 'me' && '0' }}> {message.data.text}</MessageBox>
                                    <span className="timeAgo">{now.to(parseInt(message.date))}</span>
                        </MessageArea>

                        {message.author !== 'me' &&
                            <Avatars isViewer={(isViewer &&  message.author==='me')? false : (!isViewer &&  message.author!=='me' )  ? true:  false}
                             imgUrl={isViewer ? influencer.influencer_avatar : user.user_avatar}/>
                        }
                    </MessageRow>
                )

            }

            
            
            )

            }
            {request && (request.request_state === statesEventRequest.pendingPayment  || request.request_state === statesEventRequest.pendingModified) && 
            <MessagePending style={{ backgroundColor:  '#F4F7F9', display:'flex', justifyContent: 'center' }} >
                <div className="">
                    <h5 style={{color:'#ff5a60'}}>{i18next.t("RequestMessagesComponentPendingTicketPurchase")}</h5>
                    <p >{i18next.t("RequestMessagesComponentPendingTicketPurchaseDescription")}</p >
                </div>

            </MessagePending>

            }

            </>
            :

            <div style={{display:'flex' , justifyContent:'center'}}>

                <h5>{i18next.t("RequestMessagesComponentNoPrivateRequestYet")} {isViewer && (<Link to="/all_influencers">{i18next.t("RequestMessagesComponentFindYourInfluencer")}</Link>)}</h5>
            </div>}
        </div>
    )
}

export default RequestMessages
const MessageArea = styled.div`
margin-bottom: 24px;
.timeAgo {
    margin-left:12px;
    color : #808080;
}
`
const MessageRow = styled.div` 
    display: flex;
    justify-content: center; 

    .imgUser {
        object-fit: cover;
        width: 50px;
    height: 50px;
    border-radius: 50%;
    }
`


const MessageBox = styled.div` 
margin: 0 12px;
padding: 16px;
border-radius: 12px 12px 0 12px; 
border:1px solid #D8DCE5 ;

`

const MessagePending = styled.div` 
margin: 0 12px;
padding: 16px;
border-radius: 24px; 
border:1px solid #D8DCE5 ;

`
const ImgUser = styled.img`

object-fit: cover;
        width: 50px;
    height: 50px;
    border-radius: 50%;

`
const ImgInfluencer = styled.img`

width: 50px;
    height: 50px;
      border-radius: 50%;
      object-fit: cover;
      border: 1px solid #ff5a60;
    padding:2px;
    .Div-foto {
      position: relative;
    }

    .Icono {
      position: absolute;
      bottom: 0px;
      right: 28px;
      width: 17px;
    }

`