import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import i18next from "i18next";
import ReferralImg from "assets/img/wellness/sessionExample.png";
import item1 from "assets/img/wellness/ELEMENTOS_ICONO3 SECCION2.png";
import item2 from "assets/img/wellness/ELEMENTOS_ICONO2_SECCION2.png";
import item3 from "assets/img/wellness/ELEMENTOS_ICONO1_SECCION2.png";
import item4 from "assets/img/wellness/ELEMENTOS_ICONO1_SECCION3.png";
import item5 from "assets/img/wellness/ELEMENTOS_ICONO2 SECCION3.png";
import item6 from "assets/img/wellness/ELEMENTOS_ICONO3 SECCION3.png";
import backgroundImage from "assets/img/wellness/FOTOCOLOR.png";
import enrolHeaderRightIcon from "assets/img/wellness/ELEMENTOS_ICONO_HEADER.png";
import testimonialImage from "assets/img/wellness/testimonial.png";
import leftHeaderDecorator from "assets/img/wellness/ELEMENTOS_BACK2 SECCION1.png";
import logoGotmy from "assets/img/logo.png";
import logoGotmyWhite from "assets/img/logo-white.png";
import specialTopHeaderDecorator from "assets/img/wellness/ELEMENTOS_BACK SECCION1.png";
import { useModalRegisterLogic } from "components/comunes/login_menu/modals-login/modal_register/ModalRegisterLogic";
import ModalCTA from "./ModalCTA";
import useSizeFactors from "./useSizeFactor";
import { FRESHCHAT_ID } from "../../environment/environment";
import { parseLocale } from "utils/Localization";
import { Row, Col, Container, Button } from "react-bootstrap";
import { InlineWidget, openPopupWidget } from "react-calendly";
import FreshChat from "react-freshchat";
import "./wellnessStyle.scss";

const recaptchaRef = React.createRef();

const WIDTH_BREAKPOINT = 530;

const ReferralWellness = () => {
  const [modalCTA, setModalCTA] = useState(false);
  const {
    twitterRegister,
    linkedinRegister,
    facebookRegister,
    instagramRegister,
  } = useModalRegisterLogic(recaptchaRef.current);
  const params = useParams();
  const {
    sizeFactor,
    inAppBrowse,
    screenSize,
    ultraWide,
    width,
  } = useSizeFactors();
  const [referral, setReferral, clearReferral] = useLocalStorage("referral");
  const [talent, setTalent, cleatTalent] = useLocalStorage("LandingTalent");
  useEffect(() => {
    var millis = new Date().getTime();
    if (params && params.id) {
      setReferral({ id: params.id, timeStamp: millis });
    }

    setTalent(true);
  }, [params]);

  return (
    <section className="wellnessSection">
      <FreshChat token={FRESHCHAT_ID} onInit={(widget) => {}} />
      <Container fluid>
        {modalCTA && (
          <ModalCTA
            modalCTA={modalCTA}
            extraText={"Es necesario registrarte para crear tu primer evento"}
            setModalCTA={setModalCTA}
            facebookRegister={facebookRegister}
            instagramRegister={instagramRegister}
            linkedinRegister={linkedinRegister}
            twitterRegister={twitterRegister}
            screenSize={screenSize}
            inAppBrowse={inAppBrowse}
          />
        )}
        <Row
          className="wellnessEnrolHeader wellnessCenterElements"
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {!(width < WIDTH_BREAKPOINT) && (
            <Col
              xs={{ span: 3 }}
              md={{ span: 3 }}
              lg={{ span: 1 }}
              xl={{ span: 1 }}
              className="wellnessCenterElements"
            >
              <img className="wellnessGotmyIcon" src={logoGotmy} />
            </Col>
          )}
          <Col
            xs={{ span: width < WIDTH_BREAKPOINT ? 12 : 7, offset: 0 }}
            md={{ span: 7, offset: 1 }}
            lg={{ span: 5, offset: 3 }}
            xl={{ span: 5, offset: 4 }}
            className="wellnessCenterElements"
            style={{
              flexDirection: "row",
              justifyContent: width < WIDTH_BREAKPOINT ? "center" : "flex-end",
            }}
          >
            {false && (
              <p
                style={{
                  textAlign: width < WIDTH_BREAKPOINT ? "center" : "right",
                }}
              >
                Habla con un asesor
              </p>
            )}
            <Button
              className={"action-blue grow"}
              onClick={() => {
                openPopupWidget({
                  url:
                    "https://calendly.com/gotmy-wellness/demo-gotmy-wellness",
                });
              }}
            >
              Habla con un asesor
            </Button>
            <Button
              className={"grow"}
              onClick={() => {
                setModalCTA(true);
              }}
            >
              ¡Empieza ahora!
            </Button>
          </Col>
          {!(width < WIDTH_BREAKPOINT) && (
            <Col
              xs={{ span: 1 }}
              md={{ span: 1 }}
              lg={{ span: 1 }}
              className="wellnessCenterElements"
            >
              <img className="wellnessHeaderIcon" src={enrolHeaderRightIcon} />
            </Col>
          )}
        </Row>
        <Row className="wellnessHeaderPresentation">
          <img
            className="wellnessHeaderPresentationSpecialTopDecorator"
            src={specialTopHeaderDecorator}
            alt="Decorator"
          />
          <Col xs={{ span: 12 }}>
            <Row>
              <Col
                xs={false}
                lg={{ span: 1 }}
                className="wellnessHeaderPresentationImageContainer headerDecorator leftHeaderdecorator"
              >
                <img src={leftHeaderDecorator} />
              </Col>
              {/* <Col
                xs={{ span: 12 }}
                lg={{ span: 3 }}
                className="wellnessHeaderPresentationImageContainer"
              >
                <img src={ReferralImg} />
              </Col> */}
              <Col
                xs={{ span: 12 }}
                lg={{ span: 10 }}
                className="wellnessHeaderPresentationTextContainer d-flex justify-content-center"
              >
                <div className={"titleContainer d-flex justify-content-center"}>
                  <div className={"d-flex justify-content-center"}>
                    <h2>
                      Gotmy para consejeros{"\n"}emocionales y espirituales
                    </h2>
                  </div>
                  <p>
                    Ya es momento de conectar de manera más cercana con tu
                    audiencia, crea{"\n"}sesiones privadas y genera ingresos
                    recurrentes compartiendo lo que más amas.
                  </p>
                </div>
                <Button className={"grow"} onClick={() => setModalCTA(true)}>
                  ¡Súmate!
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="wellnessDescriptionContainer">
          <Row className="wellnessDescription wellnessCenterElements ">
            <Col className={"wellnessCenterElements"}>
              <h2>
                ¿Qué puedes hacer en <span>gotmy</span> si te dedicas a
                compartir tus conocimientos?
              </h2>
            </Col>
          </Row>
          <Row className="wellnessDescription wellnessDescriptionList wellnessCenterElements">
            <Col
              xs={12}
              md={4}
              className="wellnessDescriptionItemList wellnessCenterElements"
            >
              <img src={item1} />
              <h4>Gana más dinero</h4>
              <p>
                Consigue una fuente de ingresos extra al ofrecer contenido
                exclusivo en vivo a tu audiencia, tú pones el precio.
              </p>
            </Col>
            <Col
              xs={12}
              md={4}
              className="wellnessDescriptionItemList wellnessCenterElements"
            >
              <img src={item2} />
              <h4>Genera accesos exclusivos</h4>
              <p>
                Crea un vínculo único con tu audiencia mediante streamings
                exclusivos y reuniones privadas.
              </p>
            </Col>
            <Col
              xs={12}
              md={4}
              className="wellnessDescriptionItemList wellnessCenterElements"
            >
              <img src={item3} />
              <h4>Ahorra tiempo</h4>
              <p>
                Crea, comparte, cobra y realiza tu evento en una sola
                plataforma, tú tienes el control.
              </p>
            </Col>
          </Row>
        </div>
        <div className="wellnessDescriptionContainer">
          <Row className="wellnessDescription wellnessCenterElements wellnessDescriptionWhite">
            <Col className={"wellnessCenterElements"}>
              <h2>
                ¿Qué ofrece <span>gotmy</span> para los{" "}
                <span>guías emocionales y espirituales</span>?
              </h2>
            </Col>
          </Row>
          <Row
            className="wellnessDescription wellnessDescriptionList wellnessCenterElements wellnessDescriptionWhite"
            style={{
              paddingBottom: "5vh",
            }}
          >
            <Col
              xs={12}
              className="wellnessDescriptionItemList wellnessDescriptionItemListRow wellnessCenterElements"
            >
              <img src={item4} />
              <p>
                Creemos en la fuerza de un equipo, por eso te ofrecemos atención
                personalizada desde el día uno, soporte técnico y una atención
                al cliente única.
              </p>
            </Col>
            <Col
              xs={12}
              className="wellnessDescriptionItemList wellnessDescriptionItemListRow wellnessCenterElements"
            >
              <img src={item5} />

              <p>
                Networking con otros creadores para generar una red única para
                conocer nuevas audiencias.
              </p>
            </Col>
            <Col
              xs={12}
              className="wellnessDescriptionItemList wellnessDescriptionItemListRow wellnessCenterElements"
            >
              <img src={item6} />
              <p>¡Un espacio tan cómodo como tu casa!</p>
            </Col>
          </Row>
        </div>
        <Row className={"wellnessTestimonial wellnessCenterElements"}>
          <Col
            xs={12}
            md={4}
            className={"wellnessCenterElements"}
            style={width > 767 ? { justifyContent: "flex-end" } : null}
          >
            <img src={testimonialImage} />
          </Col>

          <Col xs={12} md={6} className={" testimonialContainer"}>
            <p className={"wellnessTestimonialText"}>
              "La verdad es que me ha ayudado muchísimo, a poder armar mis
              cursos en línea, de una forma súper fácil, súper eficiente... en
              pocas palabras, me ha salvado la vida para poder difundir el
              mensaje que vengo a enseñarles."
            </p>
            <Row className={"wellnessCenterElements"}>
              <Col xs={width < 769 ? 6 : 5}>
                <h4>- Mónica Hefferan</h4>
                <h5>Nutrición energética</h5>
              </Col>
              <Col xs={width < 769 ? 6 : 7}>
                <Button className={"grow"} onClick={() => setModalCTA(true)}>
                  ¡Quiero comenzar!
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="wellnessFooter wellnessCenterElements">
          <Col xs={{ span: 4 }} md={{ span: 3 }} lg={{ span: 3 }}>
            <img className="wellnessGotmyIcon" src={logoGotmyWhite} />
          </Col>
          <Col
            xs={{ span: 6, offset: 2 }}
            md={{ span: 3, offset: 6 }}
            lg={{ span: 3, offset: 6 }}
          >
            <p>
              {new Date().getFullYear()}{" "}
              {i18next.t("MobileFooterComponentGotmyCopyright")}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ReferralWellness;
