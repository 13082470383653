import React , {useEffect} from 'react'
import Loader from "components/comunes/loader";
import { withRouter } from "react-router-dom";

const PaypalBack = (props) => {
    
    /*   writes paypal token into localstorage for useBuyEvent to listen to*/
    useEffect(()=> {
        let success = true
        let payerId 
        let url  = props.location.search
        let paypalId
        
        if (props.location && props.location.pathname.includes('paypal_callback_error')){
            // TODO: Manage error
            success = false
            paypalId = url.substring(url.indexOf("=") + 1);
        }
        if (success) { 
            
            paypalId = url.substring(
                url.indexOf("=") + 1, 
                url.indexOf("&")
            );
            
        }
        localStorage.setItem('paypal', JSON.stringify({paypalId, success}))

    },[props.location.search])
    return (
        <div style={{height:'100vh'}} className="row">
        <section className=" container align-self-center">
        <Loader />
    </section>


        </div>
    )
}
export default withRouter(PaypalBack)