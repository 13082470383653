import React, { useState, useEffect, useRef } from 'react'
import DatePicker from "react-date-picker";
import AddCalendar from "assets/img/icons/ic-addcalendar.svg";
import { Controller } from 'react-hook-form'
import { countCharacters, verifyRegex, } from "utils";
import useTimezoneDifference from '../../../hooks/useTimezoneDifference'
import TimePicker from "react-datepicker";
import useClickOutside from '../../../hooks/useClickOutside'
import Clock from "assets/img/icons/clock.svg";
import Attendees from "components/comunes_events/attendees_admin";
import useMobileNav from '../../../hooks/useMobileNav'
import { useSelector } from "react-redux"
import { parsePriceByCurrencyJustSymbol, parseCurrencyCode, parsePriceByCurrency, findCurrencyByCurrencyCode, parsePriceByCurrencyCode } from "../../../utils/currencyUtils";
import i18next from 'i18next';

const SelectMeetingDetails = ({ date, month, data, loading, editing, viewerTimezone, getValues, register, control, errors, initialValues, startTime, endTime, setStartTime, setEndTime }) => {
    const DESCRIPTIONLIMIT = 1000;
    const [user, setUser] = useState()
    const { mobileNav } = useMobileNav()
    const currencies = useSelector((state) => state.currencies.currencies)
    const [startPickerOpen, setStartPickerOpen] = useState(false)
    const [endPickerOpen, setEndPickerOpen] = useState(false)
    const startPickerRef = useRef(null)
    const endPickerRef = useRef(null)
    useClickOutside(endPickerRef, () => setEndPickerOpen(false))
    useClickOutside(startPickerRef, () => setStartPickerOpen(false))
    const { difference } = useTimezoneDifference({ timezone1: viewerTimezone })
    const [overnightEvent, setOvernightEvent] = useState(false)
    const [char, setChar] = useState(DESCRIPTIONLIMIT)
    const [withDate, setWithDate] = useState(false)
    const [currency, setCurrency] = useState({})
    const getInitialDate = () => {
        let tempDate = new Date((new Date((new Date()).setDate((date))).setMonth(month)))
        return (tempDate)
    }
    const handleDateOptionChange = (e) => {
        setWithDate(getValues('date'))
    }
    const handleCurrencyChange = (e) => {
        setCurrency(findCurrencyByCurrencyCode(e.target.value, currencies))

    }

   

    useEffect(() => {

        if (currencies && currencies.length > 0) {
            if (editing !== true) {
                setCurrency(currencies[0])
            }
            else {
                setCurrency(findCurrencyByCurrencyCode(initialValues.request_currency, currencies))
            }
        }
    }, [currencies])
    useEffect(() => {
        if (data && data.user) {
            setUser(data.user)
        }
    }, [data])
    const handleStartChange = (e) => {

    }
    const onChangeTime = (date, period, e) => {
        if (period === 'start') {

            setStartTime(date)
            // setEventValues({ ...eventValues, event_start_hour: time })

        }
        else if (period === 'end') {
            setEndTime(date)
        }
        return date
    }
    const handleDescriptionChange = (e) => {
        const text = getValues('event_description')
        setChar(countCharacters(text.length, DESCRIPTIONLIMIT))
    }


    useEffect(() => {
        if (initialValues) {
            setWithDate('true')
        }
    }, [initialValues])
    useEffect(() => {
        setOvernightEvent(false)
    }, [])
    useEffect(() => {

        if ((startTime !== '' && endTime !== '') && (endTime < startTime)) {
            setOvernightEvent(true)
        }
        else {
            setOvernightEvent(false)
        }
        return (() => setOvernightEvent(false))
    }, [startTime, endTime])
    return (
        <div>
            {/* <h4 className=" mt-0 mb-1 ">
                How many people do you want the private meeting for?
                                </h4> */}
            <div className="d-flex justify-content-start pt-4">
                {user && <div className="d-inline d-md-none ml-4">
                    <Attendees userName={user.user_username} name={`${user.user_name} ${user.user_lastname}`}
                        location={user.user_location && JSON.parse(user.user_location).city} avatar={user.user_avatar}
                    />
                </div>
                }
            </div>
            <h4 className=" mt-0 mb-4 mt-4">{i18next.t('SelectMeetingDetailsComponentWhenWouldULikeToHaveTheMeeting')}</h4>
            {editing !== true &&
                <>

                    <div className="">
                        <input ref={register({ required: true })} onChange={handleDateOptionChange} className="mr-2 demo" type="radio" name="date" id="nodate"
                            defaultChecked={initialValues ? false : true} value={false} />
                        <label style={{ marginTop: '-4px' }}>{i18next.t('SelectMeetingDetailsComponentIDoNotCare')}</label>
                    </div>
                    <div className="">
                        <input ref={register({ required: true })} defaultChecked={initialValues ? true : false}
                            onChange={handleDateOptionChange} className="mr-2 demo" type="radio" name="date" value={true} id="date" />
                        <label style={{ marginTop: '-4px' }}>
                            {i18next.t('SelectMeetingDetailsComponentIDLikeTaSpecificDate')}
                        </label>
                    </div>
                </>
            }
            {errors.date && <p style={{ color: 'red', fontSize: "13px" }} className="errorz">{i18next.t('SelectMeetingDetailsComponentPleaseSelectOneOption')}</p>}
            {withDate === 'true' &&
                <div className="  d-flex flex-md-row flex-column justify-content-start">
                    <div style={{ minWidth: !mobileNav && '130px', maxWidth: !mobileNav && '170px' }} className="">
                        <p className="mb-1 mr-4 small_grey">{i18next.t('SelectMeetingDetailsComponentSelectDate')}</p>
                        {((!loading && editing) || (!editing)) &&
                            <Controller
                                ref={register({ required: editing })}
                                rules={{ required: editing }}
                                defaultValue={initialValues && initialValues.request_date ? getInitialDate()
                                    : ""}
                                as={DatePicker}
                                control={control}
                                valueName="selected"
                                onChange={([selected]) => selected}
                                name="ReactDatepicker"
                                className="date-picker"
                                placeholderText="Select date"
                                clearIcon={null}
                                format="dd/MM/yyyy"
                                minDate={new Date()}
                                calendarIcon={<img src={AddCalendar} alt="calendar" />}
                            />}
                        {errors.ReactDatepicker && <p style={{ color: 'red', fontSize: "13px" }} className="errorz">{i18next.t('SelectMeetingDetailsComponentFieldRequired')}</p>}
                    </div>
                    <div style={{ flexWrap: 'wrap' }} className="d-flex  mt-4 mt-md-0 ">
                        <div style={{ minWidth: !mobileNav && '130px', maxWidth: !mobileNav ? '160px' : "193px", }} className="mx-md-3 mr-3">
                            <p className="mb-1 small_grey">{i18next.t('SelectMeetingDetailsComponentStartTime')}</p>

                            {((!loading && editing) || (!editing))
                                &&
                                <div ref={startPickerRef} onClick={() => setStartPickerOpen(!startPickerOpen)} style={{ position: 'relative' }} className="">
                                    <Controller
                                        rules={{ required: editing }}
                                        defaultValue={(initialValues && initialValues.startTime && initialValues.startTime !== "") ? initialValues.startTime : undefined}
                                        control={control}
                                        valueName="selected"
                                        name="TimePickerStart"
                                        render={({ onChange, onBlur, value }) => (
                                            <TimePicker
                                                open={startPickerOpen}
                                                selected={value}
                                                preventOpenOnFocus={true}
                                                onChange={onChange}
                                                readOnly
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                dateFormat="h:mm aa"
                                            />
                                        )}
                                    />
                                    <img onClick={() => setStartPickerOpen(!startPickerOpen)} src={Clock} alt="" style={{ cursor: 'pointer', position: 'absolute', top: '11px', height: '18px', width: '18px', right: '12px' }} />
                                    {errors.TimePickerStart && <p style={{ color: 'red', fontSize: "13px" }} className="errorz">{i18next.t('SelectMeetingDetailsComponentFieldRequired')}</p>}
                                </div>
                            }
                        </div>

                        <div style={{ minWidth: !mobileNav && '130px', maxWidth: !mobileNav ? '160px' : "193px" }} >
                            <p className="mb-1 small_grey">{i18next.t('SelectMeetingDetailsComponentEndtime')}</p>

                            {((!loading && editing) || (!editing)) &&

                                <div ref={endPickerRef} onClick={() => setEndPickerOpen(!endPickerOpen)} style={{ position: 'relative' }} className="">

                                    <Controller
                                        rules={{ required: editing }}
                                        defaultValue={(initialValues && initialValues.endTime && initialValues.endTime !== "") ? initialValues.endTime : undefined}
                                        control={control}
                                        valueName="selected"
                                        name="TimePickerEnd"
                                        render={({ onChange, value }) => {
                                            return (
                                                <TimePicker
                                                    open={endPickerOpen}
                                                    selected={value}
                                                    preventOpenOnFocus={true}
                                                    onChange={onChange}
                                                    readOnly
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    dateFormat="h:mm aa"
                                                />
                                            )
                                        }

                                        }
                                    />
                                    <img src={Clock} alt="" style={{ cursor: 'pointer', position: 'absolute', top: '11px', height: '18px', width: '18px', right: '12px' }} />
                                    {errors.TimePickerEnd && <p style={{ color: 'red', fontSize: "13px" }} className="errorz">{i18next.t('SelectMeetingDetailsComponentFieldRequired')}</p>}

                                </div>
                            }
                        </div>


                        {/* <div style={{ width: '30%' }} className="mr-2 ml-2">
                            <p className="mb-1 small_grey">Start time</p>
                            <input onChange={(e) => setStartTime(e.target.value)}
                                defaultValue={initialValues && initialValues.request_start_hour ? initialValues.request_start_hour : false}
                                ref={register} step={900} className="input_people" id="input_people_publish" type="time" name="event_start_hour"
                            />
                            {editorStartTime && <span className="text-center" style={{ fontSize: '12px', color: '#d69e2e' }}>{editorStartTime + " based on your location"}</span>}

                            {errors.request_start_hour && <p style={{ color: 'red', fontSize: "13px" }} className="errorz">This field is required</p>}

                        </div >
                        <div style={{ position: 'relative', width: '30%' }}>
                            <p className="mb-1 small_grey">End time</p>
                            <input onChange={(e) => setEndTime(e.target.value)} defaultValue={initialValues && initialValues.request_end_hour ?
                                initialValues.request_end_hour : false}
                                ref={register} step={900} className="input_people" id="input_people_publish" type="time" name="event_end_hour"
                            />
                            {overnightEvent && <span style={{ position: 'absolute', right: '25px', fontSize: '12px' }}>+1</span>}
                            {errors.end_hour && <p style={{ color: 'red', fontSize: "13px" }} className="errorz">This field is required</p>}

                        </div> */}

                    </div>
                </div>
            }
            {editing !== true &&

                // <div className="my-2" style={{ maxWidth: !mobileNav ? '320px' : "386px" }}>

                //     <p className="mb-1 small_grey">Currency: {currency && currency.currency_name}</p>

                //     </div>



                <div className="mb-1" style={{ maxWidth: !mobileNav ? '320px' : "386px" }}>

                    <p className="mb-1 small_grey">{i18next.t('SelectMeetingDetailsComponentCurrency')}</p>
                    <select value={currency && currency.currency_code} onChange={handleCurrencyChange} ref={register} disabled={false} className="select_category" name="event_currency" >
                        {currencies.map(currency => {
                            return (
                                <option id={currency.currency_ide} value={currency.currency_code} key={currency.currency_code}>
                                    {currency.currency_name}
                                </option>
                            )
                        }
                        )}
                    </select>

                </div>
            }
            <div className="mt-4" style={{ maxWidth: !mobileNav ? '160px' : "193px" }}>
                <p className="mb-1 small_grey">{i18next.t('SelectMeetingDetailsComponentPricePerTicket')} {currency && currency.currency_symbol} ({currency && currency.currency_name})</p>
                <input defaultValue={initialValues && initialValues.request_price_ticket}
                    placeholder={currency && currency.currency_code && currency.currency_code.toUpperCase()}
                    ref={register({ required: true })}
                    onKeyPress={(e) => verifyRegex(e, /[\d*\.?\d{1,2}+$]/)}
                    className="input_people" name="price" type="text" style={{ textAlign: "center" }}
                />
                {errors.price && <p style={{ color: 'red', fontSize: "13px" }} className="errorz">{i18next.t('SelectMeetingDetailsComponentFieldRequired')}</p>}
            </div>
            <div className="mt-4">
                {editing ?
                    <>
                        <h4>{i18next.t('SelectMeetingDetailsComponentPurposeOfTheMeeting')}</h4>

                    </>
                    :

                    <>
                        <h4>{i18next.t('SelectMeetingDetailsComponentExplainYourPurpose')}</h4>

                        <p className="mb-2 small_grey">
                            {i18next.t('SelectMeetingDetailsComponentYouCanAddExternalLinks')}
                        </p>

                        <p className="mb-2 small_grey">
                            {i18next.t('SelectMeetingDetailsComponentCharactersLeft', { char: char })}
                        </p>
                        
                    </>
                }
                <textarea onChange={handleDescriptionChange}
                    ref={register({ required: true })} className="textArea textarea_request" name="event_description"
                    defaultValue={initialValues && initialValues.request_reason} disabled={initialValues && true}
                    maxLength={DESCRIPTIONLIMIT} />
                {errors.event_description && <p style={{ color: 'red', fontSize: "13px" }} className="errorz">{i18next.t('SelectMeetingDetailsComponentFieldRequired')}</p>}

            </div>

        </div>
    )
}
export default SelectMeetingDetails