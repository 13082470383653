import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import {
  Api,
   LOGIN_BACK_URL,
  ACCESS_TOKEN_API,
  INFLUENCER_ROLE
} from "../../../../environment/environment";
import { withRouter } from "react-router-dom";
import SweetAlert from "sweetalert2-react";
import { connect } from "react-redux";
import * as actions from '../../../../store/actions/user';

const VerifyFacebookLogin =  ({ history, setUser,getInfluencer }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const verifyFacebook = useCallback(async () => {
    
  }, [history]);

  useEffect(() => {
    verifyFacebook();
  }, []);

  return (
    errorMessage && (
      <SweetAlert
        show={errorMessage}
        title={errorMessage}
        onConfirm={() => {
          setErrorMessage("");
          history.push("/");
        }}
      />
    )
  );
};


const mapDispatchToProps = dispatch => {
  return {
    setUser: data => dispatch(actions.setUser(data)),
    getInfluencer: token => dispatch(actions.getInfluencer(token))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(VerifyFacebookLogin));

