import React, {Component,useState} from 'react';
import Filters_search from "../../search_page/search_by_category/filters_search";
import Back from "../../comunes/back";
import NavBar_search from "../../search_page/search/navbar_search";
import Video from "../../comunes/videos_seccion";


function AllVideos(props) {

    return (
     <div>
         <NavBar_search/>

         <div className="container">
             <div className="row mt-4">
                 <Back text={"Back to Search"} url={"/search"}/>

             </div>
         </div>

         <div className="container">
             <Filters_search/>
         </div>

         <Video />
     </div>
    );
}

export default AllVideos;