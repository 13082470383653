import React, {Component} from 'react';

import './style.scss';
import map from "../../../assets/img/icons/ic-map.svg";
import FechaCat from "../header-streaming";
import logo from '../../../assets/img/logo-circulo-white.png';
import {Link} from "react-router-dom";
import EndCat from "../end-streaming";
class MenuModerator extends Component {
    render() {
        return (
            <div className="menu_ban2">
                <div className="menu_ban">
                    <img src={logo} className="Logo3" alt="logo"/>
                    <h2 className="moderation_ban_titulo">Live Event Moderation</h2>
                </div>
                <Link to = {'/moderator-view'}><button className="leave-streaming_ban">Back to streaming</button></Link>
            </div>
        );
    }
}

export default MenuModerator;

