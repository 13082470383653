import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import i18next from "i18next";
import { isMobile } from "react-device-detect";
import ButtonManagerElement from "./ButtonManagerElement";
import ButtonManagerCollapser from "./ButtonManagerCollapser";
import SettingsIcon from "assets/img/icons/settings-dark.svg";
import UpArrowCollapserIcon from "assets/img/icons/up-arrow.svg";
import DownArrowCollapserIcon from "assets/img/icons/down-arrow.svg";
import iconUser from "assets/img/icons/user-dark.svg";
import StopCallIcon from "assets/img/icons/hangCall-dark.png";
import CameraDisabled from "assets/img/icons/ic-camera-off-dark.svg";
import CameraEnabled from "assets/img/icons/ic-camera-on.svg";
import RaiseHandEnabled from "assets/img/icons/raise-hand.svg";
import RaiseHandDisabled from "assets/img/icons/raise-hand-off.svg";
import chatBubble from "assets/img/icons/chat-bubble-dark.svg";
import chatBubbleDisabled from "assets/img/icons/chat-bubble-dark-off.svg";
import logo from "assets/img/icons/reloadLogo.png";
import logoCirculo from "assets/img/icons/reloadLogo.png";
import Request from "assets/img/icons/ic-requests.svg";
import OpenMicEnabled from "assets/img/icons/ic-openMode-dark.svg";
import OpenMicDisabled from "assets/img/icons/ic-openMode-dark-off.svg";
import MicroDisabled from "assets/img/icons/ic-streaming-mic-off-dark.svg";
import MicroEnabled from "assets/img/icons/ic-streaming-mic-dark.svg";

const ButtonManager = ({
  toggleStreamingOptionsModal,
  toggleViewerModal,
  showParticipantsButton,
  eventStarted,
  isInfluencer,
  microphone,
  onSetMicrophone,
  webcam,
  enabledChat,
  onSetWebcam,
  hasTurn,
  onSetEnableChat,
  enableRiseHand,
  onSetEnableRiseHand,
  onSetRiseHand,
  showChooseRaiseHand,
  onStopRemoteCall,
  onSetEnableOpenMic,
  enabledOpenMic,
  risedHand,
  mySpeaking_viewer,
  isLandscape,
  showButtons,
  setShowButtons
}) => {
  var shouldShowSettings = false;
  //const [showButtons, setShowButtons] = useState(false);
  console.log("toggleStreamingOptionsModal", toggleStreamingOptionsModal);

  if (!eventStarted || isInfluencer || hasTurn || enabledOpenMic) {
    if(!isMobile){
      shouldShowSettings = true;
    }
  }

  if(isMobile && !eventStarted){
    return null;
  }

  if(/*isLandscape && */!showButtons){
    return (
      <div
      className={
        "ButtonManagerContainer ButtonManagerItemFirstElement ButtonManagerItemLastElement"
      }
    >
      <ButtonManagerCollapser
      icon={showButtons ? DownArrowCollapserIcon : UpArrowCollapserIcon}
      onClick={() =>{
        setShowButtons(!showButtons)
      }}
      />

    </div>
    )
  }

  return (
    <div
      className={
        "ButtonManagerContainer ButtonManagerItemFirstElement ButtonManagerItemLastElement"
      }
    >
      {/*isLandscape*/ showButtons && (
        <ButtonManagerCollapser
        icon={showButtons ? DownArrowCollapserIcon : UpArrowCollapserIcon}
        specialClassName={showButtons}
        onClick={() =>{
          setShowButtons(!showButtons)
        }}
        />
      )}
      {showParticipantsButton && (
        <ButtonManagerElement
          tooltipText={i18next.t(
            "PreviewStreamingInfluencerComponentTooltipShowOnlineViewersPanel"
          )}
          icon={iconUser}
          onClick={toggleViewerModal}
          isFirstElement={true}
          label={"Participants"}
        />
      )}
      {eventStarted && (isInfluencer || hasTurn || enabledOpenMic) && (
        <ButtonManagerElement
          tooltipText={
            microphone
              ? i18next.t(
                  "PreviewStreamingInfluencerComponentTooltipDisableMicrophone"
                )
              : i18next.t(
                  "PreviewStreamingInfluencerComponentTooltipEnableMicrophone"
                )
          }
          icon={microphone ? MicroEnabled : MicroDisabled}
          onClick={onSetMicrophone}
          label={"Mic on/off"}
        />
      )}
      {eventStarted && (isInfluencer || hasTurn) && (
        <ButtonManagerElement
          tooltipText={
            webcam
              ? i18next.t(
                  "PreviewStreamingInfluencerComponentTooltipDisableCamera"
                )
              : i18next.t(
                  "PreviewStreamingInfluencerComponentTooltipEnableCamera"
                )
          }
          icon={webcam ? CameraEnabled : CameraDisabled}
          onClick={onSetWebcam}
          label={"Cam on/off"}
        />
      )}
      {eventStarted && isInfluencer && (
        <ButtonManagerElement
          tooltipText={
            enableRiseHand
              ? i18next.t(
                  "PreviewStreamingInfluencerComponentTooltipDisableRaiseHand"
                )
              : i18next.t(
                  "PreviewStreamingInfluencerComponentTooltipEnableRaiseHand"
                )
          }
          icon={enableRiseHand ? RaiseHandDisabled : RaiseHandEnabled}
          onClick={onSetEnableRiseHand}
          label={"Raise hand"}
        />
      )}
      {eventStarted && isInfluencer && mySpeaking_viewer && (
        <ButtonManagerElement
          tooltipText={i18next.t(
            "PreviewStreamingInfluencerComponentTooltipDisableRaiseHandStopViewersTurn"
          )}
          icon={StopCallIcon}
          onClick={onStopRemoteCall}
          label={"Stop turn"}
        />
      )}
      {eventStarted && isInfluencer && (
        <ButtonManagerElement
          tooltipText={
            enabledOpenMic
              ? i18next.t(
                  "PreviewStreamingInfluencerComponentTooltipDisableOpenMic"
                )
              : i18next.t(
                  "PreviewStreamingInfluencerComponentTooltipEnableOpenMic"
                )
          }
          icon={enabledOpenMic ? OpenMicDisabled : OpenMicEnabled}
          onClick={onSetEnableOpenMic}
          label={"Open mic"}
        />
      )}{" "}
      {eventStarted && isInfluencer && (
        <ButtonManagerElement
          tooltipText={
            enabledChat
            ? i18next.t(
                "PreviewStreamingInfluencerComponentTooltipDisableChat"
              )
            : i18next.t(
                "PreviewStreamingInfluencerComponentTooltipEnableChat"
              )
          }
          icon={enabledChat ? chatBubbleDisabled : chatBubble}
          onClick={onSetEnableChat}
          label={"Chat moderation"}
        />
      )}
      {eventStarted && !isInfluencer && enableRiseHand && !hasTurn && (
        <ButtonManagerElement
          tooltipText={
            !risedHand
              ? i18next.t(
                  "PreviewStreamingInfluencerComponentTooltipRaiseUpTheHand"
                )
              : i18next.t(
                  "PreviewStreamingInfluencerComponentTooltipRaiseDownTheHand"
                )
          }
          icon={risedHand ? RaiseHandDisabled : RaiseHandEnabled}
          onClick={onSetRiseHand}
          isLastElement={!enabledOpenMic}
          label={"Raise hand"}
        />
      )}
      {shouldShowSettings && (
        <ButtonManagerElement
          tooltipText={i18next.t(
            "PreviewStreamingInfluencerComponentTooltipOpenSettings"
          )}
          icon={SettingsIcon}
          onClick={toggleStreamingOptionsModal}
          isFirstElement={!eventStarted}
          isLastElement={true}
          label={"Settings"}
        />
      )}
    </div>
  );
};

export default ButtonManager;
