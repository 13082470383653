import React from 'react'
import { Modal } from "react-bootstrap";
import logob from '../../assets/img/gotmy-n.png';
import { Link } from "react-router-dom";
import { googlePlayGotmyURL, appleStoreGotmyURL } from '../../environment/environment'
import styled from 'styled-components'
import AppStore from '../../assets/img/AppStoreBoton.png';
import PlayStore from '../../assets/img/PlayStoreBoton.png'

const ModalSuccess = ({ modalDownload, setModalDownload , message}) => {
    return (
        <Modal show={modalDownload} onHide={() => setModalDownload(false)}
        >

            <Modal.Body>
                <div className="text-center d-flex flex-column justify-content-center align-items-center">
                    <img src={logob} className=" Logo" alt="logo" />

                    <Title className="mb-1">{message}</Title>
                    
                    <div className="logos d-flex mb-4  mt-4">

                        <a id="leftIcon" style={{ display: 'block'  }} className=" " href={appleStoreGotmyURL} target="_blank">

                            <Logo style={{ marginRight:'8px'}} className="storeLogo" src={AppStore} alt="App Store" />
                        </a>
                        <a style={{ display: 'block' }} className="" href={googlePlayGotmyURL} target="_blank">

                            <Logo  className="storeLogo" src={PlayStore} alt="Google Play" />
                        </a>


                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default ModalSuccess
const Title= styled.div` 
font-size:22px;
    @media(max-width:768px){
        font-size:20px;
    }
`
const Logo= styled.img` 
height:50px;
    @media(max-width:768px){
        font-size:40;
    }
`