import { useState, useEffect, useCallback } from "react";
import { LanguageDAO, UserDAO, CategoryDAO } from "daos";
import * as yup from "yup";
import moment from "moment";
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions";
import axios from "axios";
toast.configure();

const dateLimit = moment(new Date())
  .subtract(16, "y")
  .format("YYYY-MM-DD");

const initialValuesUser = {
  name: "",
  username: "",
  birthdate: "",
  biography: "",
  country: "0",
  categories: [],
  languages: [],
  avatar: "",
  location: "",
};

const languagueDAO = new LanguageDAO();
const userDAO = new UserDAO();
const categoryDAO = new CategoryDAO();

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^[a-zA-Z.\u00C0-\u024F\u1E00-\u1EFF ]*$/,
      "Name and last name format  must be A-Z a-z!"
    )
    .max(20, "max number of characters in name is 20")
    .required(),
  username: yup
    .string()
    .matches(
      /^[a-zA-Z]{3}[a-zA-Z0-9_-]{2,26}$/,
      "Your username can only contain letters and numbers!"
    )
    .max(26, "max number of characters in username is 26")
    .required(),
  lastname: yup
    .string()
    .matches(
      /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF ]*$/,
      "Name and last name format  must be A-Z a-z! "
    )
    .min(0)
    .max(20, "max number of characters in last name is 20")
    .required(),
  // location: yup.string().required(),
  // languages: yup.array().required(),
});

let auxLanguages = [];

const useMyPublicProfileLogic = ({
  history,
  match,
  onGetUser,
  onGetInfluencer,
}) => {
  const dispatch = useDispatch();
  const [userValues, setUserValues] = useState(initialValuesUser);
  const [presentationVideo, setPresentationVideo] = useState(null);
  const [countries, setCountries] = useState([]);
  const [categories, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [languages, setLanguages] = useState([]);
  const [languagesSelected, setLanguagesSelected] = useState([]);
  const [showErrorValidation, setShowErrorValidation] = useState(false);
  const [missingFields, setMissingFields] = useState({
    message: "",
    missingFields: "",
  });
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [loadingImage, setLoadingImage] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [avaliable, setAvaliable] = useState(undefined);
  const [alreadyInfluencer, setAlreadyInfluencer] = useState(false);
  const [showModalCategories, setShowModalCategories] = useState(false);
  const [tabKeySelected, setTabKeySelected] = useState("profile");
  const [loadedImg, setLoadedImg] = useState(false);

  /** Get parameter to detect if you want to be influencer */
  const isInfluencer =
    Object.keys(match.params > 0) && match.params.active ? true : false;

  // Get the categories and set them in the state.
  const initCategory = async () => {
    try {
      const response = await categoryDAO.findAll();
      const { data } = response.data;
      setCategory(data);
    } catch (err) {
      throw err;
    }
  };

  // Get the languages and set them in the state.
  const initLanguages = async () => {
    try {
      const response = await languagueDAO.findAll();
      const { data } = response.data;
      auxLanguages = data;
      setLanguages(data);
    } catch (err) {
      throw err;
    }
  };

  // Obtains the information of the logged-in user and sets them in the state.
  const initUser = async () => {
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    const { token } = env;
    try {
      const response = await userDAO.findByToken(token);
      const { data } = response.data;
      const updatedValues = JSON.parse(JSON.stringify(userValues));
      updatedValues.name = data.user_name;
      updatedValues.lastname = data.user_lastname;
      updatedValues.avatar = data.user_avatar;
      const birthdate = moment.unix(parseInt(data.user_birthdate) / 1000);
      updatedValues.birthdate = birthdate.format("YYYY-MM-DD");
      updatedValues.country = data.user_city;
      updatedValues.username = data.user_username;
      updatedValues.biography = data.user_aboutme;
      try {
        updatedValues.location =
          data.user_location && JSON.parse(data.user_location).city;
      } catch (err) {}
      const categoryUser = await userDAO.findCategories(token);
      const languagesUser = await userDAO.findLanguages(token);
      const langSelected = languagesUser.data.data
        .map((item) => {
          const detail = auxLanguages.find(
            (lang) => lang.lang_ide === item.lang_ide
          );
          return detail;
        })
        .filter((lang) => lang);
      updatedValues.languages = langSelected.map((lang) => lang.lang_ide);
      updatedValues.categories = categoryUser.data.data.map(
        (cat) => cat.cate_ide
      );
      if (!isInfluencer) setUserValues(updatedValues);
      setLanguagesSelected(langSelected);

      if (isInfluencer) {
        userDAO
          .profileInfluencer(token)
          .then((data) => {
            if (data.data && data.data.influencer) {
              const { influencer } = data.data;
              updatedValues.biography = influencer.influencer_aboutme;
              updatedValues.avatar = influencer.influencer_avatar;
              updatedValues.available =
                influencer.influencer_receive_private_request;
              setAvaliable(updatedValues.available);
              setUserValues(updatedValues);
              setAlreadyInfluencer(true);
            }
          })
          .catch((err) => {
            setUserValues(updatedValues);
          });
      }
    } catch (err) {
      throw err;
    } finally {
      setLoadingInitial(false);
    }
  };

  const handleAvaliable = () => {
    setUserValues({ ...userValues, available: !avaliable });
    setAvaliable(!avaliable);
  };
  const init = useCallback(async () => {
    // await initCountries();
    await initLanguages();
    await initCategory();
    initUser();
  }, []);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (languages.length > 0) {
      setTimeout(() => {
        if (document.querySelector(".rbt-aux")) {
          document.querySelector(".rbt-aux").style.display = "none";
        }
      }, 2000);
    }
  }, [languages]);

  /**
   * Handles the form changes and updates the values for the user in the state.
   * @param {Object} event, Event triggered for the form
   */
  const onChangeUserValues = (event) => {
    const { name, value, files } = event.target;
    console.log("onChangeUserValues", name, value, files, event.target);

    const updatedValues = JSON.parse(JSON.stringify(userValues));
    updatedValues[name] = value;
    setUserValues(updatedValues);
  };

  const onChangePresentationVideo = (event) => {
    const { name, value, files } = event.target;
    console.log("onChangePresentationVideo", name, value, files, event.target);

    if(files.length > 0){
      validateVideo(files[0], event.target )
    }else{
      //Throw error
      toast("Should select a video file")
    }
  };

  const validateVideo = (videoFile, target) => {
    console.log("validateVideo", videoFile)
    if (!videoFile || !videoFile.type) {
      toast("Sorry, something went wrong.");
      return;
      //error
    }

    var compatibleMimeType = false;

    if (
      videoFile.type === "video/mp4" ||
      videoFile.type === "video/quicktime"
    ) {
      compatibleMimeType = true;
    }

    if (!compatibleMimeType) {
      //error
      toast("Only supported .mp4 or .mov");
      return;
    }

    const MAX_FILE_SIZE = 100 * 1024 * 1024; // X * MEGABYTES * KILOBYTES
    const MAX_FILE_DURATION = 30; // SECONDS

    if (videoFile.size > MAX_FILE_SIZE) {
      toast("File size exceeded");
      return;
    }

    try {
      var video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        var duration = video.duration;

        if (duration > MAX_FILE_DURATION) {
          //error
          toast(
            "Video can not excceed " + MAX_FILE_DURATION + " duration."
          );
          return;
        }

        //Save video
        setPresentationVideo({file: videoFile, target: target, videoURL: URL.createObjectURL(videoFile)});
        
      };

      video.src = URL.createObjectURL(videoFile);
    } catch (error) {
      toast("Sorry, something went wrong.");
    }
  };

  const onChangeDatePickerValues = (date) => {
    const updatedValues = JSON.parse(JSON.stringify(userValues));
    updatedValues["birthdate"] = date;
    setUserValues(updatedValues);
  };
  const onChangeLanguageValues = (languages) => {
    const updatedValues = JSON.parse(JSON.stringify(userValues));
    updatedValues["languages"] = languages;

    setUserValues(updatedValues);
  };
  const onChangeLocationValues = (location) => {
    const updatedValues = JSON.parse(JSON.stringify(userValues));
    updatedValues["location"] = location;
    setUserValues(updatedValues);
  };

  /**
   * Handle changes to the category checkbox
   * @param {Number} value, Category Id
   */
  const onChangeCategories = (value) => {
    const updatedUserValues = JSON.parse(JSON.stringify(userValues));
    const { categories } = updatedUserValues;
    const index = categories.indexOf(value);

    if (index < 0) {
      categories.push(value);
    } else {
      categories.splice(index, 1);
    }
    updatedUserValues["categories"] = categories;
    setUserValues(updatedUserValues);
  };

  /**
   * Handle image changes.
   * @param {Object} event, Event trigered for the input file
   */
  const handleImageChange = async (url) => {
    setLoadingImage(true);
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compresedFile = await imageCompression(url, options);
    const base64 = await imageCompression.getDataUrlFromFile(compresedFile);

    const updatedUserValues = JSON.parse(JSON.stringify(userValues));
    updatedUserValues.avatar = base64;
    setUserValues(updatedUserValues);
    setLoadingImage(false);
  };

  const handleImageLoad = async (event) => {
    const reg = new RegExp("(.*?).(jpg|png|jpeg)$");
    if (!reg.test(event.target.value)) {
      toast("Supported formats are JPG, PNG and JPEG!");
      return;
    } else {
      setLoadedImg(false);
      setLoadingImage(true);
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setLoadedImg(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
      setLoadingImage(false);
    }
  };

  /**
   * Send the information to the API.
   * @param {Object} event
   */
  const onSubmit = async (event) => {
    event.preventDefault();
    const env = JSON.parse(localStorage.getItem("env") || "{}");
    const { token } = env;
    const valid = await schema.isValid(userValues);
    try {
      const validate = await schema.validate(userValues);
    } catch (error) {
      let missingFields = [];
      schema._nodes.map((node, i) => {
        if (userValues[node] === "" || userValues[node].length === 0) {
          missingFields.push(node);
        }
      });
      if (missingFields.length > 0) {
        // setMissingFields({message: "Some fields are missing" , missingFields: missingFields.join(', ')})
        setMissingFields({ message: error.message });
      } else {
        setMissingFields({ message: error.message });
      }

      setShowErrorValidation(true);
      return;
    }
    if (!valid) {
    }

    setUpdating(true);

    try {
      const data = !isInfluencer
        ? {
            username: userValues.username,
            user_name: userValues.name,
            ...(userValues.lastname &&
              userValues.lastname !== "" && {
                user_lastname: userValues.lastname,
              }),
            user_birthdate: moment(userValues.birthdate).isValid()
              ? new Date(moment(userValues.birthdate)).getTime()
              : new Date(moment(dateLimit)).getTime(),
            user_aboutme: userValues.biography,
            user_avatar: userValues.avatar,
            user_username: userValues.username,
          }
        : {
            user_username: userValues.username,
            user_name: userValues.name,
            ...(userValues.lastname &&
              userValues.lastname !== "" && {
                user_lastname: userValues.lastname,
              }),
            user_birthdate: moment(userValues.birthdate).isValid()
              ? new Date(moment(userValues.birthdate)).getTime()
              : new Date(moment(dateLimit)).getTime(),
            user_aboutme: userValues.biography,
            user_avatar: userValues.avatar,

            influencer_aboutme: userValues.biography,
            influencer_avatar: userValues.avatar,
            influencer_receive_private_request: userValues.available,
          };

      try {
        const code = await geocodeByAddress(userValues.location);

        const latLng = await getLatLng(code[0]);
        data.user_location = {
          city: userValues.location,
          lat: latLng.lat,
          lng: latLng.lng,
        };
        console.log({ data });
      } catch (err) {}

      const avatar = data.user_avatar || data.influencer_avatar;
      if (!avatar.includes("base64")) {
        delete data.user_avatar;
        delete data.influencer_avatar;
      }

      await userDAO.update(token, data);

      if(presentationVideo){
        const videoFile = presentationVideo.file;

        const values = new FormData();

        let response = null;

        console.log("Esto tiene submitVideo", videoFile)

        values.append("influencer_presentation_video", videoFile, videoFile.name);

        response = await userDAO.updatePresentationVideo(values, token);

        console.log("Esto tiene updatePresentationVideo", response)

      }
      //  else {
      if (alreadyInfluencer) {
        await userDAO.updateInfluencer(token, data);
      } else {
        try {
          await userDAO.upgradeToInfluencer(token, data);
          setAlreadyInfluencer(true);
        } catch (err) {
          // TODO: Fixme.The server is returning status 500 when the upgrade is still successful.
          if (err.response.status === 500) {
            if (isInfluencer) {
              history.push("/profile_influencer");
            } else {
              history.push("/profile");
            }
          }
        }
      }

      await userDAO.setLanguages(token, {
        languages: userValues.languages,
      });
      await userDAO.setCategories(token, {
        categories: userValues.categories,
      });
      await dispatch(actions.getUser(token));
      if (isInfluencer) {
        await dispatch(actions.getInfluencer(token));

        history.push("/profile_influencer");
      } else {
        var redirectUrl = localStorage.getItem("pendingPrivateRequest");
        if (redirectUrl && data.user_location) {
          history.push(`${redirectUrl}`);
          localStorage.removeItem("pendingPrivateRequest");
        } else if (redirectUrl && data.user_location === undefined) {
          alert(
            "You have a pending private meeting. Fill out your location to request it!"
          );
        } else {
          history.push("/profile");
        }
      }
    } catch (err) {
      // TODO: handler this!
      throw err;
    } finally {
      setUpdating(false);
    }
  };

  return {
    userValues,
    presentationVideo,
    languagesSelected,
    onChangeUserValues,
    onChangePresentationVideo,
    onChangeDatePickerValues,
    countries,
    languages,
    categories,
    setLanguagesSelected,
    onSubmit,
    showErrorValidation,
    setShowErrorValidation,
    missingFields,
    setMissingFields,
    onChangeCategories,
    setUserValues,
    setCountries,
    handleImageChange,
    loadingInitial,
    setLoadingInitial,
    loadingImage,
    updating,
    isInfluencer,
    avaliable,
    handleAvaliable,
    showModalCategories,
    setShowModalCategories,
    tabKeySelected,
    setTabKeySelected,
    selectedCategory,
    setSelectedCategory,
    onChangeLanguageValues,
    onChangeLocationValues,
    handleImageChange,
    loadedImg,
    handleImageLoad,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onConfirmUser: (token) => dispatch(actions.confirmUser(token)),
    onClearErrors: () => dispatch(actions.clearErrors()),
    onGetUser: (token) => dispatch(actions.getUser(token)),
    onGetInfluencer: (token) => dispatch(actions.getInfluencer(token)),
  };
};
const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    loading: state.user.loading,
    error: state.user.error,
  };
};
export default useMyPublicProfileLogic;
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(useMyPublicProfileLogic);
