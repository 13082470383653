import React from 'react';
import {useSelector } from "react-redux"

import UpcomingLiveEvent from "./card";
import Camera from '../../comunes/camera';
import Rating from "../../comunes_events/rating"
import RatingPoints from "../../comunes_events/rating_points";
import './style.scss';
import Bar from '../../comunes/graphic_reviews';
import i18next from "i18next"
function RatingReview(props) {
    	    const stats = useSelector(state=> state.user.myStats.data) // stats.ratings.rating
            const influencer = useSelector(state=>state.user.influencer)
             console.log ({influencer})
    return (
        <div className="container  ">
        <div style={{paddingTop:"5rem",  }} className="row " id="rating_reviews">
            <div style={{justifyContent: "space-between"}} className="d-flex w-100 title_rating"><h2>{i18next.t("RatingReviewsAll") }</h2>
            {stats && stats.rating.reviews && 
            <div className= "mt-2">
            <RatingPoints score={influencer.influencer_ranking} reviews={stats.rating.reviews.length} />
            </div>
            }
            </div>
            <div className="col-12 mt-4"><Bar/></div> 
            <div className="col-12 comments_review">
                
                <h2 className="title_reviews"> {i18next.t("RatingReviewsReviews") }</h2>
       
            {stats && stats.rating && stats.rating.reviews && 
                stats.rating.reviews.map ((review)=>{
                    return (<Rating review={review} /> )
                })
            }
                {/* <p className="show_reviews">Show more Reviews</p> */}
            </div>
           
        
        </div>
    </div>
    );
}

export default RatingReview;