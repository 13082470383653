import { useState } from "react";
import { UserDAO } from "daos";
import i18next from "i18next";


const userDAO = new UserDAO();

/**
 * @param {Function} showAlert, Show alert on the screen
 */
export const useModalRecoverLogic = ({ showAlert }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    if (loading) return;

    setLoading(true);

    try {
      const { data } = await userDAO.forgotPassword(email);
      if (data.success) {
        showAlert(i18next.t("useModalRecoverLogicLinkSent",{email: email}));
      } else {
        showAlert(i18next.t("useModalRecoverLogicLinkSentSorryError"));
      }
    } catch (e) {
      const { status } = e.response;
      if (status === 404) {
        showAlert(i18next.t("useModalRecoverLogicLinkSentSorryError"));
      } else {
        showAlert(`Error: ${e.toString()}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return { email, setEmail, onSubmit, loading };
};
