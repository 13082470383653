import React, { useState, useEffect } from "react";
import PuntUsu from "components/comunes_events/imagen_usu_puntuacion";
import ticketFree from "assets/img/icons/ticket_free.svg";
import ticketImg from "assets/img/icons/ticket.svg";
import DiaMes from "components/comunes_events/dia_mes";
import NombreCat from "components/comunes_events/nombre_cat_fecha";
import { Modal } from "react-bootstrap";
import logob from '../../assets/img/gotmy-n.png';
import useBuyEvent from '../../hooks/useBuyEvent'
import {
    CardElement, ElementsConsumer
} from '@stripe/react-stripe-js';
import Checkout from './payment/Checkout'
import useUserOrLocalTimeHour from "../../hooks/useUserOrLocalTimeHour"
import i18next from 'i18next';
import useIsUserLoggedIn from "../../hooks/useIsUserLoggedIn"
import { parsePriceByCurrencyJustSymbol, parsePriceByCurrency } from "../../utils/currencyUtils";
import {useSelector} from "react-redux"
import { B2B_PORT } from "../../environment/environment";

const ModalBuyEvent = ({currency, confirmEmail, setConfirmEmail, email, setEmail, match, stripe, elements, mobileNav, modalDetail, setModalDetail, eventData, onGetUser, setModalPurchaseSuccess, setErrorMessage }) => {
    const [finalPrice, setFinalPrice] = useState()
    let {isUserLoggedIn}= useIsUserLoggedIn()

    if(B2B_PORT){
        isUserLoggedIn = false;
    }
    
    const [totalFee, setTotalFee] = useState()
    const [savedCard, setSavedCard] = useState({status:false})

    const { event_title, event_category, event_date, event_start_hour,
        event_uuid, event_cover_image, event_price_ticket, event_price_ticket_streaming, 
        event_location, ticket, ticket_streaming } = eventData.event;
    const { user_username, user_name, user_avatar } = eventData.user;
    const [redirect, setRedirect] = useState({ status: false })
    const [privacy, setPrivacy] = useState(false)
    const [usesPaypal, setUsesPaypal] = useState(false)
    const [terms, setTerms] = useState(false)
    const { payment, handlePurchase } = useBuyEvent({savedCard, ticket, ticket_streaming, match,
        finalPrice, setRedirect, usesPaypal, redirect, stripe, elements, isUserLoggedIn,
        onGetUser, setModalDetail, setModalPurchaseSuccess,
        setErrorMessage, privacy, terms, email, confirmEmail, event_uuid
    })

    const userCards = useSelector ((state)=> state.user.userCardMethods)
    const {time}= useUserOrLocalTimeHour(event_date, eventData.event)

    useEffect(() => {
        if (payment.status === 'success') {
            setModalDetail(false);
            setModalPurchaseSuccess(true);
        }
        if (payment.status === 'error') {
            setErrorMessage(payment.message)
        }
    }, [payment])


    const handleIframeLoad = (e) => {
    }

    useEffect(() => {
        let finalPrice
        let fee
        if (event_price_ticket!==null){

            if (event_price_ticket === 0) {
                setFinalPrice(0)
                setTotalFee(0)
            }
            else if (event_price_ticket > 0 && ticket) {
                fee = ticket.gotmy_min_ticket_fee + (ticket.gotmy_ticket_fee * 0.01 * event_price_ticket)
                finalPrice = event_price_ticket + fee
                setTotalFee(fee.toFixed(2))
                setFinalPrice(finalPrice.toFixed(2))
            }

        }
        else if (event_price_ticket_streaming!==null){
            if (event_price_ticket_streaming === 0) {
                setFinalPrice(0)
                setTotalFee(0)
            }
            else if (event_price_ticket_streaming > 0 && ticket_streaming) {
                fee = ticket_streaming.gotmy_min_ticket_fee + (ticket_streaming.gotmy_ticket_fee * 0.01 * event_price_ticket_streaming)
                finalPrice = event_price_ticket_streaming + fee
                setTotalFee(fee.toFixed(2))
                setFinalPrice(finalPrice.toFixed(2))
            }
        }
    }, [event_price_ticket, event_price_ticket_streaming])

    return (
        <Modal show={modalDetail} onHide={() => setModalDetail(false)}>
            {mobileNav && <Modal.Header closeButton>
                <div className="text-center   w-100">
                    <span className="">{i18next.t('ModalBuyEventComponentBuyTicket')}</span>
                </div>
            </Modal.Header>}
            <Modal.Body>
                {redirect.status !== true ?
                    <>
                        {!mobileNav && <img src={logob} className="mw-100 Logo" alt="logo" />}
                        <div className="d-flex flex-column flex-md-row">
                            <div className={`${!mobileNav && "Div-g"}`}>

                                {!mobileNav && <img
                                    style={{ height: '280px', width: '100%', objectFit: 'cover' }}
                                    className="mw-100 mt-4 br-10 mb-4"
                                    src={event_cover_image}
                                    alt=""
                                />
                                }
                                {!mobileNav &&
                                    <PuntUsu margin={0} userFullName={user_name} userName={user_username} userAvatar={user_avatar} />}
                                <div className={`d-flex mb-4 mb-md-0 mt-2 ${!mobileNav ? "align-items-center" : "align-items-start"}`}>
                                    {time && <DiaMes
                                        day={time.day}
                                        month={time.month}
                                    />
}
                                   {time && <NombreCat mode="modal" hideLocation upcoming title={event_title}
                                        category={event_category ? event_category.cate_description : ""} hour={time.time}
                                        location={event_location && JSON.parse(event_location).city} />
}
                                </div>
                            </div>
                            <div className={`${!mobileNav && "Div-m"} d-flex flex-column`}>
                                {!mobileNav && <p className="Ticket"> {i18next.t('ModalBuyEventComponentEventTicket')} {parsePriceByCurrency(finalPrice, currency)  }</p>}
                                <div className="d-flex align-items-center mb-3">
                                    <img src={ticketImg} className="eventPriceImg" />
                                    <div className="d-flex flex-column ml-3 w-100">
                                        <label style={{ fontWeight: 600, color: 'black' }}>{i18next.t('ModalBuyEventComponentEventPrice')}</label>
                                        {(event_price_ticket > 0 || event_price_ticket_streaming>0) ?
                                            <span className="Input-linea">  {event_price_ticket!==null? parsePriceByCurrencyJustSymbol(event_price_ticket, currency) : 
                                                parsePriceByCurrencyJustSymbol(event_price_ticket_streaming, currency)
                                            }</span> :
                                            <span className="Input-linea">{i18next.t('ModalBuyEventComponentFree')}</span>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-3">
                                    <img src={ticketFree} className="eventPriceImg" />
                                    <div className="d-flex flex-column ml-3 w-100">
                                        <label style={{ fontWeight: 600, color: 'black' }}>{i18next.t('ModalBuyEventComponentGotMyFees')}</label>
                                        {ticket?
                                        <span className="Input-linea" type="text"> {parsePriceByCurrencyJustSymbol( ticket.gotmy_min_ticket_fee, currency) }  + {ticket.gotmy_ticket_fee}% = {" "}
                                 {parsePriceByCurrencyJustSymbol(totalFee, currency) } </span>:
                                 
                                 <span className="Input-linea" type="text"> {parsePriceByCurrencyJustSymbol( ticket_streaming.gotmy_min_ticket_fee, currency) }  + {ticket_streaming.gotmy_ticket_fee}% = {" "}
                                 {parsePriceByCurrencyJustSymbol(totalFee, currency) } </span>
                                 }
                                    </div>
                                </div>
                                {parseFloat(finalPrice)>0 &&  <p className="Title">{i18next.t('ModalBuyEventComponentPaymentMethod')}</p> } 
                                <Checkout
                                    savedCard= {savedCard}
                                    setSavedCard= {setSavedCard}
                                    currency ={currency}
                                    setUsesPaypal={setUsesPaypal}
                                    paypal={usesPaypal}
                                    handlePurchase={handlePurchase}
                                    email={email}
                                    setEmail={setEmail}
                                    confirmEmail={confirmEmail}
                                    setConfirmEmail={setConfirmEmail}
                                    payment={payment}
                                    privacy={privacy}
                                    setPrivacy={setPrivacy}
                                    terms={terms}
                                    setTerms={setTerms}
                                    finalPrice={finalPrice}
                                    isUserLoggedIn={B2B_PORT? false : isUserLoggedIn}
                                />

                            </div>
                        </div>
                    </>
                    :
                    <iframe  onLoad={handleIframeLoad} id="iframe" title="redirect" src={redirect.url} width="100%"
                        height="700" frameBorder="0" marginHeight="0" marginWidth="0" />
                }
            </Modal.Body>
        </Modal>

    )
}


export default function InjectedCheckoutForm({currency, confirmEmail, setConfirmEmail, email, setEmail, match, isUserLoggedIn, mobileNav, modalDetail, setModalDetail, eventData, onGetUser, setModalPurchaseSuccess, setErrorMessage }) {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <ModalBuyEvent 
                currency={currency}
                confirmEmail={confirmEmail} 
                setConfirmEmail={setConfirmEmail} 
                email={email} 
                setEmail={setEmail} 
                match={match} 
                isUserLoggedIn={isUserLoggedIn} 
                stripe={stripe} 
                elements={elements}
                 mobileNav={mobileNav} 
                 modalDetail={true} 
                 setModalDetail={setModalDetail} onGetUser={onGetUser}
                setModalPurchaseSuccess={setModalPurchaseSuccess} 
                eventData={eventData}
                 setErrorMessage={setErrorMessage} />
            )}
        </ElementsConsumer>
    );
}