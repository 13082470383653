export const actionTypes = {
  ON_GET_REQUESTS: "ON_GET_REQUESTS",
  ON_SUCCESS_GET_REQUEST: "ON_SUCCESS_GET_REQUEST",
  ON_FINISH_GET_REQUEST: "ON_FINISH_GET_REQUEST",
  ON_SET_SEARCH_INPUT: "ON_SET_SEARCH_INPUT",
  ON_SET_REQUEST_ACTIVE: "ON_SET_REQUEST_ACTIVE",
  ON_REJECT_REQUEST: "ON_REJECT_REQUEST",
  ON_FINISH_REJECT_REQUEST: "ON_FINISH_REJECT_REQUEST",
  ON_ACCEPT_REQUEST: "ON_ACCEPT_REQUEST",
  ON_FINISH_ACCEPT_REQUEST: "ON_FINISH_ACCEPT_REQUEST"
};

export const actions = Object.freeze({
  onGetRequests: state => ({ ...state, loadingRequests: true }),
  onSuccessGetRequest: (state, { requests }) => ({
    ...state,
    requests,
    loadingRequests: false
  }),
  onFinishGetRequest: state => ({
    ...state,
    loadingRequests: false
  }),
  onSetSearchInput: (state, { value }) => ({
    ...state,
    searchInput: value
  }),
  onSetRequestActive: (state, { requestActive }) => ({
    ...state,
    requestActive
  }),
  onRejectRequest: state => ({ ...state, rejecting: true }),
  onFinishRejectRequest: state => ({ ...state, rejecting: false }),
  onAcceptRequest: state => ({ ...state, accepting: true }),
  onFinishAcceptRequest: state => ({ ...state, accepting: false })
});
