import React, { useState, useEffect } from 'react';
import {useSelector } from "react-redux"
import CanvasJSReact from '../stats/canvasjs.react/canvasjs.react';
import './App.css';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
const RatingsGraph = ()=> {
		const ratingsOptions = {theme: "light2",animationEnabled: true,exportEnabled: false,
			toolTip: {shared: true,reversed: true},
			axisY: [{lineColor:"white",tickColor:"white",labelFontColor:"white"        }],
		dataPointMaxWidth: 60,
			legend: {verticalAlign: "top",horizontalAlign: "left",reversed: true,cursor: "pointer",
			},
			toolTip:{
				enabled: true 
			  },
			data: [
			{
				legendMarkerType: "circle",
				type: "stackedColumn",
				name: "Ratings",
				color: '#FF5A60',
				showInLegend: true,
				yValueFormatString: "#,###",
			},
    ]
		}

		const stats = useSelector(state=> state.user.myStats.data)
		const [ratingsData, setRatingsData ] = useState(ratingsOptions)

			useEffect(()=>{
			let rating=[]
			if (stats && stats.rating){
			let reversedRatings = stats.rating.ratings.reverse()
					reversedRatings.forEach((rtg)=>
					{
						rating.push({y:rtg.reviews, label:rtg.date})
					})

					setRatingsData({...ratingsData, data:[
			{
				legendMarkerType: "circle",type: "stackedColumn",name: "Ratings",color: '#FF5A60',
				showInLegend: true,yValueFormatString: "#,###",
				dataPoints: rating
			},
						
					]})
			}

		},[stats])

		return (
		<div className="profile_stats" id="line-stats">
			<h1 className="stats_title">Rating</h1>
			{/* <select id="stats_live" className="profile_stats_select">
				<option value="seven_days">Last 12 Month</option>
				<option value="month">Last 6 Month</option>
				<option value="six_month">Last Month</option>
				<option value="year">Last 7 Days</option>
			</select> */}
			<CanvasJSChart options = {ratingsData} 
				// onRef={ref => this.chart = ref}
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	
}

export default RatingsGraph;



