import React from 'react'
import {motion} from 'framer-motion'
import styled from 'styled-components'
import RequestMessages from "components/comunes_events/RequestMessages/RequestMessages"
import { RequestDetail } from "./components";
import {RequestDetail as RequestDetailInfluencer} from "../../private-message_influencer/component/RequestDetail"

const variants = {
    open: { x:0},
    closed: { x:'-150%'}
}

 const MobileDetails = ({showMobileDetails,setShowMobileDetails,requestActive, imagesPath,onReject,
    onAccept,rejecting,accepting,isViewer, onAcceptRequest, onRejectRequest}) => {
    return (
        <Details variants={variants} initial='closed' 
        animate={showMobileDetails? 'open' : 'closed'}
        transition={{damping:300} } >

            <div className='d-flex mb-3' style={{borderBottom: '1px solid #D8DCE5 ', width:'100%', alignItems:'center'}}>


            <button style={{color:'red', fontSize:'30px', background:'white', border:'none', fontWeight:'bold'}} onClick={()=>setShowMobileDetails(false)}>←</button>
            
            </div>
            <div style={{padding:'20px'}}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start'}}>
            <h4 style={{fontSize:'20px', marginBottom:'12px'}}>Request Summary</h4>

            <RequestMessages requestActive={requestActive} isViewer={isViewer} />
            <>
            {isViewer ? 
            <RequestDetail
            isMobile
              data={requestActive}
              imagesPath={imagesPath}
              onReject={onRejectRequest}
              onAccept={onAcceptRequest}
              rejecting={rejecting}
              acepting={accepting}
            />
            : 
<RequestDetailInfluencer
         isMobile 
          data={requestActive}
          imagesPath={imagesPath}
          onReject={onRejectRequest}
          onAccept={onAcceptRequest}
          rejecting={rejecting}
          acepting={accepting}
        />}
        </>

            </div>

            </div>

        </Details>
    )
}
export default MobileDetails
const Details = styled(motion.div)`
position:fixed;
z-index: 99;
overflow-y:auto;
top:0;
left:0;
width: 100vw;
height: 100vh;
background:white;
padding-bottom: 40px;

`