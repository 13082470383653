import React, { useState, useEffect } from "react";
import arrow from "../../../assets/img/icons/ic-arrow-left.svg";
import Card from "./card"; 
import EventHappeningCard from '../Cards/EventHappeningCard'
import "./style.scss";
import { EventDAO } from "daos";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import useMobileNav from '../../../hooks/useMobileNav'
import {SlideCards}  from '../../comunes/upcomingLiveEvents/SlideCards'
import i18next from 'i18next';

const eventDAO = new EventDAO();

/**
 * @param {Array} categories
 * @param {Object} dataInjected, Prevents the request from being made by default and sets what this object has in the state.
 */
// TODO: validate that the dataInjected object is valid
const LiveEvents = ({ categories, dataInjected }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const {mobileNav} = useMobileNav()

  const initEvents = async dataInjected => {
    try {
      let response;
      if (!dataInjected) {
        response = await eventDAO.findLivingEvents();
      }
      if (dataInjected && dataInjected.length === 0) {
        response = await eventDAO.findLivingEvents();
      }

      const prevData =
        dataInjected && dataInjected.length>0 ? dataInjected : response.data.data;
      const data = prevData.map(item => {
        item.event.event_category = categories.find(
          category => category.cate_ide === item.event.event_category_ide
        );
        return item;
      });

      setEvents(data);
      setLoading(false);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (categories.length > 0) initEvents(dataInjected);
  }, [categories]);

  return (
    <>
      {events.length > 0 &&
      
      <div style={{width:'100%', paddingTop:'25px'}} className="Home-live">
        <div className="container">
          <div className="row">
          <div style={{display:'flex', alignContent:'end', justifyContent:'space-between'}} className="col-12">
          {events.length > 0 && <h2 style={{padding:'0 16px', marginBottom:!mobileNav? '34px':'0px'}}>{i18next.t("LiveEventsComponentLiveOnlineEvents")}</h2>}


              {(events.length > 0 ) &&  (
                <Link to={`/all_upcoming_live_events`}>
                  <div className="Show-all">
              <span >{i18next.t("LiveEventsComponentShowAll")}</span>
                    <img src={arrow} alt="arrow" />
                  </div>
                </Link>
              )
              
              
              }
            </div>

            {(!loading  && !mobileNav )
              ? events.map((event, index) => (
                  <EventHappeningCard key={index} event={event} />
              ))
              : 
              (mobileNav &&  events &&  events.length>0) &&
                    <SlideCards
                    loading={loading}
                      events={events}
                      happening/>
              
              }
          </div>
        </div>
      </div>
      }
    </>
  );
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  };
};

LiveEvents.propTypes = {
  dataInjected: PropTypes.object
};

export default connect(mapStateToProps)(LiveEvents);
