import React, { Component, useEffect, useState } from 'react';
import billing from "../../../assets/img/icons/ic-billing.svg"
import './style.scss';
import { connect } from "react-redux";

import mapIcon from "../../../assets/img/icons/ic-map.svg";
import userIcon from "../../../assets/img/icons/ic-user.svg";
import correctIcon from "../../../assets/img/icons/correct.png"
import failedIcon from "../../../assets/img/icons/incomplete.png"
import pendingIcon from "../../../assets/img/icons/pending.png"
import {useSelector} from "react-redux"
import { parsePriceByCurrencyJustSymbol } from "../../../utils/currencyUtils";

const TableBilling = ({events}) => {
  return(
    <div>
      {events && events.map(event => {
        const event_date = new Date(parseInt(event.event.event_date)).toGMTString();
        var payment_status = "";
        var payment_icon = "";
        var location = JSON.parse(event.event.event_location);

          if (event.event.payment_state == "cancelled") {
            payment_icon = failedIcon;
            payment_status = "Cancelled";
          } else if (event.event.payment_state == "succeeded") {
            payment_icon = correctIcon;
            payment_status = "Completed";
          } else {
            payment_icon = pendingIcon;
            payment_status = "Processing";
          }

        return (
          <div>
              <div className="border-bottom-grey spacing">
                  <div>{event_date}</div>
                  <div>Event Purchased</div>
                  <div>{event.event.event_title}</div>
                  <div>Payment status: {payment_status} <img src={payment_icon} className="icon-25"/></div>
                  <div className="price">Price: {parsePriceByCurrencyJustSymbol(event.event.event_price_ticket, event.event.currency)}</div>
                  <div><img src={userIcon} alt=""/>Influencer name: {event.influencer.user.user_username}</div>
                  <div><img src={mapIcon} alt=""/>Live from {location.city}</div>
              </div>
          </div>
        )
      })
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default  connect(mapStateToProps)(TableBilling);