import SocialConnectBox from "../components/comunes/social/SocialConnectBox";

export default {
  //Open Graph Header
  GotmyLandingHeaderDescription:
    "Live Video Events Marketplace. Buy and sell access to videos and live online events",
  GotmyLandingHeaderoOGTitle: "gotmy | Live Video Interactive Events Platform",

  //Home Header
  GotmyHomeHeaderTitle: "Live Video Events Marketplace",
  GotmyHomeHeaderDescription:
    "Buy and sell access to videos and live online events",
  GotmyHomeHeaderTrenginsSearches: "Trending searches",
  GotmyHomeHeaderSeachBarPlaceholder:
    "Search influencer name, live event, category, profession, skill...",

  //Steps component
  StepComponentYouAreIn: "You're In",
  StepComponentTalkFaceToFaceWith:
    "Talk face-to-face with anyone online, either  in a private video meeting or live group event",
  StepComponentStepOneTitle: "Get Started",
  StepComponentStepOneDescription:
    "Register your gotmy account & discover incredible live video events",
  StepComponentStepTwoTitle: "Get Access",
  StepComponentStepTwoDescription:
    "Book an influencer, professional and skilled people after previewing their scheduled live group event or requesting a private video meeting, where they control the content, attendance, time, agenda and ticket price",
  StepComponentStepThreeTitle: "Get their attention",
  StepComponentStepThreeDescription:
    "Engage live, directly from the influencer, professional and skilled people in a small venue where your voice will be heard",

  //LiveEvents Component
  LiveEventsComponentLiveOnlineEvents: "Live Online Events",
  LiveEventsComponentShowAll: "Show all",

  //Nav Component
  NavBarComponentBlog: "Blog",

  //Nav Viewer Component
  MenuViewerComponentDiscover: "Discover",
  MenuViewerComponentSearch: "Search",
  MenuViewerComponentLiveEvent: "My events",
  MenuViewerComponentRequests: "Requests",
  MenuViewerComponentActivity: "Activity",

  //MovileNav Component
  MobileNavBarComponentBlog: "Blog",

  //Login Menu Component
  LoginMenuComponentBecomeInfluencer: "Become influencer",
  LoginMenuComponentProcessingInfluencerRequest:
    "Processing influencer request",
  LoginMenuComponentGoToInfluencerMode: "Go to influencer mode",
  LoginMenuComponentViewerMode: "Viewer mode",
  LoginMenuComponentMyViewerProfile: "My Viewer Profile",
  LoginMenuComponentEditProfile: "Edit Profile",
  LoginMenuComponentBilling: "Billing",
  LoginMenuComponentPaymentMethods: "Payment methods",
  LoginMenuComponentSettings: "Settings",
  LoginMenuComponentHelp: "Help",
  LoginMenuComponentLogout: "Log Out",
  LoginMenuComponentLoginAsInfluencer: "Login as Influencer",
  LoginMenuComponentMobileLogin: "Log In",
  LoginMenuComponentMobileSignup: "Sign Up",

  //Upcoming Live Events Component
  UpcomingLiveEventsComponentUpcomingLiveEvents: "Upcoming Live Events",
  UpcomingLiveEventsComponentShowAll: "Show all",
  UpcomingLiveEventsComponentNoEventsInThisCategoryMessage:
    "There are no events in this category yet",

  //Card Upcoming Component
  CardUpcomingComponentOnly: "Only",
  CardUpcomingComponentTicketsFree: "tickets free",
  CardUpcomingComponentLiveFrom: "Live from",
  CardUpcomingComponentLiveAt: "at",

  //Event Card Component
  EventCardComponentStarts: "Starts",
  EventCardComponentTicketsLeft: "Tickets available",
  EventCardComponentSoldOut: "Sold out",
  EventCardComponentLiveAt: "Live at",

  //Event Happening Card Component
  EventHappeningCardComponentStartsIn: "Starts in",
  EventHappeningCardComponentStartsInAFewMinutes: "Starts in few minutes",
  EventHappeningCardComponentHour: "hour",
  EventHappeningCardComponentHours: "hours",

  //Discover By Category Component
  DiscoverByCategoryComponentShowAll: "Show all",

  //VideosSeccion Component
  VideosSeccionComponentPastEvents: "Past Events",
  VideosSeccionComponentShowAll: "Show all",

  //Discover Component
  DiscoverComponentDiscoverByCategory: "Discover by Category",

  //Navbar Search Component
  NavbarSearchComponentTrengindSearches: "Trending searches",

  //Search Input Peq Component
  SearchInputPeqComponentSearchPlaceholder: "Search",

  //Tags Search Component
  TagsSearchComponentTrendingTags: "Trending Tags",

  //Search Component
  SearchComponentTrendingInfluencers: "Trending Influencers",
  SearchComponentCategories: "Categories",

  //Influencer Search Component
  InfluencerSearchComponentShowAll: "Show all",

  //AllUpcomingLiveEvents Component
  AllUpcomingLiveEventsComponentBackToSearch: "Back to Search",

  //Filtro Component
  FiltroComponentSearchPlaceholder: "Search",
  FiltroComponentSearchNoOptionsFound: "No options",

  //SearchByCategory Component
  SearchByCategoryComponentBackToSearch: "Back to Search",
  SearchByCategoryComponentTrendingInfluencers: "Trending Influencers",

  //Footer Component
  FooterComponentGotmyCompName: "Got My Idol,Inc.",
  FooterComponentAllRightsReserved: "All rights reserved",
  FooterComponentTermsOfService: "Terms of Service",
  FooterComponentPrivacyPolicy: "Privacy Policy",
  FooterComponentContact: "Contact",
  FooterComponentInvestors: "Investors",
  FooterComponentBlog: "Blog",
  FooterComponentDownloadAppTitle: "Download gotmy mobile app",
  FooterComponentDownloadAppDescription:
    "Buy and sell access to videos and live online events",

  //Mobile Footer Component
  MobileFooterComponentDownloadAppTitle: "Download gotmy mobile app",
  MobileFooterComponentDescription:
    "Buy and sell access to videos and live online events",
  MobileFooterComponentTermsOfService: "Terms of Service",
  MobileFooterComponentPrivacyPolicy: "Privacy Policy",
  MobileFooterComponentContact: "Contact",
  MobileFooterComponentInvestors: "Investors",
  MobileFooterComponentBlog: "Blogs",
  MobileFooterComponentGotmyCopyright: "Got My Idol,Inc. All rights reserved",

  //Modal Login, Modal Register, Modal Basic and Modal Reset Password Component
  ModalLoginComponentWelcomeTo: "Welcome to",
  ModalLoginComponentEmailAddress: "Email address",
  ModalLoginComponentEmailPlaceholder: "enter email",
  ModalLoginComponentPassword: "Password",
  ModalLoginComponentPasswordPlaceholder: "enter password",
  ModalLoginComponentCotinue: "Continue",
  ModalLoginComponentLoading: "Loading",
  ModalLoginComponentRecoverPassword: "Recover Password",
  ModalLoginComponentOrSignInWith: "Or sign in with",
  ModalLoginComponentAreYouNotRegistered: "Are you not registered?",
  ModalLoginComponentSignUp: "Sign up",
  ModalLoginComponentDownloadOurAppOnThe: "Donwload our App on the",
  ModalLoginComponentAppStore: "App store",
  ModalLoginComponentOr: "or",
  ModalLoginComponentToRegisterAndEnjoyOurLiveStreams:
    "to register and enjoy our live events!",
  ModalLoginComponentRepeatPassword: "Repeat password",
  ModalLoginComponentOrSignUpWith: "Or sign up with",
  ModalLoginComponentAreYouRegistered: "Are you registered?",
  ModalLoginComponentLogIn: "Log in",
  ModalLoginComponentVerifyYourEmailAddress: "Verify Your Email Address",
  ModalLoginComponentVerifyYourEmailAddressDescription:
    "We sent you a verification link. You can now verify your account!",
  ModalLoginComponentLetsCompleteYourProfile: "Let's complete your profile!",

  //Modal Login Logic, Modal Register Logic and Modal Reset Password Component
  ModalLoginLogicComponentPasswordRequiredError: "Password is required",
  ModalLoginLogicComponentPasswordFormatError:
    "The password must have at least 8 characters 1 uppercase 1 lowercase and 1 number",
  ModalLoginLogicComponenEmailFormatErrorMessage:
    "Email must have a valid format",
  ModalLoginLogicComponentEmailRequired: "Email is required",
  ModalLoginLogicComponentSomethingWentWrong: "Sorry, something went wrong.",
  ModalLoginLogicComponentPasswordMustMatchError: "Passwords must match",
  ModalLoginLogicComponentRepeatPasswordRequired: "Repeat password is required",
  ModalLoginLogicComponentPasswordReestablishedSuccessfully:
    "Password reestablished successfully",
  ModalLoginLogicComponentUserNotFound: "User not found",
  ModalLoginLogicComponentIncorrectResetPasswordToken:
    "Incorrect Reset Password Token",
  ModalLoginLogicComponentNameIsRequired: "Name is required",
  ModalLoginLogicComponentSurnameRequired: "Surname is required",
  ModalLoginLogicComponentBirthDateRequired: "Birth date is required",
  ModalLoginLogicComponentUsernameRequired: "Username is required",

  //Form Input tags and placeholders
  FormInputTagName: "Name",
  FormInputTagSurname: "Surname",
  FormInputTagBirthDate: "Birth date",
  FormInputTagUsername: "Username",
  FormInputPlaceholderName: "Name",
  FormInputPlaceholderSurname: "Surname",
  FormInputPlaceholderUsername: "Username",

  //Event Page Component
  EventPageComponentErrorProcessingEventPayment:
    "Error Processing Event Payment",
  EventPageComponentGoBack: "Back",
  EventPageComponentBuyTicketFor: "Buy ticket for",
  EventPageComponentBuyTicketForFree: "Sign up for free",
  EventPageComponentSorryEventSoldOut: "Sorry, this event is sold out",
  EventPageComponentLiveEventDescription: "Live Event Description",
  EventPageComponentLanguage: "Language",
  EventPageComponentRelatedTags: "Related Tags",
  EventPageComponentShareLiveEvent: "Share Live Event",

  //Nombre Cat Fecha Component
  NombreCatFechaComponentLiveAt: "Live at",
  NombreCatFechaComponentPlayedAt: "Played at",

  //Attendees Event Component
  AttendeesEventComponentAttendee: "Attendee",

  //Begin in Component
  BeginInComponentStreaming: "Streaming",
  BeginInComponentBegan: "began",
  BeginInComponentBegins: "begins",
  BeginInComponentStartStreaming: "Start streaming",
  BeginInComponentViewStreaming: "View streaming",

  //Modal Buy Event Component
  ModalBuyEventComponentBuyTicket: "Buy Ticket",
  ModalBuyEventComponentEventPrice: "Event Price",
  ModalBuyEventComponentFree: "Free",
  ModalBuyEventComponentGotMyFees: "gotmy Fees",
  ModalBuyEventComponentPaymentMethod: "Payment method",
  ModalBuyEventComponentEventTicket: "Ticket",

  //Checkout Component
  CheckoutComponentEnterEmailAddress: "Enter your email address",
  CheckoutComponentConfirmEmailAddress: "Confirm your email address",
  CheckoutComponentPromoCode: "Promotion Code",
  CheckoutComponentEnterYourPromoCode: "Enter your promo code",
  CheckoutComponentIAgreeWithThe: "I agree with the",
  CheckoutComponentPrivacyPolicy: "Privacy Policy",
  CheckoutComponentTermsOfService: "Terms of Service",
  CheckoutComponentCardPaymentsAreProcessedByStripeDescription:
    "Card payments are processed by Stripe. gotmy does not store or process any credit card data",
  CheckoutComponentBuyTicketForFree: "Sign up for free",
  CheckoutComponentPay: "Pay",
  CheckoutComponentProcessingYourPayment: "Processing your payment",

  //UseBuyEvent Component
  UseBuyEventComponentErrorProcessingEventPayment:
    "Error Processing Event Payment",
  UseBuyEventComponentAcceptTemsError:
    "You have to accept the Privacy Policy and Terms of Service",
  UseBuyEventComponentPleaseEnterYourEmailAddress:
    "Please enter your email address",
  UseBuyEventComponentEmailsShouldMatch:
    "Your email and confirmation email should match",
  UseBuyEventComponentTitleRequired: "Title is required",
  UseBuyEventComponentCoverRequired: "Cover image is required",
  UseBuyEventComponentDescriptionRequired: "Description is required",
  UseBuyEventComponentCategoryRequired: "Category is required",
  UseBuyEventComponentLanguageRequired: "Event languages is required",
  UseBuyEventComponentDateRequired: "Live Event Date is required",
  UseBuyEventComponentStartHourRequired: "Start time is required",
  UseBuyEventComponentEndHourRequired: "End time is required",
  UseBuyEventComponentInvalidNumberOfTickets: "Invalid Number of tickets",
  UseBuyEventComponentInvalidPrice: "Invalid Price per tickets",
  UseBuyEventComponentNumberOfTicketsRequired: "Number of tickets is required",
  UseBuyEventComponentPriceTicketsRequired: "Price per ticket is required",

  //NavBarLiveEvents Component
  NavBarLiveEventsComponentUpcoming: "Upcoming",
  NavBarLiveEventsComponentPastEvents: "Past Events",
  NavBarLiveEventsComponentFavorites: "Favorites",

  //UpcomingLiveEventsPage Component
  UpcomingLiveEventsPageComponentYouHaveNotPurschasedAnyLiveEventYet:
    "You haven’t purchased any live event yet",
  UpcomingLiveEventsPageComponentUpcomingLiveEvents: "Upcoming Live Events",

  //NoEvents Component
  NoEventsComponentDiscoverNewEvents: "Discover new events",

  //PastLiveEvents Component
  PastLiveEventsComponentPastLiveEvents: "Past Live Events",
  PastLiveEventsComponentThereareNoPastEventsYet:
    "There are no past events yet!",

  //FavoriteLiveEvents Component
  FavoriteLiveEventsComponentFavoriteLiveEvents: "Favorite Live Events",
  FavoriteLiveEventsComponentYouHaveNotAddedMyFavouriteEventYet:
    "You haven’t added any favorite event yet",

  //ModalSuccessEventBought Component
  ModalSuccessEventBoughtComponentThankYouForBooking:
    "Thank you so much for booking my live Event!",
  ModalSuccessEventBoughtComponentYouAreAmazing: "You are amazing!",
  ModalSuccessEventBoughtComponentDownloadAppMessage:
    "Don't forget to download gotmy in App Store or Google Play to attend my live Event!",
  ModalSuccessEventBoughtComponentGoToMyLiveEvents: "Go to my Live Events",
  ModalSuccessEventBoughtComponentDiscoverMoreEvents: "Discover more Events",
  ModalSuccessEventBoughtComponentWeSentConfirmationEmailTo:
    "We sent you a confirmation email to",
  ModalSuccessEventBoughtComponentQuestionsRegardingYourEvent:
    "Questions regarding your event? Contact",

  //ModalRecoverPassword Component
  ModalRecoverPasswordComponentRecoverYourPassoword: "Recover your password",
  ModalRecoverPasswordComponentEmailAddress: "Email address",
  ModalRecoverPasswordComponentEmailAddresPlaceholder: "Email",
  ModalRecoverPasswordComponentResetMyPassword: "Reset my password",
  ModalRecoverPasswordComponentLoading: "Loading",
  ModalRecoverPasswordComponentAreYouRegistered: "Are you not registered?",
  ModalRecoverPasswordComponentSignUp: "Sign up",

  //MyViewerPaymentMethods Component
  MyViewerPaymentMethodsComponentHowDoYouWantToPay: "How do you want to pay?",
  MyViewerPaymentMethodsComponentWeNeedAPaymentMethodToProcessYourPayments:
    "We need a payment method to process your payments",
  MyViewerPaymentMethodsComponentAddCreditCard: "Add credit card",
  MyViewerPaymentMethodsComponentRemoveCreditCard: "Remove credit card",
  MyViewerPaymentMethodsComponentTheresBeenAnError: "There's been an error",
  MyViewerPaymentMethodsComponentCardName: "Name",
  MyViewerPaymentMethodsComponentCardNumber: "Card Number",
  MyViewerPaymentMethodsComponentExpierDate: "Expire Date",
  MyViewerPaymentMethodsComponentCVC: "CVC",
  MyViewerPaymentMethodsComponentSaveChanges: "Save changes",
  MyViewerPaymentMethodsComponentDoYouWantToRemoveThisCard:
    "Do you want to remove this card?",
  MyViewerPaymentMethodsComponentAreYouSureYouWantToRemoveThisCard:
    "Are you sure you want to remove this card from your account?",
  MyViewerPaymentMethodsComponentYouWouldHaveToAddItAgainForFuturePayments:
    "You would have to add it again for future payments.",
  MyViewerPaymentMethodsComponentYesIWantToRemoveIt:
    "Yes, I want to remove it from my profile",

  //Profile Component
  ProfileComponentAboutMe: "About me",
  ProfileComponentLanguages: "Languages",
  ProfileComponentShowAll: "show all",
  ProfileComponentUpcomingLiveEvents: "Upcoming Live Events",
  ProfileComponentYouHaveNotAttendedAnyLiveEventYet:
    "You haven’t attended any live event yet",

  //ProfileInfluencer Component
  ProfileInfluencerComponentDownloadOurAppToRequestPrivate:
    "Download our App to request private meetings!",
  ProfileInfluencerComponentPrivateRequestRequireLocation:
    "To request private meetings, first set your profile location!",
  ProfileInfluencerComponentFollowers: "Followers",
  ProfileInfluencerComponentFollowing: "Following",
  ProfileInfluencerComponentFollow: "Follow",
  ProfileInfluencerComponentSkills: "Skills",
  ProfileInfluencerComponentRequestPrivateMeeting: "Request Private Meeting",
  ProfileInfluencerComponentLanguages: "Languages",
  ProfileInfluencerComponentFollowMeOn: "Follow me on",
  ProfileInfluencerComponentAboutMe: "About me",
  ProfileInfluencerComponentMoreAboutMe: "More about me",
  ProfileInfluencerComponentLocation: "Location",
  ProfileInfluencerComponentTimeZone: "Time zone",
  ProfileInfluencerComponentUpcomingLiveEvents: "Upcoming Live Events",
  ProfileInfluencerComponentPrivateRequestSlot: "Private Request Slots",
  ProfileInfluencerComponentsSubscribeInfluencer: "Subscribe",
  ProfileInfluencerComponentNoContent: "No content",

  //RatingPoints Component
  RatingPointsComponentBasedOn: "Based on",
  RatingPointsComponentReviews: "reviews",

  //RequestPrivate Component
  RequestPrivateComponentPrivateRequestTitle: "Private Request",
  RequestPrivateComponentRequestSent: "Request sent!",
  RequestPrivateComponentErrorSendingRequest:
    "Sorry, there was an error with your request",

  //MenuRequest Component
  MenuRequestComponentRequestPrivateMeeting: "Request Private Meeting",
  MenuRequestComponentSend: "Send",
  MenuRequestComponentCancel: "Cancel",

  //SelectMeetingType Component
  SelectMeetingTypeComponentTitle: "What kind of private event do you want?",

  //Referral Landing page
  ReferralHeader: "GETTING STARTED IS EASY",
  ReferralSubHeader:
    "Monetize your experience and skills creating interactive online events for FREE!",
  ReferralGetStarted: "JOIN",
  ReferralGetStartedSubheading: "Create your profile and your first event.",
  ReferralGetTheirAttention: "SHARE",
  ReferralGetTheirAttentionSubheading:
    "Share them on social media with your followers and friends. Enjoy our technical, sales and marketing support.",
  ReferralGetAccess: "CONNECT AND INTERACT",
  ReferralGetAccessSubheading:
    "Interact live with your audience or people interested in your content and get paid.",
  ReferralCTAfirstLine: "Monetize your talent!",
  ReferralCTASecondLine: "Monetize your talent!",
  ReferralCTA: "BECOME AN INFLUENCER",
  ReferralSignUpFacebook: "Sign up with  Facebook",
  ReferralSignUpInstagram: "Sign up with Instagram",
  ReferralSignUpLinkedin: "Sign up with LinkedIn",
  ReferralSignUpTwitter: "Sign up with Twitter",

  // Influencer Menu
  InfluencerMenuMyEvents: "My Live Events",
  InfluencerMenuMyStats: "My Stats",
  InfluencerMenuPrivateRequests: "Private Requests",
  InfluencerMenuActivity: "Activity",

  // Influencer Dropdown
  InfluencerDropdownMenuInfluencerMode: "Influencer Mode",
  InfluencerDropdownMenuMyProfile: "My Influencer Profile",
  InfluencerDropdownMenuEdit: "Edit Profile",
  InfluencerDropdownMenuMyLiveEvents: "My Live Events",
  InfluencerDropdownMenuMyBalance: "My Balance",
  InfluencerDropdownMenuMyPayoutMethods: "My payout methods",
  InfluencerDropdownMenuBilling: "Billing",
  InfluencerDropdownMenuSettings: "Settings",
  InfluencerDropdownMenuHelp: "Help",
  InfluencerDropdownGoViewer: "Go to Viewer Mode",
  InfluencerDropdownLogout: "Log Out",

  // Influencer Profile
  InfluencerProfileFollowers: "Followers",
  InfluencerProfileFollowing: "Following",
  InfluencerProfileSkills: "Skills",
  InfluencerProfileLanguages: "Languages",
  InfluencerProfileFollowMe: "Follow me on",
  InfluencerProfileAboutMe: "About me",
  InfluencerProfileUpcoming: "Upcoming Live Events",

  // Influencer My Live Events
  InfluencerMyLiveEventsUpcoming: "My Upcoming Live Events",
  InfluencerMyLiveEventsPublish: "Publish Live Event",
  InfluencerMyLiveEventsNoEventYet: "You have no events yet.",
  InfluencerMyLiveEventsPublishNavLoading: "Loading",
  InfluencerMyLiveEventsPublishNavPublish: "Publish",
  InfluencerMyLiveEventsPublishNavCancel: "Cancel",
  InfluencerMyLiveEventsPublishNavPublishLiveEvent: "Publish Live Event",

  // Influencer payout methods
  InfluencerPayoutBindedMethods:
    "These are your binded payment methods to transfer your earnings",
  InfluencerPayoutYourPayoutMethods: "Your payout methods",
  InfluencerPayoutTypeAccount: "Account type",
  InfluencerPayoutChangeAccount: "Change account type",
  InfluencerPayoutLinkAccount: "Link an account",
  InfluencerPayoutLinkPaypal: "Link your paypal account",
  InfluencerPayoutPaypalExplanation:
    "In order to send your earnings we need to know the email binded to your Paypal account. Please ensure this email address is correct.",
  InfluencerPayoutPaypalExtraExpla:
    "Paypal payout allows binding a Paypal account to receive payments",
  InfluencerPayoutContinue: "Continue",
  InfluencerPayoutExpressExplanation:
    "Express (*) method allows binding a USA account or an international debit card to receive payments",
  InfluencerPayoutNeedPaymentMethod:
    "We need a payment method to transfer your earnings.",
  InfluencerPayoutSetupPayoutMethod: "Setup your preferred payout method",

  // Influencer Happening Events
  InfluencerHappeningEvents: "My Events Happening Now",
  InfluencerMyLiveEventsNoEventYet: "You have no live events .",

  // Influencer Publish Event Menu
  InfluencerPublishEventMenuInfo: "Live Event Information",
  InfluencerPublishEventMenuPitch: "Pitch Video",
  InfluencerPublishEventMenuThanks: "Thank you video",
  InfluencerPublishSeeEvent: "Check it out!",
  InfluencerPublishPublishEvent: "Publish",
  InfluencerPublishMenuPublishEvent: "Publish event",
  InfluencerPublishPreviewYourEvent: "Preview of your event",
  InfluencerPublishCancel: "Cancel",
  EventPreviewComponentDescriptionPlaceholder: "<Your event description here>",
  EventPreviewComponentTitlePlaceholder: "<Your title here>",

  //  LiveEventInformation Component
  LiveEventInformationStreamType: "Streaming Type",
  LiveEventInformationLiveEventInformation: "Live event information",
  LiveEventInformationShowImage:
    "This image will be shown as cover of your Live Event",
  LiveEventInformationCoverImage: "Cover Image",
  LiveEventInformationDescription: "Live Event Description",
  LiveEventInformationChooseCategory: "Choose Category",
  LiveEventInformationSelectEventType: "Select event type",
  LiveEventInformationEventTitleCharLeftPart1: "Live Event title, You have ",
  LiveEventInformationEventTitleCharLeftPart2: "characters left",
  LiveEventInformationEventYouHave: "You have ",
  LiveEventInformationEventYouHaveCharsLeft:
    "characters left. You can add external links using http://  ",
  LiveEventInformationChooseLanguages: "Choose event languages ",
  LiveEventInformationRelatedTags: "Choose related tags ",
  LiveEventInformationMaxTags: "Max 5 tags! ",
  LiveEventInformationAddTags: "Add your tags here ",
  LiveEventInformationDateAndTime: "Date and time ",
  LiveEventInformationEventDate: "Live Event Date",
  LiveEventInformationLocation: "Location",
  LiveEventInformationStartTime: "Start time",
  LiveEventInformationEndTime: "End time",
  LiveEventInformationTicketing: "Ticketing",
  LiveEventInformationCurrency: "Currency",
  LiveEventInformationTicketNumber: "Number of tickets",
  LiveEventInformationPublicEvent: "Public Event",
  LiveEventInformationShowInMarketPlace: "Show event in the marketplace",
  LiveEventInformationOpenTicketing: "Open ticketing ",
  LiveEventInformationOpenTicketingExplanation:
    "Sell tickets while your event is live",
  LiveEventInformationPricePerTicket: "Price per ticket",
  LiveEventInformationGrossRevenue: "Gross revenue (sold out)",
  LiveEventInformationTicketingFees: "Ticketing Fees",
  LiveEventInformationTicketingFeesNoFees:
    "There are no fees asociated with this event",
  LiveEventInformationTicketingFeesChoosePrice: "Choose the price",
  LiveEventInformationPromoCode: "Promo Code",
  LiveEventInformationPromoCodeDescription:
    "Influencers can create a Promo Code and select a % discount to assign to that code to share with viewers",
  LiveEventInformationDiscountPerc: "Choose a discount %",
  LiveEventInformationPlaceholderEventTitle: "Your event title",
  LiveEventInformationPlaceholderEventDescription: "Your event description",
  LiveEventInformationPlaceholderPricePerTicket: "Price per ticket ",
  LiveEventInformationPlaceholderEditCropper: "Edit your Event Photo",
  ModalCropComponentUploadPhoto: "Select a file",
  ModalCropComponentSave: "Save",
  LiveEventInformationLoadingImage: "Loading your image",
  LiveEventInformationUseOurThumbnail:
    "Would you rather use one of our thumbnails?",
  LiveEventInformationUseOurThumbnailSelect: "Select thumbnails",
  LiveEventInformationUseOurThumbnailSelectImageThumbnail: "Use this image",

  //  ErrorNotFound Component
  ErrorNotFoundMessage:
    "We couldn't load the page. We're working on it though! In the meantime, please try again :-)",
  ErrorNotFoundTitle: "Content not found",

  // My past live events component
  PastLiveEventsMyPast: "My Past Live Events",
  PastLiveEventsMyPastNoEventsYet: "You have no events yet.",
  // MyPublicProfile Component
  MyPublicProfilePublicProfile: "My Public Profile",
  MyPublicProfileFavCat: "My Favorite Categories",

  // MyPublicProfileInfluencer Component
  MyPublicProfileInfluencerPublicInfo: "Public information",
  MyPublicProfileInfluencerAddCategories: "Add two skill categories",
  MyPublicProfileInfluencerAddCategoriesDescription:
    "To be discovered on gotmy, it's recommended to choose one or two master categories that match your activity",
  MyPublicProfileInfluencerAddCategoriesAdd: "Add category",
  // PublicInfoTab Component
  PublicInfoTabPublicInfo: "Public information",
  PublicInfoTabFirstName: "First name",
  PublicInfoTabLastName: "Last name",
  PublicInfoTabChooseUsername: "Choose your username",
  PublicInfoTabLocation: "Location",
  PublicInfoTabBday: "Birthday (private)",
  PublicInfoTabChooseYourLanguage: "Choose your language",
  PublicInfoTabBiography: "Biography",
  PublicInfoTabCoverVideo: "Cover video",
  PublicInfoTabCurrentCoverVideo: "Current video",
  PublicInfoTabSelectAVideoFile: "Select video",

  //AvailableBoxComponent
  AvailableBoxComponentAreYouAvailable:
    "Are you available for private meetings",
  AvailableBoxComponentAdvice:
    "If you are active, viewers can send you proporsals for Private Live Events with them",
  AvailableBoxComponentAvailable: "I'm avaibable",
  AvailableBoxComponentUnavailable: "I'm unavailable",

  //SocialConnectBox
  SocialConnectBoxConnect: "Connect your social networks",
  SocialConnectBoxConnectDescription:
    "Connect with your social networks in order to display direct links and share your activity",
  SocialConnectBoxConnectActivated: "Activated",
  SocialConnectBoxConnectConnect: "Connect",

  //NavbarStats

  NavbarStatsOverview: "Overview",
  NavbarStatsLive: "Live Event Stats",
  NavbarStatsRatings: "Rating and reviews",

  //StatsCardComp
  StatsCardTotal: "Total Live Events",
  StatsCardTotalSales: "Total Sales",
  StatsCardTotalIncrease: "% INCREASE",
  StatsCardTotalDecrease: "% DECREASE",
  StatsCardTotalChange: "% CHANGE",
  StatsCardTotalTotalBalance: "Total Current Balance",

  //StatsCardComp
  LiveEventStatsAll: "All Live Events",
  LiveEventStatsShowAll: "Show all events",
  LiveEventStatsShowPrivate: "Show private events",
  LiveEventStatsShowPubllic: "Show public events",

  //RatingReviews Comp
  RatingReviewsAll: "All Reviews & Ratings",
  RatingReviewsReviews: "Reviews",

  //PrivateMessageInfluencer Comp
  PrivateMessageInfluencerRequests: "Private Meeting Requests",
  PrivateMessageInfluencerHowItWorks: "How it works",

  //RequestDetail Comp
  RequestDetailDetails: "Private Request Detail",
  RequestDetailDetailsDescription: "The Request Details will go here!",
  RequestDetailAccepted: "Accepted",
  RequestDetailRejected: "Rejected",
  RequestDetailPendingConfirmation: "Pending Confirmation",
  RequestDetailModified: "Modified",
  RequestDetailCancelledByViewer: "Cancelled by the viewer",
  RequestDetailConfirmed: "Request Confirmed",
  RequestDetailYouCanEdit: "You can edit the request to",
  RequestDetailYouCanEdit2: "propose new terms",
  RequestDetailYouCanEditRequest: "Edit request",
  RequestDetailDetailsRequestDetails: "Request Details",
  RequestDetailDetailsAttendees: "Attendees",
  RequestDetailDetailsPricePerTicket: "Price per ticket",
  RequestDetailDetailsDateAndTime: "Date and time",
  RequestDetailDetailsSpecificDateAndHour:
    "{{- specificDate}} from {{request_start_hour}} to {{request_end_hour}}",
  RequestDetailDetailsSpecificAnyTimeAndHour:
    "Any time from {{request_start_hour}} to {{request_end_hour}}",
  RequestDetailDetailsUndefinedDateFrom:
    "Undefined date, from {{request_start_hour}} to {{request_end_hour}}",
  RequestDetailDetailsDateAnyTime: "{{- date}} at any time",
  RequestDetailDetailsDateAndTimeNotDefined:
    "Date and time are not defined yet",
  RequestDetailDetailsReject: "Reject",
  RequestDetailDetailsLoading: "Loading...",
  RequestDetailDetailsAcceptProposal: "Accept proposal",
  RequestDetailDetailsAccepting: "Acepting...",
  RequestDetailDetailsCheckTheEvent: "Check the event",

  // Activities component
  ActivitiesShowAll: "Show all notifications",
  ActivitiesNoActivities: "You have no notifications yet",

  //HowItWorks Modal
  HowItWorksTitle: "How it works",
  HowItWorksSubtitle: "Private Meetings",
  HowItWorksStep1: "1. Send your proposal to the influencer",
  HowItWorksStep2:
    "2. Wait for influencer's response accepting, rejecting or proposing new terms",
  HowItWorksStep3: "3. Accept or reject these new terms",
  HowItWorksStep4:
    "4. Once you both agree in the meeting terms, you'll receive a link to buy your ticket and share this private meeting with your friends",
  HowItWorksStep5:
    "5. . All tickets must have been purchased 24 hours in advance, or the influencer shall have the right to cancel the private meeting",

  //PrivateMessageInfluencerLogic
  PrivateMessageInfluencerLogicSentYou: "sent you an event request",
  PrivateMessageInfluencerLogicYouHaveModified:
    "You have modified the request for event made by ",
  PrivateMessageInfluencerLogicYouHaveRejected:
    "You have rejected the request for event made by ",
  PrivateMessageInfluencerLogicYouHave: "You have",
  PrivateMessageInfluencerLogicYouHaveTheRequest:
    "the request for event made by ",
  PrivateMessageInfluencerLogicYouHaveAccepted:
    "You have accepted the request for event made by ",
  PrivateMessageInfluencerLogicHasAccepted: "has accepted your modifications ",
  PrivateMessageInfluencerLogicTheRequestMade: "the request for event made by ",
  PrivateMessageInfluencerLogicUserHasCancelled:
    "has cancelled their private event request ",
  PrivateMessageInfluencerLogicYouHaveModified:
    "You have modified the request for event made by ",
  PrivateMessageInfluencerLastUpdate: "Last update:",
  PrivateMessageViewerLastUpdate: "Last update:",

  //Private meeting reasons
  PrivateMeetingJustForFun: "Just for fun",
  PrivateMeetingJustForFunDescription: "Have a good time with you",
  PrivateMeetingJustForFunOptionName1: "Party Appearance",
  PrivateMeetingJustForFunOptionName2: "Playing games",
  PrivateMeetingJustForFunOptionName3: "Hangout",
  PrivateMeetingJustForFunOptionName4: "Other reason",
  PrivateMeetingJustForFunWhatReason: "What reason?",
  PrivateMeetingConsultantRequest: "Consultant Request",

  // Instagram sign up modal Component
  InstagramSignUpModalComponentEmailPlaceholder: "Enter your email",
  InstagramSignUpModalComponentEmailAddress: "Email",
  InstagramSignUpModalComponentConfirmEmailAddress: "Confirm your email",
  InstagramSignUpModalComponentContinue: "Continue",
  InstagramSignUpModalComponentLoading: "Loading",
  InstagramSignUpModalComponentWelcomeTo: "Welcome to",
  InstagramSignUpModalComponentLastStepDescription:
    "We need an email address to bind with your Instagram account.",
  InstagramSignUpModalComponentEmailNotMatch: "Emails do not match",

  // ModalEventInfo component
  ModalEventInfoTitle:
    "We offer two kinds of event: Interactive and Broadcast (huge audiences) ",
  ModalEventInfoInteractive: "Interactive",
  ModalEventInfoInteractiveExplain:
    "Event where you can interact with the participants in real time. Limited to groups of up to 49 viewers",
  ModalEventInfoMassive: "Broadcast",
  ModalEventInfoMassiveExp1:
    "Great for events with big audiences (more than 50 people)",
  ModalEventInfoMassiveExp2:
    "Does not allow direct video interaction with the audience ",
  ModalEventInfoMassiveExp3: "External software is required",
  ModalEventInfoContactUs:
    "If you want to enable this feature, please contact our support team on WhatsApp (+17864537535) or email (support@gotmy.com).",

  //MainApp.js
  CookiesWarningText:
    "uses cookies and other data to deliver, maintain and improve our services.\nIf you continue to use our services, we will assume that you agree to the use of such cookies.",

  //Streaming Options Component
  StreamingOptionsComponentPreview: "Preview",
  StreamingOptionsComponentPreviewLoading: "Loading...",
  StreamingOptionsComponentPreviewCurrentCamera: "Camera",
  StreamingOptionsComponentPreviewCurrentInputDevice: "Current Input device",
  StreamingOptionsComponentPreviewUnknown: "Unknown",
  StreamingOptionsComponentPreviewChangeInputDevice: "Change",
  StreamingOptionsComponentPreviewNewInputDevice: "New device",
  StreamingOptionsComponentPreviewNoAudioDevicesFound: "No audio devices found",
  StreamingOptionsComponentPreviewCurrentOutputDevice: "Current Output device",
  StreamingOptionsComponentPreviewChangeOutPutDevice: "Change Output Device",
  StreamingOptionsComponentPreviewNewOutputDevice: "New Output device",
  StreamingOptionsComponentPreviewNoVoiceDevicesFound: "No voice devices found",
  StreamingOptionsComponentPreviewStreamingSettings: "Streaming settings",
  StreamingOptionsComponentPreviewSave: "Save",
  StreamingOptionsComponentPreviewClose: "Close",
  StreamingOptionsComponentPreviewOutputAudio: "Output Audio",
  StreamingOptionsComponentPreviewSelectMicrophone: "Select Microphone",
  StreamingOptionsComponentPreviewSelectAudioOutput: "Select Audio Output",
  StreamingOptionsComponentPreviewSelectCamera: "Select Camera",
  StreamingOptionsComponentPreviewInputAudio: "Input Audio",
  StreamingOptionsComponentPreviewVideo: "Video",

  //OVVideo Component
  OVVideoComponentSharingYourScreen: "Sharing your screen",
  OVVideoComponentLoadingStreaming: "Loading streaming...",
  OVVideoComponentItSeemsTheInfluencerIsNotReadyYet:
    "It seems the influencer is not ready yet.",
  OVVideoComponentLoadingVideo: "Loading...",

  //UserVideo Component
  UserVideoComponentSharingScreen: "Sharing your screen",
  UserVideoComponentIsSpeaking: "is speaking",

  //PreviewStreaming Component
  PreviewStreamingInfluencerComponentDoYouWantToFinishTheEvent:
    "Do you want to finish the event?",
  PreviewStreamingInfluencerComponentDoYouWantToFinishTheEventBody:
    "All the viewers will disconnect and the event will be finished.",
  PreviewStreamingInfluencerComponentYes: "Yes",
  PreviewStreamingInfluencerComponentDisconnectNotFinishingEvent:
    "Disconnect not finishing event",
  PreviewStreamingInfluencerComponentNo: "No",
  PreviewStreamingInfluencerComponentDoYouWantToGoOutFromTheEvent:
    "Do you want to go out from the event?",
  PreviewStreamingInfluencerComponentDoYouWantToGoOutFromTheEventBody:
    "You will be able to join anytime you want meanwhile the event is still live.\nDo you want to exit?",
  PreviewStreamingInfluencerComponentRetryingIn: "Retrying in",
  PreviewStreamingInfluencerComponentSeconds: "seconds",
  PreviewStreamingInfluencerComponentConnectionProblemsMessage:
    "It seems you are having problems with your connection",
  PreviewStreamingInfluencerComponentDisconnect: "Disconnect",
  PreviewStreamingInfluencerComponentInternetConnectionLost:
    "Internet connection lost.",
  PreviewStreamingInfluencerComponentInternetConnectionLostBody:
    "Check if you have troubles with your internet access.",
  PreviewStreamingInfluencerComponentInternetConnectionLostBodyReconnect:
    "will reconnect automatically when your internet access will recover.",
  PreviewStreamingInfluencerComponentThanksForAttending:
    "Thanks for attending my live event!",
  PreviewStreamingInfluencerComponentWriteAReview: "Write a review",
  PreviewStreamingInfluencerComponentReviewPlaceholder:
    "Comment us how much you liked the event with a few words",
  PreviewStreamingInfluencerComponentSendFeedback: "Send feedback",
  PreviewStreamingInfluencerComponentSkip: "Skip",
  PreviewStreamingInfluencerComponentChatModeration: "Chat Moderation",
  PreviewStreamingInfluencerComponentChooseViewerAsModerator:
    "Choose viewer for moderator",
  PreviewStreamingInfluencerComponentRaiseHandQueue: "Raise Hand queue",
  PreviewStreamingInfluencerComponentLiveChat: "Live Chat",
  PreviewStreamingInfluencerComponentChatTemporarilyDisabled:
    "Chat temporarily disabled",
  PreviewStreamingSendMessage: "Send a message",
  PreviewStreamingInfluencerComponentChooseRaiseHand: "Choose Raise Hand",
  PreviewStreamingInfluencerComponentNoUsersWaiting: "No users waiting",
  PreviewStreamingInfluencerComponentSend: "Send",
  PreviewStreamingInfluencerComponentViewers: "Viewers",
  PreviewStreamingInfluencerComponentViewersConnected: "Viewers connected",
  PreviewStreamingInfluencerComponentYou: "You",
  PreviewStreamingInfluencerComponentClose: "Close",
  PreviewStreamingInfluencerComponentTooltipDisableMicrophone:
    "Disable microphone",
  PreviewStreamingInfluencerComponentTooltipEnableMicrophone:
    "Enable microphone",
  PreviewStreamingInfluencerComponentTooltipDisableCamera: "Disable camera",
  PreviewStreamingInfluencerComponentTooltipEnableCamera: "Enable camera",
  PreviewStreamingInfluencerComponentTooltipDisableRaiseHand:
    "Disable raise hand mode. This mode allow viewers to join the streaming with their cameras",
  PreviewStreamingInfluencerComponentTooltipEnableRaiseHand:
    "Enable raise hand mode\nRaise hand mode allows viewers to join the streaming with their cameras",
  PreviewStreamingInfluencerComponentTooltipDisableRaiseHandStopViewersTurn:
    "Stop viewer's turn",
  PreviewStreamingInfluencerComponentTooltipDisableOpenMic:
    "Disable Open Mic. This mode allow viewers to open their microphones",
  PreviewStreamingInfluencerComponentTooltipEnableOpenMic:
    "Enable Open Mic. This mode allow viewers to open their microphones",
  PreviewStreamingInfluencerComponentTooltipDisableChat: "Disable chat",
  PreviewStreamingInfluencerComponentTooltipEnableChat: "Enable chat",
  PreviewStreamingInfluencerComponentTooltipShowOnlineViewersPanel:
    "Show online viewers panel",
  PreviewStreamingInfluencerComponentTooltipRaiseUpTheHand:
    "Raise up the hand asking for a turn to interact.",
  PreviewStreamingInfluencerComponentTooltipRaiseDownTheHand:
    "Raise down the hand cancelling your turn to speak.",
  PreviewStreamingInfluencerComponentTooltipOpenModeIsEnabled:
    "Open mode is enabled, unmute your microphone to talk with everybody!",
  PreviewStreamingInfluencerComponentWeAreSorryButWeCanNotStartStreaming:
    "We are sorry but we can not start the streaming",
  PreviewStreamingInfluencerComponentWeAreSorryButWeCanNotStartStreamingBody:
    "Check you have signed in and you have bought a valid ticket",
  PreviewStreamingInfluencerComponentWelcomeMessage:
    "Welcome to your Live Event",
  PreviewStreamingInfluencerComponentInstructions:
    "Check all the features before starting!",
  PreviewStreamingInfluencerComponentStartStreaming: "Start Streaming",
  PreviewStreamingInfluencerComponentStartingStreaming: "Starting streaming...",
  PreviewStreamingInfluencerComponentOpenMode: "Open Mode",
  PreviewStreamingInfluencerComponentOpenModeDescription:
    "Allow all your viewers to speak",
  PreviewStreamingInfluencerComponentRaiseHand: "Raise Hand",
  PreviewStreamingInfluencerComponentRaiseHandDescription:
    "Allow your viewers to ask for a turn to speak",
  PreviewStreamingInfluencerComponentStopTurn: "Stop viewer turn",
  PreviewStreamingInfluencerComponentStopTurnDescription:
    "Finish your viewer's turn",
  PreviewStreamingInfluencerComponentCameraOnOff: "Camera On/Off",
  PreviewStreamingInfluencerComponentCameraOnOffDescription:
    "Enable or disable your camera",
  PreviewStreamingInfluencerComponentMicrophoneOnOff: "Microphone On/Off",
  PreviewStreamingInfluencerComponentMicrophoneOnOffDescription:
    "Enable or disable your microphone",
  PreviewStreamingInfluencerComponentWelcomeTo: "Welcome to",
  PreviewStreamingInfluencerComponentInstructionsViewer:
    "Check all the features before starting!",
  PreviewStreamingInfluencerComponentJoinStreaming: "Join Streaming",
  PreviewStreamingInfluencerComponentJoiningStreaming: "Joining streaming...",
  PreviewStreamingInfluencerComponentOpenModeDescriptionViewer:
    "Allow to speak with all",
  PreviewStreamingInfluencerComponentRaiseHanddDescriptionViewer:
    "Ask for a turn to speak enabling your camera",
  PreviewStreamingInfluencerComponentLoading: "Loading...",
  PreviewStreamingInfluencerComponentTooltipOpenSettings: "Open settings panel",

  //PreviewStreaming Component (logic)
  PreviewStreamingInfluencerComponentBrowserNotSupportSwitchMediaDeviceDuringStreaming:
    "This browser does not support changes in your media devices during the streaming.\nIf you want to select a different decive reload the site and set it up before joining the session.",
  PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia:
    "It seems gotmy has no access to your camera and microphone.\ngotmy needs to access your camera and microphone before joining the streaming.\nChange the permissions granted to gotmy website and reload your browser.",
  PreviewStreamingInfluencerComponentRateEvent: "Please rate the event",
  PreviewStreamingInfluencerComponentThereWasAnErrorSendingReview:
    "There was an error sending your review",
  PreviewStreamingInfluencerComponentVideoStreamingNotSupportedBrowser:
    "Video streaming not supported in your browser, use Chrome, Firefox or Safari.",
  PreviewStreamingInfluencerComponentMediaDevicesNotDetected:
    "Webcam and microphone not detected.\nPlease install a webcam device and a microphone and reload the website or ensure your devices are not in use by other application.\n If error persist try reopening your browser or restarting your computer.\n Contact gotmy suppor for better assistance.",
  PreviewStreamingInfluencerComponentProblemFinishingEvent:
    "There was a problem finishing the event. \nPlease try again in few seconds.",
  PreviewStreamingInfluencerComponentProblemFinishingEventLeaveStandard:
    "There was a problem finishing the event. \nPlease try again in few seconds.",
  PreviewStreamingInfluencerComponentShareScreenNotSupported:
    "Share screen not supported for this browser.",
  PreviewStreamingInfluencerComponentChromeOutdated:
    "Your Google Chrome is outdated.\nUpdate Google Chrome (minimum version 72) to start sharing your screen.",
  PreviewStreamingInfluencerComponentFirefoxOutdated:
    "Your Firefox is outdated.\nUpdate Firefox (minimum version 65) to start sharing your screen.",
  PreviewStreamingInfluencerComponentOperaOutdated:
    "Your Opera is outdated.\nUpdate Opera (minimum version 72) to start sharing your screen.",
  PreviewStreamingInfluencerComponentSafariOutdated:
    "Your Safari is outdated.\nUpdate Safari (minimum version 13.0) to start sharing your screen.",
  PreviewStreamingInfluencerComponentShareScreenNotSupportedUseOther:
    "Share screen not supported for this browser.\nPlease use Chrome, Firefox, Opera or Safari.",
  PreviewStreamingInfluencerComponentGotmyHasNoAccessToMediaEnsure:
    "gotmy can not access your camera and microphone.\nPlease ensure you have granted the camera and microphone access permission for this website.",
  PreviewStreamingInfluencerComponentProblemConnectingSession:
    "There was a problem connecting to the session.",
  PreviewStreamingInfluencerComponentProblemConnectingSessionCheckDeviceNotInUse:
    "There was a problem connecting to the session.\nBe sure you have an stable internet connection, there is no other app using the same camera/microphone running on the background and that your camera/microphone works properly.",
  PreviewStreamingInfluencerComponentThereWasAProblemConnectingToSessionGotmyError:
    "There was a problem connecting to the session.\nError code:",
  PreviewStreamingInfluencerComponentThereWasAProblemConnectingToSessionGotmyErrorIfErrorPersist:
    "\nIf error persist contact with gotmy support.",
  PreviewStreamingInfluencerComponentErrorConnecctingContactSupport:
    "There was a problem connecting to the session. If error persist contact with gotmy support.",
  PreviewStreamingInfluencerComponentErrorConnectingReload:
    "There was a problem connecting to the session. Reload the website and try later. If error persist contact with gotmy support.",
  PreviewStreamingInfluencerComponentGiveTurn: "Give turn",
  StreamingOptionsComponentSelectMicrophone: "Select a microphone",
  StreamingOptionsComponentErrorLoadingYourMicrophone:
    "Error loading your microphone. Check your device is working properly and try again",
  StreamingOptionsComponentErrorLoadingYourCamera:
    "Error loading your camera. Check your device is working properly and try again.",
  StreamingOptionsComponentVideo: "Video",
  StreamingOptionsComponentMicrophone: "Microphone",
  StreamingOptionsComponentSpeakers: "Speakers",
  StreamingOptionsComponentSelectACamera: "Select a camera",
  StreamingOptionsComponentNoDevicesFound: "No video devices found",
  MicrophoneUnpluggedWarning:
    "It's seems your microphone has been unplugged. Please change a new one in settings.",
  CameraUnpluggedWarning:
    "It's seems your camera has been unplugged. Please change a new one in settings.",

  //Attendees Moderator Component
  AttendeesModeratorComponentRaiseHandModeDisabled: "Raise Hand mode disabled",
  AttendeesModeratorComponentViewersWaiting: "Viewers waiting...",

  //MenuStreamingPre Component
  MenuStreamingPreComponentLeaveLiveEvent: "Leave Live Event",

  //FechaCat Component
  FechaCatComponentLiveFrom: "Live from",

  //FavoriteIcon Component
  FavoriteIconComponentAddToFavorites: "Add to favorites",
  FavoriteIconComponentRemoveFromFavorites: "Remove from favorites",

  //AttendeesInfluencer Component
  AttendeesInfluencerComponentOnline: "Online",
  AttendeesInfluencerComponentChoose: "Choose",

  //HLSStream Component
  HLSStreamComponentLoadingStream: "Loading...",
  HLSStreamComponentLoadingStreamDescription:
    "The streaming will be ready soon",

  //StreamingHLS Component
  StreamingHLSComponentInstructionsHeader: "External software setup",
  StreamingHLSComponentReminderInstructionsDescription:
    'Remember that you must configure your external software (OBS or similar) with the following parameters for the event to be streamed correctly. In OBS go to settings, stream and then in the service drop-down select "Custom..." and paste the following values:',
  StreamingHLSComponentReminderInstructionsDescriptionExtended:
    "You can reopen this information window by clicking on the floating help button at the bottom right. If you need further assistance, contact us by email at support@gotmy.com or by WhatsApp at +178 64 5375 35.",
  StreamingHLSComponentReminderInstructionsClose: "Close",
  StreamingHLSComponentReminderInstructionsServer: "Server",
  StreamingHLSComponentReminderInstructionsStreamName: "Stream key",
  HLSBroadcastComponentWatchingNow: "Watching now",

  //SelectMeetingDetails Component
  SelectMeetingDetailsComponentWhenWouldULikeToHaveTheMeeting:
    "When would you like to have the meeting?",
  SelectMeetingDetailsComponentIDoNotCare:
    "I do not care about the date and time",
  SelectMeetingDetailsComponentIDLikeTaSpecificDate:
    "I'd like  a specific date and time",
  SelectMeetingDetailsComponentPleaseSelectOneOption:
    "Please, select one option",
  SelectMeetingDetailsComponentSelectDate: "Select Date",
  SelectMeetingDetailsComponentFieldRequired: "This field is required",
  SelectMeetingDetailsComponentStartTime: "Start time",
  SelectMeetingDetailsComponentEndtime: "End time",
  SelectMeetingDetailsComponentCurrency: "Currency",
  SelectMeetingDetailsComponentPurposeOfTheMeeting: "Purpose of the meeting",
  SelectMeetingDetailsComponentExplainYourPurpose:
    "Explain your purpose for meeting",
  SelectMeetingDetailsComponentCharactersLeft:
    "You have  {{char}} characters left",
  SelectMeetingDetailsComponentYouCanAddExternalLinks:
    "You can add external links using http://",
  SelectMeetingDetailsComponentPricePerTicket: "Price per ticket",

  //UsePrivateMessageViewerLogic Component
  UsePrivateMessageViewerLogicComponentYourEventRequestHasBeenSentTo:
    "Your event request has been sent to {{username}}",
  UsePrivateMessageViewerLogicComponentEventRequestModifiedBy:
    "Your event request has been modified by {{username}}",
  UsePrivateMessageViewerLogicComponentHasBeenAcceptedBy:
    "Your event request has been accepted by {{username}}",
  UsePrivateMessageViewerLogicComponentHasBeenRejectedBy:
    "{{username}} has rejected your private event request",
  UsePrivateMessageViewerLogicComponentHasModifiedYourPrivateRequest:
    "{{username}} has modified your private event request",
  UsePrivateMessageViewerLogicComponentHasAcceptedTheModifiedRequest:
    "You have accepted the modified request for your private event!",
  UsePrivateMessageViewerLogicComponentInfluencerHasCustomState:
    "{{username}} has {{custom}} your request for event",
  UsePrivateMessageViewerLogicComponentYouHaveCancelled:
    "You have cancelled the private event request",
  UsePrivateMessageViewerLogicComponentSorryError: "Sorry, there was an error",
  UsePrivateMessageViewerLogicComponentGreatEventCreated:
    "Great! The event was created. You can now check the event and buy your ticket",
  UsePrivateMessageViewerLogicComponentPrivateRequestRejected:
    "Private request successfully rejected",

  //PrivateMessageViewer Component
  PrivateMessageViewerHowItWorks: "How it works",
  PrivateMessageViewerPrivateRequest: "Private Requests",

  //Request Messages Component
  RequestMessagesComponentPendingTicketPurchase: "Pending ticket purchase",
  RequestMessagesComponentPendingTicketPurchaseDescription:
    "The event will not take place until the viewer purchases the ticket",
  RequestMessagesComponentNoPrivateRequestYet: "You have no private requests.",
  RequestMessagesComponentFindYourInfluencer: "Find your influencer",

  //NavBar_liveEvents Component
  NavBarLiveEventsComponentUpcomingLiveEvents: "Upcoming Live Events",
  NavBarLiveEventsComponentUpcomingLiveEventsMobile: "Upcoming Events",
  NavBarLiveEventsComponentPastEvents: "Past Live Events",
  NavBarLiveEventsComponentPastEventsMobile: "Past Events",

  //Activity Component
  ActivityComponentBookedYourEvent:
    "{{ - username}} booked your event {{- eventTitle}}",
  ActivityComponentStartedFollowingYou: "{{- username}} started following you",
  ActivityComponentYouHaveTicketsFor: "You have tickets for",
  ActivityComponentMadeAPrivateRequest:
    "{{- name}} {{- surname}} made you a private request",

  //EventCardInfluencer Component
  EventCardInfluencerComponentStartsIn: "Starts {{- startingText}}",
  EventCardInfluencerComponentLiveAt: "Live at {{- date}}",
  EventCardInfluencerComponentMaxXPeople: "Max. {{- maxNumber}} people",
  EventCardInfluencerComponentTicketsSold: "Tickets sold",
  EventCardInfluencerComponentViewers: "Viewers",

  //SubscribeInfluencer Component
  SubscribeInfluencerComponentLoadingRedirectText: "Loading...",

  //Suscription success Component
  SuscriptionSuccessComponentTitle: "Subscribed successfully",
  SuscriptionSuccessComponentDescription:
    "If you have any question please contact our support team on WhatsApp (+17864537535) or email (support@gotmy.com).",

  //PrivateInfo Component
  PrivateInfoComponentPricePerTicket: "Price per ticket",
  PrivateInfoComponentPrivateInformation: "Private Information",
  PrivateInfoComponentNumberOfTickets: "Number of tickets",
  PrivateInfoComponentGrossRevenue: "Gross Revenue (sold out)",
  PrivateInfoComponentTicketingFees: "Ticketing Fees",
  PrivateInfoComponentDiscountCode: "Discount Code",
  PrivateInfoComponentReschedulingPolicy: "Rescheduling policy",
  PrivateInfoComponentReschedulingPolicyDescription:
    "Once per event, to a later date/time and up to 24 hours before the event any viewer can ask refund anytime and your score shall be affected",
  PrivateInfoComponentCancellationPolicy: "Cancellation policy",
  PrivateInfoComponentCancellationPolicyDescription:
    "Up to 24 hours before the event, viewers will get full refund and your score shall be affected.",
  PrivateInfoComponentInfluencerDelay:
    "Influencer's delay longer than 10 minutes or no-show",
  PrivateInfoComponentInfluencerDelayDescription:
    "Viewer will get full refund and your score shall be heavily affected",
  PrivateInfoComponentCancelLiveEvent: "Cancel live event",

  //ShareEvent Component
  ShareEventComponentInfluencerMessage:
    "Check out the event i'm hosting. Get your ticket! {{- title}}",
  ShareEventComponentViewerMessage:
    "Check out the event I'm going to attend {{- title}}!",
  ShareEventComponentOrCopyTheLinkHere: "Or copy the link here",
  ShareEventComponentCopied: "Link copied to the clipboard",

  //useModalRecoverLogic
  useModalRecoverLogicLinkSent: "A recovery link has been sent to: {{- email}}",
  useModalRecoverLogicLinkSentSorryError: "Sorry, an error occurred.",

   //EXTRA
   days: "days",
   and: "and",

  PreviewStreamingInfluencerComponentProblemConnectingAccesingToMediaDevices:
      "There was a problem accessing your camera / microphone. Check that your device is not in use by another program and that it is working properly. You will not be able to interact in the event with your camera and microphone, although you will be able to see the event.",
  HLSBroadcastComponentTicketInUse: "Ticket in use",
  HLSBroadcastComponentTicketInUseDescription: "We have detected that your ticket is being used from another device. \nTickets can only be used on one device at a time.\nIf you think it is an error, try refreshing the page and if the problem persists contact our support team at support@gotmy.com or WhatsApp +178 64 5375 35.",
  HLSBroadcastComponentSocketIoError: "There is a connection problem with your browser. Try using other browser or contact with support.",
};
