import * as actionTypes from "./actionTypes";
import { CategoryDAO } from "daos";

const categoryDAO = new CategoryDAO();

export const setCategories = categories => {
  return {
    type: actionTypes.SET_CATEGORIES,
    categories
  };
};

export const getCategories = () => {
  return async dispatch => {
    try {
      const response = await categoryDAO.findAll();
      let allCategories = response.data.data
      const categories= allCategories.filter(category => category.cate_active===true)     
      dispatch(setCategories(categories));
    } catch (err) {
      throw err;
    }
  };
};
