import { useState, useEffect } from "react";
import * as yup from "yup";
import { UserDAO, EventDAO } from "daos";
import { LINKEDIN_CLIENTID, LOGIN_BACK_URL, FACEBOOK_CLIENTID, INFLUENCER_ONLY_MODE, Api, SECOND_ACCESS_TOKEN_API, INFLUENCER_ROLE } from "../../../environment/environment";
import { useSelector, useDispatch } from 'react-redux'
import * as actions from "../../../store/actions";
import i18next from 'i18next';
import queryString from 'query-string'
import axios from "axios"
import { parseLocale } from 'utils/Localization'

const eventDAO = new EventDAO();



const initialFormValues = {
  email: "",
  confirmEmail: ""
};
const schema = yup.object().shape({
  email: yup
    .string()
    .email(i18next.t("ModalLoginLogicComponenEmailFormatErrorMessage"))
    .required(i18next.t("ModalLoginLogicComponentEmailRequired")),
  confirmEmail: yup
    .string()
    .email(i18next.t("ModalLoginLogicComponenEmailFormatErrorMessage"))
    .oneOf([yup.ref('email'), null], i18next.t("InstagramSignUpModalComponentEmailNotMatch") || "Emails do not match")
    .required(i18next.t("ModalLoginLogicComponentEmailRequired"))
});


const userDAO = new UserDAO();

/**
 * Logic of ModalLogin
 * @param {Object} history, React Router Object
 * @returns {Object}
 */
export const useModalLoginLogic = (history, props) => {
  const dispatch = useDispatch()
  const isInfluencer = useSelector(state => state.user.isInfluencer)
  const [formValues, setFormValues] = useState(initialFormValues),
    [errorForm, setErrorForm] = useState(""),
    [loading, setLoading] = useState(false),
    [showAlertGoodCredentials, setShowAlertGoodCredentials] = useState(false);

  useEffect(() => {
    window.$("#instagramLoginModal").modal("show");
  }, [])
  /**
   * Handle form changes.
   * @param {Object} event, Event trigerred for the input form
   */
  const onChangeFormValues = event => {
    const updatedValues = JSON.parse(JSON.stringify(formValues));
    const { name, value } = event.target;
    updatedValues[name] = value;
    setFormValues(updatedValues);

    console.log("onChangeFormValues", updatedValues);
  };

  const autoInfluencer = async (token) => {
    try {
      const { data } = await axios.post(
        `${Api}influencer/auto_upgrade`,
        {},
        { headers: { "Access-Token": token, "Content-Type": "application/json", "Content-Language": parseLocale()
      } }
      );
      localStorage.setItem("autoUpgrade", JSON.stringify({ status: true }))
      console.log("autoInfluencer respo", { data })
    }
    catch (e) {
      console.log("autoinfluencer error", e)
    }
  }

  const handleLogin = async (res) => {
    const values = queryString.parse(props.location.search)
    const code = values.code
    const redirect_url = LOGIN_BACK_URL + "instagramSignUp";
    const action = localStorage.getItem("instagramAction");
    const redirectUrlVerify = localStorage.getItem("redirectUrl");
    const path = window.location.pathname
    var referral = JSON.parse(localStorage.getItem("referral"))
    var landingTalentToken = JSON.parse(localStorage.getItem("LandingTalent"))
    const referral_code = referral ? referral.id : null

    let { data } = res;
    const env = {
      token: data.data.user_app_token
    };

    localStorage.setItem("env", JSON.stringify(env));
    
    console.log("Response login insta", res, autoInf, referral_code)
    if (referral_code || landingTalentToken === true) {
      await autoInfluencer(env.token)
      data.data.user_role = INFLUENCER_ROLE

      localStorage.removeItem("LandingTalent");
    }

    let autoInf = JSON.parse(localStorage.getItem("autoUpgrade"))
    props.props.setUser(data.data)
    props.props.getInfluencer(env.token)


    localStorage.setItem("env", JSON.stringify(env));

    window.$("#instagramLoginModal").modal("hide");
    if (autoInf && autoInf.status === true) {
      console.log("AUTOINF")
      // redirect
      localStorage.removeItem("referral")
      props.history.push("/publish_event")
    }

    else if (data.data.user_role === INFLUENCER_ROLE) {
      props.history.push('/my_live_events')
    }
    else if ((!data.data.user_name || !data.data.user_lastname)) {
      props.history.replace("viewer_profile");
    }
    else {
      props.history.push(`/`)
    }
  }

  const verifyInstagram = async (email) => {
    const values = queryString.parse(props.location.search)
    const code = values.code
    const redirect_url = LOGIN_BACK_URL + "instagramSignUp";
    const action = localStorage.getItem("instagramAction");
    const redirectUrlVerify = localStorage.getItem("redirectUrl");
    const path = window.location.pathname
    var referral = JSON.parse(localStorage.getItem("referral"))
    const referral_code = referral ? referral.id : null

    console.log("cosas", { path, redirect_url, action, code, redirectUrlVerify, referral_code, props })

    //return;

    if (code) {
      console.log("calling login_instagram")
      axios.post(`${Api}auth/login_instagram`, { code: code, email: email, redirect_url: redirect_url, ...(referral_code) && { referral_code } }, {
        headers: { "Access-Token": SECOND_ACCESS_TOKEN_API, "Content-Type": "application/json" , "Content-Language": parseLocale()
      }
      }).then(res => {
        handleLogin(res);
      })
        .catch(err => {
          console.log("----------------------")
          console.log({ err })
          console.log("----------------------")


          const prevURL = localStorage.getItem("instagramRedirectPrevURL");

          setErrorForm("There was a problem connecting with Instagram.") // USAR PARA ERROR 
          window.$("#instagramLoginModal").modal("hide");
          props.history.push(prevURL);


          //props.history.push("/");
          // document.location.reload();
        })

        .finally(() => {

          setLoading(false);
        })

    }

  }

  const onSubmit = async event => {
    if (loading) return;
    event.preventDefault();

    try {
      await schema.validate(formValues);
    } catch (err) {
      setErrorForm(err.errors[0]);
      return;
    }


    setLoading(true);

    verifyInstagram(formValues.email);

  };

  return {
    loading,
    onSubmit,
    errorForm,
    formValues,
    setErrorForm,
    onChangeFormValues,
    showAlertGoodCredentials,
    setShowAlertGoodCredentials
  };
};
