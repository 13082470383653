import React, { useState } from 'react'
import hamburger from "assets/img/icons/hamburger.svg";
import "../style.scss";
import logo from "assets/img/logo-white.png";
import Menu from '../menu'
import styled from 'styled-components'
import LoginMenu from "../../login_menu";
import { Link } from "react-router-dom";
import i18next from 'i18next';
import styles from '../../../../assets/css_general/general.scss'

import { IS_SIGN_UP_ENABLED, PRIVATE_MARKETPLACE } from '../../../../environment/environment'

const MobileNav = ({referral}) => {
    const [mobileMenu, setMobileMenu] = useState(false)

    return (

        <NavBar>
            <div className="navItems ">
                <div className="d-flex">
                    <a target="_blank" href="https://blog.gotmy.com/">
                        <img className="mr-3 " src={logo} className="Logo" alt="logo" />
                    </a>
                    <a style={{ color: "#FF5A60", alignSelf: 'flex-end', marginTop: '14px' }} className="align-bottom" target="_blank" href="https://blog.gotmy.com/" className="">
                        <h5 style={{ fontSize: '17px' }}>{i18next.t("MobileNavBarComponentBlog")}</h5>

                    </a>
                </div>
               {!PRIVATE_MARKETPLACE && ( <Stylez>

                    {IS_SIGN_UP_ENABLED && <LoginMenu mobileNav referral={referral} />}
                    <img onClick={() => setMobileMenu(!mobileMenu)} className="menuBtn" src={hamburger} />
                </Stylez>)}
            </div>

            {!PRIVATE_MARKETPLACE && mobileMenu &&
                <div className="menuItems">

                    <Menu mobileNav  />
                </div>
            }
        </NavBar>


    )
}


export default MobileNav

const NavBar = styled.div`
color : white;
    padding: 10px 20px !important;
    width: 100%;
    background-color : ${styles.mainBackground};
    .navItems {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .Logo {
        width: 75px !important;
    height: 25px !important;

    }
    .menuItems{
        margin-top: 24px;
        display: flex;
        flex-direction: column;
    }

`
const Stylez = styled.div` 
display:flex;
align-items:center;
  .menuBtn { 
    cursor: pointer;
    margin-left: 10px;

  }

`