export const getFormattedTime = (date) => {
    const timeComponents = [date.getHours(), date.getMinutes()];
    return timeComponents
      .map(component => {
        const pad = (component < 10) ? '0' : '';
        return pad + component;
      })
      .join(':');
  }
 export  const getCroppedImg = (image, crop, fileName)=> {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage( image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX,   crop.height * scaleY,
                0, 0, crop.width, crop.height);
    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg', 1);
    });
  }
