import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Search_input from "../../comunes/search_input";
import "./style.scss";
import NavBar_general from "../../comunes/navbar";
import { EventDAO } from "daos";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import useMobileNav from '../../../hooks/useMobileNav'
import i18next from 'i18next';

const VideoBackground = require("../../../assets/home/GotMyPresentation.mp4");

const eventDAO = new EventDAO();

const HomeHeader = (props) => {
  const { mobileNav } = useMobileNav()
  const [tags, setTags] = useState([])

  const env = JSON.parse(localStorage.getItem("env") || "{}");
  const handlePlayVideo = () => {
    const video = document.getElementById("the-video");
    if (video) {
      video.play();
    }
  };

  const handleTags = async () => {
    const { data } = await eventDAO.getTrendingTags();
    setTags(data.data);

  }
  useEffect(() => {
    handlePlayVideo()
    handleTags()
  },[])
 
    return (
      <div>
        {
          (true ) ? (
            <header className="App-header">
              <NavBar_general />
            </header>
          ) : 
           null
        }


      <section style={{ padding: '0px' }} className={(Object.keys(props.user).length === 0 && !props.loading && !mobileNav) ? "homeVideoHeader" : "Home-header"}>
        {(Object.keys(props.user).length === 0 && !props.loading && !mobileNav) &&
          <video id="the-video" muted loop className="fullscreen-bg__video">
            <source src={VideoBackground} type="video/mp4" />
          </video>
        }
        <div className="Contenido">
          <h1>{i18next.t('GotmyHomeHeaderTitle')}</h1>
          <h2>{i18next.t('GotmyHomeHeaderDescription')}</h2>
          <Search_input />
          {!mobileNav && <p className="mt-3">
            {i18next.t('GotmyHomeHeaderTrenginsSearches')}:
              {' '}
            {tags.slice(0, 3).map((trend, i) => {
              return (
                <>
                  <Link to={`/search/${trend}`} >
                    <span style={{ color: 'white' }}>{trend}</span>
                  </Link>
                  {i < 2 && ', '}
                </>
              )
            })}
          </p>}
        </div>
      </section>
    </div >
  );

}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    loading: state.user.loading

  };
};

export default connect(mapStateToProps)(HomeHeader);
