import React from 'react';
// import React, {Component,useState} from 'react';

import arrow from "../../../assets/img/icons/ic-arrow-right.svg";
import {Link} from "react-router-dom";

function Back(props) {

    return (
        <div className="col-12 Mb">
            <Link to={`${props.url}`} >

            <div style={{marginLeft:'-20px'}} className="Back">
                <img src={arrow} alt="arrow"/>

                <span style={{fontSize:'18px', fontWeight:'500 !important'}}>{props.text}</span>
            </div>
            </Link>
        </div>
    );
}

export default Back;