import { logger } from "../utils/Console";
import Config from "./configSelector";

//DEV
export const IS_DEV_ENVIRONMENT = false;
export const FORCE_LOCALIZATION = true;
const LOGS_ENABLED = false;
const CONSOLE_LOGS = false;
const CONSOLE_ERROR = false;
const CONSOLE_WARNINGS = false;

export const STRIPE_DEV_KEY = IS_DEV_ENVIRONMENT ? true : false;
export const IS_SIGN_UP_ENABLED = true;
export const RESTRICTED_VIEWS = true;
export const DESKTOP_ONLY_MODE = false;
export const INFLUENCER_ONLY_MODE = false;
export const IS_INFLUENCER_STREAMING_ENABLED = true;
export const IS_DACAST_STREAMING_ENABLED = true;
export const IS_VIEWER_STREAMING_ENABLED = true;
export const HIDE_MOBILE_LOGIN = false;
export const BROADCAST_EVENTS_ENABLED = true;
export const DEBUG_LOGS = true && LOGS_ENABLED;
// export const DEBUG_LOGS = IS_DEV_ENVIRONMENT && LOGS_ENABLED;

export const hideNotReadyContent = false;
export const disabledViewerView = true;

export const Api = IS_DEV_ENVIRONMENT ? Config.DEV_Api_URL : Config.Api_URL;
export const HOST = IS_DEV_ENVIRONMENT ? Config.DEV_HOST_URL : Config.HOST_URL;
export const LOGIN_BACK_URL = IS_DEV_ENVIRONMENT
  ? Config.DEV_LOGIN_BACK_URL
  : Config.LOGIN_BACK_URL;
export const STRIPE_CLIENT_ID = IS_DEV_ENVIRONMENT
  ? "ca_F7O5MhekB6pvqWeajJiVwwCl9OPh5rWS"
  : "ca_F7O5iLAv6pest766cZhmHAKQXAaBILCY";
export const FRESHCHAT_ID = "245d1a09-dd97-43e5-99ea-0deb3bacc955";
export const FRESHCHAT_ID_B2B = "b83081e8-6189-427d-a4f1-16722e614352";
export const HOME_PAGE = IS_DEV_ENVIRONMENT
  ? Config.DEV_HOME_PAGE_URL
  : Config.HOME_PAGE_URL;
export const HOME_PAGE_PAYPAL_REDIRECT = IS_DEV_ENVIRONMENT
  ? Config.DEV_HOME_PAGE_PAYPAL_REDIRECT_URL
  : Config.HOME_PAGE_PAYPAL_REDIRECT_URL;
export const SECURE_LOGIN_BACK_URL = IS_DEV_ENVIRONMENT
  ? Config.DEV_SECURE_LOGIN_BACK_URL
  : Config.SECURE_LOGIN_BACK_URL;
export const CANONICAL_HOME_PAGE = IS_DEV_ENVIRONMENT
  ? Config.DEV_HOME_PAGE_URL
  : Config.HOME_PAGE_URL;
export const INFLUENCER_ROLE = 1;
export const VIEWER_ROLE = 0;
export const ACCESS_TOKEN_API = "39711613939D23844C2F700FD4A23B31C4684EB1";
export const SECOND_ACCESS_TOKEN_API =
  "39711613939D23844C2F700FD4A23B31C4684EB1";
export const STREAMING_SPACE = "1b671a64-40d5-491e-99b0-da01ff1f3341";
export const LINKEDIN_CLIENTID = "86yfkgp5pzqs7c";
export const FACEBOOK_CLIENTID = "368470790680147";
export const INSTAGRAM_CLIENTID = "697427734128241";
export const STRIPE_PUBLIC_KEY = STRIPE_DEV_KEY
  ? "pk_test_sbZ1LoDK09jqHS6QnHl1MrGb"
  : "pk_live_jV1UJeHsXPP45NY7IW7l6vgR";
export const googlePlayGotmyURL = Config.googlePlayGotmyURL;
export const appleStoreGotmyURL = Config.appleStoreGotmyURL;
export const appleClientId = "gotmy.com.app.apple.signin.callback";
export const gotmyAppScheme = "";
export const disabledStartStreaming = false;
//B2B true
//export const newB2BInfluencerLanding = true;
export const newB2BInfluencerLanding = Config.newB2BInfluencerLanding;
export const alternativePublishPage = Config.alternativePublishPage;
//B2B false
//export const isGotmyApp = false;
export const isGotmyApp = Config.isGotmyApp;
//B2B true
//export const NEW_PUBLISH_PAGE = true;
export const NEW_PUBLISH_PAGE = Config.NEW_PUBLISH_PAGE;
//B2B true
//export const B2B_PORT = true;
export const B2B_PORT = Config.B2B_PORT;
export const hideCategories = Config.hideCategories;
export const B2B_EVENT_UUID = Config.EVENT_UUID;
export const BRAND_LINKEDIN_URL = Config.BRAND_LINKEDIN_URL;
export const BRAND_FACEBOOK_URL = Config.BRAND_FACEBOOK_URL;
export const BRAND_INSTAGRAM_URL = Config.BRAND_INSTAGRAM_URL;
export const BRAND_TWITTER_URL = Config.BRAND_TWITTER_URL;
export const BRAND_YOUTUBE_URL = Config.BRAND_YOUTUBE_URL;
export const BRAND_WEBSITE_URL = Config.BRAND_WEBSITE_URL;
export const BRAND_PINTEREST_URL = Config.BRAND_PINTEREST_URL;
export const BRAND_BLOG_URL = Config.BRAND_BLOG_URL;
export const BRAND_NAME = Config.BRAND_NAME;
export const PRIVATE_MARKETPLACE = Config.PRIVATE_MARKETPLACE;
export const SUPPORT_EMAIL = Config.SUPPORT_EMAIL;
export const INFLUENCER_ICON_ENABLED = Config.INFLUENCER_ICON_ENABLED;
export const SHARE_ICONS_FLAT_BLACK = Config.SHARE_ICONS_FLAT_BLACK;
export const SHOW_DEFAULT_ICONS = Config.SHOW_DEFAULT_ICONS;
export const LOGIN_MAIN_PAGE = Config.LOGIN_MAIN_PAGE;
export const REFERRAL_HOME_PAGE = Config.REFERRAL_HOME_PAGE;
export const ENABLE_PURCHASE = Config.ENABLE_PURCHASE;
export const DISABLE_FOOTER_HOME = Config.DISABLE_FOOTER_HOME;

/*
IS_DEV_ENVIRONMENT && CONSOLE_LOGS ? logger.enableLogger() : logger.disableLogger();
IS_DEV_ENVIRONMENT && CONSOLE_ERROR ? logger.enableErrorLogger() : logger.disableErrorLogger();
IS_DEV_ENVIRONMENT && CONSOLE_WARNINGS ? logger.enableWarningLogger() : logger.disableWarningLogger();
 */
