import React, { Component } from "react";
import "./styles.scss";

export default class OpenViduAudioComponent extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();

    this.state = {
      volumeLevel: this.props.volumeLevel || 0.5,
      previousStreamManager: null


    }
  }

  componentDidUpdate(props) {
    try{
      if (!this.state.previousStreamManager && this.props.streamManager || this.state.previousStreamManager.participant.identity != this.props.streamManager.participant.identity) {
        this.setState({
          previousStreamManager: this.props.streamManager
        }, () => {
  
          if (this.props.streamManager.track) {
            console.log("Estas es el participante", this.props.streamManager)
            var appendedChild = this.videoRef.current.appendChild(this.props.streamManager.track.attach());
            appendedChild.id = "Track-" + this.props.streamManager.track.sid;
          }
  
          
        if (this.props.volumeLevel != this.state.volumeLevel) {
          this.setVolume(this.props.volumeLevel || this.state.volumeLevel);
        }
  
        })
  
      }
    }
    catch(error){
      console.error(error);
    }

  }

  setVolume(value){
    if (this.props.streamManager && this.videoRef && this.props.setVolume) {
      const element = document.getElementById('audio-Remote');

      if (element) {
        console.log("Se setea el volumen openModeAudio", value, "is remote", this.props.setVolume, "videoElement", element.volume, element.audioTracks);
       element.volume = parseFloat(value);
       
      }

    }
  }

  componentDidMount() {

    console.log("OV AUDIO DID MOUNT", this.state, this.props);

    try{
      if (!this.state.previousStreamManager && this.props.streamManager || this.state.previousStreamManager.participant.identity != this.props.streamManager.participant.identity) {
        this.setState({
          previousStreamManager: this.props.streamManager
        }, () => {
  
          if (this.props.streamManager.track) {
            console.log("Estas es el participante", this.props.streamManager)
            var appendedChild = this.videoRef.current.appendChild(this.props.streamManager.track.attach());
            appendedChild.id = "Track-" + this.props.streamManager.track.sid;
          }
  
  
          
        if (this.props.volumeLevel != this.state.volumeLevel) {
          this.setVolume(this.props.volumeLevel || this.state.volumeLevel);
        }
  

        })
  
      }
    }
    catch(error){
      console.error(error);
    }


  }

  render() {
    return (
      <div
        style={{
          display: 'none'
        }}
      >
        <div
          id={"audio-Remote"}

          style={{
            width: 10,
            height: 10
          }}
          autoPlay={true} ref={this.videoRef} >

        </div>
      </div>
    );
  }
}