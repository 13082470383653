import React from 'react'
import closeIcon from "assets/img/icons/close.svg";
import {Typeahead} from 'react-bootstrap-typeahead'; // ES2015

const ChooseLanguage = (props)=> {
    const { languages,languagesSelected, isInfluencer, extraStyles } = props    
    const propsTypeAhead = {clearButton: true, labelKey: "lang_description", multiple: true,
        id: "typeahead-id",options: languages, defaultSelected: languagesSelected,placeholder: "Language",
        disabled: false,
        onChange: selected => {
          props.onUpdateLanguage(selected)
        },
        renderMenuItemChildren: (option, props) => (
          <div >
            <div>
              <img className="img_language" src={option.lang_image} />
              {option.lang_description}
            </div>
          </div>
        )
      };

      const CustomTagLanguage = ({ option, onRemove }) => {
        return (
          <div style={{display: "flex", flexDirection: "row", marginRight: "5%" }}>
            <img className="img_language" src={option.lang_image} alt="" />
            <p style={{ marginLeft: "15%", fontWeight: "600", fontSize: 16, color: "black", marginBottom:'0.5rem', marginTop:'0.5rem' }} >
              {option.lang_description}
            </p>
            {isInfluencer && (
              <div style={{ padding: "5px", cursor: "pointer" }} onClick={onRemove}>
                <img src={closeIcon} alt="" width="20" height="20" />
              </div>
            )}
          </div>
        );
      };
      propsTypeAhead.renderToken = (option, props) => (
        <div
          style={{
            width: "135px",
            maxHeight:'50px',
            padding: "5px",
            border: "solid 1px #D8DCE5",
            borderRadius: "35px",
            marginRight:"10px",
            display:'flex'
          }}
        >
          <CustomTagLanguage onRemove={props.onRemove} option={option} />
        </div>
      );
    
    return (
      
      <Typeahead  {...propsTypeAhead} />


   
     
    )
}

export default ChooseLanguage