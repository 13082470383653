import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../stats/canvasjs.react/canvasjs.react';
import './App.css';
import {useSelector } from "react-redux"

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 		var viewsInitial = {  theme: "light2", legend: { verticalAlign: "top", horizontalAlign: 'left'},	  
				animationEnabled: true,	
				toolTip: {
					shared: true,
					content: "{name}: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {y}",
					backgroundColor: "#2F2D3A",
					fontColor: "white",
					borderThickness: 0,
					cornerRadius: 5 
				}, 
				data: [{legendMarkerType: "circle", markerBorderColor : "white", markerBorderThickness: 2, color: '#FF5A60',
					axisYType: "secondary", type: "spline", name: "Followers", showInLegend: true,
					dataPoints: [{ y: 25, label: "6/12" }, { y: 22, label: "7/12" }, { y: 22, label: "8/12" }]
				},
				{color: '#B5B9C2', axisYType: "secondary", markerBorderColor : "white", markerBorderThickness: 2,
					type: "spline", name: "Viewers", showInLegend: true, legendMarkerType: "circle",
					dataPoints: [
						{ y: 22, label: "6/12" },{ y: 23, label: "7/12" },{ y: 25, label: "8/12" },]
				}]
		}

const LineChart  = ()=> {	
	    const stats = useSelector(state=> state.user.myStats.data)
		const [viewsData, setViewsData ] = useState(viewsInitial)
		const [dateOption, setDateOption] = useState("day") // day, week or month
		useEffect (()=>{
			let viewerDataPoints = []
			let followerDataPoints=[]
			let anonymousDataPoints= []
			 console.log ({stats})
	 		 if ( stats && stats.profile_views) {
				  let reversedViews = stats.profile_views.daily_views.reverse()
				  if (dateOption === "day"){
					reversedViews.forEach((view)=>
					{
						viewerDataPoints.push({y:view.viewer, label:view.date})
						followerDataPoints.push({y:view.follower, label:view.date})
						anonymousDataPoints.push({y:view.anonymous, label:view.date})

					})
				  }
					else if (dateOption==="month"){

					reversedViews.forEach((view)=>
					{
						viewerDataPoints.push({y:view.viewer, label:view.date})
						followerDataPoints.push({y:view.follower, label:view.date})
						anonymousDataPoints.push({y:view.anonymous, label:view.date})
					})
					}
					else if (dateOption==="week"){

stats.profile_views.weekly_views.forEach((view)=>
					{
						viewerDataPoints.push({y:view.viewer, label:view.date})
						followerDataPoints.push({y:view.follower, label:view.date})
						anonymousDataPoints.push({y:view.anonymous, label:view.date})

					})

					}
			  }
			  setViewsData({...viewsData, data:[ {legendMarkerType: "circle", markerBorderColor : "white", markerBorderThickness: 2, color: '#FF5A60',
					axisYType: "secondary", type: "spline", name: "Followers", showInLegend: true,
					dataPoints: followerDataPoints
				},
				{color: '#B5B9C2', axisYType: "secondary", markerBorderColor : "white", markerBorderThickness: 2,
					type: "spline", name: "Viewers", showInLegend: true, legendMarkerType: "circle",
					dataPoints: viewerDataPoints},
					{color: 'black', axisYType: "secondary", markerBorderColor : "black", markerBorderThickness: 2,
					type: "spline", name: "Anonymous", showInLegend: true, legendMarkerType: "circle",
					dataPoints: anonymousDataPoints}
					]
					
					 },
					)

					console.log({followerDataPoints, viewerDataPoints, anonymousDataPoints})
		}, [stats, dateOption])
		
		return (
		<div className="profile_stats" id="line_chart">
			<h2>Profile views</h2>
			<select className="profile_stats_select" value={dateOption} onChange={(e)=>setDateOption(e.target.value)}>
				<option value="day">Daily</option>
				<option value="week">Week</option>
				<option value="month">Month</option>
				{/* <option value="year">Last Year</option> */}
			</select>
			{/* <div className="users_stats"><span>500</span><p>Monthly users</p>
			<span>140</span><p>Weekly users</p>
			<span>22</span><p>Daily Users</p>
			</div> */}
			{viewsData && 
			
			<CanvasJSChart options = {viewsData} 
				/* onRef={ref => this.chart = ref} */
			/>
			}
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	
}
 
export default LineChart      