import React from 'react'
import i18next from 'i18next';
import { countCharacters, avoidLetters, verifyRegex } from "../../../../utils";
import { NEW_PUBLISH_PAGE } from "../../../../environment/environment"

const SetTicketDetails = ({eventValues,  selectedTicket, event_num_attendees, onHandleTicketNumber,
    onChangePrice, currency }) => {
    return (
        <div className={`row ${NEW_PUBLISH_PAGE && "mt-4"}`}>
            <div className="col-6">
                <p className="mb-1 small_grey">{i18next.t('LiveEventInformationTicketNumber')}</p>
                <input placeholder={`Max ${selectedTicket.max_attendees}`} className="input_people" type="text" name="event_num_attendees"
                    value={event_num_attendees} onChange={onHandleTicketNumber} style={{ textAlign: "center" }} onKeyPress={avoidLetters} />
            </div>
            <div  className="col-6">
                <div>
                    <p className="mb-1 small_grey">   {i18next.t('LiveEventInformationPlaceholderPricePerTicket')} {currency && currency.currency_symbol ? "(" + currency.currency_symbol + ")" : ""}   </p>
                    <input disabled={ NEW_PUBLISH_PAGE? false :  selectedTicket.max_ticket_price === 0}
                        className="input_people mb-4" name="event_price_ticket" value={eventValues.event_price_ticket}
                        onChange={onChangePrice} type="text" style={{ textAlign: "center" }}
                        onKeyPress={(e) => verifyRegex(e, /[\d*\.?\d+$]/)}
                    />
                </div>
            </div>
        </div>

    )
}

export default SetTicketDetails