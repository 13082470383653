import React, { Component } from "react";
import {
  Modal,
  Button,
  Nav,
  Tabs,
  Tab,
  DropdownButton,
  Dropdown,
  Badge,
  Form,
} from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import Video from "twilio-video";
import i18next from "i18next";
import Webcam from "react-webcam";
import AudioAnalyser from "./AudioAnalyser";
import { isMobile } from "react-device-detect";
//import VolumeMeter from "react-volume-meter";
import CameraIcon from "assets/img/icons/video-camera.svg";
import MicrophoneIcon from "assets/img/icons/microphone.svg";
import WarningError from "assets/img/icons/errorWarning.svg";
import SpeakersIcon from "assets/img/icons/volume-dark.svg";
var DetectRTC = require("detectrtc");

export default class StreamingOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onDeviceChangeCurrentList: [],
      onDeviceChangeActive: false,
      newVideoDevice: null,
      newAudioDevice: null,
      newOutputAudioDevice: null,
      isPreviewEnabled: false,
      previewTrack: null,
      audioInput: null,
      errorSwitchingCamera: false,
      errorSwitchingMicrophone: false,
      audioAnalyzerInitialized: false,
      currentAudioDevice: this.props.currentAudioDevice,
      videoConstraints: null,
    };

    this.toggleMicrophone = this.toggleMicrophone.bind(this);
  }

  componentDidMount() {
    const currentAudioDevice = this.props.currentAudioDevice;

    if (currentAudioDevice) {
      //this.selectAudioDevice(currentAudioDevice)
    }
  }

  componentWillUnmount() {
    
  }

  componentDidUpdate() {
    const stateCurrentAudioDevice = this.state.currentAudioDevice;
    const propsCurrentAudioDevice = this.props.currentAudioDevice;
    const availableDevices = this.props.availableDevices;
    const onDeviceChangeActive = this.state.onDeviceChangeActive;
    const onDeviceChangeCurrentList = this.state.onDeviceChangeCurrentList;

    if (
      availableDevices &&
      availableDevices.length !== onDeviceChangeCurrentList.lengt &&
      onDeviceChangeActive
    ) {
      this.setState({
        onDeviceChangeActive: false
      },this.updateCurrentDevices.bind(this))
    }

    if (
      (stateCurrentAudioDevice !== propsCurrentAudioDevice &&
        !this.state.audioAnalyzerInitialized) ||
      (stateCurrentAudioDevice &&
        propsCurrentAudioDevice &&
        stateCurrentAudioDevice.deviceId !== propsCurrentAudioDevice.deviceId &&
        !this.state.audioInput)
    ) {
      this.setState(
        {
          audioAnalyzerInitialized: true,
        },
        () => {
          this.selectAudioDevice(propsCurrentAudioDevice);
        }
      ); //this.selectAudioDevice(propsCurrentAudioDevice)
    }

    if (
      this.state.isPreviewEnabled &&
      !this.props.selectStreamingDevices.showSelectionModal &&
      this.state.previewTrack
    ) {
      //this.stopPreviewTrack();
    }
  }

  filterAndSelectAudioDevice() {}

  
  updateCurrentDevices() {
    const availableDevices = this.props.availableDevices;
    const currentAudioDevice = this.props.currentAudioDevice;
    const currentVideoDevice = this.props.currentVideoDevice;
    const onDeviceChangeActive = this.state.onDeviceChangeActive;
    const onDeviceChangeCurrentList = this.state.onDeviceChangeCurrentList;

    const isCurrentAudioDeviceInTheList = availableDevices.filter((item) => item.kind == "audioinput").find(audioDevice => audioDevice.deviceId === currentAudioDevice.deviceId);

    if(!isCurrentAudioDeviceInTheList){
      const availableAudioDevicesLenght = availableDevices.filter((item) => item.kind == "audioinput").lenght;

      this.props.composeAlertToast("It's seems your microphone has been unplugged. Please change a new one in settings.","error")

      if(availableAudioDevicesLenght > 0){
        //Select new "default" audio input TODO
      }

    }

    const isCurrentVideoDeviceInTheList = availableDevices.filter((item) => item.kind == "videoinput").find(videoDevice => videoDevice.deviceId === currentVideoDevice.deviceId);

    if(!isCurrentVideoDeviceInTheList){
      const availableVideoDevicesLenght = availableDevices.filter((item) => item.kind == "videoinput").lenght;
      this.props.composeAlertToast("It's seems your camera has been unplugged. Please change a new one in settings.","error")

      if(availableVideoDevicesLenght > 0){
        //Select new "default" video input TODO
      }
    }

  }

  

  createAudioLevelPreview() {
    return null;
  }

  createVideoPreview() {
    return null;
    if (this.state.isPreviewEnabled) {
      return null;
    }

    if (!this.props.selectStreamingDevices.showSelectionModal) {
      return null;
    }

    var videoDevice = null;

    try {
      videoDevice = this.parseSelectedDevices().videoDevice;

      if (!videoDevice) {
        videoDevice = this.props.currentVideoDevice;
      }
    } catch (error) {
      videoDevice = this.props.currentVideoDevice;
      console.error(error);
    }

    try {
      const videoTrackOptions = {
        name: videoDevice.deviceId + Date.now(),
        deviceId: videoDevice.deviceId,
        //width: 1280,
        //height: 720,
        //frameRate: 24,
      };

      this.setState({
        isPreviewEnabled: true,
        previewTrack: null,
      });

      Video.createLocalVideoTrack(videoTrackOptions)
        .then((track) => {
          this.setState(
            {
              isPreviewEnabled: true,
              previewTrack: track,
            },
            () => {
              const localMediaContainer = document.getElementById(
                "InputOptionsVideoPreview"
              );
              try {
                localMediaContainer.innerHTML = "";
              } catch (error) {
                console.error(error);
              }

              if (localMediaContainer) {
                localMediaContainer.appendChild(track.attach());
              }
            }
          );
        })
        .catch((error) => {
          console.error("createVideoPreview createLocalVideoTrack", error);
        });
    } catch (error) {
      console.error("createVideoPreview", error);
    }
  }

  parseSelectedDevices() {
    try {
      var videoDevice = null;
      var audioDevice = null;
      var outputAudioDevice = null;

      //We should specify the default devices for the first session
      if (!this.props.selectStreamingDevices.selectedDefault) {
        videoDevice = this.props.currentVideoDevice;
        audioDevice = this.props.currentAudioDevice;
        outputAudioDevice = this.props.currentOutputAudioDevice;
      }

      //Set newAudioDevice selected and update it if it is different than the current in use
      if (
        this.state.newAudioDevice &&
        (!this.props.currentAudioDevice ||
          this.state.newAudioDevice.deviceId !=
            this.props.currentAudioDevice.deviceId)
      ) {
        audioDevice = this.state.newAudioDevice;
      }

      //Set newOutputAudioDevice selected and update it if it is different than the current in use
      if (
        this.state.newOutputAudioDevice &&
        (!this.props.currentOutputAudioDevice ||
          this.state.newOutputAudioDevice.deviceId !=
            this.props.currentOutputAudioDevice.deviceId)
      ) {
        outputAudioDevice = this.state.newOutputAudioDevice;
      }

      //Set newVideoDevice selected and update it if it is different than the current in use
      if (
        this.state.newVideoDevice &&
        (!this.props.currentVideoDevice ||
          this.state.newVideoDevice.deviceId !=
            this.props.currentVideoDevice.deviceId)
      ) {
        videoDevice = this.state.newVideoDevice;
      }

      return { videoDevice, audioDevice, outputAudioDevice };
    } catch (error) {
      return null;
    }
  }

  setOptionModalVisibility(shouldBeOpen) {
    this.props.toggleStreamingOptionsModal(shouldBeOpen);
  }

  async getMediaDevices() {
    if (this.props.getMediaDevices) {
      var devices = await this.props.getMediaDevices();
    }
  }

  stopPreviewTrack(callback = null) {
    try {
      this.state.previewTrack.stop();

      this.setState(
        {
          isPreviewEnabled: false,
          previewTrack: null,
        },
        () => {
          if (callback) {
            callback();
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  stopCurrentPreviewAndCreateNew(device) {
    /*
    this.stopPreviewTrack(() => {
      this.setState({
        newVideoDevice: device,
      });
    });
    */

    this.setState({
      newVideoDevice: device,
    });
  }

  async selectVideoDevice(device, callback = null) {
    const videoTrackOptions = {
      //name: videoDevice.deviceId + Date.now(),
      deviceId: { exact: device.deviceId },
      //width: 1280,
      //height: 720,
      //frameRate: 24,
    };

    try {
      await navigator.mediaDevices.getUserMedia({ video: videoTrackOptions });

      this.setState(
        {
          errorSwitchingCamera: false,
          newVideoDevice: device,
          videoConstraints: videoTrackOptions,
        },
        callback
      );
    } catch (error) {
      this.setState({
        errorSwitchingCamera: true,
      });
      console.error(error);
    }
  }

  stopMicrophone() {
    this.state.audio.getTracks().forEach((track) => track.stop());
    this.setState({ audio: null });
  }

  toggleMicrophone() {
    if (this.state.audio) {
      this.stopMicrophone();
    } else {
      this.getMicrophone();
    }
  }

  async selectAudioDevice(device, callback = null, callbackError = null) {
    try {
      var audioTracks = this.props.getLocalAudioTracks();

      audioTracks.forEach((arrayItem) => {
        try {
          arrayItem.track.stop();
        } catch (error) {
          console.error(error);
        }
      });

      this.props.removeLocalAudioTrack();
    } catch (error) {
      console.error("selectAudioDevice", error);
    }

    try {
      const audioConstraints = {
        deviceId: { exact: device.deviceId },
      };

      const currentAudioConstraints = {
        deviceId: { exact: this.props.currentAudioDevice.deviceId },
      };

      try {
        const tracks = this.state.audioInput.getTracks();

        tracks.forEach((track) => {
          track.stop();
        });

        this.state.audioInput.stop();
      } catch (error) {
        console.error("selectAudioDevice track", error);
      }

      try {
        const currentDevice = await navigator.mediaDevices.getUserMedia({
          audio: true, //currentAudioConstraints,
          video: false,
        });

        const currentDeviceTracks = currentDevice.getTracks();

        currentDeviceTracks.forEach((track) => {
          track.stop();
        });

        this.state.audioInput.stop();
      } catch (error) {
        console.error("selectAudioDevice track", error);
      }

      const audioInput = await navigator.mediaDevices.getUserMedia({
        audio: audioConstraints,
        video: false,
      });

      this.setState(
        {
          errorSwitchingMicrophone: false,
          audioConstraints: audioConstraints,
          audioInput: audioInput,
          newAudioDevice: device,
          currentAudioDevice: device,
        },
        callback
      );
    } catch (error) {
      console.error("Error selecting new audio input:", error, this);

      this.setState(
        {
          errorSwitchingMicrophone: true,
        },
        callbackError
      );
    }
  }

  selectOutputAudioDevice(device) {
    this.setState({
      newOutputAudioDevice: device,
    });
  }

  cleanSelectors() {
    this.setState({
      newOutputAudioDevice: null,
      newAudioDevice: null,
      newVideoDevice: null,
    });
  }

  setNewVideoSettings(videoDevice, currentVideoDevice) {
    try {
      if (videoDevice.deviceId !== currentVideoDevice.deviceId) {
        //Update the new devices selected if proceeds
        this.props.selectVideoAndAudioDevice(
          videoDevice,
          audioDevice,
          outputAudioDevice,
          this.props.selectStreamingDevices.selectedDefault
        );

        this.cleanSelectors();
      }

      var videoDevice = null;
      var audioDevice = null;
      var outputAudioDevice = null;

      //We should specify the default devices for the first session
      if (!this.props.selectStreamingDevices.selectedDefault) {
        videoDevice = this.props.currentVideoDevice;
        audioDevice = this.props.currentAudioDevice;
        outputAudioDevice = this.props.currentOutputAudioDevice;
      }

      //Set newAudioDevice selected and update it if it is different than the current in use
      if (
        this.state.newAudioDevice &&
        (!this.props.currentAudioDevice ||
          this.state.newAudioDevice.deviceId !=
            this.props.currentAudioDevice.deviceId)
      ) {
        audioDevice = this.state.newAudioDevice;
      }

      //Set newOutputAudioDevice selected and update it if it is different than the current in use
      if (
        this.state.newOutputAudioDevice &&
        (!this.props.currentOutputAudioDevice ||
          this.state.newOutputAudioDevice.deviceId !=
            this.props.currentOutputAudioDevice.deviceId)
      ) {
        outputAudioDevice = this.state.newOutputAudioDevice;
      }

      //Set newVideoDevice selected and update it if it is different than the current in use
      if (
        this.state.newVideoDevice &&
        (!this.props.currentVideoDevice ||
          this.state.newVideoDevice.deviceId !=
            this.props.currentVideoDevice.deviceId)
      ) {
        videoDevice = this.state.newVideoDevice;
      }

      //Update the new devices selected if proceeds
      this.props.selectVideoAndAudioDevice(
        videoDevice,
        audioDevice,
        outputAudioDevice,
        this.props.selectStreamingDevices.selectedDefault
      );
    } catch (error) {
      console.error("There was a problem saving the new settings", error);
    }

    //Clean selector's state
    this.cleanSelectors();
  }

  saveNewSettings() {
    try {
      var videoDevice = null;
      var audioDevice = null;
      var outputAudioDevice = null;

      //We should specify the default devices when there is no session
      //if (!this.props.session/*!this.props.selectStreamingDevices.selectedDefault*/) {
      videoDevice = this.props.currentVideoDevice;
      audioDevice = this.props.currentAudioDevice;
      outputAudioDevice = this.props.currentOutputAudioDevice;
      //}

      //Set newAudioDevice selected and update it if it is different than the current in use
      if (
        this.state.newAudioDevice &&
        (!this.props.currentAudioDevice ||
          this.state.newAudioDevice.deviceId !=
            this.props.currentAudioDevice.deviceId)
      ) {
        audioDevice = this.state.newAudioDevice;
      }

      //Set newOutputAudioDevice selected and update it if it is different than the current in use
      if (
        this.state.newOutputAudioDevice &&
        (!this.props.currentOutputAudioDevice ||
          this.state.newOutputAudioDevice.deviceId !=
            this.props.currentOutputAudioDevice.deviceId)
      ) {
        outputAudioDevice = this.state.newOutputAudioDevice;
      }

      //Set newVideoDevice selected and update it if it is different than the current in use
      if (
        this.state.newVideoDevice &&
        (!this.props.currentVideoDevice ||
          this.state.newVideoDevice.deviceId !=
            this.props.currentVideoDevice.deviceId)
      ) {
        videoDevice = this.state.newVideoDevice;
      }

      //Update the new devices selected if proceeds
      this.props.selectVideoAndAudioDevice(
        videoDevice,
        audioDevice,
        outputAudioDevice,
        this.props.session ? false : true
      );
    } catch (error) {
      console.error("There was a problem saving the new settings", error);
    }

    //Clean selector's state
    this.cleanSelectors();
  }

  restorePreviousMic() {
    try {
      var audioDevice = null;
      var videoDevice = null;
      var outputAudioDevice = null;

      videoDevice = this.props.currentVideoDevice;
      audioDevice = this.props.currentAudioDevice;
      outputAudioDevice = this.props.currentOutputAudioDevice;

      //Update the new devices selected if proceeds
      this.props.selectVideoAndAudioDevice(
        videoDevice,
        audioDevice,
        outputAudioDevice,
        this.props.session ? false : true,
        false,
        true
      );
    } catch (error) {
      console.error("There was a problem saving the new settings", error);
    }

    //Clean selector's state
    this.cleanSelectors();
  }

  shouldRenderLoading() {
    return (
      !this.props.selectStreamingDevices.availableDevicesInitialized ||
      this.props.selectStreamingDevices.isChangingSettings ||
      this.props.isChangingSettings
    );
  }

  renderVideoDevices() {
    if (
      !this.props.availableDevices ||
      !Array.isArray(this.props.availableDevices) ||
      this.props.availableDevices.length < 1
    ) {
      return (
        <Row>
          <Col>
            <p>{i18next.t("StreamingOptionsComponentNoDevicesFound")}</p>
          </Col>
        </Row>
      );
    }

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row>
          <Col className={"inputOptionsContainer"}>
            <div id={"InputOptionsVideoPreview"}>
              <div>
                <Spinner
                  style={{
                    width: this.parseSelectedDevices().videoDevice ? 70 : 0,
                    height: this.parseSelectedDevices().videoDevice ? 70 : 0,
                  }}
                  animation="grow"
                  variant="danger"
                />
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  {this.parseSelectedDevices().videoDevice
                    ? i18next.t("StreamingOptionsComponentPreviewLoading")
                    : i18next.t(
                        "StreamingOptionsComponentPreviewCurrentCamera"
                      )}
                </p>
              </div>

              {this.props.currentVideoDevice && (
                <Webcam
                  id={"InputOptionsVideoPreview"}
                  audio={false}
                  style={{
                    position: "absolute",
                  }}
                  onUserMediaError={(error) => {
                    this.setState({
                      errorSwitchingCamera: true,
                    });
                    console.log("DATA onUserMediaError", error);
                  }}
                  onUserMedia={(data) => {
                    this.setState({
                      errorSwitchingCamera: false,
                    });
                    console.log("DATA onUserMedia", data);
                  }}
                  videoConstraints={{
                    deviceId: { exact: this.props.currentVideoDevice.deviceId },
                  }}
                  //audioConstraints={this.state.audioConstraints}
                />
              )}
              {this.createVideoPreview()}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={"inputOptionsContainer optionContainer"}>
            <Row className="optionContainer-item">
              <Col>
                <h5 className="StreamingOptionsSectionHeader">
                  {i18next.t("StreamingOptionsComponentPreviewCurrentCamera")}
                </h5>
              </Col>
            </Row>
            <Row className="optionContainer-item">
              <Col>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Control
                    defaultValue={
                      this.props.currentVideoDevice
                        ? this.props.currentVideoDevice.deviceId
                        : "defaultCameraSelector"
                    }
                    onChange={(data) => {
                      var item = this.props.availableDevices.find(
                        (item) =>
                          item.kind == "videoinput" &&
                          item.deviceId === data.target.value
                      );
                      if (item) {
                        this.selectVideoDevice(
                          item,
                          this.saveNewSettings.bind(this)
                        );
                      } else {
                        console.error("Something went wrong");
                      }
                    }}
                    as="select"
                  >
                     <option
                            key={"defaultCameraSelector" + "?key=-1"}
                            value={"defaultCameraSelector"}
                          >
                            {" "}
                            {i18next.t("StreamingOptionsComponentSelectACamera")}
                          </option>
                    {this.props.availableDevices
                      .filter((item) => item.kind == "videoinput")
                      .map((item, i) => {
                        return (
                          <option
                            key={item.deviceId + "?key=" + i}
                            value={item.deviceId}
                          >
                            {" "}
                            {item.label}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Form.Group>
              </Col>

              {this.state.errorSwitchingCamera && (
                <Col className={"ErrorSettingsMediaDevice"}>
                  <Row>
                    <Col className={"ErrorSettingsMediaDeviceRow"}>
                      <img
                        src={WarningError}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <p>
                      {i18next.t("StreamingOptionsComponentErrorLoadingYourCamera")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              )}

              {/*
                <DropdownButton
                  id="dropdown-camera-button"
                  className="optionContainer-item"
                  title={i18next.t(
                    "StreamingOptionsComponentPreviewSelectCamera"
                  )}
                >
                  {this.props.availableDevices
                    .filter((item) => item.kind == "videoinput")
                    .map((item, i) => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            this.selectVideoDevice(item);
                          }}
                        >
                          {item.label}
                        </Dropdown.Item>
                      );
                    })}
                </DropdownButton>
                  */}
            </Row>
            {this.state.newVideoDevice && false && (
              <>
                <Row className="optionContainer-item">
                  <Col>
                    <h5 className="StreamingOptionsSectionHeader">
                      {i18next.t(
                        "StreamingOptionsComponentPreviewNewInputDevice"
                      )}
                    </h5>
                  </Col>
                </Row>
                <Row className="optionContainer-item">
                  <Col>
                    <p>{this.state.newVideoDevice.label}</p>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  renderVideoDevicesOld() {
    if (
      !this.props.availableDevices ||
      !Array.isArray(this.props.availableDevices) ||
      this.props.availableDevices.length < 1
    ) {
      return (
        <Row>
          <Col>
          <p>{i18next.t("StreamingOptionsComponentNoDevicesFound")}</p>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col className={"inputOptionsContainer"}>
          <h3>
            <Badge variant="secondary">
              {i18next.t("StreamingOptionsComponentPreview")}
            </Badge>
          </h3>
          <div id={"InputOptionsVideoPreview"}>
            <div>
              <Spinner
                style={{
                  width: this.parseSelectedDevices().videoDevice ? 70 : 0,
                  height: this.parseSelectedDevices().videoDevice ? 70 : 0,
                }}
                animation="grow"
                variant="danger"
              />
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                {this.parseSelectedDevices().videoDevice
                  ? i18next.t("StreamingOptionsComponentPreviewLoading")
                  : i18next.t("StreamingOptionsComponentPreviewCurrentCamera")}
              </p>
            </div>

            {this.createVideoPreview()}
          </div>
        </Col>
        <Col className={"inputOptionsContainer optionContainer"}>
          <Row className="optionContainer-item">
            <Col>
              <h3>
                <Badge variant="secondary">
                  {i18next.t(
                    "StreamingOptionsComponentPreviewCurrentInputDevice"
                  )}
                </Badge>
              </h3>
            </Col>
          </Row>
          <Row className="optionContainer-item">
            <Col>
              <p>
                {this.props.currentVideoDevice
                  ? this.props.currentVideoDevice.label
                  : i18next.t("StreamingOptionsComponentPreviewUnknown")}
              </p>
            </Col>
          </Row>
          <Row className="optionContainer-item">
            <Col>
              <h3>
                <Badge variant="secondary">
                  {i18next.t(
                    "StreamingOptionsComponentPreviewChangeInputDevice"
                  )}
                </Badge>
              </h3>
            </Col>
          </Row>
          <Row className="optionContainer-item">
            <DropdownButton
              id="dropdown-camera-button"
              className="optionContainer-item"
              title={i18next.t("StreamingOptionsComponentPreviewSelectCamera")}
            >
              {this.props.availableDevices
                .filter((item) => item.kind == "videoinput")
                .map((item, i) => {
                  return (
                    <Dropdown.Item
                      onClick={() => {
                        this.selectVideoDevice(item);
                      }}
                    >
                      {item.label}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          </Row>
          {this.state.newVideoDevice && (
            <Row className="optionContainer">
              <Row className="optionContainer-item">
                <Col>
                  <h3>
                    <Badge variant="secondary">
                      {i18next.t(
                        "StreamingOptionsComponentPreviewNewInputDevice"
                      )}
                    </Badge>
                  </h3>
                </Col>
              </Row>
              <Row className="optionContainer-item">
                <Col>
                  <p>{this.state.newVideoDevice.label}</p>
                </Col>
              </Row>
            </Row>
          )}
        </Col>
      </Row>
    );
  }

  renderOutputAudioDevices() {
    if (
      !this.props.availableDevices ||
      !Array.isArray(this.props.availableDevices) ||
      this.props.availableDevices.length < 1
    ) {
      return (
        <Row>
          <Col>
            <p>
              {i18next.t("StreamingOptionsComponentPreviewNoAudioDevicesFound")}
            </p>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col className="optionContainer">
          <Row className="optionContainer-item">
            <Col>
              <h5 className="StreamingOptionsSectionHeader">
                {i18next.t(
                  "StreamingOptionsComponentPreviewCurrentOutputDevice"
                )}
              </h5>
            </Col>
          </Row>
          <Row className="optionContainer-item">
            <Col>
              <p>
                {this.props.currentOutputAudioDevice
                  ? this.props.currentOutputAudioDevice.label
                  : i18next.t("StreamingOptionsComponentPreviewUnknown")}
              </p>
            </Col>
          </Row>
          <Row className="optionContainer-item">
            <DropdownButton
              id="dropdown-camera-button"
              className="optionContainer-item"
              title={i18next.t(
                "StreamingOptionsComponentPreviewSelectAudioOutput"
              )}
            >
              {this.props.availableDevices
                .filter((item) => item.kind == "audiooutput")
                .map((item, i) => {
                  return (
                    <Dropdown.Item
                      onClick={() => {
                        this.selectOutputAudioDevice(item);
                      }}
                    >
                      {item.label}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          </Row>

          {this.state.newOutputAudioDevice && (
            <>
              <Row className="optionContainer-item">
                <Col>
                  <h5 className="StreamingOptionsSectionHeader">
                    {i18next.t(
                      "StreamingOptionsComponentPreviewNewOutputDevice"
                    )}
                  </h5>
                </Col>
              </Row>
              <Row className="optionContainer-item">
                <Col>
                  <p>{this.state.newOutputAudioDevice.label}</p>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    );
  }

  renderAudioDevices() {
    if (
      !this.props.availableDevices ||
      !Array.isArray(this.props.availableDevices) ||
      this.props.availableDevices.length < 1
    ) {
      return (
        <Row>
          <Col>
            <p>
              {i18next.t("StreamingOptionsComponentPreviewNoVoiceDevicesFound")}
            </p>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col className="optionContainer">
          <Row className="optionContainer-item">
            <Col>
              <h5 className="StreamingOptionsSectionHeader">
                {i18next.t(
                  "StreamingOptionsComponentPreviewCurrentInputDevice"
                )}
              </h5>
            </Col>
          </Row>
          <Row className="optionContainer-item">
            <Col>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  defaultValue={
                    this.props.currentAudioDevice
                      ? this.props.currentAudioDevice.deviceId
                      : "defaultMicrophoneSelector"
                  }
                  onChange={(data) => {
                    var item = this.props.availableDevices.find(
                      (item) =>
                        item.kind == "audioinput" &&
                        item.deviceId === data.target.value
                    );
                    if (item) {
                      if(isMobile){
                        this.selectAudioDevice(
                          item
                        );
                      }else{
                        this.selectAudioDevice(
                          item,
                          this.saveNewSettings.bind(this),
                          this.restorePreviousMic.bind(this)
                        );
                      }
                      
                    } else {
                      console.error("Something went wrong");
                    }
                  }}
                  as="select"
                >
                   <option
                            key={"defaultMicrophoneSelector" + "?key=-1"}
                            value={"defaultMicrophoneSelector"}
                          >
                            {" "}
                            {i18next.t("StreamingOptionsComponentSelectMicrophone")}
                          </option>
                  {this.props.availableDevices
                    .filter((item) => item.kind == "audioinput")
                    .map((item, i) => {
                      return (
                        <option
                          key={item.deviceId + "?key=" + i}
                          value={item.deviceId}
                        >
                          {" "}
                          {item.label}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </Col>
            {this.state.errorSwitchingMicrophone && (
              <Col className={"ErrorSettingsMediaDevice"}>
                <Row>
                  <Col className={"ErrorSettingsMediaDeviceRow"}>
                    <img
                      src={WarningError}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                    <p>
                    {i18next.t("StreamingOptionsComponentErrorLoadingYourMicrophone")}
                    </p>
                  </Col>
                </Row>
              </Col>
            )}

            {!this.state.errorSwitchingMicrophone && this.state.audioInput && (
              <Col>
                {this.state.audioInput ? (
                  <AudioAnalyser
                    key={Date.now()}
                    audio={this.state.audioInput}
                  />
                ) : (
                  ""
                )}
              </Col>
            )}

            {this.state.errorSwitchingCamera && (
              <Col className={"ErrorSettingsMediaDevice"}>
                <Row>
                  <Col className={"ErrorSettingsMediaDeviceRow"}>
                    <img
                      src={WarningError}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                    <p>
                    {i18next.t("StreamingOptionsComponentErrorLoadingYourCamera")}
                    </p>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          {this.state.newAudioDevice && false && (
            <>
              <Row className="optionContainer-item">
                <Col>
                  <h5 className="StreamingOptionsSectionHeader">
                    {i18next.t(
                      "StreamingOptionsComponentPreviewNewInputDevice"
                    )}
                  </h5>
                </Col>
              </Row>
              <Row className="optionContainer-item">
                <Col>
                  <p>{this.state.newAudioDevice.label}</p>
                </Col>
                <Col>
                  {this.state.audioInput ? (
                    <AudioAnalyser audio={this.state.audioInput} />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    );
  }

  renderStreamingOptions() {
    DetectRTC.load();

    return (
      <Modal
        className="SettingsModal"
        style={{}}
        show={this.props.selectStreamingDevices.showSelectionModal}
        onHide={() => {
          
        }}
        onShow={() => {
          this.setState(
            {
              newAudioDevice: null,
              newVideoDevice: null,
            },
            this.getMediaDevices.bind(this)
          );
        }}
        onRequestClose={() => {
          
          this.setOptionModalVisibility(false);
        }}
        centered
      >
        <Modal.Header>
          <h3>
            {i18next.t("StreamingOptionsComponentPreviewStreamingSettings")}
          </h3>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "50vh",
            minHeight: "50vh",
            margin: 0,
            padding: 0,
          }}
        >
          <Tab.Container id="left-tabs-example" defaultActiveKey="videoOptions">
            <Row
              style={{
                maxHeight: "50vh",
                minHeight: "50vh",
                overflowY: "hidden",
                margin: 0,
                padding: 0,
              }}
            >
              <Col
                xs={3}
                sm={3}
                style={{
                  maxHeight: "50vh",
                  minHeight: "50vh",
                  margin: 0,
                  padding: 0,
                  backgroundColor: "rgb(238, 238, 238)",
                }}
              >
                <Nav
                  variant="pills"
                  className="flex-column"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginTop: "0.5%",
                  }}
                >
                 {(this.props.hasTurn || this.props.isInfluencer) && ( <Nav.Item>
                    <Nav.Link eventKey="videoOptions">
                      <div>
                        <img
                          src={CameraIcon}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                        <p className="d-none d-md-block">{i18next.t("StreamingOptionsComponentVideo")}</p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>)}
                  <Nav.Item>
                    <Nav.Link eventKey="micOptions">
                      <div>
                        <img
                          src={MicrophoneIcon}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                        <p className="d-none d-md-block">{i18next.t("StreamingOptionsComponentMicrophone")}</p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  {!this.props.selectStreamingDevices
                    .disableChangeAudioOutputDevices &&
                    !DetectRTC.browser.isFirefox && (
                      <Nav.Item>
                        <Nav.Link eventKey="speakerOptions">
                          <div>
                            <img
                              src={SpeakersIcon}
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                            />
                            <p className="d-none d-md-block">{i18next.t("StreamingOptionsComponentSpeakers")}</p>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                </Nav>
              </Col>
              <Col
                xs={9}
                sm={9}
                style={{
                  maxHeight: "50vh",
                  minHeight: "50vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <Tab.Content>
                  <Tab.Pane eventKey="videoOptions">
                    {this.renderVideoDevices()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="micOptions">
                    {this.renderAudioDevices()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="speakerOptions">
                    {this.renderOutputAudioDevices()}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Row className="StreamingOptionsFooter">
            {false && (
              <Col
                style={
                  !this.props.selectStreamingDevices.disableCloseButton
                    ? {
                        margin: 0,
                        padding: 0,
                      }
                    : {}
                }
              >
                <button
                  className="Btn-save Btn-rojo"
                  onClick={() => {
                    this.saveNewSettings();
                  }}
                >
                  {i18next.t("StreamingOptionsComponentPreviewSave")}
                </button>
              </Col>
            )}
            {/*!this.props.selectStreamingDevices.disableCloseButton ||*/ true && (
              <Col>
                <button
                  className="Btn-rojo"
                  onClick={() => {
                    this.setOptionModalVisibility(false);
                    
                  }}
                >
                  {i18next.t("StreamingOptionsComponentPreviewClose")}
                </button>
              </Col>
            )}
          </Row>
        </Modal.Footer>
      </Modal>
    );

    return (
      <Modal
        className="SettingsModal"
        show={this.props.selectStreamingDevices.showSelectionModal}
        onShow={() => {
          this.setState(
            {
              newAudioDevice: null,
              newVideoDevice: null,
            },
            this.getMediaDevices.bind(this)
          );
        }}
        onRequestClose={() => {
          this.setOptionModalVisibility(false);
        }}
        style={{}}
        centered
      >
        <Row className="centerContent">
          <Col xs={{ span: 12 }}>
            <h2 className="rojo">
              {i18next.t("StreamingOptionsComponentPreviewStreamingSettings")}
            </h2>
          </Col>
        </Row>

        {this.shouldRenderLoading() && (
          <Col
            className={"align-items-center"}
            style={{
              textAlign: "center",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Spinner
              style={{
                width: 70,
                height: 70,
              }}
              animation="grow"
              variant="danger"
            />
            <p>{i18next.t("StreamingOptionsComponentPreviewLoading")}</p>
          </Col>
        )}

        {!this.shouldRenderLoading() && (
          <div>
            <Tabs defaultActiveKey="video" id="uncontrolled-tab-example">
              <Tab
                eventKey="video"
                title={i18next.t("StreamingOptionsComponentPreviewVideo")}
              >
                <Container>{this.renderVideoDevices()}</Container>
              </Tab>
              <Tab
                eventKey="input-audio"
                title={i18next.t("StreamingOptionsComponentPreviewInputAudio")}
              >
                {this.renderAudioDevices()}
              </Tab>
              {!this.props.selectStreamingDevices
                .disableChangeAudioOutputDevices && (
                <Tab
                  eventKey="output-audio"
                  title={i18next.t(
                    "StreamingOptionsComponentPreviewOutputAudio"
                  )}
                >
                  {this.renderOutputAudioDevices()}
                </Tab>
              )}
            </Tabs>
            <Row className="right-center-content">
              <Col>
                <button
                  className="Btn-save Btn-rojo mt-4"
                  onClick={() => {
                    this.saveNewSettings();
                  }}
                >
                  {i18next.t("StreamingOptionsComponentPreviewSave")}
                </button>
                {!this.props.selectStreamingDevices.disableCloseButton && (
                  <button
                    className="Btn-rojo mt-4"
                    onClick={() => {
                      this.setOptionModalVisibility(false);
                    }}
                  >
                    {i18next.t("StreamingOptionsComponentPreviewClose")}
                  </button>
                )}
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    );
  }

  render() {
    return this.renderStreamingOptions();
  }
}
