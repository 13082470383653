import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./style.scss";
import PropTypes from "prop-types";

const Calendar = ({ selectedDates = [] }) => {
  return (
    selectedDates.length > 0 && (
      <DatePicker
        selected={
          new Date(
            moment()
              // .add("1", "d")
              .format("YYYY-MM-DD")
          )
        }
        renderDayContents={(day, date) => {
          if (moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")){
            return <div>{day}</div>;

          }
          const selected = selectedDates.find(
            d => d === moment(date).format("YYYY-MM-DD")
          );
          return selected ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', alignContent:'center', color:'black' }}>
              {/* <div className={`react-datepicker__day react-datepicker__day--${day} react-datepicker__day--selected`}> */}
              <div className={``}>
                {day}
            </div>
            <span className='redBall'> </span>

            </div>) 
            : (
              <div
                className={`react-datepicker__day react-datepicker__day--${day}`}
              >
                {day}
              </div>
            );
        }}
        inline
      />
    )
  );
};

Calendar.propTypes = {
  selectedDates: PropTypes.array
};

export default Calendar;
