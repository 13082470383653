import React, {Component} from 'react';
import map from '../../../../assets/img/icons/ic-map.svg';

import '../../../comunes/upcomingLiveEvents/card_upcoming/style.scss';
import './style.scss';
import {Link} from "react-router-dom";

class CardUpcomingInfluencerPrivate extends Component {
    render() {
        return (
            <div className="CardUpcoming LiveEventsCard col-12 col-md-6 Past_influencer mt-5">
                <div className="Shadow">
                    

                    <div className="Header">
                    </div>

                    <div className="Footer">
                        <div className="Fecha">
                            <span className="Dia">18</span>
                            <span className="Mes"> Abr</span>
                        </div>
                        <div className="Texto">
                            <span className="Tit">Hot Pursuit - "Hotting Up"</span>
                            <span className="Cat">Games</span>
                            <span className="Hor"> <img src={map} alt=""/>Live From New York, at 18:30 pm</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CardUpcomingInfluencerPrivate;