/* hook to handle the state and redirects related to the event purchase */
import { useState, useEffect, useRef } from 'react'
import { InfluencerDAO } from "daos";
import { ACCESS_TOKEN_API, Api, HOME_PAGE_PAYPAL_REDIRECT, STRIPE_PUBLIC_KEY } from '../environment/environment'
import { loadStripe } from "@stripe/stripe-js";
import useStateRef from './useStateRef'
import axios from "axios";
import useIsUserLoggedIn from './useIsUserLoggedIn'
import i18next from 'i18next';

const useSuscription = ({ influencerUsername, sessionToken, setError, history }) => {

    const [loading, setLoading] = useState(false)
    const influencerDAO = new InfluencerDAO();

    useEffect(() => {
        console.log("usesuscribtion", sessionToken, influencerUsername)
        handleSuscription();
    }, [])

    const createSuscriptionSessionCheckout = async () => {

        //const sessionID = "cs_test_a1ayFHl3Vp3pkoItKSjYOC4zcKbwlTSm1H6EcjqDmrWTycMp483r2U9Ima";

        try{
            const sessionID = await influencerDAO.createCheckoutSuscription(influencerUsername, sessionToken);

            console.log("createSuscriptionSessionCheckout", sessionID)

            return sessionID.data.data.id;
        }catch(error){
            console.log(error)

            setError("There was an error")
            history.push(`/${influencerUsername}`)        }
      
    }

    const redirectUserToCheckOut = async (checkoutSessionID) => {
        try{
            const stripe = await loadStripe(STRIPE_PUBLIC_KEY);

            console.log("redirectUserToCheckOut", stripe)
    
            setLoading(false);
    
            stripe.redirectToCheckout({
                sessionId: checkoutSessionID
            })
        }catch(error){
            console.error(error)
        }
        
    }

    const handleSuscription = async (e) => {

        if (!loading) {
            setLoading(true);
            const checkoutSessionID = await createSuscriptionSessionCheckout();

            if (checkoutSessionID) {
                redirectUserToCheckOut(checkoutSessionID);
            } else {
                //error
                setLoading(false);
            }
        }
    }

    return { loading, handleSuscription }
}
export default useSuscription