import React, { useEffect } from "react";
import Navbar_search from "./navbar_search";
import DiscoverByCat from "../../comunes/discoverByCategory";
import UpcomingLiveEvents from "../../comunes/upcomingLiveEvents";
import LiveEvents from "../../comunes/liveEvents/index";
import Tags from "./tags_search";
import Influencer_search from "./influencer_search";
import LiveEvents_search from "./event_search/live_events";
import UpComingEvents_search from "./event_search/upcoming_events";
import { useSearchLogic } from "./SearchLogic";
import Loader from "components/comunes/loader";
import { useScroll } from '../../../hooks/useScroll'
import i18next from 'i18next';

const Search = ({ match }) => {
  const { loading, data, setQuery, trendingTags } = useSearchLogic();
  const { scrollY, scrollX, scrollDirection } = useScroll()
  useEffect(() => {
    

  }, [scrollY])
  const propsLiveEvents = {
    dataInjected: data.live_events
  };
  const propsUpcomingEvents = {
    dataInjected: data.upcoming
  };
  const propsInfluencers = { dataInjected: data.influencers };

  useEffect(() => {
    const { query } = match.params;

    setQuery(query);
  }, [match.params]);

  if (!match.params.query) {
    delete propsLiveEvents.dataInjected;
    delete propsUpcomingEvents.dataInjected;
    delete propsInfluencers.dataInjected;
  }

  return (
    <div className="Search">
      <Navbar_search opacity={`${(scrollDirection === "down" && scrollY > -50) ? "50%" : "100%"}`} />

      {!loading ? (
        <>
          <LiveEvents {...propsLiveEvents} />
          <div style={{ marginTop: '30px' }}>

            <UpcomingLiveEvents showAll limit={6} {...propsUpcomingEvents} />

          </div>
          <Influencer_search
          max={6}
          showAll
            title={i18next.t('SearchComponentTrendingInfluencers')}
            {...propsInfluencers}
          />

          <DiscoverByCat title={i18next.t('SearchComponentCategories')}/>

          <Tags data={trendingTags} />
        </>
      ) : (
          <Loader />
        )}
    </div>
  );
};

export default Search;
