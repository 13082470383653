import { useState, useEffect } from "react";
import { UserDAO } from "../../../../../daos";
import { useDispatch } from 'react-redux'
import * as actions from "../../../../../store/actions";
import $ from "jquery";
import * as yup from "yup";
import { LINKEDIN_CLIENTID, LOGIN_BACK_URL, FACEBOOK_CLIENTID, INSTAGRAM_CLIENTID } from "../../../../../environment/environment";
import useLocalStorage from "../../../../../hooks/useLocalStorage" 
import i18next from 'i18next';

const initialFormValues = {
  email: "",
  password: "",
  repeatpassword: ""
};

let schema = null;

const userDAO = new UserDAO();

/**
 * Logic of ModalRegister=
 * @returns {Object}
 */
export const useModalRegisterLogic = (recaptchaRef) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errorForm, setErrorForm] = useState("");
  const [showAlertGoodCredentials, setShowAlertGoodCredentials] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  const [referral, setReferral, clearReferral] = useLocalStorage("referral")

  useEffect(() => {
    schema = yup.object().shape({
      repeatpassword: yup
        .string()
        .required(i18next.t("ModalLoginLogicComponentPasswordRequiredError"))
        .oneOf([yup.ref("password"), null], i18next.t("ModalLoginLogicComponentPasswordMustMatchError")),
      password: yup
        .string()
        .required(i18next.t("ModalLoginLogicComponentPasswordRequiredError"))
        .test(
          "format",
          i18next.t("ModalLoginLogicComponentPasswordFormatError"),
          value => {
            const reg = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}/;
            return reg.test(value);
          }
        ),
      email: yup
        .string()
        .email(i18next.t("ModalLoginLogicComponenEmailFormatErrorMessage"))
        .required(i18next.t("ModalLoginLogicComponentEmailRequired"))
    });
  }, [])

  /**
   * Handle form changes.
   * @param {Object} event, Event trigerred for the input form
   */
  const onChangeFormValues = event => {
    const updatedValues = JSON.parse(JSON.stringify(formValues));
    const { name, value } = event.target;
    updatedValues[name] = value;
    setFormValues(updatedValues);
  };

  /**
   * Evaluate the response of the register request
   * @param {Object} event, Event triggered for the form
   */

  const twitterRegister = async () => {
    try {
      const res = await userDAO.requestTwitterToken();
      const token = res.data.oauth_token;
      
      localStorage.setItem("twitterAction", "login");
      window.location.href = "https://api.twitter.com/oauth/authenticate?oauth_token=" + token
    } catch (err) {
      if (err && err.response && err.response.status === 400) {
        setErrorForm(
          err.response.data.error.map(item => item.message).join(", ")
        );
      } else {
        setErrorForm(i18next.t("ModalLoginLogicComponentSomethingWentWrong"));
      }
    }
  };

  const linkedinRegister = () => {
    localStorage.setItem("LinkedinAction", "login");
    const backURL = LOGIN_BACK_URL;
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENTID}&redirect_uri=${backURL}&scope=r_emailaddress,r_liteprofile&state=Login`;
  };


  const facebookRegister = () => {
    const backURL = LOGIN_BACK_URL;
    localStorage.setItem("facebookAction", "login");
    window.location.href = `https://www.facebook.com/v8.0/dialog/oauth?client_id=${FACEBOOK_CLIENTID}&redirect_uri=${backURL}/facebook&state=login&auth_type=rerequest&scope=email`;
  };
  
  const instagramRegister = () => {
    const backURL = LOGIN_BACK_URL + "instagramSignUp";
    
    localStorage.setItem("istagramAction", "verify");
    localStorage.setItem("instagramRedirectPrevURL", window.location.pathname);
    window.location.href = `https://api.instagram.com/oauth/authorize?app_id=${INSTAGRAM_CLIENTID}&redirect_uri=${backURL}&response_type=code&scope=user_profile`;
  };

  const onSubmit = async event => {
    if (loading) return;
    event.preventDefault();

    try {
      await schema.validate(formValues);
    } catch (err) {
      setErrorForm(err.errors[0]);
      return;
    }

    if(recaptchaRef && recaptchaRef.execute){
      recaptchaRef.execute();
    }

  };

  const onCaptchaChange = async () =>{
    if(recaptchaRef){
      const recaptchaValue = recaptchaRef.getValue();
      

      if(recaptchaValue){
        


        try {
          await userDAO.validateCaptcha(recaptchaValue);
        } catch (err) {
          if (err.response.status !== 200) {
            setErrorForm(
              err.response.data.error.map(item => item.message).join(", ")
            );
          } else {
            setErrorForm("Sorry, something went wrong.");
          }
          return;
        } finally {
          onCaptchaSuccess();
          setLoading(false);
        }
      }
  
      
    }
   
  }

  const onCaptchaError = () =>{
    
  }

  const onCaptchaSuccess = async () =>{
    
    setLoading(true);

    try {
      var { email, password } = formValues;
      formValues.email = formValues.email.toLowerCase().trim();

       //TODO: CHECK if referral and send. 
      console.log({referral})
      await userDAO.save({ email, password });
    } catch (err) {
      console.error("onCaptchaSuccess",err)
      try{
        if (err.response.status === 400) {
          setErrorForm(
            err.response.data.error.map(item => item.message).join(", ")
          );
        } else {
          setErrorForm(i18next.t("ModalLoginLogicComponentSomethingWentWrong"));
        }
      }catch(error){
        setErrorForm(i18next.t("ModalLoginLogicComponentSomethingWentWrong"));
      }
      return;
    } finally {
      setLoading(false);
    }

    try {
      const { email, password } = formValues;
      const response = await userDAO.login({ email, password });
      const { data } = response;
      const env = {
        token: data.data.user_app_token
      }
      localStorage.setItem("env", JSON.stringify(env));
      // await dispatch(actions.getUser(data.data.user_app_token))
      dispatch(actions.setUser(data.data))
      setShowAlertGoodCredentials(true);
      window.$("#myModal_sign").modal("hide");
      window.$("#myModal_sign").modal("hide");
      window.$("#myModal_basic_info").modal("show");
      setFormValues(initialFormValues);
    } catch (err) {
      try{
        if (err.response.status === 400) {
          setErrorForm(
            err.response.data.error.map(item => item.message).join(", ")
          );
        } else {
          setErrorForm(i18next.t("ModalLoginLogicComponentSomethingWentWrong"));
        }
      }catch(error){
        setErrorForm(i18next.t("ModalLoginLogicComponentSomethingWentWrong"));
        console.error(error)
      }
    } finally {
      setLoading(false);
    }

  }

  return {
    formValues,
    onChangeFormValues,
    onSubmit,
    onCaptchaSuccess,
    onCaptchaChange,
    errorForm,
    setErrorForm,
    twitterRegister,
    showAlertGoodCredentials,
    setShowAlertGoodCredentials,
    linkedinRegister,
    facebookRegister,
    instagramRegister,
    loading
  };
};
