/* Hook to fetch an event */
import React, { useState, useEffect } from "react";
import { B2B_EVENT_UUID, ENABLE_PURCHASE } from "../environment/environment";
import { EventDAO } from "daos";
import { useSelector } from "react-redux";
import useIsUserLoggedIn from "./useIsUserLoggedIn";
import { isMobile } from "react-device-detect";
import * as EventTypes from "../constants/EventTypes";

let idleTimer = null;
let idleState = false;
const refreshPeriod = 3 * 1000; //5 seconds

const initialValuesEventData = { event: {}, user: {}, influencer: {} };

const useEvent = (languages, id) => {
  const [languagesArray, _setLanguagesArray] = useState([]);

  const languagesArrayRef = React.useRef(languagesArray);
  const setLanguagesArray = (data) => {
    languagesArrayRef.current = data;
    _setLanguagesArray(data);
  };

  const [categoriesArray, _setCategorriesArray] = useState([]);

  const categoriesArrayRef = React.useRef(categoriesArray);
  const setCategoriesArray = (data) => {
    categoriesArrayRef.current = data;
    _setCategorriesArray(data);
  };

  let localID = id;
  if (!id && B2B_EVENT_UUID) {
    localID = B2B_EVENT_UUID;
  }

  const { isUserLoggedIn } = useIsUserLoggedIn();
  const user = useSelector((state) => state.user);
  const categories = useSelector((state) => state.categories.categories);
  const eventDAO = new EventDAO();
  const [eventData, setEvent] = useState(initialValuesEventData);
  const [loading, setLoading] = useState(true);
  const [purchased, setPurchased] = useState(false);
  const [upcoming, setUpcoming] = useState(false);
  const [live, setLive] = useState(false);
  const [openTicketing, setOpenTicketing] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [hasAdvertisingEnabled, setHasAdvertisingEnabled] = useState(false);
  const [eventType, setEventType] = useState({
    code: null,
    name: null,
  });
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true); // flag for first load of event

  const [renderBuyButton, setRenderBuyButton] = useState();

  useEffect(() => {
    if(ENABLE_PURCHASE){
      if ((upcoming || (live && openTicketing)) && (!purchased && !isCreator)) {
        setRenderBuyButton(true);
      } else {
        setRenderBuyButton(false);
      }
    }
  }, [upcoming, live, openTicketing, purchased, isCreator, firstLoad]);

  const initEvent = async () => {
    try {
      const response = await eventDAO.findById(localID);
      let { data } = response.data;
      data.event.event_category = categories.find(
        (category) => category.cate_ide === data.event.event_category_ide
      );
      data.event.event_languages = languages.filter(
        (language) =>
          JSON.parse(data.event.event_lang_ides).indexOf(language.lang_ide) > -1
      );
      setEvent(data);
      setLoading(false);
      setOpenTicketing(data.event.event_opening_ticketing);
    } catch (err) {}
  };

  useEffect(() => {
    if (categoriesArray.length > 0 && languagesArray.length > 0) {
      initEvent();
    }
  }, [categoriesArray, languagesArray]);

  useEffect(() => {
    if (languages.length > 0 && languagesArray.length === 0) {
      setLanguagesArray(languages);
    }
  }, [languages]);

  
  useEffect(() => {
    if (categories.length > 0 && categoriesArray.length === 0) {
      setCategoriesArray(categories);
    }
  }, [categories]);

  useEffect(() => {
    if (!isMobile) {
      refreshEventDataTimeout();
    }

    //Component will unmount
    return () => {
      stopRefreshEvebtDataTimeout();
    };
  }, []);

  useEffect(() => {
    // enter loading state for user
    checkIfPurchased();
  }, [user, id]);

  useEffect(() => {
    console.log("useEffect eventdata", eventData);
    checkEventState();
  }, [eventData, user.user]);

  const refreshEventDataTimeout = () => {
    clearTimeout(idleTimer);
    idleState = false;
    idleTimer = setTimeout(async function() {
      try {
        const response = await eventDAO.findById(localID);
        let { data } = response.data;
        data.event.event_category = categoriesArrayRef.current.find(
          (category) => category.cate_ide === data.event.event_category_ide
        );

        console.log("refreshEventDataTimeout", data, languages, languagesArrayRef.current, categoriesArrayRef.current);

        data.event.event_languages = languagesArrayRef.current.filter(
          (language) =>
            JSON.parse(data.event.event_lang_ides).indexOf(language.lang_ide) >
            -1
        );
        setEvent(data);
        idleState = true;

        setOpenTicketing(data.event.event_opening_ticketing);
        checkIfPurchased();
        checkEventState();
        setFirstLoad(false);
        refreshEventDataTimeout();
      } catch (e) {
        console.error("There was a problem refreshing the event data", e);
        idleState = true;
        refreshEventDataTimeout();
      }
    }, refreshPeriod);
  };

  const stopRefreshEvebtDataTimeout = () => {
    clearTimeout(idleTimer);
    idleState = false;
  };

  const checkIfPurchased = () => {
    if (Object.keys(user.user).length > 0) {
      if (user.user && user.user.purchasedEvents) {
        user.user.purchasedEvents.forEach((event) => {
          if (event.event.event_uuid === localID) {
            setPurchased(true);
          }
        });
      }
      if (user.isInfluencer) {
        setIsInfluencer(true);
      } else {
        setIsInfluencer(false);
      }
    }
  };

  const checkEventState = () => {
    if (
      eventData &&
      eventData.event &&
      eventData.event.event_ide !== undefined
    ) {
      if (
        Object.keys(eventData.user).length > 0 &&
        user.user &&
        eventData.user.user_username === user.user.user_username
      ) {
        setIsCreator(true);
      }

      if (eventData.influencer && eventData.influencer.influencer_advertising) {
        setHasAdvertisingEnabled(true);
      }

      if (eventData.event && eventData.event.event_state === 0) {
        setUpcoming(true);
        setLive(false);
      }

      if (eventData.event && eventData.event.event_state === 1) {
        setLive(true);
        setUpcoming(false);
      }

      if (eventData.event && eventData.event.event_state === 2) {
        setLive(false);
        setUpcoming(false);
      }

      if (eventData.event) {
        if (eventData.event.ticket !== null) {
          setEventType({
            code: EventTypes.WEBRTC,
            name: EventTypes.WEBRTC_STRING,
          });
        } else {
          setEventType({
            code: EventTypes.RTMP,
            name: EventTypes.RTMP_STRING,
          });
        }
      }
    }
  };

  return {
    renderBuyButton,
    isUserLoggedIn,
    eventData,
    loading,
    purchased,
    upcoming,
    live,
    isCreator,
    eventType,
    isInfluencer,
    openTicketing,
    firstLoad,
    hasAdvertisingEnabled,
  };
};
export default useEvent;
