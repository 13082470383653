import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

const FilteredListStreaming = ({ data = [] }) => {
  return (
    <div className="chat-streaming">
      <ul className="list_chat list-unstyled">
        {data.map(({ avatar, username, message }) => (
          <li className="list_chat_details_streaming">
            <div className="">
              <img className="img_user_chat2" src={avatar} />
              <div>
                <h3 className="m-0" id="title_name2">
                  {username}
                </h3>{" "}
                <p className="last_message2 m-0">{message}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

FilteredListStreaming.propTypes = {
  data: PropTypes.array.isRequired
};

export default FilteredListStreaming;
