import React, { Component, useState } from "react";
import EventCardInfluencer from '../../comunes/Cards/EventCardInfluencer'

function FavoriteLiveEvents(props) {
  const events =
    localStorage.getItem("eventsDraft") &&
    JSON.parse(localStorage.getItem("eventsDraft"));
  return (
    <div className="container Upcoming-live-events">
      <div className="row">
        <div className="col-md-12 d-flex Fila-live-events">
          <div className="col-12 h-15">
            <h2 className="m-0">Drafts Live Events</h2>
          </div>
          <div className="row">
          {events &&
            events.map((event, index) => (
              <EventCardInfluencer event={event} key={index} cardsPerRow={3} type='draft'/>

            ))}
            </div>
          <br />
          <br />
          {!events && (
            <p style={{display: "flex", justifyContent: "center",
             alignItems: "center", width: "100%", fontSize: "22px"
              }}
            >
              You have no events yet.
            </p>
          )}
        </div>
     
      </div>
    </div>
  );
}

export default FavoriteLiveEvents;
