import React from 'react'
import { parsePrivateRequestReasonsLocaleResources } from '../../../utils/Localization'
import fun from "assets/img/icons/ic-fun.svg";
import business from "assets/img/icons/ic-business.svg";
import education from "assets/img/icons/ic-raisehand.svg";
import other from "assets/img/icons/ic-price.svg";
import * as EventRequestTypes from 'utils/EventRequestTypes'
import i18next from 'i18next';

const SelectMeetingType = ({errors,  register, mainReason, setMainReason, setSelectedOption, selectedOption }) => {

    return (
        <div className="mt-5">
            <h4>{i18next.t("SelectMeetingTypeComponentTitle")}</h4>
            {
                parsePrivateRequestReasonsLocaleResources().map((reason) => {
                    return (
                        <div style={{cursor: 'pointer'}}>
                            <div style={{ alignContent: 'start', alignItems: 'start' }} onClick={() => setMainReason(reason)} className="d-flex information_detalles mt-3 ">
                                <img src={reason.request_type === EventRequestTypes.JUST_FOR_FUN ? fun : reason.request_type === EventRequestTypes.BUSINESS ? business :
                                    reason.request_type ===EventRequestTypes.EDUCATION ? education : reason.request_type === EventRequestTypes.OTHER_REASON && other} className=" optionLogo pointer mr-3 mt-3" onClick={() => setMainReason(reason)} alt="fun" />
                                <div className="">
                                    <div style={{ borderBottom: mainReason.request_type !== reason.request_type && '1px solid #D8DCE5 ' }}>
                                        <p className="m-0">{reason.name}</p>
                                        <p className="request_p">{reason.secondaryName}</p>
                                    </div>
                                    <div className="">
                                        {mainReason.request_type === reason.request_type &&
                                            reason.options.map((option) => (
                                                <div className="" onClick={() => setSelectedOption(option)} style={{cursor: 'pointer'}}>
                                                    <div onClick={() => setSelectedOption(option)} className=" d-flex">
                                                        <input ref={register} className="mr-2 demo" style={{cursor: 'pointer'}} type="radio" id="acepto" name="acepto" />
                                                        <label style={{ marginTop: '-4px' }}>
                                                            {option.optionName}
                                                        </label>
                                                    </div>
                                                    {option && option.optionId === 4 && selectedOption && selectedOption.optionId === 4 &&
                                                        <div className="">
                                                            <span className="small_grey">{option.secondaryName}</span>
                                                            <input  style={{ padding: '8px', cursor: 'pointer' }} ref={register}
                                                                className="reason" type="text" name="customReason" />
                                                        </div>

                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SelectMeetingType