import { useState, useEffect } from "react";
import * as yup from "yup";
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import { EventDAO } from "daos";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useSelector } from "react-redux";
import * as momenttz from "moment-timezone";
import moment from "moment";
import { useDispatch } from "react-redux";
import * as actions from "../../store/actions";
import {
  roundHourToclouserQuarterToString,
  roundHourToclouserQuarter,
} from "../../utils/timeAndDateUtils";
import imagePlaceHolder from "assets/img/imagePlaceHolder.jpg";

import i18next from "i18next";
import axios from "axios";
import {
  EVENT_TYPE_BROADCAST,
  EVENT_TYPE_INTERACTIVE,
  streamingTypes,
} from "../../utils/EventStreamingTypes";
import {
  NEW_PUBLISH_PAGE,
  newB2BInfluencerLanding,
  B2B_PORT,
  PRIVATE_MARKETPLACE,
  BRAND_NAME,
  HOME_PAGE,
} from "../../environment/environment";

toast.configure();
const eventDAO = new EventDAO();
let intervalPitchVideoChange = null;
let intervalThanksVideoChange = null;
let TIME_MAX_VIDEO = 15;

let refPresentationVideo = "";
let refThanksVideo = "";

export const usePublishLiveEventLogic = ({
  requestData,
  privateMode,
  user,
  history,
  id,
}) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const schema = yup.object().shape({
    event_title: yup
      .string()
      .required(i18next.t("UseBuyEventComponentTitleRequired")),
    event_cover_image: yup.string(),
    //.required(i18next.t("UseBuyEventComponentCoverRequired")),
    event_description: yup
      .string()
      .required(i18next.t("UseBuyEventComponentDescriptionRequired")),
    event_currency: yup.string(),
    event_category_ide: NEW_PUBLISH_PAGE
      ? yup.number()
      : yup
          .number()
          .required(i18next.t("UseBuyEventComponentCategoryRequired"))
          .typeError(i18next.t("UseBuyEventComponentCategoryRequired")),
    event_lang_ides: NEW_PUBLISH_PAGE
      ? yup.array()
      : yup.array().required(i18next.t("UseBuyEventComponentLanguageRequired")),
    event_tags: yup.array(),
    event_date: yup
      .string()
      .required(i18next.t("UseBuyEventComponentDateRequired")),
    event_start_hour: yup
      .string()
      .required(i18next.t("UseBuyEventComponentStartHourRequired")),
    event_end_hour: NEW_PUBLISH_PAGE
      ? yup.string()
      : yup.string().required(i18next.t("UseBuyEventComponentEndHourRequired")),
    event_num_attendees: yup
      .number()
      .required(i18next.t("UseBuyEventComponentNumberOfTicketsRequired"))
      .typeError(i18next.t("UseBuyEventComponentInvalidNumberOfTickets")),
    event_price_ticket: yup
      .string()
      .required(i18next.t("UseBuyEventComponentPriceTicketsRequired"))
      .typeError(i18next.t("UseBuyEventComponentInvalidPrice")),
  });

  let userLocation = null;

  try {
    console.log("Parsinbg location", user, user.user_location);
    const parsedLocation = JSON.parse(user.user_location).city;

    console.log("Parsinbg location 2", user, parsedLocation);

    if (parsedLocation !== "Default") {
      userLocation = parsedLocation;
    }
  } catch (error) {}

  let initialEventValues = {
    event_title: "",
    event_description: "",
    event_currency: "usd",
    event_category_ide: categories && categories[0].cate_ide,
    event_lang_ides: [],
    event_tags: [],
    event_date: roundHourToclouserQuarter(new Date()),
    event_location: userLocation || "Mexico City, CDMX, Mexico",
    event_start_hour: roundHourToclouserQuarterToString(new Date()),
    event_end_hour: roundHourToclouserQuarterToString(
      new Date(
        moment()
          .add(1, "hours")
          .valueOf()
      )
    ),
    event_num_attendees: undefined,
    event_price_ticket: 0,
    event_promotional_code_discount: "",
    event_promotional_code: "",
    event_cover_image: "",
    event_thanks_message: "",
    event_presentation_video: "",
    event_thanks_video: "",
    event_ticket_type: "",
  };
  const [eventValues, setEventValues] = useState(initialEventValues);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [openTicket, setOpenTicket] = useState(true);
  const [publicEvent, setPublicEvent] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [confirmUserEmail, setConfirmUserEmail] = useState("");
  const [loadingImage, setLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultImage, setDefaultImage] = useState(null);
  const [errorForm, setErrorForm] = useState("");
  const [showSuccessEvent, setShowSuccessEvent] = useState(false);
  const [initiaLoading, setInitialLoaging] = useState(true);
  const [publishing, setPublishing] = useState(false);
  const [loadedImg, setLoadedImg] = useState(false);
  const [massiveBroadcast, setMassiveBroadcast] = useState(false);

  const initEvent = async () => {
    try {
      const env = JSON.parse(localStorage.getItem("env"));
      await eventDAO.findById(id);
      await dispatch(actions.getUser(env.token));
      await dispatch(actions.getInfluencer(env.token));
      const updatedEventValues = JSON.parse(JSON.stringify(eventValues));
      setEventValues(updatedEventValues);
    } catch (err) {
      console.error("initEvent", err);
    } finally {
      setInitialLoaging(false);
    }
  };

  const selectCoverImage = (base64Data) => {
    setDefaultImage(base64Data);
    setLoadingImage(false);
    console.log("selectCoverImage", base64Data)
  };

  const initDefaultImage = async (file) => {
    try {
      const selectedFile = file || imagePlaceHolder;

      console.log("initEvent handleImageChange", selectedFile);
      setLoadingImage(true);
      var xhr = new XMLHttpRequest();
      xhr.open("GET", selectedFile, true);
      xhr.responseType = "blob";
      xhr.onload = function(e) {
        console.log(this.response);
        var reader = new FileReader();
        reader.onload = function(event) {
          var res = event.target.result;
          console.log("result", res);

          selectCoverImage(res);
        };
        var file = this.response;
        reader.readAsDataURL(file);
      };
      xhr.send();
    } catch (error) {
      setLoadingImage(false);
    }

    /*try{
      await handleImageChange(imagePlaceHolder);
    }catch(error){
      console.error("initDefaultImage:", error)
    }*/
  };

  const loadTicketTypes = async () => {
    try {
      const res = await eventDAO.getTicketType();
      const ticketTypes = res.data.data;

      const activeTypes = ticketTypes.filter((type) => type.active === true);
      setTicketTypes(activeTypes);

      setEventValues({
        ...eventValues,
        event_ticket_type: activeTypes[0].type_id,
      });
    } catch (err) {
      console.error("ERROR LOADTICKETTYPES ", err);
    }
  };
  useEffect(() => {
    if (privateMode && requestData && requestData.user) {
      let { request, user, influencer_user } = requestData;
      console.log("privateMode useeffect setEventValues");
      setEventValues({
        ...eventValues,
        event_title: `Private Event for ${user.user_username}`,
        request_ide: request.request_ide,
        event_num_attendees: request.request_num_attendees,
        event_date: request.request_date,
        event_start_hour: request.request_start_hour
          ? request.request_start_hour
          : "00:00",
        event_end_hour: request.request_end_hour
          ? request.request_end_hour
          : "00:00",
        event_ticket_type: request.request_ticket_type,
        event_price_ticket: request.request_price_ticket,
        event_location: JSON.parse(user.user_location).city,
      });
    }
  }, [requestData]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      setEventValues({
        ...eventValues,
        event_category_ide: categories[0].cate_ide,
      });
      // loadTicketTypes()
    }
  }, [categories]);

  useEffect(() => {
    console.log("eventValues useeffect", eventValues);
  }, [eventValues]);

  useEffect(() => {
    if (id) {
      initEvent();
    } else {
      setInitialLoaging(false);
    }
    initDefaultImage();
    loadTicketTypes();
  }, []);

  /**
   * Handles the form changes and updates the values for the event in the state.
   * @param {Object} event, Event triggered for the form
   */

  const onChangeEventValues = (event) => {
    let { name, value } = event.target;
    console.log({ value });

    console.log("onChangeEventValues", event.target, { value });

    // html resolves e.target.value into a string. Checking if it should be int and converting:
    if (name === "event_ticket_type") {
      value = parseInt(value, 10);
    }
    const updatedValues = JSON.parse(JSON.stringify(eventValues));
    updatedValues[name] = value;
    setEventValues(updatedValues);
  };

  const onChangeUserEmail = (event) => {
    let { name, value } = event.target;
    console.log({ value });

    console.log("onChangeUserEmail", event.target, { value });

    setUserEmail(value);
  };

  const onChangeConfirmUserEmail = (event) => {
    let { name, value } = event.target;
    console.log({ value });

    console.log("onChangeConfirmUserEmail", event.target, { value });

    setConfirmUserEmail(value);
  };

  const handleImageLoad = async (event) => {
    const reg = new RegExp("(.*?).(jpg|png|jpeg)$");
    if (!reg.test(event.target.value)) {
      toast("Supported formats are JPG, PNG and JPEG!");
      return;
    } else {
      setLoadedImg(false);
      setLoadingImage(true);
      const updatedEventValues = JSON.parse(JSON.stringify(eventValues));
      updatedEventValues.event_cover_image = "";
      setEventValues(updatedEventValues);

      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setLoadedImg(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
      setLoadingImage(false);
    }
  };
  /**
   * Handle image changes.
   * @param {Object} event, Event trigered for the input file
   */
  const handleImageChange = async (url) => {
    console.log("handleImageChange 1", url);
    setLoadingImage(true);
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compresedFile = await imageCompression(
      url,
      // event.target.files[0],
      options
    );
    const base64 = await imageCompression.getDataUrlFromFile(compresedFile);
    const updatedEventValues = JSON.parse(JSON.stringify(eventValues));
    updatedEventValues.event_cover_image = base64;

    console.log("handleImageChange", base64);
    setEventValues(updatedEventValues);
    setLoadingImage(false);
  };

  const handlePitchVideoChange = (blob) => {
    refPresentationVideo = new File([blob], "event_presentation_video", {
      type: "video/mp4",
      lastModified: new Date(),
    });
  };
  const handleThanksVideoChange = (blob) => {
    refThanksVideo = new File([blob], "event_thanks_video", {
      type: "video/mp4",
      lastModified: new Date(),
    });
  };

  const onSaveInDraft = () => {
    const eventsDraft =
      localStorage.getItem("eventsDraft") &&
      JSON.parse(localStorage.getItem("eventsDraft"));

    if (eventsDraft) {
      eventsDraft.push(eventValues);
      localStorage.setItem("eventsDraft", JSON.stringify(eventsDraft));
    } else {
      localStorage.setItem("eventsDraft", JSON.stringify([eventValues]));
    }
  };

  const validateUserEmail = () => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var email = userEmail.trim().toLowerCase();

    var confirmEmail = confirmUserEmail.trim().toLowerCase();

    if (!email || email.length === 0 || !emailRegex.test(String(email))) {
      alert(i18next.t("UseBuyEventComponentPleaseEnterYourEmailAddress"));

      return false;
    }

    if (
      !confirmEmail ||
      confirmEmail.length === 0 ||
      !emailRegex.test(String(confirmEmail))
    ) {
      alert(i18next.t("UseBuyEventComponentPleaseEnterYourEmailAddress"));

      return false;
    }

    if (email !== confirmEmail) {
      alert(i18next.t("UseBuyEventComponentEmailsShouldMatch"));

      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    if (newB2BInfluencerLanding) {
      e.preventDefault();

      console.log({ eventValues });
      console.log({ openTicket });
      if (loading) return;

      console.log("Submit", eventValues, defaultImage);
      try {
        await schema.validate(eventValues);
      } catch (err) {
        setErrorForm(err.errors[0]);
        return;
      }

      if (!user.user_email) {
        try {
          if (!validateUserEmail()) {
            return;
          }
        } catch (err) {
          return;
        }
      }

      setLoading(true);
      try {
        const values = new FormData();

        if(B2B_PORT || PRIVATE_MARKETPLACE){
          values.append("event_platform_name", BRAND_NAME);
          values.append("event_platform_url", HOME_PAGE);
        }

        values.append("event_title", eventValues.event_title);
        values.append("event_description", eventValues.event_description);
        values.append("event_opening_ticketing", true);
        values.append("public", publicEvent);

        if (eventValues.request_ide) {
          values.append("request_ide", eventValues.request_ide);
        }

        if (
          (NEW_PUBLISH_PAGE &&
            Object.keys(eventValues.event_location).length > 0) ||
          NEW_PUBLISH_PAGE !== true
        ) {
          const code = await geocodeByAddress(eventValues.event_location);
          const latLng = await getLatLng(code[0]);

          var location = {
            city: eventValues.event_location,
            lat: latLng.lat,
            lng: latLng.lng,
          };
          var locationUrl =
            "https://api.timezonedb.com/v2.1/get-time-zone?key=" +
            "8AOLVPPL26GV" +
            "&format=" +
            "json" +
            "&by=position&lat=" +
            location.lat +
            "&lng=" +
            location.lng;
          var locationn = await axios.get(locationUrl);
          var zoneName = locationn.data.zoneName;
        }
        var localTime;
        // New publish page uses UTC
        if (NEW_PUBLISH_PAGE) {
          console.log({ zoneName });
          if (zoneName) {
            localTime = momenttz.tz(eventValues.event_date, zoneName);
          } else {
            localTime = momenttz.utc(eventValues.event_date);
          }
        } else {
          localTime = momenttz.tz(eventValues.event_date, zoneName);
        }
        console.log({ localTime, ms: localTime.valueOf() });
        values.append("event_date", localTime.valueOf());

        if (massiveBroadcast) {
          values.append("event_type", EVENT_TYPE_BROADCAST);
          values.append(
            "event_ticket_type_streaming",
            eventValues.event_ticket_type
          );
          values.append(
            "event_num_attendees_streaming",
            eventValues.event_num_attendees
          );
          values.append(
            "event_price_ticket_streaming",
            parseFloat(eventValues.event_price_ticket)
          );
        } else {
          values.append("event_type", EVENT_TYPE_INTERACTIVE);
          if (NEW_PUBLISH_PAGE !== true) {
            values.append("event_ticket_type", eventValues.event_ticket_type);
          }
          values.append("event_num_attendees", eventValues.event_num_attendees);
          values.append(
            "event_price_ticket",
            parseFloat(eventValues.event_price_ticket)
          );
        }
        if (location) {
          values.append("event_location", JSON.stringify(location));
        }
        values.append("event_start_hour", eventValues.event_start_hour);

        values.append(
          "event_currency",
          parseFloat(eventValues.event_price_ticket) > 0
            ? eventValues.event_currency
            : "free"
        );

        if (eventValues.event_cover_image) {
          values.append("event_cover_image", eventValues.event_cover_image);
        } else {
          if (defaultImage) {
            values.append("event_cover_image", defaultImage);
          } else {
            throw new Error("Default image not initialized");
          }
        }

        if (!user.user_email) {
          var email = userEmail.trim().toLowerCase();
          values.append("email", email);
        }

        const env = JSON.parse(localStorage.getItem("env"));
        console.log("esto se enviaria private", values, eventValues, location,env );

        const response = await eventDAO.create(values, env.token);
        const { data } = response.data;
        console.log({ data });
        //if (!user.user_email) {
        await dispatch(actions.getUser(env.token));
        //}

        await dispatch(actions.getInfluencer(env.token));
        history.push(`/event/${data.event.event_uuid}`);


      } catch (err) {
        console.error(err, { err });
        if (err && err.response) {
          const { status, data } = err.response;
          if (status === 404) {
            setErrorForm(data.error.message);
          } else if (status === 400) {
            setErrorForm(data.error[0].message);
          }
        } else {
          setErrorForm("Sorry, something went wrong.");
        }
      } finally {
        setLoading(false);
      }
    } else {
      console.log("SUBMIT CALLED _______________________");
      e.preventDefault();

      console.log({ eventValues });
      console.log({ openTicket });
      if (loading) return;
      try {
        await schema.validate(eventValues);
      } catch (err) {
        setErrorForm(err.errors[0]);
        return;
      }

      if (!user.user_email) {
        try {
          if (!validateUserEmail()) {
            return;
          }
        } catch (err) {
          return;
        }
      }

      setLoading(true);
      try {
        const values = new FormData();
        if(B2B_PORT || PRIVATE_MARKETPLACE){
          values.append("event_platform_name", BRAND_NAME);
          values.append("event_platform_url", HOME_PAGE);
        }
        values.append("event_title", eventValues.event_title);
        values.append("event_description", eventValues.event_description);
        values.append(
          "event_category_ide",
          parseInt(eventValues.event_category_ide)
        );
        values.append(
          "event_lang_ides",
          JSON.stringify(eventValues.event_lang_ides)
        );
        values.append("event_tags", JSON.stringify(eventValues.event_tags));
        values.append("event_opening_ticketing", openTicket);
        values.append("public", publicEvent);

        if (eventValues.request_ide) {
          values.append("request_ide", eventValues.request_ide);
        }

        if (
          (NEW_PUBLISH_PAGE &&
            Object.keys(eventValues.event_location).length > 0) ||
          NEW_PUBLISH_PAGE !== true
        ) {
          const code = await geocodeByAddress(eventValues.event_location);
          const latLng = await getLatLng(code[0]);

          var location = {
            city: eventValues.event_location,
            lat: latLng.lat,
            lng: latLng.lng,
          };
          var locationUrl =
            "https://api.timezonedb.com/v2.1/get-time-zone?key=" +
            "8AOLVPPL26GV" +
            "&format=" +
            "json" +
            "&by=position&lat=" +
            location.lat +
            "&lng=" +
            location.lng;
          var locationn = await axios.get(locationUrl);
          var zoneName = locationn.data.zoneName;
        }
        var localTime;
        // New publish page uses UTC
        if (NEW_PUBLISH_PAGE) {
          console.log({ zoneName });
          if (zoneName) {
            localTime = momenttz.tz(eventValues.event_date, zoneName);
          } else {
            localTime = momenttz.utc(eventValues.event_date);
          }
        } else {
          localTime = momenttz.tz(eventValues.event_date, zoneName);
        }
        console.log({ localTime, ms: localTime.valueOf() });
        values.append("event_date", localTime.valueOf());

        if (massiveBroadcast) {
          values.append("event_type", EVENT_TYPE_BROADCAST);
          values.append(
            "event_ticket_type_streaming",
            eventValues.event_ticket_type
          );
          values.append(
            "event_num_attendees_streaming",
            eventValues.event_num_attendees
          );
          values.append(
            "event_price_ticket_streaming",
            parseFloat(eventValues.event_price_ticket)
          );
        } else {
          values.append("event_type", EVENT_TYPE_INTERACTIVE);
          if (NEW_PUBLISH_PAGE !== true) {
            values.append("event_ticket_type", eventValues.event_ticket_type);
          }
          values.append("event_num_attendees", eventValues.event_num_attendees);
          values.append(
            "event_price_ticket",
            parseFloat(eventValues.event_price_ticket)
          );
        }
        if (location) {
          values.append("event_location", JSON.stringify(location));
        }
        values.append("event_start_hour", eventValues.event_start_hour);
        if (eventValues.event_end_hour) {
          values.append("event_end_hour", eventValues.event_end_hour);
        }
        values.append(
          "event_currency",
          parseFloat(eventValues.event_price_ticket) > 0
            ? eventValues.event_currency
            : "free"
        );
        if (
          eventValues.event_promotional_code &&
          eventValues.event_promotional_code_discount
        ) {
          values.append(
            "event_promotional_code",
            eventValues.event_promotional_code
          );
          values.append(
            "event_promotional_code_discount",
            parseInt(eventValues.event_promotional_code_discount)
          );
        }
        if (eventValues.event_cover_image) {
          values.append("event_cover_image", eventValues.event_cover_image);
        } else {
          if (defaultImage) {
            values.append("event_cover_image", defaultImage);
          } else {
            throw new Error("Default image not initialized");
          }
        }

        if (refPresentationVideo) {
          values.append("event_presentation_video", refPresentationVideo);
        }
        if (refThanksVideo) {
          values.append("event_thanks_video", refThanksVideo);
        }
        /*
        values.append(
          "event_thanks_message",
          (document.getElementById("textarea_thankyou") &&
            document.getElementById("textarea_thankyou").value) ||
            ""
        );*/

        if (!user.user_email) {
          var email = userEmail.trim().toLowerCase();
          values.append("email", email);
        }

        const env = JSON.parse(localStorage.getItem("env"));
        console.log("esto se enviaria", values, eventValues, location,env );

        const response = await eventDAO.create(values, env.token);
        const { data } = response.data;
        console.log({ data });
        if (!user.user_email) {
          dispatch(actions.getUser(env.token));
        }

        dispatch(actions.getInfluencer(env.token));
        history.push(`/event/${data.event.event_uuid}`);


      } catch (err) {
        console.log({ err });
        if (err && err.response) {
          const { status, data } = err.response;
          if (status === 404) {
            setErrorForm(data.error.message);
          } else if (status === 400) {
            setErrorForm(data.error[0].message);
          }
        } else {
          setErrorForm("Sorry, something went wrong.");
        }
      } finally {
        setLoading(false);
      }
    }
  };
  return {
    massiveBroadcast,
    setMassiveBroadcast,
    openTicket,
    setOpenTicket,
    publicEvent,
    setPublicEvent,
    loadTicketTypes,
    errorForm,
    handleImageLoad,
    setErrorForm,
    categories: categories,
    loadedImg,
    eventValues,
    onChangeEventValues,
    setEventValues,
    handleImageChange,
    loadingImage,
    loading,
    onSubmit,
    initiaLoading,
    onSaveInDraft,
    handlePitchVideoChange,
    handleThanksVideoChange,
    refPresentationVideo,
    refThanksVideo,
    ticketTypes,
    onChangeUserEmail,
    onChangeConfirmUserEmail,
    userEmail,
    confirmUserEmail,
    initDefaultImage,
    defaultImage
  };
};
