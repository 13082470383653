import React, { useState, useEffect } from "react";
import logo from "assets/img/gotmy-n.png";
import "./style.scss";
import { WithAlert } from "../../hocs/withAlert";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import {schema} from './recoverSchema'
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import SweetAlert from "sweetalert2-react";

const initialFormValues = {
  password: "",
  confirmPassword: "",
};
 const RecoverPassword = ({showAlert,match: {params: { token }}, history, error, user, loading, onResetUserPassword }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [alert, setAlert] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  useEffect(() => {
    setTimeout(() => setModalVisible(true), 500);
  }, []);

  const onChangeFormValues = ({ target: { name, value }  }) => {
    setFormValues({...formValues,[name]: value});
  };

  const onSubmit = async () => {
    try {
      await schema.validate(formValues);
    } catch (err) {
      showAlert(err.errors[0]);
      return;
    }
    setAlert(true)
    await onResetUserPassword({user_reset_token: token, new_password: formValues.password})
  };
  const handleOnConfirm = ()=>{
      history.push('/')
  }
  return (
    <Modal show={modalVisible} className="Modales-login">
      <Modal.Body>
        <h6>Reset your password</h6>
        <img src={logo} className="Logo" alt="" />
       
        <div className="form-group">
          <label htmlFor="passwrod">Password</label>
          <input required  type="password"  className="form-control modal_register"
                name="password" placeholder="enter password" value={formValues.password}
                onChange={onChangeFormValues}/>
        </div>
        <div className="form-group">
          <label htmlFor="repeatpasswrod">Repeat password</label>
          <input required type="password" className="form-control modal_register"
                 name="confirmPassword"  placeholder="repeat password"
                 value={formValues.confirmPassword} onChange={onChangeFormValues}  />
        </div>

        <button className="Btn-rojo mt-1" onClick={onSubmit} disabled={loading}>
          {!loading ? "Submit" : "Loading..."}
        </button>
      </Modal.Body>
     
      <SweetAlert show={alert} title={ ((!user  ||  loading) && !error)
          ? "Verifying... Please wait" :
        (user && Object.keys(user).length>0 &&  !loading) ? "Password successfully recovered!":
        (error && error.message) ? error.message: ""
      }
      onConfirm={ handleOnConfirm }
    /> 
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
    return {
      onResetUserPassword: data => dispatch(actions.resetUserPassword(data)),
      onClearErrors: () => dispatch(actions.clearErrors()),
    };
  };
const mapStateToProps = state => {
    return {
      user: state.user.user,
      loading: state.user.loading,
      error: state.user.error
    };
  };
  
export default connect(mapStateToProps,mapDispatchToProps)
                (withRouter(WithAlert(withRouter(RecoverPassword))));

