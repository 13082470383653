import React from "react";
import "./style.scss";
import logo from "../../../assets/img/logo-circulo-white.png";
import { Link } from "react-router-dom";
import useMobileNav from "../../../hooks/useMobileNav"
import i18next from 'i18next';

const MenuRequest = ({ onSend }) => {
  const {mobileNav} = useMobileNav()
  return (
    <div className="FechaCat2">
      <div className="FechaCat13 d-flex align-items-center">
        <img src={logo} className="Logo5" alt="logo" />
       {!mobileNav &&  <h2 className="mb-0 title_request">{i18next.t("MenuRequestComponentRequestPrivateMeeting")}</h2>}
      </div>
      {/* <Link to={`/message/privated_request`}> */}
      <button className="request-streaming send_request" onClick={onSend}>
        {i18next.t("MenuRequestComponentSend")}
      </button>
      {/* </Link> */}
      <Link to={`/`}>
        <button className="request-streaming cancel">{i18next.t("MenuRequestComponentCancel")}</button>
      </Link>
    </div>
  );
};

export default MenuRequest;
