import React, { useState } from "react";
import logo from "assets/img/gotmy-n.png";
import "./style.scss";
import Delete from "assets/img/icons/ic-delete.svg";
import Sad from "assets/img/icons/sad-face-in-rounded-square.svg";
import { useModalLoginLogic } from "./ModalLoginLogic";
import SweetAlert from "sweetalert2-react";
import { withRouter } from "react-router-dom";
import styled from 'styled-components'
import i18next from 'i18next';

/**
 * @param {Object} history, React Router Object
 */
const ModalLogin = (props) => {
  const {
    loading,
    onSubmit,
    errorForm,
    formValues,
    setErrorForm,
    onChangeFormValues,
  } = useModalLoginLogic(props.history, props);
  const { email, confirmEmail } = formValues;
  
  return (
    <div className="modal Modales-login Modal-login" data-backdrop="static" id="instagramLoginModal">
      <div className="modal-dialog">
        <div className="modal-content">
          <img data-dismiss="modal" src={Delete} className="Cruz" alt="" />
          <div className="modal-body">
            <h6>{i18next.t("InstagramSignUpModalComponentWelcomeTo")}</h6>
            <img src={logo} className="Logo" alt="" />

            <h7 style={{
              textAlign: 'center',
              marginLeft: '5%',
              marginRight: '5%',
              marginBottom: '5%'
            }}>{i18next.t("InstagramSignUpModalComponentLastStepDescription")}</h7>
            <form noValidate onSubmit={onSubmit}>
              <div className="form-group">
                <label htmlFor="email">{i18next.t("InstagramSignUpModalComponentEmailAddress")}</label>
                <input
                  required
                  type="email"
                  className="form-control modal_register"
                  name="email"
                  placeholder={i18next.t("InstagramSignUpModalComponentEmailPlaceholder")}
                  value={email}
                  onChange={onChangeFormValues}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">{i18next.t("InstagramSignUpModalComponentConfirmEmailAddress")}</label>
                <input
                  required
                  type="email"
                  className="form-control modal_register"
                  name="confirmEmail"
                  placeholder={i18next.t("InstagramSignUpModalComponentEmailPlaceholder")}
                  value={confirmEmail}
                  onChange={onChangeFormValues}
                />
              </div>

              <button type="submit" className="Btn-rojo" disabled={loading}>
                {!loading ? i18next.t("InstagramSignUpModalComponentContinue") : i18next.t("InstagramSignUpModalComponentLoading") + "..."}
              </button>
            </form>
          </div>
        </div>
      </div>

      <SweetAlert
        imageUrl={Sad}
        show={errorForm}
        title={errorForm}
        onConfirm={() => setErrorForm("")}
      />
    </div>
  );
};

export default withRouter(ModalLogin);
const DownloadLink = styled.a`color: var(--color-primary-color);
/* display: block; */
cursor: pointer;
font-weight: 600;`