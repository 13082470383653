import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Loader from "react-loader-spinner";
import styles from '../../../assets/css_general/general.scss'

export default () => (
  <Container>
    <Row>
      <Col>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
            paddingBottom: "12.5vh",
            paddingTop: "12.5vh",
            flexDirection: "column",
          }}
        >
          <Loader
            type="Rings"
            color={styles.loaderColor}
            height={150}
            width={150}
            timeout={0} //3 secs
          />
        </div>
      </Col>
    </Row>
  </Container>
);
