import { UserDAO } from "daos";
import { LINKEDIN_CLIENTID, LOGIN_BACK_URL, FACEBOOK_CLIENTID, INSTAGRAM_CLIENTID } from "../environment/environment";
const userDAO = new UserDAO();

export const linkedinLogin = async (backUrl) => {
  let returnUrl = LOGIN_BACK_URL
  // let returnUrl =  LOGIN_BACK_URL
  localStorage.setItem("LinkedinAction", "verify");
  localStorage.setItem("redirectUrl", backUrl);
  window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENTID}&redirect_uri=${returnUrl}&scope=r_basicprofile,r_emailaddress,w_member_social,r_liteprofile&state=login`;
};
export const facebookLogin = async (backUrl) => {
  localStorage.setItem("redirectUrl", backUrl);
  let returnUrl = LOGIN_BACK_URL
  localStorage.setItem("facebookAction", "verify");
  window.location.href = `https://www.facebook.com/v8.0/dialog/oauth?client_id=${FACEBOOK_CLIENTID}&redirect_uri=${returnUrl}/facebook&state=login&auth_type=rerequest&scope=email`;
  // window.open (linkedinURL)
};

export const instagramLogin = async (backUrl) => {
  localStorage.setItem("redirectUrl", backUrl);
  let returnUrl = LOGIN_BACK_URL
  localStorage.setItem("facebookAction", "verify");
  
  window.location.href = `https://api.instagram.com/oauth/authorize?app_id=${INSTAGRAM_CLIENTID}&redirect_uri=${returnUrl}instagram&response_type=code&scope=user_profile`;
  // window.open (linkedinURL)
};

export const twitterLogin = async (backUrl) => {
  let returnUrl = LOGIN_BACK_URL
  localStorage.setItem("redirectUrl", backUrl);
  localStorage.setItem("TwitterAction", "verify");

  try {
    const res = await userDAO.requestTwitterToken();
    //   const res = await userDAO.requestTwitterToken(returnUrl);
    const token = res.data.oauth_token;
    localStorage.setItem("twitterAction", "verify");
    // TODO: move this url to constant
    window.location.href = "https://api.twitter.com/oauth/authenticate?oauth_token=" + token
  } catch (err) {


  }
};