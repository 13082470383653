import React from 'react'
import i18next from 'i18next';

 const ChooseCategory = ({onChangeEventValues, categories}) => {
    return (
        <div style={{ width: '50%' }} className="mr-2 ">
        <p className="mb-1 small_grey">{i18next.t('LiveEventInformationChooseCategory')}</p>
        <select className="select_category" name="event_category_ide" onChange={onChangeEventValues}>
          {categories.map(category => {
            return (
              <option id={category.cate_ide} value={category.cate_ide} key={category.cate_ide}>
                {category.cate_description}
              </option>
            )
          }
          )}
        </select>
      </div>
    )
}
export default ChooseCategory