import React,  { useState, useEffect, useCallback } from 'react'
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { withRouter } from "react-router-dom";
import SweetAlert from "sweetalert2-react";

 const ConfirmEmail = ({ match, history, onConfirmUser, onClearErrors, user, loading, error }) => {
     
  const [showAlert, setShowAlert] = useState(true);

  const verifyToken = useCallback(async (token) => {
      
      await onConfirmUser(token)
  });

  useEffect(()=> {
    if (match.params.token){
        verifyToken(match.params.token)
    }
  },[match.params.token])

  const handleOnConfirm = ( )=> {
     if (!loading && user) {
        onClearErrors()
        setShowAlert(false);
        history.push("/");
      }
  }
  return(
    <SweetAlert show={showAlert} title={ ((!user  ||  loading) && !error)
          ? "Verifying... Please wait" :
        (user && Object.keys(user).length>0 &&  !loading) ? "Email successfully verified":
        (error && error.message) ? error.message: ""
      }
      onConfirm={ handleOnConfirm }
    />
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onConfirmUser: token => dispatch(actions.confirmUser(token)),
    onClearErrors: () => dispatch(actions.clearErrors()),
  };
};
const mapStateToProps = state => {
    return {
      user: state.user.user,
      loading: state.user.loading,
      error: state.user.error
    };
  };
  
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConfirmEmail));

