export default {
  //Open Graph Header
  GotmyLandingHeaderDescription:
    "Mercado de eventos de video en vivo. Compra y vende acceso a videos y eventos en línea en vivo",
  GotmyLandingHeaderoOGTitle:
    "gotmy | Plataforma de eventos interactivos de video en vivo",

  //Home Header
  GotmyHomeHeaderTitle: "Plataforma de eventos interactivos de video en vivo",
  GotmyHomeHeaderDescription:
    "Compra y vende acceso a videos y eventos en línea en vivo",
  GotmyHomeHeaderTrenginsSearches: "Lo más buscado",
  GotmyHomeHeaderSeachBarPlaceholder:
    "Busca el nombre del influencer, evento en vivo, categoría, profesión, habilidad ...",

  //Steps component
  StepComponentYouAreIn: "Estás dentro",
  StepComponentTalkFaceToFaceWith:
    "Habla cara a cara con cualquier persona en línea, ya sea en una videoconferencia privada o en un evento grupal en vivo",
  StepComponentStepOneTitle: "Empieza",
  StepComponentStepOneDescription:
    "Registra tu cuenta en gotmy y descubre increíbles eventos de video en vivo",
  StepComponentStepTwoTitle: "Ten todo el acceso",
  StepComponentStepTwoDescription:
    "Reserva un evento con un influencer, personas profesionales y capacitadas después de ver una vista previa de su evento grupal en vivo programado o trás solicitar una reunión privada, donde controlas el contenido, la asistencia, el tiempo, la agenda y el precio del ticket.",
  StepComponentStepThreeTitle: "Consigue su atención",
  StepComponentStepThreeDescription:
    "Interactúa en vivo, directamente con el influencer, y con profesionales capacitados en un espacio donde se escuchará tu voz",

  //LiveEvents Component
  LiveEventsComponentLiveOnlineEvents: "Eventos en vivo",
  LiveEventsComponentShowAll: "Mostrar todo",

  //Nav Component
  NavBarComponentBlog: "Blog",

  //Nav Viewer Component
  MenuViewerComponentDiscover: "Descubrir",
  MenuViewerComponentSearch: "Buscar",
  MenuViewerComponentLiveEvent: "Mis eventos",
  MenuViewerComponentRequests: "Solicitudes",
  MenuViewerComponentActivity: "Actividad",

  //MovileNav Component
  MobileNavBarComponentBlog: "Blog",

  //Login Menu Component
  LoginMenuComponentBecomeInfluencer: "Hazte influencer",
  LoginMenuComponentProcessingInfluencerRequest:
    "Procesando tu solicitud de influencer",
  LoginMenuComponentGoToInfluencerMode: "Ir a modo influencer",
  LoginMenuComponentViewerMode: "Modo Viewer",
  LoginMenuComponentMyViewerProfile: "Mi perfil viewer",
  LoginMenuComponentEditProfile: "Editar Perfil",
  LoginMenuComponentBilling: "Facturación",
  LoginMenuComponentPaymentMethods: "Métodos de pago",
  LoginMenuComponentSettings: "Ajustes",
  LoginMenuComponentHelp: "Ayuda",
  LoginMenuComponentLogout: "Cerrar sesión",
  LoginMenuComponentLoginAsInfluencer: "Iniciar sesión como influencer",
  LoginMenuComponentMobileLogin: "Iniciar sesión",
  LoginMenuComponentMobileSignup: "Crear cuenta",

  //Upcoming Live Events Component
  UpcomingLiveEventsComponentUpcomingLiveEvents: "Próximos eventos",
  UpcomingLiveEventsComponentShowAll: "Mostrar todo",
  UpcomingLiveEventsComponentNoEventsInThisCategoryMessage:
    "No hay eventos para esta categoría aún",

  //Card Upcoming Component
  CardUpcomingComponentOnly: "Solo",
  CardUpcomingComponentTicketsFree: "tickets libres",
  CardUpcomingComponentLiveFrom: "En vivo desde",
  CardUpcomingComponentLiveAt: "a las",

  //Event Card Component
  EventCardComponentStarts: "Empieza",
  EventCardComponentTicketsLeft: "Tickets disponibles",
  EventCardComponentSoldOut: "Agotado",
  EventCardComponentLiveAt: "En vivo a las",

  //Event Happening Card Component
  EventHappeningCardComponentStartsIn: "Comienza en",
  EventHappeningCardComponentStartsInAFewMinutes: "Comienza en unos minutos",
  EventHappeningCardComponentHour: "hora",
  EventHappeningCardComponentHours: "horas",

  //Discover By Category Component
  DiscoverByCategoryComponentShowAll: "Mostrar todo",

  //VideosSeccion Component
  VideosSeccionComponentPastEvents: "Eventos pasados",
  VideosSeccionComponentShowAll: "Mostrar todo",

  //Discover Component
  DiscoverComponentDiscoverByCategory: "Descubre por categoría",

  //Navbar Search Component
  NavbarSearchComponentTrengindSearches: "Lo más buscado",

  //Search Input Peq Component
  SearchInputPeqComponentSearchPlaceholder: "Buscar",

  //Tags Search Component
  TagsSearchComponentTrendingTags: "Tags más buscadas",

  //Search Component
  SearchComponentTrendingInfluencers: "Influencers más puntuados",
  SearchComponentCategories: "Categorías",

  //Influencer Search Component
  InfluencerSearchComponentShowAll: "Mostrar todo",

  //AllUpcomingLiveEvents Component
  AllUpcomingLiveEventsComponentBackToSearch: "Volver",

  //Filtro Component
  FiltroComponentSearchPlaceholder: "Buscar",
  FiltroComponentSearchNoOptionsFound: "No hay opciones",

  //SearchByCategory Component
  SearchByCategoryComponentBackToSearch: "Volver",
  SearchByCategoryComponentTrendingInfluencers: "Influencers más puntuados",

  //Footer Component
  FooterComponentGotmyCompName: "Got My Idol,Inc.",
  FooterComponentAllRightsReserved: "Todos los derechos reservados",
  FooterComponentTermsOfService: "Términos de servicio",
  FooterComponentPrivacyPolicy: "Política de privacidad",
  FooterComponentContact: "Contacto",
  FooterComponentInvestors: "Inversores",
  FooterComponentBlog: "Blog",
  FooterComponentDownloadAppTitle: "Descarga la app móvil",
  FooterComponentDownloadAppDescription:
    "Compra y vende acceso a videos y eventos en línea en vivo",

  //Mobile Footer Component
  MobileFooterComponentDownloadAppTitle: "Descarga la app móvil",
  MobileFooterComponentDescription:
    "Compra y vende acceso a videos y eventos en línea en vivo",
  MobileFooterComponentTermsOfService: "Términos de servicio",
  MobileFooterComponentPrivacyPolicy: "Política de privacidad",
  MobileFooterComponentContact: "Contacto",
  MobileFooterComponentInvestors: "Inversores",
  MobileFooterComponentBlog: "Blogs",
  MobileFooterComponentGotmyCopyright:
    "Got My Idol,Inc. Todos los derechos reservados",

  //Modal Login, Modal Register, Modal Basic and Modal Reset Password Component
  ModalLoginComponentWelcomeTo: "Bienvenido a",
  ModalLoginComponentEmailAddress: "Email",
  ModalLoginComponentEmailPlaceholder: "Email",
  ModalLoginComponentPassword: "Contraseña",
  ModalLoginComponentPasswordPlaceholder: "Contraseña",
  ModalLoginComponentCotinue: "Continuar",
  ModalLoginComponentLoading: "Cargando",
  ModalLoginComponentRecoverPassword: "Recuperar contraseña",
  ModalLoginComponentOrSignInWith: "O conéctate con",
  ModalLoginComponentAreYouNotRegistered: "¿No estás registrado?",
  ModalLoginComponentSignUp: "Crear cuenta",
  ModalLoginComponentDownloadOurAppOnThe: "Descarga nuestra app en",
  ModalLoginComponentAppStore: "App store",
  ModalLoginComponentOr: "o",
  ModalLoginComponentToRegisterAndEnjoyOurLiveStreams:
    "crea tu cuenta y disfruta todos los eventos en vivo",
  ModalLoginComponentRepeatPassword: "Repetir contraseña",
  ModalLoginComponentOrSignUpWith: "O crea una cuenta con",
  ModalLoginComponentAreYouRegistered: "¿Estás registrado?",
  ModalLoginComponentLogIn: "Iniciar sesión",
  ModalLoginComponentVerifyYourEmailAddress: "Verifica tu email",
  ModalLoginComponentVerifyYourEmailAddressDescription:
    "Hemos enviado un enlace de verificación a tu email.",
  ModalLoginComponentLetsCompleteYourProfile:
    "¡Solo un par más de datos para completar tu perfil!",

  //Modal Login Logic, Modal Register Logic and Modal Reset Password Component
  ModalLoginLogicComponentPasswordRequiredError: "Contraseña requerida",
  ModalLoginLogicComponentPasswordFormatError:
    "La contraseña debe tener al menos 8 caracteres, 1 mayúscula, 1 minúscula y 1 número",
  ModalLoginLogicComponenEmailFormatErrorMessage: "El email debe ser válido",
  ModalLoginLogicComponentEmailRequired: "Email requerido",
  ModalLoginLogicComponentSomethingWentWrong: "Lo sentimos, algo ha ido mal.",
  ModalLoginLogicComponentPasswordMustMatchError:
    "Las contraseñas deben coincidir",
  ModalLoginLogicComponentRepeatPasswordRequired:
    "Repetir contraseña requerido",
  ModalLoginLogicComponentPasswordReestablishedSuccessfully:
    "Contraseña restablecida con éxito",
  ModalLoginLogicComponentUserNotFound: "Usuario no encontrado",
  ModalLoginLogicComponentIncorrectResetPasswordToken:
    "Token de para restablecer contraseña incorrecto",
  ModalLoginLogicComponentNameIsRequired: "Nombre requerido",
  ModalLoginLogicComponentSurnameRequired: "Apellidos requeridos",
  ModalLoginLogicComponentBirthDateRequired: "Fecha de nacimiento requerido",
  ModalLoginLogicComponentUsernameRequired: "Nombre de usuario requerido",

  //Form Input tags and placeholders
  FormInputTagName: "Nombre",
  FormInputTagSurname: "Apellidos",
  FormInputTagBirthDate: "Fecha de nacimiento",
  FormInputTagUsername: "Nombre de usuario",
  FormInputPlaceholderName: "Nombre",
  FormInputPlaceholderSurname: "Apellidos",
  FormInputPlaceholderUsername: "Nombre de usuario",

  //Event Page Component
  EventPageComponentErrorProcessingEventPayment:
    "Error procesando el pago del evento",
  EventPageComponentGoBack: "Volver",
  EventPageComponentBuyTicketFor: "Comprar ticket por",
  EventPageComponentBuyTicketForFree: "Regístrate Gratis",
  EventPageComponentSorryEventSoldOut: "Lo sentimos, el evento esta agotado",
  EventPageComponentLiveEventDescription: "Descripción del evento",
  EventPageComponentLanguage: "Idioma",
  EventPageComponentRelatedTags: "Tags relacionados",
  EventPageComponentShareLiveEvent: "Comparte el evento",

  //Nombre Cat Fecha Component
  NombreCatFechaComponentLiveAt: "En vivo a las",
  NombreCatFechaComponentPlayedAt: "Reproducido a las",

  //Attendees Event Component
  AttendeesEventComponentAttendee: "Asistentes",

  // Influencer payout methods
  InfluencerPayoutBindedMethods:
    "Estos son los métodos que has añadido para transferirte tus ganancias",
  InfluencerPayoutYourPayoutMethods: "Tus métodos de cash out",
  InfluencerPayoutTypeAccount: "Tipo de cuenta",
  InfluencerPayoutChangeAccount: "Cambia el tipo de cuenta",
  InfluencerPayoutLinkAccount: "Enlaza una cuenta",
  InfluencerPayoutLinkPaypal: "Linkea tu cuenta de paypal",
  InfluencerPayoutPaypalExplanation:
    "Para mandarte tus ganancias necesitamos el email de tu cuenta de paypal usada. Por favor, asegúrate de que tu dirección es correcta. ",
  InfluencerPayoutPaypalExtraExpla:
    "Paypal payout permite linkar una cuenta Paypal para recibir pagos ",
  InfluencerPayoutContinue: "Continúa",
  InfluencerPayoutExpressExplanation:
    "El método express permite linkar una cuenta de USA o una tarjeta de débito internacional para recibir pagos ",
  InfluencerPayoutNeedPaymentMethod:
    "Necesitamos un método de pago para transferirte tus ganancias",
  InfluencerPayoutSetupPayoutMethod:
    "Activa tu método preferido de retirada de ganancias",

  //Begin in Component
  BeginInComponentStreaming: "La retransmisión",
  BeginInComponentBegan: "comenzó",
  BeginInComponentBegins: "comienza en",
  BeginInComponentStartStreaming: "Empezar retransmisión",
  BeginInComponentViewStreaming: "Unirse a la retransmisión",

  //Modal Buy Event Component
  ModalBuyEventComponentBuyTicket: "Comprar Ticket",
  ModalBuyEventComponentEventPrice: "Precio",
  ModalBuyEventComponentFree: "Gratis",
  ModalBuyEventComponentGotMyFees: "Tarifa gotmy",
  ModalBuyEventComponentPaymentMethod: "Métodos de pago",
  ModalBuyEventComponentEventTicket: "Ticket",

  //Checkout Component
  CheckoutComponentEnterEmailAddress: "Introduce tu email",
  CheckoutComponentConfirmEmailAddress: "Confirma tu email",
  CheckoutComponentPromoCode: "Código de promoción",
  CheckoutComponentEnterYourPromoCode: "Introduce tu código de promoción",
  CheckoutComponentIAgreeWithThe: "Estoy de acuerdo con",
  CheckoutComponentPrivacyPolicy: "la Política de Privacidad",
  CheckoutComponentTermsOfService: "los Términos de Servicio",
  CheckoutComponentCardPaymentsAreProcessedByStripeDescription:
    "Los pagos con tarjeta están procesados por Stripe. gotmy no almacena ni procesa ninguno de tus datos bancarios.",
  CheckoutComponentBuyTicketForFree: "Regístrate Gratis",
  CheckoutComponentPay: "Pagar",
  CheckoutComponentProcessingYourPayment: "Procesando tu pago",

  //UseBuyEvent Component
  UseBuyEventComponentErrorProcessingEventPayment: "Error procesandop tu pago",
  UseBuyEventComponentAcceptTemsError:
    "Debes aceptar la Política de Privacidad y los Términos de Servicio",
  UseBuyEventComponentPleaseEnterYourEmailAddress:
    "Por favor, introduce tu dirección de email",
  UseBuyEventComponentEmailsShouldMatch:
    "El campo email y la confirmación del email deben coincidir",
  UseBuyEventComponentTitleRequired: "El título es obligatorio",
  UseBuyEventComponentCoverRequired: "La imagen del evento es obligatorio",
  UseBuyEventComponentDescriptionRequired: "La descripción es obligatoria",
  UseBuyEventComponentCategoryRequired: "La categoría es obligatoria",
  UseBuyEventComponentLanguageRequired: "El idioma es obligatorio",
  UseBuyEventComponentDateRequired: "La fecha es obligatoria",
  UseBuyEventComponentStartHourRequired: "La hora de comienzo es obligatoria",
  UseBuyEventComponentEndHourRequired: "La hora de fin es obligatoria",
  UseBuyEventComponentInvalidNumberOfTickets: "Número de tickets inválido",
  UseBuyEventComponentInvalidPrice: "Precio de los tickets inválido",
  UseBuyEventComponentNumberOfTicketsRequired:
    "El número de tickets es obligatorio",
  UseBuyEventComponentPriceTicketsRequired:
    "El precio por ticket es obligatorio",

  //NavBarLiveEvents Component
  NavBarLiveEventsComponentUpcoming: "Próximos",
  NavBarLiveEventsComponentPastEvents: "Pasados",
  NavBarLiveEventsComponentFavorites: "Favoritos",

  //Referral Landing page
  ReferralHeader: "EMPEZAR ES FÁCIL",
  ReferralSubHeader:
    "¡Monetiza tu experiencia y tus habilidades creando de forma GRATUITA tus eventos interactivos!",
  ReferralGetStarted: "ÚNETE",
  ReferralGetStartedSubheading: "Crea tu perfil y tu primer evento.",
  ReferralGetTheirAttention: "COMPARTE",
  ReferralGetTheirAttentionSubheading:
    "Compártelos con tus seguidores y contactos. Nosotros te ayudamos con la estrategia y el proceso de venta.",
  ReferralGetAccess: "CONECTA E INTERACTÚA",
  ReferralGetAccessSubheading:
    "Disfruta de interactuar en vivo con tu audiencia o con gente interesada en tu contenido, y cobra por ello.",
  ReferralCTAfirstLine: "¡Monetiza tu talento!",
  ReferralCTASecondLine: "¡Monetiza tu talento!",
  ReferralCTA: "HAZTE INFLUENCER",
  ReferralSignUpFacebook: "Registro con Facebook",
  ReferralSignUpInstagram: "Registro con Instagram",
  ReferralSignUpLinkedin: "Registro con LinkedIn",
  ReferralSignUpTwitter: "Registro con Twitter",
  //UpcomingLiveEventsPage Component
  UpcomingLiveEventsPageComponentYouHaveNotPurschasedAnyLiveEventYet:
    "Aún no has comprado ningún evento en vivo",
  UpcomingLiveEventsPageComponentUpcomingLiveEvents: "Próximos eventos en vivo",

  //NoEvents Component
  NoEventsComponentDiscoverNewEvents: "Descubre otros eventos",

  //PastLiveEvents Component
  PastLiveEventsComponentPastLiveEvents: "Eventos Pasados",
  PastLiveEventsComponentThereareNoPastEventsYet: "Aún no hay eventos pasados",

  //FavoriteLiveEvents Component
  FavoriteLiveEventsComponentFavoriteLiveEvents: "Favoritos",
  FavoriteLiveEventsComponentYouHaveNotAddedMyFavouriteEventYet:
    "Aún no has agregado ningún evento favorito",

  //ModalSuccessEventBought Component
  ModalSuccessEventBoughtComponentThankYouForBooking:
    "¡Muchas gracias por reservar este evento en vivo!",
  ModalSuccessEventBoughtComponentYouAreAmazing: "¡Eres increíble!",
  ModalSuccessEventBoughtComponentDownloadAppMessage:
    "¡No olvides descargar gotmy en App Store o Google Play para asistir a mi evento en vivo!",
  ModalSuccessEventBoughtComponentGoToMyLiveEvents: "Ir a mis eventos en vivo",
  ModalSuccessEventBoughtComponentDiscoverMoreEvents: "Descubre más eventos",
  ModalSuccessEventBoughtComponentWeSentConfirmationEmailTo:
    "Le hemos enviado un correo electrónico de confirmación a",
  ModalSuccessEventBoughtComponentQuestionsRegardingYourEvent:
    "¿Tienes preguntas sobre tu evento? Contáctanos en",

  //ModalRecoverPassword Component
  ModalRecoverPasswordComponentRecoverYourPassoword: "Recupera tu contraseña",
  ModalRecoverPasswordComponentEmailAddress: "Dirección de email",
  ModalRecoverPasswordComponentEmailAddresPlaceholder: "Email",
  ModalRecoverPasswordComponentResetMyPassword: "Resetear contraseña",
  ModalRecoverPasswordComponentLoading: "Cargando",
  ModalRecoverPasswordComponentAreYouRegistered: "¿No estás registrado?",
  ModalRecoverPasswordComponentSignUp: "Crear cuenta",

  //MyViewerPaymentMethods Component
  MyViewerPaymentMethodsComponentHowDoYouWantToPay: "¿Cómo quieres pagar?",
  MyViewerPaymentMethodsComponentWeNeedAPaymentMethodToProcessYourPayments:
    "Necesitamos un método de pago para procesar tus pagos",
  MyViewerPaymentMethodsComponentAddCreditCard: "Añadir tarjeta de crédito",
  MyViewerPaymentMethodsComponentRemoveCreditCard: "Borrar  tarjeta de crédito",
  MyViewerPaymentMethodsComponentTheresBeenAnError: "Ha habido un error",
  MyViewerPaymentMethodsComponentCardName: "Nombre",
  MyViewerPaymentMethodsComponentCardNumber: "Número de tarjeta",
  MyViewerPaymentMethodsComponentExpierDate: "Fecha de caducidad",
  MyViewerPaymentMethodsComponentCVC: "CVC",
  MyViewerPaymentMethodsComponentSaveChanges: "Guardar cambios",
  MyViewerPaymentMethodsComponentDoYouWantToRemoveThisCard:
    "¿Quieres borrar esta tarjeta?",
  MyViewerPaymentMethodsComponentAreYouSureYouWantToRemoveThisCard:
    "¿Estás seguro de que deseas borrar esta tarjeta de tu cuenta?",
  MyViewerPaymentMethodsComponentYouWouldHaveToAddItAgainForFuturePayments:
    "Tendrás que agregarla nuevamente para pagos futuros.",
  MyViewerPaymentMethodsComponentYesIWantToRemoveIt:
    "Sí, quiero borrarla de mi perfil.",

  //Profile Component
  ProfileComponentAboutMe: "Sobre mi",
  ProfileComponentLanguages: "Idiomas",
  ProfileComponentShowAll: "Mostrar todo",
  ProfileComponentUpcomingLiveEvents: "Próximos eventos en vivo",
  ProfileComponentYouHaveNotAttendedAnyLiveEventYet:
    "Aún no has asistido a ningún evento en vivo",

  //ProfileInfluencer Component
  ProfileInfluencerComponentDownloadOurAppToRequestPrivate:
    "¡Descarga nuestra App para solicitar reuniones privadas!",
  ProfileInfluencerComponentPrivateRequestRequireLocation:
    "Para solicitar reuniones privadas, primero configure la ubicación de su perfil.",
  ProfileInfluencerComponentFollowers: "Seguidores",
  ProfileInfluencerComponentFollowing: "Siguiendo",
  ProfileInfluencerComponentFollow: "Seguir",
  ProfileInfluencerComponentSkills: "Habilidades",
  ProfileInfluencerComponentRequestPrivateMeeting: "Solicitar reunión privada",
  ProfileInfluencerComponentLanguages: "Idiomas",
  ProfileInfluencerComponentFollowMeOn: "Sigueme en",
  ProfileInfluencerComponentAboutMe: "Sobre mi",
  ProfileInfluencerComponentMoreAboutMe: "Más sobre mi",
  ProfileInfluencerComponentLocation: "Ubicación",
  ProfileInfluencerComponentTimeZone: "Zona horaria",
  ProfileInfluencerComponentUpcomingLiveEvents: "Próximos eventos en vivo",
  ProfileInfluencerComponentPrivateRequestSlot: "Eventos privados",
  ProfileInfluencerComponentsSubscribeInfluencer: "Suscribirse",
  ProfileInfluencerComponentNoContent: "Sin contenido",

  //RatingPoints Component
  RatingPointsComponentBasedOn: "Basado en",
  RatingPointsComponentReviews: "opiniones",

  //RequestPrivate Component
  RequestPrivateComponentPrivateRequestTitle: "Solicitud privada",
  RequestPrivateComponentRequestSent: "¡Solicitud enviada!",
  RequestPrivateComponentErrorSendingRequest:
    "Lo sentimos, hubo un error con tu solicitud.",

  //MenuRequest Component
  MenuRequestComponentRequestPrivateMeeting: "Solicitar reunión privada",
  MenuRequestComponentSend: "Enviar",
  MenuRequestComponentCancel: "Cancelar",

  //SelectMeetingType Component
  SelectMeetingTypeComponentTitle: "¿Qué tipo de evento privado quieres?",

  // Influencer Menu
  InfluencerMenuMyEvents: "Mis Eventos",
  InfluencerMenuMyStats: "Mis Estadísticas",
  InfluencerMenuPrivateRequests: "Peticiones Privadas",
  InfluencerMenuActivity: "Actividad",

  // Influencer Dropdown
  InfluencerDropdownMenuInfluencerMode: "Modo Influencer",
  InfluencerDropdownMenuMyProfile: "Mi Menú de Influencer",
  InfluencerDropdownMenuEdit: "Editar Perfil",
  InfluencerDropdownMenuMyLiveEvents: "Mis Eventos en Directo",
  InfluencerDropdownMenuMyBalance: "Mi Balance",
  InfluencerDropdownMenuMyPayoutMethods: "Mis Métodos de Pago",
  InfluencerDropdownMenuBilling: "Billing",
  InfluencerDropdownMenuSettings: "Ajustes",
  InfluencerDropdownMenuHelp: "Ayuda",
  InfluencerDropdownGoViewer: "Ir a modo Viewer",
  InfluencerDropdownLogout: "Log Out",

  // Influencer Profile
  InfluencerProfileFollowers: "Seguidores",
  InfluencerProfileFollowing: "Siguiendo",
  InfluencerProfileSkills: "Skills",
  InfluencerProfileLanguages: "Idiomas",
  InfluencerProfileFollowMe: "Sígueme en",
  InfluencerProfileAboutMe: "Acerca de mí",
  InfluencerProfileUpcoming: "Próximos eventos en vivo",

  // Influencer My Live Events
  InfluencerMyLiveEventsUpcoming: "Mis próximos eventos en vivo",
  InfluencerMyLiveEventsPublish: "Publicar evento en vivo",
  InfluencerMyLiveEventsNoEventYet: "Aún no tienes ningún evento.",
  InfluencerMyLiveEventsPublishNavLoading: "Cargando",
  InfluencerMyLiveEventsPublishNavPublish: "Publicar",
  InfluencerMyLiveEventsPublishNavCancel: "Cancelar",
  InfluencerMyLiveEventsPublishNavPublishLiveEvent: "Publicar evento en vivo",

  // Influencer Publish Event Menu
  InfluencerPublishEventMenuInfo: "Información del Evento",
  InfluencerPublishEventMenuPitch: "Vídeo promocional",
  InfluencerPublishEventMenuThanks: "Vídeo de agradecimiento",

  // Influencer Happening Events
  InfluencerHappeningEvents: "Mis eventos activos",
  InfluencerMyLiveEventsNoEventYet: "No tienes eventos en vivo",

  InfluencerPublishSeeEvent: "Ver Evento",
  InfluencerPublishPublishEvent: "Publicar",
  InfluencerPublishMenuPublishEvent: "Publicar evento",
  InfluencerPublishPreviewYourEvent: "Previsualiza tu evento",
  InfluencerPublishCancel: "Cancelar",
  EventPreviewComponentDescriptionPlaceholder: "<La descripción de tu evento>",
  EventPreviewComponentTitlePlaceholder: "<El título de tu evento>",

  //  LiveEventInformation Component
  LiveEventInformationStreamType: "Tipo de streaming",
  LiveEventInformationShowImage:
    "Esta imágen saldrá como pordada de tu evento en vivo",
  LiveEventInformationCoverImage: "Foto de portada",
  LiveEventInformationDescription: "Descripción de evento en vivo",
  LiveEventInformationChooseCategory: "Elige categoría",
  LiveEventInformationSelectEventType: "Selecciona tipo de evento",
  LiveEventInformationEventTitleCharLeftPart1: "Título del evento, tienes ",
  LiveEventInformationEventTitleCharLeftPart2: "caracteres más",
  LiveEventInformationEventYouHave: "Tienes ",
  LiveEventInformationEventYouHaveCharsLeft:
    "caracteres más. Puedes añadir links externos usando http://  ",
  LiveEventInformationChooseLanguages: "Elige los idiomas del evento ",
  LiveEventInformationRelatedTags: "Elige los tags relacionados ",
  LiveEventInformationMaxTags: "5 tags como máximo! ",
  LiveEventInformationAddTags: "Añade los tags aquí ",
  LiveEventInformationLiveEventInformation: "Información de tu evento",

  LiveEventInformationDateAndTime: "Fecha y hora ",
  LiveEventInformationEventDate: "Fecha de evento",
  LiveEventInformationLocation: "Ubicación",
  LiveEventInformationStartTime: "Hora de inicio",
  LiveEventInformationEndTime: "Hora de fin",
  LiveEventInformationTicketing: "Ticketing",
  LiveEventInformationCurrency: "Moneda",
  LiveEventInformationTicketNumber: "Número de tickets",
  LiveEventInformationPublicEvent: "Evento público",
  LiveEventInformationShowInMarketPlace: "Mostrar el evento en el marketplace",
  LiveEventInformationOpenTicketing: "Tickets abiertos ",
  LiveEventInformationOpenTicketingExplanation:
    "Vende tickets tras haber comenzado el evento",
  LiveEventInformationPricePerTicket: "Precio por ticket",
  LiveEventInformationGrossRevenue: "Recaudación en bruto (tickets agotados)",
  LiveEventInformationTicketingFees: "Gastos administrativos",
  LiveEventInformationTicketingFeesNoFees:
    "No hay gastos administrativos asociados con este evento",
  LiveEventInformationTicketingFeesChoosePrice: "Elige el precio",
  LiveEventInformationPromoCode: "Promo Code",
  LiveEventInformationPromoCodeDescription:
    "Los influencers pueden crear un código promocional y seleccionar el % de descuento a asignar a ese código a compartir con los viewers",
  LiveEventInformationDiscountPerc: "Elige un % de descuento",
  LiveEventInformationPlaceholderEventTitle: "Nombre de tu evento",
  LiveEventInformationPlaceholderEventDescription: "Descripción de tu evento",
  LiveEventInformationPlaceholderPricePerTicket: "Precio por ticket ",
  LiveEventInformationPlaceholderEditCropper: "Recorta tu imagen",
  ModalCropComponentUploadPhoto: "Selecciona otro archivo",
  ModalCropComponentSave: "Guardar",
  LiveEventInformationLoadingImage: "Cargando tu imagen",
  LiveEventInformationUseOurThumbnail:
    "¿Prefieres usar una de nuestras miniaturas?",
  LiveEventInformationUseOurThumbnailSelect: "Seleccionar miniatura",
  LiveEventInformationUseOurThumbnailSelectImageThumbnail: "Usar esta imagen",

  //  ErrorNotFound Component
  ErrorNotFoundMessage:
    "No hemos podido cargar esta página, pero esperamos solucionarlo en breve. Prueba a volver al punto anterior o recargar la pagina",
  ErrorNotFoundTitle: "Contenido no encontrado",

  // My past live events component
  PastLiveEventsMyPast: "Mis eventos en vivo finalizados",
  PastLiveEventsMyPastNoEventsYet: "No tienes eventos pasados.",
  // MyPublicProfile Component
  MyPublicProfilePublicProfile: "Mi perfil público",
  MyPublicProfileFavCat: "Mis categorías favoritas",

  // MyPublicProfileInfluencer Component
  MyPublicProfileInfluencerPublicInfo: "Información pública",
  MyPublicProfileInfluencerAddCategories: "Añade dos categorías",
  MyPublicProfileInfluencerAddCategoriesDescription:
    "Para ser descubierto en gotmy, te recomendamos que elijas una o dos categorías principales",
  MyPublicProfileInfluencerAddCategoriesAdd: "Añadir categoría",

  // PublicInfoTab Component
  PublicInfoTabPublicInfo: "Información pública",

  PublicInfoTabFirstName: "Nombre",
  PublicInfoTabLastName: "Apellido",
  PublicInfoTabChooseUsername: "Elige tu nombre de usuario",
  PublicInfoTabLocation: "Dirección",
  PublicInfoTabBday: "Fecha de nacimiento (privado)",
  PublicInfoTabChooseYourLanguage: "Elige tu idioma",
  PublicInfoTabBiography: "Biografía",
  PublicInfoTabCoverVideo: "Video de presentación",
  PublicInfoTabCurrentCoverVideo: "Video actual",
  PublicInfoTabSelectAVideoFile: "Selecciona un video",

  //AvailableBoxComponent
  AvailableBoxComponentAreYouAvailable:
    "Estás disponible para meetings privados?",
  AvailableBoxComponentAdvice:
    "Si estás activo, los viewers pueden mandarte peticiones para tener meetings privados en directo",
  AvailableBoxComponentAvailable: "Estoy disponible ",
  AvailableBoxComponentUnavailable: "No estoy disponible",

  //SocialConnectBox
  SocialConnectBoxConnect: "Conectar tus redes sociales",
  SocialConnectBoxConnectDescription:
    "Contecta tus redes sociales para compartir links y tu actividad",
  SocialConnectBoxConnectActivated: "Activo",
  SocialConnectBoxConnectConnect: "Conecta",

  //NavbarStats
  NavbarStatsOverview: "Resúmen",
  NavbarStatsLive: "Estadísticas de eventos en vivo",
  NavbarStatsRatings: "Puntuación y reseñas",

  //StatsCardComp
  StatsCardTotal: "Total Eventos en Vivo",
  StatsCardTotalSales: "Ventas Totales",
  StatsCardTotalIncrease: "% INCREMENTO",
  StatsCardTotalDecrease: "% DECREMENTO",
  StatsCardTotalChange: "% CAMBIO",
  StatsCardTotalTotalBalance: "Balance Total",

  //StatsCardComp
  LiveEventStatsAll: "Todos los Eventos en Directo",
  LiveEventStatsShowAll: "Mostrar todos los eventos",
  LiveEventStatsShowPrivate: "Mostrar eventos privados",
  LiveEventStatsShowPubllic: "Mostrar eventos públicos",

  //RatingReviews Comp
  RatingReviewsAll: "Todas las puntuaciones y reseñas",
  RatingReviewsReviews: "Reseñas",

  //PrivateMessageInfluencer Comp
  PrivateMessageInfluencerRequests: "Solicitudes de petición privada",
  PrivateMessageInfluencerHowItWorks: "Cómo funciona",

  //RequestDetail Comp
  RequestDetailDetails: "Detalles de la petición",
  RequestDetailDetailsDescription: "Detalles de la petición privada",
  RequestDetailAccepted: "Aceptada",
  RequestDetailRejected: "Rechazada",
  RequestDetailPendingConfirmation: "Pendiente de confirmación",
  RequestDetailModified: "Modificada",
  RequestDetailCancelledByViewer: "Cancelada por el viewer",
  RequestDetailConfirmed: "Petición Confirmada",
  RequestDetailYouCanEdit: "Puedes editar la petición para",
  RequestDetailYouCanEdit2: "proponer nuevos términos",
  RequestDetailYouCanEditRequest: "Editar petición",
  RequestDetailDetailsRequestDetails: "Detalles de la petición",
  RequestDetailDetailsAttendees: "Asistentes",
  RequestDetailDetailsPricePerTicket: "Precio por ticket",
  RequestDetailDetailsDateAndTime: "Fecha y hora",
  RequestDetailDetailsSpecificDateAndHour:
    "{{- specificDate}} de {{request_start_hour}} a {{request_end_hour}}",
  RequestDetailDetailsSpecificAnyTimeAndHour:
    "En cualquier momento de {{request_start_hour}} a {{request_end_hour}}",
  RequestDetailDetailsUndefinedDateFrom:
    "Sin fecha establecida, de {{request_start_hour}} a {{request_end_hour}}",
  RequestDetailDetailsDateAnyTime: "{{- date}} en cualquier momento",
  RequestDetailDetailsDateAndTimeNotDefined:
    "La fecha y la hora aún no están definidas",
  RequestDetailDetailsReject: "Rechazar",
  RequestDetailDetailsLoading: "Cargando...",
  RequestDetailDetailsAcceptProposal: "Aceptar proposición",
  RequestDetailDetailsAccepting: "Aceptando...",
  RequestDetailDetailsCheckTheEvent: "Ver el evento",

  //HowItWorks Modal
  HowItWorksTitle: "Cómo funciona",
  HowItWorksSubtitle: "Reuniones Privadas",
  HowItWorksStep1: "1. Envía tu propuesta al influencer",
  HowItWorksStep2:
    "2. Espera a la respuesta del influencer aceptando, rechazando o proponiendo nuevos términos",
  HowItWorksStep3: "3. Acepta o rechaza los nuevos términos",
  HowItWorksStep4:
    "4. Cuando los dos estéis de acuerdo en los términos, recibirás un link para comprar tu ticket y compartir este meeting privado con tus amigos",
  HowItWorksStep5:
    "5. . Los tickets se deben comprar con 24 horas de adelanto, o el influencer podrá cancelar el evento",

  //PrivateMessageInfluencerLogic
  PrivateMessageInfluencerLogicSentYou: "te ha enviado una petición",
  PrivateMessageInfluencerLogicYouHaveModified:
    "Has modificado la petición de ",
  PrivateMessageInfluencerLogicYouHave: "Has",
  PrivateMessageInfluencerLogicYouHaveTheRequest: "la petición hecha por ",
  PrivateMessageInfluencerLogicYouHaveAccepted: "Has aceptado la petición de ",
  PrivateMessageInfluencerLogicHasAccepted: "ha aceptado tu modificación ",
  PrivateMessageInfluencerLogicTheRequestMade: "la petición hecha por  ",
  PrivateMessageInfluencerLogicUserHasCancelled:
    "ha cancelado su petición de reunión privada ",
  PrivateMessageInfluencerLogicYouHaveModified:
    "Has modificado la petición de evento privado hecha por ",

  PrivateMessageInfluencerLastUpdate: "Última actualización",
  PrivateMessageViewerLastUpdate: "Última actualización",

  //Private meeting reasons
  PrivateMeetingJustForFun: "Just for fun",
  PrivateMeetingJustForFunDescription: "Pasarlo bien contigo",
  PrivateMeetingJustForFunOptionName1: "Aparición en una fiesta",
  PrivateMeetingJustForFunOptionName2: "Jugar",
  PrivateMeetingJustForFunOptionName3: "Pasar el rato",
  PrivateMeetingJustForFunOptionName4: "Otra razón",
  PrivateMeetingJustForFunWhatReason: "Qué razón?",
  PrivateMeetingConsultantRequest: "Petición de consultoría",

  // Activities component
  ActivitiesShowAll: "Mostrar todas las notificaciones",
  ActivitiesNoActivities: "No tienes notificaciones aún",

  // Instagram sign up modal Component
  InstagramSignUpModalComponentEmailPlaceholder: "Email",
  InstagramSignUpModalComponentEmailAddress: "Email",
  InstagramSignUpModalComponentConfirmEmailAddress: "Confirma tu email",
  InstagramSignUpModalComponentContinue: "Continuar",
  InstagramSignUpModalComponentLoading: "Cargando",
  InstagramSignUpModalComponentWelcomeTo: "Bienvenido a",
  InstagramSignUpModalComponentLastStepDescription:
    "Necesitamos una dirección de email para enlazarla a tu cuenta de Instagram.",
  InstagramSignUpModalComponentEmailNotMatch: "Los emails deben coincidir",

  // ModalEventInfo component
  ModalEventInfoTitle:
    "Ofrecemos dos modalidades de evento: Interactivo y Broadcast ",
  ModalEventInfoInteractive: "Interactivo",
  ModalEventInfoInteractiveExplain:
    "Evento donde puedes interaccionar en tiempo real con todos los participantes. Limitado a grupos de hasta 49 participantes simultáneos",
  ModalEventInfoMassive: "Broadcast",
  ModalEventInfoMassiveExp1:
    "Ideal para eventos con audiencias masivas (más de 50 personas)",
  ModalEventInfoMassiveExp2:
    "No permite interacción directa con la audiencia por vídeo",
  ModalEventInfoMassiveExp3: "Requiere de software externo",
  ModalEventInfoContactUs:
    "Para activar eventos de broadcast en tu cuenta, contacta con nosotros support@gotmy.com",

  //MainApp.js
  CookiesWarningText:
    "utiliza cookies y otros datos para entregar, mantener y mejorar nuestros servicios. \nSi continúa utilizando nuestros servicios, asumiremos que acepta el uso de dichas cookies.",

  //Streaming Options Component
  StreamingOptionsComponentPreview: "Previsualización",
  StreamingOptionsComponentPreviewLoading: "Cargando...",
  StreamingOptionsComponentPreviewCurrentCamera: "Cámara actual",
  StreamingOptionsComponentPreviewCurrentInputDevice:
    "Dispositivo de entrada actual",
  StreamingOptionsComponentPreviewUnknown: "Desconocido",
  StreamingOptionsComponentPreviewChangeInputDevice:
    "Cambiar dispositivo de entrada",
  StreamingOptionsComponentPreviewNewInputDevice:
    "Nuevo dispositivo de entrada",
  StreamingOptionsComponentPreviewNoAudioDevicesFound:
    "No se han encontrado dispositivos de audio",
  StreamingOptionsComponentPreviewCurrentOutputDevice:
    "Dispositivo de salida de sonido",
  StreamingOptionsComponentPreviewChangeOutPutDevice:
    "Cambiar dispositivo de salida",
  StreamingOptionsComponentPreviewNewOutputDevice:
    "Nuevo dispositivo de salida",
  StreamingOptionsComponentPreviewNoVoiceDevicesFound:
    "No se han encontrado dispositivos de audio",
  StreamingOptionsComponentPreviewStreamingSettings:
    "Configuración del streaming",
  StreamingOptionsComponentPreviewSave: "Guardar",
  StreamingOptionsComponentPreviewClose: "Cerrar",
  StreamingOptionsComponentPreviewOutputAudio: "Salida de audio",
  StreamingOptionsComponentPreviewSelectMicrophone: "Seleccionar micrófono",
  StreamingOptionsComponentPreviewSelectAudioOutput: "Seleccionar altavoz",
  StreamingOptionsComponentPreviewSelectCamera: "Seleccionar cámara",
  StreamingOptionsComponentPreviewInputAudio: "Entrada de audio",
  StreamingOptionsComponentPreviewVideo: "Video",

  //OVVideo Component
  OVVideoComponentSharingYourScreen: "Estás compartiendo tu pantalla",
  OVVideoComponentLoadingStreaming: "Cargando el streaming...",
  OVVideoComponentItSeemsTheInfluencerIsNotReadyYet:
    "Parece que el influencer no esta listo aún.",
  OVVideoComponentLoadingVideo: "Cargando...",

  //UserVideo Component
  UserVideoComponentSharingScreen: "Estás compartiendo tu pantalla",
  UserVideoComponentIsSpeaking: "está hablando",

  //PreviewStreaming Component
  PreviewStreamingInfluencerComponentDoYouWantToFinishTheEvent:
    "¿Quieres acabar el evento?",
  PreviewStreamingInfluencerComponentDoYouWantToFinishTheEventBody:
    "Todos los participantes serán desconectados y el evento se terminará.",
  PreviewStreamingInfluencerComponentYes: "Sí",
  PreviewStreamingInfluencerComponentDisconnectNotFinishingEvent:
    "Desconectar sin finalizar el evento",
  PreviewStreamingInfluencerComponentNo: "No",
  PreviewStreamingInfluencerComponentDoYouWantToGoOutFromTheEvent:
    "¿Quieres salir del evento?",
  PreviewStreamingInfluencerComponentDoYouWantToGoOutFromTheEventBody:
    "Podrás unirte en cualquier momento siempre que el evento siga en directo.\n¿Quieres salir?",
  PreviewStreamingInfluencerComponentRetryingIn: "Reintentando en",
  PreviewStreamingInfluencerComponentSeconds: "segundos",
  PreviewStreamingInfluencerComponentConnectionProblemsMessage:
    "Parece que tienes problemas con tu conexión.",
  PreviewStreamingInfluencerComponentDisconnect: "Desconectar",
  PreviewStreamingInfluencerComponentInternetConnectionLost:
    "Conexión a internet perdida.",
  PreviewStreamingInfluencerComponentInternetConnectionLostBody:
    "Comprueba si tienes problemas con tu acceso a Internet.",
  PreviewStreamingInfluencerComponentInternetConnectionLostBodyReconnect:
    "se volverá a conectar automáticamente cuando se recupere tu acceso a Internet.",
  PreviewStreamingInfluencerComponentThanksForAttending:
    "¡Gracias por asistir al evento en vivo!",
  PreviewStreamingInfluencerComponentWriteAReview: "Escribe una reseña",
  PreviewStreamingInfluencerComponentReviewPlaceholder:
    "Comentanos cuanto te gustó el evento con unas palabras",
  PreviewStreamingInfluencerComponentSendFeedback: "Enviar comentario",
  PreviewStreamingInfluencerComponentSkip: "Omitir",
  PreviewStreamingInfluencerComponentChatModeration: "Moderación del chat",
  PreviewStreamingInfluencerComponentChooseViewerAsModerator:
    "Escoge un participante como moderador",
  PreviewStreamingInfluencerComponentRaiseHandQueue: "Participantes esperando",
  PreviewStreamingInfluencerComponentLiveChat: "Chat",
  PreviewStreamingSendMessage: "Manda un mensaje",
  PreviewStreamingInfluencerComponentChatTemporarilyDisabled:
    "Chat temporalmente deshabilitado",
  PreviewStreamingInfluencerComponentChooseRaiseHand: "Elegir turno",
  PreviewStreamingInfluencerComponentNoUsersWaiting:
    "No hay usuarios esperando",
  PreviewStreamingInfluencerComponentSend: "Enviar",
  PreviewStreamingInfluencerComponentViewers: "Participantes",
  PreviewStreamingInfluencerComponentViewersConnected:
    "Participantes conectados",
  PreviewStreamingInfluencerComponentYou: "Tú",
  PreviewStreamingInfluencerComponentClose: "Cerrar",
  PreviewStreamingInfluencerComponentTooltipDisableMicrophone:
    "Desactivar micrófono",
  PreviewStreamingInfluencerComponentTooltipEnableMicrophone:
    "Activar micrófono",
  PreviewStreamingInfluencerComponentTooltipDisableCamera: "Desactivar cámara",
  PreviewStreamingInfluencerComponentTooltipEnableCamera: "Activar cámara",
  PreviewStreamingInfluencerComponentTooltipDisableRaiseHand:
    "Desactivar modo raise hand.\nEste modo permite a los participantes unirse a la transmisión con sus cámaras",
  PreviewStreamingInfluencerComponentTooltipEnableRaiseHand:
    "Activar modo raise hand.\nEste modo permite a los participantes unirse a la transmisión con sus cámaras",
  PreviewStreamingInfluencerComponentTooltipDisableRaiseHandStopViewersTurn:
    "Acabar turno del participante activo",
  PreviewStreamingInfluencerComponentTooltipDisableOpenMic:
    "Desactivar micro abierto.\nEste modo permite a todos los participantes abrir su micrófono",
  PreviewStreamingInfluencerComponentTooltipEnableOpenMic:
    "Activar micro abierto.\nEste modo permite a todos los participantes abrir su micrófono",
  PreviewStreamingInfluencerComponentTooltipDisableChat: "Desactivar chat",
  PreviewStreamingInfluencerComponentTooltipEnableChat: "Activar chat",
  PreviewStreamingInfluencerComponentTooltipShowOnlineViewersPanel:
    "Mostrar el panel de participantes activos",
  PreviewStreamingInfluencerComponentTooltipRaiseUpTheHand:
    "Pide turno para interactuar con tu cámara y tu micrófono.",
  PreviewStreamingInfluencerComponentTooltipRaiseDownTheHand:
    "Cancela tu petición de turno para interactuar con tu cámara y tu micrófono",
  PreviewStreamingInfluencerComponentTooltipOpenModeIsEnabled:
    "El modo micro abierto esta activo, Open mode is enabled\n¡Activa el micrófono para hablar con todos!",
  PreviewStreamingInfluencerComponentWeAreSorryButWeCanNotStartStreaming:
    "Lo sentimos pero no podemos iniciar el streaming.",
  PreviewStreamingInfluencerComponentWeAreSorryButWeCanNotStartStreamingBody:
    "Verifique que haya iniciado sesión y haya comprado un ticket válido",
  PreviewStreamingInfluencerComponentWelcomeMessage:
    "Bienvenido a tu evento en vivo",
  PreviewStreamingInfluencerComponentInstructions:
    "Comprueba todas las funciones antes de empezar!",
  PreviewStreamingInfluencerComponentStartStreaming: "Empezar Streaming",
  PreviewStreamingInfluencerComponentStartingStreaming:
    "Empezando streaming...",
  PreviewStreamingInfluencerComponentOpenMode: "Micro abierto",
  PreviewStreamingInfluencerComponentOpenModeDescription:
    "Permita que todos los participantes hablen",
  PreviewStreamingInfluencerComponentRaiseHand: "Raise Hand",
  PreviewStreamingInfluencerComponentRaiseHandDescription:
    "Permita que los participantes pidan turno para hablar",
  PreviewStreamingInfluencerComponentStopTurn: "Acabar turno",
  PreviewStreamingInfluencerComponentStopTurnDescription:
    "Termina el turno del participante activo",
  PreviewStreamingInfluencerComponentCameraOnOff: "Cámara On/Off",
  PreviewStreamingInfluencerComponentCameraOnOffDescription:
    "Activa o desactiva tu cámara",
  PreviewStreamingInfluencerComponentMicrophoneOnOff: "Micrófono On/Off",
  PreviewStreamingInfluencerComponentMicrophoneOnOffDescription:
    "Activa o desactiva tu micrófono",
  PreviewStreamingInfluencerComponentWelcomeTo: "Bienvenido a",
  PreviewStreamingInfluencerComponentInstructionsViewer:
    "¡Consulta todas las funciones antes de empezar!",
  PreviewStreamingInfluencerComponentJoinStreaming: "Unirse al Streaming",
  PreviewStreamingInfluencerComponentJoiningStreaming: "Uniéndose streaming...",
  PreviewStreamingInfluencerComponentOpenModeDescriptionViewer:
    "¡Habilita tu micrófono y habla con todos los participantes del streaming!",
  PreviewStreamingInfluencerComponentRaiseHanddDescriptionViewer:
    "Pide turno para hablar habilitando tu cámara",
  PreviewStreamingInfluencerComponentLoading: "Cargando...",
  PreviewStreamingInfluencerComponentTooltipOpenSettings:
    "Abrir el panel de opciones",

  //PreviewStreaming Component (logic)
  PreviewStreamingInfluencerComponentBrowserNotSupportSwitchMediaDeviceDuringStreaming:
    "Este navegador no admite cambios en sus dispositivos multimedia durante la transmisión. \nSi desea seleccionar un dispositivo diferente, vuelva a cargar el sitio y configúrelo antes de unirse a la sesión.",
  PreviewStreamingInfluencerComponentGotmyHasNoAccessToMedia:
    "Parece que gotmy no tiene acceso a tu cámara y micrófono. \ngotmy necesita acceder a tu cámara y micrófono antes de unirse a la transmisión. \nCambia los permisos otorgados a gotmy sitio web y vuelve a cargar tu navegador.",
  PreviewStreamingInfluencerComponentRateEvent: "Califica el evento",
  PreviewStreamingInfluencerComponentThereWasAnErrorSendingReview:
    "Hubo un error al enviar su reseña",
  PreviewStreamingInfluencerComponentVideoStreamingNotSupportedBrowser:
    "El streaming no es compatible con su navegador, use Chrome, Firefox o Safari.",
  PreviewStreamingInfluencerComponentMediaDevicesNotDetected:
    "Cámara web y micrófono no detectados. \nInstale un dispositivo de cámara web y un micrófono y vuelva a cargar el sitio web o asegúrese de que sus dispositivos no estén siendo utilizados por otra aplicación. \nSi el error persiste, intente volver a abrir su navegador o reiniciar su computadora. \nComuníquese con gotmy support para una mejor asistencia.",
  PreviewStreamingInfluencerComponentProblemFinishingEvent:
    "Hubo un problema al finalizar el evento. \nVuelva a intentarlo en unos segundos.",
  PreviewStreamingInfluencerComponentProblemFinishingEventLeaveStandard:
    "Hubo un problema al finalizar el evento. \nVuelva a intentarlo en unos segundos.",
  PreviewStreamingInfluencerComponentShareScreenNotSupported:
    "Compartir pantalla no es compatible con este navegador.",
  PreviewStreamingInfluencerComponentChromeOutdated:
    "Tu Google Chrome está desactualizado. \nActualiza Google Chrome (versión mínima 72) para comenzar a compartir tu pantalla.",
  PreviewStreamingInfluencerComponentFirefoxOutdated:
    "Tu Firefox está desactualizado. \nActualiza Firefox (versión mínima 65) para comenzar a compartir tu pantalla.",
  PreviewStreamingInfluencerComponentOperaOutdated:
    "Tu Opera está desactualizada. \nActualiza Opera (versión mínima 72) para comenzar a compartir tu pantalla.",
  PreviewStreamingInfluencerComponentSafariOutdated:
    "Tu Safari está desactualizado. \nActualiza Safari (versión mínima 13.0) para comenzar a compartir tu pantalla.",
  PreviewStreamingInfluencerComponentShareScreenNotSupportedUseOther:
    "Compartir pantalla no es compatible con este navegador. \nUtilice Chrome, Firefox, Opera o Safari",
  PreviewStreamingInfluencerComponentGotmyHasNoAccessToMediaEnsure:
    "gotmy no puede acceder a tu cámara y micrófono. \nAsegúrate de haber otorgado permiso de acceso a la cámara y al micrófono para este sitio web",
  PreviewStreamingInfluencerComponentProblemConnectingSession:
    "Hubo un problema al conectarse a la sesión.",
  MicrophoneUnpluggedWarning:
    "Parece que tu micófono ha sido desconectado. Por favor configura uno nuevo en el panel de opciones.",
  CameraUnpluggedWarning:
    "Parece que tu cámara ha sido desconectado. Por favor configura uno nuevo en el panel de opciones.",

  PreviewStreamingInfluencerComponentProblemConnectingSessionCheckDeviceNotInUse:
    "Hubo un problema al conectarse a la sesión.\nAsegurate de que tu conexión es estable, que ninguna otra aplicación está usando tu micrófono/cámara y que tu micrófono/cámara funciona correctamente.",
  PreviewStreamingInfluencerComponentThereWasAProblemConnectingToSessionGotmyError:
    "Hubo un problema al conectarse a la sesión. \nCódigo de error:",
  PreviewStreamingInfluencerComponentThereWasAProblemConnectingToSessionGotmyErrorIfErrorPersist:
    "\nSi el error persiste, comuníquese con el soporte de gotmy.",
  PreviewStreamingInfluencerComponentErrorConnecctingContactSupport:
    "Hubo un problema al conectarse a la sesión. Si el error persiste, comuníquese con el soporte de gotmy.",
  PreviewStreamingInfluencerComponentErrorConnectingReload:
    "Hubo un problema al conectarse a la sesión. Vuelva a cargar el sitio web e intente más tarde. Si el error persiste, comuníquese con el soporte de gotmy.",
  PreviewStreamingInfluencerComponentGiveTurn: "Dar turno",
  StreamingOptionsComponentSelectMicrophone: "Seleciona un micrófono",
  StreamingOptionsComponentErrorLoadingYourMicrophone:
    "Error cargando tu micrófono. Comprueba que el dispositivo funciona correctamente y prueba de nuevo.",
  StreamingOptionsComponentErrorLoadingYourCamera:
    "Error cargando tu cámara. Comprueba que el dispositivo funciona correctamente y prueba de nuevo.",
  StreamingOptionsComponentVideo: "Video",
  StreamingOptionsComponentMicrophone: "Micrófono",
  StreamingOptionsComponentSpeakers: "Altavoces",
  StreamingOptionsComponentSelectACamera: "Seleciona una cámara",
  StreamingOptionsComponentNoDevicesFound: "No se han encontrado dispositivos",

  //Attendees Moderator Component
  AttendeesModeratorComponentRaiseHandModeDisabled:
    "Modo Raise Hand desactivado",
  AttendeesModeratorComponentViewersWaiting: "Participantes esperando...",

  //MenuStreamingPre Component
  MenuStreamingPreComponentLeaveLiveEvent: "Salir del evento en vivo",

  //FechaCat Component
  FechaCatComponentLiveFrom: "En vivo desde",

  //FavoriteIcon Component
  FavoriteIconComponentAddToFavorites: "Agregar a favoritos",
  FavoriteIconComponentRemoveFromFavorites: "Quitar de favoritos",

  //AttendeesInfluencer Component
  AttendeesInfluencerComponentOnline: "En línea",
  AttendeesInfluencerComponentChoose: "Escoger",

  //HLSStream Component
  HLSStreamComponentLoadingStream: "Cargando...",
  HLSStreamComponentLoadingStreamDescription:
    "El streaming estará listo en breve",

  //StreamingHLS Component
  StreamingHLSComponentInstructionsHeader:
    "Configuración para el software externo",
  StreamingHLSComponentReminderInstructionsDescription:
    'Recuerda que debes configurar tu software externo (OBS u otro similar) con los siguientes parámetros para que el evento se retransmita correctamente. En OBS ve a ajustes (settings), emisión (stream) y luego en el desplegable de servicio selecciona "Personalizado..." e introduce los siguientes valores:',
  StreamingHLSComponentReminderInstructionsDescriptionExtended:
    "Puedes volver a abrir esta ventana de información haciendo click en el botón flotante de ayuda en la parte inferior derecha. Si necesitas más asistencia contacta con nosotros por email a support@gotmy.com o por WhatsApp en tel:+178 645 375 35.",
  StreamingHLSComponentReminderInstructionsClose: "Cerrar",
  StreamingHLSComponentReminderInstructionsServer: "Servidor",
  StreamingHLSComponentReminderInstructionsStreamName: "Clave de retransmisión",
  HLSBroadcastComponentWatchingNow: "Espectadores",

  //SelectMeetingDetails Component
  SelectMeetingDetailsComponentWhenWouldULikeToHaveTheMeeting:
    "¿Cuándo le gustaría tener la reunión?",
  SelectMeetingDetailsComponentIDoNotCare: "No me importa la fecha y la hora",
  SelectMeetingDetailsComponentIDLikeTaSpecificDate:
    "Me gustaría una fecha y hora específica",
  SelectMeetingDetailsComponentPleaseSelectOneOption:
    "Por favor selecciona una opcion",
  SelectMeetingDetailsComponentSelectDate: "Seleccione fecha",
  SelectMeetingDetailsComponentFieldRequired: "Este campo es obligatorio",
  SelectMeetingDetailsComponentStartTime: "Hora de inicio",
  SelectMeetingDetailsComponentEndtime: "Hora de finalización",
  SelectMeetingDetailsComponentCurrency: "Moneda",
  SelectMeetingDetailsComponentPurposeOfTheMeeting: "Propósito de la reunión",
  SelectMeetingDetailsComponentExplainYourPurpose:
    "Explique su propósito de su reunión",
  SelectMeetingDetailsComponentCharactersLeft:
    "Tienes {{char}} caracteres libres",
  SelectMeetingDetailsComponentYouCanAddExternalLinks:
    "Puedes agregar enlaces externos usando http://",
  SelectMeetingDetailsComponentPricePerTicket: "Precio por ticket",

  //UsePrivateMessageViewerLogic Component
  UsePrivateMessageViewerLogicComponentYourEventRequestHasBeenSentTo:
    "Tu petición de evento privado se ha enviado a {{username}}",
  UsePrivateMessageViewerLogicComponentEventRequestModifiedBy:
    "Tu solicitud de evento ha sido modificada por {{username}}",
  UsePrivateMessageViewerLogicComponentHasBeenAcceptedBy:
    "Tu solicitud de evento ha sido aceptada por {{username}}",
  UsePrivateMessageViewerLogicComponentHasBeenRejectedBy:
    "{{username}} ha rechazado tu solicitud de evento privado",
  UsePrivateMessageViewerLogicComponentHasModifiedYourPrivateRequest:
    "{{username}} ha modificado tu solicitud de evento privado",
  UsePrivateMessageViewerLogicComponentHasAcceptedTheModifiedRequest:
    "¡Has aceptado la solicitud modificada para tu evento privado!",
  UsePrivateMessageViewerLogicComponentInfluencerHasCustomState:
    "{{username}} ha {{custom}} su solicitud de evento privado",
  UsePrivateMessageViewerLogicComponentYouHaveCancelled:
    "Has cancelado la solicitud de evento privado",
  UsePrivateMessageViewerLogicComponentSorryError:
    "Lo sentimos, ha habido un error",
  UsePrivateMessageViewerLogicComponentGreatEventCreated:
    "¡Estupendo! El evento fue creado. Ahora puedes consultar el evento y comprar tu entrada",
  UsePrivateMessageViewerLogicComponentPrivateRequestRejected:
    "Solicitud privada rechazada con éxito",

  //PrivateMessageViewer Component
  PrivateMessageViewerHowItWorks: "Cómo funciona",
  PrivateMessageViewerPrivateRequest: "Peticiones Privadas",

  //Request Messages Component
  RequestMessagesComponentPendingTicketPurchase: "Compra de ticket pendiente",
  RequestMessagesComponentPendingTicketPurchaseDescription:
    "El evento no se llevará a cabo hasta que el viewer compre el ticket",
  RequestMessagesComponentNoPrivateRequestYet:
    "No tienes solicitudes privadas.",
  RequestMessagesComponentFindYourInfluencer: "Encuentra tu influencer",

  //NavBar_liveEvents Component
  NavBarLiveEventsComponentUpcomingLiveEvents: "Próximos",
  NavBarLiveEventsComponentUpcomingLiveEventsMobile: "Próximos",
  NavBarLiveEventsComponentPastEvents: "Pasados",
  NavBarLiveEventsComponentPastEventsMobile: "Pasados",

  //Activity Component
  ActivityComponentBookedYourEvent:
    "{{- username}} ha comprado un ticket para tu evento {{- eventTitle}}",
  ActivityComponentStartedFollowingYou:
    "{{- username}} ha comenzado a seguirte",
  ActivityComponentYouHaveTicketsFor: "Tienes tickets para",
  ActivityComponentMadeAPrivateRequest:
    "{{- name}} {{- surname}} te ha enviado una propuesta de evento",

  //EventCardInfluencer Component
  EventCardInfluencerComponentStartsIn: "Comienza {{- startingText}}",
  EventCardInfluencerComponentLiveAt: "En vivo a {{- date}}",
  EventCardInfluencerComponentMaxXPeople: "Máx. {{- maxNumber}} tickets",
  EventCardInfluencerComponentTicketsSold: "Tickets vendidos",
  EventCardInfluencerComponentViewers: "Espectadores",

  //SubscribeInfluencer Component
  SubscribeInfluencerComponentLoadingRedirectText: "Cargando...",

  //Suscription success Component
  SuscriptionSuccessComponentTitle: "Suscrito correctamente",
  SuscriptionSuccessComponentDescription:
    "Si tienes alguna pregunta contacta con nosotros por email a support@gotmy.com o por WhatsApp en tel:+178 645 375 35.",

  //PrivateInfo Component
  PrivateInfoComponentPricePerTicket: "Precio por ticket",
  PrivateInfoComponentPrivateInformation: "Información privada",
  PrivateInfoComponentNumberOfTickets: "Número de tickets",
  PrivateInfoComponentGrossRevenue:
    "Ingresos brutos (todos los tickets vendidos)",
  PrivateInfoComponentTicketingFees: "Tarifa de venta de tickets",
  PrivateInfoComponentDiscountCode: "Código de descuento",
  PrivateInfoComponentReschedulingPolicy:
    "Política de cambio de cambio de evento",
  PrivateInfoComponentReschedulingPolicyDescription:
    "Una vez por evento, hasta una fecha/hora posterior y hasta 24 horas antes de la fecha inicial evento, tras el cambio cualquier espectador puede solicitar un reembolso en cualquier momento y tu puntuación se verá afectada.",
  PrivateInfoComponentCancellationPolicy: "Política de cancelación",
  PrivateInfoComponentCancellationPolicyDescription:
    "Hasta 24 horas antes del evento, los espectadores recibirán un reembolso completo y tu puntuación se verá afectada.",
  PrivateInfoComponentInfluencerDelay:
    "Retraso del influencer de más de 10 minutos o no presentación",
  PrivateInfoComponentInfluencerDelayDescription:
    "El espectador obtendrá un reembolso completo y la puntuación se verá muy afectada",
  PrivateInfoComponentCancelLiveEvent: "Cancelar evento en vivo",
  //ShareEvent Component
  ShareEventComponentInfluencerMessage:
    "Echa un vistazo al evento que estoy organizando. ¡Consigue tu entrada! {{- title}}",
  ShareEventComponentViewerMessage:
    "Echa un vistazo al evento que voy a asistir {{- title}}!",
  ShareEventComponentOrCopyTheLinkHere: "O comparte este enlace",
  ShareEventComponentCopied: "Enlace copiado al portapapeles",

  //useModalRecoverLogic
  useModalRecoverLogicLinkSent:
    "Se ha enviado un enlace de recuperación al email: {{- email}}",
  useModalRecoverLogicLinkSentSorryError:
    "Algo ha salido mal, vuelve a intentarlo.",

    //EXTRA
  days: "días",
  and: "y",

  PreviewStreamingInfluencerComponentProblemConnectingAccesingToMediaDevices:
      "Hubo un problema al acceder a tu cámara/micrófono. Comprueba que tu dispositivo no está en uso por otro programa y que funciona correctamente. No podrás interactuar en el evento con tu cámara y micrófono aunque si podrás ver el evento.",
  HLSBroadcastComponentTicketInUse: "Ticket en uso",
  HLSBroadcastComponentTicketInUseDescription: "Hemos detectado que tu ticket está siendo usado desde otro dispositivo.\nLos tickets solo pueden usarse en un dispositivo a la vez.\nSi crees que es un error prueba recargando la página y si el problema persiste contacta con nuestro equipo de soporte en support@gotmy.com o en WhatsApp +178 64 5375 35.",
  HLSBroadcastComponentSocketIoError: "Hay un problema de conexión con su navegador. Intente utilizar otro navegador o póngase en contacto con el servicio de asistencia.",
};
