export const Api_URL = "https://api.gotmy.com/api/";
export const DEV_Api_URL = "https://dev.gotmy.com/api/";
export const HOST_URL = "https://api.gotmy.com/api/";
export const DEV_HOST_URL = "https://dev.gotmy.com/api/";
export const LOGIN_BACK_URL = "https://gotmy.com/";
export const DEV_LOGIN_BACK_URL = "https://dev.gotmy.com/";
export const HOME_PAGE_URL = "https://www.gotmy.com/";
export const DEV_HOME_PAGE_URL = "https://dev.gotmy.com";
export const HOME_PAGE_PAYPAL_REDIRECT_URL = "https://www.gotmy.com";
export const DEV_HOME_PAGE_PAYPAL_REDIRECT_URL = "https://dev.gotmy.com";
export const SECURE_LOGIN_BACK_URL = "https://gotmy.com";
export const DEV_SECURE_LOGIN_BACK_URL = "https://dev.gotmy.com/";
export const googlePlayGotmyURL =
  "https://play.google.com/store/apps/details?id=com.gotmy.app";
export const appleStoreGotmyURL =
  "https://apps.apple.com/app/gotmy/id1480807998";

export const SOCKETIO_ENDPOINT = "https://socket.dev.gotmy.com";
export const DEV_SOCKETIO_ENDPOINT = "https://socket.prod.gotmy.com";
export const EVENT_UUID = null;
export const BRAND_LINKEDIN_URL = "https://www.linkedin.com/company/gotmy/";
export const BRAND_FACEBOOK_URL = "https://www.facebook.com/gotmydotcom/";
export const BRAND_INSTAGRAM_URL = "https://www.instagram.com/gotmydotcom/";
export const BRAND_TWITTER_URL = "https://twitter.com/gotmydotcom/";
export const BRAND_YOUTUBE_URL = null;
export const BRAND_WEBSITE_URL = "https://www.hitsbook.com/";
export const BRAND_PINTEREST_URL = "https://www.pinterest.com/gotmydotcom/";
export const BRAND_BLOG_URL = "https://blog.gotmy.com/blog/";
export const BRAND_NAME = "gotmy";
export const PRIVATE_MARKETPLACE = false;
export const SUPPORT_EMAIL = "support@gotmy.com";
export const INFLUENCER_ICON_ENABLED = true;
export const SHARE_ICONS_FLAT_BLACK = false;
export const SHOW_DEFAULT_ICONS = true;
export const LOGIN_MAIN_PAGE = false;
export const REFERRAL_HOME_PAGE = false;
export const ENABLE_PURCHASE = true;
export const newB2BInfluencerLanding = false;
export const alternativePublishPage = true;
export const isGotmyApp = true;
export const NEW_PUBLISH_PAGE = true;
export const B2B_PORT = false;
export const hideCategories = true;
export const DISABLE_FOOTER_HOME = false;

