import React, {useEffect} from 'react'
import Loader from "components/comunes/loader";
import { withRouter, useParams , useLocation, useHistory} from "react-router-dom";
import axios from "axios";
import { Api } from "../../environment/environment";
import { parseLocale } from 'utils/Localization'

const StripeBack = () => {
    const location = useLocation()
    const history = useHistory()
    useEffect(() => {
      if (location && location.search ){
        const stripeCode = location.search.slice(6)
        console.log ({stripeCode})
        handleNewStripeCode ({token: stripeCode})

      }
    }, [location])
    const handleNewStripeCode = async (token) =>{
        const userToken = JSON.parse(localStorage.getItem("env"))
        console.log (token)
        try {
      let { data } = await axios.post(`${Api}payment/influencer/save_stripe_account`,
      token ,
      {
        headers: {"Access-Token": userToken.token, "Content-Type": "application/json","Content-Language": parseLocale()}
      });
      history.push("/")
    }

    catch(e){

        console.log(e)

    }
}
    return (
        <div className="mt-5">
        <section className=" container mt-5">
        <Loader />
    </section>


        </div>
    )
}
export default withRouter(StripeBack)

    // A custom hook that builds on useLocation to parse
// the query string for you.

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
