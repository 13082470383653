import axios from "axios";
import {
  Api,
  ACCESS_TOKEN_API,
  SECOND_ACCESS_TOKEN_API,
  LOGIN_BACK_URL,
  TWITTER_BACK_URL
} from "./../environment/environment";
import { version } from "../../package.json";
import { parseLocale } from '../utils/Localization'
/**
 * API requests related to the "users" model
 */


var DetectRTC = require('detectrtc');



let headers = { 
  "Access-Token": ACCESS_TOKEN_API,
  "Content-Language": parseLocale() 
};

const refreshHeaders = (accessToken) => {
  DetectRTC.load();
  headers = {};

  headers["Access-Token"] = accessToken;
  headers["Content-Type"] = "application/json";
  headers["Platform"] = "Web/" + DetectRTC.browser.name + "/" + DetectRTC.browser.fullVersion + "/OS:" + DetectRTC.osName + DetectRTC.osVersion;
  headers["Platform-Display"] = DetectRTC.displayResolution;
  headers["Video-Permission"] = DetectRTC.isWebsiteHasWebcamPermissions;
  headers["Microphone-Permission"] = DetectRTC.isWebsiteHasMicrophonePermissions;
  headers["Microphone"] = DetectRTC.hasMicrophone;
  headers["Camera"] = DetectRTC.hasWebcam;
  headers["isWebSocketsBlocked"] = DetectRTC.isWebSocketsBlocked;
  headers["isWebSocketsSupported"] = DetectRTC.isWebSocketsSupported;
  headers["Content-Language"] = parseLocale();
  headers["Build"] = version;

}


export default class UserDAO {
  login(data) {
    var referral =  JSON.parse(localStorage.getItem ("referral"))
    localStorage.removeItem("referral")
    const referral_code = referral? referral.id :null 

    return axios.post(`${Api}auth/login`,  {...data, ...(referral_code) && {referral_code}}, {
      headers
    });
  }

  save(data) {

    var referral =  JSON.parse(localStorage.getItem ("referral"))
    localStorage.removeItem("referral")
    const referral_code = referral? referral.id :null 
    return axios.post(`${Api}user/register`, {...data, ...(referral_code) && {referral_code}}, {
      headers
    });
  }

  followUser(username, myToken) {
    refreshHeaders(myToken);
    return axios.post(
      `${Api}influencer/follow/` + username,
      {},
      { headers }
    );
  }

  unFollowUser(username, myToken) {
    refreshHeaders(myToken);
    return axios.post(
      `${Api}influencer/unfollow/` + username,
      {},
      { headers }
    );
  }

  influencerVerificationCode(data, myToken) {
    refreshHeaders(myToken);
    return axios.post(`${Api}auth/phone_verification`, data, { headers });
  }

  findByToken(token) {
    refreshHeaders(token);
    return axios.get(`${Api}user/profile`, { headers });
  }
  loginWithTicket(data) {
    return axios.post(`${Api}auth/login_ticket`, data, { headers });
  }

  findCategories(token) {
    refreshHeaders(token);
    return axios.get(`${Api}user/categories`, { headers });
  }

  findLanguages(token) {
    refreshHeaders(token);
    return axios.get(`${Api}user/languages`, {
      headers
    });
  }

  update(token, data) {
    refreshHeaders(token);
    return axios.patch(`${Api}user/update`, data, { headers });
  }
  
  updatePresentationVideo(data, token) {
    refreshHeaders(token);
    return axios.post(`${Api}influencer/updatePresentationVideo`, data, {
      headers: {
        "Access-Token": token,
        "Content-Type": "multipart/mixed"
      }
    });
  }

  /* DEPRECATED */
  upgradeToInfluencer(token, data) {
    refreshHeaders(token);
    return axios.post(`${Api}influencer/upgrade`, data, { headers });
  }

  requestUpgradeInfluencer(token) {
    refreshHeaders(token);
    return axios.post(`${Api}influencer/request_upgrade`, null, { headers });
  }

  requestTwitterToken(url) {
    const redirectUrl = url ? url : LOGIN_BACK_URL
    return axios.post(
      `${Api}auth/request_twitter_token`, { redirect_url: redirectUrl },
      { headers });
  }

  setLanguages(token, data) {
    refreshHeaders(token);
    return axios.post(`${Api}user/set_languages`, data, {
      headers
    });
  }

  setCategories(token, data) {
    refreshHeaders(token);
    return axios.post(`${Api}user/set_categories`, data, {
      headers
    });
  }

  profileInfluencer(token) {
    refreshHeaders(token);
    return axios.get(`${Api}influencer/profile`, { headers });
  }

  updateInfluencer(token, data) {
    refreshHeaders(token);
    return axios.patch(`${Api}influencer/update`, data, { headers });
  }

  findMyPurchasedEvents(token) {
    refreshHeaders(token);
    return axios.get(`${Api}user/purchased_events`, { headers });
  }

  setFavoriteEvent(token, event_uuid) {
    refreshHeaders(token);
    return axios.post(
      `${Api}user/set_favorite_event`,
      { event_uuid },
      { headers }
    );
  }

  removeFavoriteEvent(token, event_uuid) {
    refreshHeaders(token);
    return axios.post(
      `${Api}user/remove_favorite_event`,
      { event_uuid },
      { headers }
    );
  }

  forgotPassword(email) {
    return axios.post(
      `${Api}auth/forgot_password`,
      { email },
      { headers }
    );
  }

  forgotPsswordNewPassword(data) {
    return axios.post(`${Api}auth/forgot_password`, data, { headers });
  }

  getMyAcitivtyViewer(token) {
    refreshHeaders(token);
    return axios.get(`${Api}activity/my_activity`, { headers });
  }

  getMyAcitivtyInfluencer(token) {
    refreshHeaders(token);
    return axios.get(`${Api}activity/influencer/my_activity`, { headers });
  }

  confirmEmail(token) {
    refreshHeaders(token);
    return axios.post(
      `${Api}user/confirm_email`,
      { token },
      { headers }
    );
  }

  getMyInfluencerStats(token, currency = "usd") {
    refreshHeaders(token);
    return axios.get(`${Api}influencer/stats?currency=${currency}`, { headers });
  }
  getMyInfluencerCurrencies(token) {
    refreshHeaders(token);
    return axios.get(`${Api}influencer/billing/currencies`, { headers });
  }

  getUserPaymentMethods(token) {
    refreshHeaders(token);
    return axios.get(`${Api}/payment/stripe_methods`, { headers });
  }

  savePaymentMethod = (token, cardToken) => {
    refreshHeaders(token);
    return axios.post(
      `${Api}payment/save_stripe_method`,
      { token: cardToken },
      { headers }
    );
  }


  deletePaymentMethod = (token, paymentMethod) => {
    refreshHeaders(token);
    return axios.post(
      `${Api}/payment/delete_stripe_method`,
      { stripe_payment_method_id: paymentMethod },
      { headers }
    );
  }

  createCardTokenStripe = (number, month, year, cvc, stripeKey) => {
    return axios.post(
      `https://api.stripe.com/v1/tokens`,
      "card[number]=" + number + "&card[exp_month]=" + month + "&card[exp_year]=" + year + "&card[cvc]=" + cvc,
      {
        headers: {
          "Authorization": "Bearer " + stripeKey,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    );
  }


  /* TODO
    ENDPOINT NOT DEFINED
  */
  validateCaptcha(token, data) {
    // return axios.patch(`${Api}user/update`, data, {
    //   headers: {
    //     "Access-Token": token,
    //     "Content-Type": "application/json"
    //   }
    // });
  }
}
