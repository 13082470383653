import React from "react";
import NavBar_streaming from "../comunes/navbar_choose_moderator";
import "./style.scss";
import UserModerator from "../comunes_events/choose_moderator_card";
import { connect } from "react-redux";
import * as actions from "./../../store/actions";

const ModeratorChoose = ({ optionsModerators }) => {
  return (
    <div class="streaming">
      <header className="app-streaming">
        <NavBar_streaming />
      </header>

      <div className="container-fluid streaming">
        <div className="row">
          <div className="d-flex flex-column div_ban">
            <UserModerator data={optionsModerators} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    optionsModerators: state.streaming.optionsModerators
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetModerator: moderator => dispatch(actions.setModerator(moderator))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModeratorChoose);
