import React, { useState } from "react";
import useSuscription from '../../hooks/useSuscription'
import useSessionToken from '../../hooks/useSessionToken'
import { Col, Row } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import i18next from 'i18next';
import * as actions from "../../store/actions/user";
import { connect } from "react-redux";
import logoGotmy from "assets/img/logo.png"

const SubscribeInfluencer = ({ match, setError, history }) => {
    const [influencerUsername] = useState(match.params.id)
    const [userToken] = useState(match.params.userToken)
    let sessionToken = useSessionToken()
    sessionToken = userToken || sessionToken;

    useSuscription({ influencerUsername, sessionToken, setError, history })

    return (
        <div style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center"
        }}>
            <section >
                <Row>
                    <Col
                        xs={{ span: 10, offset: 1 }}
                        md={{ span: 10, offset: 1 }}
                    >
                        <Row>
                            <Col
                                xs={{ span: 8, offset: 2 }}
                                md={{ span: 4, offset: 4 }}
                            >
                                <img src={logoGotmy}
                                    style={{
                                        width: "100%"
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col
                                style={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                xs={{ span: 8, offset: 2 }}
                                md={{ span: 4, offset: 4 }}
                            >
                                <Spinner
                                    style={{
                                        width: 70,
                                        height: 70
                                    }}
                                    animation="grow" variant="danger" />
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                style={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                xs={{ span: 8, offset: 2 }}
                                md={{ span: 4, offset: 4 }}
                            >
                                <h4>
                                    {i18next.t("SubscribeInfluencerComponentLoadingRedirectText")}
                                </h4>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </div>
    );
};


const mapDispatchToProps = dispatch => {
    return {
      setError: error => dispatch(actions.setError(error))
    };
  };
  
  export default connect(
    null,
    mapDispatchToProps
  )(SubscribeInfluencer);
  

