import React, { useState } from "react";
import "./style.scss";
import { withRouter } from "react-router-dom";
import i18next from 'i18next';

const Search_input_peq = ({ history, opacity }) => {
  const [text, setText] = useState("");
  return (
    <div style={{ opacity: opacity }} className="Search-div-peq">
      <i
        className="fas fa-search"
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (text) {
            history.push(`/search/${text}`);
          }
        }}
      ></i>
      <input
        type="text"
        className="form-control"
        placeholder={i18next.t('SearchInputPeqComponentSearchPlaceholder')}
        value={text}
        onChange={event => setText(event.target.value)}
        onKeyPress={event => {
          if (event.which === 13 && text) {
            history.push(`/search/${text}`);
          }
        }}
      />
    </div>
  );
};

export default withRouter(Search_input_peq);
