import React from "react";
import NavBarGeneral from "../comunes/navbar_influencer";
import "./style.scss";
import SearchInputMessages from "../comunes/search_input_messages";
import Chat from "../comunes/chat_demo";
import FilteredList from "../comunes/chat";
import InputPhoto2 from "../comunes/Input_photo_live_message";
import Attendees from "../comunes_events/attendees_admin";
import AttendeesParticipant from "../comunes_events/attendees_participant";
import SearchInputPeq from "../comunes/search_input_peq";
import ImageLeave from "../comunes_events/imagen_puntuacion.group-chat";
import { useMessageInfluencerLogic } from "./MessageInfluencerLogic";

const MessageInfluencer = () => {
  const style = {};
  const { state, onChangeSearchInput } = useMessageInfluencerLogic();
  const { chats, loadingChats, searchInput } = state;
  return (
    <div class="messages_details_influencer">
      <header className="App-header">
        <NavBarGeneral />
      </header>
      <section className="container-fluid some">
        <div className="row">
          <div class="chat2 col-3 ml-3">
            <div className="d-flex">
              <h2 className="title_messages">Messages</h2>
              <button className="Btn-rojo group-chat">Create group chat</button>
            </div>
            <SearchInputMessages
              value={searchInput}
              onChange={onChangeSearchInput}
            />
            <FilteredList />
            <Chat />
          </div>
          <div className="col-5 date">
            <div className="bar"></div>
            <p className="text-center ml-3">Today</p>
            <div className="bar"></div>
          </div>
          <div className="col-3 col-md-offset-3 group_div" style={style}>
            <div className="caja_none" id="caja_none">
              <p className="group_name mb-1">Group name</p>
              <InputPhoto2 />
              <div className="mt-2 div_save">
                <button className="Btn-rojo save">Save</button>
                <button className="Btn-rojo cancel">Cancel</button>
              </div>
              <h2 className="gm mt-3 mb-3">Administrator</h2>
              <Attendees />
              <h2 className="gm mt-4 mb-3">Participants</h2>
              <SearchInputPeq />
              <AttendeesParticipant />
              <AttendeesParticipant />
            </div>
            <div className="group_div" style={style}>
              <div className="copy">
                <ImageLeave />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MessageInfluencer;
