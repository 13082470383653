import React, { useState } from "react";
import hamburger from "assets/img/icons/hamburger.svg";
import "../style.scss";
import logo from "assets/img/logo-white.png";
import Menu from "../../menu_influencer";
import styled from "styled-components";
// import LoginMenu from "../../login_menu";
import {
  IS_SIGN_UP_ENABLED,
  PRIVATE_MARKETPLACE,
} from "../../../../../environment/environment";
import Login_menus from "../../../login_menu_influencer";
import { Link } from "react-router-dom";
import styles from '../../../../../assets/css_general/general.scss'

const MobileNavInfluencer = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  return (
    <NavBar>
      <div className="navItems ">
        <div className="d-flex">
          <a target="_blank" href="https://blog.gotmy.com/">
            <img className="mr-3 " src={logo} className="Logo" alt="logo" />
          </a>
          {!PRIVATE_MARKETPLACE && (
            <a
              style={{
                color: "#FF5A60",
                alignSelf: "flex-end",
                marginTop: "14px",
              }}
              className="align-bottom"
              target="_blank"
              href="https://blog.gotmy.com/"
              className=""
            >
              <h5 style={{ fontSize: "17px" }}>Blog</h5>
            </a>
          )}
        </div>{" "}
        <Stylez>
          {IS_SIGN_UP_ENABLED && <Login_menus mobileNav />}
          {!PRIVATE_MARKETPLACE &&(<img
            onClick={() => setMobileMenu(!mobileMenu)}
            className="menuBtn"
            src={hamburger}
          />)}
        </Stylez>
      </div>

      {mobileMenu && (
        <div className="menuItems">
          <Menu mobileNav />
        </div>
      )}
    </NavBar>
  );
};

export default MobileNavInfluencer;

const NavBar = styled.div`
  z-index: 9;
  color: white;
  padding: 10px 20px !important;
  width: 100%;
  background-color : ${styles.mainBackground};
  .navItems {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Logo {
    width: 100px !important;
    height: 33px !important;
  }
  .menuItems {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }
`;
const Stylez = styled.div`
  display: flex;
  align-items: center;
  .menuBtn {
    cursor: pointer;
    margin-left: 10px;
  }
`;
