import { connect } from "react-redux";
import React from "react";
import logo from "assets/img/gotmy-n.png";
import "./style.scss";
import Delete from "assets/img/icons/ic-delete.svg";
import Sad from "assets/img/icons/sad-face-in-rounded-square.svg";
import { useModalBasicInfoLogic } from "./ModalBasicInfoLogic";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import DatePicker from "react-date-picker";
import AddCalendar from "../../../../../assets/img/icons/ic-addcalendar.svg";
import i18next from 'i18next';

const dateLimit = moment(new Date()).subtract(16, "y").format("YYYY-MM-DD");

const ModalBasicInfo = (props) => {
  const {
    formValues,
    onChangeFormValues,
    onChangeDatePickerValues,
    onSubmit,
    errorForm,
    setErrorForm,
    setShowAlertGoodCredentials,
    showAlertGoodCredentials,
    loading
  } = useModalBasicInfoLogic(props.user.user);
  const { user_name, user_lastname, user_birthdate, user_username } = formValues;

  return (
    <div className="modal Modales-login Modal-login" id="myModal_basic_info" >
      <div className="modal-dialog">
        <div className="modal-content">
          <img data-dismiss="modal" src={Delete} className="Cruz" alt="" />

          <div className="modal-body">
            <h6>{i18next.t("ModalLoginComponentWelcomeTo")}</h6>
            <img style={{ marginBottom: '15px' }} src={logo} className="Logo" alt="" />
            <h6 className="mb-3">{i18next.t("ModalLoginComponentLetsCompleteYourProfile")}</h6>
            <form noValidate onSubmit={onSubmit}>
              <div className="form-group">
                <label htmlFor="mb-1 email">{i18next.t("FormInputTagName")}</label>
                <input
                  required
                  type="text"
                  className="form-control modal_register"
                  name="user_name"
                  placeholder={i18next.t("FormInputPlaceholderName")}
                  maxLength="60"
                  value={user_name}
                  onChange={onChangeFormValues}
                />
              </div>
              <div className="form-group">
                <label htmlFor="mb-1 surname">{i18next.t("FormInputTagSurname")}</label>
                <input
                  required
                  type="text"
                  className="form-control modal_register"
                  name="user_lastname"
                  placeholder={i18next.t("FormInputPlaceholderSurname")}
                  maxLength="60"
                  value={user_lastname}
                  onChange={onChangeFormValues}
                />
              </div>

              <div className="form-group">
                <label htmlFor="mb-1 birthdate">{i18next.t("FormInputTagBirthDate")}</label>
                <br />
                <DatePicker
                  clearIcon={null} format="MM-dd-yyy" className="date-picker" value={new Date(user_birthdate)} onChange={onChangeDatePickerValues}
                  maxDate={new Date(dateLimit)} name="user_birthdate" calendarIcon={<img src={AddCalendar} alt="calendar" />}
                />
              </div>

              <div className="form-group">
                <label htmlFor="mb-1 username">{i18next.t("FormInputTagUsername")}</label>
                <input
                  required
                  type="text"
                  className="form-control modal_register"
                  name="user_username"
                  placeholder={i18next.t("FormInputPlaceholderUsername")}
                  maxLength="15"
                  value={user_username}
                  onChange={onChangeFormValues}
                />
              </div>

              <button type="submit" className="Btn-rojo" disabled={loading}>
                {!loading ? i18next.t("ModalLoginComponentCotinue") : i18next.t("ModalLoginComponentLoading") + "..."}
              </button>
            </form>
          </div>
        </div>
      </div>

      <SweetAlert
        imageUrl={Sad}
        show={errorForm}
        title={errorForm}
        onConfirm={() => setErrorForm("")}
      />
      <SweetAlert
        icon="success"
        show={showAlertGoodCredentials}
        title={i18next.t("ModalLoginComponentVerifyYourEmailAddress")}
        text={i18next.t("ModalLoginComponentVerifyYourEmailAddressDescription")}
        onConfirm={() => setShowAlertGoodCredentials(false)}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(ModalBasicInfo);
