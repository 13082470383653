import axios from "axios";
import {
  Api,
  ACCESS_TOKEN_API,
  SECOND_ACCESS_TOKEN_API
} from "./../environment/environment";

let headers = { "Access-Token": SECOND_ACCESS_TOKEN_API };

export default class EventRequestDAO {
  create(data, token) {
    return axios.post(`${Api}event_request/create`, data, {
      headers: {
        "Access-Token": token,
        "Content-Type": "application/json"
      }
    });
  }

  getMyRequestInfluencer(token) {
    return axios.get(`${Api}event_request/influencer/my_request`, {
      headers: { "Access-Token": token }
    });
  }

  getMyRequests(token) {
    return axios.get(`${Api}event_request/my_request`, {
      headers: { "Access-Token": token }
    });
  }

  modifyEventRequestInfluencer(token, data) {
    
    return axios.post(`${Api}event_request/update`, data, {
      headers: { "Access-Token": token }
    });
  }

  rejectRequest(token, data) {
    return axios.post(`${Api}event_request/reject`, data, {
      headers: { "Access-Token": token }
    });
  }

  acceptEventRequestInfluencer(token, data) {
    return axios.post(`${Api}event_request/accept`, data, {
      headers: { "Access-Token": token }
    });
  }
}
