import React, { useState, useEffect } from 'react';
import './style.scss';
import logo from '../../../assets/img/logo-white.png';
import Menu from "../../comunes/navbar/menu";
import Navbar from "../../comunes/navbar";
import Login_menu from "../../comunes/login_menu";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import i18next from 'i18next';

i18next.t("NavBarLiveEventsComponent")
const NavBar_liveEvents = ({ events }) => {
    const [pastEvents, setPastEvents] = useState([])
    const now = new Date()

    useEffect(() => {
        if (events && events.length > 0) {
            let filteredEvents = events.filter((event) => new Date(parseInt(event.event.event_streaming_end_date)) < now)
            setPastEvents(filteredEvents)
        }
    }, [events])
    return (
        <div className="Nav_search w-100">

            <div className=" Bg-blue flex-column pb-0 w-100">

                <div className="d-flex justify-content-between w-100">
                    {/* <img src={logo} className="Logo" alt="logo" /> */}
                    <Navbar/>
                    {/* <Menu />
                    <Login_menu /> */}
                </div>

                {/*MENU LIVE EVENTS*/}
                <div className="Menu-live-Events">
                    <NavLink exact to={`/live_events`} activeClassName="active_live_events" className="col-4 ">
                        {i18next.t("NavBarLiveEventsComponentUpcoming")} 
                        </NavLink>
                    {/* {pastEvents && pastEvents.length > 0 &&
                        <NavLink exact to={`/live_events/past_live_events`} activeClassName="active_live_events" className="col-4 ">
                            Past 
                        </NavLink>
                    } */}
                     <NavLink exact to={`/live_events/past_live_events`} activeClassName="active_live_events" className="col-4 ">
                            {i18next.t("NavBarLiveEventsComponentPastEvents")}
                        </NavLink>
                    <NavLink exact to={`/live_events/favorite_live_events`} activeClassName="active_live_events" className="col-4">
                        {i18next.t("NavBarLiveEventsComponentFavorites")} 
                        </NavLink>

                </div>

            </div>

        </div>
    );

}


const mapStateToProps = state => {
    return {
        events: state.user.user.purchasedEvents
    };
};

export default connect(
    mapStateToProps
)(NavBar_liveEvents);