import React, {Component} from 'react';

import './style.scss';
import map from "../../../assets/img/icons/ic-map.svg";
import logo from '../../../assets/img/logo-circulo-white.png';
import {Link} from "react-router-dom";

class MenuChooseModerator extends Component {
    render() {
        return (
            <div className="FechaCat2">
                <div className="FechaCat13 d-flex align-items-center">
                    <img src={logo} className="Logo5" alt="logo"/>
                    <h2 className="mb-0 title_request">Choose Live Event Moderator</h2>
                </div>
                <Link to={`/streaming_influencer`}><button className="request-streaming send_request" >Save</button></Link>
                <Link to={`/streaming_influencer`}><button className="request-streaming cancel" >Cancel</button></Link>
            </div>
        );
    }
}

export default MenuChooseModerator;

